export const types = {
  ZONE_TO_STATE_MAP: "ZONE_TO_STATE_MAP",
  ZONE_TO_STATE_MAP_SUCCESS: "ZONE_TO_STATE_MAP_SUCCESS",


  ADD_ZONE_TO_STATE_MAP: "ADD_ZONE_TO_STATE_MAP",
  ADD_ZONE_TO_STATE_MAP_SUCCESS: "ADD_ZONE_TO_STATE_MAP_SUCCESS",

  DELETE_ZONE_TO_STATE_MAP: "DELETE_ZONE_TO_STATE_MAP",
  DELETE_ZONE_TO_STATE_MAP_SUCCESS: "DELETE_ZONE_TO_STATE_MAP_SUCCESS",

 
};
