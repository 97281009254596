let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  TranspoterHeaderDataSecondary = {
    columns: [
      {
        label: "Transporter Name",
        field: "Name",
        sort: "asc",
        width: 2,
      },
      // {
      //   label: "Cost",
      //   field: "cost",
      //   sort: "asc",
      //   width: 1,
      // },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 1,
      },

      {
        label: "Action",
        field: "Action",
        sort: "asc",
        width: 1,
      },
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var TranspoterHeaderDataSecondary = {
  columns: [
    {
      label: "Transporter Name",
      field: "Name",
      sort: "asc",
      width: 2,
    },
    // {
    //   label: "Cost",
    //   field: "cost",
    //   sort: "asc",
    //   width: 1,
    // },
    {
      label: "Status",
      field: "status",
      sort: "asc",
      width: 2,
    },

    {
      label: "Action",
      field: "Action",
      sort: "asc",
      width: 1,
    },
  ],
};
export const searchBarTransporterSecondary = {
  searchBarData: [],
};
