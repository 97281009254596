import React, { Component } from "react";
import ReactApexCharts from "react-apexcharts";



export default class CostToRevenueRatio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chartType: this.props.chartType,
      series: [
        // {
        //   name: "Freight Cost",
        //   //   data: [],
        //   data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        //   color: "rgba(172, 16, 0, 1)",
        // },
        // {
        //   name: "Shipment Cost",
        //   //   data: [],
        //   data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        //   color: "rgba(255, 111, 0, 1)",
        // },
        {
          name: "Cost To Revenue Ratio",
          //   data: [],
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          // color: "rgba(40, 163, 76, 1)",
          color: "#5C3FFF",
        },
      ],
      widthChart: "",
      options: {
        tooltip: {
          shared: false,
          intersect: true,
          // followCursor: true,
        },
        markers: {
          size: 5,
          colors: ["#2800FC","rgba(255, 111, 0, 1)","rgba(40, 163, 76, 1)"],
          shape: "circle",
          radius: 3,
          strokeColor: "rgba(241, 245, 247, 1)",
        },
        chart: {
          background: "transparent",
          foreColor: "#373d3f",
          width: "100%",
          height: 300,
          type: "bar",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        legend: {
          show: false,
          position: "bottom",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
          //   curve: "straight",
        },
        //   theme: {
        //     mode: 'dark',
        //     palette: '#171722',
        //     monochrome: {
        //         enabled: false,
        //         color: 'white',
        //         shadeTo: 'light',
        //         shadeIntensity: 0.65
        //     },
        // },
        grid: {
          show: true,
          borderColor: "#90A4AE",
          strokeDashArray: 0,
          position: "back",
          xaxis: {
            lines: {
              show: false,
            },
          },
         
          yaxis: {
            lines: {
              show: false,
            },
           
          },
          row: {
            colors: undefined,
            opacity: 0.5,
          },
          column: {
            colors: undefined,
            opacity: 0.5,
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },

        xaxis: {
          startAtZero: true,
          axisBorder: {
            show: true,
            color: "#78909C",
            offsetX: -0,
            offsetY: 0,
          },

          // tickAmount: "",
          tickPlacement: "between",
          categories: [
            "Jan-21",
            "Feb-21",
            "Mar-21",
            "Apr-21",
            "Aug-21",
            "Sep-21",
            "Oct-21",
          ],
        },

        yaxis: {
          forceNiceScale: true,
          labels: {
            formatter: function (val) {
              return val + ' %'
            },
            rotate:0,
          },
          show: true,
          showAlways: true,
          axisBorder: {
            show: true,
            color: "#78909C",
            offsetX: 0,
            offsetY: +0,
          },

          //   tickAmount: 3,
          tickPlacement: "between",
          //   max:60
        },
      },
    };
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ widthChart: '100%' })
    }, 1000)
    this.setState({ widthChart: ' ' })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.invoice !== this.props.invoice || prevProps.chartType !== this.props.chartType) {
      // console.log("this.props.invoice",this.props.invoice)
      let totalMonth =12;
      // let freightCost = [];
      // let shipmentCost =[];
      let costToRevenuPercentage=[];
      let WeekDay = 7;
      let quater = 4;
      let date=[];
      if (this.props.invoice !== undefined) {
        let costToRevenueRatioData = this.props?.invoice
        if (this.props.selectedFilteredCostToRevenueRatio.value === "month") {
          for (let i = 0; i < totalMonth; i++) {
            let checkData = costToRevenueRatioData.filter((ele) => {
              // console.log("ele",ele)
              return ele?.tenure === i + 1;
            });
            // console.log("check data", checkData)
            if (checkData?.length > 0) {
              // freightCost.push(
              //   Number(checkData[0].freightCost.toFixed(2))
              // );
              // shipmentCost.push(
              //   Number(checkData[0].shipmentCost.toFixed(2))
              // );
              costToRevenuPercentage.push( checkData[0]?.freightCost === 0 ? Number(1):
              Number(((checkData[0].shipmentCost/checkData[0].freightCost)*100).toFixed(2))
              );
            } else {
              // freightCost.push(0);
              // shipmentCost.push(0);
              costToRevenuPercentage.push(0);
            }
          }
  
          this.setState(
            {
              chartType: this.props.chartType,
              series: [
                // {
                //   data: freightCost,
                // },
                // {
                //   data: shipmentCost,
                // },
                {
                  data: costToRevenuPercentage,
                },
              ],
              options: {
                xaxis: {
                  categories: [
                    "JAN",
                    "FEB",
                    "MAR",
                    "APR",
                    "MAY",
                    "JUNE",
                    "JULY",
                    "AUG",
                    "SEPT",
                    "OCT",
                    "NOV",
                    "DEC",
                  ],
                },
                chart: { type: this.props.chartType },
              },
            },
            () => {
             
              // console.log("tthis.state",this.state.series)
            }
          );
         
          
        } 
        if (this.props.selectedFilteredCostToRevenueRatio.value === "weekDay") {
          for (let i = 0; i < WeekDay; i++) {
            let checkData = costToRevenueRatioData.filter((ele) => {
              // console.log("ele",ele)
              return ele?.tenure === i ;
            });
            // console.log("check data", checkData)
            if (checkData?.length > 0) {
              // freightCost.push(
              //   Number(checkData[0].freightCost.toFixed(2))
              // );
              // shipmentCost.push(
              //   Number(checkData[0].shipmentCost.toFixed(2))
              // );
              costToRevenuPercentage.push( checkData[0]?.freightCost === 0 ? Number(1):
              Number(((checkData[0].shipmentCost/checkData[0].freightCost)*100).toFixed(2))
              );
            } else {
              // freightCost.push(0);
              // shipmentCost.push(0);
              costToRevenuPercentage.push(0);
            }
          }
  
          this.setState(
            {
              chartType: this.props.chartType,
              series: [
                // {
                //   data: freightCost,
                // },
                // {
                //   data: shipmentCost,
                // },
                {
                  data: costToRevenuPercentage,
                },
              ],
              options: {
                xaxis: {
                  categories: [
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                  ],
                },
                chart: { type: this.props.chartType },
              },
            },
            () => {
             
              // console.log("tthis.state",this.state.series)
            }
          );
         
          
        } 
        if (this.props.selectedFilteredCostToRevenueRatio.value === "quarter") {
          for (let i = 0; i < quater; i++) {
            let checkData = costToRevenueRatioData.filter((ele) => {
              // console.log("ele",ele)
              return ele?.tenure === i + 1;
            });
            // console.log("check data", checkData)
            if (checkData?.length > 0) {
              // freightCost.push(
              //   Number(checkData[0].freightCost.toFixed(2))
              // );
              // shipmentCost.push(
              //   Number(checkData[0].shipmentCost.toFixed(2))
              // );
              costToRevenuPercentage.push( checkData[0]?.freightCost === 0 ? Number(1):
              Number(((checkData[0].shipmentCost/checkData[0].freightCost)*100).toFixed(2))
              );
            } else {
              // freightCost.push(0);
              // shipmentCost.push(0);
              costToRevenuPercentage.push(0);
            }
          }
  
          this.setState(
            {
              chartType: this.props.chartType,
              series: [
                // {
                //   data: freightCost,
                // },
                // {
                //   data: shipmentCost,
                // },
                {
                  data: costToRevenuPercentage,
                },
              ],
              options: {
                xaxis: {
                  categories: [
                    "Quater1 ",
                    "Quater2 ",
                    "Quater3 ",
                    "Quater4 ",
                  ],
                },
                chart: { type: this.props.chartType },
              },
            },
            () => {
             
              // console.log("tthis.state",this.state.series)
            }
          );
         
          
        } 
        if (this.props.selectedFilteredCostToRevenueRatio.value === "date"||this.props.selectedFilteredCostToRevenueRatio.value === "week") {
          costToRevenueRatioData.forEach((ele)=>{
            date.push(ele?.tenure);
            costToRevenuPercentage.push( ele?.freightCost === 0 ? Number(1):
            Number(((ele?.shipmentCost/ele?.freightCost)*100).toFixed(2)))
          })
           
  
          this.setState(
            {
              chartType: this.props.chartType,
              series: [
                // {
                //   data: freightCost,
                // },
                // {
                //   data: shipmentCost,
                // },
                {
                  data: costToRevenuPercentage,
                },
              ],
              options: {
                xaxis: {
                  categories: [
                   ...date
                  ],
                },
                chart: { type: this.props.chartType },
              },
            },
            () => {
             
              // console.log("tthis.state",this.state.series)
            }
          );
         
          
        } 
        if (this.props.selectedFilteredCostToRevenueRatio.value === "year") {
          costToRevenueRatioData.forEach((ele)=>{
            date.push(ele?.tenure);
            costToRevenuPercentage.push( ele?.freightCost === 0 ? Number(1):
            Number(((ele?.shipmentCost/ele?.freightCost)*100).toFixed(2)))
          })
           
  
          this.setState(
            {
              chartType: this.props.chartType,
              series: [
                // {
                //   data: freightCost,
                // },
                // {
                //   data: shipmentCost,
                // },
                {
                  data: [0,...costToRevenuPercentage],
                },
              ],
              options: {
                xaxis: {
                  categories: [date[0]-1,
                   ...date
                  ],
                },
                chart: { type: this.props.chartType },
              },
            },
            () => {
             
              // console.log("tthis.state",this.state.series)
            }
          );
         
          
        } 
       
      }
    }
  }
  render() {
    return (
      <div className="cursor-pointer freightCostApexchart">
        <ReactApexCharts
          style={{
            width: "100%",
            minWidth: "fit-content"
          }}
          options={this.state.options}
          series={this.state.series}
          type={this.state.chartType ? this.state.chartType : "line"}
          width={this.state.widthChart ? this.state.widthChart : "100%"}
          height={484}
        />
      </div>
    )
  }
}
