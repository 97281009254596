import React, { Component } from "react";
import { connect } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import { Row, Col, Container } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { NotificationManager } from "react-notifications";
import { exportToCSV } from "../common/exportToCsv";

//actions
import { dispatchLeadTimeReport, dispatchLeadTimeReportExcel } from "../../store/baxter-report/actions";

import Index from "../../components/Common/DataTableNeo/Index";
import {
  AdminHeaderData,
  getLocalStorage,
  searchBar,
} from "./RowHeaders/RowHeaders";
import QuickFilters from "./quckFilters";
import { Button } from "reactstrap";
import moment from "moment";

var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var limit = 10;
var searchValues = {};
var darkTheme;
var searchValues = {};
let pathName;
let key;
let keyOffset;
class DispatchLeadTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dataLoading: false,
      offset: 0,
      searchFilters: {},
      isExportingExcel: false
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Prevent unnecessary rendering while exporting
    if (this.state.isExportingExcel !== nextState.isExportingExcel) {
      return false;
    }
    // Add other conditions if needed
    return true;
  }


  componentDidMount() {
    offset = 0;
    this.getAllData();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 0.9;
  }
  getAllData = () => {
    pathName = window.location.pathname;
    key = `mySearchFilter_${pathName}`;
    keyOffset = `myOffsetFilter_${pathName}`;

    let storedData = localStorage.getItem(key)
      ? JSON.parse(window.localStorage.getItem(key))
      : "";
    searchValues = storedData || {};
    let offsetData = localStorage.getItem(keyOffset)
      ? localStorage.getItem(keyOffset)
      : window.localStorage.setItem(keyOffset, offset);
    this.setState({ dataLoading: true }, () => { });
    let searchFilters = this.state.searchFilters;
    this.props.dispatchLeadTimeReport(
      { offset: offset || offsetData, limit: 10 },
      Object.keys(searchFilters).length > 0 ? searchFilters : storedData,
      (res) => {
        this.setState({ dataLoading: false });
      }
    );
    // this.setState({ dataLoading: false });
  };
  // componentWillUnmount = () => {
  //   offset = 0;
  // };
  getAllDataReset = () => {
    offset = 0;
    window.localStorage.setItem(keyOffset, offset);
    this.getAllData();
  };
  handleChangePage = (event, val) => {
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    window.localStorage.setItem(keyOffset, offset);
    this.setState({ loading: true });
    this.getAllData();
  };
  handleChange = (dateValues) => {
    searchValues["invoiceGeneratedTime"] =
      dateValues[0] !== null
        ? dateValues[0]
          ? moment(dateValues[0]).format("YYYY-MM-DD")
          : moment(dateValues).format("YYYY-MM-DD")
        : null;
    searchValues["invoiceDispatchedTime"] =
      dateValues[1] !== null
        ? moment(dateValues[1]).format("YYYY-MM-DD")
        : null;
        pathName = window.location.pathname;
        key = `mySearchFilter_${pathName}`;
        keyOffset = `myOffsetFilter_${pathName}`;
        this.setState({ searchFilters: searchValues }, () => {
          offset = 0;
          window.localStorage.setItem(
            key,
            JSON.stringify(this.state.searchFilters)
          );
          window.localStorage.setItem(keyOffset, offset);
          this.getAllData();
        });
  };

  viewOrderProductData = (ele) => {
    this.props.history.push({
      pathname: "/viewOrderProductDetail",
      state: { currentProduct: ele },
    });
  };

  
  searchValueAssignerFunction = (name, value, type) => {
    if (typeof string === "text" || type === "text") {
      searchValues[name] = value?.replace(/^\s+/, "");
      this.setState({ searchFilters: searchValues }, () => {
        window.localStorage.setItem(
          key,
          JSON.stringify(this.state.searchFilters)
        );
        window.localStorage.setItem(keyOffset, offset);
      });
    }

    if (type === "date") {
      offset = 0;
      searchValues[name] = value;
      this.setState({ searchFilters: searchValues }, () => {
        window.localStorage.setItem(
          key,
          JSON.stringify(this.state.searchFilters)
        );
        window.localStorage.setItem(keyOffset, offset);
        this.getAllData();
      });
    }
  };

    reset = (invoiceTopFilter) => {
      const { invoiceGeneratedTime, invoiceDispatchedTime } = searchValues;
      offset = 0;
      if (invoiceTopFilter) {
        searchValues = {
          ...searchValues,
          invoiceGeneratedTime: "",
          invoiceDispatchedTime: "",
        };
        localStorage.setItem(key, JSON.stringify(searchValues));
        window.localStorage.setItem(keyOffset, offset);
      } else {
        searchValues = {
          invoiceGeneratedTime: invoiceGeneratedTime || "",
          invoiceDispatchedTime: invoiceDispatchedTime || "",
        };
        localStorage.setItem(key, JSON.stringify(searchValues));
        window.localStorage.setItem(keyOffset, offset);
      }
      this.setState(
        {
          searchFilters: searchValues,
        },
        () => {
          this.getAllData();
        }
      );
    }
  
  leadTimeTAT = (date1, date2) => {
    const dates1 = new Date(date1);
    const dates2 = new Date(date2);
    var timeDiff = Math.abs(dates2?.getTime() - dates1?.getTime());
    var daysDiff = Math.ceil(timeDiff / (24 * 60 * 60 * 1000));
    return `${daysDiff} day`;
  };

  exportExcelData = () => {
    this.setState({ dataLoading: true });
    // this.setState({ isExportingExcel: true });
    let storedData = localStorage.getItem(key)
      ? JSON.parse(window.localStorage.getItem(key))
      : "";
    let searchFilters = this.state.searchFilters;

    // Call the action function using await to ensure proper execution
    this.props.dispatchLeadTimeReportExcel(
      { offset: 0, limit: 100000 },
      Object.keys(searchFilters).length > 0 ? searchFilters : storedData,
      (res) => {
        this.setState({ dataLoading: false });
        // this.setState({ isExportingExcel: false });
        // let exdata=[]
        let exportData = [];
        if (res?.data?.docs?.length > 0) {
          res.data.docs.map((ele) => {
            // console.log("ele", ele);
            exportData.push({
              "Order Number": ele?.order?.orderNumber ? ele?.order?.orderNumber : "N/A",
              "Warehouse Number": ele?.order?.businessUnit
                ? ele?.order?.businessUnit
                : "N/A",
              "Invoice Number": ele?.legalInvoiceNo ? ele?.legalInvoiceNo : "N/A",
              "Invoice Generation Time": ele?.ackDt ? (
                moment(ele?.ackDt).format("YYYY-MMM-DD hh:mmA")
              ) : (
                "N/A"
              ),
              "Invoice Dispatch Time": ele?.eWayBillDt ? (
                moment(ele?.eWayBillDt).format("YYYY-MMM-DD hh:mmA")
              ) : (
                "N/A"
              ),
              "Lead Time TAT": this.leadTimeTAT(ele?.ackDt, ele?.eWayBillDt),

            });
          });
        }
        // console.log("export Data", exportData);
        if (exportData?.length > 0) {
          exportToCSV([...exportData], "Dispatch Lead Report");
        }
        else{
          exportData.push({
            "Order No": "",
            "Warehouse Number": "",
            "Invoice Number": "",
            "Invoice Generation Time": "",
            "Invoice Dispatch Time": "",
            "Lead Time TAT":""
          });
          exportToCSV([...exportData], "Dispatch Lead Report");
        }
      }
    );
  };


  render() {
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    darkTheme = mode === "dark__mode" ? true : false;


    const { dispatchLeadTimeReportData } = this.props;



    const localStorageData = JSON.parse(localStorage.getItem("LeadTimeReport"));
    const orderProducts = navigator.onLine
      ? dispatchLeadTimeReportData?.data?.docs
      : localStorageData?.data?.docs;
    const dispatchLeadTimeOffset = localStorage.getItem(keyOffset)
      ? JSON.parse(localStorage.getItem(keyOffset))
      : "";
    let rows = [];
    if (Array.isArray(orderProducts)) {
      // console.log("orderData : ", orderProducts);
      // if (!this.state.isExportingExcel) {
      offset = dispatchLeadTimeOffset ? dispatchLeadTimeOffset : offset;
      totCnt = navigator.onLine
        ? dispatchLeadTimeReportData?.data?.length
        : localStorageData?.data?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = navigator.onLine
        ? dispatchLeadTimeReportData?.data?.offset +
        dispatchLeadTimeReportData?.data?.docs?.length
        : localStorageData?.data?.offset + localStorageData?.data?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      orderProducts.forEach((ele) => {
        rows.push({
          orderNumber: ele?.order?.orderNumber
            ? ele?.order?.orderNumber
            : "N/A",
          warehouseNumber: ele?.order?.businessUnit
            ? ele?.order?.businessUnit
            : "N/A",
          invoiceNumber: ele?.legalInvoiceNo ? ele?.legalInvoiceNo : "N/A",
          invoiceGeneratedTime: ele?.ackDt ? (
            <>
              <div>{moment(ele?.ackDt).format("YYYY-MMM-DD")}</div>
              <div>{moment(ele?.ackDt).format("hh:mmA")}</div>
            </>
          ) : (
            "N/A"
          ),
          invoiceDispatchedTime: ele?.eWayBillDt ? (
            <>
              <div>{moment(ele?.eWayBillDt).format("YYYY-MMM-DD")}</div>
              <div>{moment(ele?.eWayBillDt).format("hh:mmA")}</div>
            </>
          ) : (
            "N/A"
          ),
          leadTimeTAT: this.leadTimeTAT(ele?.ackDt, ele?.eWayBillDt),
          // action: this.actions(ele),
        });
      });
    }
    // }


    return (
      <>
        {getLocalStorage()}
        <QuickFilters reset={this.reset} handleChange={this.handleChange}
          toggleExcel={this.exportExcelData}
        // disableExcelButton={this.state.isExportingExcel}
        />
        <Container
          aria-hidden="true"
          fluid
          className={`${darkTheme ? "darkBackgroundList mobilepPadding" : "mobilepPadding"
            }`}
        >
          <div className="dispatchList">
            <Index
              {...this.props}
              headers={AdminHeaderData}
              data={rows}
              searchable={true}
              searchBar={searchBar}
              fetchId={this.props.fetchId}
              reset={this.reset}
              searchValueAssignerFunction={this.searchValueAssignerFunction}
              getAllData={this.getAllDataReset}
              dataLength={90}
              rowHeight={"56px"}
              dataLoading={this.state.dataLoading}
              dashboard="true"
              classList="3"
              textInput="inputIndex"
              resetCol="3"
            />
          </div>

          {totCnt !== 0 && !this.state.dataLoading ? (
            <Row
              xs={12}
              style={{ color: darkTheme ? "#9d9d9d" : "#0C0C0C" }}
              className="mb-2 paginationRow"
            >
              <Col xs={7} sm={5} md={4} className="span-col-pagenation">
                <div className="float-left">
                  <Pagination
                    className="desktop-pagination"
                    size="medium"
                    shape="rounded"
                    page={offset / 10 + 1}
                    count={totPages}
                    color="primary"
                    onChange={this.handleChangePage}
                    defaultPage={1}
                    siblingCount={1}
                  />
                  <Pagination
                    className="mobile-pagination"
                    size="small"
                    shape="rounded"
                    page={offset / 10 + 1}
                    count={totPages}
                    color="primary"
                    onChange={this.handleChangePage}
                    defaultPage={1}
                    siblingCount={1}
                  />
                </div>
              </Col>
              <Col
                className="mt-2 spanPagenationentries span-col-pagenation"
                xs={5}
                sm={7}
                md={8}
              >
                {" "}
                <span>
                  Showing {fromVal} to {toVal} of {totCnt} entries
                </span>
              </Col>
            </Row>
          ) : null}
        </Container>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
    dispatchLeadTimeReportData:
      state?.dispatchLeadTimeReportReducer?.getDispatchLeadTimeReport,
    fetchId: state.dispatchLeadTimeReportReducer?.fetchId,
  };
};


export default connect(mapStateToProps, {
  dispatchLeadTimeReport,
  dispatchLeadTimeReportExcel
})(DispatchLeadTime);
