/* LAYOUT */
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const CHANGE_LAYOUT_WIDTH = "CHANGE_LAYOUT_WIDTH";
export const CHANGE_SIDEBAR_THEME = "CHANGE_SIDEBAR_THEME";
export const CHANGE_SIDEBAR_TYPE = "CHANGE_SIDEBAR_TYPE";

// topbar
export const CHANGE_TOPBAR_THEME = "CHANGE_TOPBAR_THEME";

/* RIGHT SIDEBAR */
export const TOGGLE_RIGHT_SIDEBAR = "TOGGLE_RIGHT_SIDEBAR";
export const SHOW_RIGHT_SIDEBAR = "SHOW_RIGHT_SIDEBAR";
export const HIDE_RIGHT_SIDEBAR = "HIDE_RIGHT_SIDEBAR";

// Preloader
export const CHANGE_PRELOADER = "CHANGE_PRELOADER";
export const types = {
  FETCH_VEHICLES: "FETCH_VEHICLES",
  FETCH_VEHICLES_SUCCESS: "FETCH_VEHICLES_SUCCESS",
  FETCH_VEHICLES_EXCEL: "FETCH_VEHICLES_EXCEL",
  FETCH_VEHICLES_SUCCESS_EXCEL: "FETCH_VEHICLES_SUCCESS_EXCEL",
  FETCH_ALL_VEHICLES: "FETCH_ALL_VEHICLES",
  FETCH_VEHICLE_BYID: "FETCH_VEHICLE_BYID",
  FETCH_VEHICLE_BYID_SUCCESS: "FETCH_VEHICLE_BYID_SUCCESS",

  EDIT_VEHICLE_BYID: "EDIT_VEHICLE_BYID",
  EDIT_VEHICLE_BYID_SUCCESS: "EDIT_VEHICLE_BYID_SUCCESS",

  FETCH_AVAIL_VEHICLES: "FETCH_AVAIL_VEHICLES",
  FETCH_AVAIL_VEHICLES_SUCCESS: "FETCH_AVAIL_VEHICLES_SUCCESS",
  CREATE_VEHICLE: "CREATE_VEHICLE",
  CREATE_VEHICLE_SUCCESS: "CREATE_VEHICLE_SUCCESS",

  DELETE_VEHICLE: "DELETE_VEHICLE",
  DELETE_VEHICLE_SUCCESS: "DELETE_VEHICLE_SUCCESS",

  FETCH_VEHICLES_TYPE: "FETCH_VEHICLES_TYPE",
  FETCH_VEHICLES_TYPE_SUCCESS: "FETCH_VEHICLES_TYPE_SUCCESS",

  FETCH_ALL_VEHICLE_TYPES_SUCCESS: "FETCH_ALL_VEHICLE_TYPES_SUCCESS",
  FETCH_ALL_VEHICLE_TYPES: "FETCH_ALL_VEHICLE_TYPES",
  GET_VEHICLE_PRESIGNED_URL:"GET_VEHICLE_PRESIGNED_URL",
  CREATE_VEHICLE_VIA_EXCEL:"CREATE_VEHICLE_VIA_EXCEL",
  UPLOAD_IMAGE1:"UPLOAD_IMAGE1",
};
