// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import { types } from "./actionTypes";

import { getApi } from "../../helpers/auth_helper";
// import { getDataGroupBy } from "rsuite/esm/utils";

function* workfetchShipmentMaster({ filters, searchFilter, callback }) {
  let offset = filters?.offset ? filters?.offset : 0;
  // let shipmentId = filters?.shipmentId ? {shipmentId:filters?.shipmentId} : "";
  // let tripId = filters?.tripId ? {tripId:filters?.tripId} : "";
  let filterString = "";
  if(searchFilter !== null && searchFilter !== undefined ){
    for (const [key, value] of Object.entries(searchFilter)) {
      let filterKey = key;
      let filterValue = value;
      filterString = filterString + "&" + filterKey + "=" + filterValue;
    }
  }
  try {
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/shipment/getshipmentmasterdata?offset=` +
      offset +
      "&limit=" +
      process.env.REACT_APP_PERPAGE +
      filterString;

    const response = yield call(getApi, uri);
    callback(response);
    yield put({
      type: types.SHIPMENT_MASTER_SUCCESS,
      payload: response,
    });
    localStorage.setItem("shipmentFreighMaster", JSON.stringify(response));
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not fetch posts.", error);
  }
}

/**
 * Watchers
 */

export function* watchfetchShipmentMaster() {
  yield takeEvery(types.SHIPMENT_MASTER, workfetchShipmentMaster);
}

function* ShipmentMasterSaga() {
  yield all([fork(watchfetchShipmentMaster)]);
}

export default ShipmentMasterSaga;
