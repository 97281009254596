import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";
import { createRoute } from "../../store/route/actions";
import { NotificationManager } from "react-notifications";
import { Formik, ErrorMessage } from "formik";
import { restrict } from "../restrict";
import { PERMS } from "../../enum/perms.enum";
import * as Yup from "yup";

import "@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { connect } from "react-redux";
import formatInput from "../InputFormatter";
import { FormGroup, Label, Input } from "reactstrap";
import { fetchHops } from "../../store/hop/actions";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

const initialState = () => ({
  onSubmitDisable: false,
  offset: 0,
  limit: 20,
  hopData: [],
  hops: [],
  selectedHop: [],
  form: {
    routeCode: "",
    name: "",
    source: "",
    destination: "",
    via: "",
  },
  filteredSources: "",
  filteredDestinations: "",
});
class RouteForm extends Component {
  constructor(props) {
    super(props);
    this.state = initialState();
    (async () => {
      let redirect = await restrict(PERMS.DRIVER_TRANSPORTER_CREATE);

      if (redirect === false) {
        this.props.history.push("/operationsDashboard");
      }
    })();
  }

  componentDidMount = () => {
    this.setState({
      filteredSources: this.state.hops,
      filteredDestinations: this.state.hops,
    });
    this.getAllSourceHopData();
  };

  getAllSourceHopData = () => {
    let filtersHopsData = {
      offset: 0,
      limit: 20,
      q: "",
    };
    this.fetchHops(filtersHopsData);
  };

  filtersHops = (e) => {
    if (e?.length > 0) {
      let filtersHopsData = {
        offset: 0,
        limit: 20,
        q: e,
      };
      this.fetchHops(filtersHopsData);
    }
    if (e.length === 0) {
      let filtersHopsData = {
        offset: 0,
        limit: 20,
        q: "",
      };
      this.fetchHops(filtersHopsData);
    }
  };

  fetchHops = (filtersHopsData) => {
    this.props.fetchHops(
      {
        offset: filtersHopsData.offset,
        limit: filtersHopsData.limit,
        label: filtersHopsData.q,
        hopCode: filtersHopsData?.hopCode,
      },
      (res) => {
        this.hops = [];
        if (res?.data?.docs?.length > 0) {
          res.data.docs.forEach((ele) => {
            this.hops.push({
              label: ele.label,
              value: {
                ele,
              },
            });
          });
          this.setState(
            { hops: this.hops || [], hopData: this.hops },
            () => {}
          );
        }
      }
    );
  };
  handleSubmitChange = (e, formik) => {
    const name = e.target.name;
    const value = e.target.value;
    let formData = this.state.form;
    formData[`${name}`] = value;
    formik.setFieldValue(name, value);
    this.setState({ form: formData }, () => {});
  };

  filterHopList = (e) => {
    console.log("e", e);
    if (e?.length > 2) {
      this.props.fetchHops({ offset: 0, limit: 20, q: e }, (res) => {
        let hops = [];
        if (res.data?.docs?.length > 0) {
          res.data.docs.forEach((ele) => {
            console.log("ele", ele);
            hops.push({
              value: ele ? ele : "",
              label: ele.label ? ele.label : "",
            });
          });
        }
        this.setState(
          {
            hops: hops || [],
          },
          () => {
            console.log("Filtered hops:", this.state.hops);
          }
        );
      });
    }

    if (e?.length === 0) {
      this.setState({ hops: this.state.hopData || [] }, () => {
        console.log("Reset to initial hops:", this.state.hopData);
      });
    }
  };
  validateNumber = (evt) => {
    var theEvent = evt || window.event;

    if (theEvent.type === "paste") {
      var key = theEvent.clipboardData.getData("text/plain");
    } else {
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /^[0-9]*$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };
  trimSpace = (event, name) => {
    const { form } = this.state;
    let formObj = formatInput(event, name, form);
    this.setState({ form: formObj });
  };

  getFilteredHops = () => {
    const { hops, form, selectedHop } = this.state;
    const selectedValues = [
      form.source?.value?.ele?._id,
      form.destination?.value?.ele?._id,
      ...((selectedHop && selectedHop.map(hop => hop.value.ele._id)) || []),
    ].filter(Boolean); 
    return hops.filter(hop => !selectedValues.includes(hop.value.ele._id));
  };
  reset = (cb) => {
    this.setState({
      form: {
        routeCode: "",
        name: "",
        source: "",
        destination: "",
        via: "",
      },
    });
  };
  getFilteredHop = (excludeHop = null) => {
    const { hops } = this.state;
    return hops.filter(hop => (hop.value.ele._id !== excludeHop?.value.ele._id)||[]);
  };
  handleSubmitSave = (formValues) => {
    let { routeCode, name, source, destination} = this.state.form;
    let { selectedHop } = this.state;
    let formObj = {
      routeCode: routeCode,
      name: name,
      source: source?.value?.ele?._id || source,
      destination: destination?.value?.ele?._id || destination,
      via: selectedHop ? selectedHop.map((item) => item.value?.ele?._id) : [],
    };
    // console.log(formObj, "handleSubmitSave");
    this.props.createRoute(formObj, (res) => {
      if (res.message === "Route has been created successfully") {
        NotificationManager.success(res["message"], "Success");
        console.log("props", this.props);
        if (this.props.handleSubmit) {
          this.props.handleSubmit(false);
        }

        this.props.history.push("/routes");
      } else {
        NotificationManager.error(res["message"], "Error");
        this.setState({ onSubmitDisable: false });
      }
    });
  };
  render() {
    const customStyles1 = {
      input: (base) => ({
        ...base,
        color: this.props.mode ? "#9d9d9d" : "#505d69",
      }),
    };
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    const validationSchema = Yup.object({
      routeCode: Yup.string()
        .required("Route code is required")
        .min(2, "Route code must be at least 2 characters"),

      name: Yup.string()
        .required("Name is required")
        .min(2, "Label must be at least 2 characters"),
    });
    const { form } = this.state;
    const role = JSON.parse(localStorage.getItem("authUser"))?.data?.role
      ?.label;
    return (
      <React.Fragment>
        <Row className="HopFormDiv">
          <Col lg={12}>
            <Row>
              <Formik
                md="12"
                initialValues={
                  this.props?.location?.mode === "edit" ? this.formSet() : form
                }
                validationSchema={validationSchema}
                onSubmit={this.handleSubmitSave}
                validateOnChange={false}
              >
                {(formik) => {
                  const { handleSubmit, values, errors, touched, resetForm } =
                    formik;
                  console.log("values", values);
                  console.log(errors);
                  return (
                    <form
                      className="form text-capitalize"
                      onSubmit={handleSubmit}
                      style={{
                        width: " 450px",
                        height: "380px",
                        marginTop: "6px",
                      }}
                    >
                      <FormGroup>
                        <Label
                          className={`required-field  mt-2 ${
                            this.props.mode ? "light__span" : ""
                          }`}
                          style={{
                            color: "#05284E",
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "bold",
                            fontSize: "15px",
                            lineHeight: "18px",
                          }}
                        >
                          Route Code.
                        </Label>

                        <Input
                          type="text"
                          value={form.routeCode}
                          onBlur={(e) => this.trimSpace(e, "")}
                          onChange={(e) =>
                            this.handleSubmitChange(
                              {
                                target: {
                                  name: "routeCode",
                                  value: e.target.value,
                                },
                              },
                              formik
                            )
                          }
                          name="hopCode"
                          id="hopCode"
                          className={
                            errors.routeCode && touched.routeCode
                              ? "input-error"
                              : null
                          }
                          placeholder="Route Code"
                          style={{
                            height: "50px",
                            width: " 390px",
                            backgroundColor: this.props.mode
                              ? "#2E2E46"
                              : "#FFFFFF",
                            border: "0.5px solid #B9B9B9",
                            boxSizing: "border-box",
                            borderRadius: "4px",
                          }}
                        />
                        <ErrorMessage
                          name="routeCode"
                          component="span"
                          className="error"
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label
                          className={`required-field  mt-2 ${
                            this.props.mode ? "light__span" : ""
                          }`}
                          style={{
                            color: "#05284E",
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "bold",
                            fontSize: "15px",
                            lineHeight: "18px",
                          }}
                        >
                          Name
                        </Label>
                        <Input
                          type="text"
                          value={form.name}
                          onChange={(e) =>
                            this.handleSubmitChange(
                              {
                                target: {
                                  name: "name",
                                  value: e.target.value,
                                },
                              },
                              formik
                            )
                          }
                          name="name"
                          id="name"
                          className={
                            errors.name && touched.name ? "input-error" : null
                          }
                          placeholder="Name"
                          style={{
                            height: "50px",
                            width: "390px",
                            backgroundColor: this.props.mode
                              ? "#2E2E46"
                              : "#FFFFFF",
                            border: "0.5px solid #B9B9B9",
                            boxSizing: "border-box",

                            borderRadius: "4px",
                          }}
                        />
                        <ErrorMessage
                          name="name"
                          component="span"
                          className="error"
                        />
                      </FormGroup>
                        <FormGroup className="select2-container mt-2">
                          <Label
                            className={`required-field control-label ${
                              this.props.mode ? "light__span" : ""
                            }`}
                            style={{
                              color: "#05284E",
                              fontFamily: "Roboto",
                              fontStyle: "normal",
                              fontWeight: "bold",
                              fontSize: "15px",
                              lineHeight: "18px",
                            }}
                          >
                            Source
                          </Label>
                          <Select
                            value={form.source}
                            onInputChange={this.filterHopList}
                            id="typeVehicle"
                            onChange={(e) => {
                              this.handleSubmitChange(
                                {
                                  target: {
                                    name: "source",
                                    value: e,
                                  },
                                },
                                formik
                              );
                              this.setState({
                                filteredDestinations: this.getFilteredHop(e),
                              });
                            }}
                            options={this.getFilteredHops(form.destination)}
                            classNamePrefix="select2-selection"
                            style={{
                              height: "40px",
                              width: " 400px",
                              backgroundColor: "#FFFFFF",
                              border: "0.5px solid #B9B9B9",
                              boxSizing: "border-box",
                              borderRadius: "4px",
                            }}
                            styles={customStyles1}
                            className={this.props.mode ? "darkModeDriver" : ""}
                          />
                          <ErrorMessage
                          name="source"
                          component="span"
                          className="error"
                        />
                        </FormGroup>
                        <FormGroup className="select2-container mt-2">
                          <Label
                            className={`required-field control-label ${
                              this.props.mode ? "light__span" : ""
                            }`}
                            style={{
                              color: "#05284E",
                              fontFamily: "Roboto",
                              fontStyle: "normal",
                              fontWeight: "bold",
                              fontSize: "15px",
                              lineHeight: "18px",
                            }}
                          >
                            Destination
                          </Label>
                          <Select
                            value={form.destination}
                            onInputChange={this.filterHopList}
                            id="typeVehicle"
                            onChange={(e) => {
                              this.handleSubmitChange(
                                {
                                  target: {
                                    name: "destination",
                                    value: e,
                                  },
                                },
                                formik
                              );
                              this.setState({
                                filteredSources: this.getFilteredHops(e),
                              });
                            }}
                            options={this.getFilteredHops(form.source)}
                            classNamePrefix="select2-selection"
                            style={{
                              height: "40px",
                              width: " 400px",
                              backgroundColor: "#FFFFFF",
                              border: "0.5px solid #B9B9B9",
                              boxSizing: "border-box",
                              borderRadius: "4px",
                            }}
                            styles={customStyles1}
                            className={this.props.mode ? "darkModeDriver" : ""}
                          />
                          <ErrorMessage
                          name="destination"
                          component="span"
                          className="error"
                        />
                        </FormGroup>
                      <FormGroup>
                        <Label
                          className={`control-label ${
                            this.props.mode ? "light__span" : ""
                          }`}
                          style={{
                            color: "#05284E",
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "bold",
                            fontSize: "15px",
                            lineHeight: "18px",
                          }}
                        >
                          Via
                        </Label>
                        <Select
                          value={
                            this.state.selectedHop ? this.state.selectedHop : []
                          }
                          isMulti={true}
                          style={{
                            height: "40px",
                            width: "400px",
                            backgroundColor: "#FFFFFF",
                            border: "0.5px solid #B9B9B9",
                            boxSizing: "border-box",
                            borderRadius: "4px",
                          }}
                          styles={customStyles1}
                          onInputChange={(e) => this.filtersHops(e)}
                          onChange={(e) => {
                            this.setState({ selectedHop: e });
                          }}
                          options={this.getFilteredHops()}
                          classNamePrefix="select2-selection"
                          placeholder="Via"
                        />
                      </FormGroup>
                      <Row
                        className="mt-2"
                        style={{ marginRight: "190px", float: "right" }}
                      >
                        <Col className="text-center mt-4">
                          <Button
                            type="submit"
                            color="primary"
                            // onClick={() => this.handleSubmitSave()}
                            className=" mr-2 btn-md button-wide"
                            style={{
                              height: "40px",
                              width: "90px",
                              top: " 549px",
                              borderRadius: " 0px",
                              backgroundColor: this.props.mode
                                ? "#5E40FF"
                                : "#05284E",
                            }}
                          >
                            {userLang?.common?.SUBMIT}
                          </Button>

                          <Button
                            type="button"
                            color="secondary"
                            className=" btn-md button-wide"
                            onClick={() => this.reset(resetForm)}
                            style={{
                              height: "40px",
                              width: "90px",
                              top: " 549px",
                              borderRadius: " 0px",
                              backgroundColor: "#0AB38C",
                            }}
                          >
                            {userLang?.common?.RESET}
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  );
                }}
              </Formik>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
const mapStatetoProps = (state) => {
  const user = state.Login.user;
  return { user };
};

export default connect(mapStatetoProps, {
  createRoute,
  fetchHops,
})(RouteForm);
