import React, { Component } from "react";
import { Row, Col, Card, Button } from "reactstrap";
import Select from "react-select";
import { Grid } from "@material-ui/core";
import { InputGroup } from "rsuite";
// images
import exportIcon from "./assets/external-link.png";
import exportDarkIcon from "./assets/external-linkDark.png";
import refreshIcon from "./assets/refresh-cw.png";
// components
import Datepicker from "../../components/Common/DatePicker/DatePicker";

let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
var darkMode;
class QuickFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userLangs: undefined,
      dateValue: [null, null],
      showCalender: false,
      fromDate: null,
      toDate: null,
    };
  }
  componentDidMount = () => {
    let storedData = localStorage.getItem("myFilterData")
      ? JSON.parse(localStorage.getItem("myFilterData"))
      : null;

    let dateDashboard = this.props.dashTopFilter;

    if (
      (dateDashboard?.startDate !== undefined &&
        dateDashboard?.endDate !== undefined) ||
      (storedData &&
        storedData["FromDate"] !== undefined &&
        storedData["ToDate"] !== undefined)
    ) {
      let date1 = dateDashboard?.startDate
        ? new Date(dateDashboard.startDate)
        : null;
      let date2 = dateDashboard?.endDate
        ? new Date(dateDashboard.endDate)
        : null;

      if (!date1 || isNaN(date1.getTime())) {
        date1 = storedData?.FromDate ? new Date(storedData.FromDate) : null;
      }

      if (!date2 || isNaN(date2.getTime())) {
        date2 = storedData?.ToDate ? new Date(storedData.ToDate) : null;
      }

      this.setState({ fromDate: date1, toDate: date2 });
    }
  };
  componentDidUpdate() {
    if (
      this.state.userLangs === undefined &&
      (this.state.userLangs !== userLang || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  }
  onChangeDatePicker = (value, label) => {
    // console.log(value, label);
    // debugger
    if (label === "fromDate") {
      this.setState({ fromDate: value }, () => {
        if (this.state.fromDate !== null && this.state.toDate !== null) {
          this.props.handleChange([this.state.fromDate, this.state.toDate]);
        }
      });
    }
    if (label === "toDate") {
      this.setState({ toDate: value }, () => {
        if (this.state.fromDate !== null && this.state.toDate !== null) {
          this.props.handleChange([this.state.fromDate, this.state.toDate]);
        }
      });
    }
  };
  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let userPreferredLang = window.localStorage.getItem("userPreferredLang");
    const bodyElement = document.querySelector("body");
    const darkMode = bodyElement.classList.contains("dark__mode");
    const customStyles1 = {
      option: (styles, state) => ({
        ...styles,
        cursor: "pointer",
      }),
      input: (base) => ({
        ...base,
        color: darkMode ? "#9d9d9d" : "#505d69",
      }),
      menu: ({ width, ...css }) => ({
        ...css,
        cursor: "pointer",
        width: "100%",
        backgroundColor: darkMode ? "#171722" : "#ffffff",
        border: darkMode
          ? "0.5px solid #616161"
          : "0.5px solid rgb(204, 204, 204)",
        boxShadow: darkMode
          ? "0px 3px 12px rgba(0, 0, 0, 0.12)"
          : "0px 3px 12px rgba(0, 0, 0, 0.12)",
        // minWidth: "100%",
      }),
      control: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "26px",
          width:
            this.props.selectedTranspoterValues?.length >= 2 ? "100%" : "100%",
          minWidth: "100%",
          minHeight: "26px",
          flexWrap: "nowrap",
          backgroundColor: darkMode ? "#2E2E46" : "",
        };
      },
      placeholder: (style) => {
        return {
          ...style,
          cursor: "pointer",
          marginTop: "2px",
          height: "26px",
          minHeight: "26px",
        };
      },
      indicatorsContainer: (style) => {
        return {
          ...style,
          cursor: "pointer",
          // backgroundColor: "#8e9af8",
          height: "26px",
          minHeight: "26px",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "10px",
          minHeight: "10px",
          backgroundColor: "#cccccc",
          marginTop: "8px",
        };
      },
      dropdownIndicator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "26px",
          minHeight: "26px",
          // backgroundColor: "#0066cc",
        };
      },
    };
    return (
      <React.Fragment>
        <Card className={`${darkMode ? "darkBgQuickFilter" : ""}`}>
          <div className="quickStylesBar trip-quick-filters darkBgQuickFilter">
            <Row>
              <Col xs={12} sm={3} md={2} lg={2}>
                <div className="">
                  <Select
                    className="consent-react_selector pr-2"
                    classNamePrefix="react-select"
                    placeholder="Select ..."
                    isMulti={false}
                    // autoFocus={true}
                    styles={customStyles1}
                    onChange={this.props.handleSortOrder}
                    options={this.props.sortOrderOption}
                    value={this.props.sortOrderVal}
                    defaultValue={this.props.sortOrderVal}
                  />
                </div>
              </Col>
              <Col xs={12} sm={9} md={10} lg={10}>
                <div className="QuickDiv">
                  <div className="dateickerGrid">
                    <div
                      className="date-picker-div RsuiteDateRange OrderDateRange"
                      style={{
                        width: "70%",
                        marginLeft: "90px",
                      }}
                    >
                      <div className="datepicker-selector orderDateContainer">
                        <InputGroup>
                          <Datepicker
                            mode={darkMode}
                            value={this.state.fromDate}
                            onChangeDatePicker={this.onChangeDatePicker}
                            fromDate={true}
                            toDate={false}
                            myPlaceholder={"Start Date"}
                            dashboard={false}
                          />
                        </InputGroup>
                        <InputGroup>
                          <Datepicker
                            mode={darkMode}
                            value={this.state.toDate}
                            onChangeDatePicker={this.onChangeDatePicker}
                            fromDate={false}
                            toDate={true}
                            myPlaceholder={"End Date"}
                            dashboard={false}
                            className="orderDateIcon"
                          />
                        </InputGroup>
                      </div>
                    </div>
                  </div>
                  <div>
                    <Button
                      className="desktop-reset reset"
                      onClick={() => {
                        this.setState(
                          {
                            dateValue: [null, null],
                            fromDate: null,
                            toDate: null,
                          },
                          () => {
                            this.props.reset("topFilter");
                          }
                        );
                      }}
                    >
                      {this.state.userLangs?.common?.RESET}
                    </Button>
                  </div>
                  <div>
                    <Button
                      className="mobile-reset reset"
                      onClick={() =>
                        this.setState(
                          {
                            dateValue: [null, null],
                            fromDate: null,
                            toDate: null,
                          },
                          () => {
                            this.props.reset();
                          }
                        )
                      }
                    >
                      <img
                        className="mobile-rest-img"
                        src={refreshIcon}
                        alt=""
                      ></img>
                    </Button>
                  </div>
                  <div>
                    <div className="tooltipIcon ml-2">
                      <Button
                        disabled={!navigator.onLine}
                        onClick={() => this.props.toggleExcel()}
                        className={`iconButtons ${darkMode ? "iconDarkButtons" : ""
                          }`}
                      >
                        <img
                          style={{ margin: "-4px" }}
                          src={darkMode ? exportDarkIcon : exportIcon}
                          alt="exp"
                        />
                      </Button>
                      <span className="tooltipIcontext" style={{ left: "10%" }}>
                        &nbsp;
                        {this.state.userLangs?.invoice?.DOWNLOAD_EXCEL ??
                          "Download Excel"}
                        &nbsp;
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default QuickFilters;
