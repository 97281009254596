import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Button,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
// import Switch from "react-switch";
import { NotificationManager } from "react-notifications";
// import DateTimePicker from "react-datetime-picker";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Datetime from "react-datetime";
import moment from "moment";
import { MoonLoader } from "react-spinners";

import { connect } from "react-redux";
import {
  // fetchTrips,
  fetchTripById,
  editTrip,
  createTrip,
  fetchHops,
} from "../../store/trip/actions";
import { fetchAvailDrivers } from "../../store/driver/actions";
import { fetchProductNames } from "../../store/order/actions";
import { fetchAvailVehicles } from "../../store/vehicle/actions";
// import Axios from "axios";

class EditTripForm extends Component {
  constructor(props) {
    super(props);
    // this.handleChange =  this.handleChange.bind(this);
    this.state = {
      loading: false,
      menuIsOpen: false,
      breadcrumbItems: [
        { title: "Trip", link: "trips" },
        { title: "Edit Trip", link: "#" },
      ],
      form: {
        tripType:
          props.trips.editTrip && props.trips.editTrip.tripType
            ? props.trips.editTrip.tripType
            : "",
        startingLocation:
          props.trips.editTrip && props.trips.editTrip.startingLocation
            ? props.trips.editTrip.startingLocation
            : "",
        destination:
          props.trips.editTrip && props.trips.editTrip.destination
            ? props.trips.editTrip.destination
            : "",
        tripStatus:
          props.trips.editTrip && props.trips.editTrip.tripStatus
            ? props.trips.editTrip.tripStatus
            : "",
        driverLicNo:
          props.trips.editTrip && props.trips.editTrip.driverLicNo
            ? props.trips.editTrip.driverLicNo
            : "",
        vehicleRegNo:
          props.trips.editTrip && props.trips.editTrip.vehicleRegNo
            ? props.trips.editTrip.vehicleRegNo
            : "",
        tripOrderDetails:
          props.trips.editTrip && props.trips.editTrip.orderDetails
            ? props.trips.editTrip.orderDetails
            : "",
        // replacementVehicle: "",
        startTime: new Date(
          (props.trips.editTrip && props.trips.editTrip.startTime) || new Date()
        ),
        // endTime: moment(new Date()).add(p1, 'days').toDate()
      },
      vehicleRegNo: "",
      editValues: {},
      tripStatusErr: "",
      formErrors: {
        tripType: "",
        startingLocation: "",
        destination: "",
        // tripStatus: "",
        driverLicNo: "",
        vehicleRegNo: "",
        tripOrderDetails: [],
        // replacementVehicle: "",
        startTime: "",
        // endTime: "",
        orderDetails: [],
      },
    };
    this.tripTypeArr = [
      this.initialVal("Trip Type"),
      { value: "Material Transfer", label: "Material Transfer" },
      { value: "Last Mile", label: "Last Mile" },
    ];

    this.startingLocationArr = [
      this.initialVal("starting Location"),

      { value: "Bangalore Factory", label: "Bangalore Factory" },
      { value: "Bangalore Warehouse", label: "Bangalore Warehouse" },
    ];
    this.initialArr = [
      this.initialVal("destination"),
      { value: "Bangalore Warehouse", label: "Bangalore Warehouse" },
      { value: "Bangalore Dealer", label: "Bangalore Dealer" },
    ];
    this.destinationArr = this.initialArr;
    this.tripStatusArr = [
      this.initialVal("status"),
      { value: "ASSIGNED", label: "ASSIGNED" },
      { value: "COMPLETED", label: "COMPLETED" },
      { value: "UPCOMING", label: "UPCOMING" },
      // { value: "inactive", label: "inactive" },
    ];

    this.vehicleRegNoArr = [this.initialVal("Vehicles")];
    this.driversList = [this.initialVal("Driver")];
    this.hops = [this.initialVal("Location")];
    this.prodList = [];
    this.ordersList = [];
  }
  initialVal = (val) => {
    return { label: "Select " + val, value: "", isDisabled: true };
  };
  valid = (current) => {
    var yesterday = moment().subtract(1, "day");
    return current.isAfter(yesterday);
  };
  componentDidMount() {
    this.setState({ loading: true });
    const id = window.location.pathname.split("/edit-trip-")[1];
    this.props.fetchHops((res) => {
      if (res.length > 0) {
        res.forEach((ele) => {
          this.hops.push({
            label: ele.hopName,
            value: {
              name: ele.hopName,
              latitude: ele.cordinates[0],
              longitude: ele.cordinates[1],
            },
          });
        });
      }
    });
    this.props.fetchAvailDrivers((res) => {
      if (res.length > 0) {
        if (this.driversList.length > 1)
          this.driversList = this.driversList.slice(0, 1);

        res.forEach((ele) => {
          this.driversList.push({
            label: ele.userId.fullName,
            id: ele._id,
            value: ele._id,
          });
        });
      }
    });
    this.props.fetchProductNames((res) => {
      if (res?.length > 0) {
        this.prodList = [];
        res.forEach((ele) => {
          const dealer = ele.DealerCode ? ele.DealerCode.userId.fullName : "-";
          this.prodList.push({
            label:
              ele.ProductName +
              "( External OrderId: " +
              ele.ExternalOrderId +
              ", Dealer : " +
              dealer +
              " )",
            value:
              ele.ProductName +
              "(Price: " +
              ele.ProductPrice +
              ", Quantity: " +
              ele.Quantity +
              " )",
            _id: ele._id,
          });
        });
      }
    });
    if (/true/i.test(process.env.REACT_APP_Kurlon) === false) {
      this.props.fetchAvailVehicles((res) => {
        if (res.length > 0) {
          if (this.vehicleRegNoArr.length > 1)
            this.vehicleRegNoArr = this.vehicleRegNoArr.slice(0, 1);
          res.forEach((ele) => {
            this.vehicleRegNoArr.push({
              id: ele._id,
              label: ele.VehicleRegistrationNumber,
              value: ele._id,
            });
          });
        }
      });
    }
    this.props.fetchTripById(id, (res) => {
      const formData = {
        id: res._id,
        tripType: res.tripType,
        startingLocation: res.startingLocation,
        destination: res.destination,
        tripStatus: res.tripStatus,
        driverLicNo: res.driverLicNo,
        vehicleRegNo: res.vehicleRegNo,
        tripOrderDetails: res.orderDetails,
        startTime: new Date(res.startTime),
        // endTime: "",
      };
      // const { trips } = this.props;
      // let listValue = [];
      // if (this.prodList) {
      //   if (trips.editTrip && trips.editTrip.orderDetails) {
      //     this.prodList.map((item) => {
      //       if (
      //         trips.editTrip.orderDetails.some(
      //           (subitem) => item._id === subitem._id
      //         )
      //       ) {
      //         listValue.push(item);
      //       }
      //     });
      //   }
      // }
      this.setState({ form: formData, loading: false });
    });
    // Axios.post()
  }
  validateField = (name, value, refValue) => {
    let errorMsg = null;
    const { form } = this.state;

    switch (name) {
      case "tripStatus":
        if (!form.tripStatus) errorMsg = "Please select tripStatus.";
        break;
      default:
        break;
    }
    return errorMsg;
  };
  resetDestVal = (form) => {
    let formData = {
      startingLocation: form.startingLocation,
      destination: "",
      // tripStatus: "",
      driverLicNo: form.driverLicNo,
      vehicleRegNo: form.vehicleRegNo,
      tripOrderDetails: form.tripOrderDetails,
      // replacementVehicle: "",
      startTime: form.startTime,
      // endTime: "",
      orderDetails: form.orderDetails,
    };

    this.setState({
      form: formData,
    });
  };
  validateForm = (form, formErrors, validateFunc) => {
    const errorObj = {};
    Object.keys(formErrors).map((x) => {
      let refValue = null;

      const msg = validateFunc(x, form[x], refValue);
      if (msg) errorObj[x] = msg;
    });
    return errorObj;
  };

  handleSubmit = () => {
    const { form } = this.state;
    if (!form.tripStatus) {
      this.setState({ tripStatusErr: "Please select tripStatus." });
    } else {
      let formValues = {
        ...this.props.trips.editTrip,
        tripStatus: form.tripStatus,
      };
      this.props.editTrip(
        { id: formValues._id, tripStatus: form.tripStatus },
        (res) => {
          if (
            res["message"] &&
            res["message"] === "Trip has been successfully updated"
          ) {
            NotificationManager.success(res["message"], "Success");
          } else {
          }
          this.props.history.push("/trips");
        }
      );
    }
  };

  reset = () => {
    let formData = {
      startingLocation: "",
      destination: "",
      tripStatus: "",
      driverLicNo: "",
      vehicleRegNo: "",
      tripOrderDetails: [],
      // replacementVehicle: "",
      startTime: "",
      // endTime: "",
      orderDetails: [],
    };

    this.setState({
      form: formData,
    });
    this.props.history.push("/trips");
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    // else const { name, value }
    const { form, formErrors } = this.state;
    let formObj = {};

    formObj = {
      ...form,
      [name]: value,
    };
    this.setState({ form: formObj }, () => {
      if (!Object.keys(formErrors).includes(name)) return;
      let formErrorsObj = {};

      const errorMsg = this.validateField(
        name,
        name === "language" ? this.state.form["language"] : value
      );
      formErrorsObj = { ...formErrors, [name]: errorMsg };
      this.setState({ formErrors: formErrorsObj });
    });
  };
  changeDateTime = (e) => {
    this.setState({ form: { ...this.state.form, startTime: e } });
    // this.setState({startTime: e });
  };
  render() {
    const { form, formErrors, loading } = this.state;
    const { trips } = this.props;

    if (loading) {
      return (
        <div className="page-content">
          <Col xs={12} className="loader">
            <MoonLoader size={45} color={"#123abc"} />
          </Col>
        </div>
      );
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Edit trip"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {/* <h4 className="card-title">Add Trip</h4> */}
                    {/* <p className="card-title-desc">A mobile and touch friendly input spinner component for Bootstrap</p> */}

                    <form>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">Trip Type</Label>
                            <Select
                              name="tripType"
                              value={this.tripTypeArr.find(
                                (x) => x.value === form.tripType
                              )}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "tripType",
                                    value: e.value,
                                  },
                                })
                              }
                              options={this.tripTypeArr}
                              classNamePrefix="select2-selection"
                            />
                            {formErrors.tripType && (
                              <span className="err">{formErrors.tripType}</span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="control-label required-field">
                              Orders
                            </Label>
                            <Select
                              value={form.tripOrderDetails}
                              isMulti={true}
                              menuIsOpen={this.state.menuIsOpen}
                              onFocus={() =>
                                this.setState({ menuIsOpen: true })
                              }
                              onBlur={() =>
                                this.setState({ menuIsOpen: false })
                              }
                              onChange={(e) => {
                                this.handleChange({
                                  target: {
                                    name: "tripOrderDetails",
                                    value: e,
                                  },
                                });
                              }}
                              options={this.prodList}
                              classNamePrefix="select2-selection"
                            />
                            {formErrors.tripOrderDetails && (
                              <span className="err">
                                {formErrors.tripOrderDetails}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label>Starting Location</Label>
                            <Select
                              isDisabled={true}
                              name="startingLocation"
                              value={this.hops.find(
                                (item) =>
                                  item.value.name === form.startingLocation.name
                              )}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "startingLocation",
                                    value: e.value,
                                  },
                                })
                              }
                              options={this.hops}
                              classNamePrefix="select2-selection"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label>Destination</Label>
                            <Select
                              isDisabled={true}
                              name="destination"
                              value={this.hops.find(
                                (item) =>
                                  item.value.name === form.destination.name
                              )}
                              options={this.hops}
                              classNamePrefix="select2-selection"
                            />
                            {formErrors.destination && (
                              <span className="err">
                                {formErrors.destination}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label>Start Time</Label>
                            <Datetime
                              inputProps={{ disabled: true }}
                              closeOnSelect
                              dateFormat="DD-MM-YYYY"
                              isValidDate={this.valid}
                              value={form.startTime}
                              input={true}
                              // timeConstraints={ this.getValidTimes(this.state.dateTime) }
                            />
                            {formErrors.startTime && (
                              <span className="err">
                                {formErrors.startTime}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                        {/true/i.test(process.env.REACT_APP_VEHICLE) ? (
                          <Col lg="6">
                            <FormGroup className="select2-container">
                              <Label>Vehicles</Label>
                              <Select
                                isDisabled={true}
                                value={
                                  form.vehicleRegNo.VehicleRegistrationNumber
                                }
                                onChange={(e) => {
                                  this.handleChange({
                                    target: {
                                      name: "vehicleRegNo",
                                      value: e.value,
                                    },
                                  });
                                }}
                                options={this.vehicleRegNoArr}
                                // isOptionDisabled={(option) => option.disabled === 'yes'}
                                // classNamePrefix="select2-selection"
                              />
                              {formErrors.vehicleRegNo && (
                                <span className="err">
                                  {formErrors.vehicleRegNo}
                                </span>
                              )}
                            </FormGroup>
                          </Col>
                        ) : (
                          <Col lg="6">
                            <FormGroup className="select2-container">
                              <Label className="required-field">Drivers</Label>
                              <Select
                                value={this.driversList.find(
                                  (x) => x.value.id === form.driverLicNo.id
                                )}
                                onChange={(e) =>
                                  this.handleChange({
                                    target: {
                                      name: "driverLicNo",
                                      value: e.value,
                                    },
                                  })
                                }
                                options={this.driversList}
                                classNamePrefix="select2-selection"
                              />
                              {formErrors.driverLicNo && (
                                <span className="err">
                                  {formErrors.driverLicNo}
                                </span>
                              )}
                            </FormGroup>
                          </Col>
                        )}
                      </Row>
                      <Row>
                        {/true/i.test(process.env.REACT_APP_VEHICLE) && (
                          <Col lg="6">
                            <FormGroup className="select2-container">
                              <Label className="required-field">Drivers</Label>
                              <Select
                                value={this.driversList.find(
                                  (x) => x.value.id === form.driverLicNo.id
                                )}
                                onChange={(e) =>
                                  this.handleChange({
                                    target: {
                                      name: "driverLicNo",
                                      value: e.value,
                                    },
                                  })
                                }
                                options={this.driversList}
                                classNamePrefix="select2-selection"
                              />
                              {formErrors.driverLicNo && (
                                <span className="err">
                                  {formErrors.driverLicNo}
                                </span>
                              )}
                            </FormGroup>
                          </Col>
                        )}
                      </Row>
                      {/* <Row>
                        <Col lg="6">
                          <FormGroup>
                            <Label>Status</Label>
                            <Select
                              value={this.tripStatusArr.find(
                                (x) => x.value === form.tripStatus
                              )}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "tripStatus",
                                    value: e.value,
                                  },
                                })
                              }
                              options={this.tripStatusArr}
                              classNamePrefix="select2-selection"
                            />
                            {this.state.tripStatusErr && (
                              <span className="err">
                                {this.state.tripStatusErr}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row> */}
                      <FormGroup className="mb-0 text-center ">
                        <div>
                          <Button
                            type="reset"
                            color="secondary"
                            onClick={this.reset}
                          >
                            Cancel
                          </Button>{" "}
                          <Button
                            type="button"
                            color="btn btn-primary"
                            className="mr-1"
                            onClick={this.handleSubmit}
                          >
                            Submit
                          </Button>
                        </div>
                      </FormGroup>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
          {/* <NotificationContainer /> */}
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  trips: state.Trip.trips,
  drivers: state.Driver.drivers.availDrivers,
  productNames: state.Order.orders,
  vehicles: state.Vehicle.vehicles.avail_vehicles,
});

export default connect(mapStateToProps, {
  createTrip,
  editTrip,
  fetchHops,
  fetchTripById,
  fetchAvailDrivers,
  fetchProductNames,
  fetchAvailVehicles,
})(EditTripForm);
