import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
//medthods
import { getApi, postApi } from "../../helpers/auth_helper";

// workers
function* workOpDashboard({ filters, searchFilter, callback }) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  // console.log(searchFilter, "seacrchfilter");
  let filterString = "";
  const keyMap = {
    // Add more key-value pairs as needed
  };

  if (searchFilter !== null && searchFilter !== undefined) {
    for (const [key, value] of Object.entries(searchFilter)) {
      let filterKey = keyMap[key] || key;
      let filterValue = value;
      filterString = filterString + "&" + filterKey + "=" + filterValue;
    }
  }

  try {
    const url =
      process.env.REACT_APP_SERVERURL +
      "/dashboard/baxterOperations?offset=" +
      offset +
      "&limit=" +
      limit +
      filterString;

    const response = yield call(getApi, url);
    callback(response);
    localStorage.setItem(
      "operationalDashboard",
      response ? JSON.stringify(response) : null
    );
    yield put({
      type: types.GET_OPERATIONAL_DASHBOARD_SUCCESS,
      payload: response,
    });
  } catch (e) {
    // console.log(e);
  }
}



// watchers
export function* watchOpDashboard() {
  yield takeEvery(types.GET_OPERATIONAL_DASHBOARD, workOpDashboard);
}

function* baxterOperationalDashboardSaga() {
  yield all([fork(watchOpDashboard)]);

}
export default baxterOperationalDashboardSaga;
