// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import { types } from "./actionTypes";

import { getApi, postApi, uploadExcel } from "../../helpers/auth_helper";

function* workGetOrdersByExternalId({ id, callback }) {
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL + `/order/filter?ExternalOrderId=${id}`;
    const response = yield call(getApi, uri);
    // console.log("getOrdersByExternalId", response);
    callback(response);
    yield put({
      type: types.GET_ORDERS_BY_EXTERNAL_ID_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch order.", error);
  }
}

//new ......
function* workgetOrdersByPagination({ callback, offset, limit }) {
  try {
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/order/orders?offset=` +
      offset +
      `&limit=` +
      limit;
    const response = yield call(getApi, uri);

    callback(response);

    yield put({
      type: types.GET_ORDERS_BY_PAGINATION_SUCCESS,
      payload: response,
    });
  } catch (error) {
    console.log("Request failed! Could not confirm order.!!", error);
  }
}

function* workGetConfirmOrders({ values, callback }) {
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/trip/confirmation-orders?tripID=${values.tripID}&dealerID=${values.dealerID}`;
    const response = yield call(getApi, uri);
    callback(response);
    yield put({
      type: types.GET_CONFIRM_ORDERS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch confirm orders.", error);
  }
}

function* workConfirmOrder({ values, callback }) {
  try {
    //console.log('values', values);
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/trip/confirm-delivery`;
    const response = yield call(postApi, uri, values);
    //console.log('response',response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.CONFIRM_ORDER_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not confirm order.", error);
  }
}

function* workfetchOrders({ offset, limit, callback }) {
  try {
    // const history = createHistory();

    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/order/orders?offset=` +
      offset +
      `&limit=` +
      limit;
    const response = yield call(getApi, uri);
    // console.log('response.statusCode',response.statusCode);
    // if(response.statusCode===403)

    // history.push('/pages-404');
    // else
    callback(response);

    //console.log('FETCH_Order',response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_ORDERS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch order.", error);
  }
}

function* workfetchOrdersfilter({ offset, filters, key, callback }) {
  try {
    // const history = createHistory();

    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/order/orders?offset=` +
      offset +
      `&filters=` +
      filters +
      `&key=` +
      key;
    const response = yield call(getApi, uri);
    // console.log('response.statusCode',response.statusCode);
    // if(response.statusCode===403)

    // history.push('/pages-404');
    // else
    callback(response);

    //console.log('FETCH_Order',response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_FILTER_ORDERS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch order.", error);
  }
}
function* workfetchOrder({ id, callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/order/order/` + id;
    const response = yield call(getApi, uri);
    // console.log("response", response);
    callback(response);
    //console.log('FETCH_Order',response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_ORDER_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch order.", error);
  }
}
function* workfetchProdNames({ callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + "/order/orders/productDetail";
    const response = yield call(getApi, uri);
    callback(response);
    //console.log('FETCH_prod names',response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_PROD_NAMES_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch order.", error);
  }
}
function* workfetchProdNamesByOrderId_DealerCode({
  searchString,
  callback,
  key,
}) {
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      "/order/orders/productDetail?" +
      searchString +
      `&key=` +
      key;
    const response = yield call(getApi, uri);
    callback(response);
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch order.", error);
  }
}
function* workfetchorderDelivered({ callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + "/order/orders/delivered";
    const response = yield call(getApi, uri);
    callback(response);
    //console.log('FETCH_prod names',response);
    //   Dispatch the action to the reducers
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch order.", error);
  }
}

function* workUploadOrders({ values, callback }) {
  try {
    //console.log('values', values);
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL + "/upload/csv-order-upload/single";
    const response = yield call(uploadExcel, uri, values);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.UPLOAD_ORDER_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch order.", error);
  }
}
// function* workDeleteOrder({ id, callback }) {
//   try {
//     // Try to call the API
//     const uri = process.env.REACT_APP_SERVERURL + `/order/delete/` + id;
//     const response = yield call(deleteApi, uri);
//     //console.log('response',response);
//     callback();
//     //   Dispatch the action to the reducers
//     yield put({
//       type: types.DELETE_DRIVER_SUCCESS,
//       payload: response,
//     });
//   } catch (error) {
//     // Act on the error
//     console.log("Request failed! Could not fetch order.", error);
//   }
// }
/**
 * Watchers
 */
function* workfetchOrdersbyId({ filter, key, callback }) {
  try {
    // const history = createHistory();

    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL + `/order/filter?` + filter + `=` + key;
    const response = yield call(getApi, uri);
    // console.log('response.statusCode',response.statusCode);
    // if(response.statusCode===403)

    // history.push('/pages-404');
    // else
    callback(response);

    //console.log('FETCH_Order',response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.GET_ORDERS_BY_ID_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch order.", error);
  }
}

export function* watchgetOrdersByPagination() {
  yield takeEvery(types.GET_ORDERS_BY_PAGINATION, workgetOrdersByPagination);
}
export function* watchFetchOrders() {
  yield takeEvery(types.FETCH_ORDERS, workfetchOrders);
}
export function* watchFetchOrdersfilter() {
  yield takeEvery(types.FETCH_FILTER_ORDERS, workfetchOrdersfilter);
}
export function* watchFetchOrder() {
  yield takeEvery(types.FETCH_ORDER, workfetchOrder);
}
export function* watchFetchProdNames() {
  yield takeEvery(types.FETCH_PROD_NAMES, workfetchProdNames);
}
export function* watchFetchProdNamesByOrderId_dealerCode() {
  yield takeEvery(
    types.FETCH_PRODUCT_NAMES_BY_PRODUCT_ID_DEALER_CODE,
    workfetchProdNamesByOrderId_DealerCode
  );
}

export function* watchFetchorderDelivered() {
  yield takeEvery(types.FETCH_ORDER_DELIVERED, workfetchorderDelivered);
}
export function* watchUploadOrders() {
  yield takeEvery(types.UPLOAD_ORDER, workUploadOrders);
}
export function* watchGetOrdersByExternalId() {
  yield takeEvery(types.GET_ORDERS_BY_EXTERNAL_ID, workGetOrdersByExternalId);
}
export function* watchGetConfirmOrders() {
  yield takeEvery(types.GET_CONFIRM_ORDERS, workGetConfirmOrders);
}
export function* watchConfrimOrder() {
  yield takeEvery(types.CONFIRM_ORDER, workConfirmOrder);
}
export function* watchfetchOrdersbyId() {
  yield takeEvery(types.GET_ORDERS_BY_ID, workfetchOrdersbyId);
}
// export function* watchDeleteOrder() {
//   yield takeEvery(types.DELETE_DRIVER, workDeleteOrder);
// }
function* OrderSaga() {
  yield all([
    fork(watchgetOrdersByPagination),
    fork(watchFetchOrder),
    fork(watchFetchOrdersfilter),
    fork(watchFetchOrders),
    fork(watchFetchProdNames),
    fork(watchFetchProdNamesByOrderId_dealerCode),
    fork(watchUploadOrders),
    fork(watchGetOrdersByExternalId),
    fork(watchGetConfirmOrders),
    fork(watchConfrimOrder),
    fork(watchFetchorderDelivered),
    fork(watchfetchOrdersbyId),
    // fork(watchDeleteOrder)
  ]);
}

export default OrderSaga;
