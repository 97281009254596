import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
//medthods
import { getApi, patchApi } from "../../helpers/auth_helper";

// workers
function* workerGetBaxTripList({ filters, searchFilter, callback }) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  let cfaCodes = filters?.cfaCode ? filters?.cfaCode : "";
  // console.log(searchFilter, "seacrchfilter");
  let filterString = "";
  const keyMap = {
    Warehouse: "businessUnit",
    SubStatus: "subStatus",
    Status: "status",
    "Order Number": "orderNumber",
    "EWay Bill No": "eWayBillNo",
    "Order Co": "orderCo",
    "Order Type": "orderType",
    "billed To Name": "billedToName",
    "billed To City": "billedToCity",
    Vehicle: "vehicleRegNo",
    Driver: "driver",
    "Legal Invoice No": "legalInvoiceNo",
    "Trip Id": "tripId",
    "Is Ewaybill Not Exist":"isEwaybillDoesNotExist"
    // Add more key-value pairs as needed
  };

  if (searchFilter !== null && searchFilter !== undefined) {
    for (const [key, value] of Object.entries(searchFilter)) {
      let filterKey = keyMap[key] || key;
      let filterValue = value;
      filterString = filterString + "&" + filterKey + "=" + filterValue;
    }
  }

  try {
    const url =
      process.env.REACT_APP_SERVERURL +
      "/trip/searchBaxterTripList?offset=" +
      offset +
      "&limit=" +
      limit +
      "&cfaCodes=" +
      cfaCodes +
      filterString;

    const response = yield call(getApi, url);
    callback(response);
    localStorage.setItem(
      "TripBaxData",
      response ? JSON.stringify(response) : null
    );
    yield put({
      type: types.GET_TRIP_LIST_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerGetBaxTripListForExcel({ filters, searchFilter, callback }) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  let cfaCodes = filters?.cfaCode ? filters?.cfaCode : "";
  // console.log(searchFilter, "seacrchfilter");
  let filterString = "";
  const keyMap = {
    Warehouse: "businessUnit",
    SubStatus: "subStatus",
    Status: "status",
    "Order Number": "orderNumber",
    "EWay Bill No": "eWayBillNo",
    "Order Co": "orderCo",
    "Order Type": "orderType",
    "billed To Name": "billedToName",
    "billed To City": "billedToCity",
    Vehicle: "vehicleRegNo",
    Driver: "driver",
    "Legal Invoice No": "legalInvoiceNo",
    "Trip Id": "tripId",
    // Add more key-value pairs as needed
  };

  if (searchFilter !== null && searchFilter !== undefined) {
    for (const [key, value] of Object.entries(searchFilter)) {
      let filterKey = keyMap[key] || key;
      let filterValue = value;
      filterString = filterString + "&" + filterKey + "=" + filterValue;
    }
  }

  try {
    const url =
      process.env.REACT_APP_SERVERURL +
      "/trip/searchBaxterTripList?offset=" +
      offset +
      "&limit=" +
      limit +
      "&cfaCodes=" +
      cfaCodes +
      filterString;

    const response = yield call(getApi, url);
    callback(response);
    localStorage.setItem(
      "TripBaxData",
      response ? JSON.stringify(response) : null
    );
    yield put({
      type: types.GET_TRIP_LIST_FOR_EXCEL_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}

function* workerChangeMarkedAsArrivedStatus({ tripID, callback }) {
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL + `/trip/baxter/markAsArrivedAtSource`;
    const response = yield call(patchApi, uri, { tripId: tripID });
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.CHANGE_MARKED_AS_ARRIVED_STATUS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not change trip status.", error);
  }
}
function* workerTripQualityCheckStart({ values, callback }) {
  try {
    const uri = process.env.REACT_APP_SERVERURL + `/trip/baxter/qualityCheck`;
    const response = yield call(patchApi, uri, values);
    callback(response);
    yield put({
      type: types.QUALITY_CHECK_START_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not fetch driver.", error);
  }
}
function* workerLoadingCompleted({ tripIDVal, callback }) {
  try {
    const uri =
      process.env.REACT_APP_SERVERURL + `/trip/baxter/loadingCompleted`;
    const response = yield call(patchApi, uri, tripIDVal);
    callback(response);
    yield put({
      type: types.LOADING_COMPLETED_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not fetch driver.", error);
  }
}

function* workerTripHistoryDetail({ id, callback }) {
  try {
    const uri =
      process.env.REACT_APP_SERVERURL + `/trip/baxter/tripDetails/${id}`;
    const response = yield call(getApi, uri);
    callback(response);
    yield put({
      type: types.VIEW_TRIP_HISTORY_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not fetch driver.", error);
  }
}

function* workerCompleteTrip({ Values, callback }) {
  try {
    const uri = process.env.REACT_APP_SERVERURL + `/trip/baxter/completed`;
    const response = yield call(patchApi, uri, Values);
    callback(response);
    yield put({
      type: types.COMPLETE_TRIP_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not fetch driver.", error);
  }
}

function* workerStartLoading({ tripIDVal, callback }) {
  try {
    // console.log("tripID ", tripIDVal);
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/trip/baxter/startLoading`;
    const response = yield call(patchApi, uri, tripIDVal);
    callback(response);
    // console.log("response test", response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.START_LOADING_STATUS_SUCCESS,
      payload: tripIDVal,
    });
  } catch (error) {
    // Act on the error
    //consolelog("Request failed! Could not fetch posts.", error);
  }
}
function* workerUpdateMobileOnTripTrack({ Values, callback }) {
  try {
    const uri = process.env.REACT_APP_SERVERURL + `/trip/baxter/updateDriver`;
    const response = yield call(patchApi, uri, Values);
    callback(response);
    yield put({
      type: types.UPDATE_MOBILE_TRACK_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not fetch driver.", error);
  }
}
// watchers
export function* watchBaxTripList() {
  yield takeEvery(types.GET_TRIP_LIST, workerGetBaxTripList);
}
export function* watchBaxTripListForExcel() {
  yield takeEvery(types.GET_TRIP_LIST_FOR_EXCEL, workerGetBaxTripListForExcel);
}
export function* watchChangeMarkedAsArrivedStatus() {
  yield takeEvery(
    types.CHANGE_MARKED_AS_ARRIVED_STATUS,
    workerChangeMarkedAsArrivedStatus
  );
}
export function* watchTripQualityCheckStart() {
  yield takeEvery(types.QUALITY_CHECK_START, workerTripQualityCheckStart);
}
export function* watchStartLoading() {
  yield takeEvery(types.START_LOADING_STATUS, workerStartLoading);
}

export function* watchLoadingCompleted() {
  yield takeEvery(types.LOADING_COMPLETED, workerLoadingCompleted);
}

export function* watchTripHistoryDetail() {
  yield takeEvery(types.VIEW_TRIP_HISTORY, workerTripHistoryDetail);
}
export function* watchCompleteTrip() {
  yield takeEvery(types.COMPLETE_TRIP, workerCompleteTrip);
}

export function* watchUpdateMobileOnTripTrack() {
  yield takeEvery(types.UPDATE_MOBILE_TRACK, workerUpdateMobileOnTripTrack);
}

function* BaxTripListSaga() {
  yield all([fork(watchBaxTripList)]);
  yield all([fork(watchBaxTripListForExcel)]);
  yield all([fork(watchChangeMarkedAsArrivedStatus)]);
  yield all([fork(watchTripQualityCheckStart)]);
  yield all([fork(watchStartLoading)]);
  yield all([fork(watchLoadingCompleted)]);
  yield all([fork(watchTripHistoryDetail)]);
  yield all([fork(watchCompleteTrip)]);
  yield all([fork(watchUpdateMobileOnTripTrack)]);
}
export default BaxTripListSaga;
