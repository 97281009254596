import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, h5, Button } from "reactstrap";
import moment from "moment";
import {
  NonVehiclePlacementChargesHeaders,
  MultiPointPickChargesHeaders,
  DetentionChargesAtSourceHeaders,
  getLocalStorage,
} from "./AddAgreement/AgreementForms/DatatableHeaders";
// import NonVehiclePlacementCharges from "./AddAgreement/AgreementForms/NonVehiclePlacementCharges";
// import MultiPointPickCharges from "./AddAgreement/AgreementForms/MultiPointPickCharges";
// import MultiPointDropCharges from "./AddAgreement/AgreementForms/MultiPointDropCharges";
// import DetentionChargesAtDestination from "./AddAgreement/AgreementForms/DetentionChargesAtDestination";
// import DetentionChargesAtSource from "./AddAgreement/AgreementForms/DetentionChargesAtSource";
// import LateDeliveryCharges from "./AddAgreement/AgreementForms/LateDeliveryCharges";
// import CancellationUponArrivalCompany from "./AddAgreement/AgreementForms/CancellationUponArrivalCompany";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";

import DynamicTable from "../../components/Common/DynamicTable";

var vehicleNonPlacementChargesData = {};
var MultiPointPickChargesData = {};
var MultiPointDropChargesData = {};
var detentionChargesAtSourceData = {};
var detentionChargesAtDestinationData = {};
var lateDeliveryChargesData = {};
var cancellationUponArrivalCompanyData = {};
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class AggreementPdf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicleNonPlacementChargesList: [],
      vehicleNonPlacementChargesDataListID: [],
      MultiPointPickChargesList: [],
      MultiPointPickChargesDataListID: [],
      MultiPointDropChargesList: [],
      MultiPointDropChargesDataListID: [],
      detentionChargesAtSourceList: [],
      detentionChargesAtSourceDataListID: [],
      detentionChargesAtDestinationList: [],
      detentionChargesAtDestinationDataListID: [],
      lateDeliveryChargesList: [],
      lateDeliveryChargesDataListID: [],
      cancellationUponArrivalCompanyList: [],
      cancellationUponArrivalCompanyDataListID: [],
      startDate: "",
      endDate: "",
    };
  }
  componentDidUpdate = () => {
    this.vehicleNonPlacementChargesData();
    this.MultiPointPickChargesData();
    this.MultiPointDropChargesData();
    this.detentionChargesAtDestinationData();
    this.detentionChargesAtSourceData();
    this.lateDeliveryChargesData();
    this.cancellationUponArrivalCompanyData();
    //  this.pdfDownload()
  };
  vehicleNonPlacementChargesData = () => {
    //console.log("vehicle")
    let arr = [];
    let arrID = [];
    if (
      this.props.billing.copiedAgreementValues.id &&
      !this.state.vehicleNonPlacementChargesList.length
    ) {
      if (
        this.props.billing.copiedAgreementValues.charges.vehicleNonPlacement
          .length
      ) {
        this.props.billing.copiedAgreementValues.charges.vehicleNonPlacement.forEach(
          (item, index) => {
            arr.push({
              vehicleType: item.vehicleType.label,
              charges: item.charges,
              startDate: moment(item.startDate).format("l"),
              endDate: moment(item.endDate).format("l"),
            });
            arrID.push({
              vehicleType: item.vehicleType._id,
              charges: item.charges,
              startDate: moment(item.startDate).format("l"),
              endDate: moment(item.endDate).format("l"),
            });
          }
        );
        this.setState({
          vehicleNonPlacementChargesList: arr,
          vehicleNonPlacementChargesDataListID: arrID,
        });
      }
    }
  };
  //MPPC

  MultiPointPickChargesData = () => {
    let arr = [];
    let arrID = [];
    if (
      this.props.billing.copiedAgreementValues.id &&
      !this.state.MultiPointPickChargesList.length
    ) {
      if (
        this.props.billing.copiedAgreementValues.charges.multipointPickup.length
      ) {
        this.props.billing.copiedAgreementValues.charges.multipointPickup.forEach(
          (item, index) => {
            //console.log(item, index);
            arr.push({
              destination: item.destination.label,
              source: item.source.label,
              charges: item.charges,
              startDate: moment(item.startDate).format("l"),
              endDate: moment(item.endDate).format("l"),
            });
            arrID.push({
              destination: item.destination._id,
              source: item.source._id,
              charges: item.charges,
              startDate: item.startDate,
              endDate: item.endDate,
            });
          }
        );
        this.setState({
          MultiPointPickChargesList: arr,
          MultiPointPickChargesDataListID: arrID,
        });
      }
    }
  };

  //MPDC

  MultiPointDropChargesData = () => {
    let arr = [];
    let arrID = [];
    if (
      this.props.billing.copiedAgreementValues.id &&
      !this.state.MultiPointDropChargesList.length
    ) {
      if (
        this.props.billing.copiedAgreementValues.charges.multipointDrop.length
      ) {
        this.props.billing.copiedAgreementValues.charges.multipointDrop.forEach(
          (item, index) => {
            //console.log(item, index);
            arr.push({
              destination: item.destination.label,
              source: item.source.label,
              charges: item.charges,
              startDate: moment(item.startDate).format("l"),
              endDate: moment(item.endDate).format("l"),
            });
            arrID.push({
              destination: item.destination._id,
              source: item.source._id,
              charges: item.charges,
              startDate: item.startDate,
              endDate: item.endDate,
            });
          }
        );
        this.setState({
          MultiPointDropChargesList: arr,
          MultiPointDropChargesDataListID: arrID,
        });
      }
    }
  };

  //DCS

  detentionChargesAtSourceData = () => {
    let arr = [];
    let arrID = [];
    if (
      this.props.billing.copiedAgreementValues.id &&
      !this.state.detentionChargesAtSourceList.length
    ) {
      if (
        this.props.billing.copiedAgreementValues.charges.sourceDetention.length
      ) {
        this.props.billing.copiedAgreementValues.charges.sourceDetention.forEach(
          (item, index) => {
            //console.log(item, index);
            let duration_value;
            if (item.duration.start === 0) {
              duration_value = "12:00 AM - 12:00 AM";
            } else duration_value = "3:00 PM - 12:00 AM";
            arr.push({
              vehicleType: item.vehicleType.label,
              charges: item.charges,
              day: item.day,
              startDate: moment(item.startDate).format("l"),
              endDate: moment(item.endDate).format("l"),
              timePeriod: duration_value,
            });
            arrID.push({
              vehicleType: item.vehicleType._id,
              charges: item.charges,
              day: item.day,
              startDate: item.startDate,
              endDate: item.endDate,
              duration: item.duration,
            });
          }
        );
        this.setState({
          detentionChargesAtSourceList: arr,
          detentionChargesAtSourceDataListID: arrID,
        });
      }
    }
  };

  detentionChargesAtDestinationData = () => {
    let arr = [];
    let arrID = [];
    if (
      this.props.billing.copiedAgreementValues.id &&
      !this.state.detentionChargesAtDestinationList.length
    ) {
      if (
        this.props.billing.copiedAgreementValues.charges.destinationDetention
          .length
      ) {
        this.props.billing.copiedAgreementValues.charges.destinationDetention.forEach(
          (item, index) => {
            //console.log(item, index);
            let duration_value;
            if (item.duration.start === 0) {
              duration_value = "12:00 AM - 12:00 AM";
            } else duration_value = "3:00 PM - 12:00 AM";
            arr.push({
              vehicleType: item.vehicleType.label,
              charges: item.charges,
              day: item.day,
              startDate: moment(item.startDate).format("l"),
              endDate: moment(item.endDate).format("l"),
              timePeriod: duration_value,
            });
            arrID.push({
              vehicleType: item.vehicleType._id,
              charges: item.charges,
              day: item.day,
              startDate: item.startDate,
              endDate: item.endDate,
              duration: item.duration,
            });
          }
        );
        this.setState({
          detentionChargesAtDestinationList: arr,
          detentionChargesAtDestinationDataListID: arrID,
        });
      }
    }
  };

  //LDC

  lateDeliveryChargesData = () => {
    let arr = [];
    let arrID = [];
    if (
      this.props.billing.copiedAgreementValues.id &&
      !this.state.lateDeliveryChargesList.length
    ) {
      if (
        this.props.billing.copiedAgreementValues.charges.destinationDetention
          .length
      ) {
        this.props.billing.copiedAgreementValues.charges.destinationDetention.forEach(
          (item, index) => {
            //console.log(item, index);
            let duration_value;
            if (item.duration.start === 0) {
              duration_value = "12:00 AM - 12:00 AM";
            } else duration_value = "3:00 PM - 12:00 AM";
            arr.push({
              vehicleType: item.vehicleType.label,
              charges: item.charges,
              day: item.day,
              startDate: moment(item.startDate).format("l"),
              endDate: moment(item.endDate).format("l"),
              timePeriod: duration_value,
            });
            arrID.push({
              vehicleType: item.vehicleType._id,
              charges: item.charges,
              day: item.day,
              startDate: item.startDate,
              endDate: item.endDate,
              duration: item.duration,
            });
          }
        );
        this.setState({
          lateDeliveryChargesList: arr,
          lateDeliveryChargesDataListID: arrID,
        });
      }
    }
  };

  //CAC

  cancellationUponArrivalCompanyData = () => {
    let arr = [];
    let arrID = [];
    if (
      this.props.billing.copiedAgreementValues.id &&
      !this.state.cancellationUponArrivalCompanyList.length
    ) {
      if (
        this.props.billing.copiedAgreementValues.charges
          .cancellationUponArrivalByCompany.length
      ) {
        this.props.billing.copiedAgreementValues.charges.cancellationUponArrivalByCompany.forEach(
          (item, index) => {
            //console.log(item, index);
            arr.push({
              vehicleType: item.vehicleType.label,
              charges: item.charges,
              startDate: moment(item.startDate).format("l"),
              endDate: moment(item.endDate).format("l"),
            });
            arrID.push({
              vehicleType: item.vehicleType._id,
              charges: item.charges,
              startDate: item.startDate,
              endDate: item.endDate,
            });
          }
        );
        this.setState({
          cancellationUponArrivalCompanyList: arr,
          cancellationUponArrivalCompanyDataListID: arrID,
        });
      }
    }
  };
  pdfDownload = () => {
    // let pdf =new jsPDF('p', 'pt', 'letter');
    // pdf.setFontSize(8);
    // setTimeout(function(quality = 1){
    //   pdf.html(document.getElementById("printagreement"),{pagesplit:true,margins:[10,10,10,10],callback:function  (pdf) {
    //       //console.log(document.getElementById("printagreement"))
    //     // pdf.save()
    //     window.open(pdf.output('bloburl'))

    //   }});
    setTimeout(function () {
      let quotes = document.getElementById("printagreement");
      html2canvas(quotes).then((canvas) => {
        //! MAKE YOUR PDF
        let pdf = new jsPDF("p", "pt", "letter", true);

        for (let i = 0; i <= quotes.clientHeight / 980; i++) {
          // console.log(quotes.clientHeight);
          //! This is all just html2canvas stuff
          let srcImg = canvas;
          let sX = 0;
          let sY = 1140 * i; // start 980 pixels down for every new page
          let sWidth = 1100;
          let sHeight = 1140;
          let dX = 0;
          let dY = 0;
          let dWidth = 1100;
          let dHeight = 1140;
          window.onePageCanvas = document.createElement("canvas");
          window.onePageCanvas.setAttribute("width", 1100);
          window.onePageCanvas.setAttribute("height", 1140);
          let ctx = window.onePageCanvas.getContext("2d");
          // details on this usage of this function:
          // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Using_images#Slicing
          ctx.drawImage(
            srcImg,
            sX,
            sY,
            sWidth,
            sHeight,
            dX,
            dY,
            dWidth,
            dHeight
          );

          // document.body.appendChild(canvas);
          let canvasDataURL = window.onePageCanvas.toDataURL("image/png", 1.0);

          let width = window.onePageCanvas.width;
          let height = window.onePageCanvas.clientHeight;

          //! If we're on anything other than the first page,
          // add another page
          if (i > 0) {
            pdf.addPage(); //8.5" x 11" in pts (in*72)
          }
          //! now we declare that we're working on that page
          pdf.setPage(i + 1);
          //! now we add content to that page!
          pdf.addImage(
            canvasDataURL,
            "PNG",
            10,
            10,
            width * 0.62,
            height * 0.5
          );
        }

        //  pdf.save('agreement.pdf')
        //  window.open(pdf.output('bloburl'))

        pdf.output("save", "agreement.pdf"); //Try to save PDF as a file (not works on ie before 10, and some mobile devices)
        pdf.output("datauristring");
        pdf.output("dataurlnewwindow");
      });
    }, 2000);
  };

  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    var darkMode = mode === "dark__mode" ? true : false;
    vehicleNonPlacementChargesData = {
      columns: NonVehiclePlacementChargesHeaders,
      rows: this.state.vehicleNonPlacementChargesList,
    };
    MultiPointPickChargesData = {
      columns: MultiPointPickChargesHeaders,
      rows: this.state.MultiPointPickChargesList,
    };
    MultiPointDropChargesData = {
      columns: MultiPointPickChargesHeaders,
      rows: this.state.MultiPointDropChargesList,
    };
    detentionChargesAtSourceData = {
      columns: DetentionChargesAtSourceHeaders,
      rows: this.state.detentionChargesAtSourceList,
    };
    detentionChargesAtDestinationData = {
      columns: DetentionChargesAtSourceHeaders,
      rows: this.state.detentionChargesAtDestinationList,
    };
    lateDeliveryChargesData = {
      columns: DetentionChargesAtSourceHeaders,
      rows: this.state.lateDeliveryChargesList,
    };
    cancellationUponArrivalCompanyData = {
      columns: NonVehiclePlacementChargesHeaders,
      rows: this.state.cancellationUponArrivalCompanyList,
    };
    const customStylesTable = {
      title: (style) => {
        return {
          ...style,
          fontFamily: "Roboto",
          color: darkMode ? "#9D9D9D" : "#505d69",
          fontWeight: 700,
        };
      },
      table: (style) => {
        return {
          ...style,
          textAlign: "center",
          fontFamily: "Roboto",
          fontStyle: "normal",
          fontSize: "12px",
          fontWeight: 400,
          borderCollapse: "collapse",
          border: "3px solid #ddd",
          width: "100%",
          marginBottom: "1rem",
        };
      },
      td: (style) => {
        return { ...style, padding: "3px", color: "#505d69" };
      },
      tr: (style) => {
        return { ...style, border: "1px solid #ddd" };
      },
      th: (style) => {
        return {
          ...style,
          border: "1px solid #ddd",
          padding: "3px",
          paddingTop: "6px",
          paddingBottom: "6px",
          textAlign: "center",
          backgroundColor: "#505d69",
          color: "white",
        };
      },
    };

    return (
      <React.Fragment>
        <div>
          {getLocalStorage()}
          <MDBContainer>
            <MDBModal
              isOpen={this.props.isOpen}
              toggle={this.props.closePdf}
              size="lg"
            >
              <MDBModalHeader toggle={this.props.closePdf}></MDBModalHeader>
              <MDBModalBody>
                <div>
                  <Col lg="12" style={{ textAlign: "right" }}>
                    <Button
                      onClick={() => {
                        this.pdfDownload();
                      }}
                      color="primary"
                    >
                      {userLang?.common?.DOWNLOAD_PDF ?? "Download PDF"}
                    </Button>
                  </Col>
                  <div id="printagreement">
                    <table className="pdfTable paddingTable">
                      <thead>
                        <tr>
                          <th></th>
                        </tr>
                      </thead>
                      <tfoot>
                        <tr>
                          <td></td>
                        </tr>
                      </tfoot>
                      <tbody>
                        <tr>
                          <td>
                            <Row style={{ margin: "5px" }} width="100%">
                              <Col style={{ paddingLeft: "0px" }}>
                                <h5 className="light__span">
                                  {userLang?.invoice?.AGREEMENT_CHARGES_COPY}
                                </h5>
                              </Col>
                            </Row>
                            <Row style={{ margin: "5px" }} width="100%">
                              <Col sm="4" style={{ paddingLeft: "0px" }}>
                                <h6>{userLang?.common?.TRANSPORTER_NAME}</h6>
                                <p>
                                  {this.props.transporter?.transporter ||
                                    this.props.transporter}
                                </p>
                              </Col>
                              <Col sm="4" style={{ paddingLeft: "0px" }}>
                                <h6>{userLang?.common?.START_DATE}</h6>
                                <p>
                                  {this.props.transporter?.startTime ||
                                    this.props.startTime}
                                </p>
                              </Col>
                              <Col sm="4" style={{ paddingLeft: "0px" }}>
                                <h6>{userLang?.common?.EXPIRY_DATE}</h6>
                                <p>
                                  {this.props.transporter?.expiryTime ||
                                    this.props.expiryTime}
                                </p>
                              </Col>
                            </Row>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <DynamicTable
                              styles={customStylesTable}
                              title={
                                userLang?.invoice?.VEHICLE_NON_PLACEMENT_CHARGES
                              }
                              data={
                                this.props.vehicleNonPlacementChargesData ||
                                vehicleNonPlacementChargesData
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <DynamicTable
                              styles={customStylesTable}
                              title={
                                userLang?.invoice?.MULTI_POINT_PICK_CHARGES
                              }
                              data={
                                this.props.MultiPointPickChargesData ||
                                MultiPointPickChargesData
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <DynamicTable
                              styles={customStylesTable}
                              title={userLang?.invoice?.MULT_POINT_DROP_CHARGES}
                              data={
                                this.props.MultiPointDropChargesData ||
                                MultiPointDropChargesData
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <DynamicTable
                              styles={customStylesTable}
                              title={
                                userLang?.invoice?.DETENTION_CHARGES_AT_SOURCE
                              }
                              data={
                                this.props.detentionChargesAtSourceData ||
                                detentionChargesAtSourceData
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <DynamicTable
                              styles={customStylesTable}
                              title={
                                userLang?.invoice
                                  ?.DETENTION_CHARGES_AT_DESTINATON
                              }
                              data={
                                this.props.detentionChargesAtDestinationData ||
                                detentionChargesAtDestinationData
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <DynamicTable
                              styles={customStylesTable}
                              title={userLang?.invoice?.LATE_DELIVERY_CHARGES}
                              data={
                                this.props.lateDeliveryChargesData ||
                                lateDeliveryChargesData
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <DynamicTable
                              styles={customStylesTable}
                              title={
                                userLang?.invoice
                                  ?.CANCELLATION_UPON_ARRIVAL_COMPANY
                              }
                              data={
                                this.props.cancellationUponArrivalCompanyData ||
                                cancellationUponArrivalCompanyData
                              }
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </MDBModalBody>
            </MDBModal>
          </MDBContainer>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  billing: state.Billing.billing,
});
export default connect(mapStateToProps, {})(withRouter(AggreementPdf));
