// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  TOGGLE_RIGHT_SIDEBAR,
  SHOW_RIGHT_SIDEBAR,
  HIDE_RIGHT_SIDEBAR,
  types,
} from "./actionTypes";

import {
  changeSidebarType as changeSidebarTypeAction,
  changeTopbarTheme as changeTopbarThemeAction,
} from "./actions";

import { getApi, postApi} from "../../helpers/auth_helper";

/**
 * Changes the body attribute
 */
function changeBodyAttribute(attribute, value) {
  if (document.body) document.body.setAttribute(attribute, value);
  return true;
}

/**
 * Toggle the class on body
 * @param {*} cssClass
 */
function manageBodyClass(cssClass, action = "toggle") {
  switch (action) {
    case "add":
      if (document.body) document.body.classList.add(cssClass);
      break;
    case "remove":
      if (document.body) document.body.classList.remove(cssClass);
      break;
    default:
      if (document.body) document.body.classList.toggle(cssClass);
      break;
  }

  return true;
}

/**
 * Changes the layout type
 * @param {*} param0
 */
function* changeLayout({ payload: layout }) {
  try {
    if (layout === "horizontal") {
      yield put(changeTopbarThemeAction("dark"));
      document.body.removeAttribute("data-sidebar");
      document.body.removeAttribute("data-sidebar-size");
    } else {
      yield put(changeTopbarThemeAction("light"));
    }
    yield call(changeBodyAttribute, "data-layout", layout);
  } catch (error) {}
}

/**
 * Changes the layout width
 * @param {*} param0
 */
function* changeLayoutWidth({ payload: { width, layoutType } }) {
  try {
    if (layoutType === "vertical") {
      if (width === "boxed") {
        yield put(changeSidebarTypeAction("icon"));
      } else {
        yield put(changeSidebarTypeAction("default"));
      }
    }
    yield call(changeBodyAttribute, "data-layout-size", width);
  } catch (error) {}
}

/**
 * Changes the left sidebar theme
 * @param {*} param0
 */
function* changeLeftSidebarTheme({ payload: { theme, layoutType } }) {
  try {
    yield call(changeBodyAttribute, "data-sidebar", theme);
    if (layoutType === "vertical") {
      if (theme === "light")
        //Fire action for changing dark theme of topbar
        yield put(changeTopbarThemeAction("dark"));
      if (theme === "dark")
        //Fire action for changing light theme of topbar
        yield put(changeTopbarThemeAction("light"));
    }
  } catch (error) {}
}

/**
 * Changes the topbar theme
 * @param {*} param0
 */
function* changeTopbarTheme({ payload: theme }) {
  try {
    yield call(changeBodyAttribute, "data-topbar", theme);
  } catch (error) {}
}

/**
 * Changes the left sidebar type
 * @param {*} param0
 */
function* changeLeftSidebarType({ payload: { sidebarType, isMobile } }) {
  try {
    switch (sidebarType) {
      case "compact":
        yield call(changeBodyAttribute, "data-sidebar-size", "small");
        yield call(manageBodyClass, "sidebar-enable", "remove");
        yield call(manageBodyClass, "vertical-collpsed", "remove");
        break;
      case "icon":
        yield call(changeBodyAttribute, "data-keep-enlarged", "true");
        yield call(manageBodyClass, "vertical-collpsed", "add");
        break;
      case "condensed":
        yield call(manageBodyClass, "sidebar-enable", "add");
        if (!isMobile) yield call(manageBodyClass, "vertical-collpsed", "add");
        break;
      default:
        yield call(changeBodyAttribute, "data-sidebar-size", "");
        yield call(manageBodyClass, "sidebar-enable", "remove");
        if (!isMobile)
          yield call(manageBodyClass, "vertical-collpsed", "remove");
        break;
    }
  } catch (error) {}
}

/**
 * Toggles the rightsidebar
 */
function* toggleRightSidebar() {
  try {
    yield call(manageBodyClass, "right-bar-enabled");
  } catch (error) {}
}

/**
 * Show the rightsidebar
 */
function* showRightSidebar() {
  try {
    yield call(manageBodyClass, "right-bar-enabled", "add");
  } catch (error) {}
}

/**
 * Hides the rightsidebar
 */
function* hideRightSidebar() {
  try {
    yield call(manageBodyClass, "right-bar-enabled", "remove");
  } catch (error) {}
}

function* workfetchTransporterSearch({
  offset,
  source,
  destination,
  vehicleType,
  route = "",
  callback,
}) {
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/transporter/searchBySpecs?source=` +
      source +
      `&destination=` +
      destination +
      `&route=` +
      route +
      `&vehicleType=` +
      vehicleType;
    const response = yield call(getApi, uri);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_TRANSPORTER_SEARCH_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch posts.", error);
  }
}

function* workfetchAllTransporterSearch({ offset, limit, filter, callback }) {
  try {
    // Try to call the API
    // const uri =
    //   process.env.REACT_APP_SERVERURL +
    //   `/transporter/search?offset=` +
    //   offset +
    //   `&limit=` +
    //   limit;
    let filterString = filter ? filter : "";
    // console.log("filterString ",filterString )
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/transporter/search?offset=` +
      Number(offset) +
      `&limit=` +
      limit +
      `&q=` +
      filterString;
    const response = yield call(getApi, uri);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_ALL_TRANSPORTERS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch posts.", error);
  }
}

function* workfetchTransporters({ offset, filters, callback }) {
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/transporter/transporters?offset=` +
      offset +
      `&limit=` +
      process.env.REACT_APP_PERPAGE +
      `&filters=` +
      filters;
    const response = yield call(getApi, uri);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_TRANSPORTERS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch posts.", error);
  }
}
function* workgetTransporters({ callback }) {
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL + `/transporter/getTransporters`;
    const response = yield call(getApi, uri);

    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.GET_TRANSPORTERS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch posts.", error);
  }
}

function* workfetchHops({ callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/hop/filterhop`;
    const response = yield call(getApi, uri);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_HOPS_LIST_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch hops.", error);
  }
}

function* workCreateTransporter({ values, callback }) {
  try {
    //console.log('values', values);
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/transporter/create`;
    const response = yield call(postApi, uri, values);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.CREATE_TRANSPORTER_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not create trip.", error);
  }
}

function* workfetchTransportersforIndent({ callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/organization/organizations`;
    const response = yield call(getApi, uri);

    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_TRANSPORTERS_FOR_INDENT_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch posts.", error);
  }
}

/**
 * Watchers
 */
export function* watchChangeLayoutType() {
  yield takeEvery(CHANGE_LAYOUT, changeLayout);
}

export function* watchChangeLayoutWidth() {
  yield takeEvery(CHANGE_LAYOUT_WIDTH, changeLayoutWidth);
}

export function* watchChangeLeftSidebarTheme() {
  yield takeEvery(CHANGE_SIDEBAR_THEME, changeLeftSidebarTheme);
}

export function* watchChangeLeftSidebarType() {
  yield takeEvery(CHANGE_SIDEBAR_TYPE, changeLeftSidebarType);
}

export function* watchChangeTopbarTheme() {
  yield takeEvery(CHANGE_TOPBAR_THEME, changeTopbarTheme);
}

export function* watchToggleRightSidebar() {
  yield takeEvery(TOGGLE_RIGHT_SIDEBAR, toggleRightSidebar);
}

export function* watchShowRightSidebar() {
  yield takeEvery(SHOW_RIGHT_SIDEBAR, showRightSidebar);
}

export function* watchHideRightSidebar() {
  yield takeEvery(HIDE_RIGHT_SIDEBAR, hideRightSidebar);
}

export function* watchFetchHops() {
  yield takeEvery(types.FETCH_HOPS_LIST, workfetchHops);
}
export function* watchFetchTransporters() {
  yield takeEvery(types.FETCH_TRANSPORTERS, workfetchTransporters);
}
export function* watchGetTransporters() {
  yield takeEvery(types.GET_TRANSPORTERS, workgetTransporters);
}
export function* watchCreateTransporter() {
  yield takeEvery(types.CREATE_TRANSPORTER, workCreateTransporter);
}
export function* watchfetchTransportersforIndent() {
  yield takeEvery(
    types.FETCH_TRANSPORTERS_FOR_INDENT,
    workfetchTransportersforIndent
  );
}
export function* watchFetchTransporterSearch() {
  yield takeEvery(types.FETCH_TRANSPORTER_SEARCH, workfetchTransporterSearch);
}

export function* watchFetchAllTransporterSearch() {
  yield takeEvery(
    types.FETCH_ALL_TRANSPORTER_SEARCH,
    workfetchAllTransporterSearch
  );
}
function* TransporterSaga() {
  yield all([
    fork(watchChangeLayoutType),
    fork(watchChangeLayoutWidth),
    fork(watchChangeLeftSidebarTheme),
    fork(watchChangeLeftSidebarType),
    fork(watchToggleRightSidebar),
    fork(watchShowRightSidebar),
    fork(watchHideRightSidebar),
    fork(watchChangeTopbarTheme),
    fork(watchFetchHops),
    fork(watchCreateTransporter),
    fork(watchFetchTransporters),
    fork(watchGetTransporters),
    fork(watchfetchTransportersforIndent),
    fork(watchFetchTransporterSearch),
    fork(watchFetchAllTransporterSearch),
    // fork(watchEditTrip),
  ]);
}

export default TransporterSaga;
