import { types } from "./actionTypes";
const initialState = {
  getCustomerDetails: null,
};
const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CUSTOMERS_SUCCESS:
      state = {
        ...state,
        getCustomerDetails: action.payload,
      };
      return state;
    default:
      return state;
  }
};
export default customerReducer;
