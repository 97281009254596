import React, { Component } from "react";
import { Row, Col, Card } from "reactstrap";

class SecondaryQuickSearchFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    let mode = localStorage.getItem("Theme");
    return (
      <React.Fragment>
        <Card
          style={{ height: "160px", margin: "16px 8px" }}
          className={`${mode === "dark__mode" ? "darkBgQuickFilter" : ""}`}
        >
          <div className="quickStylesBar">
            <Row>
              <Col sm={9}></Col>
            </Row>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default SecondaryQuickSearchFilters;
