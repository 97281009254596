import React from "react";
import { Button } from "reactstrap";
import actionVerticleDots from "../Trips/assets/more-vertical.png";


export const actions = (ele, concentStatusFunc) => {
  // console.log(ele, "--- status");
  return ele.consentProvided ? (
    <>
      <div className='dropdown'>
        <Button className='iconButtons dropbtn actionButtonsDark'>
          <img
            style={{ margin: '-4px' }}
            src={actionVerticleDots}
            alt='actions'
          />
        </Button>
        <div className='dropdown-content contentDark'>
          <div
            className='action-button actionDspan'
            onClick={() => {
              concentStatusFunc.consentUpdate(ele)
            }}
          >
            Stop Consent{' '}
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className='dropdown'>
        <Button className='iconButtons dropbtn actionButtonsDark'>
          <img
            style={{ margin: '-4px' }}
            src={actionVerticleDots}
            alt='actions'
          />
        </Button>
        <div className='dropdown-content contentDark'>
          <div
            className='action-button actionDspan'
            onClick={() => {
              concentStatusFunc.consentUpdate(ele)
            }}
          >
            Start Consent{' '}
          </div>
        </div>
      </div>
    </>
  )
 
  
};
