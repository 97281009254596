import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchTripById } from "../../store/trip/actions";
import { trackLocationTripById } from "../../store/trip/actions";
import { MDBContainer } from "mdbreact";
import SecondaryQualityData from "./SecondaryQualityData";

import { MDBModal, MDBModalBody } from "mdbreact";

class SecondaryQualityDetailModel extends Component {
  constructor() {
    super();
    this.state = {
      tripId: "",
      id: "",

      collapse: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount = () => {
    // let tripId = this.props?.tripIds;
  };

  toggle = (index) => {
    this.setState((prevState) => ({
      expandIndex: prevState.expandIndex !== index ? index : "",
    }));
  };

  render() {
    let mode = localStorage.getItem("Theme");
    var darkState = mode === "dark__mode" ? true : false;

    return (
      <div className="tripModel">
        <React.Fragment>
          <MDBContainer className="text-capitalize">
            <MDBModal
              isOpen={this.props.isOpen}
              toggle={() => this.props.toggleModel()}
              size="xl"
            >
              <MDBModalBody className="indentModal">
                <SecondaryQualityData
                  id={this.props.tripIds}
                  closeModal={this.props.toggleModel}
                  darkTheme={darkState}
                />
              </MDBModalBody>
              {/* <MDBModalFooter>
            footer
            </MDBModalFooter> */}
            </MDBModal>
          </MDBContainer>
        </React.Fragment>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    tripById: state.Trip.trips.editTrip,
    locationtripById: state.Trip.trips.locationTrip,
  };
};
export default connect(mapStateToProps, {
  fetchTripById,
  trackLocationTripById,
})(SecondaryQualityDetailModel);
