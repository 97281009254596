import React, { Component } from 'react'
// import packages
import { Grid } from "@material-ui/core";
// import files
import LoadOptimizationHeader from './LoadOptimizationHeader'
import LoadAddItem from './LoadAddItem';
import LoadContainer from './LoadContainer';



var measureOptions = [
  {
    value: "in",
    label: "in",
  },
  {
    value: "cm",
    label: "cm",
  },
];

export default class LoadOptimization extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedOption: {
        value: "in",
        label: "in",
      },
      lotNo: "",
      length: "",
      width: "",
      height: "",
      totalKg: "",
      totalPcs: 0,
      description:"",
      addItemDetails: [],
    };
  }
  onChangeSelect = (e) => {
    // console.log("e select", e);
    this.setState({
      selectedOption: e,
    });
  };
  handleSubmitChange = (e) => {
    // console.log("SOURCE: ", e.target.value);
    const name = e.target.name;
    if (name === "lotno") {
      this.setState({
        lotNo: e.target.value.toUpperCase(),
      });
    } else if (name === "length") {
      this.setState({
        length: e.target.value,
      });
    } else if (name === "width") {
      this.setState({
        width: e.target.value,
      });
    } else if (name === "height") {
      this.setState({
        height: e.target.value,
      });
    } else if (name === "totalkg") {
      this.setState({
        totalKg: e.target.value,
      });
    } else if (name === "totalpcs") {
      this.setState({
        totalPcs: e.target.value,
      });
    }
    else if (name === "description") {
      this.setState({
        description: e.target.value,
      });
    }
  };
  onReset = () => {
    this.setState(
      {
        lotNo: "",
        width: "",
        height: "",
        totalKg: "",
        totalPcs: 0,
        length: "",
        description:"",
        selectedOption: {
          value: "in",
          label: "in",
        },
      },
      () => {
        // console.log("this.state.lotNo", this.state.lotNo);
      }
    );
  };
  addDetails = () => {
    // let randomColor ;
    let addDetails;
    addDetails = {
      lotno: this.state.lotNo,
      length: this.state.length,
      width: this.state.width,
      height: this.state.height,
      totalKg: this.state.totalKg,
      totalPcs: this.state.totalPcs,
      measures: this.state.selectedOption,
    };
    if (this.state.addItemDetails?.length === 0) {
      let randomColor = this.generateRandomColor();
      addDetails.color = randomColor;
      this.setState(
        {
          addItemDetails: [...this.state.addItemDetails, addDetails],
        },
        () => {
          // console.log("testing", this.state.addItemDetails);
        }
      );
    }
    if (this.state.addItemDetails?.length > 0) {
      this.state.addItemDetails.map((ele, i) => {
        if (
          ele?.length !== addDetails.length ||
          ele?.width !== addDetails.width ||
          ele?.height !== addDetails?.height ||
          ele?.totalKg !== addDetails?.totalKg ||
          ele?.measures !== addDetails?.measures
        ) {
          let randomColor = this.generateRandomColor();
          addDetails.color = randomColor;
        } else {
          addDetails.color = ele?.color;
        }
        this.setState(
          {
            addItemDetails: [...this.state.addItemDetails, addDetails],
          },
          () => {
            // console.log("testing", this.state.addItemDetails);
          }
        );
        return true
      });
    }
  };
  deleteItemDetails = (i, a) => {
    const deleteItemDetails = this.state.addItemDetails.filter(
      (ele, index) => i !== index
    );
    this.setState({
      addItemDetails: deleteItemDetails,
    });
  };
  increament = () => {
    this.setState({
      totalPcs: Number(this.state.totalPcs) + 1,
    });
  };
  decreament = () => {
    if (this.state.totalPcs > 0) {
      this.setState({
        totalPcs: Number(this.state.totalPcs) - 1,
      });
    }
  };
  generateRandomColor = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  render() {
    return (
        <>
        <div>
        <LoadOptimizationHeader 
        {...this.props}
        />
        <div>
            <Grid 
             container
             spacing={2}
             justifyContent="center"
             alignItems="center"  
            className="LoadGridHeight"
            >
              <Grid xs={12} md={4} className=" pr-2 pl-4">
             <LoadAddItem 
              selectedOption={this.state.selectedOption}
              lotNo={this.state.lotNo}
              length={this.state.length}
              width={this.state.width}
              height={this.state.height}
              totalKg={this.state.totalKg}
              totalPcs={this.state.totalPcs}
              addItemDetails={this.state.addItemDetails}
              measureOptions={measureOptions}
              description={this.state.description}
              onChangeSelect={this.onChangeSelect}
              handleSubmitChange={this.handleSubmitChange}
              onReset={this.onReset}
              addDetails={this.addDetails}
              deleteItemDetails={this.deleteItemDetails}
              increament={this.increament}
              decreament={this.decreament}
             />
              </Grid>
              <Grid xs={12} md={8} className="pr-2 pl-2">
                  <LoadContainer 
                  addItemDetails= { this.state.addItemDetails}
                  />
                  </Grid>
            </Grid>
            </div>
      </div>
      </>
    )
  }
}
