// import { mapKeys } from "lodash";
import { types } from "../actionTypes";

export default (state = {}, action) => {
  switch (action.type) {
    // case types.FETCH_POST_SUCCESS:
    //   // Copy the current state and set a new property with a dynamic key value and the payload as the value
    //   return { ...state, [action.payload.id]: action.payload };
    case types.FETCH_TRANSPORTERS_SUCCESS:
      //   // Create a new state object that uses an AJAX request response and grabs the 'id' property from each object in the response to use as its key
      return action.payload;
    case types.FETCH_TRANSPORTERS:
      //   // Create a new state object that uses an AJAX request response and grabs the 'id' property from each object in the response to use as its key
      return { ...state, payload: action.payload };

    case types.CREATE_TRANSPORTER_SUCCESS:
      //console.log('state', state);
      // Copy the current state and set a new property with a dynamic key value and the payload as the value
      return { ...state, transporter: action.payload };

    case types.FETCH_HOPS_SUCCESS:
      return { ...state, hops: action.payload };
    case types.FETCH_TRANSPORTERS_FOR_INDENT_SUCCESS:
      return action.payload;
    default:
      state = { ...state };
      break;
  }

  return state;
};
