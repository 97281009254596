import React, { Component } from "react";
import { Row, Col, Card, Button } from "reactstrap";
import { connect } from "react-redux";
import downloadImg from "./assets/download.png";
// import axios from "axios";
// import { getHeaders } from "../../helpers/global_req_helper";
import { fetchTripById } from "../../store/trip/actions";
import { trackLocationTripById } from "../../store/trip/actions";
import { MDBCollapse } from "mdbreact";
import { Grid, Box } from "@material-ui/core";
import { CloseIcon } from "../../components/Common/CommonIcons";
import plusIcon from "./assets/plus.png";
import minusIcon from "./assets/minus.png";
import playBtn from "./assets/play.svg";
import plusDarkIcon from "./assets/plusDark.png";
// import * as FileSaver from "file-saver";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class ShipmentData extends Component {
  constructor(props) {
    super();
    this.state = {
      tripDetail: [],
      tripId: "",
      // imageArray: [
      //   "https://cdn.pixabay.com/photo/2016/02/08/07/42/diamond-1186139_960_720.jpg",
      //   "https://cdn.pixabay.com/photo/2018/09/08/09/48/ornament-3662184__340.jpg",
      //   "https://cdn.pixabay.com/photo/2016/02/23/14/07/noble-1217693_960_720.jpg",
      // ],
      imageArray1: [],
      trackTripById: [],
      shipment: [],
      tripStatusTransitionLogs: [],
      qualityImage: [],
      qualities: {},
      collapse: false,
      activeTab: "1",
      TripShipId: "",
      sourceObjectId: "",
      contentShow: "",
      currentIndex: "",
      currentShipIndex: "",
      itemIndex: "",
    };
    this.toggle = this.toggle.bind(this);

    // console.log(this, "stateDataship");
  }

  componentDidMount = () => {
    // console.log(this.props?.id, "tripdatapage");
    let tripId = this.props?.id;
    let trip = [];
    this.props.fetchTripById(tripId, (ele) => {});
    this.setState({ tripId: tripId }, () => {
      this.props.fetchTripById(tripId, (ele) => {
        this.setState({
          TripShipId: ele?.data[0]?.tripId ? ele?.data[0]?.tripId : "",
        });
        if (ele.data[0].epodDetails.length) {
          this.setState(
            {
              qualities: ele.data[0].qualityCheck.answers,
              qualityImage: ele.data[0]?.qualityCheck?.images || [],
              shipment: ele.data[0].epodDetails
                ? ele.data[0].epodDetails[0].shipments
                : "---",
              tripStatusTransitionLogs: ele?.data[0]?.tripStatusTransitionLogs
                ? ele?.data[0]?.tripStatusTransitionLogs
                : "",
            },
            () => {
              let lrImages = [
                ele.data[0].epodDetails.length > 0
                  ? ele.data[0].epodDetails[0]?.lrImages?.front[0]
                  : "",

                ele.data[0].epodDetails.length > 0
                  ? ele.data[0].epodDetails[0]?.lrImages?.back[0]
                  : "",

                ele.data[0].epodDetails.length > 0
                  ? ele.data[0].epodDetails[0].unloadingReceiptImages[0]
                  : "",
              ];

              this.setState({ imageArray1: lrImages });
              // console.log(lrImages, "lrimage");
            }
          );
        } else {
          this.setState(
            {
              qualities: ele.data[0].qualityCheck.answers,
              qualityImage: ele.data[0]?.qualityCheck?.images || [],
              tripStatusTransitionLogs: ele?.data[0]?.tripStatusTransitionLogs,
            },
            () => {
              // console.log(ele.data[0].qualityCheck, "callbackqualityCheck");
            }
          );
        }

        trip.push({
          quality: ele.data[0].qualityCheck,
          shipment:
            ele.data[0].epodDetails.length > 0
              ? ele.data[0].epodDetails[0].shipments
              : [],
        });

        this.setState({ tripDetail: trip });
      });
    });
  };

  downloadImage = (image) => {
    fetch(image, { mode: "no-cors" })
      .then((res) => res.blob())
      .then((blob) => {
        var element = document.createElement("a");
        element.href = URL.createObjectURL(blob);
        element.download = "image.jpeg";
        element.click();
      });
  };

  toggle = (index) => {
    this.setState((prevState) => ({
      expandIndex: prevState.expandIndex !== index ? index : "",
    }));
  };

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };
  contentShow = (id) => {
    this.setState({ contentShow: !this.state.contentShow, currentIndex: id });
  };

  render() {
    const download = (pictures) => {
      let headers = new Headers();

      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append(
        "Access-Control-Allow-Headers",
        "content-type, authorization"
      );
      for (var i = 0; i < pictures.length; i++) {
        // console.log(pictures, "pictureUrl");
        fetch(pictures[i], {
          method: "GET",
          headers: {},
          // mode:"no-cors",
          cache: "no-cache",
        })
          .then((response) => {
            response.arrayBuffer().then(function (buffer) {
              const url = window.URL.createObjectURL(new Blob([buffer]));
              const link = document.createElement("a");
              link.href = url;
              link.download = `epod:${new Date() / 1000}.jpeg`;
              link.click();
            });
          })
          .catch((err) => {
            // console.log(err);
          });
      }
    };
    var lrCopy = [];
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let darkState = this.props?.darkTheme;

    if (Array.isArray(this.props.tripById?.data)) {
      this.props.tripById.data.forEach((ele) => {
        if (ele.epodDetails.length > 0) {
          lrCopy.push({
            lrFront:
              ele.epodDetails.length > 0
                ? ele.epodDetails[0]?.lrImages?.front
                : "---",
            lrBack:
              ele.epodDetails.length > 0
                ? ele.epodDetails[0]?.lrImages?.back
                : "---",
            unloadingReceiptImages:
              ele.epodDetails.length > 0
                ? ele.epodDetails[0].unloadingReceiptImages
                : [],
            qualityImage: ele.qualityCheck ? ele.qualityCheck.images : "----",
            unloaded:
              ele.epodDetails.length > 0
                ? ele.epodDetails[0]?.createdBy?.name
                : "---",
          });
        }
      });
    }

    let shipped = [];

    let ship =
      this.state.shipment.length > 0
        ? this.state.shipment.map((t1) => {
            if (t1) {
              let verifyData = [];
              let curStatus = this.state?.tripStatusTransitionLogs?.filter(
                (logs) => logs.curStatus === "shipment_verified"
              );
              let match = curStatus?.filter(
                (logs1) => logs1?.hop?._id === t1?.source?._id
              );
              if (match) {
                match.map((updatedByData) =>
                  verifyData.push({
                    updatedBy: updatedByData?.updatedBy?.name
                      ? updatedByData?.updatedBy?.name
                      : "----",
                    updatedAt:
                      updatedByData?.hop.label +
                      "-" +
                      updatedByData?.hop.hopCode
                        ? updatedByData?.hop.label +
                          "-" +
                          updatedByData?.hop.hopCode
                        : "---",
                  })
                );
              }

              shipped = [
                ...shipped,
                {
                  shipid: t1.shipmentId,
                  shipSourceId: t1.source._id,
                  updatedverifyBy: verifyData ? verifyData : "",
                  source: t1.source.label,
                  destination: t1.destination.label,
                  items: t1.items,
                  copy: lrCopy,
                },
              ];
            }
            return true;
          })
        : null;

    // console.log(ship, "shipped");

    const modalStyle = {
      height: "100%",
      width: "100%",
      display: "initial",
      backgroundColor: darkState ? "#1B1C31" : "#ffffff",
      scrollY: "hidden",
      padding: "5px",
      position: "relative",
      border: darkState ? "border: 0.5px solid #616161" : "#ffffff",
    };
    const headerStyle = {
      position: "relative",
      fontFamily: "Roboto",
      fontWeight: "500",
      fontSize: "18px",
      lineHeight: "23px",
      color: darkState ? "#9D9D9D" : "#0C0C0C",
      left: "0px",
    };
    const closeStyle = {
      position: "relative",
      // color: darkState ? "#B7A9FF" : "#2800fc",
      width: "20px",
      marginRight: "35px",
    };
    const rowsTopSpaceStyle = { marginTop: "0px" };
    const dataBoxStyle = {
      height: "380px",
      overflowY: "scroll",
      overflowX: "clip",
    };

    const rowSeparaterStyle = {
      borderLeft: "0.5px solid #B9B9B9",
      height: "100%",
    };

    const oddRowStyle = {
      minHeight: "27px",
      width: "101%",
      margin: "auto",
      marginTop: "4px",
      marginRight: "0px",
    };
    const evenRowStyle = {
      minHeight: "27px",
      width: "101%",
      margin: "auto",
      marginTop: "4px",
      marginRight: "0px",
    };

    const rowHeaderStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "15px",
      color: darkState ? "#9D9D9D" : "#05284E",
      lineHeight: "18px",
      margin: "auto",
      paddingLeft: "1.5vw",
    };
    // const rowSeparaterStyle = {
    //   borderLeft: "0.5px solid rgba(185, 185, 185, .5)",
    //   height: "286px",
    //   float: "left",
    // };
    const rowValueStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "13px",
      color: darkState ? "#9D9D9D" : "#0C0C0C",
      margin: "auto",
      flex: "wrap",
    };

    return (
      <React.Fragment>
        <div>
          <Card style={modalStyle}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <span style={headerStyle}>
                  <p>{userLang?.dashboard?.SHIPMENT}</p>
                </span>
              </div>

              {/* <CloseIcon
                style={closeStyle}
                onClick={() => this.props.closeModal()}
              /> */}
              <i style={closeStyle} onClick={() => this.props.closeModal()}>
                <CloseIcon color={darkState ? "#B7A9FF" : "#2800fc"} />
              </i>
            </div>

            <Grid style={rowsTopSpaceStyle}></Grid>
            <Row>
              <Col xs={12}>
                <div
                  className="fontRoboto1541"
                  style={{
                    color: "#05284E",
                  }}
                >
                  <div className="dark__span">
                    {userLang?.trip?.TRIP_ID} :{" "}
                    {this.state?.TripShipId ? this.state?.TripShipId : ""}
                  </div>
                </div>
              </Col>
            </Row>

            <div>
              {shipped?.map((data, index, array) => (
                <>
                  <>
                    <div className="accordion">
                      <div className="accordion-item barDropDark">
                        <>
                          <div
                            className="accordion-item-header"
                            key={index}
                            onClick={() => {
                              if (this.state.currentIndex === index) {
                                this.setState(
                                  {
                                    currentIndex: "",
                                  },
                                  () => {}
                                );
                              } else {
                                this.setState(
                                  {
                                    currentIndex: index,
                                  },
                                  () => {}
                                );
                              }
                            }}
                          >
                            <Row style={{ width: "100%" }}>
                              <Col xs={10}>
                                <Row>
                                  <span class="fontRoboto15  ml-4 light__span shipmentHeadingText">
                                    {userLang?.trip?.SHIPMENT_NO}
                                  </span>{" "}
                                  <span class="fontRoboto154 ml-2 dark__span shipmentHeadingText">
                                    {" "}
                                    {data?.shipid}
                                  </span>
                                  {/* <span className="shipmentHeadingText darkText">
                                    {userLang?.trip?.SHIPMENT_NO}:{data?.shipid}
                                  </span> */}
                                </Row>
                              </Col>
                              <Col xs={2}>
                                <div className="">
                                  {this.state.currentIndex === index ? (
                                    <Button
                                      outline
                                      color="primary"
                                      className="accordionIcon"
                                      onClick={() => {
                                        this.setState({
                                          currentIndex: "",
                                        });
                                      }}
                                    >
                                      <img
                                        style={{
                                          margin: "-4px",
                                        }}
                                        src={darkState ? minusIcon : minusIcon}
                                        alt="exp"
                                      />
                                    </Button>
                                  ) : (
                                    <Button
                                      outline
                                      color="primary"
                                      className="accordionIcon"
                                      onClick={() => {
                                        this.setState({
                                          currentIndex: index,
                                        });
                                      }}
                                    >
                                      <img
                                        style={{
                                          margin: "-4px",
                                        }}
                                        src={
                                          darkState ? plusDarkIcon : plusIcon
                                        }
                                        alt="exp"
                                      />
                                    </Button>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </div>

                          {this.state.currentIndex === index && (
                            <>
                              <div class="accordion-item-body-content">
                                <>
                                  {/* <Box sx={{ flexGrow: 1 }}>
                              <Grid
                                container
                                spacing={1}
                                alignItems="center"
                                justifyContent="center"
                                className="m-1"
                              >
                                <Grid item xs={11} md={3}>
                                  <span className="shipmentHeadingText darkText">
                                    {userLang?.trip?.SHIPMENT_NO}:{data?.shipid}
                                  </span>
                                </Grid>
                                <Grid
                                  item
                                  // xs={1}
                                  style={{
                                    padding: "3vh 0px",
                                    marginLeft: "104px",
                                    marginTop: "-3px",
                                    backgroundColor: "#B9B9B9",
                                  }}
                                >
                                  <div
                                    className="desktopDisplay"
                                    style={rowSeparaterStyle}
                                  ></div>
                                </Grid>
                                <Grid item xs={11} md={4}>
                                  <span className="shipmentHeadingText darkText ml-2">
                                    {userLang?.common?.SOURCE}: {data?.source}
                                  </span>
                                </Grid>
                                <Grid
                                  item
                                  // xs={1}
                                  style={{
                                    padding: "3vh 0px",
                                    marginLeft: "105px",
                                    marginTop: "-3px",
                                    backgroundColor: "#B9B9B9",
                                  }}
                                >
                                  <div
                                    className="desktopDisplay"
                                    style={rowSeparaterStyle}
                                  ></div>
                                </Grid>
                                <Grid item xs={11} md={4}>
                                  {" "}
                                  <span className="shipmentHeadingText darkText">
                                    {userLang?.common?.DESTINATION} : {data?.destination}
                                  </span>
                                </Grid>
                                <Grid
                                  item
                                  // xs={1}

                                  style={{
                                    padding: "3vh 0px",
                                    marginLeft: "105px",
                                    marginTop: "-3px",
                                    backgroundColor: "#B9B9B9",
                                  }}
                                >
                                  <div
                                    className="desktopDisplay"
                                    style={rowSeparaterStyle}
                                  ></div>
                                </Grid>
                                <Grid item xs={11} md={4}>
                                  {" "}
                                  <span
                                    className="shipmentHeadingText darkText"
                                    style={{
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {userLang?.trip?.UNLOADED_BY} :{" "}
                                    {lrCopy.map((fblrimage, index) => (
                                      <span key={index}>{fblrimage?.unloaded}</span>
                                    ))}
                                  </span>
                                </Grid>
                             
                              </Grid>
                            </Box>  */}

                                  {/* <Box sx={{ flexGrow: 1 }} className="shipCardBox">
                              <Grid
                                container
                                // direction="row"
                                spacing={2}
                                alignItems="center"
                                justifyContent="center"
                                className="m-1"
                              >
                                <Grid item xs={11} md={6} className="mobileShipPad">
                                  {" "}
                                  <span className="shipmentHeadingText darkText">
                                    {userLang?.common?.VERIFIED_BY} :{" "}
                                    {data?.updatedverifyBy.map((VerifiedBy, index) => (
                                      <span key={index}>{VerifiedBy?.updatedBy}</span>
                                    ))}
                                  </span>
                                </Grid>
                                <Grid
                                  item
                                  // xs={1} md={2}

                                  // style={{
                                  //   padding: "2vh 0px",
                                  //   marginLeft: "-2px",
                                  //   marginTop: "11px",
                                  //   backgroundColor: "#B9B9B9",
                                  // }}
                                >
                                  <div
                                    className="desktopDisplay"
                                    style={{
                                      ...rowSeparaterStyle,
                                      position: "relative",
                                      left: "6px",
                                    }}
                                  ></div>
                                </Grid>

                                <Grid
                                  item
                                  xs={11}
                                  md={6}
                                  className="mobileShipPad paddingL18"
                                >
                                  {" "}
                                  <span className="shipmentHeadingText darkText">
                                    {userLang?.common?.VERIFIED_AT} :{" "}
                                    {data?.updatedverifyBy.map((timeDta, index) => (
                                      <span key={index}>{timeDta?.updatedAt}</span>
                                    ))}
                                  </span>
                                </Grid>
                              </Grid>
                            </Box>  */}

                                  <Row>
                                    <div className="col-1-3">
                                      <Grid
                                        className=""
                                        container
                                        spacing={0}
                                        style={oddRowStyle}
                                      >
                                        <Grid
                                          // className=""
                                          item
                                          xs={3}
                                          md={3}
                                          style={rowHeaderStyle}
                                          className="MobilefontRoboto127  light__span"
                                        >
                                          <span>
                                            {userLang?.common?.SOURCE}
                                            <span className="text-bold">:</span>
                                          </span>
                                        </Grid>

                                        <Grid
                                          item
                                          xs={5}
                                          md={5}
                                          style={rowValueStyle}
                                          className="MobilefontRoboto124  dark__span"
                                        >
                                          {data?.source}
                                        </Grid>
                                      </Grid>
                                    </div>
                                    <div className="col-1-3">
                                      <Grid
                                        className=""
                                        container
                                        spacing={0}
                                        style={oddRowStyle}
                                      >
                                        <Grid
                                          // className=""
                                          item
                                          xs={3}
                                          md={6}
                                          style={rowHeaderStyle}
                                          className="MobilefontRoboto127  light__span"
                                        >
                                          <span>
                                            {userLang?.common?.DESTINATION}
                                            <span className="text-bold">:</span>
                                          </span>
                                        </Grid>

                                        <Grid
                                          item
                                          xs={5}
                                          md={5}
                                          style={rowValueStyle}
                                          className="MobilefontRoboto124  dark__span"
                                        >
                                          {data?.destination}
                                        </Grid>
                                      </Grid>
                                    </div>
                                    <div className="col-1-3">
                                      <Grid
                                        className=""
                                        container
                                        spacing={0}
                                        style={oddRowStyle}
                                      >
                                        <Grid
                                          // className=""
                                          item
                                          xs={3}
                                          md={6}
                                          style={rowHeaderStyle}
                                          className="MobilefontRoboto127  light__span"
                                        >
                                          <span>
                                            {userLang?.trip?.UNLOADED_BY}
                                            <span className="text-bold">:</span>
                                          </span>
                                          <span
                                            className="shipmentHeadingText darkText"
                                            style={{
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            {lrCopy.map((fblrimage, index) => (
                                              <span key={index}>
                                                {fblrimage?.unloaded}
                                              </span>
                                            ))}
                                          </span>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </Row>

                                  <Row className="mt-2">
                                    <div className="col-1-3">
                                      <Grid
                                        className=""
                                        container
                                        spacing={0}
                                        style={oddRowStyle}
                                      >
                                        <Grid
                                          // className=""
                                          item
                                          xs={6}
                                          md={3}
                                          style={rowHeaderStyle}
                                          className="MobilefontRoboto127  light__span"
                                        >
                                          <span>
                                            {userLang?.common?.VERIFIED_BY}
                                            <span className="text-bold">:</span>
                                          </span>
                                        </Grid>

                                        <Grid
                                          item
                                          xs={6}
                                          md={5}
                                          style={rowValueStyle}
                                          className="MobilefontRoboto124  dark__span"
                                        >
                                          {data?.updatedverifyBy.map(
                                            (VerifiedBy, index) => (
                                              <span key={index}>
                                                {VerifiedBy?.updatedBy}
                                              </span>
                                            )
                                          )}
                                        </Grid>
                                      </Grid>
                                    </div>
                                    <div className="col-1-3">
                                      <Grid
                                        className=""
                                        container
                                        spacing={0}
                                        style={oddRowStyle}
                                      >
                                        <Grid
                                          // className=""
                                          item
                                          xs={6}
                                          md={6}
                                          style={rowHeaderStyle}
                                          className="MobilefontRoboto127  light__span"
                                        >
                                          <span>
                                            {userLang?.common?.VERIFIED_AT}
                                            <span className="text-bold">:</span>
                                          </span>
                                        </Grid>

                                        <Grid
                                          item
                                          xs={5}
                                          md={5}
                                          style={rowValueStyle}
                                          className="MobilefontRoboto124  dark__span"
                                        >
                                          {data?.updatedverifyBy.map(
                                            (timeDta, index) => (
                                              <span key={index}>
                                                {timeDta?.updatedAt}
                                              </span>
                                            )
                                          )}
                                        </Grid>
                                      </Grid>
                                    </div>
                                    <div className="headerDesc"></div>
                                  </Row>
                                </>
                              </div>

                              <div class="accordion-item-body">
                                <>
                                  {this.state.currentIndex === index && (
                                    <>
                                      <div class="accordion-item-body-content darkCardStyle">
                                        {this.state.currentIndex === index && (
                                          <div style={dataBoxStyle}>
                                            <div
                                              className="row justify-content-center"
                                              style={{ maxWidth: "100%" }}
                                            >
                                              {data.items.map(
                                                (itemdata, index) => (
                                                  <div
                                                    key={index}
                                                    className="table-responsive-xl ShipItem darkTableStyle"
                                                    style={{
                                                      position: "relative",
                                                    }}
                                                  >
                                                    <div className="mobileDisplayShpment">
                                                      <Grid
                                                        container
                                                        className=""
                                                      >
                                                        <Grid item xs={12}>
                                                          <div
                                                            className="col"
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <ul
                                                              style={{
                                                                marginBottom:
                                                                  "0px",
                                                              }}
                                                            >
                                                              <div className="mb-2">
                                                                <span className="textHeaderStyle lightText">
                                                                  {
                                                                    userLang
                                                                      ?.trip
                                                                      ?.ITEM_NO
                                                                  }
                                                                  :{" "}
                                                                </span>
                                                                <span className="textValueStyle pl-5 darkText">
                                                                  {
                                                                    itemdata.itemNo
                                                                  }
                                                                </span>
                                                              </div>
                                                              <div className="mb-2">
                                                                <span className="textHeaderStyle lightText">
                                                                  {
                                                                    userLang
                                                                      ?.trip
                                                                      ?.LOT_NO
                                                                  }
                                                                  :{" "}
                                                                </span>
                                                                <span
                                                                  style={{
                                                                    marginLeft:
                                                                      "7px",
                                                                  }}
                                                                  className="textValueStyle pl-5 darkText"
                                                                >
                                                                  {" "}
                                                                  {
                                                                    itemdata.lotNo
                                                                  }
                                                                </span>
                                                              </div>
                                                              <div className="mb-2">
                                                                <span className="textHeaderStyle lightText">
                                                                  {
                                                                    userLang
                                                                      ?.trip
                                                                      ?.QUANTITY
                                                                  }
                                                                  :{" "}
                                                                </span>
                                                                <span
                                                                  style={{
                                                                    marginLeft:
                                                                      "-5px",
                                                                  }}
                                                                  className="textValueStyle pl-5 darkText"
                                                                >
                                                                  {" "}
                                                                  {
                                                                    itemdata.quantity
                                                                  }
                                                                </span>
                                                              </div>
                                                            </ul>
                                                            <div
                                                              className="col d-flex flex-row"
                                                              style={{
                                                                width: "100%",
                                                                marginLeft:
                                                                  "42px",
                                                              }}
                                                            >
                                                              <div className="row mb-2">
                                                                <span className="textHeaderStyle lightText">
                                                                  {
                                                                    userLang
                                                                      ?.invoice
                                                                      ?.DAMAGE
                                                                  }{" "}
                                                                  :{" "}
                                                                </span>
                                                              </div>

                                                              {(itemdata.epod &&
                                                                itemdata.epod
                                                                  .damageImages) ||
                                                              (itemdata.epod &&
                                                                itemdata.epod
                                                                  .damageImages ===
                                                                  []) ? (
                                                                <div>
                                                                  <div className="pl-5 row mb-2 d-flex justify-content-around">
                                                                    <div
                                                                      style={{
                                                                        marginLeft:
                                                                          "-14px",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          marginLeft:
                                                                            "-2px",
                                                                        }}
                                                                        className="textHeaderStyle lightText"
                                                                      >
                                                                        {
                                                                          userLang
                                                                            ?.trip
                                                                            ?.DAMAGE_QUANTITY
                                                                        }{" "}
                                                                        :{" "}
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <span
                                                                        style={{
                                                                          marginLeft:
                                                                            "-5px",
                                                                        }}
                                                                        className="textValueStyle pl-2 darkText"
                                                                      >
                                                                        {
                                                                          itemdata[
                                                                            "epod"
                                                                          ]
                                                                            ?.damagedQuantity
                                                                        }
                                                                      </span>
                                                                    </div>
                                                                  </div>
                                                                  <div className="row">
                                                                    <span className="textHeaderStyle lightText">
                                                                      {
                                                                        userLang
                                                                          ?.trip
                                                                          ?.LOOSE_OIL
                                                                      }{" "}
                                                                      (in KGs) :
                                                                      &nbsp;
                                                                    </span>
                                                                    <span className="textValueStyle pl-2 darkText">
                                                                      {" "}
                                                                      {
                                                                        itemdata[
                                                                          "epod"
                                                                        ]
                                                                          ?.looseOil
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                  <br></br>
                                                                </div>
                                                              ) : (
                                                                <div className="pl-5">
                                                                  <span
                                                                    className="lightText"
                                                                    style={{
                                                                      marginLeft:
                                                                        "7px",
                                                                    }}
                                                                  >
                                                                    N/A
                                                                  </span>
                                                                </div>
                                                              )}
                                                            </div>
                                                            <div
                                                              className="col d-flex flex-row"
                                                              style={{
                                                                width: "100%",
                                                                marginLeft:
                                                                  "22px",
                                                              }}
                                                            >
                                                              <div
                                                                style={{
                                                                  marginLeft:
                                                                    "5px",
                                                                }}
                                                                className="row mb-2"
                                                              >
                                                                <span className="textHeaderStyle lightText">
                                                                  {
                                                                    userLang
                                                                      ?.invoice
                                                                      ?.SHORTAGE
                                                                  }{" "}
                                                                  :{" "}
                                                                </span>
                                                              </div>
                                                              {(itemdata.epod &&
                                                                itemdata.epod
                                                                  .shortageImages
                                                                  .length >
                                                                  0) ||
                                                              (itemdata.epod &&
                                                                itemdata.epod
                                                                  .shortageImages ===
                                                                  []) ? (
                                                                <div>
                                                                  <div className="pl-5 row mb-2 d-flex flex-row light__span">
                                                                    <div>
                                                                      <span className="textHeaderStyle">
                                                                        {
                                                                          userLang
                                                                            ?.invoice
                                                                            ?.SHORTAGE
                                                                        }{" "}
                                                                        {
                                                                          userLang
                                                                            ?.trip
                                                                            ?.QUANTITY
                                                                        }
                                                                        : &nbsp;
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <span className="textValueStyle dark__span">
                                                                        {
                                                                          itemdata[
                                                                            "epod"
                                                                          ]
                                                                            ?.shortageQuantity
                                                                        }
                                                                      </span>
                                                                    </div>
                                                                  </div>
                                                                  <div className="row"></div>
                                                                  &nbsp;&nbsp;
                                                                </div>
                                                              ) : (
                                                                <div className="pl-5">
                                                                  <span
                                                                    className="lightText"
                                                                    style={{
                                                                      marginLeft:
                                                                        "4px",
                                                                    }}
                                                                  >
                                                                    N/A
                                                                  </span>
                                                                </div>
                                                              )}
                                                            </div>
                                                          </div>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          className="mt-3"
                                                        >
                                                          <div
                                                            style={{
                                                              textAlign:
                                                                "center",
                                                            }}
                                                            className="collapseHeader"
                                                            onClick={() => {
                                                              this.toggle(
                                                                index
                                                              );
                                                            }}
                                                          >
                                                            <div>
                                                              <span className="textAttachedColor">
                                                                {
                                                                  userLang?.trip
                                                                    ?.ATTACHED_PROOF
                                                                }
                                                                :{" "}
                                                              </span>
                                                              <Button className="collapseButton">
                                                                <i className="fas fa-chevron-down"></i>
                                                              </Button>
                                                            </div>
                                                          </div>
                                                        </Grid>
                                                      </Grid>
                                                    </div>
                                                    <div className="desktopDisplayShipment">
                                                      <table
                                                        className="table table-hover tableStyle darkTableStyle"
                                                        style={{
                                                          tableLayout: "fixed",
                                                          width: "98%",
                                                          marginLeft: "auto",
                                                          marginRight: "auto",
                                                          backgroundColor:
                                                            darkState
                                                              ? "#24243C"
                                                              : "",
                                                          marginBottom: "20px",
                                                          marginTop: "6px",
                                                        }}
                                                      >
                                                        <tbody>
                                                          <tr>
                                                            <td>
                                                              <div
                                                                className="col"
                                                                style={{
                                                                  width: "100%",
                                                                }}
                                                              >
                                                                <ul>
                                                                  <div className="mb-2">
                                                                    <span className="textHeaderStyle lightText">
                                                                      {
                                                                        userLang
                                                                          ?.trip
                                                                          ?.ITEM_NO
                                                                      }
                                                                      :{" "}
                                                                    </span>
                                                                    <span className="textValueStyle pl-2 darkText">
                                                                      {
                                                                        itemdata.itemNo
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                  <div className="mb-2">
                                                                    <span className="textHeaderStyle lightText">
                                                                      {
                                                                        userLang
                                                                          ?.trip
                                                                          ?.LOT_NO
                                                                      }
                                                                      :{" "}
                                                                    </span>
                                                                    <span className="textValueStyle pl-2 darkText">
                                                                      {" "}
                                                                      {
                                                                        itemdata.lotNo
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                  <div className="mb-2">
                                                                    <span className="textHeaderStyle lightText">
                                                                      {
                                                                        userLang
                                                                          ?.trip
                                                                          ?.QUANTITY
                                                                      }
                                                                      :{" "}
                                                                    </span>
                                                                    <span className="textValueStyle pl-2 darkText">
                                                                      {" "}
                                                                      {
                                                                        itemdata.quantity
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                </ul>

                                                                <div className="vls"></div>
                                                                <div className="vls2"></div>
                                                              </div>
                                                            </td>

                                                            <td>
                                                              <div
                                                                className="col d-inline justify-content-center"
                                                                style={{
                                                                  width: "100%",
                                                                  // marginLeft: "74px",
                                                                }}
                                                              >
                                                                <div className="row mb-2">
                                                                  <span className="textHeaderStyle lightText">
                                                                    {
                                                                      userLang
                                                                        ?.invoice
                                                                        ?.DAMAGE
                                                                    }{" "}
                                                                  </span>
                                                                </div>

                                                                {(itemdata.epod &&
                                                                  itemdata.epod
                                                                    .damageImages) ||
                                                                (itemdata.epod &&
                                                                  itemdata.epod
                                                                    .damageImages ===
                                                                    []) ? (
                                                                  <div>
                                                                    <div className="row mb-2">
                                                                      <span className="textHeaderStyle lightText">
                                                                        {
                                                                          userLang
                                                                            ?.trip
                                                                            ?.DAMAGE_QUANTITY
                                                                        }{" "}
                                                                        :{" "}
                                                                      </span>
                                                                      <span className="textValueStyle pl-2 darkText">
                                                                        {
                                                                          itemdata[
                                                                            "epod"
                                                                          ]
                                                                            ?.damagedQuantity
                                                                        }
                                                                      </span>
                                                                    </div>
                                                                    <div className="row">
                                                                      <span className="textHeaderStyle lightText">
                                                                        {
                                                                          userLang
                                                                            ?.trip
                                                                            ?.LOOSE_OIL
                                                                        }{" "}
                                                                        (in KGs)
                                                                        : &nbsp;
                                                                      </span>
                                                                      <span className="textValueStyle pl-2 darkText">
                                                                        {" "}
                                                                        {
                                                                          itemdata[
                                                                            "epod"
                                                                          ]
                                                                            ?.looseOil
                                                                        }
                                                                      </span>
                                                                    </div>
                                                                    <br></br>
                                                                  </div>
                                                                ) : (
                                                                  <span className="lightText">
                                                                    N/A
                                                                  </span>
                                                                )}
                                                              </div>
                                                            </td>

                                                            <td>
                                                              <div
                                                                className="col d-inline justify-content-center"
                                                                style={{
                                                                  width: "100%",
                                                                  marginLeft:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <div className="row mb-2">
                                                                  <span className="textHeaderStyle lightText">
                                                                    {
                                                                      userLang
                                                                        ?.invoice
                                                                        ?.SHORTAGE
                                                                    }{" "}
                                                                  </span>
                                                                </div>
                                                                {(itemdata.epod &&
                                                                  itemdata.epod
                                                                    .shortageImages
                                                                    .length >
                                                                    0) ||
                                                                (itemdata.epod &&
                                                                  itemdata.epod
                                                                    .shortageImages ===
                                                                    []) ? (
                                                                  <div>
                                                                    <div className="row mb-2">
                                                                      <span className="textHeaderStyle lightText">
                                                                        {
                                                                          userLang
                                                                            ?.invoice
                                                                            ?.SHORTAGE
                                                                        }{" "}
                                                                        {
                                                                          userLang
                                                                            ?.trip
                                                                            ?.QUANTITY
                                                                        }
                                                                        : &nbsp;
                                                                      </span>
                                                                      <span className="textValueStyle darkText">
                                                                        {
                                                                          itemdata[
                                                                            "epod"
                                                                          ]
                                                                            ?.shortageQuantity
                                                                        }
                                                                      </span>
                                                                    </div>
                                                                    <div className="row"></div>
                                                                    &nbsp;&nbsp;
                                                                  </div>
                                                                ) : (
                                                                  <span className="lightText">
                                                                    N/A
                                                                  </span>
                                                                )}
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                      <div
                                                        className="collapseHeader"
                                                        onClick={() => {
                                                          this.toggle(index);
                                                        }}
                                                      >
                                                        <div className="text-center">
                                                          <span className="textAttachedColor">
                                                            {
                                                              userLang?.trip
                                                                ?.ATTACHED_PROOF
                                                            }
                                                            :{" "}
                                                          </span>
                                                          <Button className="collapseButton">
                                                            <i className="fas fa-chevron-down"></i>
                                                          </Button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <MDBCollapse
                                                      id={index.toString()}
                                                      isOpen={
                                                        this.state
                                                          .expandIndex === index
                                                      }
                                                    >
                                                      <>
                                                        <div
                                                          // className="row justify-content-center"
                                                          style={{
                                                            maxWidth: "100%",
                                                          }}
                                                        >
                                                          <div className="table-responsive-xl">
                                                            <table
                                                              className="table"
                                                              style={{
                                                                tableLayout:
                                                                  "fixed",
                                                                width: "100%",
                                                                marginRight:
                                                                  "auto",
                                                                marginLeft:
                                                                  "-13px",
                                                                marginTop:
                                                                  "-2px",
                                                                backgroundColor:
                                                                  "#0AB38C",
                                                              }}
                                                            >
                                                              <tbody>
                                                                <tr>
                                                                  <td>
                                                                    <>
                                                                      <div className="row">
                                                                        {itemdata[
                                                                          "epod"
                                                                        ]
                                                                          ?.damageImages
                                                                          .length >
                                                                        0 ? (
                                                                          <span
                                                                            style={{
                                                                              fontWeight:
                                                                                "400",
                                                                              marginLeft:
                                                                                "71px",
                                                                              color:
                                                                                "white",
                                                                            }}
                                                                          >
                                                                            {
                                                                              userLang
                                                                                ?.trip
                                                                                ?.DAMAGE_REASONS
                                                                            }{" "}
                                                                            :{" "}
                                                                            {
                                                                              userLang
                                                                                ?.trip
                                                                                ?.LOOSE_OIL
                                                                            }{" "}
                                                                            (in
                                                                            KGs)
                                                                          </span>
                                                                        ) : (
                                                                          ""
                                                                        )}
                                                                      </div>

                                                                      {itemdata[
                                                                        "epod"
                                                                      ]
                                                                        ?.damageImages
                                                                        .length >
                                                                      0 ? (
                                                                        <div className="row">
                                                                          <div className="col-lg-4 col-md-12 mb-4 mb-lg-0">
                                                                            <a
                                                                              id={
                                                                                itemdata.itemId +
                                                                                "looseOil" +
                                                                                index
                                                                              }
                                                                              href={
                                                                                itemdata[
                                                                                  "epod"
                                                                                ]
                                                                                  ?.damageImages
                                                                              }
                                                                              download
                                                                              target="_blank"
                                                                              onClick={() => {
                                                                                this.downloadImage(
                                                                                  itemdata[
                                                                                    "epod"
                                                                                  ]
                                                                                    ?.damageImages
                                                                                );
                                                                                // console.log(
                                                                                //   "clicked logger",
                                                                                //   itemdata
                                                                                // );
                                                                              }}
                                                                            >
                                                                              <img
                                                                                src={
                                                                                  itemdata[
                                                                                    "epod"
                                                                                  ]
                                                                                    ?.damageImages
                                                                                }
                                                                                style={{
                                                                                  height:
                                                                                    "75px",
                                                                                  width:
                                                                                    "75px",
                                                                                  marginTop:
                                                                                    "15px",
                                                                                  marginLeft:
                                                                                    "58px",
                                                                                }}
                                                                                className=" shadow-1-strong rounded mb-4"
                                                                                alt=""
                                                                              />
                                                                            </a>
                                                                          </div>
                                                                        </div>
                                                                      ) : (
                                                                        <div
                                                                          className="col"
                                                                          style={{
                                                                            marginTop:
                                                                              "17px",
                                                                            marginLeft:
                                                                              "67px",
                                                                            color:
                                                                              "white",
                                                                          }}
                                                                        >
                                                                          <span>
                                                                            {
                                                                              userLang
                                                                                ?.trip
                                                                                ?.NO_DAMAGE
                                                                            }
                                                                          </span>
                                                                        </div>
                                                                      )}
                                                                    </>
                                                                  </td>
                                                                  <td>
                                                                    <div className="vls3 desktopDisplay"></div>
                                                                  </td>
                                                                  <td>
                                                                    {(itemdata.epod &&
                                                                      itemdata
                                                                        .epod
                                                                        .shortageImages
                                                                        .length >
                                                                        0) ||
                                                                    (itemdata.epod &&
                                                                      itemdata
                                                                        .epod
                                                                        .shortageImages ===
                                                                        []) ? (
                                                                      <div
                                                                        className="col"
                                                                        style={{
                                                                          width:
                                                                            "100%",
                                                                          marginTop:
                                                                            "66px",
                                                                        }}
                                                                      >
                                                                        <div className="row">
                                                                          <div className="col-lg-4 col-md-12 mb-4 mb-lg-0">
                                                                            <a
                                                                              href={
                                                                                itemdata[
                                                                                  "epod"
                                                                                ]
                                                                                  ?.shortageImages
                                                                              }
                                                                              download
                                                                              target="_blank"
                                                                              onClick={() => {
                                                                                this.downloadImage(
                                                                                  itemdata[
                                                                                    "epod"
                                                                                  ]
                                                                                    ?.shortageImages
                                                                                );
                                                                                // console.log(
                                                                                //   "clicked logger",
                                                                                //   itemdata
                                                                                // );
                                                                              }}
                                                                            >
                                                                              <img
                                                                                src={
                                                                                  itemdata[
                                                                                    "epod"
                                                                                  ]
                                                                                    ?.shortageImages
                                                                                }
                                                                                style={{
                                                                                  height:
                                                                                    "75px",
                                                                                  width:
                                                                                    "75px",
                                                                                }}
                                                                                className=" shadow-1-strong rounded mb-4"
                                                                                alt=""
                                                                              />
                                                                            </a>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    ) : (
                                                                      <div
                                                                        className="col"
                                                                        style={{
                                                                          marginTop:
                                                                            "17px",
                                                                          color:
                                                                            "white",
                                                                        }}
                                                                      >
                                                                        <span>
                                                                          {
                                                                            userLang
                                                                              ?.trip
                                                                              ?.NO_SHORTAGE
                                                                          }
                                                                        </span>
                                                                      </div>
                                                                    )}
                                                                  </td>
                                                                </tr>
                                                              </tbody>
                                                            </table>
                                                          </div>
                                                        </div>
                                                      </>
                                                    </MDBCollapse>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </>
                              </div>
                            </>
                          )}
                        </>
                      </div>
                    </div>
                  </>
                </>
              ))}
            </div>
            {this.props.lrStatus?.lrCopy ? (
              <div className="mt-4">
                <span style={headerStyle} className="mr-4 ">
                  {userLang?.trip?.LR_COPY}
                </span>

                <Grid
                  container
                  spacing={2}
                  className="shipmentLrCopy"
                  ml-2className="desktopDisplay"
                  mt-4
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={6} md={6} style={{ maxHeight: "100%" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={3} className=" lrcopyfrontrow">
                        <span style={{ color: "white" }}>
                          {lrCopy.length > 0
                            ? userLang?.trip?.FRONT_BACK
                            : `No ${userLang?.trip?.LR_COPY}`}
                        </span>
                      </Grid>
                      <Grid item xs={12} md={4} className="d-flex flex-row">
                        {lrCopy?.map((fblrimage, index) => (
                          <div
                            key={index}
                            // className="row"
                            style={{
                              width: "100%",
                              maxHeight: "100%",
                              display: "grid",
                            }}
                          >
                            <>
                              {fblrimage?.lrFront.map((option) => (
                                <div className="col-lg-4 col-md-12 mb-4 mb-lg-0">
                                  <a
                                    href={option}
                                    target="_blank"
                                    download
                                    onClick={() => {
                                      this.downloadImage(option);
                                      // console.log("clicked logger", option);
                                    }}
                                  >
                                    <img
                                      src={option}
                                      className="q-shipvector shadow-1-strong rounded mb-4"
                                      alt=""
                                      id="dbtn"
                                    />
                                  </a>
                                </div>
                              ))}
                            </>
                          </div>
                        ))}
                        {/* </Grid>
                        <Grid item xs={12} md={4}> */}
                        {lrCopy?.map((fblrimage, index) => (
                          <div
                            key={index}
                            className=" pl-2 ml-4"
                            style={{
                              width: "50%",
                              maxHeight: "100%",
                              display: "grid",
                            }}
                          >
                            <>
                              {fblrimage.lrBack.map((option) => (
                                <div
                                  key={Math.random()}
                                  className="col-lg-4 col-md-12 mb-4 mb-lg-0"
                                >
                                  <a
                                    href={option}
                                    target="_blank"
                                    download
                                    onClick={() => {
                                      this.downloadImage([option]);
                                      // console.log("clicked logger", [option]);
                                    }}
                                  >
                                    <img
                                      src={option}
                                      className="q-shipvector2 shadow-1-strong rounded mb-4"
                                      alt=""
                                      id="dbtn"
                                    />
                                  </a>
                                </div>
                              ))}
                            </>
                          </div>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={6} md={6} style={{ maxHeight: "100%" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4} className="lrcopyunlodedcol">
                        <span style={{ color: "white" }}>
                          {lrCopy.length > 0
                            ? userLang?.trip?.FRONT_BACK
                            : `No ${userLang?.trip?.LR_COPY}`}
                        </span>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        {lrCopy.map((fblrimage, index) => (
                          <div
                            key={index}
                            className="row"
                            style={{
                              width: "100%",
                              // marginLeft: "4px",
                            }}
                          >
                            <>
                              {fblrimage.unloadingReceiptImages.map(
                                (option) => (
                                  <div
                                    key={Math.random()}
                                    className="col-lg-4 col-md-12 mb-4 mb-lg-0"
                                  >
                                    {/* {console.log("OPTION", option)} */}
                                    <a
                                      href={option}
                                      target="_blank"
                                      download
                                      onClick={() => {
                                        this.downloadImage([option]);
                                        // console.log("clicked logger", [option]);
                                      }}
                                    >
                                      <img
                                        src={option}
                                        className="q-shipunloadvector shadow-1-strong rounded mb-4"
                                        alt=""
                                        id="dbtn"
                                      />
                                    </a>
                                  </div>
                                )
                              )}
                            </>
                          </div>
                        ))}
                      </Grid>
                      <Grid item xs={12} md={4} className="mt-2">
                        <div className="tooltipIcon">
                          <Button color="success" style={{ border: "none" }}>
                            <a
                              // eslint-disable-next-line no-script-url
                              href="javascript:void(0)"
                              onClick={(e) => download(this.state.imageArray1)}
                            >
                              <img
                                style={{ width: "20px", height: "23px" }}
                                src={downloadImg}
                                alt="exp"
                              />
                            </a>
                          </Button>
                          <span
                            className="tooltipIcontext"
                            style={{ width: "55px" }}
                          >
                            &nbsp;Download All Image&nbsp;
                          </span>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            ) : (
              ""
            )}
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    tripById: state.Trip.trips.editTrip,
    locationtripById: state.Trip.trips.locationTrip,
  };
};
export default connect(mapStateToProps, {
  fetchTripById,
  trackLocationTripById,
})(ShipmentData);
