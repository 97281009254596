import React, { Component, useRef } from "react";
import { connect } from "react-redux";
import { Card, Row, Col, Button, Input, CardBody } from "reactstrap";
import Datetime from "react-datetime";
import Select from "react-select";
import playBtn from "../assets/play.svg";
import html2pdf from "html2pdf.js";
import { WaveLoader } from "../../../components/Common/Loader/LoaderIndex";
import * as Yup from "yup";
import {
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from "mdbreact";
import { NotificationManager } from "react-notifications";
import { createLRCopySecondary , UpdateLRCopySecondary } from "../../../store/secondaryIndentPlanning/actions";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "../tripStyles.scss";
import { ErrorMessage, Formik } from "formik";
import moment from "moment";
import { fetchLRCopy } from "../../../store/actions";


const initialState = () => ({
  submittedForm: false,
  warningModal: false,
  form: {
    noOfPkgs: null,
    packagingType: {},
    descriptionOfGoods: "",
    actualWeight: null,
    volumetricWeight: null,
    chargeableWeight: null,
    LDimension: null,
    BDimension: null,
    HDimension: null,
    invoiceNo: "",
    partNo: "",
    qty: null,
  },
  isOpen: false,
  actualWeight: null,
  onSubmitDisable: false,
  packagingType: [
    {
      label: "dummy packaging type",
      value: 952,
    },
    {
      label: "dummy1 packaging type",
      value: 950,
    },
  ],
  transporterName: "",
  transporterAddress: "",
  transporterGSTNo: "",
  transporterCinNo: "",
  transporterPanNo: "",
  transporterPhNo: "",
  sourceName: "",
  sourceEwayBillNo: null,
  destinationName: "",
  destinationEwayBillNo: null,
  invoiceValue: null,
  bookingDate: moment(new Date()).format("DD-MM-YYYY"),
  expectedDeliveryDate: moment(new Date()).format("DD-MM-YYYY"),
  modeOfDispatch: "",
  consignorName: "",
  consignorAddress: " ",
  consignorGSTNo: "",
  consignorCity: "",
  consignorState: "",
  consignorZip: "",
  consignorPhoneNo: "",
  consigneeName: "",
  consigneeAddress: " ",
  consigneeGSTNo: "",
  consigneeCity: "",
  consigneeState: "",
  consigneeZip: "",
  consigneePhoneNo: "",
  isEditForm: false,
  islorryReceiptTrue:false,
});

class LRCopyForm extends Component {

  constructor(props) {
    super(props);
    this.state = initialState();
    this.pdfContentRef = React.createRef();
  }

  
  componentDidMount = () => {

    this.props.fetchLRCopy(
      this.props.history.location.state?.LRCopyData?._id,
      (res) => {
        
        const isLorryReceiptTrue = res?.data?.docs[0]?.lorryReceiptExists
        this.setState({islorryReceiptTrue: res?.data?.docs[0]?.lorryReceiptExists})
        this.setState(
          {
            transporterName: isLorryReceiptTrue ? res?.data?.docs[0]?.lorryReceiptDetails[0]?.transporter?.name : res?.data?.docs[0]?.transporterDetails?.name,
            transporterAddress: res?.data?.docs[0]?.lorryReceiptDetails[0]?.transporter?.address,
            transporterGSTNo: res?.data?.docs[0]?.lorryReceiptDetails[0]?.transporter?.gstNo,
            transporterCinNo: res?.data?.docs[0]?.lorryReceiptDetails[0]?.transporter?.cinNo,
            transporterPanNo: res?.data?.docs[0]?.lorryReceiptDetails[0]?.transporter?.panNo,
            transporterPhNo: res?.data?.docs[0]?.lorryReceiptDetails[0]?.transporter?.phone,
            sourceName: isLorryReceiptTrue?  res?.data?.docs[0]?.lorryReceiptDetails[0]?.source?.name :  res?.data?.docs[0]?.sourceDetails?.hopCode,
            sourceEwayBillNo: Number(res?.data?.docs[0]?.lorryReceiptDetails[0]?.source?.ewayBillNo),
            destinationName: isLorryReceiptTrue? res?.data?.docs[0]?.lorryReceiptDetails[0]?.destination?.name : res?.data?.docs[0]?.destinationDetails?.hopCode,
            destinationEwayBillNo: Number(
              res?.data?.docs[0]?.destination?.ewayBillNo
            ),
            invoiceValue: +res?.data?.docs[0]?.lorryReceiptDetails[0]?.invoiceValue,
            bookingDate: moment(res?.data?.docs[0]?.lorryReceiptDetails[0]?.bookingDate).format(
              "MM-DD-YYYY"
            ),
            expectedDeliveryDate: moment(
              res?.data?.docs[0]?.expectedDeliveryDate
            ).format("MM-DD-YYYY"),
            modeOfDispatch: res?.data?.docs[0]?.lorryReceiptDetails[0]?.modeOfDispatch,
            consignorName: res?.data?.docs[0]?.lorryReceiptDetails[0]?.consignor?.name,
            consignorAddress: res?.data?.docs[0]?.lorryReceiptDetails[0]?.consignor?.address,
            consignorGSTNo: res?.data?.docs[0]?.lorryReceiptDetails[0]?.consignor?.gstNo  ? res?.data?.docs[0]?.lorryReceiptDetails[0]?.consignor?.gstNo : 0,
            consignorCity: res?.data?.docs[0]?.lorryReceiptDetails[0]?.consignor?.city,
            consignorState: res?.data?.docs[0]?.lorryReceiptDetails[0]?.consignor?.state,
            consignorZip: res?.data?.docs[0]?.lorryReceiptDetails[0]?.consignor?.zip,
            consignorPhoneNo: res?.data?.docs[0]?.lorryReceiptDetails[0]?.consignor?.phone,
            consigneeName: res?.data?.docs[0]?.lorryReceiptDetails[0]?.consignee?.name,
            consigneeAddress: res?.data?.docs[0]?.lorryReceiptDetails[0]?.consignee?.address,
            consigneeGSTNo: res?.data?.docs[0]?.lorryReceiptDetails[0]?.consignee?.gstNo ? res?.data?.docs[0]?.lorryReceiptDetails[0]?.consignee?.gstNo : 0,
            consigneeCity: res?.data?.docs[0]?.lorryReceiptDetails[0]?.consignee?.city,
            consigneeState: res?.data?.docs[0]?.lorryReceiptDetails[0]?.consignee?.state,
            consigneeZip: res?.data?.docs[0]?.lorryReceiptDetails[0]?.consignee?.zip,
            consigneePhoneNo: res?.data?.docs[0]?.lorryReceiptDetails[0]?. consignee?.phone,
            form: {
              noOfPkgs: res?.data?.docs[0]?.lorryReceiptDetails[0]?.noOfPackages,
              packagingType: res?.data?.docs[0]?.lorryReceiptDetails[0]?.packagingType
                ? res?.data?.docs[0]?.lorryReceiptDetails[0]?.packagingType
                : this.state.isEditForm
                ? {
                    label: "dummy packaging type",
                    value: 952,
                  }
                : {},
              descriptionOfGoods: res?.data?.docs[0]?.lorryReceiptDetails[0]?.description,
              actualWeight: res?.data?.docs[0]?.lorryReceiptDetails[0]?.actualWeight,
              volumetricWeight: res?.data?.docs[0]?.lorryReceiptDetails[0]?.volumetricWeight,
              chargeableWeight: res?.data?.docs[0]?.lorryReceiptDetails[0]?.chargeableWeight,
              LDimension: res?.data?.docs[0]?.lorryReceiptDetails[0]?.dimension?.length,
              BDimension: res?.data?.docs[0]?.lorryReceiptDetails[0]?.dimension?.width,
              HDimension: res?.data?.docs[0]?.lorryReceiptDetails[0]?.dimension?.height,
              invoiceNo: res?.data?.docs[0]?.lorryReceiptDetails[0]?.invoiceNo,
              partNo: res?.data?.docs[0]?.lorryReceiptDetails[0]?.partNo,
              qty: res?.data?.docs[0]?.lorryReceiptDetails[0]?.quantity,
            },
          },
          () => {
           
          }
        );
      }
    );
  };

  warningToggle = () => {
    
    this.setState({ warningModal: !this.state.warningModal });
  };

  generatePdf = async (name) => {
    this.setState({ isOpen: true });
    const hiddenContainer = document.getElementById("hidden-pdf-container");

    const htmlContent = ` <div class="pdf-main-container">
          <div class="logo-details-container">
            <div class="logo-container">
              <div class="logo-address-container">
                <div class="logo">LOGO</div>
                <div class="logo-address">
                 <p>
                    PRICOL LOGISTICS PRIVATE LIMITED 965, HARITA CENTRE,
                    COIMBATORE-641037
                    <br />
                    GST NO: 33AAJCP7495E1Z4
                    <br />
                    CIN NO: U63090TZ2018PTC030178
                    <br />
                    PAN NO: AAJCP7495E Mob: 91 9629558255
                  </p>
                </div>
              </div>
              <div class="booking-code-container">
                <div class="booking">Booking Code</div>
                <div class="booking-value"></div>
              </div>
            </div>
            <div class="details-container">
              <div class="origin-desti-main-container">
                <div class="origin-desti-container">
                  <div class="origin">
                    <div class="origin-label">ORIGIN</div>
                    <div class="origin-value">${this.state.sourceName}</div>
                  </div>
                  <div class="desti">
                    <div class="desti-label">DESTINATION</div>
                    <div class="desti-value">${this.state.destinationName}</div>
                  </div>
                </div>
                <div class="origin-desti-container">
                  <div class="blank1">
                    <div class="blank1-label">LR NUMBER</div>
                    <div class="blank1-value">45625</div>
                  </div>
                  <div class="blank2">
                    <div class="blank2-label"></div>
                    <div class="blank2-value"></div>
                  </div>
                </div>
              </div>
              <div class="origin-desti-main-container">
                <div class="origin-desti-container">
                  <div class="desti">
                    <div class="invoice-label">INVOICE VALUE</div>
                    <div class="invoice-value">${this.state.invoiceValue}</div>
                  </div>
                  <div class="desti">
                    <div class="desti-label">MODE OF DISPATCH</div>
                    <div class="desti-value">${this.state.modeOfDispatch}</div>
                  </div>
                </div>
                <div class="origin-desti-container">
                  <div class="blank1">
                    <div class="blank11"></div>
                    <div class="blank12"></div>
                  </div>
                  <div class="blank2">
                    <div class="blank21"></div>
                    <div class="blank22"></div>
                  </div>
                </div>
              </div>
              <div class="eway-pay-container">
                <div class="eway-label">E-WAYBILL NO</div>
                <div class="eway-value">
                  ${this.state.sourceEwayBillNo}
                </div>
                <div class="pay-label">PAY / TO PAY / TBB</div>
              </div>
            </div>
          </div>
          <div class="consignor-consignee-container">
            <div class="consignor-details-container">
              <div class="consignor-container">
                <div class="consignor">
                  <div class="consignor-label">CONSIGNOR :-</div>
                  <div class="consignor-value">${this.state.consignorName}</div>
                </div>
                <div class="address">
                  <div class="address-label">ADDRESS :</div>
                  <div class="address-value">
                    ${this.state.consignorAddress}
                  </div>
                </div>
              </div>
              <div class="city-state">
                <div class="city">
                  <div class="city-label">
                    CITY :
                    <span class="city-value">${this.state.consignorCity}</span>
                  </div>
                </div>
                <div class="state">
                  <div class="state-label">
                    STATE :
                    <span class="state-value">
                      ${this.state.consignorState}
                    </span>
                  </div>
                </div>
              </div>
              <div class="city-state">
                <div class="city">
                  <div class="city-label">
                    CONTACT :
                    <span class="city-value">
                      ${this.state.consignorPhoneNo}
                    </span>
                  </div>
                </div>
                <div class="state">
                  <div class="state-label">
                    PINCODE :
                    <span class="state-value">${this.state.consignorZip}</span>
                  </div>
                </div>
              </div>
              <div class="city-state">
                <div class="city">
                  <div class="city-label">
                    PHONE NO :
                    <span class="city-value">
                      ${this.state.consignorPhoneNo}
                    </span>
                  </div>
                </div>
                <div class="state">
                  <div class="state-label">
                    GST NO :
                    <span class="state-value">
                      ${this.state.consignorGSTNo}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="consignor-details-container">
              <div class="consignee-container">
                <div class="consignor">
                  <div class="consignor-label">CONSIGNEE :-</div>
                  <div class="consignor-value">${this.state.consigneeName}</div>
                </div>
                <div class="address">
                  <div class="address-label">ADDRESS :</div>
                  <div class="address-value">
                    ${this.state.consigneeAddress}
                  </div>
                </div>
              </div>
              <div class="city-state">
                <div class="city-consignee">
                  <div class="city-label">
                    CITY :
                    <span class="city-value">${this.state.consigneeCity}</span>
                  </div>
                </div>
                <div class="state-consignee">
                  <div class="state-label">
                    STATE :
                    <span class="state-value">
                      ${this.state.consigneeState}
                    </span>
                  </div>
                </div>
              </div>
              <div class="city-state">
                <div class="city-consignee">
                  <div class="city-label">
                    CONTACT :
                    <span class="city-value">
                      ${this.state.consigneePhoneNo}
                    </span>
                  </div>
                </div>
                <div class="state-consignee">
                  <div class="state-label">
                    PINCODE :
                    <span class="state-value">${this.state.consigneeZip}</span>
                  </div>
                </div>
              </div>
              <div class="city-state">
                <div class="city-consignee">
                  <div class="city-label">
                    PHONE NO :
                    <span class="city-value">
                      ${this.state.consigneePhoneNo}
                    </span>
                  </div>
                </div>
                <div class="state-consignee">
                  <div class="state-label">
                    GST NO :
                    <span class="state-value">
                      ${this.state.consigneeGSTNo}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="parameters-container">
            <div class="parameters-1">
              <div class="packaging-container">
                <div class="no-pkgs">
                  <div class="no-pkgs-label">No of Pkgs</div>
                  <div class="no-pkgs-value">${this.state.form.noOfPkgs}</div>
                </div>
                <div class="pkg-type">
                  <div class="pkg-type-label">Packing Type</div>
                  <div class="pkg-type-value">
                    ${this.state.form.packagingType?.label}
                  </div>
                </div>
                <div class="disp-goods">
                  <div class="disp-goods-label">Description of Goods</div>
                  <div class="disp-goods-value">
                    ${this.state.form.descriptionOfGoods}
                  </div>
                </div>
                <div class="weight">
                  <div class="weight-label">Actual Weight</div>
                  <div class="weight-value">
                    ${this.state.form.actualWeight}
                  </div>
                </div>
              </div>
              <div class="part-no">
                <div class="part-no-label">Part No</div>
                <div class="part-no-value">${this.state.form.partNo}</div>
              </div>
            </div>
            <div class="parameters-2">
              <div class="weight-container">
                <div class="vol-weight">
                  <div class="vol-weight-label">Volumetric Weight</div>
                  <div class="vol-weight-value">
                    ${this.state.form.volumetricWeight}
                  </div>
                </div>
                <div class="charge-weight">
                  <div class="charge-weight-label">Chargeable Weight</div>
                  <div class="charge-weight-value">
                    ${this.state.form.chargeableWeight}
                  </div>
                </div>
              </div>
              <div class="qty">
                <div class="qty-label">Qty</div>
                <div class="qty-value">${this.state.form.qty}</div>
              </div>
            </div>
            <div class="parameters-3">
              <div class="dim">
                <div class="dim-label">
                  Dimensions
                  <br />L X B X H
                </div>
                <div class="dim-value">
                  ${this.state.form.LDimension}
                  <br />${this.state.form.BDimension}
                  <br />${this.state.form.HDimension}
                </div>
              </div>
              <div class="invoice-no">
                <div class="invoice-no-label">Invoice No</div>
                <div class="invoice-no-value">${this.state.form.invoiceNo}</div>
              </div>
            </div>
          </div>
          <div class="para-1">
            I/We agree to terms & conditions set out in the reserve of this
            Consignor’s copy & declare that contents on this way bill are true
            and correct. The To-pay Freight has my / our consent and will be
            paid by the Consignee along with Service Charges as applicable at
            the time of Delivery.
          </div>
          <div class="para-1">
            Pricol Logistics Pvt Ltd will not be liable for insurance. the same
            to be arranged by consignor / consignee. Pricol Logistics Pvt Ltd
            liability will be limited to issuing certificate of facts (COF)
            only. Liability Limit Up to Rs. 5000/- Only
          </div>
          <div class="bottom-container">
            <div class="part-1-container">
              <div class="received-goods">
                Received above shipment in order and in Good <br />
                Condition
              </div>
              <div class="receiver-sign">
                Name Date Time <br />
                Receiver’s Signature With Seal
              </div>
            </div>
            <div class="part-2-container">
              <div class="received-goods-value"></div>
              <div class="receiver-sign-value"></div>
            </div>
            <div class="part-3-container">
              <div class="name-date-time">
                Name Date Time <br />
                For Pricol Logistics Private Limited
              </div>
              <div class="autho-sign">Authorized Signatory</div>
            </div>
          </div>
          <div class="pdf-for" >${name}</div>
        </div>`;
    const element = document.createElement("div");
    element.style.position = "absolute";
    element.style.left = "0";
    element.style.top = "0";
    element.innerHTML = htmlContent;
    hiddenContainer.appendChild(element);
    // Wait for the layout to stabilize
    await new Promise((resolve) => setTimeout(resolve, 50));
    // Generate canvas from the hidden HTML content
    html2canvas(element).then((canvas) => {
      hiddenContainer.removeChild(element); // Clean up the temporary element
      this.setState({ isOpen: false });
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("landscape");
      pdf.addImage(imgData, "PNG", 0, 0);
      pdf.save(`${name}.pdf`);

      // Clean up the hidden container's content after generating the PDF

      // this.warningToggle();
      this.props.history.push({
        pathname: "/trips",
      });
    });
  };

  handleSubmitSave = (formValues) => {
    let {
      noOfPkgs,
      packagingType,
      descriptionOfGoods,
      actualWeight,
      volumetricWeight,
      chargeableWeight,
      LDimension,
      BDimension,
      HDimension,
      invoiceNo,
      partNo,
      qty,
    } = this.state.form;

    let form = {
      tripID: this.props.history.location.state?.LRCopyData?._id,
      transporter_name: this.state.transporterName,
      transporter_address: this.state.transporterAddress,
      transporter_gstNo: this.state.transporterGSTNo,
      transporter_cinNo: this.state.transporterCinNo,
      transporter_panNo: this.state.transporterPanNo,
      transporter_phNo: this.state.transporterPhNo,
      source_name: this.state.sourceName,
      source_ewaybillNo: +this.state.sourceEwayBillNo,
      destination_name: this.state.destinationName,
      destination_ewaybillNo: Number("0085"),
      invoiceValue: +this.state.invoiceValue,
      bookingDate: this.state.bookingDate,
      expectedDeliveryDate: this.state.expectedDeliveryDate,
      modeOfDispatch: this.state.modeOfDispatch,
      consignor_name: this.state.consignorName,
      consignor_address: this.state.consignorAddress,
      consignor_gstNo: this.state.consignorGSTNo,
      consignor_city: this.state.consignorCity,
      consignor_state: this.state.consignorState,
      consignor_zip: this.state.consignorZip,
      consignor_phoneNo: this.state.consignorPhoneNo,
      consignee_name: this.state.consigneeName,
      consignee_address: this.state.consigneeAddress,
      consignee_gstNo: this.state.consigneeGSTNo,
      consignee_city: this.state.consigneeCity,
      consignee_state: this.state.consigneeState,
      consignee_zip: this.state.consigneeZip,
      consignee_phoneNo: this.state.consigneePhoneNo,
      noOfPackages: +noOfPkgs,
      actualWeight: +actualWeight,
      volumetricWeight: +volumetricWeight,
      chargeableWeight: +chargeableWeight,
      quantity: +qty,
      length: +LDimension,
      width: +BDimension,
      height: +HDimension,
      invoiceNo: invoiceNo,
      partNo: partNo,
      description: descriptionOfGoods || "",
      packagingType: packagingType,
    };
    
     
      this.setState({ onSubmitDisable: true });
      this.props.createLRCopySecondary(form, (res) => {
        this.setState({ onSubmitDisable: false });
        if (res && res["message"] && res["message"] === "Successfully Added") {
          this.warningToggle();
          NotificationManager.success(res["message"], "Success");
        } else {
          NotificationManager.error(res["message"], "Error");
        }
      });
  
  };


  handleUpdateSave = () => {
  
    let {
      noOfPkgs,
      packagingType,
      descriptionOfGoods,
      actualWeight,
      volumetricWeight,
      chargeableWeight,
      LDimension,
      BDimension,
      HDimension,
      invoiceNo,
      partNo,
      qty,
    } = this.state.form;
  
    let form = {
      tripID: this.props.history.location.state?.LRCopyData?._id,
      transporter_name: this.state.transporterName,
      transporter_address: this.state.transporterAddress,
      transporter_gstNo: this.state.transporterGSTNo,
      transporter_cinNo: this.state.transporterCinNo,
      transporter_panNo: this.state.transporterPanNo,
      transporter_phNo: this.state.transporterPhNo,
      source_name: this.state.sourceName,
      source_ewaybillNo: +this.state.sourceEwayBillNo,
      destination_name: this.state.destinationName,
      destination_ewaybillNo: Number("0085"),
      invoiceValue: +this.state.invoiceValue,
      bookingDate: this.state.bookingDate,
      expectedDeliveryDate: this.state.expectedDeliveryDate,
      modeOfDispatch: this.state.modeOfDispatch,
      consignor_name: this.state.consignorName,
      consignor_address: this.state.consignorAddress,
      consignor_gstNo: this.state.consignorGSTNo,
      consignor_city: this.state.consignorCity,
      consignor_state: this.state.consignorState,
      consignor_zip: this.state.consignorZip,
      consignor_phoneNo: this.state.consignorPhoneNo,
      consignee_name: this.state.consigneeName,
      consignee_address: this.state.consigneeAddress,
      consignee_gstNo: this.state.consigneeGSTNo,
      consignee_city: this.state.consigneeCity,
      consignee_state: this.state.consigneeState,
      consignee_zip: this.state.consigneeZip,
      consignee_phoneNo: this.state.consigneePhoneNo,
      noOfPackages: +noOfPkgs,
      actualWeight: +actualWeight,
      volumetricWeight: +volumetricWeight,
      chargeableWeight: +chargeableWeight,
      quantity: +qty,
      length: +LDimension,
      width: +BDimension,
      height: +HDimension,
      invoiceNo: invoiceNo,
      partNo: partNo,
      description: descriptionOfGoods || "",
      packagingType: packagingType,
    };
  
    this.setState({ onSubmitDisable: true });
    const id = this.props.history.location.state?.LRCopyData?._id; 
    
    this.props.UpdateLRCopySecondary({ values: form, id: id }, (res) => {
      console.log("res is , ",res);
      if (res && res["message"] && res["message"] === "Successfully Added") {
        this.warningToggle();
        NotificationManager.success(res["message"], "Success");
      } else {
        NotificationManager.error(res["message"], "Error");
      }
    });
  };
  


  reset = (cb) => {
    

    this.setState(initialState(), () => {
      // console.log(this.state.form);
      cb();
    });
  };

  handleSubmitChange = (e) => {
    // console.log(e);
    // console.log(JSON.parse(JSON.stringify(this.state.form)));

    let formData = this.state.form;

    if (e.target.name === "packagingType") {
      formData.packagingType = e.target.value;
      // formData.packagingType.label;
    } else {
      const { name, value } = e.target;
      formData[`${name}`] = value;
    }

    this.setState({ form: formData });
  };

  onChangeTextarea = (event) => {
    console.log("onChangeTextarea");

    const { name, value } = event.target;
    // console.log(event.target);
    this.setState({ [name]: value });
  };

  isEditFormOpen = () => {
    

    this.setState({ isEditForm: !this.state.isEditForm }, () => {
      this.props.fetchLRCopy(
        this.props.history.location.state?.LRCopyData?._id,
        (res) => {
         
          
          this.setState({
            packagingType: [
              {
                label: "dummy packaging type",
                value: 952,
              },
              {
                label: "dummy1 packaging type",
                value: 950,
              },
            ],
            transporterName: res?.data?.docs[0]?.transporter?.name,
            transporterAddress: res?.data?.docs[0]?.transporter?.address,
            transporterGSTNo: res?.data?.docs[0]?.transporter?.gstNo,
            transporterCinNo: res?.data?.docs[0]?.transporter?.cinNo,
            transporterPanNo: res?.data?.docs[0]?.transporter?.panNo,
            transporterPhNo: res?.data?.docs[0]?.transporter?.phone,
            sourceName: res?.data?.docs[0]?.source?.name,
            sourceEwayBillNo: +res?.data?.docs[0]?.source?.ewayBillNo,
            destinationName: res?.data?.docs[0]?.destination?.name,
            destinationEwayBillNo: Number(
              res?.data?.docs[0]?.destination?.ewayBillNo
            ),
            invoiceValue: +res?.data?.docs[0]?.lorryReceiptDetails[0]?.invoiceValue,
            bookingDate: moment(res?.data?.docs[0]?.bookingDate).format(
              "MM-DD-YYYY"
            ),
            expectedDeliveryDate: moment(
              res?.data?.docs[0]?.expectedDeliveryDate
            ).format("MM-DD-YYYY"),
            modeOfDispatch: res?.data?.docs[0]?.lorryReceiptDetails[0]?.modeOfDispatch,
            consignorName: res?.data?.docs[0]?.consignor?.name,
            consignorAddress: res?.data?.docs[0]?.consignor?.address,
            consignorGSTNo: res?.data?.docs[0]?.consignor?.gstNo,
            consignorCity: res?.data?.docs[0]?.consignor?.city,
            consignorState: res?.data?.docs[0]?.consignor?.state,
            consignorZip: res?.data?.docs[0]?.consignor?.zip,
            consignorPhoneNo: res?.data?.docs[0]?.consignor?.phone,
            consigneeName: res?.data?.docs[0]?.consignee?.name,
            consigneeAddress: res?.data?.docs[0]?.consignee?.address,
            consigneeGSTNo: res?.data?.docs[0]?.consignee?.gstNo,
            consigneeCity: res?.data?.docs[0]?.consignee?.city,
            consigneeState: res?.data?.docs[0]?.consignee?.state,
            consigneeZip: res?.data?.docs[0]?.consignee?.zip,
            consigneePhoneNo: res?.data?.docs[0]?.consignee?.phone,
            form: {
              noOfPkgs: res?.data?.docs[0]?.noOfPackages,
              packagingType: res?.data?.docs[0]?.packagingType
                ? res?.data?.docs[0]?.packagingType
                : this.state.packagingType[0].label,
              descriptionOfGoods: res?.data?.docs[0]?.description,
              actualWeight: res?.data?.docs[0]?.actualWeight,
              volumetricWeight: res?.data?.docs[0]?.volumetricWeight,
              chargeableWeight: res?.data?.docs[0]?.chargeableWeight,
              LDimension: res?.data?.docs[0]?.lorryReceiptDetails[0]?.dimension?.length,
              BDimension: res?.data?.docs[0]?.lorryReceiptDetails[0]?.dimension?.width,
              HDimension: res?.data?.docs[0]?.lorryReceiptDetails[0]?.dimension?.height,
              invoiceNo: res?.data?.docs[0]?.lorryReceiptDetails[0]?.invoiceNo,
              partNo: res?.data?.docs[0]?.partNo,
              qty: res?.data?.docs[0]?.quantity,
            },
          });
        }
      );
    });
    // console.log(this.state.isEditForm);
  };

  render() {
   
   
    
    const form = this.state.form;
    const validationSchema1 = Yup.object().shape({
      noOfPkgs: Yup.number()
        .required("No of packages is required")
        .nullable(true),
      packagingType: Yup.object().required("Packaging type is required"),
      invoiceNo: Yup.string()
        .required("Invoice number of goods is required")
        .nullable(true),
      actualWeight: Yup.number()
        .required("Actual weight of goods is required")
        .nullable(true),
      volumetricWeight: Yup.number()
        .required("Volumetric weight of goods is required")
        .nullable(true),
      chargeableWeight: Yup.number()
        .required("Chargeable weight of goods is required")
        .nullable(true),
      qty: Yup.number()
        .required("Quantity of goods is required")
        .nullable(true),
      LDimension: Yup.number()
        .required("L dimension of goods is required")
        .nullable(true),
      BDimension: Yup.number()
        .required("B dimension of goods is required")
        .nullable(true),
      HDimension: Yup.number()
        .required("H dimension of goods is required")
        .nullable(true),
      partNo: Yup.string()
        .required("Part number of goods is required")
        .nullable(true),
    });

    
    return (
      <React.Fragment>
        {this.state.isOpen && (
          <div
            style={{
              position: "fixed",
              top: "0",
              left: "0",
              width: "125vw",
              height: "125vh",
              zIndex: "9999",
              background: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <WaveLoader />
          </div>
        )}
        <div id="hidden-pdf-container" style={{ zIndex: "-99" }}></div>

        <Card
          style={{
            width: "100%",
            paddingTop: "22px",
            paddingBottom: "22px",
            paddingRight: "24px",
            height: "68px",
          }}
          className="darkCardStyle mt-3"
        >
          <Row style={{ width: "100%" }}>
            <Col xs={11}>
              <div>
                {/* <span class="fontRoboto15 light__span">Trip Id :</span>{" "} */}
                <span class="fontRoboto154 ml-2 dark__span">
                  {/* {
                    this.props.history?.location?.state?.tripDetailsForEPOD
                      ?.tripId
                  } */}
                </span>
              </div>
            </Col>
            <Col xs={1}>
              <img
                src={playBtn}
                alt="exp"
                style={{ marginLeft: "75px" }}
                onClick={() =>
                  this.props.history.push({
                    pathname: "/trips",
                  })
                }
              />
            </Col>
          </Row>
        </Card>

        {this.props.history.location.state?.editButton && (
          <div
            style={{
              backgroundColor: "white",
              maxHeight: "96vh",
              overflow: "auto",
            }}
            className="lr-form"
          >
            <Formik
              initialValues={form}
              onSubmit={this.handleSubmitSave}
              validateOnChange={true}
              // validationSchema={() => validationSchema1}
            >
              {(formik) => {
                const { handleSubmit, errors, handleBlur, resetForm } = formik;
                // console.log(errors);
                return (
                  <form style={{ height: "100%" }}>
                    <div
                      className="LRCopyFontLabel"
                      style={{ paddingTop: "0 !important" }}
                    >
                      LR Details
                    </div>
                    <div className="separator"></div>
                    <div className="LRCopyFont">
                      <div>
                        <div
                          style={{
                            marginBottom: "18px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ flex: "1" }}>Transporter:</div>
                          <Input
                            name="transporterName"
                            id="transporterName"
                            value={this.state.transporterName}
                            style={{ marginLeft: "8px", flex: "3" }}
                            onChange={this.onChangeTextarea}
                          ></Input>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ flex: "1" }}>PAN No:</div>

                          <Input
                            name="transporterPanNo"
                            id="transporterPanNo"
                            value={this.state.transporterPanNo}
                            style={{ marginLeft: "8px", flex: "3" }}
                            onChange={this.onChangeTextarea}
                          ></Input>
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            marginBottom: "18px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ flex: "1" }}>Address:</div>
                          <Input
                            name="transporterAddress"
                            id="transporterAddress"
                            value={this.state.transporterAddress}
                            style={{ marginLeft: "8px", flex: "3" }}
                            onChange={this.onChangeTextarea}
                          ></Input>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ flex: "1" }}>Mobile No:</div>

                          <Input
                            name="transporterPhNo"
                            id="transporterPhNo"
                            value={this.state.transporterPhNo}
                            style={{ marginLeft: "8px", flex: "3" }}
                            onChange={this.onChangeTextarea}
                          ></Input>
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ flex: "1" }}>GST No:</div>

                          <Input
                            name="transporterGSTNo"
                            id="transporterGSTNo"
                            value={this.state.transporterGSTNo}
                            style={{
                              marginLeft: "8px",
                              flex: "3",
                              height: "43.77px",
                            }}
                            onChange={this.onChangeTextarea}
                          ></Input>
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ flex: "1" }}>CIN No:</div>

                          <Input
                            name="transporterCinNo"
                            id="transporterCinNo"
                            value={this.state.transporterCinNo}
                            style={{
                              marginLeft: "8px",
                              flex: "3",
                              height: "43.77px",
                            }}
                            onChange={this.onChangeTextarea}
                          ></Input>
                        </div>
                      </div>
                    </div>
                    <div className="separator"></div>
                    <div className="LRCopyFont">
                      <div>
                        <div
                          style={{
                            marginBottom: "18px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ flex: "1" }}>Source:</div>

                          <Input
                            name="sourceName"
                            id="sourceName"
                            value={this.state.sourceName}
                            style={{ marginLeft: "8px", flex: "3" }}
                            onChange={this.onChangeTextarea}
                          ></Input>
                        </div>
                        <div
                          style={{
                            marginBottom: "18px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ flex: "1" }}>E-Way Bill No:</div>

                          <Input
                            type="number"
                            name="sourceEwayBillNo"
                            id="sourceEwayBillNo"
                            value={this.state.sourceEwayBillNo}
                            style={{ marginLeft: "8px", flex: "3" }}
                            onChange={this.onChangeTextarea}
                          ></Input>
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            marginBottom: "18px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ flex: "1" }}>Destination:</div>

                          <Input
                            name="destinationName"
                            id="destinationName"
                            value={this.state.destinationName}
                            style={{ marginLeft: "8px", flex: "3" }}
                            onChange={this.onChangeTextarea}
                          ></Input>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {/* <div style={{ flex: "1" }}>E-Way Bill No:</div>

                        <textarea
                          name="destinationEwayBillNo"
                          value={this.state.destinationEwayBillNo}
                          style={{ marginLeft: "8px", flex: "3" }}
                          onChange={this.onChangeTextarea}
                        ></textarea> */}
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            marginBottom: "18px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ flex: "1" }}>Invoice Value:</div>

                          <Input
                            type="number"
                            name="invoiceValue"
                            id="invoiceValue"
                            value={this.state.invoiceValue}
                            style={{ marginLeft: "8px", flex: "3" }}
                            onChange={this.onChangeTextarea}
                          ></Input>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ flex: "1" }}>Booking Date:</div>
                          <div
                            style={{
                              // height: "45px",
                              marginLeft: "8px",
                              flex: "3",
                              //borderBottom: "0.5px solid #AEAEAE",
                            }}
                          >
                            <Datetime
                              value={this.state.bookingDate}
                              dateFormat={"DD/MM/YYYY"}
                              // className="d-flex flex-sm-grow-1 time-cont"
                              name="bookingDate"
                              onChange={(e) => {
                                this.handleSubmitChange({
                                  target: {
                                    name: "bookingDate",
                                    value: e._d,
                                  },
                                });
                              }}
                              onBlur={handleBlur}
                              id="bookingDate"

                              // ref={this.startdate}
                            />
                          </div>
                        </div>
                      </div>{" "}
                      <div>
                        <div
                          style={{
                            marginBottom: "18px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ flex: "1" }}>Mode of Dispatch:</div>

                          <Input
                            name="modeOfDispatch"
                            id="modeOfDispatch"
                            value={this.state.modeOfDispatch}
                            style={{ marginLeft: "8px", flex: "3" }}
                            onChange={this.onChangeTextarea}
                          ></Input>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ flex: "1" }}>
                            Expected Delivery Date:
                          </div>
                          <div
                            style={{
                              // height: "45px",
                              marginLeft: "8px",
                              flex: "3",
                              //borderBottom: "0.5px solid #AEAEAE",
                            }}
                          >
                            <Datetime
                              value={this.state.expectedDeliveryDate}
                              dateFormat={"DD/MM/YYYY"}
                              // className="d-flex flex-sm-grow-1 time-cont"
                              name="expectedDeliveryDate"
                              onChange={(e) => {
                                this.handleSubmitChange({
                                  target: {
                                    name: "expectedDeliveryDate",
                                    value: e._d,
                                  },
                                });
                              }}
                              onBlur={handleBlur}
                              id="expectedDeliveryDate"

                              // ref={this.startdate}
                            />
                          </div>
                          {/* <textarea
                            name="expectedDeliveryDate"
                            value={this.state.expectedDeliveryDate}
                            style={{ marginLeft: "8px", flex: "3" }}
                            onChange={this.onChangeTextarea}
                          ></textarea> */}
                        </div>
                      </div>
                    </div>
                    <div className="ConsignorMainBcg">
                      <div className="ConsignorBcg" style={{ padding: "16px" }}>
                        <div
                          className="ConsignorValue"
                          style={{
                            marginBottom: "16px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span
                            className="ConsignorLabel"
                            style={{ flex: "1" }}
                          >
                            Consignor:{" "}
                          </span>
                          <Input
                            name="consignorName"
                            id="consignorName"
                            value={this.state.consignorName}
                            style={{ marginLeft: "8px", flex: "3" }}
                            onChange={this.onChangeTextarea}
                          ></Input>
                          <div style={{ flex: 6 }}></div>
                        </div>
                        <div
                          className="ConsignorFont"
                          style={{
                            marginBottom: "16px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span
                            className="ConsignorFontDiv"
                            style={{ flex: "1" }}
                          >
                            Address:
                          </span>
                          <Input
                            name="consignorAddress"
                            id="consignorAddress"
                            value={this.state.consignorAddress}
                            style={{
                              marginLeft: "8px",
                              flex: "9",
                            }}
                            onChange={this.onChangeTextarea}
                          ></Input>
                        </div>
                        <div
                          className="ConsignorFont ConsignorFontDiv"
                          style={{
                            marginBottom: "16px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flex: "2",
                            }}
                          >
                            <span
                              className="ConsignorFontDiv"
                              style={{ flex: "1" }}
                            >
                              City:
                            </span>
                            <Input
                              name="consignorCity"
                              id="consignorCity"
                              value={this.state.consignorCity}
                              style={{
                                marginLeft: "8px",
                                flex: "9",
                                height: "23px",
                              }}
                              onChange={this.onChangeTextarea}
                            ></Input>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flex: "2",
                              marginLeft: "16px",
                            }}
                          >
                            <span
                              className="ConsignorFontDiv"
                              style={{ flex: "1" }}
                            >
                              State:
                            </span>
                            <Input
                              name="consignorState"
                              id="consignorState"
                              value={this.state.consignorState}
                              style={{
                                marginLeft: "8px",
                                flex: "9",
                                height: "23px",
                              }}
                              onChange={this.onChangeTextarea}
                            ></Input>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flex: "2",
                              marginLeft: "16px",
                            }}
                          >
                            <span
                              className="ConsignorFontDiv"
                              style={{ flex: "1" }}
                            >
                              Contact:
                            </span>
                            <Input
                              name="consignorPhoneNo"
                              id="consignorPhoneNo"
                              value={this.state.consignorPhoneNo}
                              style={{
                                marginLeft: "8px",
                                flex: "9",
                                height: "23px",
                              }}
                              onChange={this.onChangeTextarea}
                            ></Input>
                          </div>
                        </div>
                        <div
                          className="ConsignorFont ConsignorFontDiv"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flex: "2",
                            }}
                          >
                            <span
                              className="ConsignorFontDiv"
                              style={{ flex: "1" }}
                            >
                              Pin Code:
                            </span>
                            <Input
                              name="consignorZip"
                              id="consignorZip"
                              value={this.state.consignorZip}
                              style={{
                                marginLeft: "8px",
                                flex: "9",
                                height: "23px",
                              }}
                              onChange={this.onChangeTextarea}
                            ></Input>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flex: "2",
                              marginLeft: "8px",
                            }}
                          >
                            <span
                              className="ConsignorFontDiv"
                              style={{ flex: "1" }}
                            >
                              Phone No:
                            </span>
                            <Input
                              name="consignorPhoneNo"
                              id="consignorPhoneNo"
                              value={this.state.consignorPhoneNo}
                              style={{
                                marginLeft: "8px",
                                flex: "9",
                                height: "23px",
                              }}
                              onChange={this.onChangeTextarea}
                            ></Input>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flex: "2",
                              marginLeft: "8px",
                            }}
                          >
                            <span
                              className="ConsignorFontDiv"
                              style={{ flex: "1" }}
                            >
                              GST No:
                            </span>
                            <Input
                              name="consignorGSTNo"
                              id="consignorGSTNo"
                              value={this.state.consignorGSTNo}
                              style={{
                                marginLeft: "8px",
                                flex: "9",
                                height: "23px",
                              }}
                              onChange={this.onChangeTextarea}
                            ></Input>
                          </div>
                        </div>
                      </div>
                      <div className="ConsignorBcg" style={{ padding: "16px" }}>
                        <div
                          className="ConsignorValue"
                          style={{
                            marginBottom: "16px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span
                            className="ConsignorLabel"
                            style={{ flex: "1" }}
                          >
                            Consignee:{" "}
                          </span>

                          <Input
                            name="consigneeName"
                            id="consigneeName"
                            value={this.state.consigneeName}
                            style={{ marginLeft: "8px", flex: "3" }}
                            onChange={this.onChangeTextarea}
                          ></Input>
                          <div style={{ flex: 6 }}></div>
                        </div>
                        <div
                          className="ConsignorFont"
                          style={{
                            marginBottom: "16px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span
                            className="ConsignorFontDiv"
                            style={{ flex: "1" }}
                          >
                            Address:
                          </span>
                          <Input
                            name="consigneeAddress"
                            id="consigneeAddress"
                            value={this.state.consigneeAddress}
                            style={{
                              marginLeft: "8px",
                              flex: "9",
                            }}
                            onChange={this.onChangeTextarea}
                          ></Input>
                        </div>
                        <div
                          className="ConsignorFont ConsignorFontDiv"
                          style={{
                            marginBottom: "16px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flex: "2",
                            }}
                          >
                            <span
                              className="ConsignorFontDiv"
                              style={{ flex: "1" }}
                            >
                              City:
                            </span>
                            <Input
                              name="consigneeCity"
                              id="consigneeCity"
                              value={this.state.consigneeCity}
                              style={{
                                marginLeft: "8px",
                                flex: "9",
                                height: "23px",
                              }}
                              onChange={this.onChangeTextarea}
                            ></Input>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flex: "2",
                              marginLeft: "8px",
                            }}
                          >
                            <span
                              className="ConsignorFontDiv"
                              style={{ flex: "1" }}
                            >
                              State:
                            </span>
                            <Input
                              name="consigneeState"
                              id="consigneeState"
                              value={this.state.consigneeState}
                              style={{
                                marginLeft: "8px",
                                flex: "9",
                                height: "23px",
                              }}
                              onChange={this.onChangeTextarea}
                            ></Input>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flex: "2",
                              marginLeft: "8px",
                            }}
                          >
                            <span
                              className="ConsignorFontDiv"
                              style={{ flex: "1" }}
                            >
                              Contact:
                            </span>
                            <Input
                              name="consigneePhoneNo"
                              id="consigneePhoneNo"
                              value={this.state.consigneePhoneNo}
                              style={{
                                marginLeft: "8px",
                                flex: "9",
                                height: "23px",
                              }}
                              onChange={this.onChangeTextarea}
                            ></Input>
                          </div>
                        </div>
                        <div
                          className="ConsignorFont ConsignorFontDiv"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flex: "2",
                            }}
                          >
                            <span
                              className="ConsignorFontDiv"
                              style={{ flex: "1" }}
                            >
                              Pin Code:
                            </span>
                            <Input
                              name="consigneeZip"
                              id="consigneeZip"
                              value={this.state.consigneeZip}
                              style={{
                                marginLeft: "8px",
                                flex: "9",
                                height: "23px",
                              }}
                              onChange={this.onChangeTextarea}
                            ></Input>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flex: "2",
                              marginLeft: "8px",
                            }}
                          >
                            <span
                              className="ConsignorFontDiv"
                              style={{ flex: "1" }}
                            >
                              Phone No:
                            </span>
                            <Input
                              name="consigneePhoneNo"
                              id="consigneePhoneNo"
                              value={this.state.consigneePhoneNo}
                              style={{
                                marginLeft: "8px",
                                flex: "9",
                                height: "23px",
                              }}
                              onChange={this.onChangeTextarea}
                            ></Input>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flex: "2",
                              marginLeft: "8px",
                            }}
                          >
                            <span
                              className="ConsignorFontDiv"
                              style={{ flex: "1" }}
                            >
                              GST No:
                            </span>
                            <Input
                              name="consigneeGSTNo"
                              id="consigneeGSTNo"
                              value={this.state.consigneeGSTNo}
                              style={{
                                marginLeft: "8px",
                                flex: "9",
                                height: "23px",
                              }}
                              onChange={this.onChangeTextarea}
                            ></Input>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="separator"
                      style={{ marginBottom: "0px" }}
                    ></div>

                    <div
                      style={{
                        paddingTop: "22px",
                        paddingRight: "22px",
                        paddingLeft: "22px",
                        paddingBottom: "50px",
                      }}
                    >
                      <div className="fieldsRow">
                        <div style={{ flex: "1" }}>
                          <div className="fieldsLabel">
                            No of Pkgs{" "}
                            <span className="fieldsLabelStar">*</span>
                          </div>
                          <div>
                            <Input
                              id="noOfPkgs"
                              name="noOfPkgs"
                              type="number"
                              placeholder="Type here..."
                              value={form?.noOfPkgs || ""}
                              onChange={(e) => {
                                this.handleSubmitChange(e);
                              }}
                            />
                            <ErrorMessage
                              name="noOfPkgs"
                              component="span"
                              className="error"
                            />
                          </div>
                        </div>
                        <div style={{ flex: "2" }}>
                          <div className="fieldsLabel">
                            Packaging Type{" "}
                            <span className="fieldsLabelStar">*</span>
                          </div>
                          <div>
                            <Select
                              name="packagingType"
                              id="packagingType"
                              isMulti={false}
                              className="selectField"
                              classNamePrefix="select2-selection1"
                              placeholder="select..."
                              width="336px"
                              value={this.state.form.packagingType}
                              options={this.state.packagingType}
                              onChange={(e) => {
                                this.handleSubmitChange({
                                  target: {
                                    name: "packagingType",
                                    value: e,
                                  },
                                });
                              }}
                            />
                            <ErrorMessage
                              name="packagingType"
                              component="span"
                              className="error"
                            />
                          </div>
                        </div>
                        <div style={{ flex: "2" }}>
                          <div className="fieldsLabel">
                            Description of Goods
                          </div>
                          <div>
                            <Input
                              id="descriptionOfGoods "
                              name="descriptionOfGoods"
                              type="text"
                              placeholder="Type here..."
                              value={form?.descriptionOfGoods}
                              onChange={(e) => {
                                this.handleSubmitChange(e);
                              }}
                            />
                          </div>
                        </div>
                        <div style={{ flex: "1" }}>
                          <div className="fieldsLabel">
                            Actual Weight{" "}
                            <span className="fieldsLabelStar">*</span>
                          </div>
                          <div>
                            <Input
                              id="actualWeight"
                              name="actualWeight"
                              type="number"
                              placeholder="Type here..."
                              value={form?.actualWeight || ""}
                              onChange={(e) => {
                                this.handleSubmitChange(e);
                              }}
                            />
                            <ErrorMessage
                              name="actualWeight"
                              component="span"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="fieldsRow">
                        <div style={{ flex: "1" }}>
                          <div className="fieldsLabel">
                            Volumetric Weight{" "}
                            <span className="fieldsLabelStar">*</span>
                          </div>
                          <div>
                            <Input
                              id="volumetricWeight"
                              name="volumetricWeight"
                              type="number"
                              placeholder="Type here..."
                              value={form?.volumetricWeight || ""}
                              onChange={(e) => {
                                this.handleSubmitChange(e);
                              }}
                            />
                            <ErrorMessage
                              name="volumetricWeight"
                              component="span"
                              className="error"
                            />
                          </div>
                        </div>
                        <div style={{ flex: "1" }}>
                          <div className="fieldsLabel">
                            Chargeable Weight{" "}
                            <span className="fieldsLabelStar">*</span>
                          </div>
                          <div>
                            <Input
                              id="chargeableWeight"
                              name="chargeableWeight"
                              type="number"
                              placeholder="Type here..."
                              value={form?.chargeableWeight || ""}
                              onChange={(e) => {
                                this.handleSubmitChange(e);
                              }}
                            />
                            <ErrorMessage
                              name="chargeableWeight"
                              component="span"
                              className="error"
                            />
                          </div>
                        </div>
                        <div style={{ flex: "2" }}>
                          <div className="fieldsLabel">
                            Dimension <span className="fieldsLabelStar">*</span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "8px",
                              alignItems: "center",
                            }}
                          >
                            <div className="LBHStyle">
                              <div>L: </div>
                              <div>
                                <Input
                                  id="LDimension"
                                  name="LDimension"
                                  type="number"
                                  placeholder="Type here..."
                                  value={form?.LDimension || ""}
                                  onChange={(e) => {
                                    this.handleSubmitChange(e);
                                  }}
                                />
                                <ErrorMessage
                                  name="LDimension"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="LBHStyle">
                              <div>B: </div>
                              <div>
                                <Input
                                  id="BDimension"
                                  name="BDimension"
                                  type="number"
                                  placeholder="Type here..."
                                  value={form?.BDimension || ""}
                                  onChange={(e) => {
                                    this.handleSubmitChange(e);
                                  }}
                                />
                                <ErrorMessage
                                  name="BDimension"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="LBHStyle">
                              <div>H: </div>
                              <div>
                                <Input
                                  id="HDimension"
                                  name="HDimension"
                                  type="number"
                                  placeholder="Type here..."
                                  value={form?.HDimension || ""}
                                  onChange={(e) => {
                                    this.handleSubmitChange(e);
                                  }}
                                />
                                <ErrorMessage
                                  name="HDimension"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ flex: "2" }}>
                          <div className="fieldsLabel">
                            Invoice No{" "}
                            <span className="fieldsLabelStar">*</span>
                          </div>
                          <div>
                            <Input
                              id="invoiceNo"
                              name="invoiceNo"
                              type="text"
                              placeholder="Type here..."
                              value={form?.invoiceNo}
                              onChange={(e) => {
                                this.handleSubmitChange(e);
                              }}
                            />
                            <ErrorMessage
                              name="invoiceNo"
                              component="span"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="fieldsRow"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div style={{ flex: 1 }}>
                          <div className="fieldsLabel">
                            Part No <span className="fieldsLabelStar">*</span>
                          </div>
                          <div>
                            <Input
                              id="partNo"
                              name="partNo"
                              type="text"
                              placeholder="Type here..."
                              value={form?.partNo}
                              onChange={(e) => {
                                this.handleSubmitChange(e);
                              }}
                            />
                            <ErrorMessage
                              name="partNo"
                              component="span"
                              className="error"
                            />
                          </div>
                        </div>
                        <div style={{ flex: 1 }}>
                          <div className="fieldsLabel">
                            Quantity <span className="fieldsLabelStar">*</span>
                          </div>
                          <div>
                            <Input
                              id="qty"
                              name="qty"
                              type="number"
                              placeholder="Type here..."
                              value={form?.qty || ""}
                              onChange={(e) => {
                                this.handleSubmitChange(e);
                              }}
                            />
                            <ErrorMessage
                              name="qty"
                              component="span"
                              className="error"
                            />
                          </div>
                        </div>
                        <div style={{ flex: 4 }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              justifyContent: "flex-end",
                              gap: "16px",
                              height: "100%",
                            }}
                          >
                            <div>
                              <Button
                                className="fontRoboto1541"
                                style={{
                                  width: "117px",
                                  backgroundColor: "#0AB38C",
                                  color: "#FFFFFF",
                                  paddingTop: "6px",
                                  paddingBottom: "6px",
                                }}
                                color="secondary"
                                onClick={() => this.reset(resetForm)}
                              >
                                Reset
                              </Button>
                            </div>
                            <div className="exclude-from-pdf">
                              {/* <Button
                                className="fontRoboto1541"
                                style={{
                                  width: "117px",
                                  backgroundColor: "#05284E",
                                  color: "#FFFFFF",
                                }}
                                color="primary"
                                type="submit"
                                disabled={this.state.onSubmitDisable}
                              >
                                 {this.state.islorryReceiptTrue ? 'Update' : 'Submit'}
                              </Button> */}

                        {!this.state.islorryReceiptTrue ? (
                              <Button
                                className="fontRoboto1541"
                                style={{
                                  width: "117px",
                                  backgroundColor: "#05284E",
                                  color: "#FFFFFF",
                                }}
                                color="primary"
                                type="button" 
                                onClick={this.handleSubmitSave} 
                                disabled={this.state.onSubmitDisable}
                              >
                                Submit
                              </Button>
                            ) : (
                              <Button
                                className="fontRoboto1541"
                                style={{
                                  width: "117px",
                                  backgroundColor: "#05284E",
                                  color: "#FFFFFF",
                                }}
                                color="primary"
                                type="button" 
                                onClick={this.handleUpdateSave} 
                                disabled={this.state.onSubmitDisable}
                              >
                                Update
                              </Button>
                            )}

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        )}

        {!this.props.history.location.state?.editButton && (
          <div>
            {" "}
            <Card className="darkCardStyle p-2">
              <CardBody>
                <div>
                  <Row>
                    <Col md={8} sm={6} xs={12}></Col>

                    <Col
                      md={4}
                      sm={6}
                      xs={12}
                      className="d-flex flex-column align-items-end"
                    >
                      <div className="fontRoboto154 menu-span"></div>
                      <div className="p-1 light__span"></div>
                    </Col>
                  </Row>
                  <Row className="menu-element ml-0">
                    <Col xs={12} md={2} className="mb-1 menu-child-element ">
                      <div
                        className="menu-span"
                        onClick={() => {
                          this.generatePdf("Transporter Copy");
                        }}
                      >
                        Transporter Copy
                      </div>
                    </Col>
                    <Col xs={12} md={2} className="mb-1 menu-child-element ">
                      <div
                        className="menu-span"
                        onClick={() => {
                          this.generatePdf("Consignor Copy");
                        }}
                      >
                        Consignor Copy
                      </div>
                    </Col>
                    <Col xs={12} md={2} className="mb-1 menu-child-element ">
                      <div
                        className="menu-span"
                        onClick={() => {
                          this.generatePdf("Consignee Copy");
                        }}
                      >
                        Consignee Copy
                      </div>
                    </Col>
                    <Col xs={12} md={2} className="mb-1 menu-child-element ">
                      <div
                        className="menu-span"
                        style={{ paddingLeft: "817px" }}
                      >
                        <Button
                          className="fontRoboto1541"
                          style={{
                            width: "117px",
                            backgroundColor: "#05284E",
                            color: "#FFFFFF",
                          }}
                          color="primary"
                          onClick={this.isEditFormOpen}
                        >
                          Edit
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <hr className="mt-2"></hr>
                </div>
              </CardBody>
            </Card>
            {this.state.isEditForm && (
              <div
                style={{
                  backgroundColor: "white",
                  maxHeight: "76vh",
                  overflow: "auto",
                }}
                className="lr-form"
              >
                <Formik
                  initialValues={form}
                  onSubmit={this.handleSubmitSave}
                  validateOnChange={false}
                  // validationSchema={() => validationSchema}
                >
                  {(formik) => {
                    const { handleSubmit, errors, handleBlur, resetForm } =
                      formik;
                    
                    return (
                      <form  style={{ height: "100%" }}>
                        <div
                          className="LRCopyFontLabel"
                          style={{ paddingTop: "0 !important" }}
                        >
                          LR Details
                        </div>
                        <div className="separator"></div>
                        <div className="LRCopyFont">
                          <div>
                            <div
                              style={{
                                marginBottom: "18px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ flex: "1" }}>Transporter:</div>

                              <Input
                                name="transporterName"
                                value={this.state.transporterName}
                                style={{ marginLeft: "8px", flex: "3" }}
                                onChange={this.onChangeTextarea}
                              />

                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ flex: "1" }}>PAN No:</div>

                              <Input
                                name="transporterPanNo"
                                value={this.state.transporterPanNo}
                                style={{ marginLeft: "8px", flex: "3" }}
                                onChange={this.onChangeTextarea}
                              />
                            </div>
                          </div>
                          <div>
                            <div
                              style={{
                                marginBottom: "18px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ flex: "1" }}>Address:</div>
                              <Input
                                name="transporterAddress"
                                value={this.state.transporterAddress}
                                style={{ marginLeft: "8px", flex: "3" }}
                                onChange={this.onChangeTextarea}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ flex: "1" }}>Mobile No:</div>

                              <Input
                                name="transporterPhNo"
                                value={this.state.transporterPhNo}
                                style={{ marginLeft: "8px", flex: "3" }}
                                onChange={this.onChangeTextarea}
                              />
                            </div>
                          </div>

                          <div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ flex: "1" }}>GST No:</div>

                              <Input
                                name="transporterGSTNo"
                                value={this.state.transporterGSTNo}
                                style={{
                                  marginLeft: "8px",
                                  flex: "3",
                                  height: "43.77px",
                                }}
                                onChange={this.onChangeTextarea}
                              />
                            </div>
                          </div>
                          <div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ flex: "1" }}>CIN No:</div>

                              <Input
                                name="transporterCinNo"
                                value={this.state.transporterCinNo}
                                style={{
                                  marginLeft: "8px",
                                  flex: "3",
                                  height: "43.77px",
                                }}
                                onChange={this.onChangeTextarea}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="separator"></div>
                        <div className="LRCopyFont">
                          <div>
                            <div
                              style={{
                                marginBottom: "18px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ flex: "1" }}>Source:</div>

                              <Input
                                name="sourceName"
                                value={this.state.sourceName}
                                style={{ marginLeft: "8px", flex: "3" }}
                                onChange={this.onChangeTextarea}
                              />
                            </div>
                            <div
                              style={{
                                marginBottom: "18px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ flex: "1" }}>E-Way Bill No:</div>

                              <Input
                                type="number"
                                name="sourceEwayBillNo"
                                value={this.state.sourceEwayBillNo}
                                style={{ marginLeft: "8px", flex: "3" }}
                                onChange={this.onChangeTextarea}
                              />
                            </div>
                          </div>
                          <div>
                            <div
                              style={{
                                marginBottom: "18px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ flex: "1" }}>Destination:</div>

                              <Input
                                name="destinationName"
                                value={this.state.destinationName}
                                style={{ marginLeft: "8px", flex: "3" }}
                                onChange={this.onChangeTextarea}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            ></div>
                          </div>
                          <div>
                            <div
                              style={{
                                marginBottom: "18px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ flex: "1" }}>Invoice Value:</div>

                              <Input
                                typeof="number"
                                name="invoiceValue"
                                value={this.state.invoiceValue}
                                style={{ marginLeft: "8px", flex: "3" }}
                                onChange={this.onChangeTextarea}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ flex: "1" }}>Booking Date:</div>

                              <Input
                                name="bookingDate"
                                value={this.state.bookingDate}
                                style={{ marginLeft: "8px", flex: "3" }}
                                onChange={this.onChangeTextarea}
                              />
                            </div>
                          </div>{" "}
                          <div>
                            <div
                              style={{
                                marginBottom: "18px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ flex: "1" }}>Mode of Dispatch:</div>

                              <Input
                                name="modeOfDispatch"
                                value={this.state.modeOfDispatch}
                                style={{ marginLeft: "8px", flex: "3" }}
                                onChange={this.onChangeTextarea}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ flex: "1" }}>
                                Expected Delivery Date:
                              </div>

                              <Input
                                name="expectedDeliveryDate"
                                value={this.state.expectedDeliveryDate}
                                style={{ marginLeft: "8px", flex: "3" }}
                                onChange={this.onChangeTextarea}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="ConsignorMainBcg">
                          <div
                            className="ConsignorBcg"
                            style={{ padding: "16px" }}
                          >
                            <div
                              className="ConsignorValue"
                              style={{
                                marginBottom: "16px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <span
                                className="ConsignorLabel"
                                style={{ flex: "1" }}
                              >
                                Consignor:{" "}
                              </span>
                              <Input
                                name="consignorName"
                                value={this.state.consignorName}
                                style={{ marginLeft: "8px", flex: "3" }}
                                onChange={this.onChangeTextarea}
                              />
                              <div style={{ flex: 6 }}></div>
                            </div>
                            <div
                              className="ConsignorFont"
                              style={{
                                marginBottom: "16px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <span
                                className="ConsignorFontDiv"
                                style={{ flex: "1" }}
                              >
                                Address:
                              </span>
                              <Input
                                name="consignorAddress"
                                value={this.state.consignorAddress}
                                style={{
                                  marginLeft: "8px",
                                  flex: "9",
                                }}
                                onChange={this.onChangeTextarea}
                              />
                            </div>
                            <div
                              className="ConsignorFont ConsignorFontDiv"
                              style={{
                                marginBottom: "16px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flex: "2",
                                }}
                              >
                                <span
                                  className="ConsignorFontDiv"
                                  style={{ flex: "1" }}
                                >
                                  City:
                                </span>
                                <Input
                                  name="consignorCity"
                                  value={this.state.consignorCity}
                                  style={{
                                    marginLeft: "8px",
                                    flex: "9",
                                    height: "23px",
                                  }}
                                  onChange={this.onChangeTextarea}
                                />
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flex: "2",
                                  marginLeft: "16px",
                                }}
                              >
                                <span
                                  className="ConsignorFontDiv"
                                  style={{ flex: "1" }}
                                >
                                  State:
                                </span>
                                <Input
                                  name="consignorState"
                                  value={this.state.consignorState}
                                  style={{
                                    marginLeft: "8px",
                                    flex: "9",
                                    height: "23px",
                                  }}
                                  onChange={this.onChangeTextarea}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flex: "2",
                                  marginLeft: "16px",
                                }}
                              >
                                <span
                                  className="ConsignorFontDiv"
                                  style={{ flex: "1" }}
                                >
                                  Contact:
                                </span>
                                <Input
                                  name="consignorPhoneNo"
                                  value={this.state.consignorPhoneNo}
                                  style={{
                                    marginLeft: "8px",
                                    flex: "9",
                                    height: "23px",
                                  }}
                                  onChange={this.onChangeTextarea}
                                />
                              </div>
                            </div>
                            <div
                              className="ConsignorFont ConsignorFontDiv"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flex: "2",
                                }}
                              >
                                <span
                                  className="ConsignorFontDiv"
                                  style={{ flex: "1" }}
                                >
                                  Pin Code:
                                </span>
                                <Input
                                  name="consignorZip"
                                  value={this.state.consignorZip}
                                  style={{
                                    marginLeft: "8px",
                                    flex: "9",
                                    height: "23px",
                                  }}
                                  onChange={this.onChangeTextarea}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flex: "2",
                                  marginLeft: "8px",
                                }}
                              >
                                <span
                                  className="ConsignorFontDiv"
                                  style={{ flex: "1" }}
                                >
                                  Phone No:
                                </span>
                                <Input
                                  name="consignorPhoneNo"
                                  value={this.state.consignorPhoneNo}
                                  style={{
                                    marginLeft: "8px",
                                    flex: "9",
                                    height: "23px",
                                  }}
                                  onChange={this.onChangeTextarea}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flex: "2",
                                  marginLeft: "8px",
                                }}
                              >
                                <span
                                  className="ConsignorFontDiv"
                                  style={{ flex: "1" }}
                                >
                                  GST No:
                                </span>
                                <Input
                                  name="consignorGSTNo"
                                  value={this.state.consignorGSTNo}
                                  style={{
                                    marginLeft: "8px",
                                    flex: "9",
                                    height: "23px",
                                  }}
                                  onChange={this.onChangeTextarea}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="ConsignorBcg"
                            style={{ padding: "16px" }}
                          >
                            <div
                              className="ConsignorValue"
                              style={{
                                marginBottom: "16px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <span
                                className="ConsignorLabel"
                                style={{ flex: "1" }}
                              >
                                Consignee:{" "}
                              </span>

                              <Input
                                name="consigneeName"
                                value={this.state.consigneeName}
                                style={{ marginLeft: "8px", flex: "3" }}
                                onChange={this.onChangeTextarea}
                              />
                              <div style={{ flex: 6 }}></div>
                            </div>
                            <div
                              className="ConsignorFont"
                              style={{
                                marginBottom: "16px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <span
                                className="ConsignorFontDiv"
                                style={{ flex: "1" }}
                              >
                                Address:
                              </span>
                              <Input
                                name="consigneeAddress"
                                value={this.state.consigneeAddress}
                                style={{
                                  marginLeft: "8px",
                                  flex: "9",
                                }}
                                onChange={this.onChangeTextarea}
                              />
                            </div>
                            <div
                              className="ConsignorFont ConsignorFontDiv"
                              style={{
                                marginBottom: "16px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flex: "2",
                                }}
                              >
                                <span
                                  className="ConsignorFontDiv"
                                  style={{ flex: "1" }}
                                >
                                  City:
                                </span>
                                <Input
                                  name="consigneeCity"
                                  value={this.state.consigneeCity}
                                  style={{
                                    marginLeft: "8px",
                                    flex: "9",
                                    height: "23px",
                                  }}
                                  onChange={this.onChangeTextarea}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flex: "2",
                                  marginLeft: "8px",
                                }}
                              >
                                <span
                                  className="ConsignorFontDiv"
                                  style={{ flex: "1" }}
                                >
                                  State:
                                </span>
                                <Input
                                  name="consigneeState"
                                  value={this.state.consigneeState}
                                  style={{
                                    marginLeft: "8px",
                                    flex: "9",
                                    height: "23px",
                                  }}
                                  onChange={this.onChangeTextarea}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flex: "2",
                                  marginLeft: "8px",
                                }}
                              >
                                <span
                                  className="ConsignorFontDiv"
                                  style={{ flex: "1" }}
                                >
                                  Contact:
                                </span>
                                <Input
                                  name="consigneePhoneNo"
                                  value={this.state.consigneePhoneNo}
                                  style={{
                                    marginLeft: "8px",
                                    flex: "9",
                                    height: "23px",
                                  }}
                                  onChange={this.onChangeTextarea}
                                />
                              </div>
                            </div>
                            <div
                              className="ConsignorFont ConsignorFontDiv"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flex: "2",
                                }}
                              >
                                <span
                                  className="ConsignorFontDiv"
                                  style={{ flex: "1" }}
                                >
                                  Pin Code:
                                </span>
                                <Input
                                  name="consigneeZip"
                                  value={this.state.consigneeZip}
                                  style={{
                                    marginLeft: "8px",
                                    flex: "9",
                                    height: "23px",
                                  }}
                                  onChange={this.onChangeTextarea}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flex: "2",
                                  marginLeft: "8px",
                                }}
                              >
                                <span
                                  className="ConsignorFontDiv"
                                  style={{ flex: "1" }}
                                >
                                  Phone No:
                                </span>
                                <Input
                                  name="consigneePhoneNo"
                                  value={this.state.consigneePhoneNo}
                                  style={{
                                    marginLeft: "8px",
                                    flex: "9",
                                    height: "23px",
                                  }}
                                  onChange={this.onChangeTextarea}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flex: "2",
                                  marginLeft: "8px",
                                }}
                              >
                                <span
                                  className="ConsignorFontDiv"
                                  style={{ flex: "1" }}
                                >
                                  GST No:
                                </span>
                                <Input
                                  name="consigneeGSTNo"
                                  value={this.state.consigneeGSTNo}
                                  style={{
                                    marginLeft: "8px",
                                    flex: "9",
                                    height: "23px",
                                  }}
                                  onChange={this.onChangeTextarea}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="separator"
                          style={{ marginBottom: "0px" }}
                        ></div>

                        <div
                          style={{
                            paddingTop: "22px",
                            paddingRight: "22px",
                            paddingLeft: "22px",
                            paddingBottom: "50px",
                          }}
                        >
                          <div className="fieldsRow">
                            <div style={{ flex: "1" }}>
                              <div className="fieldsLabel">
                                No of Pkgs{" "}
                                <span className="fieldsLabelStar">*</span>
                              </div>
                              <div>
                                <Input
                                  id="noOfPkgs"
                                  name="noOfPkgs"
                                  type="number"
                                  placeholder="Type here..."
                                  value={form?.noOfPkgs || ""}
                                  onChange={(e) => {
                                    this.handleSubmitChange(e);
                                  }}
                                />
                                <ErrorMessage
                                  name="noOfPkgs"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div style={{ flex: "2" }}>
                              <div className="fieldsLabel">
                                Packaging Type{" "}
                                <span className="fieldsLabelStar">*</span>
                              </div>
                              <div>
                                <Select
                                  name="packagingType"
                                  id="packagingType"
                                  isMulti={false}
                                  className="selectField"
                                  classNamePrefix="select2-selection1"
                                  placeholder="select..."
                                  width="336px"
                                  value={form?.packagingType}
                                  options={this.state.packagingType}
                                  onChange={(e) => {
                                    this.handleSubmitChange({
                                      target: {
                                        name: "packagingType",
                                        value: e,
                                      },
                                    });
                                  }}
                                />
                                <ErrorMessage
                                  name="packagingType"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div style={{ flex: "2" }}>
                              <div className="fieldsLabel">
                                Description of Goods
                              </div>
                              <div>
                                <Input
                                  id="descriptionOfGoods"
                                  name="descriptionOfGoods"
                                  placeholder="Type here..."
                                  value={form?.descriptionOfGoods || ""}
                                  onChange={(e) => {
                                    this.handleSubmitChange(e);
                                  }}
                                />
                              </div>
                            </div>
                            <div style={{ flex: "1" }}>
                              <div className="fieldsLabel">
                                Actual Weight{" "}
                                <span className="fieldsLabelStar">*</span>
                              </div>
                              <div>
                                <Input
                                  id="actualWeight"
                                  name="actualWeight"
                                  type="number"
                                  placeholder="Type here..."
                                  value={form?.actualWeight || ""}
                                  onChange={(e) => {
                                    this.handleSubmitChange(e);
                                  }}
                                />
                                <ErrorMessage
                                  name="actualWeight"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="fieldsRow">
                            <div style={{ flex: "1" }}>
                              <div className="fieldsLabel">
                                Volumetric Weight{" "}
                                <span className="fieldsLabelStar">*</span>
                              </div>
                              <div>
                                <Input
                                  id="volumetricWeight"
                                  name="volumetricWeight"
                                  type="number"
                                  placeholder="Type here..."
                                  value={form?.volumetricWeight || ""}
                                  onChange={(e) => {
                                    this.handleSubmitChange(e);
                                  }}
                                />
                                <ErrorMessage
                                  name="volumetricWeight"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div style={{ flex: "1" }}>
                              <div className="fieldsLabel">
                                Chargeable Weight{" "}
                                <span className="fieldsLabelStar">*</span>
                              </div>
                              <div>
                                <Input
                                  id="chargeableWeight"
                                  name="chargeableWeight"
                                  type="number"
                                  placeholder="Type here..."
                                  value={form?.chargeableWeight || ""}
                                  onChange={(e) => {
                                    this.handleSubmitChange(e);
                                  }}
                                />
                                <ErrorMessage
                                  name="chargeableWeight"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div style={{ flex: "2" }}>
                              <div className="fieldsLabel">
                                Dimension{" "}
                                <span className="fieldsLabelStar">*</span>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "8px",
                                  alignItems: "center",
                                }}
                              >
                                <div className="LBHStyle">
                                  <div>L: </div>
                                  <div>
                                    <Input
                                      id="LDimension"
                                      name="LDimension"
                                      type="number"
                                      placeholder="Type here..."
                                      value={form?.LDimension || ""}
                                      onChange={(e) => {
                                        this.handleSubmitChange(e);
                                      }}
                                    />
                                    <ErrorMessage
                                      name="LDimension"
                                      component="span"
                                      className="error"
                                    />
                                  </div>
                                </div>
                                <div className="LBHStyle">
                                  <div>B: </div>
                                  <div>
                                    <Input
                                      id="BDimension"
                                      name="BDimension"
                                      type="number"
                                      placeholder="Type here..."
                                      value={form?.BDimension || ""}
                                      onChange={(e) => {
                                        this.handleSubmitChange(e);
                                      }}
                                    />
                                    <ErrorMessage
                                      name="BDimension"
                                      component="span"
                                      className="error"
                                    />
                                  </div>
                                </div>
                                <div className="LBHStyle">
                                  <div>H: </div>
                                  <div>
                                    <Input
                                      id="HDimension"
                                      name="HDimension"
                                      type="number"
                                      placeholder="Type here..."
                                      value={form?.HDimension || ""}
                                      onChange={(e) => {
                                        this.handleSubmitChange(e);
                                      }}
                                    />
                                    <ErrorMessage
                                      name="HDimension"
                                      component="span"
                                      className="error"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div style={{ flex: "2" }}>
                              <div className="fieldsLabel">
                                Invoice No{" "}
                                <span className="fieldsLabelStar">*</span>
                              </div>
                              <div>
                                <Input
                                  id="invoiceNo"
                                  name="invoiceNo"
                                  type="text"
                                  placeholder="Type here..."
                                  value={form?.invoiceNo}
                                  onChange={(e) => {
                                    this.handleSubmitChange(e);
                                  }}
                                />
                                <ErrorMessage
                                  name="invoiceNo"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="fieldsRow"
                            style={{ justifyContent: "space-between" }}
                          >
                            <div style={{ flex: 1 }}>
                              <div className="fieldsLabel">
                                Part No{" "}
                                <span className="fieldsLabelStar">*</span>
                              </div>
                              <div>
                                <Input
                                  id="partNo"
                                  name="partNo"
                                  type="text"
                                  placeholder="Type here..."
                                  value={form?.partNo}
                                  onChange={(e) => {
                                    this.handleSubmitChange(e);
                                  }}
                                />
                                <ErrorMessage
                                  name="partNo"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div style={{ flex: 1 }}>
                              <div className="fieldsLabel">
                                Quantity{" "}
                                <span className="fieldsLabelStar">*</span>
                              </div>
                              <div>
                                <Input
                                  id="qty"
                                  name="qty"
                                  type="number"
                                  placeholder="Type here..."
                                  value={form?.qty || ""}
                                  onChange={(e) => {
                                    this.handleSubmitChange(e);
                                  }}
                                />
                                <ErrorMessage
                                  name="qty"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div style={{ flex: 4 }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "flex-end",
                                  justifyContent: "flex-end",
                                  gap: "16px",
                                  height: "100%",
                                }}
                              >
                                <div>
                                  <Button
                                    className="fontRoboto1541"
                                    style={{
                                      width: "117px",
                                      backgroundColor: "#0AB38C",
                                      color: "#FFFFFF",
                                      paddingTop: "6px",
                                      paddingBottom: "6px",
                                    }}
                                    color="secondary"
                                    onClick={() => this.reset(resetForm)}
                                  >
                                    Reset
                                  </Button>
                                </div>
                                <div className="exclude-from-pdf">
                                  {/* <Button
                                    className="fontRoboto1541"
                                    style={{
                                      width: "117px",
                                      backgroundColor: "#05284E",
                                      color: "#FFFFFF",
                                    }}
                                    color="primary"
                                    type="submit"
                                    disabled={this.state.onSubmitDisable}
                                  >
                                     {this.state.islorryReceiptTrue ? 'Update' : 'Submit'}
                                     
                                  </Button> */}
                                    {!this.state.islorryReceiptTrue ? (
                              <Button
                                className="fontRoboto1541"
                                style={{
                                  width: "117px",
                                  backgroundColor: "#05284E",
                                  color: "#FFFFFF",
                                }}
                                color="primary"
                                type="button" 
                                onClick={this.handleSubmitSave} 
                                disabled={this.state.onSubmitDisable}
                              >
                                Submit
                              </Button>
                            ) : (
                              <Button
                                className="fontRoboto1541"
                                style={{
                                  width: "117px",
                                  backgroundColor: "#05284E",
                                  color: "#FFFFFF",
                                }}
                                color="primary"
                                type="button" 
                                onClick={this.handleUpdateSave} 
                                disabled={this.state.onSubmitDisable}
                              >
                                Update
                              </Button>
                            )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            )}
          </div>
        )}

        <MDBContainer>
          <MDBModal
            isOpen={this.state.warningModal}
            toggle={this.warningToggle}
            centered
          >
            <MDBModalHeader toggle={this.warningToggle}>
              <span style={{ color: "red" }}> WARNING</span>
            </MDBModalHeader>
            <MDBModalBody>Do you want to download the LR copy?</MDBModalBody>
            <MDBModalFooter>
              <Button
                color="warning"
                onClick={() => {
                  this.warningToggle();
                  this.props.history.push({
                    pathname: "/trips",
                  });
                }}
              >
                Cancel
              </Button>
              <Button
                color="danger"
                onClick={() => {
                  this.generatePdf("LR Copy");
                }}
              >
                Download
              </Button>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { createLRCopySecondary, fetchLRCopy ,UpdateLRCopySecondary })(
  LRCopyForm
);
