let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  AdminHeaderData = {
    columns: [
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 2,
      },
      {
        label: "Courier Mode (MT)",
        field: "courierMode",
        sort: "asc",
        width: 3,
      },

      {
        label: "Courier Mode (%)",
        field: "courierModeVolume",
        sort: "asc",
        width: 3,
      },
      {
        label: "Truck Mode (MT)",
        field: "truckMode",
        sort: "asc",
        width: 3,
      },
      {
        label: "Truck Mode (%)",
        field: "truckModeVolume",
        sort: "asc",
        width: 3,
      },
      {
        label: "Total",
        field: "total",
        sort: "asc",
        width: 2,
      },
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var AdminHeaderData = {
  columns: [
    {
      label: "Date",
      field: "date",
      sort: "asc",
      width: 1,
    },
    {
      label: "Courier Mode (MT)",
      field: "courierMode",
      sort: "asc",
      width: 2,
    },
    {
      label: "Courier Mode (%)",
      field: "courierModeVolume",
      sort: "asc",
      width: 2,
    },
    {
      label: "Truck Mode (MT)",
      field: "truckMode",
      sort: "asc",
      width: 4,
    },
    {
      label: "Truck Mode (%)",
      field: "truckModeVolume",
      sort: "asc",
      width: 3,
    },
    {
      label: "Total",
      field: "total",
      sort: "asc",
      width: 2,
    },
  ],
};
export var searchBar;
searchBar = {
  searchBarData: [],
};
