import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Box } from "@material-ui/core";
// import {Grid} from "reactstrap"

// files
import useStylesTest from "./Styles/DashboardCardStyles.js";
import DashboardCard from "./DashboardCard.js";
import { CardHeader } from "reactstrap";
import { Card } from "@material-ui/core";

class DashboardCards extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
 
  cardClick = (name) => {
    
    let filterName;
    let filter = {
      "Untracked with Consent": "Untracked with Consent",
      "Untracked without Consent": "Untracked without Consent",
      "Trips Delayed": "delayed",
      Completed: "completed",
      Planned: "planned",
      Others: "otherStatus",
      "In Transit": "in_transit",
      "Arrived at Source": "arrived_at_source",
      "Loading In Progress": "loading_in_progress",
      "Quality Check": "quality_check_completed",
      "Courier": 'COURIER',
      "Truck": 'TRUCK',
      "Tracking": 'tracking',
      "Non Tracking": 'non_tracking',
      "Trip Without Eway Bill No": "tripsWithOutEwayBillNumber"

    };
    filterName = filter[name];
    this.props.history.push({
      pathname: "/tripList",
      state: {
        filter: filterName,
        topFilters:this.props?.topFilters
      },
    });
  };

  render() {
    const trips = this.props.trips;
    const darkTheme = this.props?.opCardStyle;
    const classes = useStylesTest(this.props?.opCardStyle);
    return (
      <>
        <Grid item xs={12} sm={12} md={12}>
          <div className="desktopDisplay">
            <Grid
              container
              spacing={1}
              justifyContent="center"
              alignItems="center"
              direction="row"
              className="dashboard-card-spacing dashContainerDiv"
              style={{
                width: "100%",
                padding:"0px 0px 17px 10px",
                display: "flex",
                justifyContent: "space-between",
                //   marginTop:"-20%"
              }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader style={classes.orderStatus}>
                    <span className="roboto-font-700 span-order-status light__span">
                    {this.props?.userLang?.baxterdDashboard?.TRIP_STATUS ??" Trip Status"}
                    </span>
                  </CardHeader>
                </Card>
              </Grid>
            </Grid>
          </div>
        </Grid>

        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          className="dashboard-cards-grid-div dashContainerDiv pdl15 padding15"
          // style={{
          //   paddingLeft:"15px"
          // }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <div className="desktopDisplay">
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                className="dashboard-card-spacing dashContainerDiv"
                style={{
                  width: "100%",
                  // padding:"0px 5px"
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "19%" }}>
                  <DashboardCard
                    cardStyle={classes.totalCardStyles}
                    nameStyle={classes.totalNameStyles}
                    iconStyle={classes.totalIconStyles}
                    icon={
                      <svg
                        className="customDashboardIcon"
                        width="34"
                        height="34"
                        viewBox="0 0 34 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g filter="url(#filter0_d_2969_1537)">
                          <rect
                            x="4.29199"
                            y="4.5"
                            width="25"
                            height="25"
                            rx="4"
                            fill={darkTheme ? "#171722" : "#D5CDFF"}
                          />
                        </g>
                        <path
                          d="M15.3862 10.6738H10.4658V15.5942H15.3862V10.6738Z"
                          fill="#2800FC"
                          stroke="#2800FC"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M23.1181 10.6738H18.1978V15.5942H23.1181V10.6738Z"
                          fill="#2800FC"
                          stroke="#2800FC"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M23.1181 18.4058H18.1978V23.3262H23.1181V18.4058Z"
                          fill="#2800FC"
                          stroke="#2800FC"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15.3862 18.4058H10.4658V23.3262H15.3862V18.4058Z"
                          fill="#2800FC"
                          stroke="#2800FC"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <defs>
                          <filter
                            id="filter0_d_2969_1537"
                            x="0.291992"
                            y="0.5"
                            width="33"
                            height="33"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                          >
                            <feFlood
                              floodOpacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_2969_1537"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_2969_1537"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>
                    }
                    numberStyle={classes.totalNumberStyle}
                    name="Total"
                    nameForHindiLan={this.props?.userLang?.dashboard?.TOTAL}
                    value={trips?.total}
                    iconClr={classes.plannedEye}
                    color={"#2800FC"}
                    cardClick={this.cardClick}
                  />
                </div>
                <div style={{ width: "19%" }}>
                  <DashboardCard
                    cardStyle={classes.plannedCardStyles}
                    nameStyle={classes.plannedNameStyles}
                    iconStyle={classes.plannedIconStyles}
                    icon={
                      <svg
                        className="customDashboardIcon"
                        width="34"
                        height="34"
                        viewBox="0 0 34 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g filter="url(#filter0_d_2969_1552)">
                          <rect
                            x="4.29688"
                            y="4.5"
                            width="25"
                            height="25"
                            rx="4"
                            fill={darkTheme ? "#171722" : "#FED990"}
                          />
                        </g>
                        <path
                          d="M16.1474 12.1384H11.8609C11.5361 12.1384 11.2246 12.2674 10.9949 12.4971C10.7653 12.7267 10.6362 13.0382 10.6362 13.3631V21.936C10.6362 22.2608 10.7653 22.5723 10.9949 22.8019C11.2246 23.0316 11.5361 23.1607 11.8609 23.1607H20.4338C20.7586 23.1607 21.0701 23.0316 21.2998 22.8019C21.5295 22.5723 21.6585 22.2608 21.6585 21.936V17.6495"
                          stroke="#E19600"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M20.7397 11.2198C20.9833 10.9762 21.3137 10.8393 21.6583 10.8393C22.0028 10.8393 22.3332 10.9762 22.5768 11.2198C22.8204 11.4634 22.9572 11.7938 22.9572 12.1383C22.9572 12.4828 22.8204 12.8132 22.5768 13.0569L16.7595 18.8742L14.3101 19.4865L14.9224 17.0371L20.7397 11.2198Z"
                          fill="#E19600"
                          stroke="#E19600"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <defs>
                          <filter
                            id="filter0_d_2969_1552"
                            x="0.296875"
                            y="0.5"
                            width="33"
                            height="33"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                          >
                            <feFlood
                              floodOpacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_2969_1552"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_2969_1552"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>
                    }
                    numberStyle={classes.plannedNumberStyle}
                    name="Planned"
                    nameForHindiLan={this.props?.userLang?.dashboard?.PLANNED}
                    // iconClr={classes.tripEye}
                    value={trips?.planned}
                    color={"#E19600"}
                    cardClick={this.cardClick}
                  />
                </div>
                <div style={{ width: "20%" }} className="pendingPdCss">
                  <DashboardCard
                    cardStyle={classes.pendingCardStyles}
                    nameStyle={classes.pendingNameStyles}
                    iconStyle={classes.pendingIconStyles}
                    icon={
                      <svg
                        className="customDashboardIcon"
                        width="34"
                        height="34"
                        viewBox="0 0 34 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g filter="url(#filter0_d_2969_2584)">
                          <rect
                            x="4.29199"
                            y="4.5"
                            width="25"
                            height="25"
                            rx="4"
                            fill={darkTheme ? "#171722" : "#ffd7b5"}
                          />
                        </g>
                        <path
                          d="M16.7924 24.1249C20.7273 24.1249 23.9172 20.935 23.9172 17C23.9172 13.065 20.7273 9.87511 16.7924 9.87511C12.8574 9.87511 9.66748 13.065 9.66748 17C9.66748 20.935 12.8574 24.1249 16.7924 24.1249Z"
                          fill="#ff6700"
                          stroke="#ff6700"
                          strokeWidth="2.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15.3672 14.15V18.425L18.2171 19.8499"
                          stroke="#F1F5F7"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <defs>
                          <filter
                            id="filter0_d_2969_2584"
                            x="0.291992"
                            y="0.5"
                            width="33"
                            height="33"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                          >
                            <feFlood
                              floodOpacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_2969_2584"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_2969_2584"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>
                    }
                    numberStyle={classes.pendingNumberStyle}
                    name="Arrived at Source"
                    nameForHindiLan={
                      this.props?.userLang?.baxterdDashboard
                        ?.ARRIVED_AT_SOURCE ?? "Arrived at Source"
                    }
                    value={trips?.arrivedAtSource}
                    iconClr={classes.pendingEye}
                    color={"#05284E"}
                    cardClick={this.cardClick}
                  />
                </div>
                <div style={{ width: "20%" }}>
                  <DashboardCard
                    cardStyle={classes.completedCardStyle}
                    nameStyle={classes.completedNameStyle}
                    iconStyle={classes.completedIconStyle}
                    icon={
                      <svg
                        width="34"
                        height="34"
                        viewBox="0 0 34 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g filter="url(#filter0_d_6303_19)">
                          <rect
                            x="4.29199"
                            y="4.5"
                            width="25"
                            height="25"
                            rx="4"
                            fill="#d3f8d3"
                          />
                        </g>
                        <path
                          d="M17 17.75C17.4832 17.75 17.875 17.3582 17.875 16.875C17.875 16.3918 17.4832 16 17 16C16.5168 16 16.125 16.3918 16.125 16.875C16.125 17.3582 16.5168 17.75 17 17.75Z"
                          fill="rgb(40, 163, 76)"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M23.125 17.75C23.6082 17.75 24 17.3582 24 16.875C24 16.3918 23.6082 16 23.125 16C22.6418 16 22.25 16.3918 22.25 16.875C22.25 17.3582 22.6418 17.75 23.125 17.75Z"
                          fill="rgb(40, 163, 76)"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.875 17.75C11.3582 17.75 11.75 17.3582 11.75 16.875C11.75 16.3918 11.3582 16 10.875 16C10.3918 16 10 16.3918 10 16.875C10 17.3582 10.3918 17.75 10.875 17.75Z"
                          fill="rgb(40, 163, 76)"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <defs>
                          <filter
                            id="filter0_d_6303_19"
                            x="0.291992"
                            y="0.5"
                            width="33"
                            height="33"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                          >
                            <feFlood
                              floodOpacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_6303_19"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_6303_19"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>
                    }
                    numberStyle={classes.completedNummberStyle}
                    name="Quality Check"                  
                    nameForHindiLan={
                      this.props?.userLang?.baxterdDashboard?.QUALITY_CHECK ??
                      "Quality Check"
                    }
                    value={trips?.qualityCheckCompleted}
                    iconClr={classes.completeEye}
                    color={"#006400"}
                    cardClick={this.cardClick}
                  />
                </div>
                <div style={{ width: "20%" }} >
                  <DashboardCard
                    cardStyle={classes.plannedCardStyles}
                    nameStyle={classes.plannedNameStyles}
                    iconStyle={classes.plannedIconStyles}
                    icon={
                      <svg
                        className="customDashboardIcon"
                        width="34"
                        height="34"
                        viewBox="0 0 34 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g filter="url(#filter0_d_2969_1552)">
                          <rect
                            x="4.29688"
                            y="4.5"
                            width="25"
                            height="25"
                            rx="4"
                            fill={darkTheme ? "#171722" : "#fed4d2"}
                          />
                        </g>
                        <path
                          d="M16.1474 12.1384H11.8609C11.5361 12.1384 11.2246 12.2674 10.9949 12.4971C10.7653 12.7267 10.6362 13.0382 10.6362 13.3631V21.936C10.6362 22.2608 10.7653 22.5723 10.9949 22.8019C11.2246 23.0316 11.5361 23.1607 11.8609 23.1607H20.4338C20.7586 23.1607 21.0701 23.0316 21.2998 22.8019C21.5295 22.5723 21.6585 22.2608 21.6585 21.936V17.6495"
                          stroke="rgb(255, 111, 0)"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M20.7397 11.2198C20.9833 10.9762 21.3137 10.8393 21.6583 10.8393C22.0028 10.8393 22.3332 10.9762 22.5768 11.2198C22.8204 11.4634 22.9572 11.7938 22.9572 12.1383C22.9572 12.4828 22.8204 12.8132 22.5768 13.0569L16.7595 18.8742L14.3101 19.4865L14.9224 17.0371L20.7397 11.2198Z"
                          fill="#E19600"
                          stroke="rgb(255, 111, 0)"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <defs>
                          <filter
                            id="filter0_d_2969_1552"
                            x="0.296875"
                            y="0.5"
                            width="33"
                            height="33"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                          >
                            <feFlood
                              floodOpacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_2969_1552"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_2969_1552"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>
                    }
                    numberStyle={classes.plannedNumberStyle}
                    name="Loading In Progress"
                    nameForHindiLan={
                      this.props?.userLang?.baxterdDashboard
                        ?.LOADING_IN_PROGRESS ?? "Loading In Progress"
                    }
                    value={trips?.loadingInProgress}
                    color={"#E19600"}
                    cardClick={this.cardClick}
                  />
                </div>
                <div style={{ width: "20%" }} className="mt-2">
                  <DashboardCard
                    cardStyle={classes.plannedCardStyles}
                    nameStyle={classes.plannedNameStyles}
                    iconStyle={classes.plannedIconStyles}
                    icon={
                      <svg
                        className="customDashboardIcon"
                        width="34"
                        height="34"
                        viewBox="0 0 34 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g filter="url(#filter0_d_2969_1552)">
                          <rect
                            x="4.29688"
                            y="4.5"
                            width="25"
                            height="25"
                            rx="4"
                            fill={darkTheme ? "#171722" : "#fed4d2"}
                          />
                        </g>
                        <path
                          d="M16.1474 12.1384H11.8609C11.5361 12.1384 11.2246 12.2674 10.9949 12.4971C10.7653 12.7267 10.6362 13.0382 10.6362 13.3631V21.936C10.6362 22.2608 10.7653 22.5723 10.9949 22.8019C11.2246 23.0316 11.5361 23.1607 11.8609 23.1607H20.4338C20.7586 23.1607 21.0701 23.0316 21.2998 22.8019C21.5295 22.5723 21.6585 22.2608 21.6585 21.936V17.6495"
                          stroke="rgb(255, 111, 0)"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M20.7397 11.2198C20.9833 10.9762 21.3137 10.8393 21.6583 10.8393C22.0028 10.8393 22.3332 10.9762 22.5768 11.2198C22.8204 11.4634 22.9572 11.7938 22.9572 12.1383C22.9572 12.4828 22.8204 12.8132 22.5768 13.0569L16.7595 18.8742L14.3101 19.4865L14.9224 17.0371L20.7397 11.2198Z"
                          fill="#E19600"
                          stroke="rgb(255, 111, 0)"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <defs>
                          <filter
                            id="filter0_d_2969_1552"
                            x="0.296875"
                            y="0.5"
                            width="33"
                            height="33"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                          >
                            <feFlood
                              floodOpacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_2969_1552"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_2969_1552"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>
                    }
                    numberStyle={classes.plannedNumberStyle}
                    name="Trip Without Eway Bill No"
                    nameForHindiLan={
                      this.props?.userLang?.baxterdDashboard
                        ?.TRIP_WITHOUT_EWAY_BILL ?? "Trip Without Eway Bill No"
                    }
                    value={trips?.tripsWithOutEwayBillNumber[0]?trips?.tripsWithOutEwayBillNumber[0]?.total:0}
                    color={"#E19600"}
                    cardClick={this.cardClick}
                  />
                </div>
                <div style={{ width: "28%" }} className="mt-2">
                  <DashboardCard
                    cardStyle={classes.inTransistCardStyle}
                    nameStyle={classes.inTransistNameStyle}
                    iconStyle={classes.inTransistIconStyle}
                    icon={
                      <svg
                        className="customDashboardIcon"
                        width="34"
                        height="34"
                        viewBox="0 0 34 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g filter="url(#filter0_d_2969_2588)">
                          <rect
                            x="4.00488"
                            y="4.5"
                            width="25"
                            height="25"
                            rx="4"
                            fill={darkTheme ? "#171722" : "#EAC8FF"}
                          />
                        </g>
                        <path
                          d="M17.871 11.4474H8.6167V19.4678H17.871V11.4474Z"
                          fill="#A020F0"
                          stroke="#A020F0"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M20.0747 14.5322H22.5425L24.3934 16.383V19.4678H20.0747V14.5322Z"
                          fill="#A020F0"
                          stroke="#A020F0"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.393 22.5526C12.2448 22.5526 12.9354 21.862 12.9354 21.0102C12.9354 20.1583 12.2448 19.4678 11.393 19.4678C10.5411 19.4678 9.85059 20.1583 9.85059 21.0102C9.85059 21.862 10.5411 22.5526 11.393 22.5526Z"
                          fill="#A020F0"
                          stroke="#A020F0"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M20.6918 22.5526C21.5436 22.5526 22.2342 21.862 22.2342 21.0102C22.2342 20.1583 21.5436 19.4678 20.6918 19.4678C19.84 19.4678 19.1494 20.1583 19.1494 21.0102C19.1494 21.862 19.84 22.5526 20.6918 22.5526Z"
                          fill="#A020F0"
                          stroke="#A020F0"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <defs>
                          <filter
                            id="filter0_d_2969_2588"
                            x="0.00488281"
                            y="0.5"
                            width="33"
                            height="33"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                          >
                            <feFlood
                              floodOpacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_2969_2588"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_2969_2588"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>
                    }
                    numberStyle={classes.inTransistNumberStyle}
                    name="In Transit"
                    nameForHindiLan={this.props?.userLang?.dashboard?.IN_TRANSIT}
                    color={"#A020F0"}
                    iconClr={classes.intranEye}
                    value={trips}
                    cardClick={this.cardClick}
                  />
                </div>
                
                <div style={{ width: "28%" }} className="mt-2">
                  <DashboardCard
                    cardStyle={classes.plannedCardStyles}
                    nameStyle={classes.plannedNameStyles}
                    iconStyle={classes.plannedIconStyles}
                    icon={
                      <svg
                        width="33"
                        height="34"
                        viewBox="0 0 33 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g filter="url(#filter0_d_2969_2586)">
                          <rect
                            x="4"
                            y="4.5"
                            width="25"
                            height="25"
                            rx="4"
                            fill={darkTheme ? "#171722" : "#FFE3E1"}
                          />
                        </g>
                        <path
                          d="M21.3215 15.8659C21.3215 19.8353 16.2185 23.2375 16.2185 23.2375C16.2185 23.2375 11.1147 19.8353 11.1147 15.8659C11.1147 14.5124 11.6524 13.2143 12.6095 12.2572C13.5666 11.3002 14.8646 10.7625 16.2181 10.7625C17.5716 10.7625 18.8697 11.3002 19.8268 12.2572C20.7838 13.2143 21.3215 14.5124 21.3215 15.8659Z"
                          fill="#AC1000"
                          stroke="#AC1000"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16.4607 17.8908C17.4448 17.8908 18.2427 17.093 18.2427 16.1088C18.2427 15.1246 17.4448 14.3268 16.4607 14.3268C15.4765 14.3268 14.6787 15.1246 14.6787 16.1088C14.6787 17.093 15.4765 17.8908 16.4607 17.8908Z"
                          fill="#FFE3E1"
                          stroke="#FFE3E1"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.1147 21.9912L21.8848 10.7695"
                          stroke="#AC1000"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <defs>
                          <filter
                            id="filter0_d_2969_2586"
                            x="0"
                            y="0.5"
                            width="33"
                            height="33"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                          >
                            <feFlood
                              floodOpacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_2969_2586"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_2969_2586"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>
                    }
                    numberStyle={classes.transitNumberStyle}
                    name="Tracking Status"
                    nameForHindiLan={
                      this.props?.userLang?.baxterdDashboard?.TRACKING_STATUS ??
                      "Tracking Status"
                    }
                    value={trips}
                    color={"#E19600"}
                    cardClick={this.cardClick}
                  />
                </div>
                <div style={{ width: "23%" }} className="mt-2">
                  <DashboardCard
                    cardStyle={classes.completedCardStyle}
                    nameStyle={classes.completedNameStyle}
                    iconStyle={classes.completedIconStyle}
                    icon={
                      <svg
                        className="customDashboardIcon"
                        width="34"
                        height="34"
                        viewBox="0 0 34 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g filter="url(#filter0_d_2969_2587)">
                          <rect
                            x="4.00488"
                            y="4.5"
                            width="25"
                            height="25"
                            rx="4"
                            fill={darkTheme ? "#171722" : "#A5F2BB"}
                          />
                        </g>
                        <path
                          d="M20.9951 11.227H12.0148C11.3063 11.227 10.7319 11.8013 10.7319 12.5099V21.4901C10.7319 22.1986 11.3063 22.773 12.0148 22.773H20.9951C21.7036 22.773 22.278 22.1986 22.278 21.4901V12.5099C22.278 11.8013 21.7036 11.227 20.9951 11.227Z"
                          fill="#006400"
                          stroke="#006400"
                          strokeWidth="2.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M19.588 14.7578L15.1035 19.2423L13.4219 17.5606"
                          stroke="#A5F2BB"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <defs>
                          <filter
                            id="filter0_d_2969_2587"
                            x="0.00488281"
                            y="0.5"
                            width="33"
                            height="33"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                          >
                            <feFlood
                              floodOpacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_2969_2587"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_2969_2587"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>
                    }
                    numberStyle={classes.completedNummberStyle}
                    name="Completed"
                    nameForHindiLan={this.props?.userLang?.dashboard?.COMPLETED}
                    value={trips?.completed}
                    iconClr={classes.completeEye}
                    color={"#006400"}
                    cardClick={this.cardClick}
                  />
                </div>
              </Grid>
            </div>
            <Box sx={{ flexGrow: 1 }} className="mobileDisplayShpment">
              <Grid
                container
                spacing={1}
                justifyContent="center"
                alignItems="center"
                className="dashboard-card-spacing dashContainerDiv"
                style={{
                  width: "100%",
                  // padding:"0px 5px"
                }}
              >
                <Grid item xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader style={{ background: "white" }}>
                    <span className="roboto-font-700 span-order-status light__span">
                    Trip Status
                    </span>
                  </CardHeader>
                </Card>
              </Grid>
                <Grid item xs={12} md={5}>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    className="dashboard-card-spacing dashContainerDiv"
                    style={{
                      width: "100%",
                      // padding:"0px 5px"
                    }}
                  >
                    <Grid item xs={6}>
                      <DashboardCard
                        cardStyle={classes.totalCardStyles}
                        nameStyle={classes.totalNameStyles}
                        iconStyle={classes.totalIconStyles}
                        icon={
                          <svg
                            className="customDashboardIcon"
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g filter="url(#filter0_d_2969_1537)">
                              <rect
                                x="4.29199"
                                y="4.5"
                                width="25"
                                height="25"
                                rx="4"
                                fill={darkTheme ? "#171722" : "#D5CDFF"}
                              />
                            </g>
                            <path
                              d="M15.3862 10.6738H10.4658V15.5942H15.3862V10.6738Z"
                              fill="#2800FC"
                              stroke="#2800FC"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M23.1181 10.6738H18.1978V15.5942H23.1181V10.6738Z"
                              fill="#2800FC"
                              stroke="#2800FC"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M23.1181 18.4058H18.1978V23.3262H23.1181V18.4058Z"
                              fill="#2800FC"
                              stroke="#2800FC"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M15.3862 18.4058H10.4658V23.3262H15.3862V18.4058Z"
                              fill="#2800FC"
                              stroke="#2800FC"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <defs>
                              <filter
                                id="filter0_d_2969_1537"
                                x="0.291992"
                                y="0.5"
                                width="33"
                                height="33"
                                filterUnits="userSpaceOnUse"
                                colorInterpolationFilters="sRGB"
                              >
                                <feFlood
                                  floodOpacity="0"
                                  result="BackgroundImageFix"
                                />
                                <feColorMatrix
                                  in="SourceAlpha"
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                  result="hardAlpha"
                                />
                                <feOffset />
                                <feGaussianBlur stdDeviation="2" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                                />
                                <feBlend
                                  mode="normal"
                                  in2="BackgroundImageFix"
                                  result="effect1_dropShadow_2969_1537"
                                />
                                <feBlend
                                  mode="normal"
                                  in="SourceGraphic"
                                  in2="effect1_dropShadow_2969_1537"
                                  result="shape"
                                />
                              </filter>
                            </defs>
                          </svg>
                        }
                        numberStyle={classes.totalNumberStyle}
                        name="Total"
                        nameForHindiLan={this.props?.userLang?.dashboard?.TOTAL}
                        value={trips?.total}
                        iconClr={classes.plannedEye}
                        color={darkTheme ? "#ffffff" : "#2800FC"}
                        cardClick={this.cardClick}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DashboardCard
                        cardStyle={classes.plannedCardStyles}
                        nameStyle={classes.plannedNameStyles}
                        iconStyle={classes.plannedIconStyles}
                        icon={
                          <svg
                            className="customDashboardIcon"
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g filter="url(#filter0_d_2969_1552)">
                              <rect
                                x="4.29688"
                                y="4.5"
                                width="25"
                                height="25"
                                rx="4"
                                fill="#FED990"
                              />
                            </g>
                            <path
                              d="M16.1474 12.1384H11.8609C11.5361 12.1384 11.2246 12.2674 10.9949 12.4971C10.7653 12.7267 10.6362 13.0382 10.6362 13.3631V21.936C10.6362 22.2608 10.7653 22.5723 10.9949 22.8019C11.2246 23.0316 11.5361 23.1607 11.8609 23.1607H20.4338C20.7586 23.1607 21.0701 23.0316 21.2998 22.8019C21.5295 22.5723 21.6585 22.2608 21.6585 21.936V17.6495"
                              stroke="#E19600"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M20.7397 11.2198C20.9833 10.9762 21.3137 10.8393 21.6583 10.8393C22.0028 10.8393 22.3332 10.9762 22.5768 11.2198C22.8204 11.4634 22.9572 11.7938 22.9572 12.1383C22.9572 12.4828 22.8204 12.8132 22.5768 13.0569L16.7595 18.8742L14.3101 19.4865L14.9224 17.0371L20.7397 11.2198Z"
                              fill="#E19600"
                              stroke="#E19600"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <defs>
                              <filter
                                id="filter0_d_2969_1552"
                                x="0.296875"
                                y="0.5"
                                width="33"
                                height="33"
                                filterUnits="userSpaceOnUse"
                                colorInterpolationFilters="sRGB"
                              >
                                <feFlood
                                  floodOpacity="0"
                                  result="BackgroundImageFix"
                                />
                                <feColorMatrix
                                  in="SourceAlpha"
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                  result="hardAlpha"
                                />
                                <feOffset />
                                <feGaussianBlur stdDeviation="2" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                                />
                                <feBlend
                                  mode="normal"
                                  in2="BackgroundImageFix"
                                  result="effect1_dropShadow_2969_1552"
                                />
                                <feBlend
                                  mode="normal"
                                  in="SourceGraphic"
                                  in2="effect1_dropShadow_2969_1552"
                                  result="shape"
                                />
                              </filter>
                            </defs>
                          </svg>
                        }
                        numberStyle={classes.plannedNumberStyle}
                        name="Planned"
                        nameForHindiLan={this.props?.userLang?.dashboard?.PLANNED}
                        // iconClr={classes.tripEye}
                        value={trips?.planned}
                        color={darkTheme ? "#ffffff" : "#E19600"}
                        cardClick={this.cardClick}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4.5}>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    className="dashboard-card-spacing dashContainerDiv"
                    style={{
                      width: "100%",
                      // padding:"0px 5px"
                    }}
                  >
                    <Grid item xs={6}>
                      <DashboardCard
                        cardStyle={classes.pendingCardStyles}
                        nameStyle={classes.pendingNameStyles}
                        iconStyle={classes.pendingIconStyles}
                        icon={
                          <svg
                            className="customDashboardIcon"
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g filter="url(#filter0_d_2969_2584)">
                              <rect
                                x="4.29199"
                                y="4.5"
                                width="25"
                                height="25"
                                rx="4"
                                fill={darkTheme ? "#171722" : "#F1F5F7"}
                              />
                            </g>
                            <path
                              d="M16.7924 24.1249C20.7273 24.1249 23.9172 20.935 23.9172 17C23.9172 13.065 20.7273 9.87511 16.7924 9.87511C12.8574 9.87511 9.66748 13.065 9.66748 17C9.66748 20.935 12.8574 24.1249 16.7924 24.1249Z"
                              fill="#05284E"
                              stroke="#05284E"
                              strokeWidth="2.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M15.3672 14.15V18.425L18.2171 19.8499"
                              stroke="#F1F5F7"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <defs>
                              <filter
                                id="filter0_d_2969_2584"
                                x="0.291992"
                                y="0.5"
                                width="33"
                                height="33"
                                filterUnits="userSpaceOnUse"
                                colorInterpolationFilters="sRGB"
                              >
                                <feFlood
                                  floodOpacity="0"
                                  result="BackgroundImageFix"
                                />
                                <feColorMatrix
                                  in="SourceAlpha"
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                  result="hardAlpha"
                                />
                                <feOffset />
                                <feGaussianBlur stdDeviation="2" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                                />
                                <feBlend
                                  mode="normal"
                                  in2="BackgroundImageFix"
                                  result="effect1_dropShadow_2969_2584"
                                />
                                <feBlend
                                  mode="normal"
                                  in="SourceGraphic"
                                  in2="effect1_dropShadow_2969_2584"
                                  result="shape"
                                />
                              </filter>
                            </defs>
                          </svg>
                        }
                        numberStyle={classes.pendingNumberStyle}
                        name="Arrived at Source"
                        nameForHindiLan={
                          this.props?.userLang?.baxterdDashboard
                            ?.ARRIVED_AT_SOURCE ?? "Arrived at Source"
                        }
                        value={trips?.arrivedAtSource}
                        iconClr={classes.pendingEye}
                        color={"#05284E"}
                        cardClick={this.cardClick}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DashboardCard
                        cardStyle={classes.plannedCardStyles}
                        nameStyle={classes.plannedNameStyles}
                        iconStyle={classes.plannedIconStyles}
                        icon={
                          <svg
                            className="customDashboardIcon"
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g filter="url(#filter0_d_2969_1552)">
                              <rect
                                x="4.29688"
                                y="4.5"
                                width="25"
                                height="25"
                                rx="4"
                                fill={darkTheme ? "#171722" : "#FED990"}
                              />
                            </g>
                            <path
                              d="M16.1474 12.1384H11.8609C11.5361 12.1384 11.2246 12.2674 10.9949 12.4971C10.7653 12.7267 10.6362 13.0382 10.6362 13.3631V21.936C10.6362 22.2608 10.7653 22.5723 10.9949 22.8019C11.2246 23.0316 11.5361 23.1607 11.8609 23.1607H20.4338C20.7586 23.1607 21.0701 23.0316 21.2998 22.8019C21.5295 22.5723 21.6585 22.2608 21.6585 21.936V17.6495"
                              stroke="#E19600"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M20.7397 11.2198C20.9833 10.9762 21.3137 10.8393 21.6583 10.8393C22.0028 10.8393 22.3332 10.9762 22.5768 11.2198C22.8204 11.4634 22.9572 11.7938 22.9572 12.1383C22.9572 12.4828 22.8204 12.8132 22.5768 13.0569L16.7595 18.8742L14.3101 19.4865L14.9224 17.0371L20.7397 11.2198Z"
                              fill="#E19600"
                              stroke="#E19600"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <defs>
                              <filter
                                id="filter0_d_2969_1552"
                                x="0.296875"
                                y="0.5"
                                width="33"
                                height="33"
                                filterUnits="userSpaceOnUse"
                                colorInterpolationFilters="sRGB"
                              >
                                <feFlood
                                  floodOpacity="0"
                                  result="BackgroundImageFix"
                                />
                                <feColorMatrix
                                  in="SourceAlpha"
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                  result="hardAlpha"
                                />
                                <feOffset />
                                <feGaussianBlur stdDeviation="2" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                                />
                                <feBlend
                                  mode="normal"
                                  in2="BackgroundImageFix"
                                  result="effect1_dropShadow_2969_1552"
                                />
                                <feBlend
                                  mode="normal"
                                  in="SourceGraphic"
                                  in2="effect1_dropShadow_2969_1552"
                                  result="shape"
                                />
                              </filter>
                            </defs>
                          </svg>
                        }
                        numberStyle={classes.plannedNumberStyle}
                        name="Loading In Progress"
                        nameForHindiLan={
                          this.props?.userLang?.baxterdDashboard
                            ?.LOADING_IN_PROGRESS ?? "Loading In Progress"
                        }
                        // iconClr={classes.tripEye}
                        value={trips?.loadingInProgress}
                        color={"#E19600"}
                        cardClick={this.cardClick}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} md={3} className="pendingPdCss">
                <DashboardCard
                    cardStyle={classes.plannedCardStyles}
                    nameStyle={classes.plannedNameStyles}
                    iconStyle={classes.plannedIconStyles}
                    icon={
                      <svg
                        className="customDashboardIcon"
                        width="34"
                        height="34"
                        viewBox="0 0 34 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g filter="url(#filter0_d_2969_1552)">
                          <rect
                            x="4.29688"
                            y="4.5"
                            width="25"
                            height="25"
                            rx="4"
                            fill={darkTheme ? "#171722" : "#fed4d2"}
                          />
                        </g>
                        <path
                          d="M16.1474 12.1384H11.8609C11.5361 12.1384 11.2246 12.2674 10.9949 12.4971C10.7653 12.7267 10.6362 13.0382 10.6362 13.3631V21.936C10.6362 22.2608 10.7653 22.5723 10.9949 22.8019C11.2246 23.0316 11.5361 23.1607 11.8609 23.1607H20.4338C20.7586 23.1607 21.0701 23.0316 21.2998 22.8019C21.5295 22.5723 21.6585 22.2608 21.6585 21.936V17.6495"
                          stroke="rgb(255, 111, 0)"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M20.7397 11.2198C20.9833 10.9762 21.3137 10.8393 21.6583 10.8393C22.0028 10.8393 22.3332 10.9762 22.5768 11.2198C22.8204 11.4634 22.9572 11.7938 22.9572 12.1383C22.9572 12.4828 22.8204 12.8132 22.5768 13.0569L16.7595 18.8742L14.3101 19.4865L14.9224 17.0371L20.7397 11.2198Z"
                          fill="#E19600"
                          stroke="rgb(255, 111, 0)"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <defs>
                          <filter
                            id="filter0_d_2969_1552"
                            x="0.296875"
                            y="0.5"
                            width="33"
                            height="33"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                          >
                            <feFlood
                              floodOpacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_2969_1552"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_2969_1552"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>
                    }
                    numberStyle={classes.plannedNumberStyle}
                    name="Trip Without Eway Bill No"
                    nameForHindiLan={
                      this.props?.userLang?.baxterdDashboard
                        ?.TRIP_WITHOUT_EWAY_BILL ?? "Trip Without Eway Bill No"
                    }
                    value={trips?.tripsWithOutEwayBillNumber[0]?trips?.tripsWithOutEwayBillNumber[0]?.total:0}
                    color={"#E19600"}
                    cardClick={this.cardClick}
                  />
                </Grid>
                <Grid item xs={6} md={3} className="pendingPdCss">
                  <DashboardCard
                    cardStyle={classes.completedCardStyle}
                    nameStyle={classes.completedNameStyle}
                    iconStyle={classes.completedIconStyle}
                    icon={
                      <svg
                        width="34"
                        height="34"
                        viewBox="0 0 34 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g filter="url(#filter0_d_6303_19)">
                          <rect
                            x="4.29199"
                            y="4.5"
                            width="25"
                            height="25"
                            rx="4"
                            fill="#F1F5F7"
                          />
                        </g>
                        <path
                          d="M17 17.75C17.4832 17.75 17.875 17.3582 17.875 16.875C17.875 16.3918 17.4832 16 17 16C16.5168 16 16.125 16.3918 16.125 16.875C16.125 17.3582 16.5168 17.75 17 17.75Z"
                          fill="#0C0C0C"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M23.125 17.75C23.6082 17.75 24 17.3582 24 16.875C24 16.3918 23.6082 16 23.125 16C22.6418 16 22.25 16.3918 22.25 16.875C22.25 17.3582 22.6418 17.75 23.125 17.75Z"
                          fill="#0C0C0C"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.875 17.75C11.3582 17.75 11.75 17.3582 11.75 16.875C11.75 16.3918 11.3582 16 10.875 16C10.3918 16 10 16.3918 10 16.875C10 17.3582 10.3918 17.75 10.875 17.75Z"
                          fill="#0C0C0C"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <defs>
                          <filter
                            id="filter0_d_6303_19"
                            x="0.291992"
                            y="0.5"
                            width="33"
                            height="33"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                          >
                            <feFlood
                              floodOpacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_6303_19"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_6303_19"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>
                    }
                    numberStyle={classes.completedNummberStyle}
                    name=
                      "Quality Check"
                  
                    nameForHindiLan={
                      this.props?.userLang?.baxterdDashboard?.QUALITY_CHECK ??
                      "Quality Check"
                    }
                    value={trips?.qualityCheckCompleted}
                    iconClr={classes.completeEye}
                    color={"#006400"}
                    cardClick={this.cardClick}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <DashboardCard
                    cardStyle={classes.inTransistCardStyle}
                    nameStyle={classes.inTransistNameStyle}
                    iconStyle={classes.inTransistIconStyle}
                    icon={
                      <svg
                        className="customDashboardIcon"
                        width="34"
                        height="34"
                        viewBox="0 0 34 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g filter="url(#filter0_d_2969_2588)">
                          <rect
                            x="4.00488"
                            y="4.5"
                            width="25"
                            height="25"
                            rx="4"
                            fill={darkTheme ? "#171722" : "#EAC8FF"}
                          />
                        </g>
                        <path
                          d="M17.871 11.4474H8.6167V19.4678H17.871V11.4474Z"
                          fill="#A020F0"
                          stroke="#A020F0"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M20.0747 14.5322H22.5425L24.3934 16.383V19.4678H20.0747V14.5322Z"
                          fill="#A020F0"
                          stroke="#A020F0"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.393 22.5526C12.2448 22.5526 12.9354 21.862 12.9354 21.0102C12.9354 20.1583 12.2448 19.4678 11.393 19.4678C10.5411 19.4678 9.85059 20.1583 9.85059 21.0102C9.85059 21.862 10.5411 22.5526 11.393 22.5526Z"
                          fill="#A020F0"
                          stroke="#A020F0"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M20.6918 22.5526C21.5436 22.5526 22.2342 21.862 22.2342 21.0102C22.2342 20.1583 21.5436 19.4678 20.6918 19.4678C19.84 19.4678 19.1494 20.1583 19.1494 21.0102C19.1494 21.862 19.84 22.5526 20.6918 22.5526Z"
                          fill="#A020F0"
                          stroke="#A020F0"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <defs>
                          <filter
                            id="filter0_d_2969_2588"
                            x="0.00488281"
                            y="0.5"
                            width="33"
                            height="33"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                          >
                            <feFlood
                              floodOpacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_2969_2588"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_2969_2588"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>
                    }
                    numberStyle={classes.inTransistNumberStyle}
                    name="In Transit"
                    nameForHindiLan={this.props?.userLang?.dashboard?.IN_TRANSIT}
                    color={"#A020F0"}
                    iconClr={classes.intranEye}
                    value={trips?.inTransit}
                    cardClick={this.cardClick}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <DashboardCard
                    cardStyle={classes.plannedCardStyles}
                    nameStyle={classes.plannedNameStyles}
                    iconStyle={classes.plannedIconStyles}
                    icon={
                      <svg
                        width="33"
                        height="34"
                        viewBox="0 0 33 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g filter="url(#filter0_d_2969_2586)">
                          <rect
                            x="4"
                            y="4.5"
                            width="25"
                            height="25"
                            rx="4"
                            fill={darkTheme ? "#171722" : "#FFE3E1"}
                          />
                        </g>
                        <path
                          d="M21.3215 15.8659C21.3215 19.8353 16.2185 23.2375 16.2185 23.2375C16.2185 23.2375 11.1147 19.8353 11.1147 15.8659C11.1147 14.5124 11.6524 13.2143 12.6095 12.2572C13.5666 11.3002 14.8646 10.7625 16.2181 10.7625C17.5716 10.7625 18.8697 11.3002 19.8268 12.2572C20.7838 13.2143 21.3215 14.5124 21.3215 15.8659Z"
                          fill="#AC1000"
                          stroke="#AC1000"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16.4607 17.8908C17.4448 17.8908 18.2427 17.093 18.2427 16.1088C18.2427 15.1246 17.4448 14.3268 16.4607 14.3268C15.4765 14.3268 14.6787 15.1246 14.6787 16.1088C14.6787 17.093 15.4765 17.8908 16.4607 17.8908Z"
                          fill="#FFE3E1"
                          stroke="#FFE3E1"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.1147 21.9912L21.8848 10.7695"
                          stroke="#AC1000"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <defs>
                          <filter
                            id="filter0_d_2969_2586"
                            x="0"
                            y="0.5"
                            width="33"
                            height="33"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                          >
                            <feFlood
                              floodOpacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_2969_2586"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_2969_2586"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>
                    }
                    numberStyle={classes.transitNumberStyle}
                    name=
                      "Tracking Status"
                    
                    nameForHindiLan={
                      this.props?.userLang?.baxterdDashboard?.TRACKING_STATUS ??
                      "Tracking Status"
                    }
                    value={trips}
                    color={"#E19600"}
                    cardClick={this.cardClick}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <DashboardCard
                    cardStyle={classes.completedCardStyle}
                    nameStyle={classes.completedNameStyle}
                    iconStyle={classes.completedIconStyle}
                    icon={
                      <svg
                        className="customDashboardIcon"
                        width="34"
                        height="34"
                        viewBox="0 0 34 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g filter="url(#filter0_d_2969_2587)">
                          <rect
                            x="4.00488"
                            y="4.5"
                            width="25"
                            height="25"
                            rx="4"
                            fill={darkTheme ? "#171722" : "#A5F2BB"}
                          />
                        </g>
                        <path
                          d="M20.9951 11.227H12.0148C11.3063 11.227 10.7319 11.8013 10.7319 12.5099V21.4901C10.7319 22.1986 11.3063 22.773 12.0148 22.773H20.9951C21.7036 22.773 22.278 22.1986 22.278 21.4901V12.5099C22.278 11.8013 21.7036 11.227 20.9951 11.227Z"
                          fill="#006400"
                          stroke="#006400"
                          strokeWidth="2.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M19.588 14.7578L15.1035 19.2423L13.4219 17.5606"
                          stroke="#A5F2BB"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <defs>
                          <filter
                            id="filter0_d_2969_2587"
                            x="0.00488281"
                            y="0.5"
                            width="33"
                            height="33"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                          >
                            <feFlood
                              floodOpacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_2969_2587"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_2969_2587"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>
                    }
                    numberStyle={classes.completedNummberStyle}
                    name="Completed"
                    nameForHindiLan={this.props?.userLang?.dashboard?.COMPLETED}
                    value={trips?.completed}
                    iconClr={classes.completeEye}
                    color={"#006400"}
                    cardClick={this.cardClick}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {})(DashboardCards);
