import { types } from "./actionTypes";

// creating action
export const requestLogbookReport = (filters, callback) => {
  return {
    type: types.GET_LOGBOOK_DATA,
    filters,
    callback,
  };
};

export const createLogbookS3 = (payload, callback) => {
  return {
    type: types.CREATE_LOGBOOK_S3_URL,
    payload,
    callback,
  };
};
export const getLogbookDataS3Data = (filters, callback) => {
  return {
    type: types.GET_LOGBOOK_DATA_EXCEL_S3,
    filters,
    callback,
  };
};
