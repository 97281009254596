import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import VehiclePlacementReducer from './reducer';
const VehicleDetailPlacement = combineReducers({
    vehicleDetailPlacement: VehiclePlacementReducer,
    form: formReducer
});


export default VehicleDetailPlacement;
