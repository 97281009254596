import React, { Component } from "react";
// import packages
import { Card } from "@material-ui/core";
import { Row, Col, Input, Button } from "reactstrap";
import Select from "react-select";

export default class LoadAddItem extends Component {
  constructor(props) {
    super(props);
    this.props = {};
  }

  render() {
    return (
      <>
        <Card className=" shadow LoadAddItem">
          <Row>
            <Col xs={12}>
              <span className="fontRoboto15 spanAddItemName">Add Items</span>
            </Col>
            <Col xs={12}>
              <hr className="mt-2 addItem-hr-line" />
            </Col>
          </Row>
          <Row className="rowPadding">
            <Col xs={11}>
              <Input
                style={{ height: "30px", fontSize: "12px", width: "100%" }}
                className="form-control fontRoboto12"
                type="text"
                placeholder="Lot No..."
                value={this.props.lotNo ? this.props.lotNo : ""}
                // onChange={(e)=>{
                //   this.onChangeLotNo(e)
                // }}
                onChange={(e) =>
                  this.props.handleSubmitChange({
                    target: {
                      name: "lotno",
                      value: e.target.value,
                    },
                  })
                }
              />
            </Col>
            <Col
              xs="1"
              style={{
                paddingLeft: "0px",
                paddingLeftImportant: true,
              }}
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 0H4C1.79086 0 0 1.79086 0 4V22C0 24.2091 1.79086 26 4 26H22C24.2091 26 26 24.2091 26 22V4C26 1.79086 24.2091 0 22 0Z"
                  fill="#0AB38C"
                />
                <path
                  d="M7 13L10.75 16.75L18.25 9.25"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Col>
            <Col xs={12} className="mt-3">
              <div>
                <span className="fontRoboto137">Add box dimensions</span>
              </div>
            </Col>
            <Col xs={12} className="mt-3 addbox">
              <div
                className="d-flex flex-row"
                style={{
                  width: "101%",
                }}
              >
                <span className="pr-2 fontRoboto127 ptd5">L:</span>
                <span className="pr-2 RenderInput">
                  <Input
                    className="fontRoboto12"
                    style={{
                      width: "100%",
                      height: "30px",
                      paddingTop: "5px ",
                    }}
                    type="number"
                    value={this.props.length ? this.props.length : ""}
                    onChange={(e) =>
                      this.props.handleSubmitChange({
                        target: {
                          name: "length",
                          value: e.target.value,
                        },
                      })
                    }
                  />
                </span>
                <span className="pr-2 fontRoboto127 ptd5">W:</span>
                <span className="pr-2 RenderInput">
                  <Input
                    className="fontRoboto12"
                    style={{
                      width: "100%",
                      height: "30px",
                      paddingTop: "5px ",
                    }}
                    type="number"
                    value={this.props.width || ""}
                    onChange={(e) =>
                      this.props.handleSubmitChange({
                        target: {
                          name: "width",
                          value: e.target.value,
                        },
                      })
                    }
                  />
                </span>
                <span className="pr-2 fontRoboto127 ptd5">H:</span>
                <span className="pr-2 RenderInput">
                  <Input
                    className="fontRoboto12"
                    style={{
                      width: "100%",
                      height: "30px",
                      paddingTop: "5px ",
                    }}
                    type="number"
                    value={this.props.height || ""}
                    onChange={(e) =>
                      this.props.handleSubmitChange({
                        target: {
                          name: "height",
                          value: e.target.value,
                        },
                      })
                    }
                  />
                </span>
                <span>
                  <Select
                    className="fontRoboto12"
                    classNamePrefix="react-select1"
                    value={this.props.selectedOption}
                    options={this.props.measureOptions}
                    onChange={(e) => {
                      this.props.onChangeSelect(e);
                    }}
                  ></Select>
                </span>
              </div>
            </Col>
            <Col xs={12} className="mt-3">
              <div>
                <span className="fontRoboto137">Add Weight & No of boxes</span>
              </div>
            </Col>
            <Col xs={12} className="mt-3">
              <div
                className="d-flex flex-row"
                style={{
                  width: "101%",
                }}
              >
                <span className="pr-2 RenderInput">
                  <Input
                    className="fontRoboto12"
                    style={{
                      width: "100%",
                      height: "30px",
                      paddingTop: "5px ",
                    }}
                    type="number"
                    value={this.props.totalKg || ""}
                    onChange={(e) =>
                      this.props.handleSubmitChange({
                        target: {
                          name: "totalkg",
                          value: e.target.value,
                        },
                      })
                    }
                  />
                </span>
                <span className="pr-2 fontRoboto12 ptd5">kg</span>
                <span
                  className="pr-2"
                  style={{
                    width: "29%",
                  }}
                >
                  <div
                    className="btn-group"
                    style={{
                      height: "30px",
                    }}
                  >
                    <button
                      type="button"
                      className="btn fontRoboto127"
                      style={{
                        backgroundColor: "#0AB38C",
                        color: "#FFFFFF",
                        padding: "5px",
                      }}
                    >
                      Total
                    </button>
                    <button
                      type="button"
                      className="btn fontRoboto127"
                      style={{
                        backgroundColor: "#F1F5F7",
                        color: "#0C0C0C",
                        padding: "5px",
                      }}
                    >
                      Pcs
                    </button>
                  </div>
                </span>
                <span className="pr-2 RenderInput">
                  <span className="d-flex flex-row">
                    <span>
                      <svg
                        onClick={() => this.props.increament()}
                        className="DownArrow"
                        width="15"
                        height="15"
                        viewBox="0 0 7 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.000198364 3.3501L3.3502 9.12599e-05L6.7002 3.3501L0.000198364 3.3501Z"
                          fill="#0C0C0C"
                        />
                      </svg>
                    </span>
                    <span>
                      <Input
                        className="PcsInput fontRoboto12"
                        style={{
                          width: "100%",
                          height: "30px",
                          paddingTop: "5px ",
                        }}
                        type="number"
                        value={this.props.totalPcs || ""}
                        onChange={(e) =>
                          this.props.handleSubmitChange({
                            target: {
                              name: "totalpcs",
                              value: e.target.value,
                            },
                          })
                        }
                      />
                    </span>
                    <span>
                      <svg
                        onClick={() => this.props.decreament()}
                        className="upArrow"
                        width="15"
                        height="15"
                        viewBox="0 0 7 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.7 0L3.35 3.35001L0 0L6.7 0Z"
                          fill="#0C0C0C"
                        />
                      </svg>
                    </span>
                  </span>
                </span>
                <span className="pr-2 fontRoboto12 ptd5">Pcs</span>
              </div>
            </Col>
            <Col xs={12} className="mt-3">
              <textarea
                style={{
                  resize: "none",
                }}
                value={this.props.description}
                onChange={(e) =>
                  this.props.handleSubmitChange({
                    target: {
                      name: "description",
                      value: e.target.value,
                    },
                  })
                }
                className="form-control fontRoboto12 darkTexBox"
                id="exampleFormControlTextarea1"
                rows="2"
                placeholder="Type Discription Here"
              />
            </Col>
            <Col xs={12} className="mt-3">
              <Button
                className="resetButtonStyle fontRoboto134"
                style={{
                  backgroundColor: "#0AB38C",
                  color: "white",
                  height: "30px",
                }}
                onClick={() => this.props.onReset()}
              >
                Reset
              </Button>
              <Button
                className="submitButtonStyle fontRoboto134"
                style={{
                  backgroundColor: this.props.mode ? "#5E40FF" : "#05284E",
                  color: "white",
                  height: "30px",
                }}
                onClick={() => this.props.addDetails()}
              >
                Add
              </Button>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs={12}>
              <span className="ffontRoboto137  spanAddItemName">
                Item Details
              </span>
            </Col>
            <Col xs={12}>
              <hr className="mt-2 addItem-hr-line" />
            </Col>
          </Row>
          <Row className="rowPadding">
            {this.props.addItemDetails.length > 0 ? (
              <Col xs={12} className="itemDetailsDiv">
                {this.props.addItemDetails.map((ele, i) => (
                  <Card className="shadow ItemDetailsCard mt-2" key={i}>
                    <Row className="itemdetailsRowPadding">
                      <Col xs={10}>
                        <div className="fontRoboto127">{ele?.lotno} </div>
                      </Col>
                      <Col xs={1}>
                        <div
                          style={{
                            width: "5px",
                            height: "5px",
                            backgroundColor: `${ele?.color}`,
                            borderRadius: "50%",
                            boxShadow: `0 0 10px 5px ${ele?.color}`,
                            marginLeft: "-16px",
                            marginTop: "5px",
                          }}
                        ></div>
                      </Col>
                      <Col xs={1}>
                        <div className="fontRoboto127">
                          <svg
                            style={{
                              width: "13px",
                            }}
                            onClick={() => this.props.deleteItemDetails(i, ele)}
                            width="7"
                            height="7"
                            viewBox="0 0 7 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6 1L1 6"
                              stroke="#2800FC"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M1 1L6 6"
                              stroke="#2800FC"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </Col>
                      <Col xs={9}>
                        <div className="fontRoboto12 mt-2">
                          ( {ele?.length} {ele?.measures?.label} X {ele?.width}
                          {ele?.measures?.label} X {ele?.height}
                          {ele?.measures?.label}) {ele?.totalKg}kg/Total
                        </div>
                      </Col>
                      <Col xs={3}>
                        <div className="fontRoboto12 mt-2">
                          {ele?.totalPcs} pcs
                        </div>
                      </Col>
                    </Row>
                  </Card>
                ))}
              </Col>
            ) : (
              ""
            )}
          </Row>
        </Card>
      </>
    );
  }
}
