import React, { Component } from "react";

import { Row, Col, Card, CardBody, Container, Button } from "reactstrap";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

//Import Breadcrumb
import { MDBDataTable } from "mdbreact";
// import {MDBTableEditor} from 'mdb-react-table-editor';
import {
  fetchOrders,
  getConfirmOrderDetails,
  confirmOrder,
  deleteOrder,
} from "../../store/order/actions";
import { connect } from "react-redux";
import NotificationManager from "react-notifications/lib/NotificationManager";

var rows = [];
var data = [];
class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      error: true,
      breadcrumbItems: [
        { title: "Order", link: "employee" },
        { title: "Order Details", link: "#" },
      ],
    };
  }
  componentDidMount() {
    const path = this.props.history.location.pathname;
    const tripId = path.split("/")[2];
    const dealerId = path.split("/")[3];
    const data = {
      tripID: tripId,
      dealerID: dealerId,
    };
    this.props.getConfirmOrderDetails(data, (res) => {
      if (res.orders && res.orders.length > 0) {
        this.setState({ error: false });
        rows = [];
        res.orders.forEach((ele) => {
          //console.log('ele.productId', ele);
          rows.push({
            ProductCode: ele.ProductCode,
            ProductDimension: ele.ProductDimension,
            ProductName: ele.ProductName,
            Quantity: ele.Quantity,
            ProductPrice: ele.ProductPrice,
            SubTotal: ele.SubTotal,
            ExternalOrderId: ele.ExternalOrderId,
            DealerCode: ele.DealerCode,
            OrderStatus: ele.OrderStatus,
          });
        });
      } else {
        this.setState({ error: true });
        NotificationManager.error(
          "Please Enter Valid Trip ID, Dealer ID",
          "Error"
        );
      }
    });
  }
  deleteEmp(id) {
    this.props.deleteOrder(id, () => {
      this.refreshPage();
    });
  }
  refreshPage() {
    window.location.reload();
  }

  handleAction = (action) => {
    if (action === "no") {
      window.open("about:blank", "_self");
      window.close();
    } else {
      const path = this.props.history.location.pathname;
      const tripId = path.split("/")[2];
      const dealerId = path.split("/")[3];
      const data = {
        tripID: tripId,
        dealerID: dealerId,
      };
      this.props.confirmOrder(data, (res) => {
        if (res["message"] === "Order status has been updated") {
          this.setState({ open: true });
        } else {
          NotificationManager.error("Something went wrong!", "Error");
        }
      });
    }
  };

  handleClose = () => {
    this.setState({ open: false });
    window.open("about:blank", "_self");
    window.close();
  };

  render() {
    if (this.state.error) {
      return null;
    }
    const { fullScreen } = this.props;
    data = {
      columns: [
        {
          label: "ProdCode",
          field: "ProductCode",
          sort: "asc",
          width: 200,
        },
        {
          label: "ProdDimension",
          field: "ProductDimension",
          sort: "asc",
          width: 200,
        },
        {
          label: "ProdName",
          field: "ProductName",
          sort: "asc",
          width: 150,
        },
        {
          label: "Quantity",
          field: "Quantity",
          sort: "asc",
          width: 100,
        },
        {
          label: "ProdPrice",
          field: "ProductPrice",
          sort: "asc",
          width: 100,
        },
        {
          label: "SubTotal",
          field: "SubTotal",
          sort: "asc",
          width: 100,
        },
        {
          label: "Ext.OrderId",
          field: "ExternalOrderId",
          sort: "asc",
          width: 100,
        },
        {
          label: "Status",
          field: "OrderStatus",
          sort: "asc",
          width: 100,
        },
      ],
      rows: rows,
    };

    //console.log('rows', rows);
    return (
      <div style={{ backgroundColor: "white", height: "100vh" }}>
        <React.Fragment>
          <div className="page-content">
            <h2>
              Please confirm if you have received all the items mentioned in the
              below
            </h2>
            <Container fluid>
              <Row>
                <Col xs={12}>
                  <Card>
                    <CardBody>
                      <h4 className="card-title"> Order Details </h4>
                      <MDBDataTable
                        responsive
                        striped
                        bordered
                        data={data}
                        pagesAmount={8}
                        // paging={false}
                        searching={false}
                        // onSort={(e) => this.handlePage(e)}
                        // onSearch={(e) => this.handlePage(e)}
                        // onPageChange={(e) => this.handlePage(e)}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs={5}></Col>
                <Col xs={1}>
                  <Button
                    onClick={() => this.handleAction("no")}
                    type="button"
                    color="btn btn-danger"
                    className="mb-1"
                  >
                    No
                  </Button>
                </Col>
                <Col xs={6}>
                  <Button
                    onClick={() => this.handleAction("yes")}
                    type="button"
                    color="btn btn-success"
                    className="mb-1"
                  >
                    Yes
                  </Button>
                </Col>
              </Row>
            </Container>
            <Dialog
              fullScreen={fullScreen}
              open={this.state.open}
              onClose={this.handleClose}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogContent>
                <DialogContentText>Thank you for confirming.</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} color="primary" autoFocus>
                  Done
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </React.Fragment>
      </div>
    );
  }
}
// export default Trip;
const mapStateToProps = (state) => ({
  orders: state.Order.orders,
});

export default connect(mapStateToProps, {
  fetchOrders,
  getConfirmOrderDetails,
  confirmOrder,
  deleteOrder,
})(Order);
