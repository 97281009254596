import { types } from "./actionTypes";

// creating action
export const getWeightOfInvoiceItems = (legalInvoiceArray, callback ) => {
  return {
    type: types.GET_WEIGHTOFINVOICEITEMS,
    legalInvoiceArray,
    callback,
  };
};

export const FetchBaxLoadOptimizationData = (values,callback) => ({
  type: types.LOAD_OPTIMIZATION_DATA_ACCESS,
  values,
  callback,
});

