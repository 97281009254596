import React, { Component } from "react";
import { Button, Modal, Row, Col } from "reactstrap";
import moment from "moment";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import ChargesStartEndDate from "../../BasicComponents/ChargesStartEndDate";
import SourceDestination from "../../BasicComponents/SourceDestination";
import {
  MultiPointPickChargesHeaders,
  getLocalStorage,
  searchBar,
} from "./DatatableHeaders";
import Index from "../../../../components/Common/DataTableNeo/Index";
import { Card } from "@material-ui/core";


let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class MultiPointDropCharges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      sourceObject: [],
      sourceLabel: "",
      source: "",
      destinationObject: [],
      destinationLabel: "",
      destination: "",
      charges: "",
      startDate: "",
      endDate: "",
      MultiPointDropChargesForm: [],
      MultiPointDropChargesFormID: [],
      viewMode: false,
      DateAltered: false,
      fetchId: "",
      agreementId: "",
    };
  }

  dateResetterOnModalClose = () => {
    this.setState({ startDate: "", endDate: "" });
  };

  componentDidUpdate = () => {
    let arr = [];
    let arrID = [];
    let sourceObj = [];
    let destinationObj = [];
    if (
      (this.props.billing.copiedAgreementValues.id &&
        !this.state.MultiPointDropChargesForm.length &&
        !this.state.viewMode) ||
      (this.state.fetchId !== this.props.billing.fetchId &&
        this.state.agreementId !== this.props.billing.copiedAgreementValues.id)
    ) {
      this.props.billing.copiedAgreementValues.charges.multipointDrop.forEach(
        (item, index) => {
          // console.log(item, index);
          arr.push({
            destination: item.destination.label,
            source: item.source.label,
            charges: item.charges,
            startDate: moment(item.startDate).format("l"),
            endDate: moment(item.endDate).format("l"),
          });
          arrID.push({
            destination: item.destination._id,
            source: item.source._id,
            charges: item.charges,
            startDate: item.startDate,
            endDate: item.endDate,
          });
          sourceObj.push({
            label: item.source.label,
            value: {
              id: item.source._id,
            },
          });
          destinationObj.push({
            label: item.destination.label,
            value: {
              id: item.destination._id,
            },
          });
        }
      );
      this.setState(
        {
          MultiPointDropChargesForm: arr,
          MultiPointDropChargesFormID: arrID,
          sourceObject: sourceObj,
          destinationObject: destinationObj,
          viewMode: true,
          agreementId: this.props.billing.copiedAgreementValues.id,
          fetchId: this.props.billing.fetchId,
        },
        () => {
          this.props.listFiller(
            this.state.MultiPointDropChargesForm,
            this.state.MultiPointDropChargesFormID
          );
          this.props.listShow();
        }
      );
    } else if (
      this.props.billing.startDate &&
      this.props.billing.endDate &&
      !this.state.DateAltered
    ) {
      // console.log("STATE LOGS", this.state);
      let arr = this.state.MultiPointDropChargesForm;
      let arrID = this.state.MultiPointDropChargesFormID;
      arr.forEach((item, index) => {
        item.startDate = this.props.billing.startDate.Date;
        item.endDate = this.props.billing.endDate.Date;
        // console.log("ITEM: ", index, "-", item);
      });
      arrID.forEach((item, index) => {
        item.startDate = this.props.billing.startDate.Date;
        item.endDate = this.props.billing.endDate.Date;
        // console.log("ITEMID: ", index, "-", item);
      });
      this.setState(
        {
          MultiPointDropChargesForm: arr,
          MultiPointDropChargesFormID: arrID,
          DateAltered: true,
        },
        () => {
          // console.log("ARR", this.state.MultiPointDropChargesForm);
          this.props.listFiller(
            this.state.MultiPointDropChargesForm,
            this.state.MultiPointDropChargesFormID
          );
          this.props.listShow();
        }
      );
    }
  };

  handleDelete = (item) => {
    let modifiedArray = [];
    let modifiedIDArray = [];
    let sourceId;
    let destinationId;
    // console.log("dlete");
    modifiedArray = this.state.MultiPointDropChargesForm.filter(
      (e) => e.destination !== item.destination || e.source !== item.source
    );
    this.state.sourceObject.find((o, i) => {
      if (o.label === item.source) {
        sourceId = this.state.sourceObject[i].value.id;
      }
      return null;
    });
    this.state.destinationObject.find((o, i) => {
      if (o.label === item.destination) {
        destinationId = this.state.destinationObject[i].value.id;
      }
      return null;
    });
    modifiedIDArray = this.state.MultiPointDropChargesFormID.filter(
      (e) => e.destination !== destinationId || e.source !== sourceId
    );
    this.setState(
      {
        MultiPointDropChargesForm: modifiedArray,
        MultiPointDropChargesFormID: modifiedIDArray,
      },
      () => {
        this.props.listFiller(
          this.state.MultiPointDropChargesForm,
          this.state.MultiPointDropChargesFormID
        );
        // console.log("-----", this.state.MultiPointDropChargesForm);
        // console.log(this.state.MultiPointDropChargesFormID, "--------");
      }
    );
    // this.setState({
    //   MultiPointDropChargesForm: modifiedArray,
    // });
  };

  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    const validationSchema = Yup.object({
      // vehicleType: Yup.object().required("Vehicle Type is required"),
      source: Yup.object().required(userLang?.planning?.SOURCE_IS_REQUIRED),
      destination: Yup.object().required(
        userLang?.planning?.DESTINATION_IS_REQUIRED
      ),
      startdate: Yup.date().required(userLang?.invoice?.DATE_IS_REQUIRED),
      expirydate: Yup.date().required(userLang?.invoice?.DATE_IS_REQUIRED),
      charges: Yup.number().required(userLang?.invoice?.CHARGES_IS_REQUIRED),
    });
    this.state.MultiPointDropChargesForm.forEach((item) => {
      item.actions = (
        <div style={{ color: "red" }}>
          <i
            className="fas fa-trash-alt darkInvoiceModalIcons"
            style={{ cursor: "pointer" }}
            onClick={() => this.handleDelete(item)}
          ></i>
        </div>
      );
    });
   
    return (
      <React.Fragment>
        {getLocalStorage()}
        <Modal
          isOpen={this.props.modal}
          toggle={this.props.toggle}
          size="xl"
          className="ModalHeight"
        >
          <Row className="mt-2">
            <Col xs={8}>
              <div className="m-3">
                <h4 className="fontRoboto light__span">
                  {userLang?.invoice?.MULT_POINT_DROP_ADD_CHARGES}
                </h4>
              </div>
            </Col>
            <Col xs={4} className="text-right">
              <i
                className="fas fa-times closemodal1 pr-2 text-right close"
                onClick={() => this.props.toggle()}
              ></i>
            </Col>
          </Row>
          <Row className="m-2">
            <Formik
              initialValues={{
                source: this.state.sourceLabel,
                destination: this.state.destinationLabel,
                charges: this.state.charges,
                startdate: this.props.startDate,
                expirydate: this.props.endDate,
              }}
              validationSchema={validationSchema}
              validateOnChange={true}
              onSubmit={(values, { resetForm }, e) => {
                // console.log(values);
                let chargeArray = [];
                let chargeArrayID = [];
                this.setState(
                  {
                    sourceObject: this.state.sourceObject.concat(values.source),
                    destinationObject: this.state.destinationObject.concat(
                      values.destination
                    ),
                    sourceLabel: values.source.label,
                    source: values.source.value.id,
                    destinationLabel: values.destination.label,
                    destination: values.destination.value.id,
                    charges: values.charges,
                    startDate: values.startdate,
                    endDate: values.expirydate,
                  },
                  () => {
                    // console.log("------", this.state);
                    chargeArray = {
                      source: this.state.sourceLabel,
                      destination: this.state.destinationLabel,
                      charges: this.state.charges,
                      startDate: this.state.startDate,
                      endDate: this.state.endDate,
                    };
                    chargeArrayID = {
                      source: this.state.source,
                      destination: this.state.destination,
                      charges: this.state.charges,
                      startDate: this.state.startDate,
                      endDate: this.state.endDate,
                    };
                    this.setState(
                      {
                        source: "",
                        destination: "",
                        sourceLabel: "",
                        destinationLabel: "",
                        charges: "",
                      },
                      () => {
                        // console.log(chargeArray);
                        this.setState(
                          {
                            MultiPointDropChargesForm:
                              this.state.MultiPointDropChargesForm.concat(
                                chargeArray
                              ),
                            MultiPointDropChargesFormID:
                              this.state.MultiPointDropChargesFormID.concat(
                                chargeArrayID
                              ),
                          },
                          () => {
                            // console.log(this.state.MultiPointDropChargesForm);
                            // console.log(this.state);
                            this.props.listFiller(
                              this.state.MultiPointDropChargesForm,
                              this.state.MultiPointDropChargesFormID
                            );
                          }
                        );
                      }
                    );
                  }
                );
                resetForm({
                  values: {
                    charges: "",
                    source: "",
                    destination: "",
                    startdate: this.state.startDate,
                    expirydate: this.state.endDate,
                  },
                });
              }}
            >
              {(formik) => {
                const {
                  setFieldValue,
                  handleChange,
                  handleSubmit,
                  values,                
                  errors,
                  touched,
                  handleBlur,
                 
                } = formik;
                return (
                  <Col xs={12}>
                    <form onSubmit={handleSubmit}>
                      <Row>
                        <Col xs={12} md={4}>
                          <SourceDestination
                            sourceVal={values.source}
                            destinationVal={values.destination}
                            handleBlur={handleBlur}
                            errors={errors}
                            touched={touched}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                          />
                        </Col>
                        <Col xs={12} md={6}>
                          <ChargesStartEndDate
                            valid={this.props.valid}
                            validExpiry={this.props.validExpiry}
                            handleBlur={handleBlur}
                            errors={errors}
                            touched={touched}
                            chargeVal={values.charges}
                            startDateVal={values.startdate}
                            endDateVal={values.expirydate}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                          />
                        </Col>
                        <Col xs={6} md={1} lg={1}>
                          <Button
                            style={{
                              width: "100%",
                              position: "relative",
                              top: "37%",
                              backgroundColor: "#05284E",
                            }}
                            type="submit"
                            color="secondary"
                            className={`small align-middle rounded rounded-black mb-1 ${
                              this.props.mode ? "darkAgreementAddToList" : ""
                            }`}
                          >
                            <span style={{ fontWeight: "900" }}>
                              {userLang?.invoice?.ADD_To_LIST}
                            </span>
                          </Button>{" "}
                        </Col>
                        <Col xs={6} md={1} lg={1}>
                          <Button
                            style={{
                              width: "100%",
                              position: "relative",
                              top: "37%",
                              backgroundColor: "#0AB38C",
                            }}
                            type="button"
                            color="primary"
                            className="small align-middle rounded mb-1"
                            onClick={() => {
                              this.props.listShow();
                              this.props.toggle();
                              this.dateResetterOnModalClose();
                            }}
                            disabled={!this.state.MultiPointDropChargesForm?.length}
                          >
                            <span style={{ fontWeight: "900" }}>
                              {" "}
                              {userLang?.invoice?.SAVE_ALL}
                            </span>
                          </Button>{" "}
                        </Col>
                      </Row>
                    </form>
                  </Col>
                );
              }}
            </Formik>
            <Col xs={12} className="DataTable darkDataTableCharges">
              <Card>
              <Index
                headers={MultiPointPickChargesHeaders}
                data={this.state.MultiPointDropChargesForm}
                searchBar={searchBar}
                rowHeight={"56px"}
                limit = {this.state.MultiPointDropChargesForm.length}
              />
              </Card>
            </Col>
          </Row>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  billing: state.Billing.billing,
});

export default connect(mapStateToProps, {})(MultiPointDropCharges);
