import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
//medthods
import { getApi, postApi ,deleteApi ,deleteApiPayload} from "../../helpers/auth_helper";

// workers
function* workZoneToStateMapList({ filters, searchFilter, callback }) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  let filterString = "";
  const keyMap = {
    "Warehouse Number": "warehouseNumber",
    // Add more key-value pairs as needed
  };

  if (searchFilter !== null && searchFilter !== undefined) {
    for (const [key, value] of Object.entries(searchFilter)) {
      let filterKey = keyMap[key] || key;
      let filterValue = value;
      filterString = filterString + "&" + filterKey + "=" + filterValue;
    }
  }

  try {
    const url =
      process.env.REACT_APP_SERVERURL +
      "/order/zoneToStateMapping?offset=" +
      offset +
      "&limit=" +
      limit +
      filterString;

    const response = yield call(getApi, url);
    callback(response);
    localStorage.setItem(
      "zonetoStateMap",
      response ? JSON.stringify(response) : null
    );
    yield put({
      type: types.ZONE_TO_STATE_MAP_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}

function* workAddZoneToStateMapList({ Values, callback }) {

  try {
    const url = process.env.REACT_APP_SERVERURL + "/order/zoneToStateMapping";

    const response = yield call(postApi, url, Values);
    callback(response);
    yield put({
      type: types.ADD_ZONE_TO_STATE_MAP_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}

function* workDeleteZoneToStateMapList({ Id, callback }) {
  console.log(Id,"0000000000")
  try {
    const url = process.env.REACT_APP_SERVERURL + "/order/zoneToStateMapping";
    const response = yield call(deleteApiPayload, url, Id);
    callback(response);
    yield put({
      type: types.DELETE_ZONE_TO_STATE_MAP_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
// watchers
export function* watchZoneToStateMapList() {
  yield takeEvery(types.ZONE_TO_STATE_MAP, workZoneToStateMapList);
}
export function* watchAddZoneToStateMapList() {
  yield takeEvery(types.ADD_ZONE_TO_STATE_MAP, workAddZoneToStateMapList);
}

export function* watchDeleteZoneToStateMapList() {
  yield takeEvery(types.DELETE_ZONE_TO_STATE_MAP, workDeleteZoneToStateMapList);
}

function* masterZoneToStateMapSaga() {
  yield all([fork(watchZoneToStateMapList)]);
  yield all([fork(watchAddZoneToStateMapList)]);
  yield all([fork(watchDeleteZoneToStateMapList)]);
}
export default masterZoneToStateMapSaga;
