import { types } from "./actionTypes";

export const fetchRoutes = (offset, source, destination, callback) => ({
  type: types.FETCH_ROUTES,
  offset,
  source,
  destination,
  callback,
});
export const fetchRoutesForMaster = (filters, callback) => ({
  type: types.FETCH_ROUTES_FOR_MASTER,
  filters,
  callback,
});
export const createRoute = (values, callback) => ({
  type: types.CREATE_ROUTE,
  values,
  callback,
});
