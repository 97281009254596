import React, { Component } from "react";
import { Card } from "reactstrap";
import { MDBRow, MDBCol } from "mdbreact";
import { CloseIcon } from "../../components/Common/CommonIcons";
import SecondaryTrackingMap from "../SecondaryTrackingMap";
import { fetchTripById } from "../../store/actions";
import { connect } from "react-redux";
import moment from "moment";

import TrackingPath from "../TripDetail/TrackingPath";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

export class SecondaryTripHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      source: {},
      customers: {},
    };
  }

  componentDidMount = () => {
    this.props.fetchTripById(this.state.tripId, (res) => {
      console.log(res);
      let tripData = res?.data[0];

      let driverVehicleHistory = [
        { key: userLang?.trip?.TRIP_ID, value: tripData.tripId },
        // { key: userLang?.trip?.TRIP_ID, value: "2745" },
        {
          key: userLang?.tracking?.STARTING_LOCATION,
          value: tripData.source.label,
          // value: "Vadodara-Cold Storage",
        },
        {
          key: userLang?.common?.CUSTOMERS,
          value: tripData.customers.map((item) => {
            return <div>{item.customerName},&nbsp;</div>;
          }),
          // value: "Jhagadia Factory-106",
        },
        // { key: userLang?.common?.ROUTE, value: tripData?.route?.name ?? "" },
        {
          key: userLang?.common?.START_TIME,
          value: moment(tripData.startTime).format("DD-MM-YYYY"),
          // value: moment("2022-09-28T09:30:00.000Z").format("DD-MM-YYYY"),
        },
        {
          key: userLang?.common?.TRANSPORTER,
          value: tripData.transporter.name,
          // value: "S P GOLDEN TRANSPORT PVT LTD",
        },
        { key: userLang?.trip?.DRIVER, value: tripData.driver.name },
        // { key: userLang?.trip?.DRIVER, value: "ranjit" },
        { key: userLang?.tracking?.CONTACT_NO, value: tripData.driver.phone },
        // { key: userLang?.tracking?.CONTACT_NO, value: "7698763628" },
        {
          key: userLang?.tracking?.VEHICLE_NO,
          value: tripData.vehicle.regNo,
          // value: "GJ26T4661",
        },
        {
          key: userLang?.tracking?.LAST_STATUS,
          value: moment(tripData.updatedAt).format("DD-MM-YYYY"),
          // value: moment("2022-09-28T05:19:32.126Z").format("DD-MM-YYYY"),
        },

        {
          key: userLang?.common?.STATUS,
          value: "in_progress".replace("_", " "),
        },
      ];

      this.setState(
        {
          driverVehicleHistory: driverVehicleHistory,
          source: res.data[0].source.location,

          customers: res.data[0].customers.map((item) => {
            return item.source.location;
          }),
          // destination: { latitude: "21.628877", longitude: "73.128519" },
          transporterId: tripData.transporter["_id"],
          // transporterId: "61ba509e29e8452750b80712",

          driverPhone: res.data[0].driver.phone,
          // driverPhone: "7698763628",
          selectedTripId: res?.data[0]?.tripId ?? "",
          // selectedTripId: "2745",
        },
        () => {}
      );
    });
  };

  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

    const modalStyle = {
      height: "100%",
      width: "100%",
      backgroundColor: this.props.darkTheme ? "#1B1C31" : "#ffffff",
      paddingTop: "25px",
      paddingLeft: "25px",
      paddingRight: "25px",
      paddingBottom: "auto",
      position: "relative",
    };
    const headerStyle = {
      position: "relative",
      fontFamily: "Roboto",
      fontWeight: "bold",
      fontSize: "20px",
      lineHeight: "23px",
      color: this.props.darkTheme ? "#9D9D9D" : "#0C0C0C",
      left: "0px",
    };
    const closeStyle = {
      position: "relative",
      // color: this.props.darkTheme ? "#B7A9FF" : "#2800fc",
      width: "20px",
      marginRight: "0px",
    };
    return (
      <Card style={modalStyle}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span style={headerStyle}>
            {userLang?.tracking?.TRACKING_HISTORY}
          </span>

          {/* <CloseIcon
            style={closeStyle}
            onClick={() => this.props.closeModal()}
          /> */}
          <i
            style={closeStyle}
            onClick={() => {
              this.props.closeModal();
            }}
          >
            <CloseIcon color={this.props.darkTheme ? "#B7A9FF" : "#2800fc"} />
          </i>
        </div>

        <MDBRow>
          <MDBCol
            md="6"
            sm="12"
            className={`col-example ${
              this.props.darkTheme ? "darkIntransitModalPopup" : ""
            }`}
          >
            <TrackingPath
              tripTrackId={this.props.id}
              nameTransporter={this.props.transporterName}
              nameDriver={this.props.driverName}
              tripIdHistory={this.props.tripIdHistory}
            />
          </MDBCol>

          <MDBCol
            md="6"
            className="col-example PaddingCol"
            style={{ overflow: "auto" }}
          >
            <Card className="googleMapCard">
              <SecondaryTrackingMap
                id={this.props.id}
                source={this.state.source}
                customers={this.state.customers}
              />
            </Card>
          </MDBCol>
        </MDBRow>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    trips: state.Trip.trips.trips,
  };
};

export default connect(mapStateToProps, {
  fetchTripById,
})(SecondaryTripHistory);
