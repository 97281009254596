import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  USER_DETAILS_SUCCESSFUL,
  LOAD_USERS_SUCCESSFUL
} from "./actionTypes";

const initialState = {
  registrationError: null,
  message: null,
  loading: null,
  userDetail: null,
  fetchId: "",
};

const account = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER:
      state = {
        ...state,
        user: null,
        loading: true,
        registrationError: null,
      };
      break;

    case REGISTER_USER_SUCCESSFUL:
      state = {
        ...state,
        user: action.payload,
        loading: false,
        registrationError: null,
      };
      break;
    case REGISTER_USER_FAILED:
      state = {
        ...state,
        loading: false,
        registrationError: action.payload,
      };
      break;
    case USER_DETAILS_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        userDetail: action.payload,
      };
      break;
    case LOAD_USERS_SUCCESSFUL:
      state={
        ...state,
        fetchUser : action.payload,
        fetchId :  "id" + new Date().getTime()
      };
      break;
    
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default account;