// import { mapKeys } from 'lodash';
import { types } from '../actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    // case types.FETCH_EMPLOYEE_SUCCESS:
    //   // Copy the current state and set a new property with a dynamic key value and the payload as the value
    //   return { ...state, [action.payload.id]: action.payload };
    case types.FETCH_EMPLOYEES_SUCCESS:
      //   // Create a new state object that uses an AJAX request response and grabs the 'id' property from each object in the response to use as its key
      return action.payload 
    case types.CREATE_EMPLOYEE_SUCCESS:
      // Copy the current state and set a new property with a dynamic key value and the payload as the value
      return { ...state, [action.payload.id]: action.payload };
    case types.DELETE_EMPLOYEE_SUCCESS:
      // Copy the current state and delete the property with the specified key
      var newState = { ...state };
      delete newState[action.payload.id];
      return newState;
    default:
      state = { ...state };
      break;
  
}
//   case REGISTER_USER:
//     state = {
//         ...state,
//         user: null,
//         loading: true,
//         registrationError: null
//     }
//     break;

// case REGISTER_USER_SUCCESSFUL:
//     state = {
//         ...state,
//         user: action.payload,
//         loading: false,
//         registrationError: null
//     }
//     break;
// case REGISTER_USER_FAILED:
//     state = {
//         ...state,
//         loading: false,
//         registrationError: action.payload
//     }
//     break;
return state;

};
