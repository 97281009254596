import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col, Card, Button, FormGroup, Input } from "reactstrap";
import SearchIcon from "../assets/search.png";
import Datetime from "react-datetime";
import Select from "react-select";
var darkMode ="";
class Quickfilter extends Component {
  render() {
    darkMode = this.props?.mode;
    const customStyles1 = {
      indicatorsContainer: (style) => {
        return {
          ...style,
          backgroundColor: darkMode ? "#2E2E46" : "",
          width: "45px",
          height: "34px",
          textAlign: "center",
          justifyContent: "center",
          borderRadius: "0px 4px 4px 0px",
        };
      },
      input: (base) => ({
        ...base,
        color: darkMode ? "#9d9d9d" : "#505d69",
      }),
      indicatorSeparator: (style) => {
        return {
          ...style,
          backgroundColor: darkMode ? "#f2f3fc" : "#f2f3fc",
          margin: "4px",
        };
      },
      control: (style) => {
        return {
          ...style,
          height: "26px",
          minHeight:"34px",
          width:"100%",
          backgroundColor: darkMode ? "#2E2E46" : "",
        };
      },
      dropdownIndicator: (style) => {
        return {
          ...style,
          alignSelf: "start",
        };
      },
      menu: (style) => {
        return {
          ...style,
          backgroundColor: darkMode ? "#171722" : "#ffffff",
          color: darkMode ? "#B7A9FF" : "rgb(51, 51, 51)",
          opacity: darkMode ? "1" : "",
          border: darkMode
            ? "0.5px solid #616161"
            : "0.5px solid rgb(204, 204, 204)",
          boxShadow: darkMode
            ? "0px 3px 12px rgba(0, 0, 0, 0.12)"
            : "0px 3px 12px rgba(0, 0, 0, 0.12)",
          // zIndex: "22px",
        };
      },
    };
    const { userLang, mode } = this.props;
    return (
      <>
        <Card className="darkBgQuickFilter">
          <div className="quickStylesBar quickstyleAddagreement darkQuickAgreement">
            <Row>
              <Col xs={12} md={3} className="MobilePadding">
                <Row className="d-flex justify-content-between">
                  <Col>
                    <Button
                      type="button"
                      color="#05284E"
                      className="small align-middle mb-1 rounded-black "
                      onClick={() => this.props.copyAgreementToggle()}
                    >
                      <span className="fontRoboto12">
                        {userLang?.invoice?.COPY_FROM_LAST_AGREEMENT}
                      </span>
                    </Button>{" "}
                  </Col>
                  <Col className="mobileDisplay">
                    {/* <Button
                      style={{
                        minWidth: "10%",
                      }}
                      className="mobile-reset reset"
                    >
                      <img className="mobile-rest-img" src={refreshIcon}></img>
                    </Button> */}
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={1} className="padding5 MobilePaddingR pr-2">
                {/* <FormGroup className="required select2-container">
                  <div className="d-flex">
                    <div className="rdt">
                      <Input
                        name="transporter"
                        value={this.props.selectedTransporterName}
                        id="transporter"
                        placeholder="Select Transpoter..."
                        // onChange={(e) => console.log("changed")}
                        // readOnly
                        onChange={(e) => this.props.OnChangeTranspoter(e)}
                      />
                    </div>
                    {this.props.billing.viewMode ? (
                      ""
                    ) : (
                      <span className="SearchAddAgre">
                        <span className=" input-line pl-1 ml-1"></span>
                        <span className="" onClick={() => this.props.toggle()}>
                          <img src={SearchIcon} alt=""/>
                        </span>
                      </span>
                    )}
                  </div>
                </FormGroup> */}
              </Col>
              <Col xs={12} md={3}>
              <FormGroup className="p-0 m-0">
                  <div className="d-flex">
                    <Select
                      value={this.props.selectedGroup}
                      className="form-control-sm"
                      onChange={this.props.handleSelectGroup}
                      options={this.props.transporterOption}
                      classNamePrefix="select2-selection"
                      styles={customStyles1}
                      
                    />
                  </div>
                </FormGroup>
              </Col>
             
              <Col xs={12} md={2} className="padding5 pr-2">
                <FormGroup className="select2-container required">
                  <div className="d-flex">
                    <Datetime
                      value={new Date(this.props.startDate) || null}
                      className="d-flex flex-sm-grow-1 time-cont"
                      timeFormat={false}
                      name="date"
                      isValidDate={this.props.valid}
                      onChange={(e) => this.props.onChangeStartDate(e)}
                      id="date"
                      ref={this.props.startdate}
                      onFocus={(e) => {}}
                      inputProps={{
                        placeholder: "Start Date..",
                        disabled: this.props.billing.viewMode,
                      }}
                      // disable={this.props.billing.viewMode}
                    />
                    <svg
                      onClick={() => this.props.startDateCalenderToggle()}
                      style={{
                        position: "absolute",
                        right: "21px",
                        top: "7px",
                        width: "22px",
                      }}
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill={mode ? "#2e2e46" : "none"}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22 0H4C1.79086 0 0 1.79086 0 4V22C0 24.2091 1.79086 26 4 26H22C24.2091 26 26 24.2091 26 22V4C26 1.79086 24.2091 0 22 0Z"
                        fill={"#F1F5F7"}
                      />
                      <path
                        d="M22 0.25H4C1.92893 0.25 0.25 1.92893 0.25 4V22C0.25 24.0711 1.92893 25.75 4 25.75H22C24.0711 25.75 25.75 24.0711 25.75 22V4C25.75 1.92893 24.0711 0.25 22 0.25Z"
                        stroke={"#2800FC"}
                        strokeWidth="0.5"
                      />
                      <path
                        d="M18 7H8C6.89543 7 6 7.89543 6 9V18C6 19.1046 6.89543 20 8 20H18C19.1046 20 20 19.1046 20 18V9C20 7.89543 19.1046 7 18 7Z"
                        stroke={"#2800FC"}
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16 6V8"
                        stroke={"#2800FC"}
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10 6V8"
                        stroke={"#2800FC"}
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6 11H20"
                        stroke={"#2800FC"}
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </FormGroup>
              </Col>
              <Col xs={12} md={2} className="padding5 pr-2">
                <FormGroup className="select2-container required">
                  <div className="d-flex">
                    <Datetime
                      value={new Date(this.props.endDate) || null}
                      name="expirydate"
                      timeFormat={false}
                      isValidDate={this.props.validExpiryLocal}
                      onChange={(e) => this.props.onChangeEndadate(e)}
                      options={[]}
                      input={true}
                      id="expirydate"
                      ref={this.props.expirydate}
                      inputProps={{
                        placeholder: "Expiry Date..",
                        disabled: this.props.billing.viewMode,
                      }}
                    />
                    <svg
                      onClick={() => this.props.endDateCalenderToggle()}
                      style={{
                        position: "relative",
                        left: "-10%",
                        top: "2px",
                        width: "22px",
                      }}
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22 0H4C1.79086 0 0 1.79086 0 4V22C0 24.2091 1.79086 26 4 26H22C24.2091 26 26 24.2091 26 22V4C26 1.79086 24.2091 0 22 0Z"
                        fill={"#F1F5F7"}
                      />
                      <path
                        d="M22 0.25H4C1.92893 0.25 0.25 1.92893 0.25 4V22C0.25 24.0711 1.92893 25.75 4 25.75H22C24.0711 25.75 25.75 24.0711 25.75 22V4C25.75 1.92893 24.0711 0.25 22 0.25Z"
                        stroke={"#2800FC"}
                        strokeWidth="0.5"
                      />
                      <path
                        d="M18 7H8C6.89543 7 6 7.89543 6 9V18C6 19.1046 6.89543 20 8 20H18C19.1046 20 20 19.1046 20 18V9C20 7.89543 19.1046 7 18 7Z"
                        stroke={"#2800FC"}
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16 6V8"
                        stroke={"#2800FC"}
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10 6V8"
                        stroke={"#2800FC"}
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6 11H20"
                        stroke={"#2800FC"}
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </FormGroup>
              </Col>
              {!this.props.billing.viewMode ? (
                <Col xs={12} md={1} className="desktopDisplay">
                  <div
                    style={{
                      position: "relative",
                      top: "-3%",
                    }}
                  >
                    <Button
                      onClick={() => {
                        this.props.reset();
                      }}
                      className="operations-dashboard-btn-reset"
                      variant="outlined"
                      style={{
                        height: "26px",
                        width: "90%",
                      }}
                    >
                      {this.props?.userLang?.common?.RESET}
                    </Button>
                  </div>
                </Col>
              ) : (
                ""
              )}
            </Row>
          </div>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  fetchId: state.Billing.billing.fetchId,
  billing: state.Billing.billing,
});

export default connect(mapStateToProps, {})(withRouter(Quickfilter));
