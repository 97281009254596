import React, { Component } from "react";
import { connect } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import { Row, Col, Container } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { NotificationManager } from "react-notifications";
import { exportToCSV } from "../common/exportToCsv";

//actions
import { bifurcationReport,bifurcationReportExcel } from "../../store/baxter-report/actions";

import Index from "../../components/Common/DataTableNeo/Index";
import {
  AdminHeaderData,
  getLocalStorage,
  searchBar,
} from "./RowHeaders/RowHeaders";
import QuickFilters from "./quickFilters";
import { Button } from "reactstrap";
import moment from "moment";

var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var limit = 10;
var searchValues = {};
var darkTheme;
class BifuricationVolume extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dataLoading: false,
      offset: 0,
      searchFilters: {},
    };
  }

  componentDidMount() {
    this.getAllData();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 0.9;
  }
  getAllData = () => {
    this.setState({ dataLoading: true }, () => { });
    let searchFilters = this.state.searchFilters;
    this.props.bifurcationReport(
      { offset: offset, limit: 10 },
      this.state.searchFilters,
      (res) => {
        this.setState({ dataLoading: false });
      }
    );
    // this.setState({ dataLoading: false });
  };
  componentWillUnmount = () => {
    offset = 0;
  };
  getAllDataReset = () => {
    offset = 0;
    this.getAllData();
  };
  handleChangePage = (event, val) => {
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    this.setState({ loading: true });
    this.getAllData();
  };
  handleChange = (dateValues) => {
    searchValues["fromOrderDispatchDate"] =
      dateValues[0] !== null
        ? dateValues[0]
          ? moment(dateValues[0]).format("YYYY-MM-DD")
          : moment(dateValues).format("YYYY-MM-DD")
        : null;
    searchValues["toOrderDispatchDate"] =
      dateValues[1] !== null
        ? moment(dateValues[1]).format("YYYY-MM-DD")
        : null;
    this.setState({ searchFilters: searchValues }, () => {
      offset = 0;
      this.getAllData();
    });
  };

  viewOrderProductData = (ele) => {
    this.props.history.push({
      pathname: "/viewOrderProductDetail",
      state: { currentProduct: ele },
    });
  };

  searchValueAssignerFunction = (name, value, type) => {
    if (typeof string === "text" || type === "text") {
      searchValues[name] = value?.replace(/^\s+/, "");
      this.setState({ searchFilters: searchValues }, () => { });
    }

    if (type === "date") {
      offset = 0;
      searchValues[name] = value;
      this.setState({ searchFilters: searchValues }, () => {
        this.getAllData();
      });
    }
  };
  reset = () => {
    offset = 0;
    searchValues = {};
    this.setState({ searchFilters: {} }, () => {
      this.getAllData();
    });
  };
  leadTimeTAT = (date1, date2) => {
    const dates1 = new Date(date1);
    const dates2 = new Date(date2);
    var timeDiff = Math.abs(dates2?.getTime() - dates1?.getTime());
    var daysDiff = Math.ceil(timeDiff / (24 * 60 * 60 * 1000));
    return `${daysDiff} day`;
  };

  exportExcelData = () => {
    this.setState({ dataLoading: true });
    // console.log("kjrfreifde")
    this.props.bifurcationReportExcel(
      { offset: 0, limit: 10000 },
      this.state.searchFilters,
      (res) => {
        console.log(res)
        this.setState({ dataLoading: false });
        let exportData = [];
        if (res?.data?.docs?.length > 0) {
          res.data.docs.map((ele) => {
            // console.log("ele", ele);

            let total = ele?.couriermodeVolume + ele?.truckmodeVolume;
            let courierMode = ele?.couriermodeVolume ? ele?.couriermodeVolume : 0;
            let truckMode = ele?.truckmodeVolume ? ele?.truckmodeVolume : 0;
            exportData.push({
              "Date": ele?.orderDispatchDate ? (

                moment(ele?.orderDispatchDate).format("YYYY-MMM-DD")

              ) : (
                "N/A"
              ),
              "Courier Mode (MT)": courierMode?.toFixed(2),
              "Courier Mode (%)": ((courierMode / total) * 100)?.toFixed(2),
              "Truck Mode (MT)": truckMode?.toFixed(2),
              "Truck Mode (%)": ((truckMode / total) * 100)?.toFixed(2),
              "Total": total?.toFixed(2),
            });
          });
        }
        if (exportData?.length > 0) {
          exportToCSV([...exportData], "Bifurication Volume Report");
        }else{
          exportData.push({
            "Date": "",
            "Courier Mode (MT)": "",
            "Courier Mode (%)": "",
            "Truck Mode (MT)": "",
            "Truck Mode (%)": "",
            "Total": "",
          });
          exportToCSV([...exportData], "Bifurication Volume Report");
        }
      }
    );


  };


  render() {
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    darkTheme = mode === "dark__mode" ? true : false;

    const { bifurcationReportData } = this.props;
    const localStorageData = JSON.parse(localStorage.getItem("LeadTimeReport"));
    const bifurcationData = navigator.onLine
      ? bifurcationReportData?.data?.docs
      : localStorageData?.data?.docs;
    let rows = [];
    if (Array.isArray(bifurcationData)) {
      totCnt = navigator.onLine
        ? bifurcationReportData?.data?.length
        : localStorageData?.data?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = navigator.onLine
        ? bifurcationReportData?.data?.offset +
        bifurcationReportData?.data?.docs?.length
        : localStorageData?.data?.offset + localStorageData?.data?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      bifurcationData.forEach((ele) => {
        let total = ele?.couriermodeVolume + ele?.truckmodeVolume;
        let courierMode = ele?.couriermodeVolume ? ele?.couriermodeVolume : 0;
        let truckMode = ele?.truckmodeVolume ? ele?.truckmodeVolume : 0;
        rows.push({
          date: ele?.orderDispatchDate ? (
            <>
              <div>{moment(ele?.orderDispatchDate).format("YYYY-MMM-DD")}</div>
            </>
          ) : (
            "N/A"
          ),
          courierMode: courierMode?.toFixed(2),
          courierModeVolume: ((courierMode / total) * 100)?.toFixed(2),
          truckMode: truckMode?.toFixed(2),
          truckModeVolume: ((truckMode / total) * 100)?.toFixed(2),
          total: total?.toFixed(2),
        });
      });
    }
    return (
      <>
        {getLocalStorage()}
        <QuickFilters toggleExcel={this.exportExcelData} reset={this.reset} handleChange={this.handleChange} />
        <Container
          aria-hidden="true"
          fluid
          className={`${darkTheme ? "darkBackgroundList mobilepPadding" : "mobilepPadding"
            }`}
        >
          <div className="dispatchList">
            <Index
              {...this.props}
              headers={AdminHeaderData}
              data={rows}
              searchable={false}
              searchBar={searchBar}
              fetchId={this.props.fetchId}
              reset={this.reset}
              searchValueAssignerFunction={this.searchValueAssignerFunction}
              getAllData={this.getAllDataReset}
              dataLength={90}
              rowHeight={"56px"}
              dataLoading={this.state.dataLoading}
              dashboard="true"
              classList="3"
              textInput="inputIndex"
              resetCol="3"
            />
          </div>

          {totCnt !== 0 && !this.state.dataLoading ? (
            <Row
              xs={12}
              style={{ color: darkTheme ? "#9d9d9d" : "#0C0C0C" }}
              className="mb-2 paginationRow"
            >
              <Col xs={7} sm={5} md={4} className="span-col-pagenation">
                <div className="float-left">
                  <Pagination
                    className="desktop-pagination"
                    size="medium"
                    shape="rounded"
                    page={offset / 10 + 1}
                    count={totPages}
                    color="primary"
                    onChange={this.handleChangePage}
                    defaultPage={1}
                    siblingCount={1}
                  />
                  <Pagination
                    className="mobile-pagination"
                    size="small"
                    shape="rounded"
                    page={offset / 10 + 1}
                    count={totPages}
                    color="primary"
                    onChange={this.handleChangePage}
                    defaultPage={1}
                    siblingCount={1}
                  />
                </div>
              </Col>
              <Col
                className="mt-2 spanPagenationentries span-col-pagenation"
                xs={5}
                sm={7}
                md={8}
              >
                {" "}
                <span>
                  Showing {fromVal} to {toVal} of {totCnt} entries
                </span>
              </Col>
            </Row>
          ) : null}
        </Container>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
    bifurcationReportData:
      state?.dispatchLeadTimeReportReducer?.bifurcationReportData,
    fetchId: state.dispatchLeadTimeReportReducer?.fetchId,
  };
};
export default connect(mapStateToProps, {
  bifurcationReport,
  bifurcationReportExcel
})(BifuricationVolume);
