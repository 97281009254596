let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  AdminHeaderData = {
    columns: [
      {
        label: "VehicleType",
        field: "vehicleTypeCode",
        sort: "asc",
        width: 100,
      },

      {
        label: "Label",
        field: "label",
        sort: "asc",
        width: 100,
      },

      {
        label: "Length",
        field: "specLength",
        sort: "asc",
        width: 100,
      },
      {
        label: "Width",
        field: "specWidth",
        sort: "asc",
        width: 100,
      },
      {
        label: "Height",
        field: "specHeight",
        sort: "asc",
        width: 100,
      },

      {
        label: "Weight",
        field: "specWeight",
        sort: "asc",
        width: 100,
      },

      {
        label: "Image",
        field: "image",
        sort: "asc",
        width: 100,
      },

      {
        label: userLang?.common?.ACTIONS,
        field: "actions",
        sort: "asc",
        width: 100,
      },
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var AdminHeaderData = {
  columns: [
    {
      label: "vehicleType",
      field: "vehicleTypeCode",
      sort: "asc",
      width: 50,
    },
    {
      label: "Label",
      field: "label",
      sort: "asc",
      width: 100,
    },
    {
      label: "Length",
      field: "specLength",
      sort: "asc",
      width: 100,
    },
    {
      label: "Width",
      field: "specWidth",
      sort: "asc",
      width: 50,
    },
    {
      label: "Height",
      field: "specHeight",
      sort: "asc",
      width: 100,
    },
    {
      label: "Weight",
      field: "specWeight",
      sort: "asc",
      width: 100,
    },

    {
      label: userLang?.common?.ACTIONS,
      field: "actions",
      sort: "asc",
      width: 100,
    },
  ],
};
export const searchBar = {
  searchBarData: [
    {
      label: "vehicleType",
      field: "vehicleTypeCode",
      sort: "asc",
      width: 50,
    },
    {
      label: "Label",
      field: "label",
      sort: "asc",
      width: 100,
    },
    {
      label: "Length",
      field: "specLength",
      sort: "asc",
      width: 100,
    },
    {
      label: "Width",
      field: "specWidth",
      sort: "asc",
      width: 50,
    },
    {
      label: "Height",
      field: "specHeight",
      sort: "asc",
      width: 100,
    },
    {
      label: "Weight",
      field: "specWeight",
      sort: "asc",
      width: 100,
    },
  ],
};
