import { Description } from "@material-ui/icons";

let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  AdminHeaderData = {
    columns: [
   
      {
        label: "Address line1",
        field: "addressLine1",
        sort: "asc",
        width: 2,
      },
      {
        label: "Address line2",
        field: "addressLine2",
        sort: "asc",
        width: 2,
      },
      {
        label: "Address line3",
        field: "addressLine3",
        sort: "asc",
        width: 2,
      },
      {
        label: "Address line4",
        field: "addressLine4",
        sort: "asc",
        width: 2,
      },
      {
        label: "City",
        field: "city",
        sort: "asc",
        width: 1,
      },
      {
        label: "Postal Code",
        field: "postalCode",
        sort: "asc",
        width: 1,
      },
      {
        label: "State",
        field: "state",
        sort: "asc",
        width: 1,
      },
      {
        label: "Country Description",
        field: "region",
        sort: "asc",
        width: 2,
      },
      {
        label: "Regional Description",
        field: "regionalDescription",
        sort: "asc",
        width: 2,
      },
     
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var AdminHeaderData = {
  columns: [
   
    {
      label: "Address line1",
      field: "addressLine1",
      sort: "asc",
      width: 2,
    },
    {
      label: "Address line2",
      field: "addressLine2",
      sort: "asc",
      width: 2,
    },
    {
      label: "Address line3",
      field: "addressLine3",
      sort: "asc",
      width: 2,
    },
    {
      label: "Address line4",
      field: "addressLine4",
      sort: "asc",
      width: 2,
    },
    {
      label: "City",
      field: "city",
      sort: "asc",
      width: 1,
    },
    {
      label: "Postal Code",
      field: "postalCode",
      sort: "asc",
      width: 1,
    },
    {
      label: "State",
      field: "state",
      sort: "asc",
      width: 1,
    },
    {
      label: "Country Description",
      field: "region",
      sort: "asc",
      width: 2,
    },
    {
      label: "Regional Description",
      field: "regionalDescription",
      sort: "asc",
      width: 2,
    },
   
  ],
};

export var searchBar;
searchBar = {
  searchBarData: [
    {
      label: "City",
      field: "city",
      type: "text",
    },
    {
      label: "Postal Code",
      field: "postalCode",
      type: "text",
    },
    {
      label: "State",
      field: "stateDescription",
      type: "text",
    },
    {
      label: "Country Description",
      field: "countryDescription",
      type: "text",
    },
    {
      label: "Regional Description",
      field: "regionalDescription",
      type: "text",
    },
    {},
  ],
};

