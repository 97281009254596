import {types } from "./actionTypes";

// creating action
export const getProducts = (filters, searchFilter, callback) =>{
    return {
        type:types.GET_PRODUCTS,
        filters,
        searchFilter,
        callback
    }
}

export const getProductsExcel = (filters, searchFilter, callback) =>{
    return {
        type:types.GET_PRODUCTS_EXCEL,
        filters,
        searchFilter,
        callback
    }
}

export const createProduct = (payload, callback) =>{
    return {
        type:types.CREATE_PRODUCT,
        payload,
        callback
    }
}
export const createProductViaExcel = (payload, callback) =>{
    return {
        type:types.CREATE_PRODUCT_VIA_EXCEL,
        payload,
        callback
    }
}
export const updateProduct = (payload, callback) =>{
    return {
        type:types.UPDATE_PRODUCT,
        payload,
        callback
    }
}
export const deleteProduct = (payload, callback) =>{
    return {
        type:types.DELETE_PRODUCT,
        payload,
        callback
    }
}

export const UpdateProductValues = (Values, callback) => {
    return {
      type: types.UPDATE_OPTIMIZATION_VAlUES,
      Values,
      callback,
    };
  };