import { types } from "./actionTypes";

// creating action
export const getTripList = (filters, searchFilter, callback) => {
  return {
    type: types.GET_TRIP_LIST,
    filters,
    searchFilter,
    callback,
  };
};
export const getTripListForExcel = (filters, searchFilter, callback) => {
  return {
    type: types.GET_TRIP_LIST_FOR_EXCEL,
    filters,
    searchFilter,
    callback,
  };
};

export const changeMarkedAsArrivedStatus = (tripID, callback) => {
  return {
    type: types.CHANGE_MARKED_AS_ARRIVED_STATUS,
    tripID,
    callback,
  };
};

export const qualityCheckStart = (values, callback) => {
  return {
    type: types.QUALITY_CHECK_START,
    values,
    callback,
  };
};

export const startLoadingStatus = (tripIDVal, callback) => ({
  type: types.START_LOADING_STATUS,
  tripIDVal,
  callback,
});

export const loadingCompleted = (tripIDVal, callback) => ({
  type: types.LOADING_COMPLETED,
  tripIDVal,
  callback,
});

export const tripHistory = (id, callback) => ({
  type: types.VIEW_TRIP_HISTORY,
  id,
  callback,
});

export const completeTrip = (Values, callback) => ({
  type: types.COMPLETE_TRIP,
  Values,
  callback,
});

export const updateMobileOnTripTrack = (Values, callback) => ({
  type: types.UPDATE_MOBILE_TRACK,
  Values,
  callback,
});
