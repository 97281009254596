let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  CustomersHeaderData = {
    columns: [
      {
        label: "Action",
        field: "Action",
        sort: "asc",
        width: 1,
      },
      {
        label: "Customer Name",
        field: "customerName",
        sort: "asc",
        width: 2,
      },
      {
        label: "Customer Number",
        field: "Number",
        sort: "asc",
        width: 1,
      },
      {
        label: "Ship To Code",
        field: "ShipToCode",
        sort: "asc",
        width: 1,
      },

      {
        label: "City",
        field: "City",
        sort: "asc",
        width: 1,
      },
      {
        label: "Address",
        field: "Address",
        sort: "asc",
        width: 2,
      },
      {
        label: "Postal Code",
        field: "PostalCode",
        sort: "asc",
        width: 1,
      },
      {
        label: "State",
        field: "State",
        sort: "asc",
        width: 1,
      },
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var CustomersHeaderData = {
  columns: [
    {
      label: "Action",
      field: "Action",
      sort: "asc",
      width: 1,
    },
    {
      label: "Customer Name",
      field: "customerName",
      sort: "asc",
      width: 2,
    },
    {
      label: "Customer Number",
      field: "Number",
      sort: "asc",
      width: 1,
    },
    {
      label: "Ship To Code",
      field: "ShipToCode",
      sort: "asc",
      width: 1,
    },

    {
      label: "City",
      field: "City",
      sort: "asc",
      width: 1,
    },
    {
      label: "Address",
      field: "Address",
      sort: "asc",
      width: 2,
    },
    {
      label: "Postal Code",
      field: "PostalCode",
      sort: "asc",
      width: 1,
    },
    {
      label: "State",
      field: "State",
      sort: "asc",
      width: 1,
    },
  ],
};

export const searchBarSecondary = {
  searchBarData: [
    {
      label: userLang?.common?.CUSTOMERS,
      field: "customerName",
      type: "select",
    },
    {
      label: userLang?.planning?.CUSTOMER_NUMBER,
      field: "customerNumber",
      type: "text",
    },
    {
      label: userLang?.planning?.SHIPTOCODE,
      field: "shipToCode",
      type: "text",
    },
    {
      label: userLang?.common?.ACTIONS,
      field: "actions",
      type: "text",
    },
  ],
};
