export const types = {
  FETCH_TRIPS_SECONDARY: "FETCH_TRIPS_SECONDARY",
  FETCH_TRIPS_SECONDARY_SUCCESS: "FETCH_TRIPS_SECONDARY_SUCCESS",

  FETCH_TRIPS_EXCEL_SECONDARY: "FETCH_TRIPS_EXCEL_SECONDARY",
  FETCH_TRIPS_EXCEL_SECONDARY_SUCCESS: "FETCH_TRIPS_EXCEL_SECONDARY_SUCCESS",

  CHANGE_ARRIVEDATSOURCE_STATUS_SECONDARY:
    "CHANGE_ARRIVEDATSOURCE_STATUS_SECONDARY",
  CHANGE_ARRIVEDATSOURCE_STATUS_SECONDARY_SUCCESS:
    "CHANGE_ARRIVEDATSOURCE_STATUS_SECONDARY_SUCCESS",

  CHANGE_MARKASARRIVED_INTRANSIT_STATUS_SECONDARY:
    "CHANGE_MARKASARRIVED_INTRANSIT_STATUS_SECONDARY",
  CHANGE_MARKASARRIVED_INTRANSIT_STATUS_SECONDARY_SUCCESS:
    "CHANGE_MARKASARRIVED_INTRANSIT_STATUS_SECONDARY_SUCCESS",

  SET_TRIP_CUSTOMER_DROPDOWN: "SET_TRIP_CUSTOMER_DROPDOWN",
  SET_TRIP_CUSTOMER_DROPDOWN_SUCCESS: "SET_TRIP_CUSTOMER_DROPDOWN_SUCCESS",

  SET_TRIP_CUSTOMER_DROPDOWN_FALSE: "SET_TRIP_CUSTOMER_DROPDOWN_FALSE",
  SET_TRIP_CUSTOMER_DROPDOWN_FALSE_SUCCESS:
    "SET_TRIP_CUSTOMER_DROPDOWN_FALSE_SUCCESS",

  TRIP_QUALITY_CHECK_SECONDARY: "TRIP_QUALITY_CHECK_SECONDARY",
  TRIP_QUALITY_CHECK_SECONDARY_SUCCESS: "TRIP_QUALITY_CHECK_SECONDARY_SUCCESS",

  START_LOADING_SECONDARY: "START_LOADING_SECONDARY",
  START_LOADING_SECONDARY_SUCCESS: "START_LOADING_SECONDARY_SUCCESS",

  START_UNLOADING_SECONDARY: "START_UNLOADING_SECONDARY",
  START_UNLOADING_SECONDARY_SUCCESS: "START_UNLOADING_SECONDARY_SUCCESS",

  SUBSCRIBE_CONSENT_SECONDARY: "SUBSCRIBE_CONSENT_SECONDARY",
  SUBSCRIBE_CONSENT_SECONDARY_SUCCESS: "SUBSCRIBE_CONSENT_SECONDARY_SUCCESS",

  SHIPMENT_VERIFY_SECONDARY: "SHIPMENT_VERIFY_SECONDARY",
  SHIPMENT_VERIFY_SECONDARY_SUCCESS: "SHIPMENT_VERIFY_SECONDARY_SUCCESS",

  SHIPMENT_VERIFY_INFO_SECONDARY: "SHIPMENT_VERIFY_INFO_SECONDARY",
  SHIPMENT_VERIFY_INFO_SECONDARY_SUCCESS:
    "SHIPMENT_VERIFY_INFO_SECONDARY_SUCCESS",

  ADD_SHIPMENT_VERIFY_SECONDARY: "ADD_SHIPMENT_VERIFY_SECONDARY",
  ADD_SHIPMENT_VERIFY_SECONDARY_SUCCESS:
    "ADD_SHIPMENT_VERIFY_SECONDARY_SUCCESS",

  FINISH_LOADING_SECONDARY: "FINISH_LOADING_SECONDARY",
  FINISH_LOADING_SECONDARY_SECONDARY: "FINISH_LOADING_SECONDARY_SECONDARY",

  TRIP_EPOD_SECONDARY: "TRIP_EPOD_SECONDARY",
  TRIP_EPOD_SECONDARY_SUCCESS: "TRIP_EPOD_SECONDARY_SUCCESS",

  TRIP_EPOD_COMPLETE_SECONDARY: "TRIP_EPOD_COMPLETE_SECONDARY",
  TRIP_EPOD_COMPLETE_SECONDARY_SUCCESS: "TRIP_EPOD_COMPLETE_SECONDARY_SUCCESS",

  TRIP_COMPLETE_SECONDARY: "TRIP_COMPLETE_SECONDARY",
  TRIP_COMPLETE_SECONDARY_SUCCESS: "TRIP_COMPLETE_SECONDARY_SUCCESS",

  ACKNOWLEDGE_LR_SECONDARY: "ACKNOWLEDGE_LR_SECONDARY",
  ACKNOWLEDGE_LR_SECONDARY_SUCCESS: "ACKNOWLEDGE_LR_SECONDARY_SUCCESS",
};
