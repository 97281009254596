import React, { Component } from "react";
import { Card } from "@material-ui/core";
import { Row, Col } from "reactstrap";
// import { Card } from "reactstrap";
import TripStatusChildCard from "./TripStatusChildCard";
import { ExportIcon } from "../../../components/Common/CommonIcons";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export default class TripStatusCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: false,
      export: false,
      loading: false,
    };
  }
  toggle = () => {
    this.setState((prevState) => ({
      menu: false,
    }));
  };
  toggleExcel = async () => {
    setTimeout(async () => {
      let tripStatusArr = [];
      if (this.props?.trips) {
        if (this.props?.trips !== undefined) {
          let planned = this.props.trips?.planned;
          let arrivedAtSource = this.props.trips?.arrivedAtSource;
          let qualityCheck = this.props.trips?.qualityCheckCompleted;
          let LoadingProgress = this.props.trips?.loadingInProgress;
          let shipmentVerified = this.props.trips?.shipmentVerified;
          let inTransit = this.props.trips?.inTransit;
          let arrivedAtVia = this.props.trips?.arrivedAtVia;
          let completed = this.props.trips?.completed;

          tripStatusArr.push({
            Planned: planned,
            ArrivedAtSource: arrivedAtSource,
            QualityCheckCompleted: qualityCheck,
            LoadingInProgress: LoadingProgress,
            ShipmentVerified: shipmentVerified,
            Intransit: inTransit,
            ArrivedAtVia: arrivedAtVia,
            completed: completed,
          });
        }
      }
      await this.exportToCSV([...tripStatusArr], "TRIPSTATUS_DATA_EXCEL");
    }, 2000);
    this.setState({ loading: true, export: true });
  };
  exportToCSV = (csvData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    this.setState({ export: false });
  };

  render() {
    const trips = this.props.trips;
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    var darkMode = mode === "dark__mode" ? true : false;
    return (
      <>
        <Card className="trip-card-div darkCardStyle">
          <div className="mt-2 pl-3">
            <Row className="pt-2">
              <Col xs="9" className="pdt5">
                <span className="roboto-font-700 span-trip-status light__span">
                  {this.props?.userLang?.dashboard?.TRIP_STATUS}
                </span>
              </Col>
              <Col xs="3">
                <span
                  className="light__span"
                  style={{
                    float: "right",
                    marginLeft: "535px",
                  }}
                >
                  <div
                    className="d-flex justify-content-end "
                    style={{
                      position: "relative",
                      right: "24px",
                    }}
                  >
                    <div className="pr-2">
                      {/* <div className=''>
                      <Dropdown
                        isOpen={this.state.menu}
                        toggle={this.toggle}
                        className='d-none d-sm-inline-block'
                      >
                        <DropdownToggle
                          style={{
                            width: '100%',
                            display: 'flex',
                            height: '26px',
                            border: '1px solid blue',

                            justifyContent: 'center',
                          }}
                          tag='button'
                          className='btn mt-0 pt-0'
                        // id="page-header-user-dropdown"
                        >
                          <span
                            style={{
                              display: 'flex',
                              marginTop: '5px',
                            }}
                            className='d-xl-inline-block ml-1 text-transform profile-name'
                          >
                            Monthly
                          </span>
                          <i
                            style={{
                              fontSize: '17px',
                            }}
                            className='mdi mdi-chevron-down ml-1 pl-1 d-xl-inline-block mt-0 profile-chervon'
                          ></i>
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem></DropdownItem>

                          <DropdownItem
                            href=''
                            className='notify-item'
                          ></DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div> */}
                    </div>
                    <div>
                      <div  className={navigator.onLine ? "tooltipIcon" : "tooltipIcon disable-export"}>
                        <i className="cursor-pointer exportDark"
                        onClick={() => this.toggleExcel()}><ExportIcon color={darkMode?"#B7A9FF":"#2800FC"}/></i>
                        <span
                          className="tooltipIcontext1"
                          // style={{ width: "55px" }}
                        >
                          {this.props?.userLang?.invoice?.DOWNLOAD_EXCEL ?? "Download Excel"}
                        </span>
                      </div>
                    </div>
                  </div>
                </span>
              </Col>
            </Row>
          </div>
          <hr className="mt-3 trip-status-hr-line" />
          <TripStatusChildCard
            trips={trips}
            userLang={this.props?.userLang}
            mode={darkMode}
            tripData={this.props.tripData}
          />
          <Row className="">
            <Col xs="6">
              <Row className=" ml-3 mt-1">
                <Col xs="2" className="mt-1">
                  <span className="span-tripstatus-circle1 span-trip-planned-out-circle">
                    <span className="span-tripstatus-circle2 span-operTat-planned-small-circle"></span>
                  </span>
                </Col>
                <Col xs="10" className="tripstatus-names">
                  <span className="mt-0 mobile-trip-label-pd light__span">
                    {" "}
                    {this.props?.userLang?.dashboard?.PLANNED}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col xs="6">
              <Row className=" ml-3 mt-2">
                <Col xs="2" className="mt-1">
                  <span className="span-tripstatus-circle1 span-trip-shipment-out-circle">
                    <span className="span-tripstatus-circle2 span-trip-shipment-small-circle"></span>
                  </span>
                </Col>
                <Col xs="10" className="tripstatus-names">
                  <span className="mt-0 mobile-trip-label-pd light__span">
                    {" "}
                    {this.props?.userLang?.dashboard?.SHIPMENT_VERIFIED}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-0">
            <Col xs="6">
              <Row className=" ml-3 mt-2">
                <Col xs="2" className="mt-1">
                  <span className="span-tripstatus-circle1 span-trip-arrived-out-circle">
                    <span className="span-tripstatus-circle2 span-trip-arrived-small-circle"></span>
                  </span>
                </Col>
                <Col xs="10" className="tripstatus-names">
                  <span className="mt-0  mobile-trip-label-pd light__span">
                    {this.props?.userLang?.dashboard?.ARRIVED_AT_SOURCE}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col xs="6">
              <Row className=" ml-3 mt-2">
                <Col xs="2" className="mt-1">
                  <span className="span-tripstatus-circle1 span-trip-intransist-out-circle">
                    <span className="span-tripstatus-circle2 span-trip-intransist-small-circle"></span>
                  </span>
                </Col>
                <Col xs="10" className="tripstatus-names">
                  <span className="mt-0  mobile-trip-label-pd light__span">
                    {" "}
                    {this.props?.userLang?.dashboard?.IN_TRANSIT}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-0">
            <Col xs="6">
              <Row className=" ml-3 mt-2">
                <Col xs="2" className="mt-1">
                  <span className="span-tripstatus-circle1 span-trip-qcc-out-circle">
                    <span className="span-tripstatus-circle2 span-trip-qcc-small-circle"></span>
                  </span>
                </Col>
                <Col xs="10" className="tripstatus-names">
                  <span className="mt-0  mobile-trip-label-pd light__span">
                    {this.props?.userLang?.dashboard?.QUALITY_CHECK_COMPLETED}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col xs="6">
              <Row className=" ml-3 mt-2">
                <Col xs="2" className="mt-1">
                  <span className="span-tripstatus-circle1 span-trip-arrived-out-circle">
                    <span className="span-tripstatus-circle2 span-trip-arrived-small-circle"></span>
                  </span>
                </Col>
                <Col xs="10" className="tripstatus-names">
                  <span className="mt-0  mobile-trip-label-pd light__span">
                    {this.props?.userLang?.dashboard?.ARRIVED_AT_VIA_POINT}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-0">
            <Col xs="6">
              <Row className=" ml-3 mt-2">
                <Col xs="2" className="mt-1">
                  <span className="span-tripstatus-circle1 span-trip-loading-out-circle">
                    <span className="span-tripstatus-circle2 span-trip-loading-small-circle"></span>
                  </span>
                </Col>
                <Col xs="10" className="tripstatus-names">
                  <span className="mt-0  mobile-trip-label-pd light__span">
                    {this.props?.userLang?.dashboard?.LOADING_IN_PROGRESS}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col xs="6">
              <Row className=" ml-3 mt-2">
                <Col xs="2" className="mt-1">
                  <span className="span-tripstatus-circle1 span-trip-complete-out-circle">
                    <span className="span-tripstatus-circle2 span-operTat-complete-small-circle"></span>
                  </span>
                </Col>
                <Col xs="10" className="tripstatus-names">
                  <span className="mt-0  mobile-trip-label-pd light__span">
                    {" "}
                    {this.props?.userLang?.dashboard?.COMPLETED}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </>
    );
  }
}
