import React from "react";
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import { connect } from "react-redux";
import { compose } from "redux";
import TrackingMap from "../TrackingMap";

import { Row, Col, Card, Button } from "reactstrap";
import Autocomplete from "./Autocomplete";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

const closeImg = require("../../assets/images/navigation/closeIcon.svg");

class AddressModal extends React.Component {
  constructor(props) {
    super(props);
    this.destRef = React.createRef();
    this.srcRef = React.createRef();
    this.state = {
      sourceAlias: "",
      destinationAlias: "",
    };
  }

  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    // let form = this.props.form
    // console.log("PROPS", this.props)
    return (
      <React.Fragment>
        <div className="AddressModalContainer">
          <MDBContainer>
            <MDBModal
              isOpen={this.props.isOpen}
              toggle={() => this.props.toggle()}
              centered
              className=""
            >
              <MDBModalHeader
                toggle={this.toggle}
                style={{
                  borderBottom: "0 none",
                  padding: "15px 0px 0px 20px",
                }}
              >
                <i
                  className="addressModalCloseIcon close"
                  onClick={() => this.props.toggle()}
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 1L1 13"
                      stroke="#2800FC"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1 1L13 13"
                      stroke="#2800FC"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </i>
                <span className="modalTitle fontRoboto">{"Add Addresses"}</span>
                <div>
                  <div
                    className="fontRoboto1541 pt-2 dark__span"
                    style={{
                      color: "#05284E",
                    }}
                  >
                    <div>{"Add One Source Location"}</div>
                  </div>
                </div>
              </MDBModalHeader>
              <MDBModalBody>
                <Card className="">
                  <Row>
                    <Col style={{ padding: "20px" }}>
                      <div className="modalContainer">
                        <div style={{ paddingRight: "40px" }}>
                          {/* <input ref={this.inputRef} /> */}

                          <div className="inputGroup mt-3">
                            <label className="labelStyle">Vehicle</label>
                            <input
                              id="vehicle"
                              placeholder="Add No."
                              name="vehicle"
                              className="inputStyle"
                              value={this.props.form.vehicleNo}
                              onChange={(e) => {
                                this.props.handleChange(
                                  e.target.value,
                                  "vehicle"
                                );
                              }}
                            />
                          </div>

                          <div className="inputGroup mt-3">
                            <label className="labelStyle">Source</label>
                            <span
                              ref={this.srcRef}
                              style={{
                                width: "80%",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Autocomplete
                                callback={(e) => {
                                  const srcLat = e.geometry.location.lat();
                                  const srcLng = e.geometry.location.lng();
                                  const source = e.name;
                                  let sourceObj = this.props.form.source;
                                  sourceObj["source"] = source;
                                  sourceObj["srcLat"] = srcLat;
                                  sourceObj["srcLng"] = srcLng;
                                  this.props.handleChange(sourceObj, "source");
                                }}
                              />
                              <input
                                id="sourceAlias"
                                placeholder="Alias"
                                name="sourceAlias"
                                className="aliasInputStyle"
                                value={this.state.sourceAlias}
                                onChange={(e) => {
                                  this.setState({
                                    sourceAlias: e.target.value,
                                  });
                                  this.props.handleChange(
                                    e.target.value,
                                    "sourceAlias"
                                  );
                                }}
                              />
                            </span>
                          </div>
                          <div className="inputGroup mt-3">
                            <label className="labelStyle">Destination</label>
                            <span
                              ref={this.destRef}
                              style={{
                                width: "80%",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Autocomplete
                                label={"destination"}
                                callback={(e) => {
                                  const destLat = e.geometry.location.lat();
                                  const destLng = e.geometry.location.lng();
                                  const destination = e.name;
                                  let destinationObj =
                                    this.props.form.destination;
                                  destinationObj["destination"] = destination;
                                  destinationObj["destLat"] = destLat;
                                  destinationObj["destLng"] = destLng;
                                  this.props.handleChange(
                                    destinationObj,
                                    "destination"
                                  );
                                }}
                              />
                              <input
                                id="destinationAlias"
                                placeholder="Alias"
                                name="destinationAlias"
                                value={this.state.destinationAlias}
                                className="aliasInputStyle"
                                onChange={(e) => {
                                  this.setState({
                                    destinationAlias: e.target.value,
                                  });
                                  this.props.handleChange(
                                    e.target.value,
                                    "destinationAlias"
                                  );
                                }}
                              />
                            </span>
                          </div>
                          <div className="inputGroup mt-3">
                            <label className="labelStyle"></label>
                            <span style={{ width: "80%" }}>
                              <Button
                                className="buttonStyle"
                                style={{
                                  backgroundColor: "#05284E",
                                  color: "white",
                                }}
                                onClick={() => {
                                  this.props.addDestination();
                                  this.destRef.current.children[0].value = "";
                                  this.setState({
                                    destinationAlias: "",
                                  });
                                }}
                              >
                                {"Add Address"}
                              </Button>
                            </span>
                          </div>
                        </div>
                        <Card
                          style={{
                            minHeight: "445px",
                            maxHeight: "445px",
                            overflowY: "auto",
                            marginTop: "10px",
                            padding: "0px 20px",
                            border: "none !important",
                          }}
                        >
                          {/* {"hey" + JSON.stringify(JSON.parse(this.props.form.destinationList))} */}
                          {this.props.form.destinationList.map(
                            (item, index) => {
                              return (
                                <div className="inputGroup mt-3">
                                  <span
                                    style={{
                                      alignItems: "center",
                                      width: "10%",
                                    }}
                                  >
                                    <label className="labelStyle">
                                      {index + 1}.
                                    </label>
                                  </span>
                                  <span
                                    style={{
                                      width: "90%",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <input
                                      id="destination"
                                      name="destination"
                                      placeholder={item.destination}
                                      className="inputStyle inputCard"
                                    />
                                    <input
                                      style={{ width: "23% !important" }}
                                      id="destinationAlias"
                                      name="destinationAlias"
                                      placeholder={item.destinationAlias}
                                      className="aliasInputStyle inputAliasCard"
                                    />
                                    <img
                                      src={closeImg}
                                      onClick={() => {
                                        // console.log("Remove ")
                                        this.props.removeDestination(item);
                                      }}
                                      alt=""
                                    />
                                  </span>
                                </div>
                              );
                            }
                          )}
                        </Card>

                        <div
                          className="mt-2"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div>
                            <Button
                              className="resetButtonStyle"
                              style={{
                                backgroundColor: "#0AB38C",
                                color: "white",
                              }}
                              onClick={() => {
                                this.props.reset();
                                this.setState({
                                  sourceAlias: "",
                                  destinationAlias: "",
                                });

                                this.destRef.current.children[0].value = "";
                                this.srcRef.current.children[0].value = "";
                              }}
                            >
                              {userLang?.common?.RESET}
                            </Button>
                            <Button
                              className="submitButtonStyle"
                              style={{
                                backgroundColor: "#05284E",
                                color: "white",
                              }}
                              onClick={() => {
                                this.props.submit();
                              }}
                            >
                              {"Submit"}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col>
                      <TrackingMap
                        id={"RouteOptimization"}
                        detailToggler={() => {}}
                        handleVehicleHistory={() => {}}
                        source={this.props.form.source}
                        destinationList={this.props.form.destinationList}
                        resetFlag={this.props.resetFlag}
                        changeResetFlag={this.props.changeResetFlag}
                        srcChange={this.props.srcChange}
                        destChange={this.props.destChange}
                      />
                    </Col>
                  </Row>
                </Card>
              </MDBModalBody>
            </MDBModal>
          </MDBContainer>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default compose(connect(mapStateToProps, {}))(AddressModal);
