import React, { Component } from "react";
import SecondaryTripHistory from "./SecondaryTripHistory";
import { MDBModal, MDBModalBody, MDBContainer } from "mdbreact";
export default class SecondaryTripHistoryModel extends Component {
  render() {
    let mode = localStorage.getItem("Theme");
    var darkState = mode === "dark__mode" ? true : false;
    return (
      <div className="tripHistoryModel">
        <React.Fragment>
          <MDBContainer className="text-capitalize">
            <MDBModal
              isOpen={this.props.isOpen}
              toggle={() => this.props.toggleModel()}
              size="xl"
            >
              <MDBModalBody
                className={`indentModal ${
                  darkState ? "darkBackgroundList" : ""
                }`}
              >
                <SecondaryTripHistory
                  id={this.props.tripIds}
                  transporterName={this.props.transporter}
                  driverName={this.props.driver}
                  closeModal={this.props.toggleModel}
                  tripIdHistory={this.props.tripIdHistory}
                  darkTheme={darkState}
                />
              </MDBModalBody>
            </MDBModal>
          </MDBContainer>
        </React.Fragment>
      </div>
    );
  }
}
