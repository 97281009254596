/* LAYOUT */
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const CHANGE_LAYOUT_WIDTH = "CHANGE_LAYOUT_WIDTH";
export const CHANGE_SIDEBAR_THEME = "CHANGE_SIDEBAR_THEME";
export const CHANGE_SIDEBAR_TYPE = "CHANGE_SIDEBAR_TYPE";
export const STORE_SHIPMENTDATA = "STORE_SHIPMENTDATA";

// topbar
export const CHANGE_TOPBAR_THEME = "CHANGE_TOPBAR_THEME";

/* RIGHT SIDEBAR */
export const TOGGLE_RIGHT_SIDEBAR = "TOGGLE_RIGHT_SIDEBAR";
export const SHOW_RIGHT_SIDEBAR = "SHOW_RIGHT_SIDEBAR";
export const HIDE_RIGHT_SIDEBAR = "HIDE_RIGHT_SIDEBAR";

// Preloader
export const CHANGE_PRELOADER = "CHANGE_PRELOADER";
export const types = {
  FETCH_FILTER_TRIPS: "FETCH_FILTER_TRIPS",
  FETCH_TRIPS: "FETCH_TRIPS",
  FETCH_TRIPS_PRICOL: "FETCH_TRIPS_PRICOL",
  FETCH_TRIPS_PRICOL_SUCCESS: "FETCH_TRIPS_PRICOL_SUCCESS",
  FETCH_HOPS_LIST: "FETCH_HOPS_LIST",
  FETCH_HOPS_LIST_SUCCESS: "FETCH_HOPS_LIST_SUCCESS",
  FETCH_TRIP_BYID: "FETCH_TRIP_BYID",
  FETCH_TRIP_BYID_SUCCESS: "FETCH_TRIP_BYID_SUCCESS",
  TRACK_LOCATION_BYTRIPID_SUCCESS: "TRACK_LOCATION_BYTRIPID_SUCCESS",
  TRACK_LOCATION_BYTRIPID: "TRACK_LOCATION_BYTRIPID",
  TRACK_TRIP_BYID_SUCCESS: "TRACK_TRIP_BYID_SUCCESS",
  TRACK_TRIP_BYID: "TRACK_TRIP_BYID",
  FETCH_TRIPS_SUCCESS: "FETCH_TRIPS_SUCCESS",
  FETCH_FILTER_TRIPS_SUCCESS: "FETCH_FILTER_TRIPS_SUCCESS",
  CREATE_TRIP: "CREATE_TRIP",
  CREATE_TRIP_PRICOL: "CREATE_TRIP_PRICOL",
  CREATE_TRIP_PRICOL_SUCCESS: "CREATE_TRIP_PRICOL_SUCCESS",
  EDIT_TRIP: "EDIT_TRIP",
  EDIT_TRIP_SUCCESS: "EDIT_TRIP_SUCCESS",
  CREATE_TRIP_SUCCESS: "CREATE_TRIP_SUCCESS",
  CHANGE_TRIP_STATUS: "CHANGE_TRIP_STATUS",
  CHANGE_TRIP_STATUS_SUCCESS: "CHANGE_TRIP_STATUS_SUCCESS",
  // FETCH_POSTS: 'FETCH_POSTS',
  // FETCH_POSTS_SUCCESS: 'FETCH_POSTS_SUCCESS',
  // CREATE_POST: 'CREATE_POST',z
  // CREATE_POST_SUCCESS: 'CREATE_POST_SUCCESS',
  DELETE_TRIP: "DELETE_TRIP",
  DELETE_TRIP_SUCCESS: "DELETE_TRIP_SUCCESS",
  START_LOADING: "START_LOADING",
  START_LOADING_SUCCESS: "START_LOADING_SUCCESS",
  START_LOADING_ERROR: "START_LOADING_ERROR",
  CREATE_REPORTINCIDENT: "CREATE_REPORTINCIDENT",
  CREATE_REPORTINCIDENT_SUCCESS: "CREATE_REPORTINCIDENT_SUCCESS",
  UPDATE_DRIVER: "UPDATE_DRIVER",
  UPDATE_DRIVER_SUCCESS: "UPDATE_DRIVER_SUCCESS",

  FINISH_LOADING: "FINISH_LOADING",
  FINISH_LOADING_SUCCESS: "FINISH_LOADING_SUCCESS",
  FINISH_LOADING_ERROR: "FINISH_LOADING_ERROR",

  SHIPMENT_VERIFY: "SHIPMENT_VERIFY",
  SHIPMENT_VERIFY_SUCCESS: "SHIPMENT_VERIFY_SUCCESS",
  FETCH_LOCATION_BY_ID: "FETCH_LOCATION_BY_ID",
  FETCH_LOCATION_BY_ID_SUCCESS: "FETCH_LOCATION_BY_ID_SUCCESS",
  FETCH_TRIPS_BILLS: "FETCH_TRIPS_BILLS",
  FETCH_TRIPS_BILLS_SUCCESS: "FETCH_TRIPS_BILLS_SUCCESS",
  CANCEL_TRIP: "CANCEL_TRIP",
  CANCEL_TRIP_SUCCESS: "CANCEL_TRIP_SUCCESS",
  FETCH_TRANSPORTER_TRIPS: "FETCH_TRANSPORTER_TRIPS",
  UPDATE_TRIP_DETAILS: "UPDATE_TRIP_DETAILS",
  UPDATE_TRIP_DETAILS_SUCCESS: "UPDATE_TRIP_DETAILS_SUCCESS",
  FETCH_TRIPS_EXCEL: "FETCH_TRIPS_EXCEL",
  FETCH_TRIPS_EXCEL_SUCCESS: "FETCH_TRIPS_EXCEL_SUCCESS",

  CHANGE_ARRIVEDATSOURCE_STATUS: "CHANGE_ARRIVEDATSOURCE_STATUS",
  CHANGE_ARRIVEDATSOURCE_STATUS_SUCCESS:
    "CHANGE_ARRIVEDATSOURCE_STATUS_SUCCESS",
  FETCH_TRIP_TOWER: "FETCH_TRIP_TOWER",
  FETCH_TRIP_TOWER_SUCCESS: "FETCH_TRIP_TOWER_SUCCESS",

  SKIP_VIA_POINT: "SKIP_VIA_POINT",
  SKIP_VIA_POINT_SUCCESS: "SKIP_VIA_POINT_SUCCESS",
  TRIP_QUALITY_CHECK: "TRIP_QUALITY_CHECK",
  TRIP_QUALITY_CHECK_SUCCESS: "TRIP_QUALITY_CHECK_SUCCESS",
  SPEC_FETCHSIGNEDURL_QUALITYCHECK: "SPEC_FETCHSIGNEDURL_QUALITYCHECK",
  SPEC_FETCHSIGNEDURL_QUALITYCHECK_SUCCESS:
    "SPEC_FETCHSIGNEDURL_QUALITYCHECK_SUCCESS",

  FETCH_LR_COPY: "FETCH_LR_COPY",
  FETCH_LR_COPY_SUCCESS: "FETCH_LR_COPY_SUCCESS",

  START_UNLOADING: "START_UNLOADING",
  START_UNLOADING_SUCCESS: "START_UNLOADING_SUCCESS",

  FETCH_SIGNED_URL_LR_FRONT: "FETCH_SIGNED_URL_LR_FRONT",
  FETCH_SIGNED_URL_LR_FRONT_SUCCESS: "FETCH_SIGNED_URL_LR_FRONT_SUCCESS",
  FETCH_SIGNED_URL_LR_BACK: "FETCH_SIGNED_URL_LR_BACK",
  FETCH_SIGNED_URL_LR_BACK_SUCCESS: "FETCH_SIGNED_URL_LR_BACK_SUCCESS",
  FETCH_SIGNED_URL_UNLOAD_RECEIPTS: "FETCH_SIGNED_URL_UNLOAD_RECEIPTS",
  FETCH_SIGNED_URL_UNLOAD_RECEIPTS_SUCCESS:
    "FETCH_SIGNED_URL_UNLOAD_RECEIPTS_SUCCESS",
  FETCH_SHIPMENT_TRIP_BYID: "FETCH_SHIPMENT_TRIP_BYID",
  FETCH_SHIPMENT_TRIP_BYID_SUCCESS: "FETCH_SHIPMENT_TRIP_BYID_SUCCESS",
  FETCH_SIGNED_URL_SHIPMENT_DAMAGED: "FETCH_SIGNED_URL_SHIPMENT_DAMAGED",
  FETCH_SIGNED_URL_SHIPMENT_DAMAGED_SUCCESS:
    "FETCH_SIGNED_URL_SHIPMENT_DAMAGED_SUCCESS",
  FETCH_SIGNED_URL_SHIMENT_SHORTAGE: "FETCH_SIGNED_URL_SHIMENT_SHORTAGE",
  FETCH_SIGNED_URL_SHIMENT_SHORTAGE_SUCCESS:
    "FETCH_SIGNED_URL_SHIMENT_SHORTAGE_SUCCESS",
  TRIP_EPOD: "TRIP_EPOD",
  TRIP_EPOD_SUCCESS: "TRIP_EPOD_SUCCESS",
};

//   export
// export const FETCH_TRIPS = "FETCH_TRIPS";
