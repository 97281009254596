import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import TripCountStatusReducer from './reducer';
const TripCountStatus = combineReducers({
  tripCountStatus: TripCountStatusReducer,
  form: formReducer
});


export default TripCountStatus;
