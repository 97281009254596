import React, { Component } from "react";
import { Container, Card, CardBody, Row, Col, Button } from "reactstrap";
import { Grid } from "@material-ui/core";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export default class ShipToDetails extends Component {
  constructor(props) {
    super();
    this.state = {
    
    };
  }

  
 
  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let darkState = this.props.darkTheme;
    const { currentInvoice } = this.props;
    const rowsTopSpaceStyle = { marginTop: "0px" };
    const dataBoxStyle = {
      height: "100%",
      overflowY: "scroll",
      overflowX: "clip",
      padding: "0%",
    };
    const oddRowStyle = {
      minHeight: "27px",
      width: "101%",
      margin: "auto",
      marginTop: "4px",
      marginRight: "0px",
    };
    const evenRowStyle = {
      minHeight: "27px",
      width: "101%",
      margin: "auto",
      marginTop: "4px",
      marginRight: "0px",
    };

    const rowHeaderStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "15px",
      color: darkState ? "#9D9D9D" : "#05284E",
      lineHeight: "18px",
      margin: "auto",
      paddingLeft: "1.5vw",
    };
    const rowSeparaterStyle = {
      borderLeft: "0.5px solid rgba(185, 185, 185, .5)",
      height: "172px",
      float: "left",
    };
    const rowSeparaterStyle1 = {
      borderLeft: "0.5px solid rgba(185, 185, 185, .5)",
      height: "355px",
      marginTop: "53px",
      float: "left",
    };
    const rowValueStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "13px",
      color: darkState ? "#9D9D9D" : "#0C0C0C",
      margin: "auto",
      flex: "wrap",
      alignContent: "flex-start",
    };

    return (
      <div>
        <Grid className="mt-4" container style={oddRowStyle}>
          <Grid
            // className=""
            item
            xs={3}
            md={4}
            style={rowHeaderStyle}
            className="MobilefontRoboto127"
          >
            <span>
              Ship to Name <span className="text-bold">:</span>
            </span>
          </Grid>

          <Grid
            item
            xs={5}
            md={8}
            style={rowValueStyle}
            className="MobilefontRoboto124"
          >
            {currentInvoice?.orders?.shipToDetails?.shipToName}
          </Grid>
        </Grid>

        <Grid className="" container style={evenRowStyle}>
          <Grid
            item
            xs={3}
            md={4}
            style={rowHeaderStyle}
            className="MobilefontRoboto127"
          >
            <span>
              Ship to Address 1 <span className="text-bold">:</span>
            </span>
          </Grid>

          <Grid
            item
            xs={5}
            md={8}
            style={rowValueStyle}
            className="MobilefontRoboto124"
          >
            {currentInvoice?.orders?.shipToDetails?.shipToAddress1}
          </Grid>
        </Grid>

        <Grid className=" " container style={oddRowStyle}>
          <Grid
            item
            xs={3}
            md={4}
            style={rowHeaderStyle}
            className="MobilefontRoboto127"
          >
            <span>
              {" "}
              Ship to Address 2 <span className="text-bold">:</span>
            </span>
          </Grid>

          <Grid
            item
            xs={5}
            md={8}
            style={rowValueStyle}
            className="MobilefontRoboto124"
          >
            {currentInvoice?.orders?.shipToDetails?.shipToAddress2}
          </Grid>
        </Grid>
        <Grid className=" " container style={evenRowStyle}>
          <Grid
            item
            xs={3}
            md={4}
            style={rowHeaderStyle}
            className="MobilefontRoboto127"
          >
            <span>
              {" "}
              Ship to Address 3 <span className="text-bold">:</span>
            </span>
          </Grid>

          <Grid
            item
            xs={5}
            md={8}
            style={rowValueStyle}
            className="MobilefontRoboto124"
          >
            {currentInvoice?.orders?.shipToDetails?.shipToAddress3}
          </Grid>
        </Grid>
        <Grid className=" " container style={oddRowStyle}>
          <Grid
            item
            xs={3}
            md={4}
            style={rowHeaderStyle}
            className="MobilefontRoboto127"
          >
            <span>
              {" "}
              Ship to Address 4 <span className="text-bold">:</span>
            </span>
          </Grid>

          <Grid
            item
            xs={5}
            md={8}
            style={rowValueStyle}
            className="MobilefontRoboto124"
          >
            {currentInvoice?.orders?.shipToDetails?.shipToAddress4}
          </Grid>
        </Grid>

        <Grid className="mt-4" container style={oddRowStyle}>
          <Grid
            // className=""
            item
            xs={3}
            md={4}
            style={rowHeaderStyle}
            className="MobilefontRoboto127"
          >
            <span>
              Ship to City <span className="text-bold">:</span>
            </span>
          </Grid>

          <Grid
            item
            xs={5}
            md={8}
            style={rowValueStyle}
            className="MobilefontRoboto124"
          >
            {currentInvoice?.orders?.shipToDetails?.shipToCity}
          </Grid>
        </Grid>

        <Grid className="" container style={evenRowStyle}>
          <Grid
            item
            xs={3}
            md={4}
            style={rowHeaderStyle}
            className="MobilefontRoboto127"
          >
            <span>
              Ship to Destination <span className="text-bold">:</span>
            </span>
          </Grid>

          <Grid
            item
            xs={5}
            md={8}
            style={rowValueStyle}
            className="MobilefontRoboto124"
          >
            {currentInvoice?.orders?.shipToDetails?.shipToDestination}
          </Grid>
        </Grid>

        <Grid className=" " container style={oddRowStyle}>
          <Grid
            item
            xs={3}
            md={4}
            style={rowHeaderStyle}
            className="MobilefontRoboto127"
          >
            <span>
              {" "}
              Ship to Zip <span className="text-bold">:</span>
            </span>
          </Grid>

          <Grid
            item
            xs={5}
            md={8}
            style={rowValueStyle}
            className="MobilefontRoboto124"
          >
            {currentInvoice?.orders?.shipToDetails?.shipToZip}
          </Grid>
        </Grid>
        <Grid className=" " container style={evenRowStyle}>
          <Grid
            item
            xs={3}
            md={4}
            style={rowHeaderStyle}
            className="MobilefontRoboto127"
          >
            <span>
              {" "}
              Ship to Country <span className="text-bold">:</span>
            </span>
          </Grid>

          <Grid
            item
            xs={5}
            md={8}
            style={rowValueStyle}
            className="MobilefontRoboto124"
          >
            {currentInvoice?.orders?.shipToDetails?.shipToCountry}
          </Grid>
        </Grid>
        <Grid className=" " container style={oddRowStyle}>
          <Grid
            item
            xs={3}
            md={4}
            style={rowHeaderStyle}
            className="MobilefontRoboto127"
          >
            <span>
              {" "}
              Ship to GSTN No <span className="text-bold">:</span>
            </span>
          </Grid>

          <Grid
            item
            xs={5}
            md={8}
            style={rowValueStyle}
            className="MobilefontRoboto124"
          >
            {currentInvoice?.orders?.shipToDetails?.shipToGSTNNo}
          </Grid>
        </Grid>
        <Grid className=" " container style={oddRowStyle}>
          <Grid
            item
            xs={3}
            md={4}
            style={rowHeaderStyle}
            className="MobilefontRoboto127"
          >
            <span>
              {" "}
              Ship to State Name <span className="text-bold">:</span>
            </span>
          </Grid>

          <Grid
            item
            xs={5}
            md={8}
            style={rowValueStyle}
            className="MobilefontRoboto124"
          >
            {currentInvoice?.orders?.shipToDetails?.shipToStateName}
          </Grid>
        </Grid>
      </div>
    );
  }
}
