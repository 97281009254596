import React from "react";
import { Row, Col, Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import DataTable from "../Utility/dataTable";
import Index from "../../components/Common/DataTableNeo/Index";

function TripCreationModal(props) {
  // console.log("PROPS-HEAD", props?.selectedDataHead);
  // console.log("PROPS-DATA", props?.SelectedDataFinal);
  return (
    <div /* className="page-content" */>
      <Modal
        isOpen={props.modalView}
        toggle={props.modalViewToggle}
        size="lg"
        centered
        className="CreateTripModal"
        style={{ maxWidth: "68%" }}
      >
        <ModalHeader>
          <div className="Heading">
            <h4 className="fontRoboto"> Confirm Trip Creation</h4>
            <svg
              onClick={() => props.modalViewToggle()}
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.9092 1.82141L1.90918 13.8214"
                stroke="#2800FC"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1.90918 1.82141L13.9092 13.8214"
                stroke="#2800FC"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </ModalHeader>

        <ModalBody>
          <Index
            {...props}
            headers={props?.selectedDataHead}
            data={props?.SelectedDataFinal}
            dataLength={90}
            rowHeight={"60px"}
          />
          <div>
            <span className="float-right">
              <span className="mr-2">
                {" "}
                <Button
                  className="fontRoboto17"
                  style={{ backgroundColor: "#0AB38C", color: "white" }}
                  disabled={!props?.modalView}
                  onClick={props.createTrip}
                  size="md"
                >
                  Confirm
                </Button>
              </span>
              <span className="ml-2">
                <Button
                  className="fontRoboto17"
                  style={{ backgroundColor: "#E53D2C", color: "white" }}
                  onClick={() => props.modalViewToggle()}
                >
                  Cancel
                </Button>
              </span>
            </span>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default TripCreationModal;
