import React, { Component } from 'react'
import { Card, Col, Row } from 'reactstrap'
import { Grid } from 'rsuite'
import TransportDonutChart from './TransportDonutChart'

export default class TransportCardDetails extends Component {
    constructor(props){
        super(props)
        this.state = {}
    }
  render() {
    return (
        <>
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          className="mt-1 pd18"
        >
          <Row  xs={12} md={12} className="pl-1 pr-1">
                <Col item xs={6} className="">
                  <Card className=" card-transMode-dountchart shadow darkdountchart">
                    <TransportDonutChart
                      tripInformation={this.props.tripInformation}
                    />
                  </Card>
                </Col>
          </Row>
        </Grid>
      </>
    )
  }
}
