export const types = {
    FETCH_REPORTS: "FETCH_REPORTS",
    FETCH_REPORTS_SUCCESS: "FETCH_REPORTS_SUCCESS",

    
    FETCH_INDENTLANE_REPORTS: "FETCH_INDENTLANE_REPORTS",
    FETCH_INDENTLANE_REPORTS_SUCCESS: "FETCH_INDENTLANE_REPORTS_SUCCESS",

    FETCH_INDENTSTATUS_REPORTS:"FETCH_INDENTSTATUS_REPORTS",
    FETCH_INDENTSTATUS_REPORTS_SUCCESS: "FETCH_INDENTSTATUS_REPORTS_SUCCESS",
    
    FETCH_INDENTTRANSPORTER_REPORTS: "FETCH_INDENTTRANSPORTER_REPORTS",
    FETCH_INDENTTRANSPORTER_REPORTS_SUCCESS: "FETCH_INDENTTRANSPORTER_REPORTS_SUCCESS",

    FETCH_TRANSPORTER_PERFORMANCE : "FETCH_TRANSPOTER_PERFORMANCE",
    FETCH_TRANSPORTER_PERFORMANCE_SUCCESS : "FETCH_TRANSPOTER_PERFORMANCE_SUCCESS",
    FETCH_SHARE_OF_BUSINESS:"FETCH_SHARE_OF_BUSINESS",
    FETCH_SHARE_OF_BUSINESS_SUCCESS:"FETCH_SHARE_OF_BUSINESS_SUCCESS"
}