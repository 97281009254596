
import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  Form,
} from "reactstrap";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
class InvoiceCancelledModal extends Component {
  render() {
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    var darkMode = mode === "dark__mode" ? true : false;
    return (
      <Modal
        isOpen={this.props.cancelModal}
        // autoFocus={true}
        centered={true}
        toggle={this.props.cancelTogglemodal}
        className="addZoneToState"
      //  size={"xl"}
      >
        <div className="modal-content p-1">
          <ModalHeader>
            <div className="Heading">
              <h4 className="fontRoboto light__span">
                {userLang?.invoice?.CANCEL_INVOICE ?? "Cancel Invoice"}

              </h4>
              <svg
                onClick={this.props.cancelTogglemodal}
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.9092 1.82141L1.90918 13.8214"
                  stroke="#2800FC"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.90918 1.82141L13.9092 13.8214"
                  stroke="#2800FC"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </ModalHeader>

          <ModalBody>
            <Form>
              <FormGroup>
                <Label
                  className="required-field labelStyle light__span mt-2"
                >
                  {" "}
                  <span> {userLang?.common?.REMARK ?? "Remark"}</span>
                </Label>
                <Input
                  type="textarea"
                  className="form-control"
                  placeholder="Remark..."
                  value={this.props.remark}
                  onChange={(e) =>
                    this.props.onChangeInput("remark", e.target.value)
                  }
                />

              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="success"
              onClick={() => this.props.reset()}
            >
              {userLang?.common?.RESET ?? "RESET"}
            </Button>
            <Button
              type="button"
              color="danger"
              onClick={this.props.handleSubmit}
              disabled={!this.props.remark}
            >
              {userLang?.common?.CANCEL ?? "CANCEL"}
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    );
  }
}
export default InvoiceCancelledModal