import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import DriversReducer from './reducer';
const Driver = combineReducers({
  drivers: DriversReducer,
  form: formReducer
});


export default Driver;
