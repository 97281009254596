import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  CHECK_LOGIN,
  LOGOUT_USER,
  CHECK_LOAD,
  GET_USER,
  LOGOUT_USER_SUCCESS,
  AD_LOGIN,
  // AD_LOGIN_SUCCESS,
  FETCH_ALL_NOTIFICATION_DATA,
  // FETCH_ALL_NOTIFICATION_DATA_SUCCESS,
  UPDATE_ALL_NOTIFICATION_DATA,
  // UPDATE_ALL_NOTIFICATION_DATA_SUCCESS,
  UPDATE_USER_PASSWORD,
  UPDATE_USER_DATA,
  // UPDATE_USER_PASSWORD_SUCCESS,
} from "./actionTypes";
import { apiError, loginUserSuccessful, loadUserSuccess } from "./actions";

// AUTH related methods
import {
  postLogin,
  getUser,
  getApi,
  postADLogin,
  postApi,
  patchApi,
  putApi,
} from "../../../helpers/auth_helper";

import jwt from "jwt-decode";
import { getPolicies } from "../../../pages/policies";
import { types } from "../../../store/accessPolicy/actionTypes";
import locationSaga from "../../baxter_location/saga";

//If user is login then dispatch redux action's are directly from here.

function* loginUser({ payload: { user, history }, callback }) {
  try {
    // console.log(user, "here saga");
    window.localStorage.setItem("userId", user?.parentCode);
    const response = yield call(
      postLogin,
      process.env.REACT_APP_SERVERURL + "/auth/login",
      user
    );
    callback(response);
    if (response.success === false) throw response;
    localStorage.setItem("token", response.data.token);
    localStorage.setItem("refreshToken", response.data?.refreshToken);
    localStorage.setItem("typeSelection", "Primary");
    const user1 = jwt(response.data.token);

    const actualUser = yield call(
      getApi,
      process.env.REACT_APP_SERVERURL + `/user/user/${user1.userId}`
    );
    localStorage.setItem(
      "authUser",
      JSON.stringify({ data: actualUser.data, token: response.data.token })
    );
    if (process.env.REACT_APP_CLIENT != "3_BAXTER") {
      localStorage.setItem("uname", JSON.stringify(actualUser?.data?.name));
      let lastsync = yield call(
        getApi,
        process.env.REACT_APP_SERVERURL + `/Param/lastSyncTime`
      );

      localStorage.setItem("lastSyncTime", JSON.stringify({ ...lastsync }));

      yield put(loginUserSuccessful(actualUser));
      history.push("/operationsDashboard");
    }
    if (process.env.REACT_APP_CLIENT == "3_BAXTER") {
      const getPath = sessionStorage.getItem("viewOrderPath");
      getPath ? (window.location.href = getPath) : history.push("/ordersList");
    } else {
      history.push("/operationsDashboard");
    }
  } catch (error) {
    // console.log(error, "error");
    if (error) {
      localStorage.removeItem("authUser");
    }
    yield put(apiError(error.message));
  }
}

function* ADloginUser({ payload: { token, history }, callback }) {
  try {
    const response = yield call(
      postADLogin,
      process.env.REACT_APP_SERVERURL + "/auth/adlogin",
      token
    );
    callback(response);
    localStorage.setItem("token", response.data.token);
    const user1 = jwt(response.data.token);
    // console.log("ga", user1);
    const actualUser = yield call(
      getApi,
      process.env.REACT_APP_SERVERURL + `/user/user/${user1.userId}`
    );
    // console.log(actualUser, "here saga");

    localStorage.setItem(
      "authUser",
      JSON.stringify({ ...actualUser, token: response.data.token })
    );
    localStorage.setItem("uname", JSON.stringify(actualUser.data.name));
    localStorage.setItem("role", actualUser.data);
    let lastsync = yield call(
      getApi,
      process.env.REACT_APP_SERVERURL + `/Param/lastSyncTime`
    );
    localStorage.setItem("lastSyncTime", JSON.stringify({ ...lastsync }));

    yield put(loginUserSuccessful(actualUser));
    history.push("/operationsDashboard");
  } catch (error) {
    // console.log(error, "error");

    const isLogin = JSON.parse(localStorage.getItem("authUser"));
    if (!isLogin?.success) {
      localStorage.removeItem("authUser");
    }
    yield put(apiError(error));
  }
}

//Baxter API user update
function* workUpdateUserData({ Id, data, callback }) {
  // console.log("SAGA DATA", data);
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL + `/user/updateUser?` + `userId=${Id}`;
    const response = yield call(putApi, uri, data);
    const authUserData = JSON.parse(localStorage.getItem("authUser"));
    if (authUserData) {
      authUserData.data.name = data.name;
      authUserData.data.phone = data.phone;
      authUserData.data.whatsAppNo = data.whatsAppNo;
      authUserData.data.email = data.email;
      localStorage.setItem("authUser", JSON.stringify(authUserData));
    }
    callback(response);
    // console.log(response);
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not update password.", error);
  }
}

function* workLoadUser(payload) {
  // console.log(payload.payload.token,"saga")
  try {
    // console.log(payload.payload.token,"saga2")
    const user1 = jwt(payload.payload.token);

    // console.log(user1,"saga3")
    const actualUser = yield call(
      getUser,
      process.env.REACT_APP_SERVERURL + "/user/user",
      user1.userId
    );
    const uri = process.env.REACT_APP_SERVERURL + `/role/getRGPFrmToken`;
    var res = yield call(getApi, uri);
    let policiesList = getPolicies(res);
    yield put({
      type: types.FETCH_ROLE_GRP_POLICIES_SUCCESS,
      payload: policiesList,
    });
    yield put(loadUserSuccess(actualUser));
  } catch (error) {
    // var message =
    //   "Sorry! something went wrong, please contact our support team";
    yield put(apiError(error));
  }
}

function* workGetUser({ offset, callback }) {
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/user/List?offset=` +
      offset +
      `&limit=100`;
    const response = yield call(getApi, uri);
    callback(response);
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not fetch users.", error);
  }
}

function* workFetchAllNotificationData({ data, callback }) {
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL + `/userNotification/search/` + data;
    const response = yield call(getApi, uri);
    callback(response);
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not fetch notification data.", error);
  }
}

function* workUpdateAllNotificationData({ data, callback }) {
  // console.log("SAGA DATA", data);
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/userNotification/update`;
    const response = yield call(postApi, uri, data);
    callback(response);
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not update notification data.", error);
  }
}

function* workUpdateUserPassword({ data, callback }) {
  // console.log("SAGA DATA", data);
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/auth/updatePassword`;
    const response = yield call(patchApi, uri, data);
    callback(response);
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not update password.", error);
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("lastSyncTime");
    localStorage.removeItem("token");
    localStorage.removeItem("authUser");
    localStorage.removeItem("uname");
    localStorage.removeItem("userPreferredLang");
    localStorage.removeItem("userLangDetail");
    localStorage.removeItem("operationDashboardData");
    localStorage.removeItem("transporterDashboardData");
    localStorage.removeItem("transportHistory");
    localStorage.removeItem("reportData");
    localStorage.removeItem("transporterPerformanceData");
    localStorage.removeItem("invoiceDashboardData");
    localStorage.removeItem("IndentData");
    localStorage.removeItem("TripData");
    localStorage.removeItem("driversListData");
    localStorage.removeItem("vehiclesListData");
    localStorage.removeItem("usersDataList");
    localStorage.removeItem("rolesListData");
    localStorage.removeItem("agreementData");
    localStorage.removeItem("invoiceData");
    localStorage.removeItem("indentLaneData");
    localStorage.removeItem("indentStatusData");
    localStorage.removeItem("indentTransporterData");
    localStorage.removeItem("online");
    localStorage.removeItem("remember");
    localStorage.removeItem("refreshToken");
    localStorage.clear();

    window.location = "/login";
    yield put({
      type: LOGOUT_USER_SUCCESS,
    });
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(CHECK_LOGIN, loginUser);
}
export function* watchUserLoad() {
  yield takeEvery(CHECK_LOAD, workLoadUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}
export function* watchGetUsers() {
  yield takeEvery(GET_USER, workGetUser);
}
export function* watchFetchAllNotificationData() {
  yield takeEvery(FETCH_ALL_NOTIFICATION_DATA, workFetchAllNotificationData);
}
export function* watchUpdateAllNotificationData() {
  yield takeEvery(UPDATE_ALL_NOTIFICATION_DATA, workUpdateAllNotificationData);
}
export function* watchUpdateUserPassword() {
  yield takeEvery(UPDATE_USER_PASSWORD, workUpdateUserPassword);
}
export function* watchADloginUser() {
  yield takeEvery(AD_LOGIN, ADloginUser);
}

export function* watchUpdateUserData() {
  yield takeEvery(UPDATE_USER_DATA, workUpdateUserData);
}

function* loginSaga() {
  yield all([
    fork(watchUserLogin),
    fork(watchUserLoad),
    fork(watchUserLogout),
    fork(watchGetUsers),
    fork(watchADloginUser),
    fork(watchFetchAllNotificationData),
    fork(watchUpdateAllNotificationData),
    fork(watchUpdateUserPassword),
    fork(watchUpdateUserData),
  ]);
}

export default loginSaga;
