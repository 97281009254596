import React, { Component } from "react";
//import from libraries
import { Row, Col, Card, Button, InputGroup, Input } from "reactstrap";
// components
import refreshIcon from "./assets/refresh-cw.png";

import Datepicker from "../../components/Common/DatePicker/DatePicker";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
var darkMode;

class QuickFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      // searchBar: [],
      searchFilters: {},
      userLangs: undefined,
      dateValue: [null, null],
      showCalender: false,
      fromDate: null,
      toDate: null,
    };
  }

  componentDidUpdate() {
    if (
      this.state.userLangs === undefined &&
      (this.state.userLangs !== userLang || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  }
  onChangeDatePicker = (value, label) => {
    // console.log(value, label);
    // debugger
    if (label === "fromDate") {
      this.setState({ fromDate: value }, () => {
        if (this.state.fromDate !== null && this.state.toDate !== null) {
          this.props.handleChange([this.state.fromDate, this.state.toDate]);
        }
      });
    }
    if (label === "toDate") {
      this.setState({ toDate: value }, () => {
        if (this.state.fromDate !== null && this.state.toDate !== null) {
          this.props.handleChange([this.state.fromDate, this.state.toDate]);
        }
      });
    }
  };
  render() {
    darkMode = this.props?.mode;

    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    return (
      <React.Fragment>
        <Card className="darkBgQuickFilter ">
          <div
            className="quickStylesBar reportQuickStyleBar LogBook"
            style={
              {
                // width: "105vw",
              }
            }
          >
            {" "}
            <div>
              <div className="dateickerGrid">
                <div
                  className="date-picker-div RsuiteDateRange OrderDateRange"
                  style={{
                    width: "70%",
                    marginLeft: "90px",
                  }}
                >
                  <div className="datepicker-selector orderDateContainer">
                    <InputGroup>
                      <Datepicker
                        mode={darkMode}
                        value={this.state.fromDate}
                        onChangeDatePicker={this.onChangeDatePicker}
                        fromDate={true}
                        toDate={false}
                        myPlaceholder={"Start Date"}
                        dashboard={false}
                      />
                    </InputGroup>
                    <InputGroup>
                      <Datepicker
                        mode={darkMode}
                        value={this.state.toDate}
                        onChangeDatePicker={this.onChangeDatePicker}
                        fromDate={false}
                        toDate={true}
                        myPlaceholder={"End Date"}
                        dashboard={false}
                        className="orderDateIcon"
                      />
                    </InputGroup>
                  </div>
                </div>
              </div>{" "}
            </div>
            <div>
              <Button
                className="desktop-reset reset"
                onClick={() => {
                  this.setState(
                    {
                      dateValue: [null, null],
                      dispatchDateValue: [null, null],
                      fromDate: null,
                      toDate: null,
                      dispatchFromDate: null,
                      dispatchToDate: null,
                    },
                    () => {
                      this.props.reset();
                    }
                  );
                }}
              >
                {this.state.userLangs?.common?.RESET}
              </Button>
              <Button
                className="mobile-reset reset"
                onClick={() =>
                  this.setState(
                    {
                      dateValue: [null, null],
                      dispatchDateValue: [null, null],
                      fromDate: null,
                      toDate: null,
                      dispatchFromDate: null,
                      dispatchToDate: null,
                    },
                    () => {
                      this.props.reset();
                    }
                  )
                }
              >
                <img className="mobile-rest-img" src={refreshIcon} alt=""></img>
              </Button>
            </div>
            <div className="tooltipIcon">
              <Button
                disabled={this.props.searchFilters?.length <= 0 ? true : false}
                className="logbookButton"
                onClick={() => this.props.uploadS3Data(this.props.excelUrl)}
              >
                Create Logbook Report
              </Button>
              <span
                className="tooltipIcontext"
                style={{
                  left: "10%",
                }}
              >
                {" "}
                &nbsp; Select date range&nbsp;
              </span>
            </div>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default QuickFilters;
