import { types } from "../actionTypes";
const initialState = {
    loadOptimizationData: [],
};
export default (state = { initialState }, action) => {
  switch (action.type) {
    case types.LOAD_OPTIMIZATION_DATA_SUCCESS:
      return { ...state, loadOptimizationData: action.payload };

    default:
      state = { ...state };
      break;
  }

  return state;
};
