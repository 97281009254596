import React, { Component } from "react";
import { Row, Col, Container } from "reactstrap";
import { NotificationManager } from "react-notifications";
import { fetchIndents } from "../../store/actions";
import { actions } from "./actionButtons";
import Index from "../../components/Common/DataTableNeo/Index";
import {
  AdminHeaderData,
  searchBar,
  getLocalStorage,
} from "./RowHeaders/RowHeaders.js";
import { fetchDrivers, fetchDriversExcel , uploadImagesAws , createDriverViaExcel , fetchDriverPresignedUrl } from "../../store/driver/actions";
import { connect } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import { restrict } from "../restrict";
import { PERMS } from "../../enum/perms.enum";
import {
  subscribeConsent,
  unsubscribeConsent,
} from "../../store/driver/actions";
import Popup from "../../components/Common/Popup";
import DriverForm from "../Forms/DriverForm";
import QuickFilters from "./quckFilters";
import { exportToCSV } from "../common/exportToCsv";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import GetAppIcon from "@material-ui/icons/GetApp";
import * as XLSX from 'xlsx';


var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var darkTheme;
// let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;
class Driver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dataLoading: false,
      breadcrumbItems: [
        { title: "Driver", link: "#" },
        { title: "Driver Details", link: "#" },
      ],
      offset: 0,
      name: "",
      phoneNo: "",
      setOpenPopup: false,
      isOpen: false,
      excelUrl: "",
    };
    this.fileInput = React.createRef();
    if (process.env.REACT_APP_CLIENT != "3_BAXTER") {
      (async () => {
        let redirect = await restrict(PERMS.DRIVER_TRANSPORTER_READ);

        if (redirect === false) {
          this.props.history.push("/operationsDashboard");
        }
      })();
    }
  
  }

  reset = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
    this.setState(
      {
        name: "",
        phoneNo: "",
        loading: true,
      },

      () => {
        offset = 0;
        this.setState({ loading: true });
        // this.props.fetchDrivers(offset, this.state.name, this.state.phoneNo);
        this.getAllData();
      }
    );
  };

  getAllData = () => {
    this.setState({ dataLoading: true }, () => {});
    this.props.fetchDrivers(
      offset,
      this.state.name,
      this.state.phoneNo,
      null,
      () => {
        this.setState({ dataLoading: false });
      }
    );
  };

  getFilterData = () => {
    this.setState({ dataLoading: true }, () => {});
    offset = 0;
    this.props.fetchDrivers(
      offset,
      this.state.name,
      this.state.phoneNo,
      null,
      () => {
        this.setState({ dataLoading: false });
      }
    );
  };
  enterCheck = (event) => {
    if (event.key === "Enter") {
      this.getFilterData();
    }
  };
  filterList = () => {
    this.getFilterData();
  };

  handleDriverName = (e) => {
    this.setState({ name: e.target.value }, () => {});
  };
  handleDriverPhone = (e) => {
    if (rx_live.test(e.target.value)) {
      this.setState({ phoneNo: e.target.value }, () => {});
    }
  };
  // getDriverPhoneFunc() {
  //   let phoneNo = this.state.phoneNo;
  //   let name = this.state.name;
  //   let offset = 0;
  //   this.props.fetchDrivers(offset, name, phoneNo);
  // }
  // getDriverNameFunc() {
  //   let name = this.state.name;
  //   let phoneNo = this.state.phoneNo;
  //   let offset = 0;
  //   this.props.fetchDrivers(offset, name, phoneNo);
  // }
  // getData() {
  //   let name = this.state.name;
  //   let phoneNo = this.state.phoneNo;
  //   let offset = 0;
  //   this.props.fetchDrivers(offset, name, phoneNo);
  // }
  componentDidMount = () => {
    offset = 0;
    this.setState({ loading: true });
    this.getAllData();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 0.9;
  }
  componentDidUpdate(prevProps) {}
  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
  };

  setOpenPopupfalse = (props) => {
    this.setState({ setOpenPopup: props });
  };

  handleSubmit = (props) => {
    this.setState({ setOpenPopup: props });
    offset = 0;
    this.setState({ loading: true });
    this.getAllData();
  };
  handleChangePage = (event, val) => {
    // console.log(event, val, "handlepage");
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    this.setState({ loading: true });
    this.getAllData();
  };
  consentUpdate = (ele) => {
    let offset = 0;
    if (!ele.consentProvided) {
      this.props.subscribeConsent(ele.phone, (res) => {
        if (res.success) NotificationManager.success(res["message"], "Success");
        this.setState({ dataLoading: true }, () => {});
        this.props.fetchDrivers(
          offset,
          this.state.name,
          this.state.phoneNo,
          null,
          () => {
            this.setState({ dataLoading: false }, () => {});
          }
        );
      });
    } else {
      this.props.unsubscribeConsent(ele.phone, (res) => {
        this.setState({ dataLoading: true }, () => {});
        if (res.success) NotificationManager.success(res["message"], "Success");
        this.props.fetchDrivers(
          offset,
          this.state.name,
          this.state.phoneNo,
          null,
          () => {
            this.setState({ dataLoading: false }, () => {});
          }
        );
      });
    }
  };
  addDriver = () => {
    this.setState({ setOpenPopup: true });
  };

  exportExcelData = () => {
    this.setState({ dataLoading: true }, () => {});
    // Call the action function using await to ensure proper execution
    this.props.fetchDriversExcel(
      0,
      this.state.name,
      this.state.phoneNo,
      null,
      (res) => {
        this.setState({ dataLoading: false }, () => {});
        // this.setState({ isExportingExcel: false });
        // let exdata=[]
        let exportData = [];
        if (res?.data?.docs?.length > 0) {
          res.data.docs.map((ele) => {
            // console.log("ele", ele);
            exportData.push({
              "Full Name": ele?.name ? ele?.name : "N/A",
              "Phone": ele?.phone ? ele?.phone : "N/A",
              "License Number": ele.licence?.number ? ele.licence?.number : "N/A",
              "Transporter": ele?.transporter?.name
                ? ele?.transporter?.name
                : "N/A",
              // "Transporter Vendor": ele?.transporter ? ele?.transporter : "N/A",
              "Consent Status":ele?.driverConsentStatusFromServiceProvider ? (
                ele?.driverConsentStatusFromServiceProvider
              ) : ele?.driverConsentStatusFromCargoExchange?.length ? 
                ele?.driverConsentStatusFromCargoExchange :"PROCESS NOT INITIATED"
            });
          });
        }
        // console.log("export Data", exportData);
        if (exportData?.length > 0) {
          exportToCSV([...exportData], "Driver");
        } else {
          exportData.push({
            "Full Name": "",
            "Phone": "",
            "LicenseNumber": "",
            "Transporter": "",
            "Consent Provided": "",
          });
          exportToCSV([...exportData], "Driver");
        }
      }
    );
  };

downloadExcelTemplate = () =>{
    let exportHeaders = [
      {
        "FullName":"sampleName",
        "Contact":"1234567891",
        "DriverLicenseNo":"HKL123MKO",
      }
    ];
    exportToCSV([...exportHeaders], "DRIVER_Template");
}

   

  openModal = () => {
    this.setState({
      isOpen: true,
      excelUrl: "",
      selectedFile: {
        ...this.state.selectedFile, 
        name: "",                   
      },
    });
  };
  closeModal = () => {
    this.setState({
      isOpen: false,
      excelUrl: "",
      selectedFile: {
        ...this.state.selectedFile,  
        name: "",                    
      },
    });
  };

  handleSubmitExcel = () => {
    this.props.createDriverViaExcel(
      { excelFileURL: this.state.excelUrl },
      (res) => {
        if (res.success) {
          NotificationManager.success(res["message"], "Success");
            this.getAllData();
          this.closeModal();
        } else {
          NotificationManager.error(res["message"], "Error");
        }
      }
    );
  };

  fileUpload = (event) => {
    this.setState(
      {
        selectedFile: event.target.files[0],
      },
      () => {
        this.S3Upload();
      }
    );
  };

  S3Upload = async () => {
    this.props.fetchDriverPresignedUrl((res) => {
      let softCopy = "";
      softCopy = res.presignedS3Url.split("?")[0];
      this.setState(
        {
          excelUrl: softCopy,
        },
        () => {
          const formData = new FormData();
          formData.append(
            this.state.selectedFile,
            this.state.selectedFile.name
          );
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", this.state.selectedFile.type);
          this.props.uploadImagesAws(
            res.presignedS3Url,
            this.state.selectedFile,
            myHeaders
          );
        }
      );
    });
  };

  fileUploadTrigger = () => {
    this.fileInput.current.click();
  };


  
  render() {
    // console.log(this.state.dataLoading, "lloading");
    let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    darkTheme = mode === "dark__mode" ? true : false;
    // const { name, phoneNo, loading } = this.state;
    const { drivers } = this.props;
    const localStorageData = JSON.parse(
      localStorage.getItem("driversListData")
    );
    const driverData = navigator.onLine
      ? drivers?.drivers?.data?.docs
      : localStorageData?.data?.docs;
    let rows = [];

    if (Array.isArray(driverData)) {
      // console.log("drivers : ", this.props.drivers.drivers.data.docs);
      totCnt = navigator.onLine
        ? drivers?.drivers?.data?.length
        : localStorageData?.data?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = navigator.onLine
        ? drivers?.drivers?.data?.offset + drivers?.drivers?.data?.docs?.length
        : localStorageData?.data?.offset + localStorageData?.data?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      driverData.forEach((ele) => {
        rows.push({
          fullName: ele?.name ? ele?.name : "N/A",
          phone: ele?.phone ? ele?.phone : "N/A",
          driverLicenseNumber: ele.licence?.number
            ? ele.licence?.number
            : "N/A",
          transporter: ele?.transporter?.name ? ele?.transporter?.name : "N/A",
          transporterVendor: ele?.transporter ? ele?.transporter : "N/A",
          consentProvided: ele?.driverConsentStatusFromServiceProvider ? (
            ele?.driverConsentStatusFromServiceProvider
          ) : ele?.driverConsentStatusFromCargoExchange?.length ? (
            (ele?.driverConsentStatusFromCargoExchange === "ALLOWED" && (
              <span
                style={{
                  color: "green",
                }}
              >
                ALLOWED
              </span>
            )) ||
            (ele?.driverConsentStatusFromCargoExchange === "PENDING" && (
              <span
                style={{
                  color: "blue",
                }}
              >
                PENDING
              </span>
            )) ||
            (ele?.driverConsentStatusFromCargoExchange ===
              "PROCESS INITIATED" && (
              <span
                style={{
                  color: "blue",
                }}
              >
                PROCESS INITIATED
              </span>
            ))
          ) : (
            <span
              style={{
                color: "red",
              }}
            >
              PROCESS NOT INITIATED
            </span>
          ),
          consentUpdate: actions(ele, {
            consentUpdate: this.consentUpdate,
          }),
        });
      });
    }
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let userType = authUser?.data?.role?.label;

    return (
      <React.Fragment>
        <>

        <Modal
          isOpen={this.state.isOpen}
          toggle={this.closeModal}
          size="lg"
          className="productModal"
        >
          <ModalHeader>
            <div className="Heading">
              <h3>Upload Driver Data</h3>
              <svg
                onClick={this.closeModal}
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.9092 1.82141L1.90918 13.8214"
                  stroke="#2800FC"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.90918 1.82141L13.9092 13.8214"
                  stroke="#2800FC"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="imageDiv">
              <div className="d-block">
                <svg
                  onClick={() => this.fileUploadTrigger()}
                  width="150"
                  height="150"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 31.6534C0.0758962 31.2107 0.126494 30.768 0.227689 30.3253C0.809559 27.6309 2.23894 25.5185 4.51582 23.9753C4.65497 23.8741 4.80676 23.7729 4.9712 23.659C4.17429 20.9521 4.57907 18.4855 6.60297 16.4616C8.61422 14.4503 11.0808 13.9949 13.7372 14.8045C17.0893 8.92253 22.0225 6.15232 28.7773 7.22752C33.9256 8.04973 39.1245 12.4517 39.9214 19.3709C40.2376 19.4468 40.5665 19.4974 40.8827 19.5986C44.8167 20.7876 47.511 23.3428 48.8265 27.2009C50.6607 32.5515 48.7633 37.9781 44.0704 41.1531C42.2995 42.3548 40.3262 42.9873 38.1631 42.9873C35.0008 42.9873 31.8511 42.9873 28.6888 42.9873C28.499 42.9873 28.3093 42.9873 28.0942 42.9873C28.0942 39.3949 28.0942 35.8657 28.0942 32.2733C28.2587 32.2606 28.4105 32.248 28.5623 32.248C29.4351 32.248 30.3079 32.2353 31.168 32.248C31.7246 32.2606 32.2432 32.2227 32.5342 31.6534C32.8378 31.0842 32.5595 30.6415 32.2306 30.1988C31.0415 28.5543 29.8525 26.9099 28.6635 25.2655C27.7021 23.9373 26.7408 22.5965 25.7794 21.2683C25.1343 20.3829 24.35 20.3702 23.7176 21.2683C21.5419 24.2662 19.3788 27.2768 17.2031 30.2747C17.1525 30.3379 17.1146 30.4012 17.064 30.4771C16.811 30.8565 16.7351 31.2487 16.9501 31.6661C17.1652 32.0709 17.5194 32.2606 17.9874 32.2606C19.1132 32.2606 20.239 32.2606 21.4027 32.2606C21.4027 35.853 21.4027 39.3949 21.4027 42.9999C21.2003 42.9999 21.0106 42.9999 20.8209 42.9999C17.3043 42.9999 13.7878 42.9873 10.2839 42.9999C6.78006 43.0126 4.11104 41.4314 2.0745 38.6865C0.974001 37.1939 0.328883 35.5115 0.0885456 33.6647C0.0758962 33.5761 0.0252987 33.4876 0 33.3991C0 32.8172 0 32.2353 0 31.6534Z"
                    fill="#C6C6C6"
                  />
                </svg>
                <input
                  type="file"
                  name="Image"
                  multiple={true}
                  accept=".xlsx, .jpg, .jpeg, .png"
                  style={{ display: "none" }}
                  ref={this.fileInput}
                  onChange={(e) => {
                    this.fileUpload(e);
                  }}
                />
                <span className="clickText">
                  {this.state.selectedFile?.name
                    ? this.state.selectedFile?.name
                    : "Click here to upload Excel"}
                </span>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              className="uploadTemplateButton"
              onClick={() => {
                this.downloadExcelTemplate();
              }}
            >
              <span className="light__span">
                <i
                  className="fas fa-file-excel"
                  style={{ color: "#20511f" }}
                ></i>
                &nbsp;Template
              </span>
              <GetAppIcon fontSize="xs" className="downloadIcon" />
            </button>
            <button
              className="Submit"
              onClick={() => {
                this.handleSubmitExcel();
              }}
            >
              Submit
            </button>
          </ModalFooter>
        </Modal>


          {getLocalStorage()}
          <QuickFilters
            openModal={this.openModal}
            toggleExcel={this.exportExcelData}
            addDriverFunc={this.addDriver}
            reset={this.reset}
            handleDriverName={this.handleDriverName}
            name={this.state.name}
            // resetName={this.resetName}
            handleDriverPhone={this.handleDriverPhone}
            phoneNo={this.state.phoneNo}
            // resetPhone={this.resetPhone}
            enterCheck={this.enterCheck}
            filterList={this.filterList}
            mode={darkTheme}
          />
          <Container fluid style={{ marginTop: "-8px" }}>
            {userType && (userType !== "admin" || userType !== "Admin") ? (
              <Index
                {...this.props}
                headers={AdminHeaderData}
                data={rows}
                searchBar={searchBar}
                fetchId={this.props.fetchId}
                getAllData={this.getAllData}
                dataLength={drivers?.drivers?.data?.length}
                rowHeight={"56px"}
                dataLoading={this.state.dataLoading}
              />
            ) : (
              <Index
                {...this.props}
                headers={AdminHeaderData}
                data={rows}
                searchBar={searchBar}
                fetchId={this.props.fetchId}
                getAllData={this.getAllData}
                dataLength={drivers?.drivers?.data?.length}
                rowHeight={"56px"}
                dataLoading={this.state.dataLoading}
              />
            )}

            {totCnt !== 0 && !this.state.dataLoading ? (
              <Row
                xs={12}
                style={{ color: darkTheme ? "#9d9d9d" : "#0C0C0C" }}
                className="mb-2 paginationRow"
              >
                <Col xs={7} sm={5} md={4} className="span-col-pagenation">
                  <div className="float-left">
                    <Pagination
                      className="desktop-pagination"
                      size="medium"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                    <Pagination
                      className="mobile-pagination"
                      size="small"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                  </div>
                </Col>
                <Col
                  className="mt-2 spanPagenationentries span-col-pagenation"
                  xs={5}
                  sm={7}
                  md={8}
                >
                  {" "}
                  <span>
                    Showing {fromVal} to {toVal} of {totCnt} entries
                  </span>
                </Col>
              </Row>
            ) : null}
          </Container>
          <Popup
            title={userLang?.masters?.ADD_DRIVER}
            openPopup={this.state.setOpenPopup}
            setOpenPopupfalse={this.setOpenPopupfalse}
            mode={darkTheme}
          >
            <DriverForm handleSubmit={this.handleSubmit} mode={darkTheme} />
          </Popup>
        </>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  drivers: state.Driver.drivers,
  fetchId: state.Driver.drivers.fetchId,
  policies: state.AccessPolicy.accessPolicies.policies,
  user: state.Login.user,
});

export default connect(mapStateToProps, {
  fetchIndents,
  fetchDrivers,
  fetchDriversExcel,
  subscribeConsent,
  unsubscribeConsent,
  uploadImagesAws,
  createDriverViaExcel,
  fetchDriverPresignedUrl
})(Driver);
