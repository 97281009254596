export const types = {
  FETCH_VEHICLETYPELIST: "FETCH_VEHICLETYPE",
  FETCH_VEHICLETYPELIST_SUCCESS: "FETCH_VEHICLETYPE_SUCCESS",

  SPEC_FILTER_VEHICLETYPE:"SPEC_FILTER_VEHICLETYPE",
  SPEC_FILTER_VEHICLETYPE_SUCCESS :"SPEC_FILTER_VEHICLETYPE_SUCCESS",
  
  SPEC_FILTER_VEHICLETYPE_EXCEL:"SPEC_FILTER_VEHICLETYPE_EXCEL",
  SPEC_FILTER_VEHICLETYPE_SUCCESS_EXCEL :"SPEC_FILTER_VEHICLETYPE_SUCCESS_EXCEL",

  SPEC_ADD_VEHICLETYPE:"SPEC_ADD_VEHICLETYPE",
  SPEC_ADD_VEHICLETYPE_SUCCESS:"SPEC_ADD_VEHICLETYPE_SUCCESS",

  SPEC_UPDATE_VEHICLETYPE:"SPEC_UPDATE_VEHICLETYPE",
  SPEC_UPDATE_VEHICLETYPE_SUCCESS:"SPEC_UPDATE_VEHICLETYPE_SUCCESS",

  SPEC_DELETE_VEHICLETYPE:"SPEC_DELETE_VEHICLETYPE",
  SPEC_DELETE_VEHICLETYPE_SUCCESS:"SPEC_DELETE_VEHICLETYPE_SUCCESS",

  SPEC_FETCHSIGNEDURL_VEHICLETYPE:"SPEC_FETCHSIGNEDURL_VEHICLETYPE",
  SPEC_FETCHSIGNEDURL_VEHICLETYPE_SUCCESS:"SPEC_FETCHSIGNEDURL_VEHICLETYPE_SUCCESS",
  
  UPLOAD_VEHICLETYPE:"UPLOAD_VEHICLETYPE_SUCCESS",
  UPLOAD_VEHICLETYPE_SUCCESS:"UPLOAD_VEHICLETYPE_SUCCESS",

  UPLOAD_BULK_DATA:"UPLOAD_BULK_DATA",
  GET_VECHILETYPE_PRESIGNED_URL:"GET_VECHILETYPE_PRESIGNED_URL",
  CREATE_VECHILETYPE_VIA_EXCEL:"CREATE_VECHILETYPE_VIA_EXCEL",
  UPLOAD_IMAGE1:"UPLOAD_IMAGE1",
  UPLOAD_IMAGE_SUCCESS1:"UPLOAD_IMAGE_SUCCESS1",
  CREATE_VECHILETYPE_VIA_EXCEL_SUCCESS:"CREATE_VECHILETYPE_VIA_EXCEL_SUCCESS",
	GET_VECHILETYPE_PRESIGNED_URL_SUCCESS:"GET_VECHILETYPE_PRESIGNED_URL_SUCCESS"
};
