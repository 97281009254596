import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import TripsReducer from './reducer';
const Trip = combineReducers({
  trips: TripsReducer,
  form: formReducer
});


export default Trip;
