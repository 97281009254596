import { connect } from "react-redux";
import React, { Component } from "react";
import { viewProductByOrderId } from "../../store/baxter-order/actions";
import {
  Row,
  Col,
  Card,
} from "reactstrap";
import { Button, Container } from "reactstrap";
import plusIcon from "./assets/plus.png";
import plusDarkIcon from "./assets/plusDark.png";
import minusIcon from "./assets/minus.png";
import { Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
class ViewProductByOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dataLoading: false,
      orderId: "",
      contentShow: "",
      currentIndex: "",
      orderNumber: "",
      isOpenInvoice: "",
      currentInvoice: "",
    };
  }

  componentDidMount() {
    this.getAllData();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }


  componentDidUpdate= (prevProps) =>{
    if(prevProps.currentOrder !== this.props.currentOrder){
      this.getAllData();
    }
  }
  
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 0.9;
  }

  getAllData = () => {
   
    this.setState({ dataLoading: false }, () => {});
    if (this.props?.currentProduct) {
      let data = this.props?.currentProduct;
   
      this.setState(
        {
          orderId: data?._id,
          orderNumber: data?.orderNumber,
        },
        () => {
          this.props.viewProductByOrderId(this.state.orderId, (res) => {
            this.setState({ dataLoading: false });
          });
        }
      );
    }
    if (this.props?.currentOrder) {
      let data = this.props?.currentOrder;
     
      this.setState(
        {
          orderId: data?._id,
          orderNumber: data?.orderNumber,
        },
        () => {
          this.props.viewProductByOrderId(this.state.orderId, (res) => {
            this.setState({ dataLoading: false });
          });
        }
      );
    }
  };
  contentShow = (id) => {
    this.setState({ contentShow: !this.state.contentShow, currentIndex: id });
  };
  onMouseout = (index) => {
    this.setState({ currentIndex: "" });
  };

  invoiceToggle = (data) => {
    this.props.history.push({
      pathname: "/invoiceOrderProduct",
      state: {currentInvoice:data},
    });
  };

  render() {
    let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let darkState = this.props.darkTheme;
    const oddRowStyle = {
      minHeight: "27px",
      width: "101%",
      margin: "auto",
      marginTop: "4px",
      marginRight: "0px",
    };
    const evenRowStyle = {
      minHeight: "27px",
      width: "101%",
      margin: "auto",
      marginTop: "4px",
      marginRight: "0px",
    };

    const rowHeaderStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "15px",
      color: darkState ? "#9D9D9D" : "#05284E",
      lineHeight: "18px",
      margin: "auto",
      paddingLeft: "1.5vw",
    };
    const rowSeparaterStyle = {
      borderLeft: "0.5px solid rgba(185, 185, 185, .5)",
      height: "286px",
      float: "left",
    };
    const rowValueStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "13px",
      color: darkState ? "#9D9D9D" : "#0C0C0C",
      margin: "auto",
      flex: "wrap",
    };
    const { getProductDataByOrder } = this.props;
    const localStorageData = JSON.parse(localStorage.getItem("productByOrder"));
    const productByOrderId = navigator.onLine
      ? getProductDataByOrder?.data?.docs
      : localStorageData?.data?.docs;

  
    let rows = [];
    if (Array.isArray(productByOrderId)) {
      productByOrderId.forEach((ele) => {
        rows.push({
          id: ele?._id,
          description: ele?.description ? ele?.description : "N/A",
          orderLineNo: ele?.orderLineNo ? ele?.orderLineNo : "N/A",
          secondItemNumber: ele?.secondItemNumber
            ? ele?.secondItemNumber
            : "N/A",
          description: ele?.description ? ele?.description : "N/A",
          descriptionLine2: ele?.descriptionLine2
            ? ele?.descriptionLine2
            : "N/A",
          nextStat: ele?.nextStat ? ele?.nextStat : "N/A",
          nxtDescription: ele?.nxtDescription ? ele?.nxtDescription : "N/A",
          lastStat: ele?.lastStat ? ele?.lastStat : "N/A",
          lstDescription: ele?.lstDescription ? ele?.lstDescription : "N/A",
          quantityOrdered: ele?.quantityOrdered ? ele?.quantityOrdered : "N/A",
          quantityShipped: ele?.quantityShipped ? ele?.quantityShipped : "N/A",
          quantityBackordered: ele?.quantityBackordered
            ? ele?.quantityBackordered
            : "N/A",
          quantityCanceled: ele?.quantityCanceled
            ? ele?.quantityCanceled
            : "N/A",
          unitPriceL: ele?.unitPriceL ? ele?.unitPriceL : "N/A",
          extendedPrice: ele?.extendedPrice ? ele?.extendedPrice : "N/A",
          unitListPrice: ele?.unitListPrice ? ele?.unitListPrice : "N/A",
          secUm: ele?.description ? ele?.description : "N/A",
          secondaryQtyOrdered: ele?.secondaryQtyOrdered
            ? ele?.secondaryQtyOrdered
            : "N/A",
          prUm: ele?.prUm ? ele?.prUm : "N/A",
          unitWeight: ele?.unitWeight ? ele?.unitWeight : "N/A",
          wtUm: ele?.wtUm ? ele?.wtUm : "N/A",
          unitVolume: ele?.unitVolume ? ele?.unitVolume : "N/A",
          volUm: ele?.volUm ? ele?.volUm : "N/A",
          customerPoNoDate: ele?.customerPoNoDate
            ? ele?.customerPoNoDate
            : "N/A",
          documentNumber: ele?.documentNumber ? ele?.documentNumber : "N/A",
          //order
          orderCo: ele?.order?.orderCo ? ele?.order?.orderCo : "N/A",
          orderType: ele?.order?.orderType ? ele?.order?.orderType : "N/A",
          businessUnit: ele?.order?.businessUnit
            ? ele?.order?.businessUnit
            : "N/A",
          addressNumber: ele?.orders?.addressNumber
            ? ele?.order?.addressNumber
            : "N/A",
          //invoice
          invoice: ele?.orders,
        });
      });
    }

    return (
      <>
        <Container fluid={true} className="mt-2">
          <Row>
            <Col xl="12" className="p-1">
              <Card style={{ width: "100%", padding: "2%" }} className="darkCardStyle">
                <h6><span className="light__span">Order Number </span>: <span className="dark__span">{this.state.orderNumber}</span></h6>
                {rows.map((itemdata, index) => (
                  <div className="accordion">
                    <div className="accordion-item barDropDark">
                      <>
                        <div
                          className="accordion-item-header"
                          key={index}
                          onClick={() => {
                            if (this.state.currentIndex === index) {
                              this.setState({
                                currentIndex:""
                              },()=>{
                              })
                            }
                            else {
                              this.setState({
                                currentIndex:index
                              },()=>{
                              })
                            }
                          }}
                        >
                          <Row style={{ width: "100%" }}>
                            <Col xs={10}>
                              <Row>
                                <Col className="">
                                  <Grid
                                    className=""
                                    container
                                    spacing={0}
                                    style={oddRowStyle}
                                  >
                                    <Grid
                                      // className=""
                                      item
                                      xs={3}
                                      md={6}
                                      style={rowHeaderStyle}
                                      className="MobilefontRoboto127  light__span"
                                    >
                                      <span>
                                        Line No{" "}
                                        <span className="text-bold">:</span>
                                      </span>
                                    </Grid>

                                    <Grid
                                      item
                                      xs={5}
                                      md={5}
                                      style={rowValueStyle}
                                      className="MobilefontRoboto124  dark__span"
                                    >
                                      {itemdata?.orderLineNo}
                                    </Grid>
                                  </Grid>
                                </Col>
                                <Col className="headerDesc">
                                  <Grid
                                    className=""
                                    container
                                    spacing={0}
                                    style={oddRowStyle}
                                  >
                                    <Grid
                                      // className=""
                                      item
                                      xs={3}
                                      md={6}
                                      style={rowHeaderStyle}
                                      className="MobilefontRoboto127  light__span"
                                    >
                                      <span>
                                        Order Type{" "}
                                        <span className="text-bold">:</span>
                                      </span>
                                    </Grid>

                                    <Grid
                                      item
                                      xs={5}
                                      md={5}
                                      style={rowValueStyle}
                                      className="MobilefontRoboto124  dark__span"
                                    >
                                      {itemdata?.orderType}
                                    </Grid>
                                  </Grid>
                                </Col>
                                <Col className="headerDesc">
                                  <Grid
                                    className=""
                                    container
                                    spacing={0}
                                    style={oddRowStyle}
                                  >
                                    <Grid
                                      // className=""
                                      item
                                      xs={3}
                                      md={6}
                                      style={rowHeaderStyle}
                                      className="MobilefontRoboto127  light__span"
                                    >
                                      <span>
                                        Warehouse{" "}
                                        <span className="text-bold">:</span>
                                      </span>
                                    </Grid>

                                    <Grid
                                      item
                                      xs={5}
                                      md={5}
                                      style={rowValueStyle}
                                      className="MobilefontRoboto124  dark__span"
                                    >
                                      {itemdata?.businessUnit}
                                    </Grid>
                                  </Grid>
                                </Col>
                                <Col className="headerDesc">
                                  <Grid
                                    className=""
                                    container
                                    spacing={0}
                                    style={oddRowStyle}
                                  >
                                    <Grid
                                      // className=""
                                      item
                                      xs={3}
                                      md={6}
                                      style={rowHeaderStyle}
                                      className="MobilefontRoboto127  light__span"
                                    >
                                      <span>
                                        Order Co{" "}
                                        <span className="text-bold">:</span>
                                      </span>
                                    </Grid>

                                    <Grid
                                      item
                                      xs={5}
                                      md={5}
                                      style={rowValueStyle}
                                      className="MobilefontRoboto124  dark__span"
                                    >
                                      {itemdata?.orderCo}
                                    </Grid>
                                  </Grid>
                                </Col>
                              </Row>
                            </Col>
                            <Col xs={2}>
                              <div className="">
                             
                                  {
                                  this.state.currentIndex === index ? (
                                    <Button
                                    outline
                                    color="primary"
                                    className="accordionIcon"
                                    onClick={() => {
                                      this.setState({
                                        currentIndex:""
                                      })
                                    }}
                                  >
                                    <img
                                      style={{ margin: "-4px" }}
                                      src={darkState ? minusIcon : minusIcon}
                                      alt="exp"
                                    />
                                    </Button>
                                  ) : (
                                    <Button
                                    outline
                                    color="primary"
                                    className="accordionIcon"
                                    onClick={() => {
                                      this.setState({
                                        currentIndex:index
                                      })
                                    }}
                                  >
                                    <img
                                      style={{ margin: "-4px" }}
                                      src={darkState ? plusDarkIcon : plusIcon}
                                      alt="exp"
                                    />
                                    </Button>
                                  )}
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div class="accordion-item-body">
                          {
                            this.state.currentIndex === index && (
                              <div class="accordion-item-body-content darkCardStyle">
                                <section class="column-container">
                                  <div class="col-1-3 pad color3">
                                    <Grid
                                      className=""
                                      container
                                      spacing={0}
                                      style={oddRowStyle}
                                    >
                                      <Grid
                                        // className=""
                                        item
                                        xs={3}
                                        md={6}
                                        style={rowHeaderStyle}
                                        className="MobilefontRoboto127  light__span"
                                      >
                                        <span>
                                          Order Line No{" "}
                                          <span className="text-bold">:</span>
                                        </span>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={5}
                                        md={5}
                                        style={rowValueStyle}
                                        className="MobilefontRoboto124  dark__span"
                                      >
                                        {itemdata?.orderLineNo}
                                      </Grid>
                                    </Grid>

                                    <Grid
                                      className=""
                                      container
                                      style={evenRowStyle}
                                    >
                                      <Grid
                                        item
                                        xs={3}
                                        md={6}
                                        style={rowHeaderStyle}
                                        className="MobilefontRoboto127  light__span"
                                      >
                                        <span>
                                          {" "}
                                          Description{" "}
                                          <span className="text-bold">:</span>
                                        </span>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={5}
                                        md={5}
                                        style={rowValueStyle}
                                        className="MobilefontRoboto124  dark__span"
                                      >
                                        {itemdata?.description}
                                      </Grid>
                                    </Grid>

                                    <Grid
                                      className=" "
                                      container
                                      style={oddRowStyle}
                                    >
                                      <Grid
                                        item
                                        xs={3}
                                        md={6}
                                        style={rowHeaderStyle}
                                        className="MobilefontRoboto127  light__span"
                                      >
                                        <span>
                                          {" "}
                                          Second Item Number{" "}
                                          <span className="text-bold">:</span>
                                        </span>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={5}
                                        md={5}
                                        style={rowValueStyle}
                                        className="MobilefontRoboto124  dark__span"
                                      >
                                        {itemdata?.secondItemNumber}
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      className=" "
                                      container
                                      style={evenRowStyle}
                                    >
                                      <Grid
                                        item
                                        xs={3}
                                        md={6}
                                        style={rowHeaderStyle}
                                        className="MobilefontRoboto127  light__span"
                                      >
                                        <span>
                                          {" "}
                                          DescriptionLine2{" "}
                                          <span className="text-bold">:</span>
                                        </span>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={5}
                                        md={5}
                                        style={rowValueStyle}
                                        className="MobilefontRoboto124  dark__span"
                                      >
                                        {itemdata?.descriptionLine2}
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      className=" "
                                      container
                                      style={oddRowStyle}
                                    >
                                      <Grid
                                        item
                                        xs={3}
                                        md={6}
                                        style={rowHeaderStyle}
                                        className="MobilefontRoboto127  light__span"
                                      >
                                        <span>
                                          {" "}
                                          Next Stat{" "}
                                          <span className="text-bold">:</span>
                                        </span>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={5}
                                        md={5}
                                        style={rowValueStyle}
                                        className="MobilefontRoboto124  dark__span"
                                      >
                                        {itemdata?.nextStat}
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      className=" "
                                      container
                                      style={oddRowStyle}
                                    >
                                      <Grid
                                        item
                                        xs={3}
                                        md={6}
                                        style={rowHeaderStyle}
                                        className="MobilefontRoboto127  light__span"
                                      >
                                        <span>
                                          {" "}
                                          SecUm{" "}
                                          <span className="text-bold">:</span>
                                        </span>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={5}
                                        md={5}
                                        style={rowValueStyle}
                                        className="MobilefontRoboto124  dark__span"
                                      >
                                        {itemdata?.secUm}
                                      </Grid>
                                    </Grid>

                                    <Grid
                                      className=" "
                                      container
                                      style={oddRowStyle}
                                    >
                                      <Grid
                                        item
                                        xs={3}
                                        md={6}
                                        style={rowHeaderStyle}
                                        className="MobilefontRoboto127  light__span"
                                      >
                                        <span>
                                          {" "}
                                          Address Number{" "}
                                          <span className="text-bold">:</span>
                                        </span>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={5}
                                        md={5}
                                        style={rowValueStyle}
                                        className="MobilefontRoboto124  dark__span"
                                      >
                                        {itemdata?.addressNumber}
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div style={rowSeparaterStyle}></div>
                                  <div class="col-1-3 pad color2">
                                    <Grid
                                      className=" "
                                      container
                                      style={evenRowStyle}
                                    >
                                      <Grid
                                        item
                                        xs={3}
                                        md={6}
                                        style={rowHeaderStyle}
                                        className="MobilefontRoboto127  light__span"
                                      >
                                        <span>
                                          {" "}
                                          PrUm{" "}
                                          <span className="text-bold">:</span>
                                        </span>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={5}
                                        md={5}
                                        style={rowValueStyle}
                                        className="MobilefontRoboto124  dark__span"
                                      >
                                        {itemdata?.prUm}
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      className=" "
                                      container
                                      style={oddRowStyle}
                                    >
                                      <Grid
                                        item
                                        xs={3}
                                        md={6}
                                        style={rowHeaderStyle}
                                        className="MobilefontRoboto127  light__span"
                                      >
                                        <span>
                                          {" "}
                                          Last Stat{" "}
                                          <span className="text-bold">:</span>
                                        </span>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={5}
                                        md={5}
                                        style={rowValueStyle}
                                        className="MobilefontRoboto124  dark__span"
                                      >
                                        {itemdata?.lastStat}
                                      </Grid>
                                    </Grid>

                                    <Grid
                                      className=" "
                                      container
                                      style={evenRowStyle}
                                    >
                                      <Grid
                                        item
                                        xs={3}
                                        md={6}
                                        style={rowHeaderStyle}
                                        className="MobilefontRoboto127  light__span"
                                      >
                                        Last Description{" "}
                                        <span className="text-bold"> :</span>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={5}
                                        md={5}
                                        style={rowValueStyle}
                                        className="MobilefontRoboto124  dark__span"
                                      >
                                        {itemdata?.lstDescription}
                                      </Grid>
                                    </Grid>

                                    <Grid
                                      className=" "
                                      container
                                      style={oddRowStyle}
                                    >
                                      <Grid
                                        item
                                        xs={3}
                                        md={6}
                                        style={rowHeaderStyle}
                                        className="MobilefontRoboto127  light__span"
                                      >
                                        <span>
                                          {" "}
                                          Quantity Ordered{" "}
                                          <span className="text-bold">:</span>
                                        </span>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={5}
                                        md={5}
                                        style={rowValueStyle}
                                        className="MobilefontRoboto124  dark__span"
                                      >
                                        {itemdata?.quantityOrdered}
                                      </Grid>
                                    </Grid>

                                    <Grid
                                      className=" "
                                      container
                                      style={evenRowStyle}
                                    >
                                      <Grid
                                        item
                                        xs={3}
                                        md={6}
                                        style={rowHeaderStyle}
                                        className="MobilefontRoboto127  light__span"
                                      >
                                        <span>
                                          {" "}
                                          Quantity Shipped{" "}
                                          <span className="text-bold">:</span>
                                        </span>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={5}
                                        md={5}
                                        style={rowValueStyle}
                                        className="MobilefontRoboto124  dark__span"
                                      >
                                        {itemdata?.quantityShipped}
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      className=" "
                                      container
                                      style={evenRowStyle}
                                    >
                                      <Grid
                                        item
                                        xs={3}
                                        md={6}
                                        style={rowHeaderStyle}
                                        className="MobilefontRoboto127  light__span"
                                      >
                                        <span>
                                          {" "}
                                          Unit Weight{" "}
                                          <span className="text-bold">:</span>
                                        </span>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={5}
                                        md={5}
                                        style={rowValueStyle}
                                        className="MobilefontRoboto124  dark__span"
                                      >
                                        {itemdata?.unitWeight}
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      className=" "
                                      container
                                      style={evenRowStyle}
                                    >
                                      <Grid
                                        item
                                        xs={3}
                                        md={6}
                                        style={rowHeaderStyle}
                                        className="MobilefontRoboto127  light__span"
                                      >
                                        <span>
                                          {" "}
                                          Unit Volume{" "}
                                          <span className="text-bold">:</span>
                                        </span>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={5}
                                        md={5}
                                        style={rowValueStyle}
                                        className="MobilefontRoboto124  dark__span"
                                      >
                                        {itemdata?.unitVolume}
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      className=" "
                                      container
                                      style={evenRowStyle}
                                    >
                                      <Grid
                                        item
                                        xs={3}
                                        md={6}
                                        style={rowHeaderStyle}
                                        className="MobilefontRoboto127  light__span"
                                      >
                                        <span>
                                          {" "}
                                          Volume{" "}
                                          <span className="text-bold">:</span>
                                        </span>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={5}
                                        md={5}
                                        style={rowValueStyle}
                                        className="MobilefontRoboto124  dark__span"
                                      >
                                        {itemdata?.volUm}
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div style={rowSeparaterStyle}></div>
                                  <div class="col-1-3 pad color2">
                                    <Grid
                                      className=" "
                                      container
                                      style={evenRowStyle}
                                    >
                                      <Grid
                                        item
                                        xs={3}
                                        md={6}
                                        style={rowHeaderStyle}
                                        className="MobilefontRoboto127  light__span"
                                      >
                                        <span>
                                          {" "}
                                          Quantity Back Ordered
                                          <span className="text-bold">:</span>
                                        </span>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={5}
                                        md={5}
                                        style={rowValueStyle}
                                        className="MobilefontRoboto124  dark__span"
                                      >
                                        {itemdata?.quantityBackordered}
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      className=" "
                                      container
                                      style={oddRowStyle}
                                    >
                                      <Grid
                                        item
                                        xs={3}
                                        md={6}
                                        style={rowHeaderStyle}
                                        className="MobilefontRoboto127  light__span"
                                      >
                                        <span>
                                          {" "}
                                          Quantity Cancelled{" "}
                                          <span className="text-bold">:</span>
                                        </span>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={5}
                                        md={5}
                                        style={rowValueStyle}
                                        className="MobilefontRoboto124  dark__span"
                                      >
                                        {itemdata?.quantityCanceled}
                                      </Grid>
                                    </Grid>

                                    <Grid
                                      className=" "
                                      container
                                      style={evenRowStyle}
                                    >
                                      <Grid
                                        item
                                        xs={3}
                                        md={6}
                                        style={rowHeaderStyle}
                                        className="MobilefontRoboto127  light__span"
                                      >
                                        <span>
                                          {" "}
                                          Unit PriceL{" "}
                                          <span className="text-bold">:</span>
                                        </span>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={5}
                                        md={5}
                                        style={rowValueStyle}
                                        className="MobilefontRoboto124  dark__span"
                                      >
                                        {itemdata?.unitPriceL}
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      className=" "
                                      container
                                      style={oddRowStyle}
                                    >
                                      <Grid
                                        item
                                        xs={3}
                                        md={6}
                                        style={rowHeaderStyle}
                                        className="MobilefontRoboto127  light__span"
                                      >
                                        <span>
                                          {" "}
                                          Extended Price{" "}
                                          <span className="text-bold">:</span>
                                        </span>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={5}
                                        md={5}
                                        style={rowValueStyle}
                                        className="MobilefontRoboto124  dark__span"
                                      >
                                        {itemdata?.extendedPrice}
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      className=" "
                                      container
                                      style={oddRowStyle}
                                    >
                                      <Grid
                                        item
                                        xs={3}
                                        md={6}
                                        style={rowHeaderStyle}
                                        className="MobilefontRoboto127  light__span"
                                      >
                                        <span>
                                          {" "}
                                          Unit List Price{" "}
                                          <span className="text-bold">:</span>
                                        </span>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={5}
                                        md={5}
                                        style={rowValueStyle}
                                        className="MobilefontRoboto124  dark__span"
                                      >
                                        {itemdata?.unitListPrice}
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      className=" "
                                      container
                                      style={oddRowStyle}
                                    >
                                      <Grid
                                        item
                                        xs={3}
                                        md={6}
                                        style={rowHeaderStyle}
                                        className="MobilefontRoboto127  light__span"
                                      >
                                        <span>
                                          {" "}
                                          Customer PoNo Date{" "}
                                          <span className="text-bold">:</span>
                                        </span>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={5}
                                        md={5}
                                        style={rowValueStyle}
                                        className="MobilefontRoboto124  dark__span"
                                      >
                                        {itemdata?.customerPoNoDate}
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      className=" "
                                      container
                                      style={oddRowStyle}
                                    >
                                      <Grid
                                        item
                                        xs={3}
                                        md={6}
                                        style={rowHeaderStyle}
                                        className="MobilefontRoboto127  light__span"
                                      >
                                        <span>
                                          {" "}
                                          Secondary Qty Ordered{" "}
                                          <span className="text-bold">:</span>
                                        </span>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={5}
                                        md={5}
                                        style={rowValueStyle}
                                        className="MobilefontRoboto124  dark__span"
                                      >
                                        {itemdata?.secondaryQtyOrdered}
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      className=" "
                                      container
                                      style={oddRowStyle}
                                    >
                                      <Grid
                                        item
                                        xs={3}
                                        md={6}
                                        style={rowHeaderStyle}
                                        className="MobilefontRoboto127  light__span"
                                      >
                                        <span>
                                          {" "}
                                          Document Number{" "}
                                          <span className="text-bold">:</span>
                                        </span>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={5}
                                        md={5}
                                        style={rowValueStyle}
                                        className="MobilefontRoboto124  dark__span"
                                      >
                                        {itemdata?.documentNumber}
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <Button
                                    style={{
                                      float: "right",
                                      marginRight: "3px",
                                      backgroundColor: "#05284E",
                                    }}
                                    onClick={() => {
                                      this.invoiceToggle(itemdata?.invoice);
                                    }}
                                  >
                                    Invoice
                                  </Button>
                                </section>
                              </div>
                            )}
                        </div>
                      </>
                    </div>
                  </div>
                ))}
              </Card>
            </Col>
          </Row>
        </Container>

      
      </>
    );
  }
}

const mapStateToProps = (state) => {
 
  return {
    ...state,
    getProductDataByOrder: state?.orderListReducer?.viewProductByOrderId,
    fetchId: state.orderListReducer?.fetchId,
  };
};
export default connect(mapStateToProps, {
  viewProductByOrderId,
})(withRouter(ViewProductByOrder));

