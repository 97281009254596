import React, { Component } from "react";
import { Row, Col, Card, Button, Input, InputGroup } from "reactstrap";
// import Select from "react-select";
// import { Grid } from "@material-ui/core";
// import { InputGroup } from "rsuite";
// images
import searchIcon from "./assets/search.png";
import searchDark from "./assets/searchDrk.png";
import plusIcon from "./assets/plus.png";
import plusDarkIcon from "./assets/plusDark.png";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
var darkMode;
class QuickFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userLangs: undefined,
    };
  }

  componentDidUpdate() {
    if (
      this.state.userLangs === undefined &&
      (this.state.userLangs !== userLang || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  }

  render() {

    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let userPreferredLang = window.localStorage.getItem("userPreferredLang");
    const bodyElement = document.querySelector("body");
    const darkMode = bodyElement.classList.contains("dark__mode");
    const customStyles1 = {
      option: (styles, state) => ({
        ...styles,
        cursor: "pointer",
      }),
      input: (base) => ({
        ...base,
        color: darkMode ? "#9d9d9d" : "#505d69",
      }),
      menu: ({ width, ...css }) => ({
        ...css,
        cursor: "pointer",
        width: "100%",
        backgroundColor: darkMode ? "#171722" : "#ffffff",
        border: darkMode
          ? "0.5px solid #616161"
          : "0.5px solid rgb(204, 204, 204)",
        boxShadow: darkMode
          ? "0px 3px 12px rgba(0, 0, 0, 0.12)"
          : "0px 3px 12px rgba(0, 0, 0, 0.12)",
        // minWidth: "100%",
      }),
      control: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "26px",
          width:
            this.props.selectedTranspoterValues?.length >= 2 ? "100%" : "100%",
          minWidth: "100%",
          minHeight: "26px",
          flexWrap: "nowrap",
          backgroundColor: darkMode ? "#2E2E46" : "",
        };
      },
      placeholder: (style) => {
        return {
          ...style,
          cursor: "pointer",
          marginTop: "2px",
          height: "26px",
          minHeight: "26px",
        };
      },
      indicatorsContainer: (style) => {
        return {
          ...style,
          cursor: "pointer",
          // backgroundColor: "#8e9af8",
          height: "26px",
          minHeight: "26px",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "10px",
          minHeight: "10px",
          backgroundColor: "#cccccc",
          marginTop: "8px",
        };
      },
      dropdownIndicator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "26px",
          minHeight: "26px",
          // backgroundColor: "#0066cc",
        };
      },
    };
    return (
      <React.Fragment>
        <Card className={`${darkMode ? "darkBgQuickFilter" : ""}`}>
          <div className="quickStylesBar trip-quick-filters darkBgQuickFilter">
            <Row>
              <Col xs={12} md={2} className="pl-0">
                <div className=" filter">
                  <div className="input-border mt-2">
                    <InputGroup>
                      <Input
                        className="form-control "
                        type="text"
                        name="state"
                        value={this.props.inputVal?.state}
                        onChange={(e) => this.props.onInputChange(e)}
                        onKeyDown={(event) => this.props?.enterCheck(event)}
                        placeholder="State"
                        aria-label="Search"
                        icon="fas fa-times-circle"
                      />
                      <span className="pt-2 pb-2 input-line pl-1 ml-1"></span>
                      <span
                        className="span-img"
                        onClick={() => this.props?.filterList()}
                      >
                        <img
                          className="pl-1"
                          src={darkMode ? searchDark : searchIcon}
                          alt=""
                        ></img>
                      </span>
                     
                    </InputGroup>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={2} className="pl-0">
                <div className=" filter">
                  <div className="input-border mt-2">
                    <InputGroup>
                      <Input
                        className="form-control"
                        type="text"
                        name="zone"
                        value={this.props.inputVal?.zone}
                        onChange={(e) => this.props.onInputChange(e)}
                        onKeyDown={(event) => this.props?.enterCheck(event)}
                        placeholder="Zone"
                        aria-label="Search"
                        icon="fas fa-times-circle"
                      />
                      <span className="pt-2 pb-2 input-line pl-1 ml-1"></span>
                      <span
                        className="span-img"
                        onClick={() => this.props?.filterList()}
                      >
                        <img
                          className="pl-1"
                          src={darkMode ? searchDark : searchIcon}
                          alt=""
                        ></img>
                      </span>
                 
                    </InputGroup>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={2} className="pl-0">
                <div className=" filter">
                  <div className="input-border mt-2">
                    <InputGroup>
                      <Input
                        className="form-control"
                        type="text"
                        name="cost"
                        value={this.props.inputVal?.cost}
                        onChange={(e) => this.props.onInputChange(e)}
                        onKeyDown={(event) => this.props?.enterCheck(event)}
                        placeholder="Cost"
                        aria-label="Search"
                        icon="fas fa-times-circle"
                      />
                      <span className="pt-2 pb-2 input-line pl-1 ml-1"></span>
                      <span
                        className="span-img"
                        onClick={() => this.props?.filterList()}
                      >
                        <img
                          className="pl-1"
                          src={darkMode ? searchDark : searchIcon}
                          alt=""
                        ></img>
                      </span>
                    </InputGroup>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={2} className="pl-0">
                <div className=" filter">
                  <div className="input-border mt-2">
                    <InputGroup>
                      <Input
                        className="form-control"
                        type="text"
                        name="businessUnit"
                        value={this.props.inputVal?.businessUnit}
                        onChange={(e) => this.props.onInputChange(e)}
                        onKeyDown={(event) => this.props?.enterCheck(event)}
                        placeholder="Warehouse"
                        aria-label="Search"
                        icon="fas fa-times-circle"
                      />
                      <span className="pt-2 pb-2 input-line pl-1 ml-1"></span>
                      <span
                        className="span-img"
                        onClick={() => this.props?.filterList()}
                      >
                        <img
                          className="pl-1"
                          src={darkMode ? searchDark : searchIcon}
                          alt=""
                        ></img>
                      </span>
                    </InputGroup>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={2} className="pl-0">
                <div className=" filter">
                  <div className="input-border mt-2">
                    <InputGroup>
                      <Input
                        className="form-control"
                        type="text"
                        name="courier"
                        value={this.props.inputVal?.courier}
                        onChange={(e) => this.props.onInputChange(e)}
                        onKeyDown={(event) => this.props?.enterCheck(event)}
                        placeholder="Courier"
                        aria-label="Search"
                        icon="fas fa-times-circle"
                      />
                      <span className="pt-2 pb-2 input-line pl-1 ml-1"></span>
                      <span
                        className="span-img"
                        onClick={() => this.props?.filterList()}
                      >
                        <img
                          className="pl-1"
                          src={darkMode ? searchDark : searchIcon}
                          alt=""
                        ></img>
                      </span>
                    </InputGroup>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={2} className="ml-auto">
                <div className="d-flex justify-content-end">
                  <div className=" filter mr-2">
                    {" "}
                    <div>
                      <Button
                        style={{
                          width: "100%",
                          paddingRight: "20px",
                        }}
                        onClick={() => this.props?.reset()}
                        className="reset"
                      >
                        {this.state.userLangs?.common?.RESET}
                      </Button>
                    </div>
                  </div>
                  <div className="filter mr-2">
                    <div className="tooltipIcon">
                      <Button
                        className="iconButtons iconDarkButtons"
                        onClick={() => this.props?.addEditZoneToStateFunc()}
                      >
                        <img
                          style={{ margin: "-4px" }}
                          src={darkMode ? plusDarkIcon : plusIcon}
                          alt="exp"
                        />
                      </Button>
                      <span
                        className="tooltipIcontext"
                        style={{ width: "100px", left: "-8px" }}
                      >
                        &nbsp;{" "}
                        {this.state.userLangs?.masters?.ADD_STATE_ZONE ??
                          "Add State"}
                        &nbsp;
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default QuickFilters;
