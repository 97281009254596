import React, { Component } from "react";
import { Row, Col, Card, Button } from "reactstrap";
import "../Dashboard/OperationsDashboard/Styles/dashboard.css";
import "./route.scss";


let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));


class QuickFilters extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidUpdate() {
        if (
            this.state.userLangs === undefined &&
            (this.state.userLangs !== userLang || userLang === null)
        ) {
            this.setState({
                userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
            });
        }
    }
    render() {
        // darkMode = this.props?.mode

        userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
        return (
            <React.Fragment>
                <Card className="darkBgQuickFilter">
                    <div className="quickStylesBar reportQuickStyleBar">
                        <Row>
                            <Col xs={0} md={6} lg={8}>
                                <div className="d-flex justify-content-start">
                                    <div>
                                        <Button
                                            className  = "buttonStyle"
                                            style={{
                                                minWidth: "70%",
                                            }}
                                          onClick={() => this.props?.toggleAddressModal()}
                                        >
                                            Add Address
                                        </Button>
                                    </div>
                                    <div>
                                        <Button
                                        className  = "buttonStyle"
                                            style={{
                                                minWidth: "70%",
                                            }}
                                        //   onClick={() => this.props?.reset()}
                                        >
                                            Upload Address
                                        </Button>
                                    </div>
                                </div>

                            </Col>

                        </Row>

                    </div>
                </Card>
            </React.Fragment >
        );
    }
}

export default QuickFilters;
