import { types } from "./actionTypes";
const initialState = {
  getBaxTripListData: null,
  fetchId: "",
};
const getBaxTripListReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TRIP_LIST_SUCCESS:
      state = {
        ...state,
        getBaxTripListData: action.payload,
        fetchId: "id" + new Date().getTime()
      };
      return state;
    case types.CHANGE_MARKED_AS_ARRIVED_STATUS_SUCCESS:
      state = {
        ...state,
        changeMarkedAsArrivedStatus: action.payload,
        fetchId: "id" + new Date().getTime()
      };
      return state;
      case types.QUALITY_CHECK_START_SUCCESS:
        state = {
          ...state,
          qualityCheckStart: action.payload,
          fetchId: "id" + new Date().getTime()
        };
        return state;
        case types.START_LOADING_STATUS_SUCCESS: 
        state = {
          ...state,
          startLoading: action.payload,
          fetchId: "id" + new Date().getTime()
        };
        return state;
        case types.LOADING_COMPLETED_SUCCESS: 
        state = {
          ...state,
          loadingCompleted: action.payload,
          fetchId: "id" + new Date().getTime()
        };
        return state;
        case types.VIEW_TRIP_HISTORY_SUCCESS: 
        state = {
          ...state,
          tripHistory: action.payload,
          fetchId: "id" + new Date().getTime()
        };
        return state;
       
    default:
      return state;
  }
};
export default getBaxTripListReducer;
