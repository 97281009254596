
import React, { Component } from "react";
import { Card, CardImg, Container, Row, Col, Button } from "reactstrap";
import { connect } from "react-redux";
import { fetchTripById } from "../../store/trip/actions";
import { trackLocationTripById } from "../../store/trip/actions";
import formatDate from "../DateFormatter";
import Index from "../../components/Common/DataTableNeo/Index";
import {
  HeaderData,
  searchBar,
  getLocalStorage,
} from "./RowHeaders/RowHeaders";
import maskGroup from "./assets/MaskGroup31.png";
import roadMap from "./assets/RoadMap.png";
import "./tripDetails.css";
import moment from "moment";

import QualityCheckModel from "./QualityCheckModel";
import TripDetailShipmentModel from "./ShipmentModel";
import TripHistoryModel from "./TripHistoryModel";
import { WaveLoader } from "../../components/Common/Loader/LoaderIndex";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class TripDetails_new extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      tripTransitionLogs: [],
      plannedIndex: "",
      arrivedAtSourceIndex: "",
      qualityCheckCompletedIndex: "",
      loadingInProgressIndex: "",
      shipmentVerifiedIndex: "",
      loadingCompletedIndex: "",
      epodInProgressIndex: "",
      epodCompletedIndex: "",
      arrivedAtViaPointIndex: "",
      rejectedIndex: "",

      showTripDetailModel: false,
      showShipDetailModel: false,
      showTripHistoryModel: false,
      tripDetailId: " ",
      lrStatus: " ",
      driverName: " ",
      transporterName: " ",
      tripIdHistory: " ",
    };
  }

  componentDidMount = () => {
    this.setState({ loading: true });
    // console.log("MOUNTED", this.props.history.location.state?.tripDetails);
    this.props.fetchTripById(
      this.props.history.location.state?.tripDetails._id,
      (res) => {
        // console.log("RESPONSE", res);
        this.setState(
          {
            tripTransitionLogs: res.data[0].tripStatusTransitionLogs,
            loading: false,
          },
          () => {
            // console.log(
            //   "LOGS - TRIP TRANSITION",
            //   this.state.tripTransitionLogs
            // );
            this.organizer();
          }
        );
      }
    );
  };

  organizer = () => {
    this.state.tripTransitionLogs.forEach((ele, index) => {
      if (ele.curStatus === "planned") {
        this.setState({ plannedIndex: index });
      } else if (ele.curStatus === "arrived_at_source") {
        this.setState({ arrivedAtSourceIndex: index });
      } else if (ele.curStatus === "quality_check_completed") {
        this.setState({ qualityCheckCompletedIndex: index });
      } else if (ele.curStatus === "loading_in_progress") {
        this.setState({ loadingInProgressIndex: index });
      } else if (ele.curStatus === "shipment_verified") {
        this.setState({ shipmentVerifiedIndex: index });
      } else if (ele.curStatus === "loading_completed") {
        this.setState({ loadingCompletedIndex: index });
      } else if (ele.curStatus === "epod_in_progress") {
        this.setState({ epodInProgressIndex: index });
      } else if (ele.curStatus === "epod_completed") {
        this.setState({ epodCompletedIndex: index });
      } else if (ele.curStatus === "arrived_at_via") {
        this.setState({ arrivedAtViaPointIndex: index });
      } else if (ele.curStatus === "rejected") {
        this.setState({ rejectedIndex: index });
      }
    });
  };

  transformStatus = (status) => {
    let newStatus = status.split("_").join(" ");
    switch (newStatus) {
      case "in transit":
        return (
          <div className="actionText" style={{ color: "#A020F0" }}>
            {" " + newStatus}
          </div>
        );
      case "loading in progress":
        return (
          <div className="actionText" style={{ color: "#AC1000" }}>
            {" " + newStatus}
          </div>
        );
      case "quality check completed":
        return (
          <div className="actionText" style={{ color: "#28A34C" }}>
            {" " + newStatus}
          </div>
        );
      case "cancelled":
        return (
          <div className="actionText" style={{ color: "#f75c5c" }}>
            {" " + newStatus}
          </div>
        );
      case "planned":
        return (
          <div className="actionText" style={{ color: "#E19600" }}>
            {" " + newStatus}
          </div>
        );
      case "completed":
        return (
          <div className="actionText" style={{ color: "#006400" }}>
            {" " + newStatus}
          </div>
        );
      case "arrived at source":
        return (
          <div className="actionText" style={{ color: "#FF6F00" }}>
            {" " + newStatus}
          </div>
        );
      case "arrived at via point":
        return (
          <div className="actionText" style={{ color: "#FF6F00" }}>
            {" " + newStatus}
          </div>
        );
      case "shipment verified":
        return (
          <div className="actionText" style={{ color: "#6900BB" }}>
            {" " + newStatus}
          </div>
        );
      case "rejected":
        return (
          <div className="actionText" style={{ color: "#ed2045" }}>
            {" " + newStatus}
          </div>
        );
      default:
        return <div className="actionText">{newStatus}</div>;
    }
  };
  driverPopulate = (value) => {
    let name;
    let phone;
    if (value.name !== "") {
      name = value.name;
    } else name = "----";
    if (value.phone !== "") phone = value.phone;
    else phone = "----";
    if (name === "----" && phone === "----") return "-----";
    else return name + " (" + phone + ")";
  };

  tripDetailToggleModel = async (id) => {
    this.setState({ showTripDetailModel: !this.state.showTripDetailModel });
    this.setState({ tripDetailId: id });
  };

  tripShipmentToggleModel = async (id, propsLr) => {
    this.setState({ showShipDetailModel: !this.state.showShipDetailModel });
    this.setState({ tripDetailId: id, lrStatus: propsLr });
  };

  tripHistoryToggleModel = async (id, name, driver, tripIdHistory) => {
    this.setState({ showTripHistoryModel: !this.state.showTripHistoryModel });
    this.setState({
      tripDetailId: id,
      transporterName: name,
      driverName: driver,
      tripIdHistory: tripIdHistory,
    });
  };

  route = (via) => {
    return via?.map((e, i) => (
      <div key={i}>
        {i ? (
          <div className="text-left pr-4" style={{ fontSize: "0.6em" }}>
            <i
              style={{ color: "#5664D2" }}
              className="fas fa-ellipsis-v ml-1"
            ></i>
          </div>
        ) : null}

        <div className="text-left d-flex align-items-center flex-nowrap">
          <i
            style={{ color: "#5664D2" }}
            className="fas fa-circle iconSize"
          ></i>
          <span className="ml-2">{e.label}</span>
        </div>
      </div>
    ));
  };

  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let mode = localStorage.getItem("Theme");
    var darkState = mode === "dark__mode" ? true : false;

    let tripDetailArray = [];
    let tripDetails = this.props.history.location.state?.tripDetails;
    tripDetailArray.push(tripDetails);
    let data = [];
    tripDetailArray.forEach((ele) => {
      data.push({
        id: ele._id,
        tripId: ele.tripId ? ele.tripId : "N/A",
        indentId: ele.indentId ? ele.indentId?.indentId : null,
        driver: ele.driver ? this.driverPopulate(ele.driver) : "N/A",
        source: ele.source ? `${ele.source.label}` : "N/A",
        destination: ele.destination ? `${ele.destination.label}` : "N/A",
        vehicle:
          (
            <>
              <p style={{ padding: "0px", margin: "0px" }}>
                {ele.vehicle?.regNo}
              </p>
              <p style={{ padding: "0px", margin: "0px" }}>
                {ele.vehicle?.type?.label}
              </p>
            </>
          ) || "N/A",
        transporter: ele.transporter?.name || "N/A",
        route: ele.via.length ? <>{this.route(ele.via)}</> : "DIRECT",
        description: ele.description,
        startTime: ele.startTime ? formatDate(ele.startTime) : "N/A",
        expiryTime: ele.expiryTime ? formatDate(ele.expiryTime) : "N/A",
        // status: this.status(ele.status, ele.lastRejection, ele._id) || "---",
        subStatus: this.transformStatus(ele.subStatus),
      });
    });
    return (
      <React.Fragment>
        {getLocalStorage()}
        <Container fluid style={{ margin: "8px" }} className="TripDetailsDiv">
          <Row>
            <Col xs={9} className="d-flex flex-row bd-highlight mb-2 ">
              <Button
                style={{
                  width: "60px",
                  height: "36px",
                  backgroundColor: "#3e3e3e",
                }}
                color="secondary"
                size="sm"
                onClick={() =>
                  this.props.history.push({
                    pathname: "/trips",
                  })
                }
              >
                <i className="fas fa-arrow-circle-left fa-2x"></i>
              </Button>
            </Col>
          </Row>
          {/* <div className="MobileResponsiveTripHis"> */}
          <Index
            {...this.props}
            headers={HeaderData}
            data={data}
            searchBar={searchBar}
            searchable={false}
          />
          <Card
            className="shadow mobileCardRoute"
            style={{
              justifyContent: "center",
              height: "640px",
              background: `url(${maskGroup})`,
            }}
          >
            {this.state.loading ? (
              <WaveLoader />
            ) : (
              <div className="row">
                <div
                  className="col-md-12"
                  style={{
                    position: "relative",
                  }}
                >
                  <div className="row topPosition d-flex align-items-center">
                    <div className="col-md-2"></div>
                    <div className="col-md-2 MobileArrived">
                      {this.state.tripTransitionLogs[
                        this.state.arrivedAtSourceIndex
                      ]?.curStatus === "arrived_at_source" ? (
                        <div
                          className={`square bottom frist bordering d-flex align-items-center ${
                            darkState ? "darkTripDetails" : ""
                          }`}
                        >
                          <div className="one">
                            <p
                              className={`m-0 paraHeadSource ${
                                darkState ? "light__span" : ""
                              }`}
                            >
                              {userLang?.dashboard?.ARRIVED_AT_SOURCE}
                            </p>
                            <p className="m-0">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.894 12.1299V10.8929C10.894 10.5681 10.83 10.2464 10.7057 9.94631C10.5813 9.64621 10.3991 9.37355 10.1693 9.14389C9.93958 8.91424 9.66684 8.7321 9.36668 8.60788C9.06653 8.48366 8.74484 8.41979 8.42 8.41992H3.473C2.81712 8.41992 2.1881 8.68047 1.72432 9.14425C1.26055 9.60802 1 10.237 1 10.8929V12.1299"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M5.89404 6C7.27476 6 8.39404 4.88071 8.39404 3.5C8.39404 2.11929 7.27476 1 5.89404 1C4.51333 1 3.39404 2.11929 3.39404 3.5C3.39404 4.88071 4.51333 6 5.89404 6Z"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              &nbsp;&nbsp;
                              <span
                                className={`${darkState ? "dark__span" : ""}`}
                              >
                                {this.state.tripTransitionLogs[
                                  this.state.arrivedAtSourceIndex
                                ]?.updatedBy?.name
                                  ? this.state.tripTransitionLogs[
                                      this.state.arrivedAtSourceIndex
                                    ]?.updatedBy?.name
                                  : "N/A"}
                              </span>
                            </p>
                            <p className="m-0">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6 11.5C8.76142 11.5 11 9.26142 11 6.5C11 3.73858 8.76142 1.5 6 1.5C3.23858 1.5 1 3.73858 1 6.5C1 9.26142 3.23858 11.5 6 11.5Z"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M6 4.5V7.433L7.955 8.411"
                                  stroke="#2800FC"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              &nbsp;&nbsp;
                              <span
                                className={`${darkState ? "dark__span" : ""}`}
                              >
                                {this.state.tripTransitionLogs[
                                  this.state.arrivedAtSourceIndex
                                ]?.updatedAt
                                  ? moment(
                                      this.state.tripTransitionLogs[
                                        this.state.arrivedAtSourceIndex
                                      ]?.updatedAt
                                    ).format("MM-DD-YYYY HH:mm:ss")
                                  : "N/A"}
                              </span>
                            </p>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-2 MobileLoading">
                      {this.state.tripTransitionLogs[
                        this.state.loadingInProgressIndex
                      ]?.curStatus === "loading_in_progress" ? (
                        <div
                          className={`square bottom second bordering d-flex align-items-center ${
                            darkState ? "darkTripDetails" : ""
                          }`}
                        >
                          <div className="two">
                            <p
                              className={`m-0 paraHeadSource ${
                                darkState ? "light__span" : ""
                              }`}
                            >
                              {userLang?.dashboard?.LOADING_IN_PROGRESS}
                            </p>
                            <p className="m-0">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.894 12.1299V10.8929C10.894 10.5681 10.83 10.2464 10.7057 9.94631C10.5813 9.64621 10.3991 9.37355 10.1693 9.14389C9.93958 8.91424 9.66684 8.7321 9.36668 8.60788C9.06653 8.48366 8.74484 8.41979 8.42 8.41992H3.473C2.81712 8.41992 2.1881 8.68047 1.72432 9.14425C1.26055 9.60802 1 10.237 1 10.8929V12.1299"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M5.89404 6C7.27476 6 8.39404 4.88071 8.39404 3.5C8.39404 2.11929 7.27476 1 5.89404 1C4.51333 1 3.39404 2.11929 3.39404 3.5C3.39404 4.88071 4.51333 6 5.89404 6Z"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              &nbsp;&nbsp;
                              <span
                                className={`${darkState ? "dark__span" : ""}`}
                              >
                                {this.state.tripTransitionLogs[
                                  this.state.loadingInProgressIndex
                                ]?.updatedBy?.name
                                  ? this.state.tripTransitionLogs[
                                      this.state.loadingInProgressIndex
                                    ]?.updatedBy?.name
                                  : "N/A"}
                              </span>
                            </p>
                            <p className="m-0">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6 11.5C8.76142 11.5 11 9.26142 11 6.5C11 3.73858 8.76142 1.5 6 1.5C3.23858 1.5 1 3.73858 1 6.5C1 9.26142 3.23858 11.5 6 11.5Z"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M6 4.5V7.433L7.955 8.411"
                                  stroke="#2800FC"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              &nbsp;&nbsp;
                              <span
                                className={`${darkState ? "dark__span" : ""}`}
                              >
                                {this.state.tripTransitionLogs[
                                  this.state.loadingInProgressIndex
                                ]?.updatedAt
                                  ? moment(
                                      this.state.tripTransitionLogs[
                                        this.state.loadingInProgressIndex
                                      ]?.updatedAt
                                    ).format("MM-DD-YYYY HH:mm:ss")
                                  : "N/A"}
                              </span>
                            </p>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-2 MobileIntransist">
                      {this.state.tripTransitionLogs[
                        this.state.loadingCompletedIndex
                      ]?.curStatus === "loading_completed" ? (
                        <div
                          className={`square bottom third bordering d-flex align-items-center ${
                            darkState ? "darkTripDetails" : ""
                          }`}
                        >
                          <div className="thr">
                            <div className="d-flex align-items-center justify-content-between">
                              <p
                                className={`m-0 paraHeadSource ${
                                  darkState ? "light__span" : ""
                                }`}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.tripHistoryToggleModel(
                                    data[0]?.id,
                                    data[0]?.transporter,
                                    data[0]?.driver,
                                    data[0]?.tripId
                                  );
                                }}
                              >
                                {userLang?.dashboard?.IN_TRANSIT}
                              </p>
                              <svg
                                width="26"
                                height="16"
                                viewBox="0 0 26 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.tripHistoryToggleModel(
                                    data[0]?.id,
                                    data[0]?.transporter,
                                    data[0]?.driver,
                                    data[0]?.tripId
                                  );
                                }}
                              >
                                <path
                                  d="M22 0H4C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H22C24.2091 16 26 14.2091 26 12V4C26 1.79086 24.2091 0 22 0Z"
                                  fill="#05284E"
                                />
                                <path
                                  d="M22 0.25H4C1.92893 0.25 0.25 1.92893 0.25 4V12C0.25 14.0711 1.92893 15.75 4 15.75H22C24.0711 15.75 25.75 14.0711 25.75 12V4C25.75 1.92893 24.0711 0.25 22 0.25Z"
                                  stroke="#2800FC"
                                  strokeWidth="0.5"
                                />
                                <path
                                  d="M14 8C14 7.44772 13.5523 7 13 7C12.4477 7 12 7.44772 12 8C12 8.55228 12.4477 9 13 9C13.5523 9 14 8.55228 14 8Z"
                                  stroke="white"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7 8C7 7.44772 6.55228 7 6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9C6.55228 9 7 8.55228 7 8Z"
                                  stroke="white"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M21 8C21 7.44772 20.5523 7 20 7C19.4477 7 19 7.44772 19 8C19 8.55228 19.4477 9 20 9C20.5523 9 21 8.55228 21 8Z"
                                  stroke="white"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            <p className="m-0">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.894 12.1299V10.8929C10.894 10.5681 10.83 10.2464 10.7057 9.94631C10.5813 9.64621 10.3991 9.37355 10.1693 9.14389C9.93958 8.91424 9.66684 8.7321 9.36668 8.60788C9.06653 8.48366 8.74484 8.41979 8.42 8.41992H3.473C2.81712 8.41992 2.1881 8.68047 1.72432 9.14425C1.26055 9.60802 1 10.237 1 10.8929V12.1299"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M5.89404 6C7.27476 6 8.39404 4.88071 8.39404 3.5C8.39404 2.11929 7.27476 1 5.89404 1C4.51333 1 3.39404 2.11929 3.39404 3.5C3.39404 4.88071 4.51333 6 5.89404 6Z"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              &nbsp;&nbsp;
                              <span
                                className={`${darkState ? "dark__span" : ""}`}
                              >
                                {" "}
                                {this.state.tripTransitionLogs[
                                  this.state.loadingCompletedIndex
                                ]?.updatedBy?.name
                                  ? this.state.tripTransitionLogs[
                                      this.state.loadingCompletedIndex
                                    ]?.updatedBy?.name
                                  : "N/A"}
                              </span>
                            </p>
                            <p className="m-0">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6 11.5C8.76142 11.5 11 9.26142 11 6.5C11 3.73858 8.76142 1.5 6 1.5C3.23858 1.5 1 3.73858 1 6.5C1 9.26142 3.23858 11.5 6 11.5Z"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M6 4.5V7.433L7.955 8.411"
                                  stroke="#2800FC"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              &nbsp;&nbsp;
                              <span
                                className={`${darkState ? "dark__span" : ""}`}
                              >
                                {" "}
                                {this.state.tripTransitionLogs[
                                  this.state.loadingCompletedIndex
                                ]?.updatedAt
                                  ? moment(
                                      this.state.tripTransitionLogs[
                                        this.state.loadingCompletedIndex
                                      ]?.updatedAt
                                    ).format("MM-DD-YYYY HH:mm:ss")
                                  : "N/A"}
                              </span>
                            </p>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-2 MobileCompletedCard">
                      {this.state.tripTransitionLogs[
                        this.state.epodCompletedIndex
                      ]?.curStatus === "epod_completed" ? (
                        <div
                          className={`square bottom fourth bordering d-flex align-items-center ${
                            darkState ? "darkTripDetails" : ""
                          }`}
                        >
                          <div className="four">
                            <div className="d-flex align-items-center justify-content-between">
                              <p
                                className={`m-0 paraHeadSource ${
                                  darkState ? "light__span" : ""
                                }`}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.tripShipmentToggleModel(data[0]?.id, {
                                    lrCopy: true,
                                  });
                                }}
                              >
                                {userLang?.dashboard?.COMPLETED}
                              </p>
                              <svg
                                width="26"
                                height="16"
                                viewBox="0 0 26 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.tripShipmentToggleModel(data[0]?.id, {
                                    lrCopy: true,
                                  });
                                }}
                              >
                                <path
                                  d="M22 0H4C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H22C24.2091 16 26 14.2091 26 12V4C26 1.79086 24.2091 0 22 0Z"
                                  fill="#05284E"
                                />
                                <path
                                  d="M22 0.25H4C1.92893 0.25 0.25 1.92893 0.25 4V12C0.25 14.0711 1.92893 15.75 4 15.75H22C24.0711 15.75 25.75 14.0711 25.75 12V4C25.75 1.92893 24.0711 0.25 22 0.25Z"
                                  stroke="#2800FC"
                                  strokeWidth="0.5"
                                />
                                <path
                                  d="M14 8C14 7.44772 13.5523 7 13 7C12.4477 7 12 7.44772 12 8C12 8.55228 12.4477 9 13 9C13.5523 9 14 8.55228 14 8Z"
                                  stroke="white"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7 8C7 7.44772 6.55228 7 6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9C6.55228 9 7 8.55228 7 8Z"
                                  stroke="white"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M21 8C21 7.44772 20.5523 7 20 7C19.4477 7 19 7.44772 19 8C19 8.55228 19.4477 9 20 9C20.5523 9 21 8.55228 21 8Z"
                                  stroke="white"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            <p className="m-0">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.894 12.1299V10.8929C10.894 10.5681 10.83 10.2464 10.7057 9.94631C10.5813 9.64621 10.3991 9.37355 10.1693 9.14389C9.93958 8.91424 9.66684 8.7321 9.36668 8.60788C9.06653 8.48366 8.74484 8.41979 8.42 8.41992H3.473C2.81712 8.41992 2.1881 8.68047 1.72432 9.14425C1.26055 9.60802 1 10.237 1 10.8929V12.1299"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M5.89404 6C7.27476 6 8.39404 4.88071 8.39404 3.5C8.39404 2.11929 7.27476 1 5.89404 1C4.51333 1 3.39404 2.11929 3.39404 3.5C3.39404 4.88071 4.51333 6 5.89404 6Z"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              &nbsp;&nbsp;
                              <span
                                className={`${darkState ? "dark__span" : ""}`}
                              >
                                {this.state.tripTransitionLogs[
                                  this.state.epodCompletedIndex
                                ]?.updatedBy?.name
                                  ? this.state.tripTransitionLogs[
                                      this.state.epodCompletedIndex
                                    ]?.updatedBy?.name
                                  : "N/A"}
                              </span>
                            </p>
                            <p className="m-0">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6 11.5C8.76142 11.5 11 9.26142 11 6.5C11 3.73858 8.76142 1.5 6 1.5C3.23858 1.5 1 3.73858 1 6.5C1 9.26142 3.23858 11.5 6 11.5Z"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M6 4.5V7.433L7.955 8.411"
                                  stroke="#2800FC"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              &nbsp;&nbsp;
                              <span
                                className={`${darkState ? "dark__span" : ""}`}
                              >
                                {" "}
                                {this.state.tripTransitionLogs[
                                  this.state.epodCompletedIndex
                                ]?.updatedAt
                                  ? moment(
                                      this.state.tripTransitionLogs[
                                        this.state.epodCompletedIndex
                                      ]?.updatedAt
                                    ).format("MM-DD-YYYY HH:mm:ss")
                                  : "N/A"}
                              </span>
                            </p>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="img">
              <img src="https://www.cloudesign.com/wp-content/uploads/2022/03/Road-image.png" />
            </div> */}
                <div className="roadMobileImg">
                  <CardImg src={roadMap} className="CardMobileImage"></CardImg>
                  {/* <div>
                <Card
                  style={{ backgroundColor: "black", height: "400px" }}
                ></Card>
              </div> */}
                </div>
                <div
                  className="col-md-12"
                  style={{
                    position: "relative",
                  }}
                >
                  <div className="row bottomPosition">
                    <div className="col-md-2 MobilePlannedCard">
                      {true ? (
                        <div
                          className={`square top fifth bordering d-flex align-items-center ${
                            darkState ? "darkTripDetails" : ""
                          }`}
                        >
                          <div className="five">
                            <p
                              className={`m-0 paraHeadSource ${
                                darkState ? "light__span" : ""
                              }`}
                            >
                              {userLang?.dashboard?.PLANNED}
                            </p>
                            <p className="m-0">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.894 12.1299V10.8929C10.894 10.5681 10.83 10.2464 10.7057 9.94631C10.5813 9.64621 10.3991 9.37355 10.1693 9.14389C9.93958 8.91424 9.66684 8.7321 9.36668 8.60788C9.06653 8.48366 8.74484 8.41979 8.42 8.41992H3.473C2.81712 8.41992 2.1881 8.68047 1.72432 9.14425C1.26055 9.60802 1 10.237 1 10.8929V12.1299"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M5.89404 6C7.27476 6 8.39404 4.88071 8.39404 3.5C8.39404 2.11929 7.27476 1 5.89404 1C4.51333 1 3.39404 2.11929 3.39404 3.5C3.39404 4.88071 4.51333 6 5.89404 6Z"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              &nbsp;&nbsp;
                              <span
                                className={`${darkState ? "dark__span" : ""}`}
                              >
                                {tripDetails.createdBy
                                  ? tripDetails.createdBy
                                  : "N/A"}
                              </span>
                            </p>
                            <p className="m-0">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6 11.5C8.76142 11.5 11 9.26142 11 6.5C11 3.73858 8.76142 1.5 6 1.5C3.23858 1.5 1 3.73858 1 6.5C1 9.26142 3.23858 11.5 6 11.5Z"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M6 4.5V7.433L7.955 8.411"
                                  stroke="#2800FC"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              &nbsp;&nbsp;
                              <span
                                className={`${darkState ? "dark__span" : ""}`}
                              >
                                {tripDetails.createdAt
                                  ? moment(tripDetails.createdAt).format(
                                      "MM-DD-YYYY HH:mm:ss"
                                    )
                                  : "N/A"}
                              </span>
                            </p>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-2 MobilbeQualityCheckCard ">
                      {this.state.tripTransitionLogs[
                        this.state.qualityCheckCompletedIndex
                      ]?.curStatus === "quality_check_completed" ||
                      this.state.tripTransitionLogs[this.state.rejectedIndex]
                        ?.curStatus === "rejected" ? (
                        <div
                          className={`square top bordering sixth d-flex align-items-center ${
                            darkState ? "darkTripDetails" : ""
                          }`}
                        >
                          <div className="six">
                            <div className="d-flex align-items-center justify-content-between">
                              <p
                                className={`m-0 paraHeadSource ${
                                  darkState ? "light__span" : ""
                                }`}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.tripDetailToggleModel(data[0]?.id);
                                }}
                              >
                                {this.state.tripTransitionLogs[
                                  this.state.rejectedIndex
                                ] ? (
                                  <div
                                    // className="actionText"
                                    style={{ color: "#ed2045" }}
                                  >
                                    {
                                      userLang?.dashboard
                                        ?.QUALITY_CHECK_COMPLETED
                                    }
                                  </div>
                                ) : (
                                  userLang?.dashboard?.QUALITY_CHECK_COMPLETED
                                )}
                              </p>
                              <svg
                                width="26"
                                height="16"
                                viewBox="0 0 26 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ cursor: "pointer", width:"40px", marginTop:"-9px" }}
                                onClick={() => {
                                  this.tripDetailToggleModel(data[0]?.id);
                                }}
                              >
                                <path
                                  d="M22 0H4C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H22C24.2091 16 26 14.2091 26 12V4C26 1.79086 24.2091 0 22 0Z"
                                  fill="#05284E"
                                />
                                <path
                                  d="M22 0.25H4C1.92893 0.25 0.25 1.92893 0.25 4V12C0.25 14.0711 1.92893 15.75 4 15.75H22C24.0711 15.75 25.75 14.0711 25.75 12V4C25.75 1.92893 24.0711 0.25 22 0.25Z"
                                  stroke="#2800FC"
                                  strokeWidth="0.5"
                                />
                                <path
                                  d="M14 8C14 7.44772 13.5523 7 13 7C12.4477 7 12 7.44772 12 8C12 8.55228 12.4477 9 13 9C13.5523 9 14 8.55228 14 8Z"
                                  stroke="white"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7 8C7 7.44772 6.55228 7 6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9C6.55228 9 7 8.55228 7 8Z"
                                  stroke="white"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M21 8C21 7.44772 20.5523 7 20 7C19.4477 7 19 7.44772 19 8C19 8.55228 19.4477 9 20 9C20.5523 9 21 8.55228 21 8Z"
                                  stroke="white"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            <p className="m-0">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.894 12.1299V10.8929C10.894 10.5681 10.83 10.2464 10.7057 9.94631C10.5813 9.64621 10.3991 9.37355 10.1693 9.14389C9.93958 8.91424 9.66684 8.7321 9.36668 8.60788C9.06653 8.48366 8.74484 8.41979 8.42 8.41992H3.473C2.81712 8.41992 2.1881 8.68047 1.72432 9.14425C1.26055 9.60802 1 10.237 1 10.8929V12.1299"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M5.89404 6C7.27476 6 8.39404 4.88071 8.39404 3.5C8.39404 2.11929 7.27476 1 5.89404 1C4.51333 1 3.39404 2.11929 3.39404 3.5C3.39404 4.88071 4.51333 6 5.89404 6Z"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              &nbsp;&nbsp;
                              <span
                                className={`${darkState ? "dark__span" : ""}`}
                              >
                                {" "}
                                {this.state.tripTransitionLogs[
                                  this.state.qualityCheckCompletedIndex
                                ]?.updatedBy?.name
                                  ? this.state.tripTransitionLogs[
                                      this.state.qualityCheckCompletedIndex
                                    ]?.updatedBy?.name
                                  : this.state.tripTransitionLogs[
                                      this.state.rejectedIndex
                                    ]?.updatedBy?.name
                                  ? this.state.tripTransitionLogs[
                                      this.state.rejectedIndex
                                    ]?.updatedBy?.name
                                  : "N/A"}
                              </span>
                            </p>
                            <p className="m-0">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6 11.5C8.76142 11.5 11 9.26142 11 6.5C11 3.73858 8.76142 1.5 6 1.5C3.23858 1.5 1 3.73858 1 6.5C1 9.26142 3.23858 11.5 6 11.5Z"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M6 4.5V7.433L7.955 8.411"
                                  stroke="#2800FC"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              &nbsp;&nbsp;
                              <span
                                className={`${darkState ? "dark__span" : ""}`}
                              >
                                {" "}
                                {this.state.tripTransitionLogs[
                                  this.state.qualityCheckCompletedIndex
                                ]?.updatedAt
                                  ? moment(
                                      this.state.tripTransitionLogs[
                                        this.state.qualityCheckCompletedIndex
                                      ]?.updatedAt
                                    ).format("MM-DD-YYYY HH:mm:ss")
                                  : this.state.tripTransitionLogs[
                                      this.state.rejectedIndex
                                    ]?.updatedAt
                                  ? moment(
                                      this.state.tripTransitionLogs[
                                        this.state.rejectedIndex
                                      ]?.updatedAt
                                    ).format("MM-DD-YYYY HH:mm:ss")
                                  : "N/A"}
                              </span>
                            </p>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-2 MobileShipmentCard">
                      {this.state.tripTransitionLogs[
                        this.state.shipmentVerifiedIndex
                      ]?.curStatus === "shipment_verified" ? (
                        <div
                          className={`square top bordering d-flex seventh align-items-center ${
                            darkState ? "darkTripDetails" : ""
                          }`}
                        >
                          <div className="seven">
                            <div className="d-flex align-items-center justify-content-between">
                              <p
                                className={`m-0 paraHeadSource ${
                                  darkState ? "light__span" : ""
                                }`}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.tripShipmentToggleModel(data[0]?.id, {
                                    lrCopy: false,
                                  });
                                }}
                              >
                                {userLang?.dashboard?.SHIPMENT_VERIFIED}
                              </p>
                              <svg className="ml-2"
                                width="26"
                                height="16"
                                viewBox="0 0 26 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.tripShipmentToggleModel(data[0]?.id, {
                                    lrCopy: false,
                                  });
                                }}
                              >
                                <path
                                  d="M22 0H4C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H22C24.2091 16 26 14.2091 26 12V4C26 1.79086 24.2091 0 22 0Z"
                                  fill="#05284E"
                                />
                                <path
                                  d="M22 0.25H4C1.92893 0.25 0.25 1.92893 0.25 4V12C0.25 14.0711 1.92893 15.75 4 15.75H22C24.0711 15.75 25.75 14.0711 25.75 12V4C25.75 1.92893 24.0711 0.25 22 0.25Z"
                                  stroke="#2800FC"
                                  strokeWidth="0.5"
                                />
                                <path
                                  d="M14 8C14 7.44772 13.5523 7 13 7C12.4477 7 12 7.44772 12 8C12 8.55228 12.4477 9 13 9C13.5523 9 14 8.55228 14 8Z"
                                  stroke="white"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7 8C7 7.44772 6.55228 7 6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9C6.55228 9 7 8.55228 7 8Z"
                                  stroke="white"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M21 8C21 7.44772 20.5523 7 20 7C19.4477 7 19 7.44772 19 8C19 8.55228 19.4477 9 20 9C20.5523 9 21 8.55228 21 8Z"
                                  stroke="white"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            <p className="m-0">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.894 12.1299V10.8929C10.894 10.5681 10.83 10.2464 10.7057 9.94631C10.5813 9.64621 10.3991 9.37355 10.1693 9.14389C9.93958 8.91424 9.66684 8.7321 9.36668 8.60788C9.06653 8.48366 8.74484 8.41979 8.42 8.41992H3.473C2.81712 8.41992 2.1881 8.68047 1.72432 9.14425C1.26055 9.60802 1 10.237 1 10.8929V12.1299"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M5.89404 6C7.27476 6 8.39404 4.88071 8.39404 3.5C8.39404 2.11929 7.27476 1 5.89404 1C4.51333 1 3.39404 2.11929 3.39404 3.5C3.39404 4.88071 4.51333 6 5.89404 6Z"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              &nbsp;&nbsp;
                              <span
                                className={`${darkState ? "dark__span" : ""}`}
                              >
                                {this.state.tripTransitionLogs[
                                  this.state.shipmentVerifiedIndex
                                ]?.updatedBy?.name
                                  ? this.state.tripTransitionLogs[
                                      this.state.shipmentVerifiedIndex
                                    ]?.updatedBy?.name
                                  : "N/A"}
                              </span>
                            </p>
                            <p className="m-0">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6 11.5C8.76142 11.5 11 9.26142 11 6.5C11 3.73858 8.76142 1.5 6 1.5C3.23858 1.5 1 3.73858 1 6.5C1 9.26142 3.23858 11.5 6 11.5Z"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M6 4.5V7.433L7.955 8.411"
                                  stroke="#2800FC"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              &nbsp;&nbsp;
                              <span
                                className={`${darkState ? "dark__span" : ""}`}
                              >
                                {" "}
                                {this.state.tripTransitionLogs[
                                  this.state.shipmentVerifiedIndex
                                ]?.updatedAt
                                  ? moment(
                                      this.state.tripTransitionLogs[
                                        this.state.shipmentVerifiedIndex
                                      ]?.updatedAt
                                    ).format("MM-DD-YYYY HH:mm:ss")
                                  : "N/A"}
                              </span>
                            </p>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-2 MobileArrivedAtVia">
                      {this.state.tripTransitionLogs[
                        this.state.arrivedAtViaPointIndex
                      ]?.curStatus === "arrived_at_via" ? (
                        <div
                          className={`square top bordering d-flex eighth align-items-center ${
                            darkState ? "darkTripDetails" : ""
                          }`}
                        >
                          <div className="eight">
                            <p
                              className={`m-0 paraHeadSource ${
                                darkState ? "light__span" : ""
                              }`}
                            >
                              {userLang?.dashboard?.ARRIVED_AT_VIA_POINT}
                            </p>
                            <p className="m-0">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.894 12.1299V10.8929C10.894 10.5681 10.83 10.2464 10.7057 9.94631C10.5813 9.64621 10.3991 9.37355 10.1693 9.14389C9.93958 8.91424 9.66684 8.7321 9.36668 8.60788C9.06653 8.48366 8.74484 8.41979 8.42 8.41992H3.473C2.81712 8.41992 2.1881 8.68047 1.72432 9.14425C1.26055 9.60802 1 10.237 1 10.8929V12.1299"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M5.89404 6C7.27476 6 8.39404 4.88071 8.39404 3.5C8.39404 2.11929 7.27476 1 5.89404 1C4.51333 1 3.39404 2.11929 3.39404 3.5C3.39404 4.88071 4.51333 6 5.89404 6Z"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              &nbsp;&nbsp;
                              <span
                                className={`${darkState ? "dark__span" : ""}`}
                              >
                                {" "}
                                {this.state.tripTransitionLogs[
                                  this.state.arrivedAtViaPointIndex
                                ]?.updatedBy?.name
                                  ? this.state.tripTransitionLogs[
                                      this.state.arrivedAtViaPointIndex
                                    ]?.updatedBy?.name
                                  : "N/A"}
                              </span>
                            </p>
                            <p className="m-0">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6 11.5C8.76142 11.5 11 9.26142 11 6.5C11 3.73858 8.76142 1.5 6 1.5C3.23858 1.5 1 3.73858 1 6.5C1 9.26142 3.23858 11.5 6 11.5Z"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M6 4.5V7.433L7.955 8.411"
                                  stroke="#2800FC"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              &nbsp;&nbsp;
                              <span
                                className={`${darkState ? "dark__span" : ""}`}
                              >
                                {" "}
                                {this.state.tripTransitionLogs[
                                  this.state.arrivedAtViaPointIndex
                                ]?.updatedAt
                                  ? moment(
                                      this.state.tripTransitionLogs[
                                        this.state.arrivedAtViaPointIndex
                                      ]?.updatedAt
                                    ).format("MM-DD-YYYY HH:mm:ss")
                                  : "N/A"}
                              </span>
                            </p>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                </div>
              </div>
            )}
          </Card>
          {/* </div> */}
        </Container>
        <QualityCheckModel
          isOpen={this.state.showTripDetailModel}
          toggleModel={this.tripDetailToggleModel}
          tripIds={this.state.tripDetailId}
        />

        <TripDetailShipmentModel
          isOpen={this.state.showShipDetailModel}
          toggleModel={this.tripShipmentToggleModel}
          tripIds={this.state.tripDetailId}
          lrStatus={this.state.lrStatus}
        />

        <TripHistoryModel
          isOpen={this.state.showTripHistoryModel}
          toggleModel={this.tripHistoryToggleModel}
          tripIds={this.state.tripDetailId}
          transporter={this.state.transporterName}
          driver={this.state.driverName}
          tripIdHistory={this.state.tripIdHistory}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    tripById: state.Trip.trips.editTrip,
    locationTripById: state.Trip.trips.locationTrip,
  };
};
export default connect(mapStateToProps, {
  fetchTripById,
  trackLocationTripById,
})(TripDetails_new);
