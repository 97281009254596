let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  HeaderData = {
    columns: [
      {
        label: userLang?.masters?.SHIPMENT_ID
          ? userLang?.masters?.SHIPMENT_ID
          : "Shipment ID",
        field: "shipmentId",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.masters?.TRIP_ID
          ? userLang?.masters?.TRIP_ID
          : "Trip ID",
        field: "tripId",
        sort: "asc",
        width: 2,
      },
      {
        label: "EPOD Status",
        field: "epodDetails",
        sort: "asc",
        width: 2,
      },
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var HeaderData = {
  columns: [
    {
      label: userLang?.masters?.SHIPMENT_ID
        ? userLang?.masters?.SHIPMENT_ID
        : "Shipment ID",
      field: "shipmentId",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.masters?.ep ? userLang?.masters?.TRIP_ID : "Trip ID",
      field: "tripId",
      sort: "asc",
      width: 2,
    },
    {
      label: "EPOD Status",
      field: "epodDetails",
      sort: "asc",
      width: 2,
    },
  ],
};
export const searchBar = {
  searchBarData: [],
};
