import { types } from "./actionTypes";
export const fetchDealers = (offset,callback) => ({
  type: types.FETCH_DEALERS,
  offset,
  callback,
});
export const fetchDealersfilter = (offset,filters,key, callback) => ({
  type: types.FETCH_FILTER_DEALERS,
  offset,
  filters,
  key,
  callback,
});

export const fetchAvailDealers = () => ({ type: types.FETCH_AVAIL_DEALERS });
// export const fetchPost = id => ({ type: types.FETCH_POST, id });
export const createDealer = (values, callback) => ({
  type: types.CREATE_DEALER,
  values,
  callback,
});
export const editDealerById = (values, callback) => ({
  type: types.EDIT_DEALER_BYID,
  values,
  callback,
});
export const fetchDealerById = (id, callback) => ({
  type: types.FETCH_DEALER_BYID,
  id,
  callback,
});
export const deleteDealer = (id, callback) => ({
  type: types.DELETE_DEALER,
  id,
  callback,
});
export const uploadDealer = (values, callback) => ({
  type: types.UPLOAD_DEALERS,
  values,
  callback,
});
