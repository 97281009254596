import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  Form,
} from "reactstrap";
import Select from "react-select";
const truckTypeOptions = [
  { label: "PTL", value: "PTL" },
  { label: "FTL", value: "FTL" },
];
export default class AddCharges extends Component {
  render() {
    const { errors } = this.props;
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    var darkMode = mode === "dark__mode" ? true : false;
    // console.log(">>>>>>>>>>>>>>>>>IN MODAL",this.props.form)
    return (
      <Modal
        isOpen={this.props.modal}
        // autoFocus={true}
        centered={true}
        toggle={this.props.toggle}
        className="addZoneToState"

      //  size={"xl"}
      >
        <div className="modal-content p-1">
          <ModalHeader>
            <div className="Heading">
              <h4 className="fontRoboto light__span">
                Add charges
              </h4>
              <svg
                onClick={this.props.toggle}
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.9092 1.82141L1.90918 13.8214"
                  stroke="#2800FC"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.90918 1.82141L13.9092 13.8214"
                  stroke="#2800FC"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </ModalHeader>

          <ModalBody>
            <Form>
              <FormGroup>
                <Label
                  className={`required-field labelStyle mt-2 ${this.props.mode ? "light__span" : ""
                    }`}
                >
                  {" "}
                  <span>Basic Freight Charges</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Basic Freight Charges"
                  value={this.props.form?.basicFreightCharges}
                  onChange={(e) =>
                    this.props.onChangeInput(
                      "basicFreightCharges",
                      e.target.value
                    )
                  }
                />
                {errors?.basicFreightCharges && (
                  <div className="error">{errors?.basicFreightCharges} </div>
                )}
              </FormGroup>

              <FormGroup>
                <Label
                  className={`required-field labelStyle mt-2 ${this.props.mode ? "light__span" : ""
                    }`}
                >
                  {" "}
                  <span>Loading Charges</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Loading Charges"
                  value={this.props.form?.loadingCharges}
                  onChange={(e) =>
                    this.props.onChangeInput("loadingCharges", e.target.value)
                  }
                />
                {errors?.loadingCharges && (
                  <div className="error">{errors?.loadingCharges} </div>
                )}
              </FormGroup>

              <FormGroup>
                <Label
                  className={`required-field labelStyle mt-2 ${this.props.mode ? "light__span" : ""
                    }`}
                >
                  {" "}
                  <span>Unloading Charges</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Unloading Charges"
                  value={this.props.form?.unLoadingCharges}
                  onChange={(e) =>
                    this.props.onChangeInput("unLoadingCharges", e.target.value)
                  }
                />
                {errors?.unLoadingCharges && (
                  <div className="error">{errors?.unLoadingCharges} </div>
                )}
              </FormGroup>

              <FormGroup>
                <Label
                  className={`labelStyle mt-2 ${this.props.mode ? "light__span" : ""
                    }`}
                >
                  {" "}
                  <span>Detention Charges</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Detention Charges"
                  value={this.props.form?.detentionCharges}
                  onChange={(e) =>
                    this.props.onChangeInput("detentionCharges", e.target.value)
                  }
                />
                {errors?.detentionCharges && (
                  <div className="error">{errors?.detentionCharges} </div>
                )}
              </FormGroup>

              <FormGroup>
                <Label
                  className={`required-field labelStyle mt-2 ${this.props.mode ? "light__span" : ""
                    }`}
                >
                  {" "}
                  <span>Truck Type</span>
                </Label>
                <div className="">
                  <Select
                    className="consent-react_selector pr-2"
                    classNamePrefix="react-select"
                    placeholder="Select ..."
                    isMulti={false}
                    // autoFocus={true}
                    // styles={customStyles1}
                    onChange={(selectedOption) =>
                      this.props.onChangeInput("truckType", selectedOption.value)
                    }
                    options={truckTypeOptions}
                    value={this.props.form?.truckType}
                  // defaultValue="ptl"
                  />
                </div>
                {errors?.truckType && (
                  <div className="error">{errors?.truckType} </div>
                )}
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            {/* <Button
              type="button"
              color="success"
              onClick={() => this.props.reset()}
            >
              Reset
            </Button> */}
            <Button
              type="button"
              color="primary"
              onClick={this.props.handleSubmit}
            >
              {this.props.viewById?.viewMode === "edit" ? "Update" : "Add"}
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    );
  }
}
