import { types } from "./actionTypes";
export const fetchTripsSecondary = (offset, filters, hopsData, callback) => ({
  type: types.FETCH_TRIPS_SECONDARY,
  offset,
  filters,
  hopsData,
  callback,
});

export const fetchTripsExcelSecondary = (offset, filters, callback) => ({
  type: types.FETCH_TRIPS_EXCEL_SECONDARY,
  offset,
  filters,
  callback,
});

export const change_arrivedAtSource_statusSecondary = (tripID, callback) => ({
  type: types.CHANGE_ARRIVEDATSOURCE_STATUS_SECONDARY,
  tripID,
  callback,
});

export const change_MarkAsArrived_InTransit_statusSecondary = (
  tripCustomerId,
  callback
) => ({
  type: types.CHANGE_MARKASARRIVED_INTRANSIT_STATUS_SECONDARY,
  tripCustomerId,
  callback,
});

export const setTripCustomerDropDown = (index) => ({
  type: types.SET_TRIP_CUSTOMER_DROPDOWN,
  index,
});

export const setTripCustomerDropDownFalse = () => ({
  type: types.SET_TRIP_CUSTOMER_DROPDOWN_FALSE,
});

export const tripQualityCheckSecondary = (values, callback) => ({
  type: types.TRIP_QUALITY_CHECK_SECONDARY,
  values,
  callback,
});

export const startLoadingSecondary = (tripID, callback) => ({
  type: types.START_LOADING_SECONDARY,
  tripID,
  callback,
});

export const startUnloadingSecondary = (tripID, callback) => ({
  type: types.START_UNLOADING_SECONDARY,
  tripID,
  callback,
});

export const subscribeConsentSecondary = (phone, callback) => ({
  type: types.SUBSCRIBE_CONSENT_SECONDARY,
  phone,
  callback,
});

export const shipmentVerifySecondary = (verify, callback) => ({
  type: types.SHIPMENT_VERIFY_SECONDARY,
  verify,
  callback,
});

export const shipmentVerifyInfoSecondary = (sf_number, callback) => ({
  type: types.SHIPMENT_VERIFY_INFO_SECONDARY,
  sf_number,
  callback,
});

export const addShipmentVerifySecondary = (verifyData, callback) => ({
  type: types.ADD_SHIPMENT_VERIFY_SECONDARY,
  verifyData,
  callback,
});

export const finishLoadingSecondary = (tripID, callback) => ({
  type: types.FINISH_LOADING_SECONDARY,
  tripID,
  callback,
});

export const tripEpodSecondary = (values, callback) => ({
  type: types.TRIP_EPOD_SECONDARY,
  values,
  callback,
});

export const tripEpodCompleteSecondary = (values, callback) => ({
  type: types.TRIP_EPOD_COMPLETE_SECONDARY,
  values,
  callback,
});

export const tripCompleteSecondary = (tripId, callback) => ({
  type: types.TRIP_COMPLETE_SECONDARY,
  tripId,
  callback,
});

export const acknowledgeLRSecondary = (values, callback) => ({
  type: types.ACKNOWLEDGE_LR_SECONDARY,
  values,
  callback,
});
