// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import { types } from "./actionTypes";

import { getApi } from "../../helpers/auth_helper";
// import { getDataGroupBy } from "rsuite/esm/utils";



function* workfetchVehiclePlacementReport({
  values,
  callback
}) {
  // console.log("values",values)
  let offset = values?.offset ? values?.offset : 0;
  let limit = values?.limit ? values?.limit: process.env.REACT_APP_PERPAGE;
  let transpoterId= values?.transpoterId ? values.transpoterId :"";
  let startDate = values?.startDate ? values?.startDate :"";
  let endDate = values?.endDate ? values?.endDate : "";
  let isExport = values?.isExport ? values?.isExport :false
  try {
    const uri = process.env.REACT_APP_SERVERURL+
    `/report/fetch/placementReport?offset=`+
    offset+
    `&limit=`+
    Number(limit)+
    `&transporterId=`+
    transpoterId +
    `&startDate=`+
    startDate+
    `&endDate=`+
    endDate +
    `&isExport=`+
    isExport;
    const response = yield call(getApi, uri);
    callback(response);
    yield put({
      type: types.VEHICLE_PLACEMENT_REPORT_SUCCESS,
      payload: response,
    });
    localStorage.setItem("VehicleplacementData", JSON.stringify(response));
  } catch (error) {
    // Act on the error
    console.countlog("Request failed! Could not fetch posts.", error);
  }
}

function* workfetchVehiclePlacementReportExport({
  values,
  callback
}) {
  // console.log("values",values)
  let offset = values?.offset ? values?.offset : 0;
  let limit = values?.limit ? values?.limit: process.env.REACT_APP_PERPAGE;
  let transpoterId= values?.transpoterId ? values.transpoterId :"";
  let startDate = values?.startDate ? values?.startDate :"";
  let endDate = values?.endDate ? values?.endDate : "";
  let isExport = values?.isExport ? values?.isExport :false
  try {
    const uri = process.env.REACT_APP_SERVERURL+
    `/report/fetch/placementReport?offset=`+
    offset+
    `&limit=`+
    Number(limit)+
    `&transporterId=`+
    transpoterId +
    `&startDate=`+
    startDate+
    `&endDate=`+
    endDate +
    `&isExport=`+
    isExport;
    const response = yield call(getApi, uri);
    callback(response);
    yield put({
      type: types.VEHICLE_PLACEMENT_REPORT_EXPORT_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.countlog("Request failed! Could not fetch posts.", error);
  }
}


/**
 * Watchers
 */


export function* watchfetchVehiclePlacementReport() {
  yield takeEvery(
    types.VEHICLE_PLACEMENT_REPORT,
    workfetchVehiclePlacementReport
  );
}
export function* watchfetchVehiclePlacementReportExport() {
  yield takeEvery(
    types.VEHICLE_PLACEMENT_REPORT_EXPORT,
    workfetchVehiclePlacementReportExport
  );
}

function* vehiclePlacementReportSaga() {
  yield all([
    fork(watchfetchVehiclePlacementReport),
    fork(watchfetchVehiclePlacementReportExport)
  ]);
}

export default vehiclePlacementReportSaga;
