import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Button,
  FormGroup,
  UncontrolledTooltip,
  Input,
} from "reactstrap";
import { MoonLoader } from "react-spinners";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";

import { fetchTripBillsById } from "../../store/trip/actions";

import { addNewBills } from "../../store/billing/actions";

import { MDBDataTable, MDBIcon } from "mdbreact";
import formatDate from "../DateFormatter";

import { restrict } from "../restrict";

import { PERMS } from "../../enum/perms.enum";

var data = {};

let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class TripBills extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Trip", link: "#" },
        { title: "Trip Details", link: "#" },
      ],
      loading: false,
      searchValue: "",
      verify: [],
      addRow: false,
      description: "",
      expense: 0,
      row: 0,
      billing: [],
      showAddBill: false,
      submitDisable: true,
    };
    if (process.env.REACT_APP_CLIENT != "3_BAXTER") {
      (async () => {
        let redirect = await restrict(PERMS.INDENT_PLANNER_READ);

        if (redirect === false) {
          this.props.history.push("/operationsDashboard");
        }
      })();
    }
  }

  componentDidMount = () => {
    this.getAllData();
  };

  componentWillUnmount = () => {
    this.setState({
      billing: [{ id: 0, description: "", expense: 0 }],
      row: 0,
      showAddBill: false,
    });
  };
  getAllData = () => {
    this.props.fetchTripBillsById(this.state.searchValue);
    this.setState({ loading: true });
    this.refineData();
  };
  refineData = () => {
    this.setState({ loading: false });
  };

  status = (status, lastRejection, id) => {
    switch (status) {
      case "Accepted":
        return (
          <strong className="tile-text h6 accepted-color">{status}</strong>
        );
      case "Rejected":
        return (
          <>
            <div id={"status-" + id}>
              <strong className="tile-text h6 rejected-color">{status}</strong>
            </div>
            <UncontrolledTooltip
              placement="top"
              autohide={true}
              target={"status-" + id}
            >
              <div>
                <b>{lastRejection.reason}</b>
              </div>
              {lastRejection.remark}
            </UncontrolledTooltip>
          </>
        );
      case "Assigned":
        return (
          <strong className="tile-text h6 assigned-color">{status}</strong>
        );
      case "Reassigned":
        return (
          <strong className="tile-text h6 assigned-color">{status}</strong>
        );
      case "Finalized":
        return (
          <strong className="tile-text h6 finalized-color">{status}</strong>
        );
      case "Drafted":
        return <strong className="tile-text h6 drafted-color">{status}</strong>;
      default:
        break;
    }
  };
  handleAddBill = (e, id) => {
    e.preventDefault();
    //consolelog("jkjkjk",e.target.name,e.target.value)
    const { name, value } = e.target;

    this.setState({ [name]: value, submitDisable: true });
  };
  handleAddbillSubmit = (e) => {
    e.preventDefault();

    let tripbill = this.props.tripBills?.data?.trip;
    tripbill = tripbill.filter((tr) => tr.tripId === this.state.searchValue);
    //consolelog(tripbill)
    let { _id } = tripbill[0];

    let otherDeductions = [...this.state.billing];
    let values = { tripId: _id, otherDeductions };
    this.props.addNewBills(values, (res) => {
      NotificationManager.success(res.message, "Success");
      // this.props.history.push("/Billing/InvoiceList");
    });
    let bill = [];
    this.setState(
      { billing: bill, description: "", expense: 0, searchValue: "" },
      () => this.getAllData()
    );
  };
  handleAddExpense = (e) => {
    e.preventDefault();
    let billing = this.state.billing;
    if (this.state.description !== "" && this.state.expense > 0) {
      billing.push({
        description: this.state.description,
        expense: this.state.expense,
      });
      this.setState({ billing: billing, description: "", expense: 0 });
    }
  };
  handleClick = () => {
    // if( this.props.tripBills?.data?.trip.length>0){
    //   this.state.billing.forEach((bill,index)=>{
    let { description, expense } = this.state;

    let collapse = (
      <table>
        <tbody>
          <tr className="colaps-rows" style={{ marginTop: "2rem" }}>
            <td className="colaps-cols-first" colSpan="4">
              <form>
                <FormGroup className="colaps-last-desc">
                  <label className="required-field">Description</label>
                  <div>
                    <Input
                      value={description}
                      name="description"
                      className="form-control"
                      required
                      onChange={(e) => {
                        this.handleAddBill(e);
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup className="colaps-last">
                  <label className="required-field">Expense Entry</label>
                  <div>
                    <Input
                      name="expense"
                      type="number"
                      value={expense}
                      className="form-control"
                      onChange={(e) => {
                        this.handleAddBill(e);
                      }}
                    />
                  </div>
                </FormGroup>
                <div className="colaps-last-btn ">
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={(e) => {
                      this.handleAddExpense(e);
                    }}
                    disabled={this.state.submitDisable}
                  >
                    Add Expense
                  </button>
                </div>
              </form>
            </td>
          </tr>
        </tbody>
      </table>
    );

    // if(bill.id==0){
    //   if(this.state.showAddBill){

    //     ReactDOM.render(collapse,document.getElementById(`dt`+bill.id))
    //   }
    // }
    // else{

    //     if(this.state.showAddBill){

    //     ReactDOM.render(collapse,document.getElementById(`dt`+bill.id))
    //     }

    // }

    // })
    // }
    if (this.state.showAddBill) {
      ReactDOM.render(collapse, document.getElementById(`dt`));
    }
  };

  searchValueSetter = (e) => {
    this.setState({ searchValue: e.target.value, searchOn: true }, () => {
      if (this.state.searchKey !== "" && this.state.searchValue !== "")
        this.setState({ disabled: false });
      if (this.state.searchValue === "") {
        this.setState({ disabled: true });
      }
    });
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (this.state.disabled === false) {
        this.getAllData();
      }
    }
  };

  reset = (e) => {
    this.setState(
      {
        searchValue: "",
        showAddBill: false,
      },
      () => {
        this.getAllData();
      }
    );
  };

  componentDidUpdate = () => {
    this.handleClick();
  };
  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

    // const role = JSON.parse(localStorage.getItem("authUser"))?.data?.role
    //   ?.label;

    const { loading, searchValue, searchOn } = this.state;
    this.policy = this.props.policies;
    // var show = false;

    let trips = [];
    if (Array.isArray(this.props.tripBills?.data?.trip)) {
      this.props.tripBills.data.trip.forEach((ele) => {
        trips.push({
          id: ele._id,
          tripId: ele.tripId ? ele.tripId : "---",

          source: ele.source ? ele.source.label : "---",
          destination: ele.destination ? ele.destination.label : "---",

          transporter: ele.transporter ? ele.transporter.name : "---",
          startTime: ele.startTime ? formatDate(ele.startTime) : "---",
          expiryTime: ele.expiryTime ? formatDate(ele.expiryTime) : "---",
          invoiceStatus: this.props.tripBills?.data?.invoice[0]?.status,
          state: ele.source?.address?.state,

          // clickEvent: (e) => this.handleClick(e),
        });
      });
    }

    if (loading) {
      return (
        <div className="page-content">
          <Col xs={12} className="loader">
            <MoonLoader size={45} color={"#123abc"} />
          </Col>
        </div>
      );
    }
    data = {
      columns: [
        {
          label: <span>{userLang?.trip?.TRIP_ID}</span>,
          field: "tripId",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>{userLang?.common?.SOURCE}</span>,
          field: "source",
          sort: "asc",
          width: 300,
        },
        {
          label: <b>{userLang?.common?.DESTINATION}</b>,
          field: "destination",
          sort: "asc",
          width: 300,
        },

        {
          label: <b>{userLang?.common?.START_TIME}</b>,
          field: "startTime",
          sort: "asc",
          width: 300,
        },
        {
          label: <b>{userLang?.invoice?.STATE}</b>,
          field: "state",
          sort: "asc",
          width: 300,
        },
        {
          label: <b>{userLang?.invoice?.INVOICE_STATUS}</b>,
          field: "invoiceStatus",
          sort: "asc",
          width: 300,
        },
      ],
      rows: trips,
    };

    return (
      <React.Fragment>
        <div className="mt-2" /* className="page-content" */>
          <Container fluid>
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody className="px-3 py-4">
                    <div className="justify-content-between d-flex mb-3">
                      <div md={2} className="float-left">
                        <h5
                          className="mt-1 BillDetailsFont"
                          // style={{
                          //   fontWeight: "700",
                          //   lineHeight: "24px",
                          //   size: "16px",
                          //   color: "#252B3B",
                          // }}
                        >
                          {userLang?.invoice?.BILL_DETAILS}{" "}
                        </h5>
                      </div>
                      <div className=" d-flex flex-wrap align-items-start float-right">
                        <div md={3} xs={12} className="pb-1 mr-4">
                          <form className="">
                            <Row>
                              <Col xs={10} className="pr-0 pl-2">
                                <input
                                  style={{ height: "37px" }}
                                  className="form-control searchBox form-control-sm sharp-corners-right rounded-left"
                                  type="text"
                                  name="SearchValue"
                                  value={searchValue || ""}
                                  onChange={this.searchValueSetter}
                                  placeholder="Search"
                                  aria-label="Search"
                                  onKeyPress={this.handleKeyDown}
                                  icon="fas fa-times-circle"
                                />
                              </Col>
                              <Col xs={2} className="pl-0">
                                <Button
                                  style={{
                                    height: "37px",
                                    width: "54px",
                                  }}
                                  color="primary"
                                  className="btn sharp-corners-left rounded-right"
                                  onClick={() => {
                                    this.getAllData();
                                    this.setState({ showAddBill: true });
                                  }}
                                >
                                  <MDBIcon icon="search" />
                                </Button>
                              </Col>
                            </Row>
                          </form>
                        </div>
                        <div className="text-right reset-col ml-1" md={1}>
                          <Button
                            style={{
                              color: "#333333 !important",
                            }}
                            className="rounded-black button-wide"
                            onClick={this.reset}
                            disabled={!searchOn}
                          >
                            {userLang?.common?.RESET}
                          </Button>
                        </div>
                      </div>
                    </div>{" "}
                    <div className="dataTableStyles">
                      <MDBDataTable
                        className="text-center text-capitalize"
                        responsive
                        data={data}
                        paging={false}
                        searching={false}
                      />
                      {/* {totCnt !== 0 ? ( */}
                      {this.state.showAddBill === true ? (
                        <Row className="table-row-collapse">
                          <Col xs="12">
                            <div>
                              <h5 style={{ marginLeft: "1%" }}>Add Bill: </h5>

                              {this.state.billing.map((e, index) => (
                                <Row key={index} className="m-1">
                                  <Col
                                    className=" font-weight-bold"
                                    md={6}
                                    style={{ paddingLeft: "18%" }}
                                  >
                                    Description : {e.description}
                                  </Col>
                                  <Col className="float-right" md={6}>
                                    Expense : {` ₹ ` + e.expense}
                                  </Col>
                                </Row>
                              ))}
                            </div>
                          </Col>
                          <Col xs={12}>
                            <span key={`dt`} id={`dt`}></span>
                          </Col>
                        </Row>
                      ) : null}

                      <Row xs={12}>
                        <Col xs={6}>
                          <span>
                            {/* Showing {fromVal} to {toVal} of {totCnt} entries */}
                            <Button
                              color="primary"
                              onClick={(e) => this.handleAddbillSubmit(e)}
                            >
                              {userLang?.common?.SUBMIT}
                            </Button>
                          </span>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    tripBills: state.Trip.trips.tripBills,
    policies: state.AccessPolicy.accessPolicies.policies,
  };
};

export default connect(mapStateToProps, {
  fetchTripBillsById,
  addNewBills,
})(TripBills);
