/* LAYOUT */
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const CHANGE_LAYOUT_WIDTH = "CHANGE_LAYOUT_WIDTH";
export const CHANGE_SIDEBAR_THEME = "CHANGE_SIDEBAR_THEME";
export const CHANGE_SIDEBAR_TYPE = "CHANGE_SIDEBAR_TYPE";

// topbar
export const CHANGE_TOPBAR_THEME = "CHANGE_TOPBAR_THEME";

/* RIGHT SIDEBAR */
export const TOGGLE_RIGHT_SIDEBAR = "TOGGLE_RIGHT_SIDEBAR";
export const SHOW_RIGHT_SIDEBAR = "SHOW_RIGHT_SIDEBAR";
export const HIDE_RIGHT_SIDEBAR = "HIDE_RIGHT_SIDEBAR";

// Preloader
export const CHANGE_PRELOADER = "CHANGE_PRELOADER";
export const types = {
  FETCH_TRANSPORTERS: "FETCH_TRANSPORTERS",
  FETCH_ALL_TRANSPORTER_SEARCH: "FETCH_ALL_TRANSPORTER_SEARCH",
  FETCH_ALL_TRANSPORTERS_SUCCESS: "FETCH_ALL_TRANSPORTERS_SUCCESS",
  GET_TRANSPORTERS: "GET_TRANSPORTERS",
  FETCH_HOPS_LIST: "FETCH_HOPS_LIST",
  FETCH_HOPS_LIST_SUCCESS: "FETCH_HOPS_LIST_SUCCESS",
  FETCH_TRANSPORTERS_SUCCESS: "FETCH_TRANSPORTERS_SUCCESS",
  GET_TRANSPORTERS_SUCCESS: "GET_TRANSPORTERS_SUCCESS",
  CREATE_TRANSPORTER: "CREATE_TRANSPORTER",
  CREATE_TRANSPORTER_SUCCESS: "CREATE_TRANSPORTER_SUCCESS",
  FETCH_TRANSPORTERS_FOR_INDENT: "FETCH_TRANSPORTERS_FOR_INDENT",
  FETCH_TRANSPORTERS_FOR_INDENT_SUCCESS:
    "FETCH_TRANSPORTERS_FOR_INDENT_SUCCESS",
  FETCH_TRANSPORTER_SEARCH: "FETCH_TRANSPORTER_SEARCH",
  FETCH_TRANSPORTER_SEARCH_SUCCESS: "FETCH_TRANSPORTER_SEARCH_SUCCESS",
};
//   export
// export const FETCH_TRIPS = "FETCH_TRIPS";
