import { types } from "./actionTypes";
const initialState = {
  getorderListDetails: null,
  fetchId: "",
};
const orderListReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ORDERS_SUCCESS:
      state = {
        ...state,
        getOrderListData: action.payload,
        fetchId: "id" + new Date().getTime()
      };
      return state;
    case types.GET_ORDERPRODUCT_SUCCESS:
        return {
          ...state,
          getProductOrderListData: action.payload,
          fetchId: "id" + new Date().getTime()
        };  
    case types.VIEW_ORDERSBY_ID_SUCCESS:
    return {
      ...state,
      viewOrderDetailById: action.payload,
      fetchId: "id" + new Date().getTime()
    }; 
    case types.VIEW_PRODUCTBY_ORDERID_SUCCESS:
      return {
        ...state,
        viewProductByOrderId: action.payload,
        fetchId: "id" + new Date().getTime()
      }; 
    case types.VIEW_INVOICEBY_ORDERID_SUCCESS:
      return {
        ...state,
        viewInvoiceByOrderId: action.payload,
        fetchId: "id" + new Date().getTime()
      }; 
    case types.VIEW_ORDER_LIFE_SUCCESS:
    return {
      ...state,
      viewOrderLife: action.payload,
      fetchId: "id" + new Date().getTime()
    }; 
    case types.TRACK_ORDER_SUCCESS:
      return {
        ...state,
        trackOrder: action.payload,
        fetchId: "id" + new Date().getTime()
      };
    case types.RESYNC_ORDER_SUCCESS:
    return {
      ...state,
      trackOrder: action.payload,
      fetchId: "id" + new Date().getTime()
    };  
    case types.ORDER_TRACKING_BY_EWAYBILL:
    return {
      ...state,
      trackOrderByEwayBill: action.payload,
      fetchId: "id" + new Date().getTime()
    };  
    default:
      return state;
  }
};
export default orderListReducer;
