import { types } from "./actionTypes";

export const fetchSync = (callback) => ({
  type: types.SYNC,
  callback,
});

export const fetchLastSyncTime = () => ({
  type: types.FETCH_LAST_SYNC_TIME,
});

export const fetchLanguage = (data) => ({
  type: types.FETCH_LANGUAGE,
  data
});