import axios from "axios";
// import { isUserAuthenticated, getUserToken } from '../auth_helper';
import { getHeaders } from "../global_req_helper";
const instance = axios.create();
// Gets the logged in user data from local session

// Register Method
const postRegister = (url, data) => {
  return instance
    .post(url, data)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch((err) => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postLogin = (url, data) => {
  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    //  mode: "cors", // or without this line
    redirect: "follow",
    body: JSON.stringify(data),
  };

  return fetch(url, parameters)
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      }
      throw response;
    })
    .catch((err) => {
      var message;
      if (err.status) {
        switch (err.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const getEmployees = (url) => {
  let headers = getHeaders();
  return fetch(url, { headers })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    });
};
// const getEmployees = (url) => {
//     let headers = {"Content-Type": "application/json"};
//     if (isUserAuthenticated) {
//         const token =getUserToken();
//       headers["Authorization"] = 'Bearer'+' '+token;
//     }

//     return fetch(url , {headers,})
//         .then(response => {
//             return response.json();
//         })
//         .then(json => {
//             return json;
//         });
// }
const createEmployee = (url, values) => {
  // let headers = {"Content-Type": "application/json"};
  // if (isUserAuthenticated) {
  //     const token =getUserToken();
  //   headers["Authorization"] = 'Bearer'+' '+token;
  // }
  let headers = getHeaders();

  const parameters = {
    method: "POST",
    headers: headers,
    //  mode: "cors", // or without this line
    redirect: "follow",
    body: JSON.stringify(values),
  };

  return fetch(url, parameters)
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    });
};
const deleteEmployee = (url) => {
  // let headers = getHeaders();

  // const parameters = {
  //     method: 'DELETE',
  //     headers: headers
  // };
  return fetch(url, {
    method: "DELETE",
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    });
};
// postForgetPwd
const postForgetPwd = (url, data) => {
  return axios
    .post(url, data)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      throw err[1];
    });
};

export {
  getEmployees,
  createEmployee,
  postRegister,
  postLogin,
  postForgetPwd,
  deleteEmployee,
};
