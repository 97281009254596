import React, { Component } from "react";
import { Row, Col, Card, CardBody, Container, Button } from "reactstrap";
import { connect } from "react-redux";
// import { viewOrderById } from "../../store/baxter-order/actions";
import { Grid } from "@material-ui/core";
import ViewProductByOrder from "./ViewProductByOrder";
import { getOrders } from "../../store/baxter-order/actions";
import { WaveLoader } from "../../components/Common/Loader/LoaderIndex";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class OrderDetail extends Component {
  constructor(props) {
    super();
    this.state = {
      mode: "",
      orderData: "",
      searchFilters: {},
    };
  }

  componentDidMount = () => {
   
    const params = new URLSearchParams(window.location.search);
    const orderNumber = params.get("orderNumber");
    // console.log(orderNumber, params, window.location.search, "id");
    this.setState(
      { urlOrderNumber: orderNumber, searchFilters: { "Order No": orderNumber } },
      () => {
        if (this.state.urlOrderNumber) {
          this.gerOrderByOrderNumber();
        }else{
          this.getData();
        }
      }
    );
  };

  gerOrderByOrderNumber = async () => {
    this.setState({ dataLoading: true }, () => {});
    let searchFilters = this.state.searchFilters;
    this.props.getOrders(0, 10,"" ,"","",searchFilters, (res) => {
      if (res && res !== undefined && res !== null) {
        if (Array.isArray(res?.data?.docs)) {
          let orderData = res?.data?.docs ? res?.data?.docs[0] : "";
          this.setState({ dataLoading: false, orderData, orderData });
        }
      }
      this.setState({ dataLoading: false });
    });
  };

  getData = () => {
    if (this.props.location?.state?.currentOrder) {
      let data = this.props.location?.state?.currentOrder;
      // console.log(data, "data");
      this.setState(
        {
          orderData: data,
        },
        () => {
          // console.log(this.state.orderData?.businessUnit, "pp");
        }
      );
    }
  };

  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let darkState = this.props.darkTheme;
    const { mode } = this.state;
    const oddRowStyle = {
      minHeight: "27px",
      width: "101%",
      margin: "auto",
      marginTop: "4px",
      marginRight: "0px",
    };
    const evenRowStyle = {
      minHeight: "27px",
      width: "101%",
      margin: "auto",
      marginTop: "4px",
      marginRight: "0px",
    };

    const rowHeaderStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "15px",
      color: darkState ? "#9D9D9D" : "#05284E",
      lineHeight: "18px",
      margin: "auto",
      paddingLeft: "1.5vw",
    };
    const rowSeparaterStyle = {
      borderLeft: "0.5px solid rgba(185, 185, 185, .5)",
      height: "482px",
      float: "left",
    };
    const rowSeparaterStyle1 = {
      borderLeft: "0.5px solid rgba(185, 185, 185, .5)",
      height: "355px",
      marginTop: "53px",
      float: "left",
    };
    const rowValueStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "13px",
      color: darkState ? "#9D9D9D" : "#0C0C0C",
      margin: "auto",
      flex: "wrap",
      alignContent: "flex-start",
    };
  
    const { orderData } = this.state;
    return (
      <React.Fragment>
        {this.state.dataLoading ? (
          <WaveLoader />
        ) : (
          <Container fluid={true} className="mt-2">
            <Row>
              <Col xs={9} className="d-flex flex-row bd-highlight mb-2 ">
                <Button
                  style={{
                    width: "60px",
                    height: "36px",
                    backgroundColor: "#3e3e3e",
                  }}
                  color="secondary"
                  size="sm"
                  onClick={() =>
                    this.props.history.push({
                      pathname: "/ordersList",
                    })
                  }
                >
                  <i className="fas fa-arrow-circle-left fa-2x"></i>
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xl="12" className="p-1">
                <div className="mt-4">
                  <section class="column-container">
                    <div class="col-1-3 pad color3">
                      <Card className="darkCardStyle orderCard">
                        <CardBody style={{ padding: "2%", height: "523px" }}>
                          <Grid
                            className=""
                            container
                            spacing={0}
                            style={oddRowStyle}
                          >
                            <Grid
                              // className=""
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                            className="MobilefontRoboto127  light__span"
                            >
                              <span className="light__span">
                                Order Number{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124 dark__span"
                            >
                              {orderData?.orderNumber}
                            </Grid>
                          </Grid>
                          <Grid className="" container style={evenRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                            className="MobilefontRoboto127  light__span"
                            >
                              <span className="light__span">
                                {" "}
                                Order Type <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124 dark__span"
                            >
                              {orderData?.orderType}
                            </Grid>
                          </Grid>

                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127 light__span"
                            >
                              <span>
                                {" "}
                                Order Co <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124 dark__span"
                            >
                              {orderData?.orderCo}
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={evenRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                            className="MobilefontRoboto127  light__span"
                            >
                              <span>
                                {" "}
                                Order Date <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124  dark__span"
                            >
                              {orderData?.orderDate}
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                            className="MobilefontRoboto127  light__span"
                            >
                              <span>
                                {" "}
                                Order Status{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124  dark__span"
                            >
                              <span className="OrderReleaseCol dark__span">
                                {orderData?.status?.replace(/_/g, " ")}
                              </span>
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={evenRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                            className="MobilefontRoboto127  light__span"
                            >
                              <span>
                                {" "}
                                Warehouse <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124  dark__span"
                            >
                              {orderData?.businessUnit}
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                            className="MobilefontRoboto127  light__span"
                            >
                              <span>
                                {" "}
                                Address Number{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124  dark__span"
                            >
                              {orderData?.addressNumber}
                            </Grid>
                          </Grid>

                          {/* <Grid className=" " container style={evenRowStyle}>
                          <Grid
                            item
                            xs={3}
                            md={6}
                            style={rowHeaderStyle}
                          className="MobilefontRoboto127  light__span"
                          >
                            Order Details Received{" "}
                            <span className="text-bold"> :</span>
                          </Grid>

                          <Grid
                            item
                            xs={5}
                            md={5}
                            style={rowValueStyle}
                            className="MobilefontRoboto124  dark__span"
                          >
                            {orderData?.orderDetailsReceived}
                          </Grid>
                        </Grid> */}

                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                            className="MobilefontRoboto127  light__span"
                            >
                              <span>
                                {" "}
                                Original Promised Date{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124  dark__span"
                            >
                              {orderData?.originalPromisedDate}
                            </Grid>
                          </Grid>

                          <Grid className=" " container style={evenRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                            className="MobilefontRoboto127  light__span"
                            >
                              <span>
                                {" "}
                                Reference <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124  dark__span"
                            >
                              {orderData?.reference}
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={evenRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                            className="MobilefontRoboto127  light__span"
                            >
                              <span>
                                {" "}
                                Ship To Number{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124  dark__span"
                            >
                              {orderData?.shipToNumber}
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                            className="MobilefontRoboto127  light__span"
                            >
                              <span>
                                {" "}
                                Total Cost <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124  dark__span"
                            >
                              {orderData?.totalCost}
                            </Grid>
                          </Grid>

                          <Grid className=" " container style={evenRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                            className="MobilefontRoboto127  light__span"
                            >
                              <span>
                                {" "}
                                Weight Display Um{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124  dark__span"
                            >
                              {orderData?.weightDisplayUm}
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                            className="MobilefontRoboto127  light__span"
                            >
                              <span>
                                {" "}
                                Updated Date{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124  dark__span"
                            >
                              {orderData?.updatedDate}
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                            className="MobilefontRoboto127  light__span"
                            >
                              <span>
                                {" "}
                                Updated Time{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124  dark__span"
                            >
                              {orderData?.updateTime}
                            </Grid>
                          </Grid>
                        </CardBody>
                      </Card>
                    </div>

                    <div class="col-1-3 pad">
                      <Card
                        style={{ backgroundColor: "#F1F5F7" }}
                        className="darkCardStyle orderCard"
                      >
                        <CardBody
                          style={{ padding: "2%", height: "506px" }}
                          className="mt-3 indentDexpiry"
                        >
                          <span className="fontRoboto177 text-center shipTo mt-4 light__span">
                            Ship to Details
                          </span>
                          <Grid className="mt-4" container style={oddRowStyle}>
                            <Grid
                              // className=""
                              item
                              xs={3}
                              md={4}
                              style={rowHeaderStyle}
                            className="MobilefontRoboto127  light__span"
                            >
                              <span>
                                Ship to Name{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={8}
                              style={rowValueStyle}
                              className="MobilefontRoboto124  dark__span"
                            >
                              {orderData?.shipToDetails?.shipToName}
                            </Grid>
                          </Grid>

                          <Grid className="" container style={evenRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={4}
                              style={rowHeaderStyle}
                            className="MobilefontRoboto127  light__span"
                            >
                              <span>
                                Ship to Address 1{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={8}
                              style={rowValueStyle}
                              className="MobilefontRoboto124  dark__span"
                            >
                              {orderData?.shipToDetails?.shipToAddress1}
                            </Grid>
                          </Grid>

                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={4}
                              style={rowHeaderStyle}
                            className="MobilefontRoboto127  light__span"
                            >
                              <span>
                                {" "}
                                Ship to Address 2{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={8}
                              style={rowValueStyle}
                              className="MobilefontRoboto124  dark__span"
                            >
                              {orderData?.shipToDetails?.shipToAddress2}
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={evenRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={4}
                              style={rowHeaderStyle}
                            className="MobilefontRoboto127  light__span"
                            >
                              <span>
                                {" "}
                                Ship to Address 3{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={8}
                              style={rowValueStyle}
                              className="MobilefontRoboto124  dark__span"
                            >
                              {orderData?.shipToDetails?.shipToAddress3}
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={4}
                              style={rowHeaderStyle}
                            className="MobilefontRoboto127  light__span"
                            >
                              <span>
                                {" "}
                                Ship to Address 4{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={8}
                              style={rowValueStyle}
                              className="MobilefontRoboto124  dark__span"
                            >
                              {orderData?.shipToDetails?.shipToAddress4}
                            </Grid>
                          </Grid>

                          <Grid className="mt-4" container style={oddRowStyle}>
                            <Grid
                              // className=""
                              item
                              xs={3}
                              md={4}
                              style={rowHeaderStyle}
                            className="MobilefontRoboto127  light__span"
                            >
                              <span>
                                Ship to City{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={8}
                              style={rowValueStyle}
                              className="MobilefontRoboto124  dark__span"
                            >
                              {orderData?.shipToDetails?.shipToCity}
                            </Grid>
                          </Grid>

                          <Grid className="" container style={evenRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={4}
                              style={rowHeaderStyle}
                            className="MobilefontRoboto127  light__span"
                            >
                              <span>
                                Ship to Destination{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={8}
                              style={rowValueStyle}
                              className="MobilefontRoboto124  dark__span"
                            >
                              {orderData?.shipToDetails?.shipToDestination}
                            </Grid>
                          </Grid>

                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={4}
                              style={rowHeaderStyle}
                            className="MobilefontRoboto127  light__span"
                            >
                              <span>
                                {" "}
                                Ship to Zip <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={8}
                              style={rowValueStyle}
                              className="MobilefontRoboto124  dark__span"
                            >
                              {orderData?.shipToDetails?.shipToZip}
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={evenRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={4}
                              style={rowHeaderStyle}
                            className="MobilefontRoboto127  light__span"
                            >
                              <span>
                                {" "}
                                Ship to Country{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={8}
                              style={rowValueStyle}
                              className="MobilefontRoboto124  dark__span"
                            >
                              {orderData?.shipToDetails?.shipToCountry}
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={4}
                              style={rowHeaderStyle}
                            className="MobilefontRoboto127  light__span"
                            >
                              <span>
                                {" "}
                                Ship to GSTN No{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={8}
                              style={rowValueStyle}
                              className="MobilefontRoboto124  dark__span"
                            >
                              {orderData?.shipToDetails?.shipToGSTNNo}
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={4}
                              style={rowHeaderStyle}
                            className="MobilefontRoboto127  light__span"
                            >
                              <span>
                                {" "}
                                Ship to State Name{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={8}
                              style={rowValueStyle}
                              className="MobilefontRoboto124  dark__span"
                            >
                              {orderData?.shipToDetails?.shipToStateName}
                            </Grid>
                          </Grid>
                        </CardBody>
                      </Card>
                    </div>

                    {/* <div style={rowSeparaterStyle}></div> */}

                    <div class="col-1-3 pad">
                      <Card
                        style={{ backgroundColor: "#F1F5F7" }}
                        className="darkCardStyle orderCard"
                      >
                        <CardBody
                          style={{ padding: "2%", height: "506px" }}
                          className="mt-3 indentDexpiry"
                        >
                          <span className="fontRoboto177  shipTo text-center mt-4  light__span">
                            Bill to Details
                          </span>
                          <div>
                            <Grid
                              className="mt-4"
                              container
                              style={oddRowStyle}
                            >
                              <Grid
                                // className=""
                                item
                                xs={3}
                                md={4}
                                style={rowHeaderStyle}
                              className="MobilefontRoboto127  light__span"
                              >
                                <span>
                                  Bill to Name{" "}
                                  <span className="text-bold">:</span>
                                </span>
                              </Grid>

                              <Grid
                                item
                                xs={5}
                                md={8}
                                style={rowValueStyle}
                                className="MobilefontRoboto124  dark__span"
                              >
                                {orderData?.billToDetails?.billedToName}
                              </Grid>
                            </Grid>

                            <Grid className="" container style={evenRowStyle}>
                              <Grid
                                item
                                xs={3}
                                md={4}
                                style={rowHeaderStyle}
                              className="MobilefontRoboto127  light__span"
                              >
                                <span>
                                  Bill to Address 1{" "}
                                  <span className="text-bold">:</span>
                                </span>
                              </Grid>

                              <Grid
                                item
                                xs={5}
                                md={8}
                                style={rowValueStyle}
                                className="MobilefontRoboto124  dark__span"
                              >
                                {orderData?.billToDetails?.billedToAddress1}
                              </Grid>
                            </Grid>

                            <Grid className=" " container style={oddRowStyle}>
                              <Grid
                                item
                                xs={3}
                                md={4}
                                style={rowHeaderStyle}
                              className="MobilefontRoboto127  light__span"
                              >
                                <span>
                                  {" "}
                                  Bill to Address 2
                                  <span className="text-bold">:</span>
                                </span>
                              </Grid>

                              <Grid
                                item
                                xs={5}
                                md={8}
                                style={rowValueStyle}
                                className="MobilefontRoboto124  dark__span"
                              >
                                {orderData?.billToDetails?.billedToAddress2}
                              </Grid>
                            </Grid>
                            <Grid className=" " container style={evenRowStyle}>
                              <Grid
                                item
                                xs={3}
                                md={4}
                                style={rowHeaderStyle}
                              className="MobilefontRoboto127  light__span"
                              >
                                <span>
                                  {" "}
                                  Bill to Address 3{" "}
                                  <span className="text-bold">:</span>
                                </span>
                              </Grid>

                              <Grid
                                item
                                xs={5}
                                md={8}
                                style={rowValueStyle}
                                className="MobilefontRoboto124  dark__span"
                              >
                                {orderData?.billToDetails?.billedToAddress3}
                              </Grid>
                            </Grid>
                            <Grid className=" " container style={oddRowStyle}>
                              <Grid
                                item
                                xs={3}
                                md={4}
                                style={rowHeaderStyle}
                              className="MobilefontRoboto127  light__span"
                              >
                                <span>
                                  {" "}
                                  Bill to Address 4{" "}
                                  <span className="text-bold">:</span>
                                </span>
                              </Grid>

                              <Grid
                                item
                                xs={5}
                                md={8}
                                style={rowValueStyle}
                                className="MobilefontRoboto124  dark__span"
                              >
                                {orderData?.billToDetails?.billedToAddress4}
                              </Grid>
                            </Grid>

                            <Grid
                              className="mt-4"
                              container
                              style={oddRowStyle}
                            >
                              <Grid
                                // className=""
                                item
                                xs={3}
                                md={4}
                                style={rowHeaderStyle}
                              className="MobilefontRoboto127  light__span"
                              >
                                <span>
                                  Bill to City
                                  <span className="text-bold">:</span>
                                </span>
                              </Grid>

                              <Grid
                                item
                                xs={5}
                                md={8}
                                style={rowValueStyle}
                                className="MobilefontRoboto124  dark__span"
                              >
                                {orderData?.billToDetails?.billedToCity}
                              </Grid>
                            </Grid>

                            <Grid className="" container style={evenRowStyle}>
                              <Grid
                                item
                                xs={3}
                                md={4}
                                style={rowHeaderStyle}
                              className="MobilefontRoboto127  light__span"
                              >
                                <span>
                                  Bill to Destination{" "}
                                  <span className="text-bold">:</span>
                                </span>
                              </Grid>

                              <Grid
                                item
                                xs={5}
                                md={8}
                                style={rowValueStyle}
                                className="MobilefontRoboto124  dark__span"
                              >
                                {orderData?.billToDetails?.billedToDestination}
                              </Grid>
                            </Grid>

                            <Grid className=" " container style={oddRowStyle}>
                              <Grid
                                item
                                xs={3}
                                md={4}
                                style={rowHeaderStyle}
                              className="MobilefontRoboto127  light__span"
                              >
                                <span>
                                  Bill to Zip{" "}
                                  <span className="text-bold">:</span>
                                </span>
                              </Grid>

                              <Grid
                                item
                                xs={5}
                                md={8}
                                style={rowValueStyle}
                                className="MobilefontRoboto124  dark__span"
                              >
                                {orderData?.billDetails?.billedToZip}
                              </Grid>
                            </Grid>
                            <Grid className=" " container style={evenRowStyle}>
                              <Grid
                                item
                                xs={3}
                                md={4}
                                style={rowHeaderStyle}
                              className="MobilefontRoboto127  light__span"
                              >
                                <span>
                                  {" "}
                                  Bill to Country
                                  <span className="text-bold">:</span>
                                </span>
                              </Grid>

                              <Grid
                                item
                                xs={5}
                                md={8}
                                style={rowValueStyle}
                                className="MobilefontRoboto124  dark__span"
                              >
                                {orderData?.billDetails?.billedToCountry}
                              </Grid>
                            </Grid>
                            <Grid className=" " container style={oddRowStyle}>
                              <Grid
                                item
                                xs={3}
                                md={4}
                                style={rowHeaderStyle}
                              className="MobilefontRoboto127  light__span"
                              >
                                <span>
                                  {" "}
                                  Bill to GSTN No{" "}
                                  <span className="text-bold">:</span>
                                </span>
                              </Grid>

                              <Grid
                                item
                                xs={5}
                                md={8}
                                style={rowValueStyle}
                                className="MobilefontRoboto124  dark__span"
                              >
                                {orderData?.billToDetails?.billedToGSTNNo}
                              </Grid>
                            </Grid>
                            <Grid className=" " container style={oddRowStyle}>
                              <Grid
                                item
                                xs={3}
                                md={4}
                                style={rowHeaderStyle}
                              className="MobilefontRoboto127  light__span"
                              >
                                <span>
                                  {" "}
                                  Bill to State Name{" "}
                                  <span className="text-bold">:</span>
                                </span>
                              </Grid>

                              <Grid
                                item
                                xs={5}
                                md={8}
                                style={rowValueStyle}
                                className="MobilefontRoboto124  dark__span"
                              >
                                {orderData?.billToDetails?.billedToStateName}
                              </Grid>
                            </Grid>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>

            <ViewProductByOrder
              currentProduct={this.props.location?.state?.currentOrder}
              currentOrder={this.state.orderData}
            />
          </Container>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    getOrdersData: state?.orderListReducer?.getOrderListData,
    fetchId: state.orderListReducer?.fetchId,
  };
};
export default connect(mapStateToProps, {
  getOrders,
})(OrderDetail);
