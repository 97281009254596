import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
//medthods
import {
  deleteApi,
  getApi,
  patchApi,
  postApi,
  uploadExcel,
  putApi
} from "../../helpers/auth_helper";

// workers
function* workerGetProducts({ filters, searchFilter, callback }) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  let filterString = "";
  const keyMap = {
    "Item Number":"itemNumber",
    "Description Line2":"descriptionLine2",
    "QV SubDivision":"qvSubDivision",
    "Case In Size":"caseInSize",
    "Case Per Pallet":"casePerPallet",
    "Weight Per Case":"weightPerCaseInKGS",
    "Length":"lInCMS",
    "Width":"wInCMS",
    "Height":"hInCMS"
    // Add more key-value pairs as needed
  };

  
  for (const [key, value] of Object.entries(searchFilter)) {
    let filterKey = keyMap[key] || key;
    let filterValue = value;
    filterString = filterString + "&" + filterKey + "=" + filterValue;
  }
  try {
     const url =
    process.env.REACT_APP_SERVERURL +
    "/product/products?offset=" +
    offset +
    "&limit=" +
    limit +
    filterString ;
   
    const response = yield call(getApi, url);
    callback(response);
    localStorage.setItem("ProductsData", response ? JSON.stringify(response):null);
    yield put({
      type: types.GET_PRODUCTS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}

function* workerGetProductsExcel({ filters, searchFilter, callback }) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  let filterString = "";
  const keyMap = {
    "Item Number":"itemNumber",
    "Description Line2":"descriptionLine2",
    "QV SubDivision":"qvSubDivision",
    "Case In Size":"caseInSize",
    "Case Per Pallet":"casePerPallet",
    "Weight Per Case":"weightPerCaseInKGS",
    "Length":"lInCMS",
    "Width":"wInCMS",
    "Height":"hInCMS"
    // Add more key-value pairs as needed
  };

  
  for (const [key, value] of Object.entries(searchFilter)) {
    let filterKey = keyMap[key] || key;
    let filterValue = value;
    filterString = filterString + "&" + filterKey + "=" + filterValue;
  }
  try {
     const url =
    process.env.REACT_APP_SERVERURL +
    "/product/products?offset=" +
    offset +
    "&limit=100000" +
    limit +
    filterString ;
   
    const response = yield call(getApi, url);
    callback(response);
    localStorage.setItem("ProductsData", response ? JSON.stringify(response):null);
    yield put({
      type: types.GET_PRODUCTS_SUCCESS_EXCEL,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}

function* workerCreateProduct({ payload, callback }) {
  try {
    const url = process.env.REACT_APP_SERVERURL + "/product/create";
    const response = yield call(postApi, url, payload);
    callback(response);
    yield put({
      type: types.CREATE_PRODUCT_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerCreateProductViaExcel({ payload, callback }) {
  try {
    const url = process.env.REACT_APP_SERVERURL + "/Product/addViaExcel";
    const response = yield call(postApi, url, payload);
    callback(response);
    yield put({
      type: types.CREATE_PRODUCT_VIA_EXCEL_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerUpdateProduct({ payload, callback }) {
  try {
    const url = process.env.REACT_APP_SERVERURL + "/product/update";
    const response = yield call(putApi, url, payload);
    callback(response);
    yield put({
      type: types.UPDATE_PRODUCT_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerDeleteProduct({ payload, callback }) {
    try {
      const url = process.env.REACT_APP_SERVERURL + "/product/delete";
      const response = yield call(deleteApi, url, payload);
      callback(response);
      yield put({
        type: types.DELETE_PRODUCT_SUCCESS,
        payload: response,
      });
    } catch (e) {
      console.log(e);
    }
}

function* workerUpdateOptimizationValues({ Values, callback }) {

  try {
    const url = process.env.REACT_APP_SERVERURL + "/product/UpdateProductOptimizationValues";

    const response = yield call(patchApi, url, Values);
    callback(response);
    yield put({
      type: types.UPDATE_OPTIMIZATION_VAlUES_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}

// watchers
export function* watchGetProducts() {
  yield takeEvery(types.GET_PRODUCTS, workerGetProducts);
}
export function* watchGetProductsExcel() {
  yield takeEvery(types.GET_PRODUCTS_EXCEL, workerGetProductsExcel);
}
export function* watchCreateProduct() {
  yield takeEvery(types.CREATE_PRODUCT, workerCreateProduct);
}
export function* watchCreateProductViaExcel() {
  yield takeEvery(types.CREATE_PRODUCT_VIA_EXCEL, workerCreateProductViaExcel);
}
export function* watchUpdateProduct() {
  yield takeEvery(types.UPDATE_PRODUCT, workerUpdateProduct);
}
export function* watchUpdateProductOptimization() {
  yield takeEvery(types.UPDATE_OPTIMIZATION_VAlUES, workerUpdateOptimizationValues);
}
export function* watchDeleteProduct() {
    yield takeEvery(types.DELETE_PRODUCT, workerDeleteProduct);
  }

function* productSaga() {
  yield all([fork(watchGetProducts)]);
  yield all([fork(watchGetProductsExcel)]);
  yield all([fork(watchCreateProduct)]);
  yield all([fork(watchCreateProductViaExcel)]);
  yield all([fork(watchUpdateProduct)]);
  yield all([fork(watchDeleteProduct)]);
  yield all([fork(watchUpdateProductOptimization)]);
}
export default productSaga;
