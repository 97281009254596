import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
//medthods
import {
  deleteApi,
  getApi,
  patchApi,
  postApi,
  uploadExcel,
  putApi,
} from "../../helpers/auth_helper";

// workers
function* workerGetLogbookData({ filters, callback }) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  let formDate = filters?.FromDate ? filters?.FromDate : "";
  let toDate = filters?.ToDate ? filters?.ToDate : "";

  try {
    const url =
      process.env.REACT_APP_SERVERURL +
      "/report/fetch/logbookReport?fromInvoiceDate=" +
      formDate +
      "&toInvoiceDate=" +
      toDate;
    const response = yield call(getApi, url);
    callback(response);
    localStorage.setItem(
      "LogbookData",
      response ? JSON.stringify(response) : null
    );
    yield put({
      type: types.GET_LOGBOOK_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerGetLogbookDataExcelS3Url({ filters, searchFilter, callback }) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;

  try {
    const url =
      process.env.REACT_APP_SERVERURL +
      "/report/fetch/logbookExcelDownloadUrls?" +
      "&offset=" +
      offset +
      "&limit=" +
      limit +
      "&fromRequestDate=&toRequestDate=";
    const response = yield call(getApi, url);
    callback(response);
    localStorage.setItem(
      "LogbookDataS3URL",
      response ? JSON.stringify(response) : null
    );
    yield put({
      type: types.GET_LOGBOOK_DATA_EXCEL_S3_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerCreateLogbookData({ payload, callback }) {
  try {
    const url =
      process.env.REACT_APP_SERVERURL + "/report/addLogbookReportExcelUrl";
    const response = yield call(postApi, url, payload);
    callback(response);
    yield put({
      type: types.CREATE_LOGBOOK_S3_URL_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}

// watchers
export function* watchGetLogbookData() {
  yield takeEvery(types.GET_LOGBOOK_DATA, workerGetLogbookData);
}
export function* watchGetLogbookDataExcelS3Urls() {
  yield takeEvery(
    types.GET_LOGBOOK_DATA_EXCEL_S3,
    workerGetLogbookDataExcelS3Url
  );
}
export function* watchCreateLogbookData() {
  yield takeEvery(types.CREATE_LOGBOOK_S3_URL, workerCreateLogbookData);
}

function* LogbookDataSaga() {
  yield all([fork(watchGetLogbookData)]);
  yield all([fork(watchCreateLogbookData)]);
  yield all([fork(watchGetLogbookDataExcelS3Urls)]);
}
export default LogbookDataSaga;
