// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import { types } from "./actionTypes";

// import {
// 	changeSidebarType as changeSidebarTypeAction,
// 	changeTopbarTheme as changeTopbarThemeAction,
// } from "./actions";

// import axios from "axios";
import {
  deleteApi,
  getApi,
  patchApi,
  postApi,
  putUploadImagesApi
} from "../../helpers/auth_helper";
// const instance = axios.create();

function* workfetchHops({ offset, filters, callback }) {
  let offsetVal =
    offset?.offset === null || offset?.offset === undefined
      ? offset
      : offset.offset;
  let limit = offset?.limit ? offset?.limit : process.env.REACT_APP_PERPAGE;
  let label = offset?.label ? offset?.label : "";
  let hopCode = offset?.hopCode ? offset?.hopCode : "";
  // console.log("limit",limit,"label",label)
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/hop/hops?offset=` +
      offsetVal +
      `&limit=` +
      limit +
      `&label=` +
      label +
      "&hopCode=" +
      hopCode;
    // process.env.REACT_APP_PERPAGE;
    const response = yield call(getApi, uri);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_HOPS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch hop.", error);
  }
}

function* workfetchSourceDestination({ offset, sourceId, q, callback }) {
  try {
    // Try to call the API
    let uri = `${process.env.REACT_APP_SERVERURL}/hop/source_dest?source=${sourceId}&q=${q}`;

    // +`?offset=` +
    // offset +
    // `&limit=` +
    // process.env.REACT_APP_PERPAGE;
    const response = yield call(getApi, uri);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_SOURCE_DESTINATION_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch hop.", error);
  }
}

//Master Hops
function* workfetchHopsfilter({ filters, searchFilter, callback }) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  let filterString = "";
  if (searchFilter !== null && searchFilter !== undefined) {
    for (const [key, value] of Object.entries(searchFilter)) {
      let filterKey =  key;
      let filterValue = value;
      filterString = filterString + "&" + filterKey + "=" + filterValue;
    }
  }
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/hop/hops?offset=` +
      offset +
      "&limit=" +
      limit +
      filterString;
    const response = yield call(getApi, uri);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_FILTER_HOPS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch hop.", error);
  }
}
function* workfetchAvailHops() {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/hop/hops`;
    yield call(getApi, uri);
    //console.log('FETCH_HOPS',response);
    //   Dispatch the action to the reducers
    // yield put({
    //   type: types.FETCH_HOPS_SUCCESS,
    //   payload: response
    // });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch hop.", error);
  }
}
function* workCreateHop({ values, callback }) {
  try {
    //console.log('values', values);
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/hop/create`;
    const response = yield call(postApi, uri, values);
    // console.log("response", response);
    callback(response);
    // //   Dispatch the action to the reducers
    //   yield put({
    //     type: types.CREATE_HOP_SUCCESS,
    //     payload: response
    //   });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch hop.", error);
  }
}
function* workEditHop({ values, callback }) {
  try {
    //console.log('values', values);
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL + `/hop/update?hopId=${values.id}`;
    const response = yield call(patchApi, uri, values);
    callback(response);
    // //   Dispatch the action to the reducers
    yield put({
      type: types.EDIT_HOP_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch hop.", error);
  }
}
function* workFetchHopById({ id, callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/hop/hop/` + id;
    const response = yield call(getApi, uri);
    //console.log('response',response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_HOP_BYID_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch hop.", error);
  }
}
function* workDeleteHop({ id, callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/hop/delete/` + id;
    const response = yield call(deleteApi, uri);
    //console.log('response',response);
    callback(response);
    //   Dispatch the action to the reducers
    // yield put({
    //   type: types.DELETE_HOP_SUCCESS,
    //   payload: response
    // });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch hop.", error);
  }
}
function* workAllSourceDestination({ callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/hop/source_dest_all`;
    const response = yield call(getApi, uri);
    callback(response);
    //   Dispatch the action to the reducers
    // yield put({
    //   type: types.FETCH_HOPS_SUCCESS,
    //   payload: response
    // });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch hop.", error);
  }
}
function* workFetchHopPresignedUrl({ callback }) {
  try {
    const url =
      process.env.REACT_APP_SERVERURL + "/upload/hopExcelPreSignedURL";
    const response = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.GET_HOP_PRESIGNED_URL_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}

function* workCreateHopViaExcel({ payload, callback }) {
  try {
    const url = process.env.REACT_APP_SERVERURL + "/hop/addViaExcel";
    const response = yield call(postApi, url, payload);
    callback(response);
    yield put({
      type: types.CREATE_HOP_VIA_EXCEL_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}

function* workUploadImages1({ uri, data, headers }) {
  try {
    const response = yield call(putUploadImagesApi, uri, data, headers);
    yield put({
      type: types.UPLOAD_IMAGE_SUCCESS1,
      payload: response,
    });
  } catch (error) {
    console.log(error);
  }
}
/**
 * Watchers
 */

export function* watchFetchHops() {
  yield takeEvery(types.FETCH_HOPS, workfetchHops);
}
export function* watchFetchSourceDestination() {
  yield takeEvery(types.FETCH_SOURCE_DESTINATION, workfetchSourceDestination);
}
export function* watchFetchHopsfilter() {
  yield takeEvery(types.FETCH_FILTER_HOPS, workfetchHopsfilter);
}
export function* watchFetchAvailHops() {
  yield takeEvery(types.FETCH_AVAIL_HOPS, workfetchAvailHops);
}
export function* watchCreateHop() {
  yield takeEvery(types.CREATE_HOP, workCreateHop);
}
export function* watchEditHop() {
  yield takeEvery(types.EDIT_HOP, workEditHop);
}
export function* watchFetchHopById() {
  yield takeEvery(types.FETCH_HOP_BYID, workFetchHopById);
}
export function* watchDeleteHop() {
  yield takeEvery(types.DELETE_HOP, workDeleteHop);
}
export function* watchFetchAllSourceDestination() {
  yield takeEvery(types.FETCH_ALL_SOURCE_DESTINATION, workAllSourceDestination);
}
export function* watchFetchHopPresignedUrl() {
  yield takeEvery(
    types.GET_HOP_PRESIGNED_URL,
    workFetchHopPresignedUrl
  );
}
export function* watchCreateHopViaExcel() {
  yield takeEvery(types.CREATE_HOP_VIA_EXCEL, workCreateHopViaExcel);
}
export function* watchUploadImages1() {
  yield takeEvery(types.UPLOAD_IMAGE1, workUploadImages1);
}
function* HopSaga() {
  yield all([
    fork(watchFetchHops),
    fork(watchFetchHopsfilter),
    fork(watchFetchAvailHops),
    fork(watchCreateHop),
    fork(watchEditHop),
    fork(watchFetchHopById),
    fork(watchDeleteHop),
    fork(watchFetchSourceDestination),
    fork(watchFetchAllSourceDestination),
    fork(watchCreateHopViaExcel),
    fork(watchFetchHopPresignedUrl),
  ]);
}

export default HopSaga;
