// import { mapKeys } from "lodash";
import { types } from "../actionTypes";
const initialState = {
  vehicleType: [],
  fetchId: "",
};
export default (state = { initialState }, action) => {
  // console.log("reduce");
  switch (action.type) {
    case types.FETCH_VEHICLETYPELIST_SUCCESS:
      //   // Create a new state object that uses an AJAX request response and grabs the 'id' property from each object in the response to use as its key
      return { ...state, vehicleType: action.payload };

    case types.SPEC_FILTER_VEHICLETYPE_SUCCESS:
    //   // Create a new state object that uses an AJAX request response and grabs the 'id' property from each object in the response to use as its key
      return { ...state, vehicleTypeSpec: action.payload ,fetchId: "id" + new Date().getTime() };

    case types.SPEC_ADD_VEHICLETYPE:
      return { ...state, newVehicleTypeSpec: action.payload };

    case types.SPEC_UPDATE_VEHICLETYPE:
      return { ...state, updateVehicleTypeSpec: action.payload };

    case types.SPEC_DELETE_VEHICLETYPE:
      return { ...state, deleteVehicleTypeSpec: action.payload };

    case types.SPEC_FETCHSIGNEDURL_VEHICLETYPE:
      return { ...state, fetchSignedUrlVehicleType: action.payload };

    default:
      state = { ...state };
      break;
  }

  return state;
};
