import { types } from "./actionTypes";
export const fetchDropPolicies = (callback) => ({
  type: types.FETCH_POLICIES_LIST,
  callback,
});
export const fetchRoleGrpPolicies = (callback) => ({
  type: types.FETCH_ROLE_GRP_POLICIES,
  callback,
});
export const fetchRoles = (offset, filter, callback) => ({
  type: types.FETCH_ROLES,
  offset,
  filter,
  callback,
});

export const fetchRolesExcel = (offset, filter, callback) => ({
  type: types.FETCH_ROLES_EXCEL,
  offset,
  filter,
  callback,
});

export const fetchRolesfilter = (offset,filters, callback) => ({
  type: types.FETCH_FILTER_ROLES,
  offset,
  filters,
  callback,
});
export const fetchRoleById = (id, callback) => ({
  type: types.FETCH_ROLEBYID,
  id,
  callback,
});
// export const fetchPost = id => ({ type: types.FETCH_POST, id });
export const createRolePolicy = (values, callback) => ({
  type: types.CREATE_ROLE_POLICY,
  values,
  callback,
});
export const editRole = (id, values, callback) => ({
  type: types.EDIT_ROLE,
  id,
  values,
  callback,
});
export const deleteRole = (id, callback) => ({
  type: types.DELETE_ROLE,
  id,
  callback,
});
