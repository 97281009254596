import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import VehiclesReducer from './reducer';
const Vehicle = combineReducers({
  vehicles: VehiclesReducer,
  form: formReducer
});


export default Vehicle;
