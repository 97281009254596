// import { mapKeys } from 'lodash';
import { types } from '../actionTypes';
const initialState = {
  fetchId: "",
  fetchRole: null
};
export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ROLES_SUCCESS:
      return { ...state,'fetchRole': action.payload ,fetchId: "id" + new Date().getTime()};
      case types.FETCH_ROLES_SUCCESS_EXCEL:
        return { ...state,'fetchRole': action.payload ,fetchId: "id" + new Date().getTime()};
    case types.FETCH_ROLE_GRP_POLICIES_SUCCESS:
      return { ...state,'policies': action.payload };
    case types.CREATE_ROLE_POLICY_SUCCESS:
      return { ...state, [action.payload.id]: action.payload };
    case types.DELETE_ROLE_SUCCESS:
      var newState = { ...state };
      delete newState[action.payload.id];
      return newState;
    default:
      state = { ...state };
      break;

  }
  return state;

};
