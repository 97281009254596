import Pagination from "@material-ui/lab/Pagination";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { NotificationManager } from "react-notifications";
import { toUpperCaseWihSpace } from "../../components/Common/utils";
//actions
import {
  getOrders,
  viewInvoiceByOrderId,
  viewOrderById,
  reSyncOrder,
  getOrdersForExcel,
} from "../../store/baxter-order/actions";

import moment from "moment";
import { Button } from "reactstrap";
import Index from "../../components/Common/DataTableNeo/Index";
import {
  AdminHeaderData,
  getLocalStorage,
  searchBar,
} from "./RowHeaders/RowHeaders";
import QuickFilters from "./quckFilters";
import { exportToCSV } from "../common/exportToCsv";
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var limit = 10;
var searchValues = {};
var darkTheme;
const orderStatusMaster = [
  { label: "ORDER PUNCHED", value: "ORDER_PUNCHED" },
  { label: "ORDER RELEASED", value: "ORDER_RELEASED" },
  { label: "INVOICE GENERATED", value: "INVOICE_GENERATED" },
  { label: "ORDER DISPATCHED", value: "ORDER_DISPATCHED" },
  { label: "ORDER DELIVERED", value: "ORDER_DELIVERED" },
];

const sortOrderOption = [
  { label: "ALL ORDER", value: "allOrder" },
  { label: "BACK ORDER", value: "isBackOrder" },
  { label: "CANCELLED ORDER", value: "isCancelledOrder" },
];
class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dataLoading: false,
      offset: 0,
      isOpenTrack: false,
      isOpenHistory: false,
      orderDetail: "",
      fromDate: "",
      toDate: "",
      invoiceByOrderId: "",
      searchFilters: {},
      customerCode: "",
      topFilters: "",
      sortOrderVal: sortOrderOption[0],
      cfaCodes: "",
    };
  }
  maintainFilterValueInTextBox = (searchValuesObj) => {
    const pathName = "/ordersList"; // Set your desired pathname here
    const keys = `myStatusFilter_${pathName}`;
    const updatedstatusFilterObj = {};

    for (const key in searchValuesObj) {
      if (key === "Status") {
        const innerKey = `${key}_${pathName}`;
        const filterStatusLabel = toUpperCaseWihSpace(searchValuesObj[key]);

        updatedstatusFilterObj[innerKey] = {
          label: filterStatusLabel,
          value: searchValuesObj[key],
        };
      }

      window.localStorage.setItem(keys, JSON.stringify(updatedstatusFilterObj));
    }
  };
  componentDidMount() {
    let mountFilterData = window.localStorage.getItem("myFilterData")
      ? JSON.parse(window.localStorage.getItem("myFilterData"))
      : "";
    searchValues = mountFilterData || {};
    this.checkLocalBackCancelled();
    if (
      this.props.location?.state?.filter !== undefined ||
      this.props.location?.state?.topFilters !== undefined
    ) {
      let filters = this.props.location.state.filter;
      let topFilters = this.props.location.state.topFilters;
      let businessUnit = topFilters?.warehouseNumber
        ? topFilters?.warehouseNumber[0]
        : "";
      let FromDate = topFilters?.startDate ? topFilters?.startDate : "";
      let ToDate = topFilters?.endDate ? topFilters?.endDate : "";
      if (filters === "ORDER_CANCELLED") {
        let cancellObj = {
          label: "CANCELLED ORDER",
          value: "isCancelledOrder",
        };
        searchValues = {
          Warehouse: businessUnit ? businessUnit : "",
          FromDate,
          ToDate,
        };
        this.handleSortOrder(cancellObj);
      } else if (filters === "BACK_ORDER") {
        let backOrdObj = { label: "BACK ORDER", value: "isBackOrder" };
        searchValues = {
          Warehouse: businessUnit ? businessUnit : "",
          FromDate,
          ToDate,
        };
        this.handleSortOrder(backOrdObj);
      } else {
        searchValues = {
          Status: filters ? filters : "",
          Warehouse: businessUnit ? businessUnit : "",
          FromDate,
          ToDate,
        };
        this.maintainFilterValueInTextBox(searchValues);
      }
      this.setState({ searchFilters: searchValues }, () => {
        offset = 0;
        window.localStorage.setItem(
          "myFilterData",
          JSON.stringify(this.state.searchFilters)
        );
        window.localStorage.setItem("offset", offset);
        window.history.replaceState(null, "");
        this.getAllData();
      });
    } else {
      offset = 0;
      this.getAllData();
    }
  }
  checkLocalBackCancelled = () => {
    const localStorageData = JSON.parse(localStorage.getItem("myFilterData"));
    const { isBackOrder, isCancelledOrder } = localStorageData || {};

    if (isBackOrder || isCancelledOrder) {
      const value = isBackOrder ? "BACK ORDER" : "CANCELLED ORDER";
      this.setState({ sortOrderVal: { label: value, value: true } });
    } else {
      this.setState({ sortOrderVal: sortOrderOption[0] });
    }
  };
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 0.9;
  }

  getAllData = () => {
    let authUser = JSON.parse(window.localStorage.getItem("authUser"));
    let role = authUser?.data ? authUser?.data?.role?.label : "";
    let storedData = localStorage.getItem("myFilterData")
      ? JSON.parse(localStorage.getItem("myFilterData"))
      : "";
    let offsetData = localStorage.getItem("offset")
      ? localStorage.getItem("offset")
      : "";

    // console.log(storedData, "storedData works");
    if (authUser?.data) {
      let custCode;
      let passCust = "";
      let supervisorCode;
      let passSupervisor = "";
      if (role === "customer") {
        custCode =
          authUser?.data?.customersCode &&
          authUser?.data?.customersCode?.map((res) => {
            return res?.customerCode;
          });
        passCust = custCode.join(",");
      }
      if (role === "supervisor") {
        supervisorCode =
          authUser?.data?.cfaCode &&
          authUser?.data?.cfaCode?.map((ele) => {
            return ele;
          });
        passSupervisor = supervisorCode.join(",");
      }
      this.setState(
        {
          customerCode: passCust,
          cfaCodes: passSupervisor,
          searchFilters: storedData,
          dataLoading: true,
        },
        () => {
          this.props.getOrders(
            offset || offsetData,
            limit,
            this.state.customerCode,
            this.state.cfaCodes,
            this.state.topFilters,
            this.state.searchFilters || storedData,
            (res) => {
              this.setState({ dataLoading: false });
            }
          );
        }
      );
      if (searchBar) {
        searchBar.searchBarData.forEach((obj) => {
          if (obj.field === "Status") {
            obj.options = orderStatusMaster;
          }
        });
      }
    }

    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };
  componentWillUnmount = () => {
    offset = 0;
  };
  getAllDataReset = () => {
    offset = 0;
    window.localStorage.setItem("offset", offset);
    this.getAllData();
  };
  handleChangePage = (event, val) => {
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    window.localStorage.setItem("offset", offset);
    this.setState({ loading: true }, () => {
      this.getAllData();
    });
  };
  handleChange = (dateValues) => {
    searchValues["FromDate"] =
      dateValues[0] !== null
        ? dateValues[0]
          ? moment(dateValues[0]).format("YYYY-MM-DD")
          : moment(dateValues).format("YYYY-MM-DD")
        : null;
    searchValues["ToDate"] =
      dateValues[1] !== null
        ? moment(dateValues[1]).format("YYYY-MM-DD")
        : null;
    this.setState({ searchFilters: searchValues }, () => {
      offset = 0;
      window.localStorage.setItem(
        "myFilterData",
        JSON.stringify(this.state.searchFilters)
      );
      window.localStorage.setItem("offset", offset);
      this.getAllData();
    });
  };

  viewOrderData = (ele) => {
    let id = ele?._id;
    // window.localStorage.setItem(
    //   "myFilterData",
    //   JSON.stringify(this.state.searchFilters)
    // );
    window.localStorage.setItem("offset", offset);

    this.props.viewOrderById(
      {
        offset: offset,
        limit: 10,
      },
      id,
      (data) => {
        this.props.history.push({
          pathname: "/viewOrders",
          state: { currentOrder: ele },
        });
      }
    );
  };
  resync = (ele) => {
    let syncOrderPayload = {
      orderId: ele?._id,
    };
    this.setState({ dataLoading: true }, () => { });
    this.props.reSyncOrder(syncOrderPayload, (res) => {
      if (res?.success) {
        NotificationManager.success(res["message"], "Success");
        this.getAllData();
        this.setState({ dataLoading: false });
      } else {
        NotificationManager.error(res["message"], "Error");
        this.setState({ dataLoading: false });
      }
    });
  };
  //order History
  toggle3 = (data) => {
    window.localStorage.setItem(
      "myFilterData",
      JSON.stringify(this.state.searchFilters)
    );
    window.localStorage.setItem("offset", offset);
    this.props.history.push({
      pathname: "/orderHistory",
      state: { currentOrder: data },
    });
  };

  //track order
  toggle4 = (data) => {
    this.props.history.push({
      pathname: "/trackOrder",
      state: { currentOrder: data },
    });
  };

  actions = (actionString) => {
    var darkMode = document.body.classList.contains("dark__mode");

    return (
      <div className="dropdown ">
        <Button className="iconButtons dropbtn actionButtonsDark">
          <svg
            width="18"
            height="4"
            viewBox="0 0 18 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 2C10 1.44772 9.55228 1 9 1C8.44772 1 8 1.44772 8 2C8 2.55228 8.44772 3 9 3C9.55228 3 10 2.55228 10 2Z"
              stroke="#2800FC"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1 1.44772 1 2C1 2.55228 1.44772 3 2 3C2.55228 3 3 2.55228 3 2Z"
              stroke="#2800FC"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 2C17 1.44772 16.5523 1 16 1C15.4477 1 15 1.44772 15 2C15 2.55228 15.4477 3 16 3C16.5523 3 17 2.55228 17 2Z"
              stroke="#2800FC"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Button>
        <div className="dropdown-content contentDark">
          <div
            className="action-button actionDspan"
            onClick={() => {
              this.viewOrderData(actionString);
            }}
          >
            <span>View Details</span>
          </div>
          <div
            className="action-button actionDspan"
            onClick={() => {
              this.resync(actionString);
            }}
          >
            Refresh Order
          </div>
          <div
            className="action-button actionDspan"
            onClick={() => {
              this.toggle3(actionString);
              // routingToIndentData.routingToProvideDetailModel(ele);
            }}
          >
            Order History
          </div>
        </div>
      </div>
    );
  };
  searchValueAssignerFunction = (name, value, type) => {
    if (typeof value === "text" || type === "text") {
      searchValues[name] = value?.replace(/^\s+/, "");
      offset = 0;
      window.localStorage.setItem("offset", offset);
      this.setState({ searchFilters: searchValues }, () => {
        window.localStorage.setItem(
          "myFilterData",
          JSON.stringify(this.state.searchFilters)
        );
        window.localStorage.setItem("offset", offset);
      });
    }
    if (type === "date" || type === "select") {
      searchValues[name] = value;
      this.setState({ searchFilters: searchValues }, () => {
        offset = 0;
        window.localStorage.setItem(
          "myFilterData",
          JSON.stringify(this.state.searchFilters)
        );
        window.localStorage.setItem("offset", offset);
        this.getAllData();
      });
    }
  };

  reset = (ordertopFilter) => {
    const { FromDate, ToDate, isBackOrder, isCancelledOrder } = searchValues;
    offset = 0;
    if (ordertopFilter) {
      searchValues = {
        ...searchValues,
        FromDate: "",
        ToDate: "",
        isBackOrder: "",
        isCancelledOrder: "",
      };
      const keysToRemove = [
        "FromDate",
        "ToDate",
        "isBackOrder",
        "isCancelledOrder",
      ];
      const myLocalObject =
        JSON.parse(localStorage.getItem("myFilterData")) || {};
      keysToRemove.forEach((key) => delete myLocalObject[key]);
      localStorage.setItem("myFilterData", JSON.stringify(myLocalObject));
      window.localStorage.setItem("offset", offset);
      this.setState({ sortOrderVal: sortOrderOption[0] });
    } else {
      searchValues = {
        FromDate: FromDate || "",
        ToDate: ToDate || "",
        isBackOrder: isBackOrder ?? "",
        isCancelledOrder: isCancelledOrder ?? "",
      };
      localStorage.setItem("myFilterData", JSON.stringify(searchValues));
      window.localStorage.setItem("offset", offset);
    }

    this.setState({ searchFilters: searchValues }, () => {
      this.getAllData();
      window.history.replaceState(null, "");
    });
  };

  handleSortOrder = (sortData) => {
    // let topFilterObj = {};
    const { value, label } = sortData;
    searchValues = {
      ...searchValues,
      isBackOrder: value === "isBackOrder" ? true : "",
      isCancelledOrder: value === "isCancelledOrder" ? true : "",
    };
    this.setState(
      {
        searchFilters: searchValues,
        sortOrderVal: { label: label, value: value },
      },
      () => {
        window.localStorage.setItem(
          "myFilterData",
          JSON.stringify(this.state.searchFilters)
        );
        window.localStorage.setItem("offset", offset);
        this.getAllData();
      }
    );
  };
  ellipse = (shipToName) => {
    return (
      <div className="tooltipIcon">
        <span
          className="d-inline-block text-truncate"
          style={{ maxWidth: "90px" }}
        >
          {shipToName}
        </span>
        <span
          className="tooltipIcontext"
        // style={{ width: "85px", marginLeft: "-14px" }}
        >
          &nbsp;{shipToName}&nbsp;
        </span>
      </div>
    );
  };
  exportExcelData = () => {
    this.setState({ dataLoading: true });

    let storedData = localStorage.getItem("myFilterData")
      ? JSON.parse(localStorage.getItem("myFilterData"))
      : "";
    this.props.getOrdersForExcel(
      0,
      100000,
      this.state.customerCode,
      this.state.cfaCodes,
      this.state.topFilters,
      this.state.searchFilters || storedData,
      (res) => {
        this.setState({ dataLoading: false });
        let exportData = [];
        if (res.success) {
          if (res?.data?.docs?.length > 0) {
            res.data.docs.map((ele) => {
              let status = [];

              status.push(ele?.status?.replace(/_/g, " "));

              if (ele?.hdCd === "C1" && ele?.isBackOrder) {
                status.push("CREDIT HOLD + BACK ORDER");
              } else if (ele?.hdCd === "C1") {
                status.push("CREDIT HOLD");
              } else if (ele?.isBackOrder) {
                status.push("BACK ORDER");
              }
              if ((ele?.hdCd === "C1") & ele?.isCancelledOrder) {
                status.push("CREDIT HOLD + ORDER CANCELLED");
              } else if (ele?.isCancelledOrder) {
                status.push("ORDER CANCELLED");
              }
              if (ele?.hdCd === "CH") {
                status.push("(DL Expired)");
              }
              exportData.push({
                "Order No": ele?.orderNumber ? ele?.orderNumber : "N/A",
                "Order Type": ele?.orderType ? ele?.orderType : "N/A",
                "Billed To Name": (ele?.customerDetails?.length> 0 ? ele?.customerDetails[0]?.customerType + " , " : "") +
                (ele?.billToDetails?.billedToName ? ele?.billToDetails?.billedToName : "N/A"),
                "Billed To City": ele?.billToDetails?.billedToCity
                  ? ele?.billToDetails?.billedToCity
                  : "N/A",
                "Order Date": ele?.orderDate ? ele?.orderDate : "N/A",
                // hdcd:ele?.hdCd ? ele?.hdCd :"N/A",
                Warehouse: ele?.businessUnit ? ele?.businessUnit : "N/A",
                Status: status?.length > 0 ? status.join(",") : "N/A",
              });
            });
          }
          if (exportData?.length > 0) {
            exportToCSV([...exportData], "Orders");
          } else {
            exportData.push({
              "Order No": "",
              "Order Type": "",
              "Billed To Name": "",
              "Billed To City": "",
              "Order Date": "",
              // hdcd:ele?.hdCd ? ele?.hdCd :"N/A",
              Warehouse: "",
              Status: "",
            });
            exportToCSV([...exportData], "Orders");
          }
        } else {
          NotificationManager.error(res?.message, "error");
        }
      }
    );
  };

  render() {
    // let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    darkTheme = mode === "dark__mode" ? true : false;
    // const { name, phoneNo, loading } = this.state;
    const { getOrdersData } = this.props;
    const localStorageData = JSON.parse(localStorage.getItem("OrdersData"));
    const orderData = navigator.onLine
      ? getOrdersData?.data?.docs
      : localStorageData?.data?.docs;
    const orderOffset = localStorage.getItem("offset")
      ? JSON.parse(localStorage.getItem("offset"))
      : "";
    let rows = [];
    if (Array.isArray(orderData)) {
      offset = orderOffset ? orderOffset : offset;
      totCnt = navigator.onLine
        ? getOrdersData?.data?.length
        : localStorageData?.data?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = navigator.onLine
        ? getOrdersData?.data?.offset + getOrdersData?.data?.docs?.length
        : localStorageData?.data?.offset + localStorageData?.data?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      orderData.forEach((ele) => {
        let status;
        if (ele?.status == "ORDER_PUNCHED" || ele?.status == "Unpaid") {
          status = (
            <>
              <span
                style={{
                  color: "#E19600",
                  fontWeight: 700,
                  fontSize: "15px",
                }}
              >
                {" "}
                {ele?.status?.replace(/_/g, "    ")}
              </span>
              <div>
                {ele?.hdCd === "C1" ? (
                  <div>
                    CREDIT HOLD
                    {!ele?.isBackOrder && !ele?.isCancelledOrder ? (
                      ""
                    ) : ele?.isBackOrder ? (
                      "+ BACK ORDER"
                    ) : ele?.isCancelledOrder ? (
                      <span className="boldDark-red "> + ORDER CANCELLED</span>
                    ) : (
                      ""
                    )}
                  </div>
                ) : ele?.hdCd === "CH" ? (
                  "(DL Expired)"
                ) : ele?.isBackOrder ? (
                  "(BACK ORDER)"
                ) : ele?.isCancelledOrder ? (
                  <span className="boldDark-red ">(ORDER CANCELLED)</span>
                ) : (
                  ""
                )}
              </div>
            </>
          );
        }
        if (ele?.status == "ORDER_DISPATCHED") {
          status = (
            <>
              <span
                style={{
                  color: "#7701D3",
                  fontWeight: 700,
                  fontSize: "15px",
                }}
              >
                {" "}
                {ele?.status?.replace(/_/g, "    ")}
              </span>
              <div>
                {ele?.isBackOrder ? (
                  "(BACK ORDER)"
                ) : ele?.isCancelledOrder ? (
                  <span className="boldDark-red ">(ORDER CANCELLED)</span>
                ) : (
                  ""
                )}
              </div>
            </>
          );
        }
        if (ele?.status == "ORDER_RELEASED") {
          status = (
            <>
              <span
                style={{
                  color: "#FF6F00",
                  fontWeight: 700,
                  fontSize: "15px",
                }}
              >
                {" "}
                {ele?.status?.replace(/_/g, "    ")}
              </span>
              <div>
                {ele?.isBackOrder ? (
                  "(BACK ORDER)"
                ) : ele?.isCancelledOrder ? (
                  <span className="boldDark-red ">(ORDER CANCELLED)</span>
                ) : (
                  ""
                )}
              </div>
            </>
          );
        }
        if (ele?.status == "INVOICE_GENERATED") {
          status = (
            <>
              <span
                style={{
                  color: "#AC1000",
                  fontWeight: 700,
                  fontSize: "15px",
                }}
              >
                {" "}
                {ele?.status?.replace(/_/g, "    ")}
              </span>
              <div>
                {ele?.isBackOrder ? (
                  "(BACK ORDER)"
                ) : ele?.isCancelledOrder ? (
                  <span className="boldDark-red ">(ORDER CANCELLED)</span>
                ) : (
                  ""
                )}
              </div>
            </>
          );
        }
        if (ele?.status == "ORDER_DELIVERED") {
          status = (
            <>
              <span
                style={{
                  color: "#006400",
                  fontWeight: 700,
                  fontSize: "15px",
                }}
              >
                {" "}
                {ele?.status?.replace(/_/g, "    ")}
              </span>
              <div>
                {ele?.isBackOrder ? (
                  "(BACK ORDER)"
                ) : ele?.isCancelledOrder ? (
                  <span className="boldDark-red ">(ORDER CANCELLED)</span>
                ) : (
                  ""
                )}
              </div>
            </>
          );
        }
        rows.push({
          orderNo: ele?.orderNumber ? ele?.orderNumber : "N/A",
          orderType: ele?.orderType ? ele?.orderType : "N/A",
          billedToName: (ele?.customerDetails?.length> 0 ? ele?.customerDetails[0]?.customerType + " , " : "") +
          (ele?.billToDetails?.billedToName ? ele?.billToDetails?.billedToName : "N/A"),
          billedToCity: ele?.billToDetails?.billedToCity
            ? this.ellipse(ele?.billToDetails?.billedToCity)
            : "N/A",
          billedToStateName: ele?.billToDetails?.billedToStateName
          ? ele?.billToDetails?.billedToStateName
          : "N/A",
          orderDate: ele?.orderDate ? ele?.orderDate : "N/A",
          // hdcd:ele?.hdCd ? ele?.hdCd :"N/A",
          orderStage: status ? status : "N/A",
          businessUnit: ele?.businessUnit ? ele?.businessUnit : "N/A",
          action: this.actions(ele),
        });
      });
    }

    return (
      <>
        {getLocalStorage()}
        <QuickFilters
          reset={this.reset}
          handleChange={this.handleChange}
          handleSortOrder={this.handleSortOrder}
          sortOrderOption={sortOrderOption}
          sortOrderVal={this.state.sortOrderVal}
          dashTopFilter={this.props.location?.state?.topFilters}
          toggleExcel={this.exportExcelData}
        />
        <Container
          aria-hidden="true"
          fluid
          className={`${darkTheme ? "darkBackgroundList mobilepPadding" : "mobilepPadding"
            }`}
        >
          <div className="orderList">
            <Index
              {...this.props}
              headers={AdminHeaderData}
              data={rows}
              searchable={true}
              searchBar={searchBar}
              fetchId={this.props.fetchId}
              reset={this.reset}
              searchValueAssignerFunction={this.searchValueAssignerFunction}
              getAllData={this.getAllDataReset}
              dataLength={90}
              rowHeight={"64px"}
              dataLoading={this.state.dataLoading}
              classList="3"
              textInput="inputIndex"
              resetCol="3"
            />
          </div>

          {totCnt !== 0 && !this.state.dataLoading && rows?.length > 0 ? (
            <Row
              xs={12}
              style={{ color: darkTheme ? "#9d9d9d" : "#0C0C0C" }}
              className="mb-2 paginationRow"
            >
              <Col xs={7} sm={5} md={4} className="span-col-pagenation">
                <div className="float-left">
                  <Pagination
                    className="desktop-pagination"
                    size="medium"
                    shape="rounded"
                    page={offset / 10 + 1}
                    count={totPages}
                    color="primary"
                    onChange={this.handleChangePage}
                    defaultPage={1}
                    siblingCount={1}
                  />
                  <Pagination
                    className="mobile-pagination"
                    size="small"
                    shape="rounded"
                    page={offset / 10 + 1}
                    count={totPages}
                    color="primary"
                    onChange={this.handleChangePage}
                    defaultPage={1}
                    siblingCount={1}
                  />
                </div>
              </Col>
              <Col
                className="mt-2 spanPagenationentries span-col-pagenation"
                xs={5}
                sm={7}
                md={8}
              >
                {" "}
                <span>
                  Showing {fromVal} to {toVal} of {totCnt} entries
                </span>
              </Col>
            </Row>
          ) : null}
        </Container>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
    getOrdersData: state?.orderListReducer?.getOrderListData,
    fetchId: state.orderListReducer?.fetchId,
  };
};

export default connect(mapStateToProps, {
  getOrders,
  viewOrderById,
  reSyncOrder,
  viewInvoiceByOrderId,
  getOrdersForExcel,
})(Order);
