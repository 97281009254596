import React, { Component } from 'react'

export class SingleValueOrArraysValue extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: '',
            name: '',
        }
    }
    componentDidMount() {
        this.setState({ value: this.props.ele, name: this.props.name })
    }
    componentDidUpdate(prevValue) {
        if(this.props !== prevValue) this.setState({ value: this.props.ele, name: this.props.name })
    }
    render() {
        let value = this.state.value;
        if (Array.isArray(value)) {
            const transformations = {
                'Order Type': el => el.orderType,
                'Order Number': el => el.orderNumber,
                'Bill To Name': el => el?.shipToName?.toLowerCase(),
                'Bill To City': el => el?.shipToCity?.toLowerCase(),
            };
            const transformFunction = transformations[this.props.name];
            if (transformFunction) {
                value = value.map(transformFunction);
            }
            if (['Transporter', "EWaybill"].includes(this.props?.name)) value = value.filter(el => el != "")
            if (value.length < 1) value = ""
        }
        return (
            <>
                {
                    Array.isArray(value) ?
                        (<>
                            <div
                                className='text-truncate'
                                style={{
                                    maxWidth: this.props?.maxWidth?.length ? this.props?.maxWidth : "90px"
                                }}>
                                {value[0] ?? value[0]}
                            </div>
                            <div
                                className='text-truncate'
                                style={{
                                    maxWidth: this.props?.maxWidth?.length ? this.props?.maxWidth : "90px"
                                }}>
                                {value[1] ?? value[1]}
                            </div>
                            {value?.length > 2 &&
                                <div className="tooltipIcon">
                                    <span
                                        className="d-inline-block text-truncate"
                                        style={{ maxWidth: ['Order Number', 'Order Type'].includes(this.props?.name) ? "50px" : "90px" }}
                                    >
                                        {`+${value.length - 2} ${this.state.name}`}
                                    </span>
                                    <span className="tooltipIcontext" style={{ width: '100px' }}>
                                        {value.map((el, i) => (
                                            <div>
                                                {i > 1 && el}
                                            </div>
                                        ))}
                                    </span>
                                </div>
                            }
                        </>)
                        : value ? <div style={{ ...(this.props?.maxWidth && { maxWidth: this.props?.maxWidth }) }} className="text-truncate">
                            <div className="tooltipIcon">
                                <span>
                                    {value}
                                </span>
                                <span className="tooltipIcontext" >
                                    &nbsp;{value}&nbsp;
                                </span>
                            </div>
                        </div>
                            : 'N/A'
                }
            </>
        )
    }
}

export default SingleValueOrArraysValue