let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  AdminHeaderData = {
    columns: [
      {
        label: "Customer Name",
        field: "customerName",
        sort: "asc",
        width: 100,
      },

      {
        label: "Customer Number",
        field: "customerNumber",
        sort: "asc",
        width: 100,
      },
      {
        label: "Ship To Code",
        field: "shipToCode",
        sort: "asc",
        width: 100,
      },
      {
        label: "Address",
        field: "address",
        sort: "asc",
        width: 200,
      },
      {
        label: "City",
        field: "city",
        sort: "asc",
        width: 100,
      },
      {
        label: "Postal Code",
        field: "postalCode",
        sort: "asc",
        width: 100,
      },
      {
        label: "State",
        field: "state",
        sort: "asc",
        width: 100,
      },
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var AdminHeaderData = {
  columns: [
    {
      label: "Customer Name",
      field: "customerName",
      sort: "asc",
      width: 100,
    },

    {
      label: "Customer Number",
      field: "customerNumber",
      sort: "asc",
      width: 100,
    },
    {
      label: "Ship To Code",
      field: "shipToCode",
      sort: "asc",
      width: 100,
    },
    {
      label: "Address",
      field: "address",
      sort: "asc",
      width: 200,
    },
    {
      label: "City",
      field: "city",
      sort: "asc",
      width: 100,
    },
    {
      label: "Postal Code",
      field: "postalCode",
      sort: "asc",
      width: 100,
    },
    {
      label: "State",
      field: "state",
      sort: "asc",
      width: 100,
    },
  ],
};
export const searchBar = {
  searchBarData: [{}],
};
