import React, { Component } from "react";
import { Card,Button } from "reactstrap";
import exportDarkIcon from "./assets/external-linkDark.png";
import exportIcon from "./assets/external-link.png";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class QuickFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userLangs: undefined,
    };
  }
  componentDidUpdate() {
    if (
      this.state.userLangs === undefined &&
      (this.state.userLangs !== userLang || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  }
  render() {
    // console.log("testing reset",this.state.userLangs?.common?.RESET)
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let userPreferredLang = window.localStorage.getItem("userPreferredLang");
    let darkMode = this.props?.mode;
    return (
      <React.Fragment>
        <Card className="darkBgQuickFilter">
          <div className="quickStylesBar driverStyle darkBgQuickFilter">
            <div className="QuickParent">
              <button
                className="uploadButton"
                onClick={() => this.props.openModal()}
              >
                Upload Excel
              </button>

              <div className="tooltipIcon">
                <Button
                  disabled={!navigator.onLine}
                  onClick={() => this.props.toggleExcel()}
                  className={`iconButtons ${darkMode ? "iconDarkButtons" : ""}`}
                >
                  <img
                    style={{ margin: "-4px" }}
                    src={darkMode ? exportDarkIcon : exportIcon}
                    alt="exp"
                  />
                </Button>
                <span className="tooltipIcontext" style={{ left: "10%" }}>
                  &nbsp;
                  {this.state.userLangs?.invoice?.DOWNLOAD_EXCEL ??
                    "Download Excel"}
                  &nbsp;
                </span>
              </div>
            </div>
        
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default QuickFilters;
