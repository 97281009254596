// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import { types } from "./actionTypes";

// import {
// 	changeSidebarType as changeSidebarTypeAction,
// 	changeTopbarTheme as changeTopbarThemeAction,
// } from "./actions";
import {
  getEmployees,
  createEmployee,
  deleteEmployee,
} from "../../helpers/Employee/employee_helper";

// import axios from "axios";
// const instance = axios.create();

function* workfetchEmployees() {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/employee/employees`;
    const response = yield call(getEmployees, uri);
    //console.log('FETCH_DEALERS',response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_EMPLOYEES_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch employee.", error);
  }
}
// function* workfetchAvailEmployees() {
//   try {
//     // Try to call the API
//     const uri = process.env.REACT_APP_SERVERURL + `/employee/employees`;
//     const response = yield call(getEmployees, uri);
//     //console.log('FETCH_DEALERS',response);
//     //   Dispatch the action to the reducers
//     yield put({
//       type: types.FETCH_DEALERS_SUCCESS,
//       payload: response,
//     });
//   } catch (error) {
//     // Act on the error
//     console.log("Request failed! Could not fetch employee.", error);
//   }
// }
function* workCreateEmployee({ values, callback }) {
  try {
    //console.log('values', values);
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/employee/create`;
    const response = yield call(createEmployee, uri, values);
    //console.log('response',response);
    callback();
    //   Dispatch the action to the reducers
    yield put({
      type: types.CREATE_DEALER_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch employee.", error);
  }
}
function* workDeleteEmployee({ id, callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/employee/delete/` + id;
    const response = yield call(deleteEmployee, uri);
    //console.log('response',response);
    callback();
    //   Dispatch the action to the reducers
    yield put({
      type: types.DELETE_EMPLOYEE_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch employee.", error);
  }
}
/**
 * Watchers
 */

export function* watchFetchEmployees() {
  yield takeEvery(types.FETCH_EMPLOYEES, workfetchEmployees);
}
// export function* watchFetchAvailEmployees() {
//   yield takeEvery(types.FET, workfetchAvailEmployees);
// }
export function* watchCreateEmployee() {
  yield takeEvery(types.CREATE_EMPLOYEE, workCreateEmployee);
}
export function* watchDeleteEmployee() {
  yield takeEvery(types.DELETE_EMPLOYEE, workDeleteEmployee);
}
function* EmployeeSaga() {
  yield all([
    fork(watchFetchEmployees),
    fork(watchCreateEmployee),
    fork(watchDeleteEmployee),
  ]);
}

export default EmployeeSaga;
