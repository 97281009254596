import React, { Component } from "react";
import { Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import { connect } from "react-redux";
import { Form, FormGroup, Label, Input, Button, Container } from "reactstrap";
// import { viewOrderById } from "../../store/baxter-order/actions";
import { Grid } from "@material-ui/core";
import { CloseIcon } from "../../components/Common/CommonIcons";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class ProductDetail extends Component {
  constructor(props) {
    super();
    this.state = {
      mode: "",
      productData: "",
    };
  }

  componentDidMount = () => {
    this.getData();
  };
  getData = () => {
    if (this.props?.location?.state?.currentProduct) {
      let data = this.props?.location?.state?.currentProduct;
      // console.log(data, "data");
      this.setState(
        {
          productData: data,
        },
        () => {
          // console.log(this.state.productData, "pp");
        }
      );
    }
  };

  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let darkState = this.props.darkTheme;
    const { mode } = this.state;
    const modalStyle = {
      height: "100%",
      width: "100%",
      backgroundColor: darkState ? "#1B1C31" : "#ffffff",
      padding: "25px",
      position: "relative",
      border: darkState ? "border: 0.5px solid #616161" : "#ffffff",
    };
    const headerStyle = {
      position: "relative",
      fontFamily: "Roboto",
      fontWeight: "bold",
      fontSize: "20px",
      lineHeight: "23px",
      color: darkState ? "#9D9D9D" : "#0C0C0C",
      left: "0px",
    };
    const closeStyle = {
      position: "relative",
      // color: darkState ? "#B7A9FF" : "#2800fc",
      width: "20px",
      marginRight: "0px",
    };
    const rowsTopSpaceStyle = { marginTop: "0px" };
    const dataBoxStyle = {
      height: "100%",
      overflowY: "scroll",
      overflowX: "clip",
      padding: "5%",
      marginTop:"2px"
      
    };
    const oddRowStyle = {
      minHeight: "57px",
      width: "101%",
      backgroundColor: darkState ? "#24243C" : "#ffffff",
      border: "0.5px solid rgba(185, 185, 185, 0.5)",
      borderRadius: "5px",
      margin: "auto",
      marginTop: "4px",

      marginRight: "0px",
      //position:'relative',
    };
    const evenRowStyle = {
      minHeight: "57px",
      width: "101%",
      backgroundColor: darkState ? "#2E2E46" : "#F1F5F7",
      border: "0.5px solid rgba(185, 185, 185, 0.5)",
      borderRadius: "5px",
      margin: "auto",
      marginTop: "4px",

      marginRight: "0px",
      //position:'relative',
    };

    const rowHeaderStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "15px",
      color: darkState ? "#9D9D9D" : "#05284E",
      lineHeight: "18px",
      margin: "auto",
      paddingLeft: "1.5vw",
    };
    const rowSeparaterStyle = {
      borderLeft: "0.5px solid rgba(185, 185, 185, .5)",
      height: "100%",
    };
    const rowValueStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "13px",
      color: darkState ? "#9D9D9D" : "#0C0C0C",
      margin: "auto",
      flex: "wrap",
    };

    const { productData } = this.state;
    return (
      <React.Fragment>
        <Container fluid={true} className="mt-2 ">
        <Row>
            <Col xs={9} className="d-flex flex-row bd-highlight mb-2 ">
              <Button
                style={{
                  width: "60px",
                  height: "36px",
                  backgroundColor: "#3e3e3e",
                }}
                color="secondary"
                size="sm"
                onClick={() =>
                  this.props.history.push({
                    pathname: "/order/products",
                  })
                }
              >
                <i className="fas fa-arrow-circle-left fa-2x"></i>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xl="12" className="p-1">
              <Card style={{ width: "100%" }}>
                
                <div style={dataBoxStyle}>
                  <div
                   
                    style={{
                      height: "100%",
                      paddingBottom: "20px",
                    }}
                  >
                    <Grid
                      className="shadow indentData-hover"
                      container
                      style={oddRowStyle}
                    >
                      <Grid
                        // className=""
                        item
                        xs={3}
                        md={6}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                        Order Number
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{
                          padding: "2vh 0px",
                        }}
                      >
                        <div style={rowSeparaterStyle}></div>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        md={5}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {productData?.orders?.orderNumber}
                      </Grid>
                    </Grid>

                    <Grid
                      className="  shadow indentData-hover"
                      container
                      style={evenRowStyle}
                    >
                      <Grid
                        item
                        xs={3}
                        md={6}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                        Description
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{
                          padding: "2vh 0px",
                        }}
                      >
                        <div style={rowSeparaterStyle}></div>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        md={5}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {productData?.description}
                      </Grid>
                    </Grid>

                    <Grid
                      className="  shadow indentData-hover"
                      container
                      style={oddRowStyle}
                    >
                      <Grid
                        item
                        xs={3}
                        md={6}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                        DescriptionLine2
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{
                          padding: "2vh 0px",
                        }}
                      >
                        <div style={rowSeparaterStyle}></div>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        md={5}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {productData?.descriptionLine2}
                      </Grid>
                    </Grid>
                    <Grid
                      className="  shadow indentData-hover"
                      container
                      style={evenRowStyle}
                    >
                      <Grid
                        item
                        xs={3}
                        md={6}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                        DoTy
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{
                          padding: "2vh 0px",
                        }}
                      >
                        <div style={rowSeparaterStyle}></div>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        md={5}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {productData?.doTy}
                      </Grid>
                    </Grid>
                    <Grid
                      className="  shadow indentData-hover"
                      container
                      style={oddRowStyle}
                    >
                      <Grid
                        item
                        xs={3}
                        md={6}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                        Document Number
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{
                          padding: "2vh 0px",
                        }}
                      >
                        <div style={rowSeparaterStyle}></div>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        md={5}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {productData?.documentNumber}
                      </Grid>
                    </Grid>

                    <Grid
                      className="  shadow indentData-hover"
                      container
                      style={evenRowStyle}
                    >
                      <Grid
                        item
                        xs={3}
                        md={6}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                        Extended Price
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{
                          padding: "2vh 0px",
                        }}
                      >
                        <div style={rowSeparaterStyle}></div>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        md={5}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {productData?.extendedPrice}
                      </Grid>
                    </Grid>
                    <Grid
                      className="  shadow indentData-hover"
                      container
                      style={oddRowStyle}
                    >
                      <Grid
                        item
                        xs={3}
                        md={6}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                        Last Stat
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{
                          padding: "2vh 0px",
                        }}
                      >
                        <div style={rowSeparaterStyle}></div>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        md={5}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {productData?.lastStat}
                      </Grid>
                    </Grid>

                    <Grid
                      className="  shadow indentData-hover"
                      container
                      style={evenRowStyle}
                    >
                      <Grid
                        item
                        xs={3}
                        md={6}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                        Lst Description
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{
                          padding: "2vh 0px",
                        }}
                      >
                        <div style={rowSeparaterStyle}></div>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        md={5}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {productData?.lstDescription}
                      </Grid>
                    </Grid>

                    <Grid
                      className="  shadow indentData-hover"
                      container
                      style={oddRowStyle}
                    >
                      <Grid
                        item
                        xs={3}
                        md={6}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                        Next Stat
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{
                          padding: "2vh 0px",
                        }}
                      >
                        <div style={rowSeparaterStyle}></div>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        md={5}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {productData?.nextStat}
                      </Grid>
                    </Grid>

                    <Grid
                      className="  shadow indentData-hover"
                      container
                      style={evenRowStyle}
                    >
                      <Grid
                        item
                        xs={3}
                        md={6}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                        Next Description
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{
                          padding: "2vh 0px",
                        }}
                      >
                        <div style={rowSeparaterStyle}></div>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        md={5}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {productData?.nxtDescription}
                      </Grid>
                    </Grid>

                    <Grid
                      className="  shadow indentData-hover"
                      container
                      style={oddRowStyle}
                    >
                      <Grid
                        item
                        xs={3}
                        md={6}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                        PrUm
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{
                          padding: "2vh 0px",
                        }}
                      >
                        <div style={rowSeparaterStyle}></div>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        md={5}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {productData?.prUm}
                      </Grid>
                    </Grid>

                    <Grid
                      className="  shadow indentData-hover"
                      container
                      style={evenRowStyle}
                    >
                      <Grid
                        item
                        xs={3}
                        md={6}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                        Quantity Back Ordered
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{
                          padding: "2vh 0px",
                        }}
                      >
                        <div style={rowSeparaterStyle}></div>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        md={5}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {productData?.quantityBackordered}
                      </Grid>
                    </Grid>
                    <Grid
                      className="  shadow indentData-hover"
                      container
                      style={oddRowStyle}
                    >
                      <Grid
                        item
                        xs={3}
                        md={6}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                        Quantity Canceled
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{
                          padding: "2vh 0px",
                        }}
                      >
                        <div style={rowSeparaterStyle}></div>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        md={5}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {productData?.quantityCanceled}
                      </Grid>
                    </Grid>

                    <Grid
                      className="  shadow indentData-hover"
                      container
                      style={evenRowStyle}
                    >
                      <Grid
                        item
                        xs={3}
                        md={6}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                        Quantity Ordered
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{
                          padding: "2vh 0px",
                        }}
                      >
                        <div style={rowSeparaterStyle}></div>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        md={5}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {productData?.quantityOrdered}
                      </Grid>
                    </Grid>

                    <Grid
                      className="shadow indentData-hover"
                      container
                      style={oddRowStyle}
                    >
                      <Grid
                        // className=""
                        item
                        xs={3}
                        md={6}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                        Quantity Shipped
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{
                          padding: "2vh 0px",
                        }}
                      >
                        <div style={rowSeparaterStyle}></div>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        md={5}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {productData?.quantityShipped}
                      </Grid>
                    </Grid>

                    <Grid
                      className="  shadow indentData-hover"
                      container
                      style={evenRowStyle}
                    >
                      <Grid
                        item
                        xs={3}
                        md={6}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                        SecUm
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{
                          padding: "2vh 0px",
                        }}
                      >
                        <div style={rowSeparaterStyle}></div>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        md={5}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {productData?.secUm}
                      </Grid>
                    </Grid>

                    <Grid
                      className="  shadow indentData-hover"
                      container
                      style={oddRowStyle}
                    >
                      <Grid
                        item
                        xs={3}
                        md={6}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                        Second Item Number
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{
                          padding: "2vh 0px",
                        }}
                      >
                        <div style={rowSeparaterStyle}></div>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        md={5}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {productData?.secondItemNumber}
                      </Grid>
                    </Grid>
                    <Grid
                      className="  shadow indentData-hover"
                      container
                      style={evenRowStyle}
                    >
                      <Grid
                        item
                        xs={3}
                        md={6}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                        Secondary Qty Ordered
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{
                          padding: "2vh 0px",
                        }}
                      >
                        <div style={rowSeparaterStyle}></div>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        md={5}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {productData?.secondaryQtyOrdered}
                      </Grid>
                    </Grid>
                    <Grid
                      className="  shadow indentData-hover"
                      container
                      style={oddRowStyle}
                    >
                      <Grid
                        item
                        xs={3}
                        md={6}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                        Unit List Price
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{
                          padding: "2vh 0px",
                        }}
                      >
                        <div style={rowSeparaterStyle}></div>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        md={5}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {productData?.unitListPrice}
                      </Grid>
                    </Grid>

                    <Grid
                      className="  shadow indentData-hover"
                      container
                      style={evenRowStyle}
                    >
                      <Grid
                        item
                        xs={3}
                        md={6}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                        Unit Price
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{
                          padding: "2vh 0px",
                        }}
                      >
                        <div style={rowSeparaterStyle}></div>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        md={5}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {productData?.unitPrice}
                      </Grid>
                    </Grid>
                    <Grid
                      className="  shadow indentData-hover"
                      container
                      style={oddRowStyle}
                    >
                      <Grid
                        item
                        xs={3}
                        md={6}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                        Unit Volume
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{
                          padding: "2vh 0px",
                        }}
                      >
                        <div style={rowSeparaterStyle}></div>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        md={5}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {productData?.unitVolume}
                      </Grid>
                    </Grid>

                    <Grid
                      className="  shadow indentData-hover"
                      container
                      style={evenRowStyle}
                    >
                      <Grid
                        item
                        xs={3}
                        md={6}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                        Unit Weight
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{
                          padding: "2vh 0px",
                        }}
                      >
                        <div style={rowSeparaterStyle}></div>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        md={5}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {productData?.unitWeight}
                      </Grid>
                    </Grid>

                    <Grid
                      className="  shadow indentData-hover"
                      container
                      style={oddRowStyle}
                    >
                      <Grid
                        item
                        xs={3}
                        md={6}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                        Volume
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{
                          padding: "2vh 0px",
                        }}
                      >
                        <div style={rowSeparaterStyle}></div>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        md={5}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {productData?.volUm}
                      </Grid>
                    </Grid>
                    <Grid
                      className="  shadow indentData-hover"
                      container
                      style={oddRowStyle}
                    >
                      <Grid
                        item
                        xs={3}
                        md={6}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                        WtUm
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{
                          padding: "2vh 0px",
                        }}
                      >
                        <div style={rowSeparaterStyle}></div>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        md={5}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {productData?.wtUm}
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    tripById: state.Trip.trips.editTrip,
  };
};
// export default connect(mapStateToProps, {
//     viewOrderById,
// })(OrderDetail);
export default ProductDetail;
