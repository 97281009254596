import { types } from "./actionTypes";
export const FetchVehiclePlacementReports = (values,callback) => ({
  type: types.VEHICLE_PLACEMENT_REPORT,
  values,
  callback,
});
export const FetchVehiclePlacementReportsExport = (values,callback) => ({
  type: types.VEHICLE_PLACEMENT_REPORT_EXPORT,
  values,
  callback,
});


