import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  TOGGLE_RIGHT_SIDEBAR,
  SHOW_RIGHT_SIDEBAR,
  CHANGE_PRELOADER,
  HIDE_RIGHT_SIDEBAR,
  types,
} from "./actionTypes";

export const fetchHops = (callback) => ({
  type: types.FETCH_HOPS_LIST,
  callback,
});
export const fetchAllTransporterSearch = (offset, limit,filter,callback) => ({
  type: types.FETCH_ALL_TRANSPORTER_SEARCH,
  offset,
  limit,
  filter, 
  callback,
});
export const fetchTransporterSearch = (
  offset,
  source,
  destination,
  vehicleType,
  route,
  callback
) => ({
  type: types.FETCH_TRANSPORTER_SEARCH,
  offset,
  source,
  destination,
  vehicleType,
  route,
  callback,
});
export const fetchTransporters = (offset, filters, callback) => ({
  type: types.FETCH_TRANSPORTERS,
  offset,
  filters,
  callback,
});
export const getTransporters = (callback) => ({
  type: types.GET_TRANSPORTERS,
  callback,
});

// export const fetchPost = id => ({ type: types.FETCH_POST, id });
export const createTransporter = (values, callback) => ({
  type: types.CREATE_TRANSPORTER,
  values,
  callback,
});

export const changeLayout = (layout) => ({
  type: CHANGE_LAYOUT,
  payload: layout,
});

export const changePreloader = (layout) => ({
  type: CHANGE_PRELOADER,
  payload: layout,
});

export const changeLayoutWidth = (width, layoutType) => ({
  type: CHANGE_LAYOUT_WIDTH,
  payload: { width, layoutType },
});

export const changeSidebarTheme = (theme, layoutType) => ({
  type: CHANGE_SIDEBAR_THEME,
  payload: { theme, layoutType },
});

export const changeSidebarType = (sidebarType, isMobile) => {
  return {
    type: CHANGE_SIDEBAR_TYPE,
    payload: { sidebarType, isMobile },
  };
};

export const changeTopbarTheme = (topbarTheme) => ({
  type: CHANGE_TOPBAR_THEME,
  payload: topbarTheme,
});

export const toggleRightSidebar = () => ({
  type: TOGGLE_RIGHT_SIDEBAR,
  payload: null,
});

export const showRightSidebar = () => ({
  type: SHOW_RIGHT_SIDEBAR,
  payload: null,
});

export const hideRightSidebar = () => ({
  type: HIDE_RIGHT_SIDEBAR,
  payload: null,
});

export const fetchTransportersforIndent = (callback) => ({
  type: types.FETCH_TRANSPORTERS_FOR_INDENT,
  callback,
});
