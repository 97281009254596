let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  HeaderData = {
    columns: [
      // {
      //   label: "S3 Url",
      //   field: "s3Url",
      //   sort: "asc",
      //   width: 5,
      // },
      {
        label: "Requested Time",
        field: "createdTime",
        sort: "asc",
        width: 2,
      },
      {
        label: "From Invoice Date",
        field: "fromInvoiceDate",
        sort: "asc",
        width: 2,
      },
      {
        label: "To Invoice Date",
        field: "toInvoiceDate",
        sort: "asc",
        width: 2,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 1,
      },
      {
        label: "Action",
        field: "action",
        width: 1,
      },
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var HeaderData = {
  columns: [
    // {
    //   label: "S3 Url",
    //   field: "s3Url",
    //   sort: "asc",
    //   width: 5,
    // },
    {
      label: "Requested Time",
      field: "createdTime",
      sort: "asc",
      width: 2,
    },
    {
      label: "Status",
      field: "status",
      sort: "asc",
      width: 1,
    },
    {
      label: "Action",
      field: "action",
      width: 1,
    },
  ],
};
export const searchBar = {
  searchBarData: [],
};
