let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  AdminHeaderData = {
    columns: [
      {
        label: "Trip Id",
        field: "tripId",
        sort: "asc",
        width: 2,

      },
      {
        label: "Order No",
        field: "orderNumber",
        sort: "asc",
        width: 2,
      },
      {
        label: "transporter",
        field: "transporter",
        sort: "asc",
        width: 2,

      },
      
      {
        label: "Vehicle",
        field: "vehicle",
        sort: "asc",
        width: 2,

      },
      {
        label: "Driver",
        field: "driver",
        sort: "asc",
        width: 2,

      },
     
      {
        label: "Status",
        field: "substatus",
        sort: "asc",
        width: 3,
      },
      {
        label: "EWaybill",
        field: "eWayBillNo",
        sort: "asc",
        width: 2,
      },
      {
        label: "Source",
        field: "source",
        sort: "asc",
        width: 2,
      },
      {
        label: "Destination",
        field: "destination",
        sort: "asc",
        width: 2,
      },
    
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var AdminHeaderData = {
  columns: [
    {
      label: "Trip Id",
      field: "tripId",
      sort: "asc",
      width: 2,

    },
    {
      label: "Order No",
      field: "orderNumber",
      sort: "asc",
      width: 2,
    },
    {
      label: "transporter",
      field: "transporter",
      sort: "asc",
      width: 2,

    },
    
    {
      label: "Vehicle",
      field: "vehicle",
      sort: "asc",
      width: 2,

    },
    {
      label: "Driver",
      field: "driver",
      sort: "asc",
      width: 2,

    },
   
    {
      label: "Status",
      field: "substatus",
      sort: "asc",
      width: 3,
    },
    {
      label: "EWaybill",
      field: "eWayBillNo",
      sort: "asc",
      width: 2,
    },
    {
      label: "Source",
      field: "source",
      sort: "asc",
      width: 2,
    },
    {
      label: "Destination",
      field: "destination",
      sort: "asc",
      width: 2,
    },
    {
      label: "Action",
      field: "actions",
      sort: "asc",
      width: 2,
    },
  ],
};
export var searchBar;

searchBar = {
  searchBarData: [
    {
      label: "Order No",
      field: "Order Number",
      type: "text",
    },
    {
      label: "order Co",
      field: "Order Co",
      type: "text",
    },
    {
      label: "Order Type",
      field: "Order Type",
      type: "text",
    },
    {
      label: "Transporter",
      field: "transporter",
      type: "text",
    },
    {
      label: "Status",
      field: "Status",
      type: "select",
    },
    {
      label: "Substatus",
      field: "SubStatus",
      type: "select",
    },
    {
      label: "EWay Bill No",
      field: "EWay Bill No",
      type: "text",
    },
    {
      label: userLang?.common?.ACTIONS,
      field: "actions",
      type: "text",
    },
  ],
 
};
