import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import ReportsReducer from './reducer';
const Reports = combineReducers({
  reports: ReportsReducer,
  form: formReducer
});


export default Reports;
