import React, { Component } from 'react'
import ReactApexChart from "react-apexcharts";

export default class OnTimeDeliveryDonutChart extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          series: [],
          options: {
            tooltip: {
              shared: false,
              intersect: true,
              y: {
                formatter: (value) => {
                  return value  + ' %'
                },
              },
              
            },
            plotOptions: {
              pie: {
                // customScale: 0.5,
                donut: {
                  size: '80%'
                }
              }
            },
            labels: [],
            colors: ["#28A34C", "#E53D2C", "#FFFFFF"],
            legend: {
              show: false,
              position: "bottom",
              horizontalAlign: "center",
              verticalAlign: "middle",
              floating: false,
              // fontSize: '2px',
              offsetX: 0,
              offsetY: 0,
            },
            dataLabels: {
              enabled: false,
            },
            responsive: [
              {
                breakpoint: 600,
                options: {
                  chart: {
                    height:"100%",
                  },
                  legend: {
                    show: false,
                  },
                },
              },
            ],
          },
        };
      }
      componentDidUpdate = (prevProps) => {
        if (prevProps.performance !== this.props.performance) {
          let performance= this.props.performance;
          var performanceObj = {
            onTimeDeliveries:0,
            onTimeDeliverisPercentage:0,
           };
          if(performance !== undefined){
            performance.map((ele)=>{
              // console.log("ele tes", ele)
              if(ele?.performanceType === "onTimeDelivery"){
                performanceObj.onTimeDeliveries = ele?.totalTrips;
                performanceObj.onTimeDeliverisPercentage = Number((ele?.performancePercentage*100).toFixed(2));
              }
              return null
            })
          }
          let OnTimeDeliveries = performanceObj?.onTimeDeliverisPercentage;
          let rejectedDeliveries = Number((100 - performanceObj?.onTimeDeliverisPercentage).toFixed(2))
          let Series = [OnTimeDeliveries,rejectedDeliveries];
          let Label = [
            "On Time Deliveries",
            "Rejected Deliveries"
          ];
          
          this.setState({ options: { ...this.state.options, labels: Label } });
          this.setState({ series: Series });
        }
      };
    
      render() {
        // console.log("this.series", this.state.series)
        return (
          <React.Fragment>
            <ReactApexChart className="cursor-pointer IndentApexchartParent justify-content-center mgt5"
              options={this.state.options}
              series={this.state.series}
              type="donut"
              height="150"
            />
          </React.Fragment>
        );
      }
  }

