import { types } from "./actionTypes";
export const fetchHops = (offset, callback) => ({
  type: types.FETCH_HOPS,
  offset,
  callback,
});
export const fetchHopsSourceDestination = (offset, sourceId, q, callback) => ({
  type: types.FETCH_SOURCE_DESTINATION,
  offset,
  sourceId,
  q,
  callback,
});
export const fetchHopsfilter = (filters, searchFilter, callback) => ({
  type: types.FETCH_FILTER_HOPS,
  filters,
  searchFilter,
  callback,
});

export const fetchAvailHops = () => ({ type: types.FETCH_AVAIL_HOPS });
// export const fetchPost = id => ({ type: types.FETCH_POST, id });
export const createHop = (values, callback) => ({
  type: types.CREATE_HOP,
  values,
  callback,
});
export const editHop = (values, callback) => ({
  type: types.EDIT_HOP,
  values,
  callback,
});
export const fetchHopById = (id, callback) => ({
  type: types.FETCH_HOP_BYID,
  id,
  callback,
});
export const deleteHop = (id, callback) => ({
  type: types.DELETE_HOP,
  id,
  callback,
});
export const fetchAllSourceDestination = (callback) => ({
  type: types.FETCH_ALL_SOURCE_DESTINATION,
  callback,
});

export const fetchHopPresignedUrl = (callback) => {
  return {
    type: types.GET_HOP_PRESIGNED_URL,
    callback,
  };
};

export const createHopViaExcel = (payload, callback) =>{
  return {
      type:types.CREATE_HOP_VIA_EXCEL,
      payload,
      callback
  }
}

export const uploadImagesAws = (uri, data, headers) => ({
  type: types.UPLOAD_IMAGE1,
  uri,
  data,
  headers,
});
