import React, { Component } from "react";
import { Row, Col, Card, Button, Input } from "reactstrap";
import Select, { components } from "react-select";
import "./invoiceListTheme.scss";
import refreshIcon from "./assets/refresh-cw.png";
import Datepicker from "../../../../components/Common/DatePicker/DatePicker";
import { Search } from "../../../../components/Common/CommonIcons";
import { Grid } from "@material-ui/core";
import { InputGroup } from "rsuite";
// images
import searchIcon from "./assets/search.png";
import searchDark from "./assets/searchDrk.png";
var darkMode;
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

const DropdownIndicator = (props) => {
  const iconSvg = props.selectProps.selectProps;
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {iconSvg}
      </components.DropdownIndicator>
    )
  );
};

class QuickFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hops: [],
      vehicleTypes: [],
      // searchBar: [],
      searchFilters: {},
      dateValue: [null, null],
      showCalender: false,
      userLangs: undefined,
      fromDate: null,
      toDate: null,
    };
  }
  componentDidUpdate() {
    if (
      this.state.userLangs === undefined &&
      (userLang !== this.state.userLangs || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  }
  handleClickAway = () => {
    if (this.state.showCalender === true) {
      this.setState({ showCalender: false });
    }
  };
  handleOpen = () => {
    this.setState({ showCalender: true });
  };
  handleClose = () => {
    this.setState({ showCalender: false });
  };
  onChangeDatePicker = (value, label) => {
    console.log("value", value, new Date("12-18-2023"), new Date(this.props.FromDate))
    if (label === "fromDate") {
      this.setState({ fromDate: value }, () => {
        if (this.state.fromDate !== null && this.state.toDate !== null) {
          this.props.handleChange([this.state.fromDate, this.state.toDate]);
        }
      });
    }
    if (label === "toDate") {
      this.setState({ toDate: value }, () => {
        if (this.state.fromDate !== null && this.state.toDate !== null) {
          this.props.handleChange([this.state.fromDate, this.state.toDate]);
        }
      });
    }
  };
  render() {
    console.log("props", this.props)
    let authUserData = JSON.parse(window.localStorage.getItem("authUser"));
    darkMode = this.props?.mode;
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    // console.log(this.props.mode);
    const customStylesV2 = {
      menu: ({ width, ...css }) => ({
        ...css,
        width: "100%",
        minWidth: "100%",
        zIndex: 9999
      }),
      input: (base) => ({
        ...base,
        color: darkMode ? "#9d9d9d" : "#505d69",
      }),
      control: (style) => {
        return {
          ...style,
          height: "28px",
          width: this.props?.form?.type?.length >= 2 ? "100%" : "100%",
          minWidth: "100%",
          minHeight: "28px",
        };
      },
      placeholder: (style) => {
        return {
          ...style,
          cursor: "pointer",
          marginTop: "2px",
          height: "26px",
          minHeight: "26px",
        };
      },
      indicatorsContainer: (style) => {
        return {
          ...style,
          // backgroundColor: "#8e9af8",
          height: "26px",
          minHeight: "26px",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "10px",
          minHeight: "10px",
          backgroundColor: "#cccccc",
          marginTop: "6px",
        };
      },
      dropdownIndicator: (style) => {
        return {
          ...style,
          height: "26px",
          minHeight: "26px",
          // backgroundColor: "#0066cc",
          marginTop: "0px",
        };
      },
    };
    const customStyles2 = {
      option: (styles, state) => ({
        ...styles,
        cursor: "pointer",
      }),
      input: (base) => ({
        ...base,
        color: darkMode ? "#9d9d9d" : "#505d69",
      }),
      menu: ({ width, ...css }) => ({
        ...css,
        zIndex: 9999,
        width: "100%",
        minWidth: "100%",
        cursor: "pointer ",
        backgroundColor: darkMode ? "#171722" : "#ffffff",
        border: darkMode
          ? "0.5px solid #616161"
          : "0.5px solid rgb(204, 204, 204)",
        boxShadow: darkMode
          ? "0px 3px 12px rgba(0, 0, 0, 0.12)"
          : "0px 3px 12px rgba(0, 0, 0, 0.12)",
        bordeRadius: darkMode ? "4px" : "4px",
      }),
      control: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "28px",
          width: "100%",
          minWidth: "100%",
          minHeight: "28px",
          flexWrap: "nowrap",
          backgroundColor: "#2E2E46",
        };
      },
      placeholder: (style) => {
        return {
          ...style,
          cursor: "pointer",
          marginTop: "2px",
          height: "26px",
          minHeight: "28px",
          fontSize: "13px",
          fontFamily: "Roboto",
          fontWeight: 400,
          fontStyle: "normal",
          paddingLeft: "10px",
          paddingTop: "5px",
          color: this.props?.opCardStyle ? "#9D9D9D" : "#0C0C0C",
        };
      },
      indicatorsContainer: (style) => {
        return {
          ...style,
          cursor: "pointer",
          // backgroundColor: "#8e9af8",
          height: "26px",
          minHeight: "26px",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "10px",
          minHeight: "10px",
          backgroundColor: "#cccccc",
          marginTop: "8px",
        };
      },
      dropdownIndicator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "26px",
          minHeight: "26px",
          // backgroundColor: "#0066cc",
        };
      },
    };
    return (
      <React.Fragment>
        <Card
          className="darkBgQuickFilter"
          style={{
            width: "100%",
            backgroundColor: this.props.mode ? "#222238" : "#ffffff",
          }}
        >
          <div className="quickStylesBar invoiceGenerateStyle">
            <>
              <Row className="pr-2">

                <Col xs={12} className="mobileDisplay">
                  <div className="d-flex justify-content-end">
                    <div>
                      <Button
                        style={{
                          minWidth: "70%",
                        }}
                        className="mobile-reset reset"
                        onClick={() => this.props?.search()}
                      >
                        {/* <img
                          className="mobile-rest-img"
                          src={Search}
                          alt=""
                        ></img> */}
                        <i className="mobile-rest-img">
                          <Search />
                        </i>
                      </Button>
                    </div>
                    <div>
                      <Button
                        style={{
                          minWidth: "70%",
                        }}
                        className="mobile-reset reset"
                        onClick={() => this.props?.reset()}
                      >
                        <img
                          className="mobile-rest-img"
                          src={refreshIcon}
                          alt=""
                        ></img>
                      </Button>
                    </div>
                    <div className="filter">
                      <div className="tooltipIcon QuickFilters">
                        <div
                          className="datepicker-selector-mobile iconButtons mobileCalender actionButtonsDark mr-2 datepicker-custom"
                          style={{ position: "relative", top: "9px" }}
                        >
                          <Datepicker
                            mode={darkMode}
                            value={this.state.fromDate}
                            onChangeDatePicker={this.onChangeDatePicker}
                            fromDate={true}
                            toDate={false}
                            classname={"reportsListStyle"}
                          />
                        </div>
                        <span
                          className="tooltipIcontext"
                          style={{ width: "130px", left: "20%" }}
                        >
                          &nbsp;{this.state.userLangs?.common?.CALENDER}&nbsp;
                        </span>
                      </div>
                    </div>
                    <div className="filter">
                      <div className="tooltipIcon QuickFilters">
                        <div
                          className="datepicker-selector-mobile iconButtons mobileCalender actionButtonsDark mr-2 datepicker-custom"
                          style={{ position: "relative", top: "9px" }}
                        >
                          <Datepicker
                            mode={darkMode}
                            value={this.state.fromDate}
                            onChangeDatePicker={this.onChangeDatePicker}
                            fromDate={false}
                            toDate={true}
                            classname={"reportsListStyle"}
                          />
                        </div>
                        <span
                          className="tooltipIcontext"
                          style={{ width: "130px", left: "20%" }}
                        >
                          &nbsp;{this.state.userLangs?.common?.CALENDER}&nbsp;
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12}>
                  <Row className="mb-2">
                    <Col xs={12} md={3}>
                      <div className=" filter">
                        <div className="input-border mt-2">
                          <InputGroup>
                            <Input
                              className="form-control "
                              type="text"
                              name="type"
                              value={this.props.filters?.type}
                              onChange={(e) => {
                                this.props.handleOnchangeFilters({
                                  target: "type",
                                  value: e.target.value,
                                });
                              }}
                              onKeyDown={(event) => this.props?.enterCheck(event)}
                              placeholder="Type"
                              aria-label="Search"
                              icon="fas fa-times-circle"
                            />

                          </InputGroup>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={3}>
                      <div className=" filter">
                        <div className="input-border mt-2">
                          <InputGroup>
                            <Input
                              className="form-control "
                              type="number"
                              name="indentId"
                              value={this.props.filters?.indentId}
                              onChange={(e) => {
                                this.props.handleOnchangeFilters({
                                  target: "indentId",
                                  value: e.target.value,
                                });
                              }}
                              onKeyDown={(event) => this.props?.enterCheck(event)}
                              placeholder="Indent ID"
                              aria-label="Search"
                              icon="fas fa-times-circle"
                            />

                          </InputGroup>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={3}>
                      <div className=" filter">
                        <div className="input-border mt-2">
                          <InputGroup>
                            <Input
                              className="form-control "
                              type="number"
                              name="tripId"
                              value={this.props.filters?.tripId}
                              onChange={(e) => {
                                this.props.handleOnchangeFilters({
                                  target: "tripId",
                                  value: e.target.value,
                                });
                              }}
                              onKeyDown={(event) => this.props?.enterCheck(event)}
                              placeholder="Trip ID"
                              aria-label="Search"
                              icon="fas fa-times-circle"
                            />

                          </InputGroup>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={3}>
                      <div className=" filter">
                        <div className="input-border mt-2">
                          <InputGroup>
                            <Input
                              className="form-control "
                              type="text"
                              name="vehicleNumber"
                              value={this.props.filters?.vehicleNumber}
                              onChange={(e) =>
                                this.props.handleOnchangeFilters({
                                  target: "vehicleNumber",
                                  value: e.target.value,
                                })
                              }
                              onKeyDown={(event) => this.props?.enterCheck(event)}
                              placeholder="Vehicle Number"
                              aria-label="Search"
                              icon="fas fa-times-circle"
                            />

                          </InputGroup>
                        </div>
                      </div>
                    </Col>

                  </Row>
                </Col>
                <Col xs={12}>
                  <Row className="mb-2">
                    <Col xs={12} md={3}>
                      <div className=" filter">
                        <div className="input-border mt-2">
                          <InputGroup>
                            <Input
                              className="form-control "
                              type="text"
                              name="lrNumber"
                              value={this.props.filters?.lrNumber}
                              onChange={(e) =>
                                this.props.handleOnchangeFilters({
                                  target: "lrNumber",
                                  value: e.target.value,
                                })
                              }
                              onKeyDown={(event) => this.props?.enterCheck(event)}
                              placeholder="LR Number"
                              aria-label="Search"
                              icon="fas fa-times-circle"
                            />

                          </InputGroup>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={3} >
                      <div className="filter">
                        <Select
                          name={this.props.name}
                          className={`transpoter-react_selector invoiceDropdown ${this.props.mode ? "darkModeInvoiceState" : ""
                            }`}
                          classNamePrefix="react-select"
                          // autoFocus={true}
                          // isSearchable
                          styles={customStylesV2}
                          style={{ width: "100%" }}
                          isMulti={false}
                          value={this.props.selectedHopValues?.selectedSource}
                          options={this.props.hopOptions}
                          onChange={(e) => this.props.onChangeHop({
                            target: "selectedSource",
                            value: e
                          })}
                          onInputChange={(e) => {
                            this.props.filtersHops(e);
                          }}

                          id="typeVehicleList"
                          placeholder="Select Source"
                          components={{ DropdownIndicator }}
                          selectProps={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="16"
                              viewBox="0 0 14 16"
                              fill="none"
                            >
                              <path
                                d="M12.455 6.7275C12.455 11.1825 6.727 15.0005 6.727 15.0005C6.727 15.0005 1 11.1825 1 6.7275C1 5.20847 1.60343 3.75166 2.67755 2.67755C3.75166 1.60343 5.20847 1 6.7275 1C8.24653 1 9.70334 1.60343 10.7775 2.67755C11.8516 3.75166 12.455 5.20847 12.455 6.7275V6.7275Z"
                                stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M7 9.00049C8.10457 9.00049 9 8.10506 9 7.00049C9 5.89592 8.10457 5.00049 7 5.00049C5.89543 5.00049 5 5.89592 5 7.00049C5 8.10506 5.89543 9.00049 7 9.00049Z"
                                stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          }
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={3} >
                      <div className="filter">
                        <Select
                          name={this.props.name}
                          className={`transpoter-react_selector invoiceDropdown ${this.props.mode ? "darkModeInvoiceState" : ""
                            }`}
                          classNamePrefix="react-select"
                          // autoFocus={true}
                          // isSearchable
                          styles={customStylesV2}
                          style={{ width: "100%" }}
                          isMulti={false}
                          value={this.props.selectedHopValues?.selecteddestination}
                          options={this.props.hopOptions}
                          onChange={(e) => this.props.onChangeHop({
                            target: "selecteddestination",
                            value: e
                          })}
                          onInputChange={(e) => {
                            this.props.filtersHops(e);
                          }}

                          id="typeVehicleList"
                          placeholder="Select destination"
                          components={{ DropdownIndicator }}
                          selectProps={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="16"
                              viewBox="0 0 14 16"
                              fill="none"
                            >
                              <path
                                d="M12.455 6.7275C12.455 11.1825 6.727 15.0005 6.727 15.0005C6.727 15.0005 1 11.1825 1 6.7275C1 5.20847 1.60343 3.75166 2.67755 2.67755C3.75166 1.60343 5.20847 1 6.7275 1C8.24653 1 9.70334 1.60343 10.7775 2.67755C11.8516 3.75166 12.455 5.20847 12.455 6.7275V6.7275Z"
                                stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M7 9.00049C8.10457 9.00049 9 8.10506 9 7.00049C9 5.89592 8.10457 5.00049 7 5.00049C5.89543 5.00049 5 5.89592 5 7.00049C5 8.10506 5.89543 9.00049 7 9.00049Z"
                                stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          }
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={3} >
                      <div className="filter">
                        <Select
                          name={this.props.name}
                          className={`transpoter-react_selector invoiceDropdown ${this.props.mode ? "darkModeInvoiceState" : ""
                            }`}
                          classNamePrefix="react-select"
                          // autoFocus={true}
                          // isSearchable
                          styles={customStylesV2}
                          style={{ width: "100%" }}
                          isMulti={false}
                          onChange={(e) => this.props.onChangeVehicleType(e)}
                          options={this.props.vehicleTypes}
                          value={this.props.selectedVehicleType}
                          onInputChange={this.props.filtersVehicles}
                          id="typeVehicleList"
                          placeholder="Select Vehicle Type..."
                          components={{ DropdownIndicator }}
                          selectProps={<svg width="14" height="14" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.33333 1H1V8.22222H9.33333V1Z" stroke={darkMode ? "#B7A9FF" : "#2800FC"} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M9.33203 3.7778H11.5543L13.2209 5.44447V8.22225H9.33203V3.7778Z" stroke={darkMode ? "#B7A9FF" : "#2800FC"} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M3.50022 11C4.26728 11 4.88911 10.3782 4.88911 9.61112C4.88911 8.84406 4.26728 8.22223 3.50022 8.22223C2.73315 8.22223 2.11133 8.84406 2.11133 9.61112C2.11133 10.3782 2.73315 11 3.50022 11Z" stroke="#2800FC" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M10.7209 11C11.488 11 12.1098 10.3782 12.1098 9.61112C12.1098 8.84406 11.488 8.22223 10.7209 8.22223C9.95386 8.22223 9.33203 8.84406 9.33203 9.61112C9.33203 10.3782 9.95386 11 10.7209 11Z" stroke="#2800FC" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>}
                        />
                      </div>
                    </Col>

                  </Row>
                </Col>
                <Col xs={12} md={3} >
                  <div className="filter">
                    <Select
                      name={this.props.name}
                      className={`transpoter-react_selector invoiceDropdown ${this.props.mode ? "darkModeInvoiceState" : ""
                        }`}
                      classNamePrefix="react-select"
                      // autoFocus={true}
                      // isSearchable
                      styles={customStylesV2}
                      style={{ width: "100%" }}
                      isMulti={false}
                      onChange={this.props.handleStateChange}
                      options={this.props.stateListOption}
                      value={this.props.stateFilter}
                      id="typeVehicleList"
                      placeholder="Select State..."
                      components={{ DropdownIndicator }}
                      selectProps={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="16"
                          viewBox="0 0 14 16"
                          fill="none"
                        >
                          <path
                            d="M12.455 6.7275C12.455 11.1825 6.727 15.0005 6.727 15.0005C6.727 15.0005 1 11.1825 1 6.7275C1 5.20847 1.60343 3.75166 2.67755 2.67755C3.75166 1.60343 5.20847 1 6.7275 1C8.24653 1 9.70334 1.60343 10.7775 2.67755C11.8516 3.75166 12.455 5.20847 12.455 6.7275V6.7275Z"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7 9.00049C8.10457 9.00049 9 8.10506 9 7.00049C9 5.89592 8.10457 5.00049 7 5.00049C5.89543 5.00049 5 5.89592 5 7.00049C5 8.10506 5.89543 9.00049 7 9.00049Z"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      }
                    />
                  </div>
                </Col>
                <Col xs={2} md={3} className="desktopDisplay">
                  {/* <div className="d-flex flex-row pr-2"> */}
                  <Grid
                    item
                    xs={12}
                    className="datePickerGrid"
                  >
                    <div
                      className="mr-2 mt-2 date-picker-div RsuiteDateRange dashboardDateRange"
                      style={{
                        width: "100%",
                      }}
                    >
                      <div className="datepicker-selector">
                        <InputGroup>
                          <Datepicker
                            mode={darkMode}
                            classname={"reportsListStyle"}
                            value={
                              this.state.fromDate || (this.props.FromDate?.length > 0
                                ? new Date(this.props.FromDate)
                                : null)
                            }
                            onChangeDatePicker={this.onChangeDatePicker}
                            fromDate={true}
                            toDate={false}
                            placeholder={"Start Date"}
                            dashboard={true}
                          />
                          <Datepicker
                            mode={darkMode}
                            classname={"reportsListStyle"}
                            value={
                              this.state.toDate || (this.props.ToDate?.length > 0
                                ? new Date(this.props.ToDate)
                                : null)
                            }
                            onChangeDatePicker={this.onChangeDatePicker}
                            fromDate={false}
                            toDate={true}
                            placeholder={"End Date"}
                            dashboard={true}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  </Grid>

                  {/* </div> */}
                </Col>

                {authUserData?.data?.role?.label !== "transporter" ? (
                  <Col xs={12} md={3}>
                    <div className="filter">
                      <Select
                        name={this.props.name}
                        className="transpoter-react_selector"
                        classNamePrefix="react-select"
                        styles={customStylesV2}
                        style={{ width: "82%" }}
                        isMulti={false}
                        onChange={(e) => this.props.handleStateTransChange(e)}
                        options={this.props.allTransporter}
                        value={this.props.selectedTrans}
                        // onBlur={this.props.getTAllranspoterData}
                        onInputChange={(e) => this.props.filtersTranspoter(e)}
                        id="typeVehicleList"
                        placeholder="Select Transpoter..."
                        components={{ DropdownIndicator }}
                        selectProps={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="16"
                            viewBox="0 0 14 16"
                            fill="none"
                          >
                            <path
                              d="M12.455 6.7275C12.455 11.1825 6.727 15.0005 6.727 15.0005C6.727 15.0005 1 11.1825 1 6.7275C1 5.20847 1.60343 3.75166 2.67755 2.67755C3.75166 1.60343 5.20847 1 6.7275 1C8.24653 1 9.70334 1.60343 10.7775 2.67755C11.8516 3.75166 12.455 5.20847 12.455 6.7275V6.7275Z"
                              stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7 9.00049C8.10457 9.00049 9 8.10506 9 7.00049C9 5.89592 8.10457 5.00049 7 5.00049C5.89543 5.00049 5 5.89592 5 7.00049C5 8.10506 5.89543 9.00049 7 9.00049Z"
                              stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        }
                      // classNamePrefix='select2-selection'
                      />
                    </div>
                  </Col>
                ) : (
                  ""
                )}
                <Col xs={12} md={3} className="d-flex">
                  <div className=" filter">
                    <Button
                      onClick={() => {
                        this.setState(
                          {
                            dateValue: [null, null],
                          },
                          () => {
                            this.props.search();
                            // this.props.filterList();
                          }
                        );
                      }}
                      className="reset"
                    >
                      {"Search"}
                    </Button>
                  </div>
                  <div className=" filter">
                    <Button
                      onClick={() => {
                        this.setState(
                          {
                            dateValue: [null, null],
                            fromDate: null,
                            toDate: null,
                          },
                          () => {
                            this.props.reset();
                          }
                        );
                      }}
                      className="reset"
                    >
                      {this.state.userLangs?.common?.RESET}
                    </Button>
                  </div>
                </Col>
              </Row>
            </>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default QuickFilters;
