import React, { Component } from "react";
import { connect } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import { Row, Col, Button } from "reactstrap";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
} from "reactstrap";
import { actions } from "./actionButtons";
import { NotificationManager } from "react-notifications";
import * as FileSaver from "file-saver";
import GetAppIcon from "@material-ui/icons/GetApp";
//actions
import {
  getProducts,
  getProductsExcel,
  createProductViaExcel,
  UpdateProductValues,
} from "../../store/baxter_product/actions";
import {
  fetchProductPresignedUrl,
  uploadImagesAws,
} from "../../store/baxter-uploadModule/actions";
// component
import Index from "../../components/Common/DataTableNeo/Index";
import {
  AdminHeaderData,
  getLocalStorage,
  searchBar,
} from "./RowHeaders/RowHeaders";
import QuickFilters from "./quckFilters";
import { exportToCSV } from "../common/exportToCsv";
import UpdateProductModal from "./UpdateProductModal";

var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var darkTheme;
var searchValues = {};
let pathName;
let key;
let keyOffset;
class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dataLoading: false,
      offset: 0,
      isOpen: false,
      modal: false,
      selectedFile: "",
      excelUrl: "",
      searchFilters: {},
      form: {},
      errors: {},
      id: "",
    };
    this.fileInput = React.createRef();
    // (async () => {
    //   let redirect = await restrict(PERMS.DRIVER_TRANSPORTER_READ);

    //   if (redirect === false) {
    //     this.props.history.push("/operationsDashboard");
    //   }
    // })();
  }
  fileUploadTrigger = () => {
    this.fileInput.current.click();
  };

  componentDidMount() {
    this.getAllData();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 0.9;
  }
  getAllData = () => {
    pathName = window.location.pathname;
    key = `mySearchFilter_${pathName}`;
    keyOffset = `myOffsetFilter_${pathName}`;

    let storedData = localStorage.getItem(key)
      ? JSON.parse(window.localStorage.getItem(key))
      : "";
    searchValues = storedData || {};
    let offsetData = localStorage.getItem(keyOffset)
      ? localStorage.getItem(keyOffset)
      : window.localStorage.setItem(keyOffset, offset);
    this.setState({ dataLoading: true }, () => {
      let searchFilters = this.state.searchFilters;
      this.props.getProducts(
        {
          offset: offset || offsetData,
          limit: 10,
        },
        Object.keys(searchFilters).length > 0 ? searchFilters : storedData,
        () => {
          this.setState({ dataLoading: false });
        }
      );
    });
  };
  getAllDataReset = () => {
    offset = 0;
    window.localStorage.setItem(keyOffset, offset);
    this.getAllData();
  };
  handleChangePage = (event, val) => {
    // console.log(event, val, "handlepage");
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    window.localStorage.setItem(keyOffset, offset);
    this.getAllData();
  };
  fileUpload = (event) => {
    this.setState(
      {
        selectedFile: event.target.files[0],
      },
      () => {
        this.S3Upload();
      }
    );
  };
  S3Upload = async () => {
    this.props.fetchProductPresignedUrl((res) => {
      // console.log("res",res.presignedS3Url)
      let softCopy = "";
      softCopy = res.presignedS3Url.split("?")[0];
      // console.log("SoftCopy",softCopy)
      this.setState(
        {
          excelUrl: softCopy,
        },
        () => {
          // console.log("images", this.state.images);
          const formData = new FormData();
          formData.append(
            this.state.selectedFile,
            this.state.selectedFile.name
          );
          // console.log("--------", formData);
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", this.state.selectedFile.type);
          this.props.uploadImagesAws(
            res.presignedS3Url,
            this.state.selectedFile,
            myHeaders
          );
        }
      );
    });
  };
  handleSubmitExcel = () => {
    // if(this.state.excelUrl){
    // console.log("excelUrl",this.state.excelUrl)
    this.props.createProductViaExcel(
      { excelFileURL: this.state.excelUrl },
      (res) => {
        if (res.success) {
          NotificationManager.success(res["message"], "Success");
          this.getAllData();
          this.closeModal();
        } else {
          NotificationManager.error(res["message"], "Error");
        }
      }
    );
    // }
  };
  handledownloadTemplate = () => {
    let userUploadTemplate =
      "https://baxterfiles.s3.ap-south-1.amazonaws.com/15-11-2023/product_master/main/PRODUCT_MASTER/d32dadc8-7866-4475-8aab-d17427a306cd_d0f0770e-6ce8-43bb-a124-a32b9f96f5be.vnd.ms-excel?Content-Type=application%2Fvnd.ms-excel";
    let filename = "product Upload Template.xlsx";
    FileSaver.saveAs(userUploadTemplate, filename);
  };
  searchValueAssignerFunction = (name, value, type) => {
    // console.log("valorder", value, name, type);
    if (typeof value === "text" || type === "text") {
      searchValues[name] = value?.replace(/^\s+/, "");
      this.setState({ searchFilters: searchValues }, () => {});
    }
  };
  componentWillUnmount = () => {
    offset = 0;
  };
  reset = () => {
    offset = 0;
    searchValues = {};
    this.setState(
      {
        searchFilters: {},
        form: {
          caseInSize: "",
          casePerPallet: "",
          weightPerCaseInKGS: "",
          lInCMS: "",
          wInCMS: "",
          hInCMS: "",
        },
        errors: {},
      },
      () => {
        this.getAllData();
      }
    );
  };
  openModal = () => {
    this.setState({
      isOpen: true,
    });
  };
  closeModal = () => {
    this.setState({
      isOpen: false,
      excelUrl: "",
    });
  };
  actions = (actionString) => {
    // console.log(actionString, "--- status");
    var darkMode = document.body.classList.contains("dark__mode");
    // console.log("darkMode", darkMode);
    return (
      <div className="dropdown" disabled>
        <Button className="iconButtons dropbtn actionButtonsDark">
          <svg
            width="18"
            height="4"
            viewBox="0 0 18 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 2C10 1.44772 9.55228 1 9 1C8.44772 1 8 1.44772 8 2C8 2.55228 8.44772 3 9 3C9.55228 3 10 2.55228 10 2Z"
              stroke="#2800FC"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1 1.44772 1 2C1 2.55228 1.44772 3 2 3C2.55228 3 3 2.55228 3 2Z"
              stroke="#2800FC"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 2C17 1.44772 16.5523 1 16 1C15.4477 1 15 1.44772 15 2C15 2.55228 15.4477 3 16 3C16.5523 3 17 2.55228 17 2Z"
              stroke="#2800FC"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Button>
        <div className="dropdown-content contentDark">
          <div
            className="action-button actionDspan"
            onClick={() => {
              // routingToIndentData.routingToProvideDetailModel(ele);
            }}
          >
            View Detail
          </div>
        </div>
      </div>
    );
  };

  //Update Optimization Values
  formSet = () => {
    let formDataEdit = {};
    let data = this.state?.viewById?.ele;

    if (this.state.viewById?.viewMode === "edit") {
      formDataEdit["caseInSize"] = data?.caseInSize;
      formDataEdit["casePerPallet"] = data?.casePerPallet;
      formDataEdit["weightPerCaseInKGS"] = data?.weightPerCaseInKGS;
      formDataEdit["lInCMS"] = data?.lInCMS;
      formDataEdit["wInCMS"] = data?.wInCMS;
      formDataEdit["hInCMS"] = data?.hInCMS;
    }

    this.setState({ form: formDataEdit, editId: data?._id });
    return formDataEdit;
  };
  onChangeInputData = (name, value) => {
    const { form } = this.state;
    let formObj = {};

    formObj = {
      ...form,
      [name]: Number(value),
    };
    this.setState({ form: formObj }, () => {});
  };
  togglemodal = () => {
    this.setState(
      (prevState) => ({
        modal: !prevState.modal,
      }),
      () => {}
    );
  };
  // validateValAdd = () => {
  //   const errorsAdd = {};
  //   let { caseInSize, casePerPallet, weightPerCaseInKGS, zone, courier } = this.state.form;
  //   // let image = this.state.urlArrayValues;

  //   if (!caseInSize) {
  //     errorsAdd.caseInSize = "State is required!";
  //   }
  //   if (!casePerPallet || !Number(casePerPallet)) {
  //     errorsAdd.casePerPallet = "CasePer is required and it should be Number !";
  //   }
  //   if (!weightPerCaseInKGS || !Number(weightPerCaseInKGS)) {
  //     errorsAdd.weightPerCaseInKGS = "Cost is required and it should be Number !";
  //   }
  //   if (!cost || !Number(cost)) {
  //     errorsAdd.cost = "Cost is required and it should be Number !";
  //   }
  //   if (!cost || !Number(cost)) {
  //     errorsAdd.cost = "Cost is required and it should be Number !";
  //   }
  //   if (!cost || !Number(cost)) {
  //     errorsAdd.cost = "Cost is required and it should be Number !";
  //   }
  //   if (!cost || !Number(cost)) {
  //     errorsAdd.cost = "Cost is required and it should be Number !";
  //   }
  //   return errorsAdd;
  // };
  handleSubmit = () => {
    this.setState({
      onSubmitDisable: true,
      // dataLoading: true,
      // errors: this.validateValAdd(),
    });
    const { form, formErrors } = this.state;

    let formVal = { ...form, id: this.state.id };
    // console.log(formVal, "form");

    this.props.UpdateProductValues(formVal, (res) => {
      // console.log(res, "90");
      if (
        (res["message"] &&
          res["message"] ===
            "Product optimization values updated successfully!") ||
        res["success"] === true
      ) {
        NotificationManager.success(res["message"], "Success");
        // this.props.history.push("/products");
        this.setState({ onSubmitDisable: false, modal: false }, () => {
          this.reset();
          window.location.reload();

          // this.getAllData();
        });
      } else {
        this.setState({ onSubmitDisable: false, dataLoading: false });
        NotificationManager.error(res["message"], "Error");
      }
    });
  };
  updateDetail = (ele) => {
    this.addOptimizationValues(ele, "edit");
  };

  addOptimizationValues = (ele, viewMode) => {
    this.setState(
      { modal: true, viewById: { ele, viewMode }, errors: {}, id: ele["_id"] },
      () => {
        if (this.state?.viewById && viewMode === "edit") {
          this.formSet();
        } else {
          this.setState({
            form: {
              caseInSize: "",
              casePerPallet: "",
              weightPerCaseInKGS: "",
              lInCMS: "",
              wInCMS: "",
              hInCMS: "",
            },
          });
        }
      }
    );
  };

  exportExcelData = () => {
    pathName = window.location.pathname;
    key = `mySearchFilter_${pathName}`;
    keyOffset = `myOffsetFilter_${pathName}`;

    let storedData = localStorage.getItem(key)
      ? JSON.parse(window.localStorage.getItem(key))
      : "";
    searchValues = storedData || {};
    let offsetData = localStorage.getItem(keyOffset)
      ? localStorage.getItem(keyOffset)
      : window.localStorage.setItem(keyOffset, offset);
    this.setState({ dataLoading: true }, () => {
      let searchFilters = this.state.searchFilters;
      this.props.getProductsExcel(
        {
          offset: 0,
          limit: 100000,
        },
        Object.keys(searchFilters).length > 0 ? searchFilters : storedData,
        (res) => {
          this.setState({ dataLoading: false }, () => {});
          // this.setState({ isExportingExcel: false });
          // let exdata=[]
          let exportData = [];
          if (res?.data?.docs?.length > 0) {
            res.data.docs.map((ele) => {
              // console.log("ele", ele);
              exportData.push({
                "Item Number": ele?.itemNumber ? ele?.itemNumber : "N/A",
                Description: ele?.description ? ele?.description : "N/A",
                "Description Line 2": ele?.descriptionLine2
                  ? ele?.descriptionLine2
                  : "N/A",
                "QV Sub Division": ele?.qvSubDivision
                  ? ele?.qvSubDivision
                  : "N/A",
                "Case In Size": ele?.caseInSize ? ele?.caseInSize : "N/A",
                "Case Per Pallet": ele?.casePerPallet
                  ? ele?.casePerPallet
                  : "N/A",
                "Weight Per Case(KG)": ele?.weightPerCaseInKGS
                  ? ele?.weightPerCaseInKGS
                  : "N/A",
                "Length(cm)": ele?.lInCMS ? ele?.lInCMS : "N/A",
                "Width(cm)": ele?.wInCMS ? ele?.wInCMS : "N/A",
                "Height(cm)": ele?.hInCMS ? ele?.hInCMS : "N/A",
              });
            });
          }
          // console.log("export Data", exportData);
          if (exportData?.length > 0) {
            exportToCSV([...exportData], "Products");
          } else {
            exportData.push({
              "Item Number": "",
              Description: "",
              "Description Line 2": "",
              "QV Sub Division": "",
              "Case In Size": "",
              "Case Per Pallet": "",
              "Weight Per Case(KG)": "",
              "Length(cm)": "",
              "Width(cm)": "",
              "Height(cm)": "",
            });
            exportToCSV([...exportData], "Products");
          }
        }
      );
    });
  };

  render() {
    // let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    const bodyElement = document.querySelector("body");
    const darkTheme = bodyElement.classList.contains("dark__mode");
    // const { name, phoneNo, loading } = this.state;
    const { getProductsData } = this.props;
    const localStorageData = JSON.parse(localStorage.getItem("productsData"));
    const productData = navigator.onLine
      ? getProductsData?.data?.docs
      : localStorageData?.data?.docs;
    const productOffset = localStorage.getItem(keyOffset)
      ? JSON.parse(localStorage.getItem(keyOffset))
      : "";
    let rows = [];
    if (Array.isArray(productData)) {
      // console.log("drivers : ", this.props.drivers.drivers.data.docs);
      offset = productOffset ? productOffset : offset;
      totCnt = navigator.onLine
        ? getProductsData?.data?.length
        : localStorageData?.data?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = navigator.onLine
        ? getProductsData?.data?.offset + getProductsData?.data?.docs?.length
        : localStorageData?.data?.offset + localStorageData?.data?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      productData.forEach((ele) => {
        rows.push({
          itemNumber: ele?.itemNumber ? ele?.itemNumber : "N/A",
          description: ele?.description ? ele?.description : "N/A",
          descriptionLine2: ele?.descriptionLine2
            ? ele?.descriptionLine2
            : "N/A",
          caseInSize: ele?.caseInSize ? ele?.caseInSize : "N/A",
          qvSubDivision: ele?.qvSubDivision ? ele?.qvSubDivision : "N/A",
          casePerPallet: ele?.casePerPallet ? ele?.casePerPallet : "N/A",
          weightPerCaseInKGS: ele?.weightPerCaseInKGS
            ? ele?.weightPerCaseInKGS
            : "N/A",
          lInCMS: ele?.lInCMS ? ele?.lInCMS : "N/A",
          wInCMS: ele?.wInCMS ? ele?.wInCMS : "N/A",
          hInCMS: ele?.hInCMS ? ele?.hInCMS : "N/A",
          actions: actions(ele, {
            updatedetail: this.updateDetail,
          }),
        });
      });
    }
    return (
      <>
        <Modal
          isOpen={this.state.isOpen}
          toggle={this.closeModal}
          size="lg"
          className="productModal"
        >
          <ModalHeader>
            <div className="Heading">
              <h3>Upload Products Data</h3>
              <svg
                onClick={this.closeModal}
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.9092 1.82141L1.90918 13.8214"
                  stroke="#2800FC"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.90918 1.82141L13.9092 13.8214"
                  stroke="#2800FC"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="imageDiv">
              <div className="d-block">
                <svg
                  onClick={() => this.fileUploadTrigger()}
                  width="150"
                  height="150"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 31.6534C0.0758962 31.2107 0.126494 30.768 0.227689 30.3253C0.809559 27.6309 2.23894 25.5185 4.51582 23.9753C4.65497 23.8741 4.80676 23.7729 4.9712 23.659C4.17429 20.9521 4.57907 18.4855 6.60297 16.4616C8.61422 14.4503 11.0808 13.9949 13.7372 14.8045C17.0893 8.92253 22.0225 6.15232 28.7773 7.22752C33.9256 8.04973 39.1245 12.4517 39.9214 19.3709C40.2376 19.4468 40.5665 19.4974 40.8827 19.5986C44.8167 20.7876 47.511 23.3428 48.8265 27.2009C50.6607 32.5515 48.7633 37.9781 44.0704 41.1531C42.2995 42.3548 40.3262 42.9873 38.1631 42.9873C35.0008 42.9873 31.8511 42.9873 28.6888 42.9873C28.499 42.9873 28.3093 42.9873 28.0942 42.9873C28.0942 39.3949 28.0942 35.8657 28.0942 32.2733C28.2587 32.2606 28.4105 32.248 28.5623 32.248C29.4351 32.248 30.3079 32.2353 31.168 32.248C31.7246 32.2606 32.2432 32.2227 32.5342 31.6534C32.8378 31.0842 32.5595 30.6415 32.2306 30.1988C31.0415 28.5543 29.8525 26.9099 28.6635 25.2655C27.7021 23.9373 26.7408 22.5965 25.7794 21.2683C25.1343 20.3829 24.35 20.3702 23.7176 21.2683C21.5419 24.2662 19.3788 27.2768 17.2031 30.2747C17.1525 30.3379 17.1146 30.4012 17.064 30.4771C16.811 30.8565 16.7351 31.2487 16.9501 31.6661C17.1652 32.0709 17.5194 32.2606 17.9874 32.2606C19.1132 32.2606 20.239 32.2606 21.4027 32.2606C21.4027 35.853 21.4027 39.3949 21.4027 42.9999C21.2003 42.9999 21.0106 42.9999 20.8209 42.9999C17.3043 42.9999 13.7878 42.9873 10.2839 42.9999C6.78006 43.0126 4.11104 41.4314 2.0745 38.6865C0.974001 37.1939 0.328883 35.5115 0.0885456 33.6647C0.0758962 33.5761 0.0252987 33.4876 0 33.3991C0 32.8172 0 32.2353 0 31.6534Z"
                    fill="#C6C6C6"
                  />
                </svg>
                <input
                  type="file"
                  name="Image"
                  multiple={true}
                  accept=".xlsx"
                  style={{ display: "none" }}
                  ref={this.fileInput}
                  onChange={(e) => {
                    this.fileUpload(e);
                  }}
                />
                <span className="clickText">
                  {this.state.selectedFile?.name
                    ? this.state.selectedFile?.name
                    : "Click here to upload Excel"}
                </span>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              className="uploadTemplateButton"
              onClick={() => {
                this.handledownloadTemplate();
              }}
            >
              <span className="light__span">
                <i
                  className="fas fa-file-excel"
                  style={{ color: "#20511f" }}
                ></i>
                &nbsp;Template
              </span>
              <GetAppIcon fontSize="xs" className="downloadIcon" />
            </button>
            <button
              className="Submit"
              onClick={() => {
                this.handleSubmitExcel();
              }}
            >
              Submit
            </button>
          </ModalFooter>
        </Modal>

        {getLocalStorage()}
        <QuickFilters
          openModal={this.openModal}
          toggleExcel={this.exportExcelData}
        />
        <Container
          aria-hidden="true"
          fluid
          className={`${
            darkTheme ? "darkBackgroundList mobilepPadding" : "mobilepPadding"
          }`}
        >
          <div className="productList">
            <Index
              {...this.props}
              headers={AdminHeaderData}
              data={rows}
              searchable={true}
              searchBar={searchBar}
              // fetchId={this.props.fetchId}
              reset={this.reset}
              searchValueAssignerFunction={this.searchValueAssignerFunction}
              // fetchId={this.props.fetchId}
              getAllData={this.getAllDataReset}
              dataLength={90}
              rowHeight={"56px"}
              dataLoading={this.state.dataLoading}
              classList="3"
              textInput="inputIndex"
              resetCol="6"
            />
          </div>
          {totCnt !== 0 && !this.state.dataLoading ? (
            <Row
              xs={12}
              style={{ color: darkTheme ? "#9d9d9d" : "#0C0C0C" }}
              className="mb-2 paginationRow"
            >
              <Col xs={7} sm={5} md={4} className="span-col-pagenation">
                <div className="float-left">
                  <Pagination
                    className="desktop-pagination"
                    size="medium"
                    shape="rounded"
                    page={offset / 10 + 1}
                    count={totPages}
                    color="primary"
                    onChange={this.handleChangePage}
                    defaultPage={1}
                    siblingCount={1}
                  />
                  <Pagination
                    className="mobile-pagination"
                    size="small"
                    shape="rounded"
                    page={offset / 10 + 1}
                    count={totPages}
                    color="primary"
                    onChange={this.handleChangePage}
                    defaultPage={1}
                    siblingCount={1}
                  />
                </div>
              </Col>
              <Col
                className="mt-2 spanPagenationentries span-col-pagenation"
                xs={5}
                sm={7}
                md={8}
              >
                {" "}
                <span>
                  Showing {fromVal} to {toVal} of {totCnt} entries
                </span>
              </Col>
            </Row>
          ) : null}
          <UpdateProductModal
            modal={this.state.modal}
            toggle={this.togglemodal}
            onChangeInput={this.onChangeInputData}
            reset={this.reset}
            form={this.state.form}
            handleSubmit={this.handleSubmit}
            viewById={this.state.viewById}
            errors={this.state.errors}
          />
        </Container>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  // console.log("state", state);
  return {
    ...state,
    getProductsData: state?.productReducer?.getproductDetails,
  };
};

export default connect(mapStateToProps, {
  getProducts,
  getProductsExcel,
  fetchProductPresignedUrl,
  uploadImagesAws,
  createProductViaExcel,
  UpdateProductValues,
})(Product);
