import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import moment from "moment";
import { withRouter } from "react-router-dom";
import TransportCard from "./TransportCard";
// import files

import { getOperationalDashboard } from "../../../store/baxter-dashboard/actions";
import { roleCheck } from "../../restrict";
import DashboardCards from "./DashboardCards";
import OrdersCard from "./OrdersCard";
import DashboardHeader from "../CommonHeader/DashboardHeader";
import { changeSwitchTheme } from "../../../store/layout/actions";

// styles
import "./Styles/dashboard.css";
import { WaveLoader } from "../../../components/Common/Loader/LoaderIndex";
import { getOrders } from "../../../store/actions.js";

let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
var searchValues = {};
class OperationsDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoading: false,
      transpoter: [],
      selectedTranspoterValues: [],
      selectedHopValues: [],
      transporterValues: [],
      hopValues: [],
      trips: {},
      indents: {},
      hops: [],
      datevalues: [null, null],
      dates: [],
      offset: 0,
      limit: 10,
      transporter: "",
      city: "",
      state: "",
      filterTranspoter: "",
      searchFilters: {},
      selectedFilter: {
        value: "month",
        label: "Monthly",
      },
      selectedFilterOperational: {
        value: "month",
        label: "Monthly",
      },
    };
    if (process.env.REACT_APP_CLIENT != "3_BAXTER") {
      (async () => {
        let redirect = await roleCheck("transporter");
        if (redirect === true) {
          this.props.history.push("/assigned-indent");
        }
      })();
    }
  }
  onReset = () => {
    searchValues = {};
    this.setState(
      {
        datevalues: [null, null],
        searchFilters: {
          transporter: "",
          city: "",
          state: "",
        },
      },
      () => {
        this.getAllTripsData();
      }
    );
  };
  componentWillUnmount =()=>{
    searchValues = {};
    this.setState(
      {
        datevalues: [null, null],
        searchFilters: {},
      },
      () => {
       
      }
    );
  }
  componentDidMount = () => {
    this.getAllTripsData();
    this.getAllData();
    this.props.changeSwitchTheme();
  };
  getAllData = () => {
    let authUser = JSON.parse(window.localStorage.getItem("authUser"));
    let role = authUser?.data ? authUser?.data?.role?.label : "";

    if (authUser?.data) {
      let custCode;
      let passCust = "";
      let supervisorCode;
      let passSupervisor = "";
      if (role === "customer") {
        custCode =
          authUser?.data?.customersCode &&
          authUser?.data?.customersCode?.map((res) => {
            return res?.customerCode;
          });
        passCust = custCode.join(",");
      }
      if (role === "supervisor") {
        supervisorCode =
          authUser?.data?.cfaCode &&
          authUser?.data?.cfaCode?.map((ele) => {
            return ele;
          });
        passSupervisor = supervisorCode.join(",");
      }
      this.setState(
        {
          customerCode: passCust,
          cfaCodes: passSupervisor,
        },
        () => {
          this.props.getOrders(
            0,
            10,
            this.state.customerCode,
            this.state.cfaCodes,
            { isCancelledOrder: true },
            {},
            (res) => {}
          );
        }
      );
    }
  };
  onChangeDatePicker = (dateValues) => {
    searchValues["startDate"] =
      dateValues[0] !== null
        ? dateValues[0]
          ? moment(dateValues[0]).format("YYYY-MM-DD")
          : moment(dateValues).format("YYYY-MM-DD")
        : null;
    searchValues["endDate"] =
      dateValues[1] !== null
        ? moment(dateValues[1]).format("YYYY-MM-DD")
        : null;
    this.setState({ searchFilters: searchValues }, () => {
      this.state.offset = 0;
      this.getAllTripsData();
    });
  };

  onSelectValue = (selectKey, selectValue) => {
    searchValues[selectKey] = selectValue;
    if (!searchValues?.tripType) delete searchValues?.tripType;
    this.setState({ searchFilters: searchValues }, () => {
      this.state.offset = 0;
      this.getAllTripsData();
    });
  };
  onInputChange = (e) => {
    searchValues[e?.target?.name] = e?.target?.value;
    this.setState({ searchFilters: searchValues }, () => {
    });
  };
  
  enterCheck = (event) => {
    if (event.key === "Enter") {
      this.state.offset = 0;
      this.getAllTripsData();
    }
  };

  // get all operations data
  getAllTripsData = () => {
    const { offset, limit } = this.state;
    this.setState({ dataLoading: true });

    this.props.getOperationalDashboard(
      { offset: offset ? offset : "", limit: 10 },
      this.state.searchFilters,
      () => {
        this.setState({ dataLoading: false }, () => {});
      }
    );
  };

  refineData = () => {
    this.setState({ loading: false });
  };

  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    const localStorageData = JSON.parse(
      localStorage.getItem("operationalDashboardData")
    );
    const TripsCountData = navigator.onLine
      ? this.props?.TripsCountData
      : localStorageData?.data;
    let tripData = TripsCountData?.tripInformation?.trips;
    let orderData = TripsCountData?.orderInformation?.orders;
    let orderInformation = TripsCountData?.orderInformation;
    // let orderInformation = dummy?.data?.orderInformation;
    let tripInformation = TripsCountData?.tripInformation;
    var tripInfo = {
      planned: 0,
      rejected: 0,
      cancelled: 0,
      completed: 0,
      arrivedAtVia: 0,
      epodInProgress: 0,
      loadingInProgress: 0,
      inTransit: 0,
      arrivedAtSource: 0,
      deleyed: TripsCountData?.tripInformation?.delayed,
      unTrackedWithConsent:
        TripsCountData?.tripInformation?.unTrackedWithConsent,
      unTrackedWithoutConsent:
        TripsCountData?.tripInformation?.unTrackedWithoutConsent,
      total: 0,
      shipmentVerified: 0,
      qualityCheckCompleted: 0,
      trackedTrips: TripsCountData?.tripInformation?.trackedTrips ?? 0,
      untrackedTrips: TripsCountData?.tripInformation?.untrackedTrips ?? 0,
      courier: TripsCountData?.tripInformation?.courier ?? 0,
      truck: TripsCountData?.tripInformation?.truck ?? 0,
      tripsWithOutEwayBillNumber: TripsCountData?.tripInformation?.tripsWithOutEwayBillNumber ?? 0,
    };
    var tot = 0;
    if (tripData !== undefined) {
      tripData.map((trip) => {
        tot = tot + trip?.total;
        tripInfo.total = tot;
        if (trip?.subStatus === "planned") {
          tripInfo.planned = trip?.total;
        } else if (trip?.subStatus === "rejected") {
          tripInfo.rejected = trip?.total;
        } else if (trip?.subStatus === "cancelled") {
          tripInfo.cancelled = trip?.total;
        } else if (trip?.subStatus === "completed") {
          tripInfo.completed = trip?.total;
        } else if (trip?.subStatus === "arrived_at_via") {
          tripInfo.arrivedAtVia = trip?.total;
        } else if (trip?.subStatus === "epod_in_progress") {
          tripInfo.epodInProgress = trip?.total;
        } else if (trip?.subStatus === "loading_in_progress") {
          tripInfo.loadingInProgress = trip?.total;
        } else if (trip?.subStatus === "in_transit") {
          tripInfo.inTransit = trip?.total;
        } else if (trip?.subStatus === "arrived_at_source") {
          tripInfo.arrivedAtSource = trip?.total;
        } else if (trip?.subStatus === "shipment_verified") {
          tripInfo.shipmentVerified = trip?.total;
        } else if (trip?.subStatus === "quality_check_completed") {
          tripInfo.qualityCheckCompleted = trip?.total;
        }
        return null;
      });
    }

    var orderInfo = {
      orderPunched: 0,
      orderRelease: 0,
      invoiceGenerated: 0,
      orderDispatch: 0,
      orderDeliver: 0,
      punchToDispatch: 0,
      orderCancelled: 0,
      dispatchToDelivery: 0,
      backOrders: 0,
      total: 0,
    };
    var orderTotal = 0;
    if (orderData !== undefined) {
      orderData.map((order) => {
        orderTotal = orderTotal + order?.total;
        orderInfo.total = orderTotal;
        if (order?.status === "ORDER_PUNCHED") {
          orderInfo.orderPunched = order?.total;
        } else if (order?.status === "ORDER_RELEASED") {
          orderInfo.orderRelease = order?.total;
        } else if (order?.status === "INVOICE_GENERATED") {
          orderInfo.invoiceGenerated = order?.total;
        } else if (order?.status === "ORDER_DISPATCHED") {
          orderInfo.orderDispatch = order?.total;
        } else if (order?.status === "ORDER_DELIVERED") {
          orderInfo.orderDeliver = order?.total;
        }

        return null;
      });
    }
    if (orderInformation) {
      const {
        punchToDispatchTimeInMS,
        dispatchToDeliveredTimeInMS,
        cancelledOrders,
        backOrders,
      } = orderInformation;

      if (punchToDispatchTimeInMS) {
        const punchToDispatch = convertTime(punchToDispatchTimeInMS);
        orderInfo.punchToDispatch = punchToDispatch;
      }

      if (dispatchToDeliveredTimeInMS) {
        const dispatchToDelivery = convertTime(dispatchToDeliveredTimeInMS);
        orderInfo.dispatchToDelivery = dispatchToDelivery;
      }
      if (cancelledOrders) {
        orderInfo.orderCancelled = cancelledOrders;
      }
      if (backOrders) {
        orderInfo.backOrders = backOrders;
      }
    }


    function convertTime(milliseconds) {
      const minutes = Math.floor(milliseconds / (1000 * 60));
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      return `${days}day ${hours % 24}hrs ${minutes % 60}min`;
    }

    console.log(orderInformation, orderInfo, "90");
    let switchTheme = this.props.switchsTheme;
    let initStorage = localStorage.getItem("Theme");
    var opCardStyle;
    var OperationsBgClr;

    if (switchTheme === "dark__mode" || initStorage === "dark__mode") {
      OperationsBgClr = "#1B1C31";
      opCardStyle = true;
    } else if (switchTheme === "light__mode" || initStorage === "light__mode") {
      OperationsBgClr = "#FBFBFB";
      opCardStyle = false;
    }
    return (
      <React.Fragment>
        <Grid
          style={{
            backgroundColor: `${OperationsBgClr}`,
          }}
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{
              width: "100%",
            }}
            className="pdt25"
          >
            <DashboardHeader
              {...this.props}
              datevalues={this.state.datevalues}
              handleChange={this.onChangeDatePicker}
              onReset={this.onReset}
              userLang={userLang}
              onInputChange={this.onInputChange}
              enterCheck={this.enterCheck}
            
              onSelectValue={this.onSelectValue}
            />
          </Grid>
          {this.state.dataLoading ? (
            <WaveLoader />
          ) : (
            <>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <DashboardCards
                  trips={tripInfo}
                  history={this.props.history}
                  userLang={userLang}
                  opCardStyle={opCardStyle ? opCardStyle : ""}
                  topFilters ={this.state.searchFilters}
                />
              </Grid>
              <Grid item xs={12} className="mt-2 pt-1 grid-card-container">
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                  className="pl-2 pr-2 "
                >
                  <Grid item xs={12} md={9} lg={8}>
                    <Grid
                      container
                      spacing={2}
                      justifyContent="center"
                      alignItems="center"
                      className="pl-2 pr-2"
                      style={{ marginBottom: "" }}
                    >
                      <Grid item xs={12} md={12} lg={12} className="pdt25">
                        <OrdersCard
                          orders={orderInfo}
                          history={this.props.history}
                          userLang={userLang}
                          opCardStyle={opCardStyle ? opCardStyle : ""}
                          tripInformation={tripInformation}
                          topFilters ={this.state.searchFilters}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={3} lg={4}>
                    {/* <TripStatusCard
                      tripData={tripData}
                      trips={tripInfo}
                      userLang={userLang}
                    /> */}

                    <TransportCard
                      tripInformation={tripInformation}
                      userLang={userLang}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    TripsCountData:
      state.operationalDashboardReducer.getOperationalDashboard?.data,
    switchsTheme: state.Layout.switchTheme,
    getOrdersData: state?.orderListReducer?.getOrderListData,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    changeSwitchTheme,
    getOperationalDashboard,
    getOrders,
  })(OperationsDashboard)
);
