let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  AdminHeaderData = {
    columns: [
      {
        label: "Trip ID",
        field: "tripId",
        sort: "asc",
        width: 2,
      },
      {
        label: "Order Number",
        field: "orderNumber",
        sort: "asc",
        width: 2,
      },
      {
        label: "Invoice Number",
        field: "invoiceNumber",
        sort: "asc",
        width: 2,
      },
      {
        label: "Truck Capacity (MT)",
        field: "truckCapacity",
        sort: "asc",
        width: 2,
      },
      {
        label: "Product Moved (MT)",
        field: "totalTruckUtilizationInMT",
        sort: "asc",
        width: 2,
      },
      {
        label: "(%) Utilization",
        field: "truckUtilizationInPercent",
        sort: "asc",
        width: 2,
      },
      {
        label: "Trip Date",
        field: "tripCreationDate",
        sort: "asc",
        width: 2,
      },
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var AdminHeaderData = {
  columns: [
    {
      label: "Trip ID",
      field: "tripId",
      sort: "asc",
      width: 2,
    },
    {
      label: "Order Number",
      field: "orderNumber",
      sort: "asc",
      width: 2,
    },
    {
      label: "Invoice Number",
      field: "invoiceNumber",
      sort: "asc",
      width: 2,
    },
    {
      label: "Truck Capacity (MT)",
      field: "truckCapacity",
      sort: "asc",
      width: 2,
    },
    {
      label: "Product Moved (MT)",
      field: "productMoved",
      sort: "asc",
      width: 2,
    },
    {
      label: "(%) Utilization",
      field: "tripCreationDate",
      sort: "asc",
      width: 2,
    },
  ],
};
export var searchBar;
searchBar = {
  searchBarData: [],
};
