import { types } from "./actionTypes";

// creating action
export const fetchCustomerPresignedUrl = (callback) => {
  return {
    type: types.GET_CUSTOMER_PRESIGNED_URL,
    callback,
  };
};

export const fetchLocationPresignedUrl = (callback) => {
  return {
    type: types.GET_LOCATION_PRESIGNED_URL,
    callback,
  };
};
export const fetchProductPresignedUrl = (callback) => {
  return {
    type: types.GET_PRODUCT_PRESIGNED_URL,
    callback,
  };
};
export const fetchLogbookPresignedUrl = (callback) => {
  return {
    type: types.GET_LOGBOOK_PRESIGNED_URL,
    callback,
  };
};
export const fetchUsersExcelPresignedUrl = (callback) => {
  return {
    type: types.UESER_EXCEL_PRESIGNED_URL,
    callback,
  };
};

export const tripCompletedFileUrl = (fileType, callback) => ({
  type: types.COMPLETE_TRIP_PRESISNED_URL,
  fileType,
  callback,
});

export const uploadImagesAws = (uri, data, headers) => ({
  type: types.UPLOAD_IMAGE1,
  uri,
  data,
  headers,
});
export const uploadImagesAws1 = (uri, data, headers, callback) => ({
  type: types.UPLOAD_IMAGE_CALLBACK,
  uri,
  data,
  headers,
  callback,
});
