import React, { Component } from "react";
import { connect } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import { restrict } from "../restrict";
import { PERMS } from "../../enum/perms.enum";
import { Row, Col } from "reactstrap";

//components
import QuickFilters from "./QuickFilter";
import Index from "../../components/Common/DataTableNeo/Index";
// files
import { HeaderData, searchBar, getLocalStorage } from "./RowHeader.js";
// actions
import { fetchIndentsForMaster } from "../../store/indent/actions";
import { fetchHops } from "../../store/hop/actions";
import { fetchAllTransporterSearch } from "../../store/transporter/actions";
import moment from "moment";

var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var darkTheme;

class IndentsForMasters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      offset: 0,
    };
    if (process.env.REACT_APP_CLIENT != "3_BAXTER") {
      (async () => {
        let redirect = await restrict(PERMS.DRIVER_TRANSPORTER_READ);

        if (redirect === false) {
          this.props.history.push("/operationsDashboard");
        }
      })();
    }
    // this.handleInputDebounceName = debounce(this.getDriverNameFunc, 1000);
    // this.handleInputDebouncePhone = debounce(this.getDriverPhoneFunc, 1000);
  }
  componentDidMount = () => {
    offset = 0;
    this.setState({ loading: true });
    this.getAllData();
    this.getAllSourceHopData();
    this.fetchAllTransporterSearch({
      offset: 0,
      limit: 10,
      q: "",
    });
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 0.9;
  }
  getAllData = () => {
    this.setState({ loading: true }, () => {});
    this.props.fetchIndentsForMaster(
      {
        offset: offset,
        source: this.state.filterNames?.source?.value?.id,
        destination: this.state.filterNames?.destination?.value?.id,
        actualIndents: this.state.filterNames?.actualIndents,
        indentPlanningCode: this.state.filterNames?.indentPlanningCode,
        kms: this.state.filterNames?.kms,
        laneLot: this.state.filterNames?.laneLot,
        // laneLot:
        //   this.state.filterNames?.laneLot?.length > 0
        //     ? this.state.filterNames?.laneLot.toUpperCase()
        //     : "",
        leadTime: this.state.filterNames?.leadTime,
        lotNo: this.state.filterNames?.lotNo,
        targetIndents: this.state.filterNames?.targetIndents,
        transporter: this.state.filterNames?.transporter?.value,
        validityFrom:
          this.state.filterNames?.validity?.length > 0
            ? moment(this.state.filterNames?.validity[0]).format("MM-DD-YYYY")
            : "",
        validityTo:
          this.state.filterNames?.validity?.length > 0
            ? moment(this.state.filterNames?.validity[1]).format("MM-DD-YYYY")
            : "",
      },
      () => {
        this.setState({ loading: false });
      }
    );
  };
  handleChangePage = (event, val) => {
    // console.log(event, val, "handlepage");
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    this.setState({ loading: true });
    this.getAllData();
  };
  getAllSourceHopData = () => {
    let filtersHopsData = {
      offset: 0,
      limit: 10,
      q: "",
    };
    this.fetchHops(filtersHopsData);
  };
  // calling hops api
  fetchHops = (filtersHopsData) => {
    this.props.fetchHops(
      {
        offset: filtersHopsData.offset,
        limit: filtersHopsData.limit,
        label: filtersHopsData.q,
      },
      (res) => {
        this.hops = [];
        if (res?.data?.docs?.length > 0) {
          // this.hops = [this.initialVal("Location")];
          res.data.docs.forEach((ele) => {
            this.hops.push({
              label: ele.hopCode + " (" + ele.label + ")",
              value: {
                name:
                  ele.address.addressLine1 +
                  ele.address.city +
                  ele.address.state,
                hopCode: ele.hopCode,
                id: ele._id,
              },
            });
          });
          this.setState({ hops: this.hops }, () => {});
        }
      }
    );
  };
  // get location name by filter
  filtersHops = (e) => {
    let pattern = /[0-9]/g;

    if (e?.length > 2) {
      let filtersHopsData = {
        offset: 0,
        limit: 10,
        q: e.match(pattern) ? "" : e,
        hopCode: e.match(pattern) ? e : "",
      };
      this.fetchHops(filtersHopsData);
    }
    if (e?.length === 0) {
      let filtersHopsData = {
        offset: 0,
        limit: 10,
        q: " ",
        hopCode: "",
      };
      this.fetchHops(filtersHopsData);
    }
  };

  filterDestinationHops = (e) => {
    if (e?.length > 2) {
      let filtersHopsData = {
        offset: 0,
        limit: 10,
        q: e,
      };
      this.fetchHops(filtersHopsData);
    }
    if (e.length === 0) {
      let filtersHopsData = {
        offset: 0,
        limit: 10,
        q: "",
      };
      this.fetchHops(filtersHopsData);
    }
  };

  fetchAllTransporterSearch = (filtersData) => {
    this.props.fetchAllTransporterSearch(
      filtersData.offset,
      filtersData.limit,
      filtersData.q,
      (res) => {
        let transporter = [];
        if (res.data?.docs?.length > 0) {
          res.data.docs.forEach((ele) => {
            transporter.push({
              value: ele._id ? ele._id : "",
              label: ele.name ? ele.name : "",
            });
          });
        }

        this.setState({ allTransporter: transporter }, () => {});
      }
    );
  };
  filtersTranspoter = (e) => {
    if (e?.length > 2) {
      let filterData = {
        offset: 0,
        limit: 10,
        q: e,
      };
      this.fetchAllTransporterSearch(filterData);
    }
    if (e?.length === 0) {
      let filterData = {
        offset: 0,
        limit: 10,
        q: "",
      };
      if (this.state.allTransporter?.length === 0)
        this.fetchAllTransporterSearch(filterData);
    }
  };
  handleChangeFilters = (type) => {
    console.log("type", type);
    this.setState(
      {
        filterNames: {
          ...this.state.filterNames,
          [type?.name]: type?.value,
        },
      },
      () => {
        console.log(this.state?.filterNames);
        if (
          type?.name == "source" ||
          type?.name == "destination" ||
          type?.name == "transporter"
        ) {
          this.getAllData();
        }
        if (type?.name === "validity") {
          if (type?.value?.length >= 2) {
            this.getAllData();
          }
        }
      }
    );
  };
  reset = () => {
    this.setState(
      {
        filterNames: {
          source: "",
          destination: "",
          actualIndents: "",
          indentPlanningCode: "",
          kms: "",
          laneLot: "",
          leadtime: "",
          lotNo: "",
          targetIndents: "",
          transporter: "",
        },
      },
      () => {
        this.getAllData();
        console.log("filters", this.state.filterNames);
      }
    );
  };
  enterCheck = (event) => {
    if (event.key === "Enter") {
      this.getAllData();
    }
  };
  filterList = () => {
    this.getAllData();
  };
  render() {
    let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    darkTheme = mode === "dark__mode" ? true : false;
    // const { name, phoneNo, loading } = this.state;
    const { IndentData } = this.props;
    const localStorageData = JSON.parse(localStorage.getItem("RoutesData"));
    const indentsDataForList = navigator.onLine
      ? IndentData?.data?.docs
      : localStorageData?.data?.docs;
    let rows = [];
    if (Array.isArray(indentsDataForList)) {
      totCnt = navigator.onLine
        ? IndentData?.data?.length
        : localStorageData?.data?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = navigator.onLine
        ? IndentData?.data?.offset + IndentData?.data?.docs?.length
        : localStorageData?.data?.offset + localStorageData?.data?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      indentsDataForList.forEach((ele) => {
        rows.push({
          actualIndents: ele?.actualIndents,
          indentPlanningCode: ele?.indentPlanningCode,
          kms: ele?.kms,
          laneLot: ele?.laneLot,
          leadtime: ele?.leadTime,
          lotNo: ele?.lotNo,
          destination: ele?.destination?.label,
          source: ele?.source?.label,
          targetIndents: ele?.targetIndents,
          transporter: ele?.transporterName,
          validity: ele?.validity
            ? moment(ele?.validity?.from).format("DD/MM/YYYY") +
              " - " +
              moment(ele?.validity?.to).format("DD/MM/YYYY")
            : "",
        });
      });
    }
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let userType = authUser?.data?.role?.label;
    return (
      <React.Fragment>
        {getLocalStorage()}
        <QuickFilters
          reset={this.reset}
          filterList={this.filterList}
          enterCheck={this.enterCheck}
          mode={darkTheme}
          handleChangeFilters={this.handleChangeFilters}
          filterNames={this.state.filterNames}
          hopOptions={this.state.hops}
          filtersHops={this.filtersHops}
          filtersTranspoter={this.filtersTranspoter}
          allTransporter={this.state.allTransporter}
        />
        <Index
          {...this.props}
          headers={HeaderData}
          data={rows}
          searchBar={searchBar}
          // fetchId={this.props.fetchId}
          getAllData={this.getAllData}
          dataLength={IndentData?.data?.length}
          rowHeight={"56px"}
          dataLoading={this.state.loading}
        />
        {totCnt !== 0 && !this.state.loading ? (
          <Row
            xs={12}
            style={{ color: darkTheme ? "#9d9d9d" : "#0C0C0C" }}
            className="mb-2 paginationRow"
          >
            <Col xs={7} sm={5} md={4} className="span-col-pagenation">
              <div className="float-left">
                <Pagination
                  className="desktop-pagination"
                  size="medium"
                  shape="rounded"
                  page={offset / 10 + 1}
                  count={totPages}
                  color="primary"
                  onChange={this.handleChangePage}
                  defaultPage={1}
                  siblingCount={1}
                />
                <Pagination
                  className="mobile-pagination"
                  size="small"
                  shape="rounded"
                  page={offset / 10 + 1}
                  count={totPages}
                  color="primary"
                  onChange={this.handleChangePage}
                  defaultPage={1}
                  siblingCount={1}
                />
              </div>
            </Col>
            <Col
              className="mt-2 spanPagenationentries span-col-pagenation"
              xs={5}
              sm={7}
              md={8}
            >
              {" "}
              <span>
                Showing {fromVal} to {toVal} of {totCnt} entries
              </span>
            </Col>
          </Row>
        ) : null}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  console.log(state);
  return {
    IndentData: state?.Indent?.indents?.indentsForMaster,
  };
};

export default connect(mapStateToProps, {
  fetchIndentsForMaster,
  fetchHops,
  fetchAllTransporterSearch,
})(IndentsForMasters);
