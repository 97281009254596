export const types = {
  GET_TRIP_LIST: "GET_TRIP_LIST",
  GET_TRIP_LIST_SUCCESS: "GET_TRIP_LIST_SUCCESS",
  GET_TRIP_LIST_FOR_EXCEL: "GET_TRIP_LIST_FOR_EXCEL",
  GET_TRIP_LIST_FOR_EXCEL_SUCCESS: "GET_TRIP_LIST_FOR_EXCEL_SUCCESS",

  CHANGE_MARKED_AS_ARRIVED_STATUS: "CHANGE_MARKED_AS_ARRIVED_STATUS",
  CHANGE_MARKED_AS_ARRIVED_STATUS_SUCCESS:
    "CHANGE_MARKED_AS_ARRIVED_STATUS_SUCCESS",

  QUALITY_CHECK_START: "QUALITY_CHECK_START",
  QUALITY_CHECK_START_SUCCESS: "QUALITY_CHECK_START_SUCCESS",

  START_LOADING_STATUS: "START_LOADING_STATUS",
  START_LOADING_STATUS_SUCCESS: "START_LOADING_STATUS_SUCCESS",

  LOADING_COMPLETED: "LOADING_COMPLETED",
  LOADING_COMPLETED_SUCCESS: " LOADING_COMPLETED_SUCCESS",

  VIEW_TRIP_HISTORY: "VIEW_TRIP_HISTORY",
  VIEW_TRIP_HISTORY_SUCCESS: "VIEW_TRIP_HISTORY_SUCCESS",

  COMPLETE_TRIP: "COMPLETE_TRIP",
  COMPLETE_TRIP_SUCCESS: "COMPLETE_TRIP_SUCCESS",

  UPDATE_MOBILE_TRACK: "UPDATE_MOBILE_TRACK",
  UPDATE_MOBILE_TRACK_SUCCESS: "UPDATE_MOBILE_TRACK_SUCCESS",
};
