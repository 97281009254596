import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchShareOfBusiness } from "../../store/Reports/actions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { WaveLoader } from "../../components/Common/Loader/LoaderIndex";

class shareOfBusiness extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sobData: [],
      total: 0,
      loading: false,
    };
  }
  componentDidMount() {
    this.getAllData();
  }
  getAllData = () => {
    let values = {
      transporter: [],
      FromDate: "",
      ToDate: "",
    };
    this.setState({
      loading: true,
    });
    this.props.fetchShareOfBusiness(0, 100, values, (res) => {
      if (res?.data?.docs?.length > 0) {
        var uniquelotName = [
          ...new Set(res?.data?.docs.map((element) => element.lotName)),
        ];
        var uniqueLaneLot = [
          ...new Set(res?.data?.docs?.map((element) => element.laneLot)),
        ];
        var uniqueTransporter = [
          ...new Set(res?.data?.docs?.map((element) => element.transporter)),
        ];
        var lotNameFilteration = [];
        uniquelotName.forEach((ele, i) => {
          var lotNameCheck = [];
          uniqueTransporter.forEach((transporter) => {
            uniqueLaneLot.forEach((lotData) => {
              var lane = [];
              res.data.docs.forEach((data) => {
                if (
                  ele.includes(data.lotName) &&
                  lotData.includes(data.laneLot) &&
                  transporter.includes(data.transporter)
                ) {
                  lane.push(data);
                }
              });
              if (lane.length > 0) lotNameCheck.push(lane);
            });
          });
          if (lotNameCheck.length > 0)
            lotNameFilteration.push({
              lotName: `${ele}`,
              laneLot: lotNameCheck,
            });
        });

        const sobTotal = this.freightCalculate(res?.data?.docs);
        this.setState({
          sobData: lotNameFilteration,
          total: sobTotal,
          loading: false,
        });
      }
    });
  };
  freightCalculate = (data) => {
    if (data.length > 0) {
      return data.reduce((next, number) => {
        return next + number?.freightTotal;
      }, 0);
    }
    return 0;
  };
  render() {
    return (
      <div>
        {this.state.loading ? (
          <WaveLoader />
        ) : (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>LOT NAME</TableCell>
                  <TableCell>LOT Category</TableCell>
                  <TableCell>Transporter Name</TableCell>
                  <TableCell>Count of Vehicles</TableCell>
                  <TableCell>Sum of Freight</TableCell>
                  <TableCell>SOB %</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state?.sobData?.length > 0 &&
                  this.state?.sobData?.map((lotData) => {
                    return (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {lotData?.lotName}
                        </TableCell>

                        <>
                          <TableCell>
                            {lotData?.laneLot?.length > 0 &&
                              lotData?.laneLot?.map((ele) => {
                                return (
                                  <>
                                    <TableRow>
                                      <TableCell>{ele[0]?.laneLot}</TableCell>
                                    </TableRow>
                                  </>
                                );
                              })}
                          </TableCell>

                          <TableCell align="right">
                            {lotData?.laneLot?.length > 0 &&
                              lotData?.laneLot?.map((ele) => {
                                return (
                                  <>
                                    <TableRow>
                                      <TableCell>
                                        {ele[0]?.transporter}
                                      </TableCell>
                                    </TableRow>
                                  </>
                                );
                              })}
                          </TableCell>
                          <TableCell align="right">
                            {lotData?.laneLot?.length > 0 &&
                              lotData?.laneLot?.map((ele) => {
                                return (
                                  <>
                                    <TableRow>
                                      <TableCell>{ele?.length}</TableCell>
                                    </TableRow>
                                  </>
                                );
                              })}
                          </TableCell>
                          <TableCell align="right">
                            {lotData?.laneLot?.length > 0 &&
                              lotData?.laneLot?.map((ele) => {
                                return (
                                  <>
                                    <TableRow>
                                      <TableCell>
                                        {this.freightCalculate(ele)}
                                      </TableCell>
                                    </TableRow>
                                  </>
                                );
                              })}
                          </TableCell>
                          <TableCell align="right">
                            {lotData?.laneLot?.length > 0 &&
                              lotData?.laneLot?.map((ele) => {
                                return (
                                  <>
                                    <TableRow>
                                      <TableCell>
                                        {parseInt(
                                          (this.freightCalculate(ele) /
                                            this.state.total) *
                                            100
                                        )}{" "}
                                        %
                                      </TableCell>
                                    </TableRow>
                                  </>
                                );
                              })}
                          </TableCell>
                        </>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
    fetchId: state.Reports.reports.fetchId,
    shareOfBusiness: state.Reports.reports.shareOfBusiness,
  };
};
export default connect(mapStateToProps, { fetchShareOfBusiness })(
  shareOfBusiness
);
