import { types } from "./actionTypes";
export const tripCountByStatus = (callback) => ({
  type: types.TRIP_COUNT_BY_STATUS,
  callback,
});
export const operationsDashboardTripsAndIndent = (
  transporter,
  hop,
  startDate,
  endDate,
  carbonEmissionDataBy,
  OperationsDataBy,
  callback
) => ({
  type: types.OPERATIONS_TRIP_INDENT_BY_STATUS,
  transporter,
  hop,
  startDate,
  endDate,
  carbonEmissionDataBy,
  OperationsDataBy,
  callback,
});

export const transporterHistoryTripsByStatus = (
  hop,
  startDate,
  endDate,
  callback
) => ({
  type: types.TRANSPORTER_TRIP_HISTORY_BY_STATUS,
  hop,
  startDate,
  endDate,
  callback,
});
export const fetchInvoiceDashboard = (
  transporter,
  state,
  startDate,
  endDate,
  freightCostDataBy,
  freightAndShipmentCostDataBy,
  callback
) => ({
  type: types.INVOICE_DASHBOARD_BY_STATUS,
  transporter,
  state,
  startDate,
  endDate,
  freightCostDataBy,
  freightAndShipmentCostDataBy,
  callback,
});
