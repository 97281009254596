import React, { Component } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Button,
  FormGroup,
  InputGroup,
  Input,
} from "reactstrap";

import Breadcrumbs from "../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { MoonLoader } from "react-spinners";
import {
  fetchOrders,
  deleteOrder,
  fetchOrdersfilter,
} from "../store/order/actions";
import { connect } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import { getAccessForEntity } from "../pages/policies";
// import Eta from "../helpers/ETA";
import moment from "moment";
import { Grid } from "@material-ui/core";

var data = {};
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      rows: [],
      breadcrumbItems: [
        { title: "Order", link: "#" },
        { title: "Order Details", link: "#" },
      ],
      filters: "",
      key: "ExternalOrderId",
      searchOn: false,
    };
  }

  componentDidMount = () => {
    this.getAllData();
  };
  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
  };
  hasAccessPolicy(val, entity) {
    const { policies } = this.props;
    let hasAccess = false;

    if (policies && policies.length > 0) {
      hasAccess = getAccessForEntity(policies, entity, val);
    }
    return hasAccess;
  }
  getAllData = () => {
    this.props.fetchOrders(offset, (res) => {
      this.refineData(res);
      this.setState({ loading: false });
    });
  };
  refineData(res) {
    if (Array.isArray(res.docs)) {
      totCnt = res.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = res.offset + res.docs.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      let rows = [];
      res.docs.forEach((ele) => {
        rows.push({
          ProductCode: ele.ProductCode,
          ProductDimension: ele.ProductDimension,
          ProductName: ele.ProductName,
          Quantity: ele.Quantity,
          ProductPrice: ele.ProductPrice,
          SubTotal: ele.SubTotal,
          ExternalOrderId: ele.ExternalOrderId,
          OrderStatus: ele.OrderStatus,
          StockType: ele.StockType,
          DealerCode:
            ele.DealerCode && ele.DealerCode.dealerCode
              ? ele.DealerCode.dealerCode
              : "-",
          ETA: ele.EstimatedTime
            ? moment(ele.EstimatedTime).format("DD-MM-YYYY")
            : "-",
          ExternalOrderDate: ele.ExternalOrderDate
            ? moment(ele.ExternalOrderDate).format("DD-MM-YYYY")
            : "-",
          clickEvent: () => {
            this.tableRowEvents(ele);
          },
          // actions: (
          //   <div>
          //     <Button onClick={() => this.deleteEmp(ele._id)}>X</Button>
          //     <span>
          //       <i className="fas fa-pencil-alt"></i>{" "}
          //     </span>
          //   </div>
          // ),
        });
      });
      this.setState({ rows });
    }
  }

  deleteEmp(id) {
    this.props.deleteOrder(id, () => {
      this.getAllData();
    });
  }
  handleChangePage = (event, val) => {
    this.setState({ loading: true });
    if (this.state.searchOn !== true) {
      offset = (val - 1) * process.env.REACT_APP_PERPAGE;
      this.getAllData();
    } else {
      offset = (val - 1) * process.env.REACT_APP_PERPAGE;
      this.props.fetchOrdersfilter(
        offset,
        this.state.filters,
        this.state.key,
        (res) => {
          this.refineData(res);
          this.setState({ loading: false });
        }
      );
    }
  };
  tableRowEvents = (row) => {
    this.props.history.push(`/order-detail/` + row._id);
  };

  filterList = (e) => {
    if (this.state.searchOn !== true) this.setState({ searchOn: true });
    if (this.state.filters.length > 2) {
      offset = 0;
      // console.error(e,"e")
      this.setState({ loading: true });
      this.props.fetchOrdersfilter(
        offset,
        this.state.filters,
        this.state.key,
        (res) => {
          this.refineData(res);
          this.setState({ loading: false });
        }
      );
    } else {
      this.getAllData();
    }
  };
  change(e) {
    // console.error(e,"e")
    let filters = e.target.value;
    this.setState({ filters });
  }
  changed = (e) => {
    let key = e.target.value;
    this.setState({ key });
  };

  reset = (e) => {
    this.setState({ loading: true, searchOn: false });
    offset = 0;
    this.props.fetchOrders(offset, (res) => {
      this.refineData(res);
      this.setState({ loading: false, filters: "" });
    });
  };

  render() {
    const { loading, rows } = this.state;
    if (loading) {
      return (
        <div className="page-content">
          <Col xs={12} className="loader">
            <MoonLoader size={45} color={"#123abc"} />
          </Col>
        </div>
      );
    }
    // let featureToggle=this.props.user.featureId?this.props.user.featureId.featureModules:[];
    // featureToggle.map((el,i)=>{
    //   console.log(el,"el")
    //   if(el.name=="order"){
    //     if(el.access===false){
    //       this.props.history.push('/pages-401');
    //     }
    //   }
    //  } )

    const optionList = [
      { key: "ExternalOrderId", value: "External Order ID" },
      { key: "dealerCode", value: "Dealer Code" },
      { key: "StockType", value: "Stock Type" },
      { key: "OrderStatus", value: "Order Status" },
    ];

    data = {
      columns: [
        {
          label: "ProdCode",
          field: "ProductCode",
          sort: "asc",
          width: 200,
        },
        {
          label: "ProdDimension",
          field: "ProductDimension",
          sort: "asc",
          width: 200,
        },
        {
          label: "ProdName",
          field: "ProductName",
          sort: "asc",
          width: 150,
        },
        {
          label: "Quantity",
          field: "Quantity",
          sort: "asc",
          width: 100,
        },
        {
          label: "ProdPrice",
          field: "ProductPrice",
          sort: "asc",
          width: 100,
        },
        {
          label: "SubTotal",
          field: "SubTotal",
          sort: "asc",
          width: 100,
        },
        {
          label: "Status",
          field: "OrderStatus",
          sort: "asc",
          width: 100,
        },
        {
          label: "StockType",
          field: "StockType",
          sort: "asc",
          width: 100,
        },
        {
          label: "Ext.OrderId",
          field: "ExternalOrderId",
          sort: "asc",
          width: 100,
        },
        {
          label: "Estimated(ETA)",
          field: "ETA",
          sort: "asc",
          width: 300,
        },
        {
          label: "ExternalOrderDate",
          field: "ExternalOrderDate",
          sort: "asc",
          width: 300,
        },
        {
          label: "DealCode",
          field: "DealerCode",
          sort: "asc",
          width: 100,
        },
        // {
        //   label: "Actions",
        //   field: "actions",
        //   sort: "asc",
        //   width: 150,
        // },
      ],
      rows: rows,
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Order"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            {this.hasAccessPolicy("create", "order") ? (
              <FormGroup className="mb-2 text-right">
                <div>
                  <Link to="/add-order" className="small">
                    {" "}
                    <Button type="button" color="btn btn-primary">
                      Add Order(Excel)
                    </Button>
                  </Link>
                </div>
              </FormGroup>
            ) : null}

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <Grid container className="mb-2" alignItems="center">
                      <Grid item xs={9} sm={12} md={3}>
                        <h4 className="card-title"> Order Details </h4>
                      </Grid>
                      <Grid item xs={12} sm={4} md={3} className="mr-2">
                        <Input
                          className="form-control mr-2 mt-2"
                          type="select"
                          name="key"
                          placeholder="key"
                          aria-label="key"
                          onChange={(e) => this.changed(e)}
                          value={this.state.key}
                        >
                          {optionList.map((item, index) => (
                            <option key={index} value={item.key}>
                              {item.value}
                            </option>
                          ))}
                        </Input>
                      </Grid>
                      <Grid item xs={12} sm={7} md={3} className="mr-2">
                        <InputGroup>
                          <Input
                            className="form-control mr-2 mt-2"
                            type="text"
                            name="filters"
                            placeholder="Search"
                            aria-label="Search"
                            onChange={(e) => this.change(e)}
                            value={this.state.filters}
                          />
                        </InputGroup>
                      </Grid>
                      <Grid item className="mr-2 mt-2">
                        <Button
                          type="button"
                          color="btn btn-primary"
                          onClick={this.filterList}
                          disabled={this.state.filters.trim() === ""}
                        >
                          Search
                        </Button>
                      </Grid>
                      <Grid item className="ml-1 mt-2">
                        <Button
                          type="button"
                          color="btn btn-primary"
                          disabled={this.state.searchOn === false}
                          onClick={this.reset}
                        >
                          Reset
                        </Button>
                      </Grid>
                    </Grid>
                    <div className="order">
                      <MDBDataTable
                        responsive
                        striped
                        bordered
                        // hover={true}
                        data={data}
                        paging={false}
                        // hover={STY}
                        className="hover"
                        // rowEvents={tableRowEvents}
                        searching={false}
                      />
                    </div>
                    {totCnt !== 0 ? (
                      <Row xs={12}>
                        <Col xs={6}>
                          <span>
                            Showing {fromVal} to {toVal} of {totCnt} entries
                          </span>
                        </Col>
                        <Col xs={6}>
                          <div className="float-right">
                            <Pagination
                              page={offset / 10 + 1}
                              count={totPages}
                              color="primary"
                              onChange={this.handleChangePage}
                            />
                          </div>
                        </Col>
                      </Row>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  orders: state.Order.orders,
  policies: state.AccessPolicy.accessPolicies.policies,
  user: state.Login.user,
});

export default connect(mapStateToProps, {
  fetchOrders,
  deleteOrder,
  fetchOrdersfilter,
})(Order);
