import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Card } from "@material-ui/core";
import { connect } from "react-redux";

class DashboardCard extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { numberStyle, cardStyle, nameStyle } = this.props;
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    var darkMode = mode === "dark__mode" ? true : false;
    let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    // let paddingIcon;
    // if (nameStyle === "Trips Delayed" || nameStyle === "Untracked Trips") {
    //   paddingIcon = "10px !important";
    // }
    return (
      <>
        {nameStyle === "Pending" || nameStyle === "रूका हुआ" ? (
          <Card
            className="shadow justify-content-center dashboard-card-div"
            style={cardStyle}
          >
            <Row
              className="invoice-overflow  mt-1"
              style={{
                width: "100%",
                paddingLeft: "12px",
              }}
            >
              <Col
                xs="10"
                md="10"
                lg="9"
                className="pt-2 mt-1  d-flex justify-content-between "
                style={{}}
              >
                <span
                  className="dashboard-name"
                  style={{ ...this.props.nameStyle }}
                >
                  {this.props.name}
                  Chan
                </span>
              </Col>
              <Col xs="2" md="2" lg="3" className="mt-3 dashboard-icon">
                <div
                  className={
                    this.props.name === "Pending" ? "pending-card" : ""
                  }
                >
                  <img
                    className="dashboard-cursor-eye pl-2"
                    src={this.props.icon}
                    alt=""
                  ></img>
                </div>
              </Col>
            </Row>
            <Row
              style={{
                paddingTop: "10px",
                paddingLeft: "12px",
              }}
            >
              <Col
                xs="9"
                md="10"
                lg="9"
                className="ml-0 pl-0 dashboard-number-col"
              >
                <span
                  className="pl-2 ml-1 dashboard-number"
                  style={{
                    ...numberStyle,
                    cursor: "default",
                  }}
                >
                  {this.props.value ? this.props.value : "0"}
                </span>
              </Col>
              <Col
                xs="3"
                md="2"
                lg="3"
                className="mt-2 pt-2 dashboard-icon dashboard-icon-bottom"
              >
                <span
                  className={
                    this.props.name === "Pending" ? "pending-card" : ""
                  }
                  style={{
                    fontSize: "15px",
                    cursor: "pointer",
                    paddingLeft: "8px",
                  }}
                  onClick={() => this.props.cardClick(this.props.name)}
                >
                  <svg
                    className="dashboard-cursor-eye svgicon customDashboardIcon"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_2588_8)">
                      <path
                        d="M0.708496 8.49992C0.708496 8.49992 3.54183 2.83325 8.50016 2.83325C13.4585 2.83325 16.2918 8.49992 16.2918 8.49992C16.2918 8.49992 13.4585 14.1666 8.50016 14.1666C3.54183 14.1666 0.708496 8.49992 0.708496 8.49992Z"
                        stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.5 10.625C9.6736 10.625 10.625 9.6736 10.625 8.5C10.625 7.32639 9.6736 6.375 8.5 6.375C7.32639 6.375 6.375 7.32639 6.375 8.5C6.375 9.6736 7.32639 10.625 8.5 10.625Z"
                        stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2588_8">
                        <rect width="17" height="17" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </Col>
            </Row>
          </Card>
        ) : this.props.name !== userLang?.dashboard?.UNTRACKED_TRIPS ? (
          <Card
            className="shadow justify-content-center dashboard-card-div"
            style={cardStyle}
          >
            <Row
              className="invoice-overflow  mt-1"
              style={{
                width: "100%",
                paddingLeft: "12px",
              }}
            >
              <Col
                xs="9"
                md="10"
                lg="9"
                className="pt-2 mt-1  d-flex justify-content-between "
                style={{}}
              >
                <span
                  className="dashboard-name"
                  style={{ ...this.props.nameStyle }}
                >
                  {this.props.name}
                  {this.props.nameText === "Pending" ? (
                    <div className="PendingContent">
                      Loading/Unloading in progress
                    </div>
                  ) : (
                    ""
                  )}
                </span>
              </Col>
              <Col xs="3" md="2" lg="3" className="mt-2 dashboard-icon">
                <div
                  className={
                    this.props.name === "Pending" ? "pending-card" : ""
                  }
                >
                  {this.props.icon}
                  {/* <img className="" src={this.props.icon}></img> */}
                </div>
              </Col>
            </Row>
            <Row
              style={{
                paddingTop: this.props.nameText === "Pending" ? "0px" : "10px",
                paddingLeft: "12px",
              }}
            >
              <Col
                xs="9"
                md="10"
                lg="9"
                className="ml-0 pl-0 dashboard-number-col"
              >
                <span
                  className="pl-2 ml-1 dashboard-number"
                  style={{
                    ...numberStyle,
                    cursor: "default",
                  }}
                >
                  {this.props.value ? this.props.value : "0"}
                </span>
              </Col>
              <Col
                xs="3"
                md="2"
                lg="3"
                className="mt-2 pt-2 dashboard-icon dashboard-icon-bottom"
              >
                <span
                  className={
                    this.props.name === "Pending" ? "pending-card" : ""
                  }
                  style={{
                    fontSize: "15px",
                    cursor: "pointer",
                    paddingLeft: "8px",
                  }}
                  onClick={() => this.props.cardClick(this.props.name)}
                >
                  {/* <img src={eyeIcon} className="dashboard-cursor-eye">
                </img> */}
                  <svg
                    className="dashboard-cursor-eye svgicon customDashboardIcon"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_2588_8)">
                      <path
                        d="M0.708496 8.49992C0.708496 8.49992 3.54183 2.83325 8.50016 2.83325C13.4585 2.83325 16.2918 8.49992 16.2918 8.49992C16.2918 8.49992 13.4585 14.1666 8.50016 14.1666C3.54183 14.1666 0.708496 8.49992 0.708496 8.49992Z"
                        stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.5 10.625C9.6736 10.625 10.625 9.6736 10.625 8.5C10.625 7.32639 9.6736 6.375 8.5 6.375C7.32639 6.375 6.375 7.32639 6.375 8.5C6.375 9.6736 7.32639 10.625 8.5 10.625Z"
                        stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2588_8">
                        <rect width="17" height="17" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </Col>
            </Row>
          </Card>
        ) : (
          <Card
            className="shadow justify-content-center dashboard-card-div"
            style={cardStyle}
          >
            <Row
              className="invoice-overflow  mt-1"
              style={{
                width: "100%",
                paddingLeft: "12px",
              }}
            >
              <Col
                xs="9"
                md="10"
                lg="9"
                className="pt-2 mt-1  d-flex justify-content-between "
                style={{}}
              >
                <span
                  className="dashboard-name"
                  style={{ ...this.props.nameStyle }}
                >
                  {this.props.name}
                </span>
              </Col>
              <Col xs="3" md="2" lg="3" className="mt-2 dashboard-icon">
                <div
                  className={
                    this.props.name === "Pending" ? "pending-card" : ""
                  }
                >
                  {this.props.icon}
                  {/* <img className="" src={this.props.icon}></img> */}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <div className="consentBackground">
                  <div className="consentText light__span">Consent</div>
                  <div className="d-flex justify-content-between">
                    <div className="unTrackedValueDiv dark__span">
                      {this?.props?.value?.unTrackedWithConsent}
                    </div>
                    <div>
                      {" "}
                      <span
                        className={
                          this.props.name === "Pending" ? "pending-card" : ""
                        }
                        style={{
                          fontSize: "15px",
                          cursor: "pointer",
                          paddingLeft: "8px",
                        }}
                        onClick={() =>
                          this.props.cardClick("Untracked with Consent")
                        }
                      >
                        <svg
                          className="dashboard-cursor-eye svgicon customDashboardIcon"
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_2588_8)">
                            <path
                              d="M0.708496 8.49992C0.708496 8.49992 3.54183 2.83325 8.50016 2.83325C13.4585 2.83325 16.2918 8.49992 16.2918 8.49992C16.2918 8.49992 13.4585 14.1666 8.50016 14.1666C3.54183 14.1666 0.708496 8.49992 0.708496 8.49992Z"
                              stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M8.5 10.625C9.6736 10.625 10.625 9.6736 10.625 8.5C10.625 7.32639 9.6736 6.375 8.5 6.375C7.32639 6.375 6.375 7.32639 6.375 8.5C6.375 9.6736 7.32639 10.625 8.5 10.625Z"
                              stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2588_8">
                              <rect width="17" height="17" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={6}>
                <div className="consentBackground">
                  <div className="consentText light__span">No Consent</div>
                  <div className="d-flex justify-content-between">
                    <div className="unTrackedValueDiv dark__span">
                      {this?.props?.value?.unTrackedWithoutConsent}
                    </div>
                    <div>
                      {" "}
                      <span
                        className={
                          this.props.name === "Pending" ? "pending-card" : ""
                        }
                        style={{
                          fontSize: "15px",
                          cursor: "pointer",
                          paddingLeft: "8px",
                        }}
                        onClick={() =>
                          this.props.cardClick("Untracked without Consent")
                        }
                      >
                        <svg
                          className="dashboard-cursor-eye svgicon customDashboardIcon"
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_2588_8)">
                            <path
                              d="M0.708496 8.49992C0.708496 8.49992 3.54183 2.83325 8.50016 2.83325C13.4585 2.83325 16.2918 8.49992 16.2918 8.49992C16.2918 8.49992 13.4585 14.1666 8.50016 14.1666C3.54183 14.1666 0.708496 8.49992 0.708496 8.49992Z"
                              stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M8.5 10.625C9.6736 10.625 10.625 9.6736 10.625 8.5C10.625 7.32639 9.6736 6.375 8.5 6.375C7.32639 6.375 6.375 7.32639 6.375 8.5C6.375 9.6736 7.32639 10.625 8.5 10.625Z"
                              stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2588_8">
                              <rect width="17" height="17" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {})(DashboardCard);
