import React, { Component } from "react";
import { MDBContainer, MDBModal, MDBModalBody } from "mdbreact";
import { Row, Col, Card, Button } from "reactstrap";
import { CloseIcon } from "../../components/Common/CommonIcons";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
// import from store
import { fetchSIgnedUrlQualityCheck } from "../../store/trip/actions";
import { tripQualityCheckSecondary } from "../../store/secondaryTrip/actions";
import { uploadAgreement } from "../../store/billing/actions";
// global variables
var fileObj = [];
var fileArray = [];
class QualityCheckButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      answers: {
        doorsLockingClosingProperly: null,
        evenAndDustFreePlatform: null,
        goodTarpaulins: null,
        holesInPlatform: null,
        objectionableOdour: null,
        protrudingNails: null,
        residualContent: null,
        usingWoodenCorners: null,
        vehicleDesign: null,
        vehicleMatched: null,
        isVehicleRegistrationCertificateValid: null,
        isPollutionCertificateValid: null,
        isInsuranceCertificateValid: null,
        isFitnessCertificateValid: null,
        isDriverLicenseValid: null,
      },
      comment: "",
      images: [],
      rejectedTrip: false,
      approvedWithDeviations: false,
      loading: false,
      signedURL: "",
      selectedFile: [],
      uploadData: "",
      fileFlag: false,
      baseImage: [],
    };
    this.fileInput = React.createRef();
  }
  fileUploadTrigger = () => {
    this.fileInput.current.click();
  };

  S3Upload = async () => {
    this.props.fetchSIgnedUrlQualityCheck(this.props.TripData?._id, (res) => {
      for (
        let i = this.state.selectedFile.length - 1;
        i < this.state.selectedFile.length;
        i++
      ) {
        let softCopy = "";
        // console.log("Calling Signed",res)
        softCopy = res.presignedS3Url.split("?")[0];
        // console.log("uploadURL", softCopy);

        this.setState(
          {
            images: [...this.state.images, softCopy],
          },
          () => {
            // console.log("images", this.state.images);
            const formData = new FormData();
            formData.append(
              this.state.selectedFile[i],
              this.state.selectedFile[i].name
            );
            // console.log("--------", formData);
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", this.state.selectedFile[i].type);
            this.props.uploadAgreement(
              res.presignedS3Url,
              this.state.selectedFile[i],
              myHeaders
            );
          }
        );
      }
    });
  };

  uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await this.convertBase64(file);
    this.setState({
      baseImage: [...this.state.baseImage, base64],
    });
    // console.log("base Image", this.state.baseImage);
  };

  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  fileUpload = (event) => {
    // console.log("EVENT console", event.target.files);
    if (event.target.files.length) {
      this.uploadImage(event);
      fileObj.push(event.target.files);
      for (let i = 0; i < fileObj[0].length; i++) {
        fileArray.push(event.target.files["0"]);
      }
      this.setState({ selectedFile: fileArray, fileFlag: true }, () => {
        // console.log("selectedFile", this.state.selectedFile);
        this.S3Upload();
      });
    }
  };
  handleChange = (e) => {
    this.setState(
      {
        answers: {
          vehicleDesign: e.target.value,
        },
      },
      () => {}
    );
  };
  handleChangeIsTruckSame = (e) => {
    this.setState(
      {
        answers: {
          ...this.state.answers,
          vehicleMatched: e.target.value,
        },
      },
      () => {
        // console.log("vehicleMatche", this.state.answers.vehicleMatched);
      }
    );
  };
  handleChangeAnswers = (e) => {
    // console.log("Under Testing", e.target.value, e.target.name);
    const { name, value } = e.target;
    if (name === "evenAndDustFreePlatform") {
      this.setState({
        answers: {
          ...this.state.answers,
          evenAndDustFreePlatform: value,
        },
      });
    } else if (name === "goodTarpaulins") {
      this.setState({
        answers: {
          ...this.state.answers,
          goodTarpaulins: value,
        },
      });
    } else if (name === "holesInPlatform") {
      this.setState({
        answers: {
          ...this.state.answers,
          holesInPlatform: value,
        },
      });
    } else if (name === "objectionableOdour") {
      this.setState({
        answers: {
          ...this.state.answers,
          objectionableOdour: value,
        },
      });
    } else if (name === "protrudingNails") {
      this.setState({
        answers: {
          ...this.state.answers,
          protrudingNails: value,
        },
      });
    } else if (name === "residualContent") {
      this.setState({
        answers: {
          ...this.state.answers,
          residualContent: value,
        },
      });
    } else if (name === "usingWoodenCorners") {
      this.setState({
        answers: {
          ...this.state.answers,
          usingWoodenCorners: value,
        },
      });
    } else if (name === "doorsLockingClosingProperly") {
      this.setState({
        answers: {
          ...this.state.answers,
          doorsLockingClosingProperly: value,
        },
      });
    } else if (name === "comment") {
      this.setState({
        comment: value,
      });
    } else if (name === "isVehicleRegistrationCertificateValid") {
      this.setState({
        answers: {
          ...this.state.answers,
          isVehicleRegistrationCertificateValid: value,
        },
      });
    } else if (name === "isPollutionCertificateValid") {
      this.setState({
        answers: {
          ...this.state.answers,
          isPollutionCertificateValid: value,
        },
      });
    } else if (name === "isInsuranceCertificateValid") {
      this.setState({
        answers: {
          ...this.state.answers,
          isInsuranceCertificateValid: value,
        },
      });
    } else if (name === "isFitnessCertificateValid") {
      this.setState({
        answers: {
          ...this.state.answers,
          isFitnessCertificateValid: value,
        },
      });
    } else if (name === "isDriverLicenseValid") {
      this.setState({
        answers: {
          ...this.state.answers,
          isDriverLicenseValid: value,
        },
      });
    }
  };
  handleReset = () => {
    this.setState({
      answers: {},
      errorMessage: {},
      comment: "",
      baseImage: [],
      images: [],
    });
  };
  validateVal = (values) => {
    const errors = {};
    if (!values) {
      errors.comment = "comment is required!";
    }

    return errors;
  };
  handleSubmit = () => {
    let qualityPayloadValues;
    let {
      doorsLockingClosingProperly,
      evenAndDustFreePlatform,
      goodTarpaulins,
      holesInPlatform,
      objectionableOdour,
      protrudingNails,
      residualContent,
      usingWoodenCorners,
      vehicleDesign,
      isVehicleRegistrationCertificateValid,
      isPollutionCertificateValid,
      isInsuranceCertificateValid,
      isFitnessCertificateValid,
      isDriverLicenseValid,
    } = this.state.answers;

    if (
      (this.state.comment &&
        vehicleDesign === "Containerized" &&
        doorsLockingClosingProperly &&
        holesInPlatform &&
        goodTarpaulins &&
        protrudingNails &&
        objectionableOdour &&
        residualContent &&
        isVehicleRegistrationCertificateValid &&
        isPollutionCertificateValid &&
        isInsuranceCertificateValid &&
        isFitnessCertificateValid &&
        isDriverLicenseValid &&
        evenAndDustFreePlatform) ||
      (this.state.comment &&
        vehicleDesign === "Open" &&
        evenAndDustFreePlatform &&
        goodTarpaulins &&
        holesInPlatform &&
        objectionableOdour &&
        protrudingNails &&
        residualContent &&
        isVehicleRegistrationCertificateValid &&
        isPollutionCertificateValid &&
        isInsuranceCertificateValid &&
        isFitnessCertificateValid &&
        isDriverLicenseValid &&
        usingWoodenCorners)
    ) {
      qualityPayloadValues = {
        tripID: this.props.TripData?._id,
        tripId: this.props.TripData?.tripId,
        source: {
          hopCode: this.props.TripData?.source?.hopCode,
          label: this.props.TripData?.source?.label,
          location: this.props.TripData?.source?.location,
        },
        customers: this.props.TripData?.customerDetails?.map((item) => ({
          shipToCode: item?.shipToCode,
          label: item?.customerName,
          location: item?.address,
        })),
        vehicleRegNo: this.props.TripData?.vehicle?.regNo,
        vehicleType: this.props.TripData?.vehicle?.type?.label,
        driverName: this.props.TripData?.driver?.name,
        driverPhone: this.props.TripData?.driver?.phone,
        answers: this.state.answers,
        comment: this.state.comment,
        images: this.state.images,
        rejectTrip: this.state.rejectedTrip,
        approvedWithDeviations: this.state.approvedWithDeviations,
      };

      this.props.tripQualityCheckSecondary(qualityPayloadValues, (res) => {
        // console.log("res", res);
        if (res.success) {
          NotificationManager.success(res["message"], "Success");
          this.props.getAllData();
          this.handleReset();
          this.props.toggleModel();
        } else {
          NotificationManager.error(res["message"], "Error");
        }
      });
    } else {
      this.setState(
        { errorMessage: this.validateVal(this.state.comment) },
        () => {
          NotificationManager.error("Required All  Answers", "Error");
        }
      );
    }
  };
  handleApproveWithDeviation = () => {
    this.setState(
      {
        approvedWithDeviations: true,
      },
      () => {
        this.handleSubmit();
      }
    );
  };
  handleReject = () => {
    this.setState(
      {
        rejectedTrip: true,
      },
      () => {
        this.handleSubmit();
      }
    );
  };

  deleteImage = (item, index) => {
    let deleteImage = this.state.baseImage.filter((image, i) => index !== i);
    let deleteImageSelectedFiles = this.state.selectedFile.filter(
      (image, i) => index !== i
    );
    this.deleteImagesSignedUrl(item, index);
    this.setState({
      baseImage: deleteImage,
      selectedFile: deleteImageSelectedFiles,
    });
  };
  deleteImagesSignedUrl = (item, index) => {
    let deleteSignedUrl = this.state.images.filter((image, i) => index !== i);
    this.setState({
      images: deleteSignedUrl,
    });
  };
  render() {
    // console.log("images", this.state.images);
    var OpenQuestions = [
      {
        action: "Is Platform even and free from dust?",
        name: "evenAndDustFreePlatform",
        checked: this.state.answers.evenAndDustFreePlatform,
      },
      {
        action: "Are there any holes in the platform?",
        name: "holesInPlatform",
        checked: this.state.answers.holesInPlatform,
      },
      {
        action: "Are there sufficient good tarpaulins?",
        name: "goodTarpaulins",
        checked: this.state.answers.goodTarpaulins,
      },
      {
        action: "Is there any Objectionable Odour?",
        name: "objectionableOdour",
        checked: this.state.answers.objectionableOdour,
      },
      {
        action:
          "Is the truck content any residual content of cement, chemical, coal, cow dung, spices etc.?",
        name: "residualContent",
        checked: this.state.answers.residualContent,
      },
      {
        action: "Are there any protruding nails?",
        name: "protrudingNails",
        checked: this.state.answers.protrudingNails,
      },
      {
        action: "Are wooden corners being used?",
        name: "usingWoodenCorners",
        checked: this.state.answers.usingWoodenCorners,
      },
      {
        action: "Is Vehicle Registration Certificate valid?",
        name: "isVehicleRegistrationCertificateValid",
        checked: this.state.answers.isVehicleRegistrationCertificateValid,
      },
      {
        action: "Is Pollution Certificate valid?",
        name: "isPollutionCertificateValid",
        checked: this.state.answers.isPollutionCertificateValid,
      },
      {
        action: "Is Insurance Certificate valid?",
        name: "isInsuranceCertificateValid",
        checked: this.state.answers.isInsuranceCertificateValid,
      },
      {
        action: "Is Fitness Certificate valid?",
        name: "isFitnessCertificateValid",
        checked: this.state.answers.isFitnessCertificateValid,
      },
      {
        action: "Is Driving Licence valid?",
        name: "isDriverLicenseValid",
        checked: this.state.answers.isDriverLicenseValid,
      },
    ];
    var ContainerizedOptions = [
      {
        action: "Is Platform even and free from dust?",
        name: "evenAndDustFreePlatform",
        checked: this.state.answers.evenAndDustFreePlatform,
      },
      {
        action: "Are there any holes in the platform?",
        name: "holesInPlatform",
        checked: this.state.answers.holesInPlatform,
      },
      {
        action: "Are there sufficient good tarpaulins?",
        name: "goodTarpaulins",
        checked: this.state.answers.goodTarpaulins,
      },
      {
        action: "Is there any Objectionable Odour?",
        name: "objectionableOdour",
        checked: this.state.answers.objectionableOdour,
      },
      {
        action:
          "Is the truck content any residual content of cement, chemical, coal, cow dung, spices etc.?",
        name: "residualContent",
        checked: this.state.answers.residualContent,
      },
      {
        action: "Are there any protruding nails?",
        name: "protrudingNails",
        checked: this.state.answers.protrudingNails,
      },
      {
        action: "Are the container doors getting closedand locked properly?",
        name: "doorsLockingClosingProperly",
        checked: this.state.answers.doorsLockingClosingProperly,
      },
      {
        action: "Is Vehicle Registration Certificate valid?",
        name: "isVehicleRegistrationCertificateValid",
        checked: this.state.answers.isVehicleRegistrationCertificateValid,
      },
      {
        action: "Is Pollution Certificate valid?",
        name: "isPollutionCertificateValid",
        checked: this.state.answers.isPollutionCertificateValid,
      },
      {
        action: "Is Insurance Certificate valid?",
        name: "isInsuranceCertificateValid",
        checked: this.state.answers.isInsuranceCertificateValid,
      },
      {
        action: "Is Fitness Certificate valid?",
        name: "isFitnessCertificateValid",
        checked: this.state.answers.isFitnessCertificateValid,
      },
      {
        action: "Is Driving Licence valid?",
        name: "isDriverLicenseValid",
        checked: this.state.answers.isDriverLicenseValid,
      },
    ];
    let mode = localStorage.getItem("Theme");
    var darkState = mode === "dark__mode" ? true : false;
    const modalStyle = {
      height: "100%",
      width: "100%",
      backgroundColor: darkState ? "#1B1C31" : "#ffffff",
      padding: "25px",
      position: "relative",
      scrollY: "hidden",
      border: darkState ? "border: 0.5px solid #616161" : "#ffffff",
    };
    const headerStyle = {
      position: "relative",
      fontFamily: "Roboto",
      fontWeight: "bold",
      fontSize: "20px",
      lineHeight: "23px",
      color: darkState ? "#9D9D9D" : "#0C0C0C",
      left: "0px",
    };
    const closeStyle = {
      position: "relative",
      // color: darkState ? "#B7A9FF" : "#2800fc",
      width: "20px",
      marginRight: "0px",
    };
    const dataBoxStyle = {
      height: "200px",
      overflowY: "scroll",
      overflowX: "clip",
    };
    const oddRowStyle = {
      minHeight: "57px",
      width: "101%",
      backgroundColor: darkState ? "#24243C" : "#ffffff",
      border: "0.5px solid rgba(185, 185, 185, 0.5)",
      borderRadius: "5px",
      margin: "auto",
      marginTop: "4px",

      marginRight: "3px",
    };
    const evenRowStyle = {
      minHeight: "57px",
      width: "101%",
      backgroundColor: darkState ? "#2E2E46" : "#F1F5F7",
      border: "0.5px solid rgba(185, 185, 185, 0.5)",
      borderRadius: "5px",
      margin: "auto",
      marginTop: "4px",
      marginRight: "3px",
    };

    const rowHeaderStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "15px",
      color: darkState ? "#9D9D9D" : "#05284E",
      lineHeight: "18px",
      margin: "auto",
      paddingLeft: "1.5vw",
    };
    const rowSeparaterStyle = {
      borderLeft: "0.5px solid rgba(185, 185, 185, .5)",
      height: "100%",
    };
    const rowValueStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "13px",
      color: darkState ? "#9D9D9D" : "#0C0C0C",
      margin: "auto",
      flex: "wrap",
    };
    // console.log("TripData", this.props.TripData);
    return (
      <div className="QualityCheckModel">
        <React.Fragment>
          <MDBContainer className="text-capitalize">
            <MDBModal
              isOpen={this.props.isOpen}
              toggle={() => this.props.toggleModel()}
              size="xl"
              className=""
              centered
            >
              <MDBModalBody className="indentModal">
                <Card style={modalStyle}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <span style={headerStyle}>Trip & Vehicle Details</span>
                    </div>

                    {/* <CloseIcon
                      style={closeStyle}
                      onClick={() => {
                        this.props.toggleModel();
                        this.handleReset();
                        this.props.getAllData();
                      }}
                    /> */}
                    <i
                      style={closeStyle}
                      onClick={() => {
                        this.props.toggleModel();
                        this.handleReset();
                        this.props.getAllData();
                      }}
                    >
                      <CloseIcon color={darkState ? "#B7A9FF" : "#2800fc"} />
                    </i>
                  </div>
                  <Row className="mt-2">
                    <Col xs={12} md={3}>
                      <div>
                        <span className="fontRoboto15 light__span">
                          Trip Id :
                        </span>{" "}
                        <span className="fontRoboto154 dark__span">
                          {this.props.TripData?.tripId}
                        </span>
                      </div>
                    </Col>
                    <div style={rowSeparaterStyle}></div>
                    <Col xs={12} md={3}>
                      <div>
                        <span className="fontRoboto15 light__span">
                          Source :
                        </span>{" "}
                        <span className="fontRoboto154 dark__span">
                          {this.props.TripData?.source?.label}
                        </span>
                      </div>
                    </Col>
                    <div style={rowSeparaterStyle}></div>
                    <Col xs={12} md={3}>
                      <div>
                        <span className="fontRoboto15 light__span">
                          Customers :
                        </span>{" "}
                        <span className="fontRoboto154 dark__span">
                          {this.props.TripData?.customerDetails?.map((item) => (
                            <div>{item.customerName},</div>
                          ))}
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col xs={12} md={3}>
                      <div className="fontRoboto15 light__span">Vehicle No</div>
                      <div>
                        <input
                          className=" mt-2 CardInputFields darkTexBox dark__span"
                          value={this.props.TripData?.vehicle?.regNo || ""}
                          readOnly
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={3}>
                      <div className="fontRoboto15 light__span">
                        Driver Name
                      </div>
                      <div>
                        <input
                          className=" mt-2 CardInputFields darkTexBox dark__span"
                          value={this.props.TripData?.driver?.name || ""}
                          readOnly
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={3}>
                      <div className="fontRoboto15 light__span">Phone</div>
                      <div>
                        <input
                          className="CardInputFields darkTexBox dark__span mt-2"
                          value={this.props.TripData?.driver?.phone || ""}
                          readOnly
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs={12} md={6}>
                      <div className="fontRoboto15 dark__span">
                        Is the truck vehicle arrived same as mentioned in Trip
                        Planning?
                      </div>
                      <Row className="ml-2">
                        <Col xs={3} lg={3}>
                          <input
                            className="form-check-input"
                            type="radio"
                            value="true"
                            checked={
                              this.state.answers.vehicleMatched === "true"
                            }
                            onChange={this.handleChangeIsTruckSame}
                          />
                          <label className="form-check-label fontRoboto154 light__span">
                            Yes
                          </label>
                        </Col>
                        <Col xs={3} lg={3}>
                          <input
                            className="form-check-input"
                            type="radio"
                            value="false"
                            checked={
                              this.state.answers.vehicleMatched === "false"
                            }
                            onChange={this.handleChangeIsTruckSame}
                          />
                          <label className="form-check-label fontRoboto154 light__span">
                            No
                          </label>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="fontRoboto15 dark__span">
                        Select Truck Type?
                      </div>
                      <Row className="ml-2">
                        <Col xs={4} lg={3}>
                          <input
                            className="form-check-input"
                            type="radio"
                            value="Open"
                            checked={
                              this.state.answers.vehicleDesign === "Open"
                            }
                            onChange={this.handleChange}
                          />
                          <label className="form-check-label fontRoboto154 light__span">
                            Open
                          </label>
                        </Col>
                        <Col xs={3} lg={3}>
                          <input
                            className="form-check-input"
                            type="radio"
                            checked={
                              this.state.answers.vehicleDesign ===
                              "Containerized"
                            }
                            value="Containerized"
                            onChange={this.handleChange}
                          />
                          <label className="form-check-label fontRoboto154 light__span">
                            Containerized
                          </label>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>

                <Card className="QualityCheckCard">
                  {this.state.answers.vehicleDesign === "Open" ? (
                    <>
                      <div style={dataBoxStyle}>
                        <div>
                          {OpenQuestions.map((data, index) => (
                            <Grid
                              className="shadow indentData-hover"
                              container
                              style={
                                index % 2 === 0 ? oddRowStyle : evenRowStyle
                              }
                              key={index}
                            >
                              <Grid
                                className=""
                                item
                                xs={7}
                                md={9}
                                style={rowHeaderStyle}
                              >
                                <span className="fontRoboto15 dark__span">
                                  {data?.action}
                                </span>
                              </Grid>
                              <Grid
                                item
                                // xs={1}
                                style={{
                                  padding: "2vh 0px",
                                  marginLeft: "-20px",
                                }}
                              >
                                <div style={rowSeparaterStyle}></div>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                md={2}
                                style={rowValueStyle}
                                justifyContent="center"
                                alignItems="center"
                              >
                                <>
                                  <ul
                                    style={{
                                      marginTop: "1rem",
                                      marginLeft: "-13px",
                                    }}
                                  >
                                    <div className="form-check form-check-inline">
                                      <Row>
                                        <Col
                                          xs="12"
                                          sm={6}
                                          className="text-nowrap"
                                        >
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name={data?.name}
                                            id={data.key}
                                            value="true"
                                            onChange={(e) => {
                                              this.handleChangeAnswers({
                                                target: {
                                                  name: data?.name,
                                                  value: e.target.value,
                                                },
                                              });
                                            }}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={data.key}
                                          >
                                            Yes
                                          </label>
                                        </Col>
                                        <Col
                                          xs="12"
                                          sm={6}
                                          className="text-nowrap"
                                        >
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            value="false"
                                            name={data?.name}
                                            onChange={(e) => {
                                              this.handleChangeAnswers({
                                                target: {
                                                  name: data?.name,
                                                  value: e.target.value,
                                                },
                                              });
                                            }}
                                          />
                                          <label className="form-check-label">
                                            No
                                          </label>
                                        </Col>
                                      </Row>
                                    </div>
                                  </ul>
                                </>
                              </Grid>
                            </Grid>
                          ))}
                        </div>
                      </div>
                      <>
                        <div className="fontRoboto15 dark__span mt-2 required-field">
                          Write Your Comment If Any
                        </div>
                        <div className="mt-2">
                          <textarea
                            style={{
                              resize: "none",
                              width: "98%",
                            }}
                            value={this.state.comment}
                            onChange={(e) =>
                              this.handleChangeAnswers({
                                target: {
                                  name: "comment",
                                  value: e.target.value,
                                },
                              })
                            }
                            className="form-control fontRoboto12 darkTexBox"
                            id="exampleFormControlTextarea1"
                            rows="4"
                            placeholder="Please comment here..."
                          />
                          <span style={{ fontWeight: 400 }}>
                            {this.state.errorMessage?.comment && (
                              <div className="error">
                                {" "}
                                {this.state.errorMessage?.comment}{" "}
                              </div>
                            )}
                          </span>
                        </div>
                        <div
                          className="mt-2"
                          style={{
                            width: "98%",
                            backgroundColor: "#0AB38C",
                            height: "100px",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                color: "#FFF",
                              }}
                              className=" fontRoboto15 d-flex justify-content-center"
                            >
                              Attach Quality Check Proof
                            </span>
                          </div>
                          <input
                            type="file"
                            name="Image"
                            multiple={false}
                            style={{ display: "none" }}
                            ref={this.fileInput}
                            onChange={this.fileUpload}
                          />
                          <span
                            style={{
                              cursor: "pointer",
                              color: this.props.mode ? "#B7A9FF" : "#5664D2",
                            }}
                            onClick={this.fileUploadTrigger}
                          >
                            <svg
                              style={{
                                // marginTop: "20px",
                                marginLeft: "20px",
                              }}
                              width="51"
                              height="51"
                              viewBox="0 0 51 51"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M50.7061 0H0.293948C0.131605 0 0 1.60235 0 3.57895V47.4211C0 49.3977 0.131605 51 0.293948 51H50.7061C50.8684 51 51 49.3977 51 47.4211V3.57895C51 1.60235 50.8684 0 50.7061 0Z"
                                fill="#05284E"
                                fillOpacity="0.59"
                              />
                              <path
                                d="M50.7062 0.224609H0.294131C0.141934 0.224609 0.0185547 1.72681 0.0185547 3.57987V47.422C0.0185547 49.275 0.141934 50.7772 0.294131 50.7772H50.7062C50.8584 50.7772 50.9818 49.275 50.9818 47.422V3.57987C50.9818 1.72681 50.8584 0.224609 50.7062 0.224609Z"
                                fill="#05284E"
                                fillOpacity="0.59"
                              />
                              <path
                                d="M26 21V31"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M21 26H31"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                          <i
                            class="fa fa-close"
                            style={{ fontSize: "24px" }}
                          ></i>
                          {this.state.baseImage?.length > 0
                            ? this.state.baseImage?.map((item, i) => {
                                return (
                                  <>
                                    <span className="pl-2 pr-2">
                                      <span className="QCImangeClose cursor-pointer">
                                        <svg
                                          className="closeIconQc"
                                          onClick={() =>
                                            this.deleteImage(item, i)
                                          }
                                          width="14"
                                          height="14"
                                          viewBox="0 0 14 14"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M13 1L1 13"
                                            stroke="#2800FC"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M1 1L13 13"
                                            stroke="#2800FC"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </span>

                                      <img
                                        src={item}
                                        alt=""
                                        width={50}
                                        height={50}
                                      />
                                    </span>
                                  </>
                                );
                              })
                            : ""}
                        </div>
                      </>
                    </>
                  ) : (
                    ""
                  )}
                  {this.state.answers.vehicleDesign === "Containerized" ? (
                    <>
                      <div style={dataBoxStyle}>
                        <div style={{ marginRight: "2vw" }}>
                          {ContainerizedOptions.map((data, index) => (
                            <Grid
                              className="shadow indentData-hover"
                              container
                              style={
                                index % 2 === 0 ? oddRowStyle : evenRowStyle
                              }
                              key={index}
                            >
                              <Grid
                                className=""
                                item
                                xs={7}
                                md={9}
                                style={rowHeaderStyle}
                              >
                                <span className="fontRoboto15 dark__span">
                                  {data?.action}
                                </span>
                              </Grid>
                              <Grid
                                item
                                // xs={1}
                                style={{
                                  padding: "2vh 0px",
                                  marginLeft: "-20px",
                                }}
                              >
                                <div style={rowSeparaterStyle}></div>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                md={2}
                                style={rowValueStyle}
                                justifyContent="center"
                                alignItems="center"
                              >
                                <>
                                  <ul
                                    style={{
                                      marginTop: "1rem",
                                      marginLeft: "-13px",
                                    }}
                                  >
                                    <div className="form-check form-check-inline">
                                      <Row>
                                        <Col
                                          xs="12"
                                          sm={6}
                                          className="text-nowrap"
                                        >
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name={data?.name}
                                            id={data.key}
                                            value="true"
                                            onChange={(e) => {
                                              this.handleChangeAnswers({
                                                target: {
                                                  name: data?.name,
                                                  value: e.target.value,
                                                },
                                              });
                                            }}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={data.key}
                                          >
                                            Yes
                                          </label>
                                        </Col>
                                        <Col
                                          xs="12"
                                          sm={6}
                                          className="text-nowrap"
                                        >
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            value="false"
                                            name={data?.name}
                                            // onChange={this.handleChangeAnswers}
                                            // defaultChecked
                                            onChange={(e) => {
                                              this.handleChangeAnswers({
                                                target: {
                                                  name: data?.name,
                                                  value: e.target.value,
                                                },
                                              });
                                            }}
                                          />
                                          <label className="form-check-label">
                                            No
                                          </label>
                                        </Col>
                                      </Row>
                                    </div>
                                  </ul>
                                </>
                              </Grid>
                            </Grid>
                          ))}
                        </div>
                      </div>
                      <>
                        <div className="fontRoboto15 dark__span mt-2 required-field">
                          Write Your Comment If Any
                        </div>
                        <div className="mt-2">
                          <textarea
                            style={{
                              resize: "none",
                              width: "98%",
                            }}
                            value={this.state.comment}
                            onChange={(e) =>
                              this.handleChangeAnswers({
                                target: {
                                  name: "comment",
                                  value: e.target.value,
                                },
                              })
                            }
                            className="form-control fontRoboto12 darkTexBox"
                            id="exampleFormControlTextarea1"
                            rows="4"
                            placeholder="Please comment here..."
                          />
                          <span style={{ fontWeight: 400 }}>
                            {this.state.errorMessage?.comment && (
                              <div className="error">
                                {" "}
                                {this.state.errorMessage?.comment}{" "}
                              </div>
                            )}
                          </span>
                        </div>
                        <div
                          className="mt-2"
                          style={{
                            width: "98%",
                            backgroundColor: "#0AB38C",
                            height: "100px",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                color: "#FFF",
                              }}
                              className=" fontRoboto15 d-flex justify-content-center"
                            >
                              Attach Quality Check Proof
                            </span>
                          </div>
                          <input
                            type="file"
                            name="Image"
                            multiple={false}
                            style={{ display: "none" }}
                            ref={this.fileInput}
                            onChange={this.fileUpload}
                          />
                          <span
                            style={{
                              cursor: "pointer",
                              color: this.props.mode ? "#B7A9FF" : "#5664D2",
                            }}
                            onClick={this.fileUploadTrigger}
                          >
                            <svg
                              style={{
                                // marginTop: "20px",
                                marginLeft: "20px",
                              }}
                              width="51"
                              height="51"
                              viewBox="0 0 51 51"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M50.7061 0H0.293948C0.131605 0 0 1.60235 0 3.57895V47.4211C0 49.3977 0.131605 51 0.293948 51H50.7061C50.8684 51 51 49.3977 51 47.4211V3.57895C51 1.60235 50.8684 0 50.7061 0Z"
                                fill="#05284E"
                                fillOpacity="0.59"
                              />
                              <path
                                d="M50.7062 0.224609H0.294131C0.141934 0.224609 0.0185547 1.72681 0.0185547 3.57987V47.422C0.0185547 49.275 0.141934 50.7772 0.294131 50.7772H50.7062C50.8584 50.7772 50.9818 49.275 50.9818 47.422V3.57987C50.9818 1.72681 50.8584 0.224609 50.7062 0.224609Z"
                                fill="#05284E"
                                fillOpacity="0.59"
                              />
                              <path
                                d="M26 21V31"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M21 26H31"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                          <i
                            class="fa fa-close"
                            style={{ fontSize: "24px" }}
                          ></i>
                          {this.state.baseImage?.length > 0
                            ? this.state.baseImage?.map((item, i) => {
                                return (
                                  <>
                                    <span className="pl-2 pr-2">
                                      <span className="QCImangeClose cursor-pointer">
                                        <svg
                                          className="closeIconQc"
                                          onClick={() =>
                                            this.deleteImage(item, i)
                                          }
                                          width="14"
                                          height="14"
                                          viewBox="0 0 14 14"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M13 1L1 13"
                                            stroke="#2800FC"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M1 1L13 13"
                                            stroke="#2800FC"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </span>

                                      <img
                                        src={item}
                                        alt=""
                                        width={50}
                                        height={50}
                                      />
                                    </span>
                                  </>
                                );
                              })
                            : ""}
                        </div>
                      </>
                    </>
                  ) : (
                    ""
                  )}
                  {this.state.answers.vehicleDesign !== "Open" &&
                    this.state.answers.vehicleDesign !== "Containerized" && (
                      <>
                        <span
                          className="darkspanQC fontRoboto154 light__span align-items-center d-flex justify-content-center light__span"
                          style={{
                            color: this.props.mode ? "#FFF" : "#05284E",
                            backgroundColor: this.props.mode
                              ? "#2e2e46"
                              : "#F1F5F7",
                            position: "relative",
                            top: "50%",
                          }}
                        >
                          Select Truck Type
                        </span>
                      </>
                    )}
                </Card>
                <div className="ml-3 mb-3">
                  <Button
                    className="resetButtonStyle fontRoboto15"
                    style={{ backgroundColor: "#0AB38C", color: "white" }}
                    onClick={() => this.handleReset()}
                  >
                    Reset
                  </Button>
                  <Button
                    className="submitButtonStyle fontRoboto15"
                    style={{
                      backgroundColor: "#05284E",
                      color: "white",
                    }}
                    onClick={() => {
                      this.handleSubmit();
                      // this.props.toggleModel();
                      // this.props.getAllData();
                    }}
                  >
                    Approve
                  </Button>
                  <span className="float-right pr-3" style={{ margin: "5px" }}>
                    <Button
                      className="submitButtonStyle fontRoboto15"
                      style={{
                        width: "180px",
                        backgroundColor: this.props.mode
                          ? "#5E40FF"
                          : "rgb(255, 191, 0)",
                        color: "white",
                        whiteSpace: "nowrap",
                      }}
                      onClick={this.handleApproveWithDeviation}
                    >
                      Approve With Deviations
                    </Button>
                    <Button
                      className="submitButtonStyle fontRoboto15"
                      style={{
                        backgroundColor: "rgb(255,69,0)",
                        color: "white",
                      }}
                      onClick={this.handleReject}
                    >
                      Reject
                    </Button>
                  </span>
                </div>
              </MDBModalBody>
            </MDBModal>
          </MDBContainer>
        </React.Fragment>
      </div>
    );
  }
}
const mapStatetoProps = (state) => {
  // console.log("state qc", state);
  return {};
};
export default connect(mapStatetoProps, {
  tripQualityCheckSecondary,
  fetchSIgnedUrlQualityCheck,
  uploadAgreement,
})(QualityCheckButton);
