import React, { Component } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Button,
  FormGroup,
  InputGroup,
  Input,
} from "reactstrap";
import { NotificationManager } from "react-notifications";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";
import { MoonLoader } from "react-spinners";
import Breadcrumbs from "../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { fetchHops, deleteHop, fetchHopsfilter } from "../store/hop/actions";
import { connect } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import { getAccessForEntity } from "../pages/policies";
import { Grid } from "@material-ui/core";

var data = {};
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;

class Hop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idToDelete: "",
      open: false,
      rows: [],
      loading: true,
      editAccess: false,
      delAccess: false,
      breadcrumbItems: [
        { title: "Hop", link: "#" },
        { title: "Hop Details", link: "#" },
      ],
      filters: "",
    };
  }

  componentDidMount = () => {
    this.getAllData();
  };
  componentDidUpdate(prevProps) {
    if (this.props.policies !== prevProps.policies) {
      this.getAllData();
    }
  }
  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
  };

  hasAccessPolicy(val, entity) {
    const { policies } = this.props;
    let hasAccess = false;

    if (policies && policies.length > 0) {
      hasAccess = getAccessForEntity(policies, entity, val);
    }
    return hasAccess;
  }
  convertTime(time) {
    let hrs = Number(time.slice(0, 2));
    let mins = time.slice(3, 5);
    return hrs > 12 ? hrs - 12 + "." + mins + " PM" : hrs + "." + mins + " AM";
  }

  getAllData = () => {
    // console.log("rows")
    this.props.fetchHops(offset, (res) => {
      // console.log(res,"rows")
      this.refinedata(res);
      this.setState({ loading: false });
    });
  };

  refinedata(res) {
    if (res && Array.isArray(res.docs)) {
      totCnt = res.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = res.offset + res.docs.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      let rows = [];

      let editAccess = this.hasAccessPolicy("edit", "hop");
      let delAccess = this.hasAccessPolicy("delete", "hop");
      this.setState({ editAccess, delAccess });

      res.docs.forEach((ele, i) => {
        let openingTime = this.convertTime(ele.openingTime);
        let closingTime = this.convertTime(ele.closingTime);
        let actions =
          editAccess || delAccess ? (
            <div>
              {delAccess ? (
                <span>
                  <i
                    onClick={() => this.handleClickOpen(ele._id)}
                    className="fas fa-trash"
                    style={{ cursor: "pointer" }}
                  ></i>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              ) : null}

              {editAccess ? (
                <span>
                  <Link to={`/edit-hop-${ele._id}`}>
                    <i className="fas fa-pencil-alt"></i>{" "}
                  </Link>
                </span>
              ) : null}
            </div>
          ) : (
            ""
          );
        rows.push({
          hopType: ele.hopType,
          hopName: ele.hopName,
          coordinates: ele.cordinates
            ? ele.cordinates[0] + ", " + ele.cordinates[1]
            : null,
          contact: ele.contact,
          email: ele.email,
          website: ele.website,
          gstin: ele.GSTIN,
          openTime: openingTime + " - " + closingTime,
          description: ele.description,
          actions: actions,
        });
        Object.keys(rows[i]).map((key) => {
          if (key !== "actions") {
            rows[i][key] = rows[i][key] ? rows[i][key] : "-";
          } else {
            if (!editAccess && !delAccess) delete rows[i][key];
          }
        });
      });
      this.setState({ rows });
    }
  }
  handleClickOpen = (id) => {
    this.setState({ open: true, idToDelete: id });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleDeleteHop = () => {
    this.handleClose();
    this.props.deleteHop(this.state.idToDelete, (res) => {
      if (res["message"] && res["message"] === "Hop has been deleted") {
        NotificationManager.success(res["message"], "Success");
        this.getAllData();
      } else {
      }
    });
  };
  handleChangePage = (event, val) => {
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    this.getAllData();
  };

  filterList = (e) => {
    // console.error(e,"e")

    if (this.state.filters.length > 2) {
      // console.error(e,"e")
      this.setState({ loading: true });
      this.props.fetchHopsfilter({offset:offset ,limit:10}, this.state.filters, (res) => {
        // console.log(res,"rows")
        this.refinedata(res);
        this.setState({ loading: false });
      });
    } else {
      this.getAllData();
    }
  };
  change(e) {
    let filters = e.target.value;
    this.setState({ filters });
  }
  render() {
    const { fullScreen } = this.props;
    const { loading, rows, delAccess, editAccess } = this.state;
    if (loading) {
      return (
        <div className="page-content">
          <Col xs={12} className="loader">
            <MoonLoader size={45} color={"#123abc"} />
          </Col>
        </div>
      );
    }
    data = {
      columns: [
        {
          label: "Hop Type",
          field: "hopType",
          sort: "asc",
          width: 150,
        },
        {
          label: "Hop Name",
          field: "hopName",
          sort: "asc",
          width: 150,
        },
        {
          label: "GeoLocation",
          field: "coordinates",
          sort: "asc",
          width: 270,
        },
        {
          label: "Contact",
          field: "contact",
          sort: "asc",
          width: 200,
        },
        {
          label: "Email",
          field: "email",
          sort: "asc",
          width: 100,
        },
        {
          label: "Website",
          field: "website",
          sort: "asc",
          width: 100,
        },
        {
          label: "GSTIN",
          field: "gstin",
          sort: "asc",
          width: 100,
        },
        {
          label: "OpenTime",
          field: "openTime",
          sort: "asc",
          width: 200,
        },
        {
          label: "Description",
          field: "description",
          sort: "asc",
          width: 100,
        },
        {
          label: "Actions",
          field: "actions",
          sort: "asc",
          width: 150,
        },
      ],
      rows: rows,
    };
    // console.log(rows,"rows")
    if (!delAccess && !editAccess) data.columns.pop(-1);
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Hop"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            {this.hasAccessPolicy("create", "hop") ? (
              <FormGroup className="mb-2 text-right">
                <div>
                  <Link to="/add-hop" className="small">
                    {" "}
                    <Button type="button" color="btn btn-primary">
                      Add Hop
                    </Button>
                  </Link>{" "}
                  {/* <Link to="/upload-hop" className="small">
                    {" "}
                    <Button type="button" color="btn btn-primary">
                      Upload Excel
                    </Button>
                  </Link> */}
                </div>
              </FormGroup>
            ) : null}

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <Grid container>
                      <Grid item xs={4} md={6}>
                        <h4 className="card-title"> Hop Details </h4>
                      </Grid>
                      <Grid item xs={1} md={1}></Grid>
                      <Grid item xs={4} md={4}>
                        <InputGroup>
                          <Input
                            className="form-control"
                            type="text"
                            name="filters"
                            placeholder="Search"
                            aria-label="Search"
                            onChange={(e) => this.change(e)}
                          />
                        </InputGroup>
                      </Grid>
                      <Grid item xs={1} md={1}>
                        <Button
                          type="button"
                          color="btn btn-primary"
                          onClick={this.filterList}
                        >
                          FILTER
                        </Button>
                      </Grid>
                    </Grid>

                    <MDBDataTable
                      className="text-center"
                      responsive
                      striped
                      bordered
                      data={data}
                      paging={false}
                      searching={false}
                    />
                    {totCnt !== 0 ? (
                      <Row xs={12}>
                        <Col xs={6}>
                          <span>
                            Showing {fromVal} to {toVal} of {totCnt} entries
                          </span>
                        </Col>
                        <Col xs={6}>
                          <div className="float-right">
                            <Pagination
                              page={offset / 10 + 1}
                              count={totPages}
                              color="primary"
                              onChange={this.handleChangePage}
                            />
                          </div>
                        </Col>
                      </Row>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
          <Dialog
            fullScreen={fullScreen}
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">Confirm</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete the hop?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary" autoFocus>
                Cancel
              </Button>
              <Button onClick={this.handleDeleteHop} color="">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  policies: state.AccessPolicy.accessPolicies.policies,
});

export default connect(mapStateToProps, {
  fetchHops,
  deleteHop,
  fetchHopsfilter,
})(Hop);
