import React, { Component } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Button,
  FormGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { fetchEmployees, deleteEmployee } from "../store/employee/actions";
import { connect } from "react-redux";

var rows = [];
var data = [];
class Employee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Employee", link: "employee" },
        { title: "Employee Details", link: "#" },
      ],
    };
  }
  componentDidMount() {
    this.props.fetchEmployees();
  }
  deleteEmp(id) {
    this.props.deleteEmployee(id, () => {
      this.refreshPage();
    });
  }
  refreshPage() {
    window.location.reload();
  }
  render() {
    const { employees } = this.props;
    if (employees && employees.length > 0) {
      //console.log('employees', employees);
      employees.forEach((ele) => {
        rows.push({
          empSerialNo: ele.empSerialNo,
          empBranchNo: ele.empBranchNo,
          firstName: ele.userId.firstName,
          lastName: ele.userId.lastName,
          email: ele.userId.email,
          phone: ele.userId.phone,
          actions: (
            <div>
              <Button onClick={() => this.deleteEmp(ele._id)}>X</Button>
              <span>
                <i className="fas fa-pencil-alt"></i>{" "}
              </span>
            </div>
          ),
        });
      });
      data = {
        columns: [
          {
            label: "Serial No",
            field: "empSerialNo",
            sort: "asc",
            width: 150,
          },
          {
            label: "Branch No",
            field: "empBranchNo",
            sort: "asc",
            width: 270,
          },
          {
            label: "First Name",
            field: "firstName",
            sort: "asc",
            width: 200,
          },
          {
            label: "Last Name",
            field: "lastName",
            sort: "asc",
            width: 100,
          },
          {
            label: "Phone",
            field: "phone",
            sort: "asc",
            width: 150,
          },
          {
            label: "Email",
            field: "email",
            sort: "asc",
            width: 100,
          },
          {
            label: "Actions",
            field: "actions",
            sort: "asc",
            width: 100,
          },
        ],
        rows: rows,
      };

      //console.log('rows',rows);
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Employee"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <FormGroup className="mb-2 text-right">
              <div>
                <Link to="/add-employee" className="small">
                  {" "}
                  <Button type="button" color="btn btn-primary">
                    Add Employee
                  </Button>
                </Link>{" "}
                <Link to="/upload-employee" className="small">
                  {" "}
                  <Button type="button" color="btn btn-primary">
                    Upload Excel
                  </Button>
                </Link>
              </div>
            </FormGroup>

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <h4 className="card-title"> Employee Details </h4>

                    <MDBDataTable responsive striped bordered data={data} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
// export default Trip;
const mapStateToProps = (state) => ({
  employees: state.Employee.employees,
});

export default connect(mapStateToProps, { fetchEmployees, deleteEmployee })(
  Employee
);
