import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  TOGGLE_RIGHT_SIDEBAR,
  SHOW_RIGHT_SIDEBAR,
  CHANGE_PRELOADER,
  HIDE_RIGHT_SIDEBAR,
  types,
} from "./actionTypes";

export const fetchHops = (callback) => ({
  type: types.FETCH_HOPS_LIST,
  callback,
});

export const fetchIndents = (offset, filters, callback) => ({
  type: types.FETCH_INDENTS,
  offset,
  filters,
  callback,
});

export const fetchIndentsExcel = (offset, filters, callback) => ({
  type: types.FETCH_INDENTS_EXCEL,
  offset,
  filters,
  callback,
});
export const fetchCreatedIndentInfo = (indentID, callback) => ({
  type: types.FETCH_CREATED_INDENT_INFO,
  payload: { indentID },
  callback,
});

export const fetchAssignedIndents = (offset, filters, callback) => ({
  type: types.FETCH_INDENTS_ASSIGNED,
  offset,
  filters,
  callback,
});

// export const fetchPost = id => ({ type: types.FETCH_POST, id });
export const createIndent = (values, callback) => ({
  type: types.CREATE_INDENT,
  values,
  callback,
});
export const createBulkIndent = (values, callback) => ({
  type: types.CREATE_BULK_INDENT,
  values,
  callback,
});
export const uploadIndent = (values, callback) => ({
  type: types.INDENT_UPLOAD,
  values,
  callback,
});
export const assignIndent = (values, callback) => ({
  type: types.ASSIGN_INDENT,
  values,
  callback,
});
export const rejectIndent = (values, callback) => ({
  type: types.REJECT_INDENT,
  values,
  callback,
});
export const approveIndent = (values, callback) => ({
  type: types.APPROVE_INDENT,
  values,
  callback,
});

export const changeLayout = (layout) => ({
  type: CHANGE_LAYOUT,
  payload: layout,
});

export const changePreloader = (layout) => ({
  type: CHANGE_PRELOADER,
  payload: layout,
});

export const changeLayoutWidth = (width, layoutType) => ({
  type: CHANGE_LAYOUT_WIDTH,
  payload: { width, layoutType },
});

export const changeSidebarTheme = (theme, layoutType) => ({
  type: CHANGE_SIDEBAR_THEME,
  payload: { theme, layoutType },
});

export const changeSidebarType = (sidebarType, isMobile) => {
  return {
    type: CHANGE_SIDEBAR_TYPE,
    payload: { sidebarType, isMobile },
  };
};

export const changeTopbarTheme = (topbarTheme) => ({
  type: CHANGE_TOPBAR_THEME,
  payload: topbarTheme,
});

export const toggleRightSidebar = () => ({
  type: TOGGLE_RIGHT_SIDEBAR,
  payload: null,
});

export const showRightSidebar = () => ({
  type: SHOW_RIGHT_SIDEBAR,
  payload: null,
});

export const hideRightSidebar = () => ({
  type: HIDE_RIGHT_SIDEBAR,
  payload: null,
});

export const setRouteDetails = (value) => ({
  type: types.ROUTE_DETAILS,
  payload: value,
});

export const setOrderDetails = (value) => ({
  type: types.ORDER_DETAILS,
  payload: value,
});

export const setVehicleDetails = (value) => ({
  type: types.VEHICLE_DETAILS,
  payload: value,
});

export const changeAssignedIndentStatus = (value) => ({
  type: types.CHANGE_ASSIGNED_INDENT_STATUS,
  payload: value,
});

export const finalizeIndent = ({ values, callback }) => ({
  type: types.FINALIZE_INDENT,
  values,
  callback,
});

export const IndentStatusAccept = ({ value, callback }) => ({
  type: types.INDENT_STATUS_ACCEPT,
  value,
  callback,
});

export const IndentStatusReject = ({ values, callback }) => ({
  type: types.INDENT_STATUS_REJECT,
  values,
  callback,
});
export const IndentStatusCancel = ({ values, callback }) => ({
  type: types.INDENT_STATUS_CANCEL,
  values,
  callback,
});

// masters
export const fetchIndentsForMaster = (filters, callback) => ({
  type: types.FETCH_INDENTS_FOR_MASTER,
  filters,
  callback,
});
