import React, { Component } from "react";

import { Container } from "reactstrap";
import { NotificationManager } from "react-notifications";

import { Link } from "react-router-dom";
import {
  fetchRoles,
  fetchRolesExcel,
  deleteRole,
  fetchRolesfilter,
} from "../../store/accessPolicy/actions";
import { connect } from "react-redux";
import { getAccessForEntity } from "../../pages/policies";

import Index from "../../components/Common/DataTableNeo/Index";
import { exportToCSV } from "../common/exportToCsv";

import {
  AdminHeaderData,
  searchBar,
  getLocalStorage,
} from "./RowHeaders/RowHeaders.js";
import QuickSearchFilters from "./QickSeachFilter";

var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var isSuperAdmin = false;
var darkTheme;

class Roles_New extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idToDelete: "",
      open: false,
      dataLoading: false,
      breadcrumbItems: [
        { title: "Role", link: "role" },
        { title: "Role Details", link: "#" },
      ],
      filters: "",
      roleData: "",
      editAccess: false,
      delAccess: false,
      rows: [],
      roles: "",
      description: "",
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props.policies !== prevProps.policies) {
      this.getAllData();
    }
  }
  componentDidMount = () => {
    this.getAllData();
    this.getRolesOffset();
  };

  getRolesOffset = () => {
    let filterPayLoad = {
      role: this.state.roles ? this.state.roles : "",
      description: this.state.description ? this.state.description : "",
    };
    this.setState({ dataLoading: true });
    this.props.fetchRoles(offset, filterPayLoad, (res) => {
      this.refineData(res);
      let roleArr = [];
      if (Array.isArray(res.data)) {
        res.data.forEach((ele) => {
          roleArr.push({
            value: ele.label,
            label:
              ele.label.replace(/_/g, " ").charAt(0).toUpperCase() +
              ele.label.replace(/_/g, " ").slice(1),
          });
        });
        this.setState({ roleData: roleArr }, () => {
          if (searchBar) {
            searchBar.searchBarData.forEach((obj) => {
              if (obj.field === "roles") {
                obj.options = this.state.roleData;
              }
            });
          }
        });
      }
      this.setState({ dataLoading: false });
    });
  };
  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
  };

  hasAccessPolicy(val, entity) {
    const { policies } = this.props;
    let hasAccess = false;

    if (policies && policies.length > 0) {
      if (policies.includes("All policies")) isSuperAdmin = true;

      hasAccess = getAccessForEntity(policies, entity, val);
    }
    return hasAccess;
  }
  reset = () => {
    offset = 0;
    this.setState({ roles: "", description: "" }, () => {
      this.getAllData();
    });
    // this.setState({ filters: "" }, () => {
    //   this.props.fetchRolesfilter(offset, this.state.filters, (res) => {
    //     this.refineData(res);
    //   });
    // });
  };
  getAllData = () => {
    const localStorageData = JSON.parse(localStorage.getItem("rolesListData"));
    let filterPayLoad = {
      role: this.state.roles ? this.state.roles : "",
      description: this.state.description ? this.state.description : "",
    };
    // console.log(filterPayLoad,"90")
    this.setState({ dataLoading: true });
    this.props.fetchRoles(offset, filterPayLoad, (res) => {
      this.refineData(res);
      this.setState({ dataLoading: false });
    });
    if (!navigator.onLine) {
      this.setState(
        {
          loading: false,
        },
        () => {
          localStorageData
            ? this.refineData(localStorageData)
            : this.setState({ rows: [] });
        }
      );
    }
  };
  searchValueAssignerFunction = (name, value, type) => {
    if (typeof value === "text" || type === "text") {
      offset = 0;
      this.setState({ [name]: value }, () => {});
    }
    if (type === "date" || type === "select") {
      this.setState({ [name]: value }, () => {
        offset = 0;
        this.getAllData();
      });
    }
  };
  refineData = (res) => {
    if (Array.isArray(res?.data)) {
      totCnt = res?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      let rows = [];
      let editAccess = this.hasAccessPolicy("edit", "role");
      let delAccess = this.hasAccessPolicy("delete", "role");
      this.setState({ editAccess, delAccess });
      res.data.forEach((ele, i) => {
        let actions =
          editAccess || delAccess ? (
            <div disabled={ele?.name === "superadmin"}>
              {delAccess ? (
                <span>
                  <i
                    onClick={() => this.handleClickOpen(ele?._id)}
                    className="fas fa-trash"
                    style={{ cursor: "pointer" }}
                  ></i>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              ) : null}

              {editAccess ? (
                <span>
                  <Link to={`/edit-role-${ele?._id}`}>
                    <i className="fas fa-pencil-alt"></i>
                  </Link>
                </span>
              ) : null}
            </div>
          ) : (
            ""
          );
        rows.push({
          name:
            ele?.label?.replace(/_/g, " ").charAt(0).toUpperCase() +
            ele?.label?.replace(/_/g, " ").slice(1),
          description: ele?.description.toUpperCase(),
          permissions: "N/A",
        });
        Object.keys(rows[i]).map((key) => {
          if (key !== "actions") {
            rows[i][key] = rows[i][key] ? rows[i][key] : "-";
          } else {
            if (!editAccess && !delAccess) delete rows[i][key];
          }
          return true;
        });
      });
      this.setState({ rows: rows });
    }
  };
  handleClickOpen = (id) => {
    this.setState({ open: true, idToDelete: id });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handledeleteRole = () => {
    this.handleClose();
    this.props.deleteRole(this.state.idToDelete, (res) => {
      if (res["message"] && res["message"] === "roleGroup has been deleted") {
        NotificationManager.success(res["message"], "Success");
        this.getAllData();
      } else {
        NotificationManager.error("Something went wrong!", "Error");
      }
    });
  };
  handleChangePage = (event, val) => {
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    this.getAllData();
  };

  filterList = (e) => {
    // console.error(e,"e")

    if (this.state.filters.length > 2) {
      // console.error(e,"e")
      // console.log("called")
      this.props.fetchRolesfilter(offset, this.state.filters, (res) => {
        // console.log("component", res);
        this.refineData(res);
      });
    } else {
      this.getAllData();
    }
  };
  change = (e) => {
    let filters = e.target.value;
    this.setState({ filters }, () => {
      // console.log("filter", this.state.filters);
    });
  };
  enterCheck = (event) => {
    if (event.key === "Enter") this.filterList();
  };

  exportExcelData = () => {
    // this.setState({ dataLoading: true }, () => {});
    let filterPayLoad = {
      role: this.state.roles ? this.state.roles : "",
      description: this.state.description ? this.state.description : "",
    };
    // console.log(">>>>>>>>>>>>>>>>")
    // Call the action function using await to ensure proper execution
    this.props.fetchRolesExcel(0, filterPayLoad, (res) => {
      console.log(res);
      let exportData = [];
      if (res?.data?.length > 0) {
        res.data.map((ele) => {
          // console.log("ele", ele);
          exportData.push({
            Role:
              ele?.label?.replace(/_/g, " ").charAt(0).toUpperCase() +
              ele?.label?.replace(/_/g, " ").slice(1),
            Description: ele?.description.toUpperCase(),
          });
        });
      }
      // console.log("export Data", exportData);
      if (exportData?.length > 0) {
        exportToCSV([...exportData], "Roles");
      } else {
        exportData.push({
          Name: "",
          Description: "",
        });
        exportToCSV([...exportData], "Roles");
      }
    });
  };

  render() {
    const { rows } = this.state;
    const { rolesData } = this.props;
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    darkTheme = mode === "dark__mode" ? true : false;
    let RoleArr = [];
    if (Array.isArray(rolesData?.data)) {
      let Roles = rolesData?.data;
      totCnt = rolesData?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = rolesData?.offset + rolesData?.data?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      Roles.forEach((ele) => {
        RoleArr.push({
          name:
            ele?.label?.replace(/_/g, " ").charAt(0).toUpperCase() +
            ele?.label?.replace(/_/g, " ").slice(1),
          description: ele?.description.toUpperCase(),
          permissions: (
            <div className="tooltipIconComment">
              <div className="ellipsis-text-roles">
                {ele?.permissions?.map(
                  (item) =>
                    item.label
                      .toLowerCase()
                      .split(" ")
                      .map((word, index) =>
                        index === 0
                          ? word
                          : word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join("") +
                    "," +
                    " "
                )}
              </div>
              <div className="tooltipIcontext">
                <ul>
                  {ele?.permissions?.map((item, index) => (
                    <li key={index}>
                      {" "}
                      {item.label
                        .toLowerCase()
                        .split(" ")
                        .map((word, index) =>
                          index === 0
                            ? word
                            : word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join("") +
                        "," +
                        " "}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ),
        });
      });
    }
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let userType = authUser?.data?.role?.label;
    let isBaxter = process.env.REACT_APP_CLIENT === "3_BAXTER";
    // if (!editAccess && !delAccess) data.columns.pop(-1);

    return (
      <>
        <div>
          {getLocalStorage()}
          <QuickSearchFilters
            toggleExcel={this.exportExcelData}
            filterValue={this.state.filters}
            change={this.change}
            filterList={this.filterList}
            reset={this.reset}
            enterCheck={this.enterCheck}
            mode={darkTheme}
          />
          <Container fluid>
            {userType && (userType !== "admin" || userType !== "Admin") ? (
              <div className="roleIndex">
                <Index
                  {...this.props}
                  headers={AdminHeaderData}
                  data={RoleArr}
                  searchBar={searchBar}
                  searchable={isBaxter ? true : false}
                  getAllData={this.getAllData}
                  reset={this.reset}
                  searchValueAssignerFunction={this.searchValueAssignerFunction}
                  rowHeight={"56px"}
                  fetchId={this.props.fetchId}
                  dataLoading={this.state.dataLoading}
                  filterMaintain={"off"}
                />
              </div>
            ) : (
              <div className="roleIndex">
                <Index
                  {...this.props}
                  headers={AdminHeaderData}
                  data={RoleArr}
                  searchBar={searchBar}
                  searchable={isBaxter ? true : false}
                  reset={this.reset}
                  fetchId={this.props.fetchId}
                  searchValueAssignerFunction={this.searchValueAssignerFunction}
                  getAllData={this.getAllData}
                  rowHeight={"56px"}
                  dataLoading={this.state.dataLoading}
                  filterMaintain={"off"}
                />
              </div>
            )}
          </Container>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  policies: state.AccessPolicy.accessPolicies.policies,
  rolesData: state.AccessPolicy.accessPolicies.fetchRole,
  fetchId: state.AccessPolicy.accessPolicies?.fetchId,
});

export default connect(mapStateToProps, {
  fetchRoles,
  fetchRolesExcel,
  deleteRole,
  fetchRolesfilter,
})(Roles_New);
