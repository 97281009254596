import { types } from "./actionTypes";
const initialState = {
  getLocationDetails: null,
};
const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LOCATIONS_SUCCESS:
      state = {
        ...state,
        getLocationDetails: action.payload,
      };
      return state;
    default:
      return state;
  }
};
export default locationReducer;
