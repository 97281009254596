import React, { Component } from "react";
import QualityCheckStart from "./QualityCheckStart";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import {
  Row,
  Col,
  Container,
  CardBody,
  Card,
  Button,
  Input,
  InputGroup,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { WaveLoader } from "../../components/Common/Loader/LoaderIndex";
import moment from "moment";
// import from store
import { qualityCheckStart } from "../../store/baxter-trip/actions";

import { InputField } from "../../components/Common/DataTableNeo/inputFields/Fields";
import { fetchVehicleTypesFilter } from "../../store/actions";
import { debouncing } from "../../components/Common/utils";


class Questionnaire extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [
        {
          id: 1,
          head: "inspectTrailerForForeignMaterial",
          action:
            "Inspect trailer for foreign material?Examples:Poisons, or other Toxins",
          question: "inspectTrailerForForeignMaterialCheck",
          answer: null,
          comments: "",
        },
        {
          id: 2,
          head: "inspectTrailerForOdorsOrResidues",
          action: "Inspect trailer for odors or residues?",
          question: "inspectTrailerForOdorsOrResiduesCheck",
          answer: null,
          comments: "",
        },
        {
          id: 3,
          head: "inspectTrailerForTheFollowingNoHolesInFloorWallCeilingOrProtruding",
          action:
            "Inspect trailer for the following: No holes in floor, wall, ceiling or protruding",
          question:
            "inspectTrailerForTheFollowingNoHolesInFloorWallCeilingOrProtrudingCheck",
          answer: null,
          comments: "",
        },
        {
          id: 4,
          head: "inCaseOfPatientDeliveriesClosedBodyVehicleToBeDeployed",
          action:
            "In case of Patient Deliveries Closed Body Vehicle to be Deployed",
          question:
            "inCaseOfPatientDeliveriesClosedBodyVehicleToBeDeployedCheck",
          answer: null,
          comments: "",
        },
        {
          id: 5,
          head: "refrigerationUnitIsOperatingIfLoadRequiresEnvironmentalControl",
          action:
            "Refrigeration unit is operating if load requires environmental control.",
          question:
            "refrigerationUnitIsOperatingIfLoadRequiresEnvironmentalControlCheck",
          answer: null,
          comments: "",
        },
        {
          id: 6,
          head: "dockOrLockLightsVerifiedWorkingInsideAndOutside",
          action: "Ck/Lock lights verified, working inside and outside.",
          question: "dockOrLockLightsVerifiedWorkingInsideAndOutsideCheck",
          answer: null,
          comments: "",
        },
        {
          id: 7,
          head: "basedOnAboveInspectionCriteriaThisTrailerIsSuitableForTransportOfMedicalProduct",
          action:
            "Based on above inspection criteria, this trailer is suitable for transport of medical product.",
          question:
            "basedOnAboveInspectionCriteriaThisTrailerIsSuitableForTransportOfMedicalProductCheck",
          answer: null,
          comments: "",
        },
      ],
      dataLoading: false,
      tripId: "",
      tripIds:"",
      vehicle: "",
      vehicleType:[{label:"",value:""}],
      selectedVehicleType:null,
      driverName: "",
      driverPhone: "",
      type: "",
      driverName: "",
      driverPhone: "",
      issueDate: "",
      effectiveDate: "",
      carrier: "",
      dateArrived: "",
      type: "",
      remarks: "",
      initiatedBy: "",
      initiatedDate: null,
      bolNumber: "",

      inspectedBy: "",
      inspectedDate: "",
      reviewedBy: "",
      reviewedDate: "",

      basicFreightCharges: "",
      loadingCharges: "",
      unLoadingCharges: "",
      detentionCharges: "",  

      errors: {},
    };
  }

  componentDidMount = () => {
    this.getData();
    this.getVehicleTypes()
  };
  getData = () => {
    if (this.props?.location?.state?.tripDetail) {
      let data = this.props?.location?.state?.tripDetail;
      // console.log(data, "data");
      this.setState(
        {
          tripId: data?._id,
          tripIds:data?.tripId,
          vehicle: data?.vehicle?.regNo,
          driverName: data?.driver?.name,
          driverPhone: data?.driver?.phone,
        },
        () => {}
      );
    }
  };

  getVehicleTypes = (label = "") => {
    let hehe = this.props?.fetchVehicleTypesFilter(0, { label }, (data) => {
      data = data?.data?.docs;
      let options = [];
      while (data?.length > 0) {
        let singleEntry = data?.shift();
        options.push({ label: singleEntry?.label,
            value: { vehicleName: singleEntry.label, vehicleId: singleEntry._id },
            vehicleTypeCode: singleEntry?.vehicleTypeCode })
      }
      this.setState({vehicleType: options})
    })
  };

  handleAnswerChange = (event, questionId) => {
    const newQuestions = this.state.questions.map((q) => {
      if (q.id === questionId) {
        return {
          ...q,
          answer: event.target.value,
        };
      }
      return q;
    });

    this.setState({
      questions: newQuestions,
    });
  };
  handleCommentInput = (event, questionId) => {
    const newQuestions = this.state.questions.map((q) => {
      if (q.id === questionId) {
        return {
          ...q,
          comments: event.target.value,
        };
      }
      return q;
    });

    this.setState({
      questions: newQuestions,
    });
  };

  handleReset = () => {
    const questions = this.state.questions.map((q) => {
      return {
        ...q,
        answer: null,
        comments: "",
      };
    });

    this.setState({
      questions: questions,
      type: "",
      remarks: "",
      effectiveDate: "",
      issueDate: "",
      carrier: "",
      dateArrived: "",
      type: "",
      remarks: "",
      initiatedBy: "",
      initiatedDate: null,
      bolNumber: "",

      inspectedBy: "",
      inspectedDate: "",
      reviewedBy: "",
      reviewedDate: "",
      basicFreightCharges: "",
      loadingCharges: "",
      unLoadingCharges: "",
      detentionCharges: "",
      errors:{},
      selectedVehicleType: {label:null,value:null}
    });
  };
  handleChange = (e) => {
    this.setState(
      {
        type: e.target.value,
      },
      () => {}
    );
  };

  onChangeInput = (name, val) => {
    this.setState(
      {
        [`${name}`]: val,
      },
      () => {}
    );
  };
  handleSubmit = async () => {
    const {
      tripId,
      vehicle,
      selectedVehicleType,
      driverName,
      driverPhone,
      issueDate,
      effectiveDate,
      carrier,
      dateArrived,
      type,
      remarks,
      initiatedBy,
      initiatedDate,
      inspectedBy,
      inspectedDate,
      reviewedBy,
      reviewedDate,
      bolNumber,
      basicFreightCharges,
      loadingCharges,
      unLoadingCharges,
      detentionCharges,  
    } = this.state;
    let answersData = {};
    for (let i = 0; i < this.state.questions?.length; i++) {
      let question = this.state.questions[i];
      let answerObj = {
        [question.question]: question.answer,
        comments: question.comments,
      };
      answersData[question.head] = answerObj;
    }
    // console.log(answersData, "answers");
    let qualityPayloadValues = {
      vehicleRegNo: vehicle,
      tripId: tripId?tripId?.toString():"",
      driverName: driverName,
      driverPhone: driverPhone,
      issueDate: issueDate?moment(issueDate).format('DD-MM-YYYY'):"",
      effectiveDate: effectiveDate? moment(effectiveDate).format('DD-MM-YYYY'):"",
      carrier: carrier?carrier:"",
      dateArrived: dateArrived?moment(dateArrived).format('DD-MM-YYYY'):"",
      type: type?type:"",
      remarks: remarks?remarks:"",
      initiatedBy: initiatedBy?initiatedBy:"",
      initiatedDate: initiatedDate?moment(initiatedDate).format('DD-MM-YYYY'):"",
      bolNumber: bolNumber?bolNumber:"",
      answers: answersData,
      inspectedBy: inspectedBy?inspectedBy:"",
      inspectedDate: inspectedDate?moment(inspectedDate).format('DD-MM-YYYY'):"",
      reviewedBy: reviewedBy,
      reviewedDate: reviewedDate?moment(reviewedDate).format('DD-MM-YYYY'):"",
      vehicleType: selectedVehicleType,
      charges: {
        basicFreightCharges: parseInt(basicFreightCharges),
        loadingCharges: parseInt(loadingCharges),
        unLoadingCharges: parseInt(unLoadingCharges),
        detentionCharges: detentionCharges? parseInt(detentionCharges) : 0,  
      }
    };
    // console.log(qualityPayloadValues,selectedVehicleType,"payload")
    this.setState({ errors: this.validateValAdd(qualityPayloadValues), dataLoading: true }, () => {
    if (
      Object.keys(this.state.errors).length === 0
    ) {
      this.props.qualityCheckStart(qualityPayloadValues, (res) => {
        // console.log("res", res);
        if (res.success) {
          NotificationManager.success(res["message"], "Success");
          this.handleReset();
          this.props.history.push("/tripList");
        } else {
          NotificationManager.error(res["message"], "Error");
          this.setState({ dataLoading: false });
        }
      });
    } else {
      NotificationManager.error("Required All fields", "Error");
      this.setState({ dataLoading: false });
    }
  });
  };
  validateValAdd = () => {
    const errorsAdd = {};
    let isAnswersValid = true;
    let isCommentValid = true;
    this.state.questions.forEach(question => {
      if (!question.answer) {
        isAnswersValid = false;
      }
      if (!question.comments) {
        isCommentValid = false;
      }
    });
    let {
      vehicle,
      driverName,
      driverPhone,
      type,
      issueDate,
      effectiveDate,
      carrier,
      dateArrived,
      remarks,
      initiatedBy,
      initiatedDate,
      bolNumber,
      inspectedBy,
      inspectedDate,
      reviewedBy,
      reviewedDate,
      selectedVehicleType,
      basicFreightCharges,
      loadingCharges,
      unLoadingCharges,
    } = this.state;

     if (!vehicle) {
       errorsAdd.vehicle = "Vehicle is required !";
     }
     if (!selectedVehicleType) {
      errorsAdd.selectedVehicleType = "Vehicle Type is required !";
     }
     if (!driverName) {
       errorsAdd.driverName = "Name is required !";
     }
     if (!driverPhone) {
       errorsAdd.driverPhone = "Phone is required !";
     }
     if (!type) {
       errorsAdd.type = "Type is required !";
     }
    if (!issueDate) {
       errorsAdd.issueDate = "Issue Date is required !";
     }
     if (!effectiveDate) {
      errorsAdd.effectiveDate = "Effective Date  is required !";
    }
     if (!carrier) {
       errorsAdd.carrier = "Carrier is required !";
    }
     if (!dateArrived) {
       errorsAdd.dateArrived = "Arrived Date is required !";
     }
     
    //  if (!remarks) {
    //    errorsAdd.remarks = "Remarks is required !";
    //  }

     if (!initiatedBy) {
       errorsAdd.initiatedBy = "Initiated By is required !";
     }

     if (!initiatedDate) {
      errorsAdd.initiatedDate = "Initiated Date is required !";
    }
    if (!bolNumber) {
     errorsAdd.bolNumber = "Bill No is required !";
   }
    if (!inspectedBy) {
      errorsAdd.inspectedBy = "Inspected By is required !";
   }
    if (!inspectedDate) {
      errorsAdd.inspectedDate = "Inspected Date is required !";
    }
    
    if (!reviewedBy) {
      errorsAdd.reviewedBy = "Reviewed By is required !";
    }

    if (!reviewedDate) {
      errorsAdd.reviewedDate = "Reviewed Date is required !";
    }
    // if (!basicFreightCharges) {
    //   errorsAdd.basicFreightCharges = "Basic Freight Charges is required !";
    // }
    // if (!loadingCharges) {
    //   errorsAdd.loadingCharges = "Loading Charges Charges is required !";
    // }
    // if (!unLoadingCharges) {
    //   errorsAdd.unLoadingCharges = "Unloading Charges Charges is required !";
    // }

    if (!isAnswersValid) {
      errorsAdd.isAnswersValid = "One or more answers are empty or null !";
    } else {
      // console.log('All answers are valid');
    }
    // if (!isCommentValid) {
    //   errorsAdd.isCommentValid = "One or more Comments are empty or null !";
    // } else {
    //   console.log('All Comments are valid');
    // }

    return errorsAdd;
  };

  render() {
    const {errors,dataLoading} = this.state;
    return (
      <>
      {dataLoading ? (
        <WaveLoader />
      ) : (
      <div>
        <Container fluid={true} style={{ backgroundColor: "white" }}>
        <Row>
            <Col xs={9} className="d-flex flex-row bd-highlight mb-2 ">
              <Button
                style={{
                  width: "60px",
                  height: "36px",
                  backgroundColor: "#3e3e3e",
                }}
                color="secondary"
                size="sm"
                onClick={() =>
                  this.props.history.push({
                    pathname: "/tripList",
                  })
                }
              >
                <i className="fas fa-arrow-circle-left fa-2x"></i>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <Card style={{ padding: "2%" }}>
                <Row className="mt-2 mr-4">
                  <Col xs={12} md={3}>
                    <div>
                      <span className="fontRoboto15 light__span">
                        Trip Id :
                      </span>{" "}
                      <span className="fontRoboto154 dark__span">
                        {this.state.tripIds}
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col xs={12} md={2}>
                    <div className="fontRoboto15 light__span">Vehicle</div>

                    <Input
                      name="vehicle"
                      placeholder="Vehicle"
                      type="text"
                      size="xs"
                      value={this.state.vehicle}
                      className="form-control-xs mt-2"
                      id="exampleFormControlInput1"
                      style={{
                        width: "100%",
                        height: "37%",
                      }}
                      onChange={(e) =>
                        this.onChangeInput("vehicle", e.target.value)
                      }
                    />
                    
                      {errors?.vehicle && (
                            <div className="error">{errors?.vehicle} </div>
                          )}
                  </Col>
                  <Col xs={12} md={2}>
                    {/* <div className="fontRoboto15 light__span">Vehicle Type</div> */}
                    <InputField
                          inputName={"Vehicle Type"}
                          labelValue={"Vehicle Type"}
                          labelStyle={{"margin-bottom":"0px"}}
                          labelClass={"fontRoboto15 light__span"}
                          controlType={"select"}
                          // value={this.state[item.field]}
                          placeholderValue={
                            "Vehicle Type"
                          }
                          inputValue={this.state.selectedVehicleType?.label}
                          onChangeMethod={(e) => {
                            this.onChangeInput('selectedVehicleType', e?.target?.value);
                          }}
                          onInputChange={(e) => debouncing(()=>this.getVehicleTypes(e)) }
                          optionList={this.state.vehicleType}
                          // handleKeyPress={this.keyPress}
                        />
                          {errors?.selectedVehicleType && (
                            <div className="error">{errors?.selectedVehicleType} </div>
                          )}
                  </Col>
                  <Col xs={12} md={2}>
                    <div className="fontRoboto15 light__span">Driver Name</div>
                    <Input
                      name="driverName"
                      placeholder="Driver Name"
                      type="text"
                      size="xs"
                      value={this.state.driverName}
                      className="form-control-xs mt-2"
                      id="exampleFormControlInput1"
                      style={{
                        width: "100%",
                        height: "37%",
                      }}
                      onChange={(e) =>
                        this.onChangeInput("driverName", e.target.value)
                      }
                    />
                     {errors?.driverName && (
                            <div className="error">{errors?.driverName} </div>
                          )}
                  </Col>
                  <Col xs={12} md={2}>
                    <div className="fontRoboto15 light__span">Driver Phone Number</div>
                    <Input
                      name="driverPhone"
                      placeholder="Driver Phone Number"
                      type="text"
                      size="xs"
                      value={this.state.driverPhone}
                      className="form-control-xs mt-2"
                      id="exampleFormControlInput1"
                      style={{
                        width: "100%",
                        height: "37%",
                      }}
                      onChange={(e) =>
                        this.onChangeInput("driverPhone", e.target.value)
                      }
                    />
                       {errors?.driverPhone && (
                            <div className="error">{errors?.driverPhone} </div>
                          )}
                  </Col>
                  <Col xs={12} md={2}>
                    <div className="fontRoboto15 dark__span">Carrier</div>
                    <Input
                      name="carrier"
                      placeholder="Carrier"
                      type="text"
                      size="xs"
                      value={this.state.carrier}
                      className="form-control-xs mt-2"
                      id="exampleFormControlInput1"
                      style={{
                        width: "100%",
                        height: "38%",
                      }}
                      onChange={(e) =>
                        this.onChangeInput("carrier", e.target.value)
                      }
                    />
                    {errors?.carrier && (
                            <div className="error">{errors?.carrier} </div>
                          )}
                  </Col>
                  <Col xs={12} md={2}>
                    <div className="fontRoboto15 dark__span">
                      Initiated Date
                    </div>

                    <DatePicker
                      selected={this.state.initiatedDate}
                      className="form-control custom-datepicker mt-2"
                      minDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                      onChange={(e) => {
                        this.onChangeInput("initiatedDate", e);
                      }}
                      placeholderText="Select a Initiated date"
                    />
                      {errors?.initiatedDate && (
                            <div className="error">{errors?.initiatedDate} </div>
                          )}
                  </Col>

                </Row>
                <Row className="mt-4">
                <Col xs={12} md={2}>
                    <div className="fontRoboto15 dark__span">Bill</div>
                    <Input
                      name="bolNumber"
                      placeholder="Bill of Lading No"
                      type="text"
                      size="xs"
                      value={this.state.bolNumber}
                      className="form-control-sm mt-2"
                      id="exampleFormControlInput1"
                      style={{
                        width: "100%",
                        height: "38%",
                      }}
                      onChange={(e) =>
                        this.onChangeInput("bolNumber", e.target.value)
                      }
                    />
                      {errors?.bolNumber && (
                            <div className="error">{errors?.bolNumber} </div>
                          )}
                  </Col>
                  <Col xs={12} md={2}>
                    <div className="fontRoboto15 dark__span">Issue Date</div>
                    <DatePicker
                      selected={this.state.issueDate}
                      className="form-control custom-datepicker mt-2"
                      minDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                      onChange={(e) => {
                        this.onChangeInput("issueDate", e);
                      }}
                      placeholderText="Select a Issue date"
                    />
                     {errors?.issueDate && (
                            <div className="error">{errors?.issueDate} </div>
                          )}
                  </Col>
                  <Col xs={12} md={2}>
                    <div className="fontRoboto15 dark__span">Date Arrived</div>
                    <DatePicker
                      selected={this.state.dateArrived}
                      className="form-control custom-datepicker mt-2"
                      minDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                      onChange={(e) => {
                        this.onChangeInput("dateArrived", e);
                      }}
                      placeholderText="Select a Arrived date"
                    />
                      {errors?.dateArrived && (
                            <div className="error">{errors?.dateArrived} </div>
                          )}
                  </Col>
                  <Col xs={12} md={2}>
                    <div className="fontRoboto15 dark__span">
                      Effective Date
                    </div>
                    <DatePicker
                      selected={this.state.effectiveDate}
                      className="form-control custom-datepicker mt-2"
                      minDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                      onChange={(e) => {
                        this.onChangeInput("effectiveDate", e);
                      }}
                      placeholderText="Select a Effective Date"
                    />
                        {errors?.effectiveDate && (
                            <div className="error">{errors?.effectiveDate} </div>
                          )}
                  </Col>
                  <Col xs={12} md={2}>
                    <div className="fontRoboto15 dark__span">Initiated By</div>
                    <Input
                      name="initiatedBy"
                      placeholder="Initiated By"
                      type="text"
                      size="xs"
                      value={this.state.initiatedBy}
                      className="form-control-xs mt-2"
                      id="exampleFormControlInput1"
                      style={{
                        width: "100%",
                        height: "39%",
                      }}
                      onChange={(e) =>
                        this.onChangeInput("initiatedBy", e.target.value)
                      }
                    />
                     {errors?.initiatedBy && (
                            <div className="error">{errors?.initiatedBy} </div>
                          )}
                  </Col>
                  <Col xs={12} md={2}>
                    <div className="fontRoboto15 dark__span">Inspected By</div>
                    <Input
                      name="inspectedBy"
                      placeholder="Inspected By"
                      type="text"
                      size="xs"
                      value={this.state.inspectedBy}
                      className="form-control-sm mt-2"
                      id="exampleFormControlInput1"
                      style={{
                        width: "100%",
                        height: "39%",
                      }}
                      onChange={(e) =>
                        this.onChangeInput("inspectedBy", e.target.value)
                      }
                    />
                        {errors?.inspectedBy && (
                            <div className="error">{errors?.inspectedBy} </div>
                          )}
                  </Col>
                </Row>
                <Row className="mt-4">
                <Col xs={12} md={2}>
                    <div className="fontRoboto15 dark__span">
                      Inspected Date
                    </div>
                    <DatePicker
                      selected={this.state.inspectedDate}
                      className="form-control custom-datepicker mt-2"
                      minDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                      onChange={(e) => {
                        this.onChangeInput("inspectedDate", e);
                      }}
                      placeholderText="Select a  Inspected Date"
                    />
                      {errors?.inspectedDate && (
                            <div className="error">{errors?.inspectedDate} </div>
                          )}
                  </Col>
                  <Col xs={12} md={2}>
                    <div className="fontRoboto15 dark__span">Reviewed By</div>
                    <Input
                      name="initiatedBy"
                      placeholder="Reviewed By"
                      type="text"
                      size="xs"
                      value={this.state.reviewedBy}
                      className="form-control-xs mt-2"
                      id="exampleFormControlInput1"
                      style={{
                        width: "100%",
                        height: "40%",
                      }}
                      onChange={(e) =>
                        this.onChangeInput("reviewedBy", e.target.value)
                      }
                    />
                      {errors?.reviewedBy && (
                            <div className="error">{errors?.reviewedBy} </div>
                          )}
                  </Col>
                  <Col xs={12} md={2}>
                    <div className="fontRoboto15 dark__span">Reviewed Date</div>
                    <DatePicker
                      selected={this.state.reviewedDate}
                      className="form-control custom-datepicker mt-2"
                      minDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                      onChange={(e) => {
                        this.onChangeInput("reviewedDate", e);
                      }}
                      placeholderText="Select a  Reviewed Date"
                    />
                     {errors?.reviewedDate && (
                            <div className="error">{errors?.reviewedDate} </div>
                          )}
                  </Col>
                  {/* <Col xs={12} md={2}>
                    <div className="fontRoboto15 dark__span">Basic Freight Charges</div>
                    <Input
                      name="basicFreightCharges"
                      placeholder="Basic Freight Charges"
                      type="number"
                      size="xs"
                      value={this.state.basicFreightCharges}
                      className="form-control-xs mt-2"
                      id="exampleFormControlInput1"
                      style={{
                        width: "100%",
                        height: "40%",
                      }}
                      onChange={(e) =>
                        this.onChangeInput("basicFreightCharges", e.target.value)
                      }
                    />
                      {errors?.basicFreightCharges && (
                            <div className="error">{errors?.basicFreightCharges} </div>
                          )}
                  </Col>
                  <Col xs={12} md={2}>
                    <div className="fontRoboto15 dark__span">Loading Charges</div>
                    <Input
                      name="loadingCharges"
                      placeholder="Loading Charges"
                      type="number"
                      size="xs"
                      value={this.state.loadingCharges}
                      className="form-control-xs mt-2"
                      id="exampleFormControlInput1"
                      style={{
                        width: "100%",
                        height: "40%",
                      }}
                      onChange={(e) =>
                        this.onChangeInput("loadingCharges", e.target.value)
                      }
                    />
                      {errors?.loadingCharges && (
                            <div className="error">{errors?.loadingCharges} </div>
                          )}
                  </Col>
                  <Col xs={12} md={2}>
                    <div className="fontRoboto15 dark__span">Unloading Charges</div>
                    <Input
                      name="unLoadingCharges"
                      placeholder="Unloading Charges"
                      type="number"
                      size="xs"
                      value={this.state.unLoadingCharges}
                      className="form-control-xs mt-2"
                      id="exampleFormControlInput1"
                      style={{
                        width: "100%",
                        height: "40%",
                      }}
                      onChange={(e) =>
                        this.onChangeInput("unLoadingCharges", e.target.value)
                      }
                    />
                      {errors?.unLoadingCharges && (
                            <div className="error">{errors?.unLoadingCharges} </div>
                          )}
                  </Col> */}
                  </Row>
                {/* <Row className="mt-4">
                  <Col xs={12} md={2}>
                    <div className="fontRoboto15 dark__span">Detention Charges</div>
                    <Input
                      name="detentionCharges"
                      placeholder="Detention Charges"
                      type="number"
                      size="xs"
                      value={this.state.detentionCharges}
                      className="form-control-xs mt-2"
                      id="exampleFormControlInput1"
                      style={{
                        width: "100%",
                        height: "40%",
                      }}
                      onChange={(e) =>
                        this.onChangeInput("detentionCharges", e.target.value)
                      }
                    />
                  </Col>
                </Row> */}
                <Row className="mt-3">
                  <Col xs={12} md={8}>
                    <div className="fontRoboto15 dark__span mt-4">
                      Select Truck Type?
                    </div>
                    <Row className="ml-2">
                      <Col xs={3} lg={3} className="typeContainer">
                        <input
                          className="form-check-input"
                          type="radio"
                          value="Van"
                          checked={this.state.type === "Van"}
                          onChange={this.handleChange}
                        />
                        <label className="form-check-label fontRoboto154 light__span">
                          Van
                        </label>
                      </Col>
                      <Col xs={3} lg={3} className="typeContainer">
                        <input
                          className="form-check-input"
                          type="radio"
                          checked={this.state.type === "Refrigerated"}
                          value="Refrigerated"
                          onChange={this.handleChange}
                        />
                        <label className="form-check-label fontRoboto154 light__span">
                          Refrigerated
                        </label>
                      </Col>
                      <Col xs={3} lg={3} className="typeContainer">
                        <input
                          className="form-check-input"
                          type="radio"
                          value="Container"
                          checked={this.state.type === "Container"}
                          onChange={this.handleChange}
                        />
                        <label className="form-check-label fontRoboto154 light__span">
                          Container
                        </label>
                      </Col>
                      <Col xs={3} lg={3} className="typeContainer">
                        <input
                          className="form-check-input"
                          type="radio"
                          checked={this.state.type === "Other"}
                          value="Other"
                          onChange={this.handleChange}
                        />
                        <label className="form-check-label fontRoboto154 light__span">
                          Other
                        </label>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={4}>
                    <div className="fontRoboto15 dark__span mt-4">Remark</div>
                    <Row className="">
                      <Col xs={3} lg={12} className="typeContainer">
                        <Input
                          name="remarks"
                          placeholder="Remark"
                          type="text"
                          size="xs"
                          value={this.state.remarks}
                          className="form-control-xs"
                          id="exampleFormControlInput1"
                          style={{
                            width: "59%",
                            height: "84%",
                          }}
                          onChange={(e) =>
                            this.onChangeInput("remarks", e.target.value)
                          }
                        />
                         {errors?.remarks && (
                            <div className="error">{errors?.remarks} </div>
                          )}
                      </Col>
                    </Row>
                  </Col>
                  {errors?.type && (
                            <div className="error">{errors?.type} </div>
                          )}
                </Row>
              </Card>
              <Card>
                <CardBody>
                <div className="container-fluid">
                 <div className="row">
                   <div className="col-sm-9">
                   {errors?.isAnswersValid && (
                      <div className="error text-center">{errors?.isAnswersValid} </div>
                    )}
                   </div>
                   <div className="col-sm-3 d-flex justify-content-end">
                   {errors?.isCommentValid && (
                      <div className="error  text-right">{errors?.isCommentValid} </div>
                    )}
                   </div>
                 </div>
                </div>
                  
                 
                  {this.state.questions.map((question) => (
                    <QualityCheckStart
                      key={question.id}
                      id={question.id}
                      question={question.question}
                      answer={question.answer}
                      comments={question.comments}
                      action={question.action}
                      handleAnswerChange={this.handleAnswerChange}
                      handleCommentInput={this.handleCommentInput}
                      errors={this.state.errors}
                    />
                  ))}
                 
                  <div className="mb-3 mt-4">
                    <Button
                      className="resetButtonStyle fontRoboto15"
                      style={{ backgroundColor: "#0AB38C", color: "white" }}
                      onClick={() => this.handleReset()}
                    >
                      Reset
                    </Button>
                    <Button
                      className="submitButtonStyle fontRoboto15"
                      style={{
                        backgroundColor: "#05284E",
                        color: "white",
                      }}
                      onClick={() => {
                        this.handleSubmit();
                      }}
                    >
                      Submit
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* <h2>Answers:</h2>
        <ul>
          {this.state.questions.map((question) => (
            <li key={question.id}>
              {question.question}: {question?.answer}
            </li>
          ))}
        </ul> */}
      </div>
      )}
      </>
    );
  }
}

export default connect(null, {
  qualityCheckStart,
  fetchVehicleTypesFilter
})(Questionnaire);
