import {
  CHECK_LOGIN,
  LOGIN_USER_SUCCESSFUL,
  API_ERROR,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOAD_USER_SUCCESSFUL,
  UPDATE_USER_DATA
} from "./actionTypes";

const initialState = {
  loginError: "",
  message: null,
  loading: false,
  user: "",
  users: [],
  loggedIn: false,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_LOGIN:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOGIN_USER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        loggedIn: true,
        user: action.payload,
      };
      break;
    case LOAD_USER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        user: action.payload,
      };
      break;
    case LOGOUT_USER:
      state = { ...state };
      break;

    case LOGOUT_USER_SUCCESS:
      state = { ...state, loggedIn: false, loginError: "" };
      break;

   //Baxter API user update
    case UPDATE_USER_DATA:
      state = { ...state};
      break;
    case API_ERROR:
      state = {
        ...state,
        loading: false,
        loginError: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
