import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Button,
} from "reactstrap";
import Dropzone from "react-dropzone";

import { Link } from "react-router-dom";

import Sample from "../../../src/IndentUpload.csv";

import Breadcrumbs from "../../components/Common/Breadcrumb";

import ModalPage from "./Modal";

class UploadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFiles: [],
      errorMsg: "",
      showModal: false,
      breadcrumbItems: [],
    };
  }

  componentDidMount = () => {
    //Sample file URL and breadcrumbs from props
    let title = this.props.page;
    // console.log(this.props);
    this.csvUrl = Sample;
    this.setState({
      breadcrumbItems: [
        {
          title: this.props.page,
          link: `${this.props.page}`,
        },
        { title: "Indents " + " " + "Upload", link: "#" },
      ],
    });
  };

  handleAcceptedFiles = async (files) => {
    files.map((file) => {
      //   Object.assign(file, {
      //     preview: URL.createObjectURL(file),
      //     formattedSize: this.formatBytes(file.size),
      //   });
    });
    // const reader = new FileReader();
    // reader.addEventListener("load", () => {
    //   let data = {};
    //   data.file = new Uint8Array(reader.result);
    //   // call api here
    // });
    // reader.readAsArrayBuffer(files[0]);
    // console.log(reader);
    this.props.handleSubmit(files)
    this.setState({ selectedFiles: files, errorMsg: "" });
  };

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  render() {
    return (
      <React.Fragment>
        {this.props.showModal ? (
          <ModalPage
            showToggle={this.props.showToggle}
            rows={this.props.rows}
            heads={this.props.heads}
            resolved={this.props.resolved}
            insertData={this.props.insertData}
            clearUpload={this.props.clearUpload}
          />
        ) : null}
        <div className="page-content mt-0 pt-2">
          <Container fluid={true}>
            {/* <Breadcrumbs
              title="Upload Excel File"
              breadcrumbItems={this.state.breadcrumbItems}
            /> */}
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <CardTitle>Drop {this.fileCategory} File</CardTitle>
                    <CardSubtitle className="mb-3">
                      {" "}
                      Download the sample file, update it and upload it again.
                    </CardSubtitle>
                    <Form>
                      <p>
                        <a href={this.props.csvUrl} target="_self" download>
                          click here{" "}
                        </a>
                        to download the sample file
                      </p>

                      <Dropzone
                        accept=".csv,.xlsx"
                        onDrop={(acceptedFiles) =>
                          this.handleAcceptedFiles(acceptedFiles)
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick mt-2"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {this.state.selectedFiles.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {f.name}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          );
                        })}
                      </div>
                    </Form>

                    <div className="text-center mt-4">
                      <Button
                        color="secondary"
                        onClick={() =>this.props.cancel()
                          // this.props.history.push(
                          //   `${
                          //     this.props.history.location.pathname.split("-")[1]
                          //   }s`
                          // )
                        }
                        type="button"
                        className="waves-effect waves-light"
                        style={{ margin: "5px" }}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="primary"
                        onClick={() =>
                          this.props.handleSubmit(this.state.selectedFiles)
                        }
                        type="button"
                        className="waves-effect waves-light"
                      >
                        Submit
                      </Button>
                    </div>
                    <span className="err">{this.state.errorMsg}</span>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default UploadForm;
