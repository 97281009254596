import React, { Component } from "react";
 import { Row, Col, Button } from "reactstrap";
 import { createHop, deleteHop } from "../../store/hop/actions";
 import { NotificationManager } from "react-notifications";
 import { Formik, ErrorMessage } from "formik";
 import { restrict } from "../restrict";
 import { PERMS } from "../../enum/perms.enum";
 import * as Yup from "yup";
 
 import "@vtaits/react-color-picker/dist/index.css";
 import "react-datepicker/dist/react-datepicker.css";
 import Select from "react-select";
 import { connect } from "react-redux";
 import { indianStates } from "./states";
 
 //Import Breadcrumb
 
 import formatInput from "../InputFormatter";
 import { FormGroup, Label, Input } from "reactstrap";
 let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
 
 const initialState = () => ({
   onSubmitDisable: false,
   offset: 0,
   limit: 20,
   stateList: indianStates,
   transporterData: "",
   form: {
     hopCode: "",
     label: "",
     address1: "",
     address2: "",
     address3: "",
     city: "",
     state: "",
     pincode: "",
     latitude: "",
     longitude: "",
   },
 });
 class HopForm extends Component {
   constructor(props) {
     super(props);
     this.state = initialState();
     console.log("this.state",this.state);
     (async () => {
       let redirect = await restrict(PERMS.DRIVER_TRANSPORTER_CREATE);
       // // console.log(redirect, "redirect");
 
       if (redirect === false) {
         this.props.history.push("/operationsDashboard");
       }
     })();
   }
 
   componentDidMount = () => {
   };
 
 
   handleSubmitChange = (e,formik) => {
     const name = e.target.name;
     let value = e.target.value;
     let formData = this.state.form;
     formData[`${name}`] = value;
     formik.setFieldValue(name, value);
 
 
     this.setState({ form: formData }, () => {});
     // console.log("formData",formData);
   };
   validateNumber = (evt) => {
     var theEvent = evt || window.event;
 
     if (theEvent.type === "paste") {
       var key = theEvent.clipboardData.getData("text/plain");
     } else {
       
       key = theEvent.keyCode || theEvent.which;
       key = String.fromCharCode(key);
     }
     var regex = /^[0-9]*$/;
     if (!regex.test(key)) {
       theEvent.returnValue = false;
       if (theEvent.preventDefault) theEvent.preventDefault();
     }
   };
   trimSpace = (event, name) => {
     const { form } = this.state;
     let formObj = formatInput(event, name, form);
     this.setState({ form: formObj });
   };
   reset = (cb) => {
     this.setState(
       {
         form: {
           hopCode: "",
           label: "",
           address1: "",
           address2: "",
           address3: "",
           city: "",
           state: "",
           pincode: "",
           latitude: "",
           longitude: "",
         },
       },
     );
   };
   handleSubmitSave = (formValues) => {
     let {
       hopCode,
       label,
       address1,
       address2,
       address3,
       city,
       state,
       pincode,
       latitude,
       longitude,
     } = this.state.form;
     let formObj = {
         hopCode: hopCode,
         address: {
           addressLine1: address1,
           addressLine2: address2,
           addressLine3: address3,
           city: city,
           pinCode: pincode,
           state: state?.value,
           latitude:latitude,
           longitude:longitude
         },
         description: label, 
         label: label,
       };
       
    //  console.log(formObj, "handleSubmitSave");
     this.props.createHop(formObj, (res) => {
       if (res.message === "Hop has been created successfully") {
         NotificationManager.success(res["message"], "Success");
         console.log("props",this.props);
         if (this.props.handleSubmit) {
           this.props.handleSubmit(false);
         }
 
         this.props.history.push("/hops");
       } else {
         NotificationManager.error(res["message"], "Error");
         this.setState({ onSubmitDisable: false });
       }
     });
   };
   render() {
     const customStyles1 = {
       input: (base) => ({
         ...base,
         color: this.props.mode ? "#9d9d9d" : "#505d69",
       }),
     };
     userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
     const validationSchema = Yup.object(
     {
         hopCode: Yup.string()
           .required("Hop code is required")
           .min(2, "Hop code must be at least 2 characters"),
           
         label: Yup.string()
           .required("Label is required")
           .min(2, "Label must be at least 2 characters"),
       
         address1: Yup.string()
           .required("Address Line 1 is required"),
       
         address2: Yup.string()
           .optional(),
       
         address3: Yup.string()
           .optional(),
       
         city: Yup.string()
           .required("City is required"),
       
         state: Yup.object()
           .required("State is required"),
       
         pincode: Yup.string()
           .required("Pin code is required")
           .matches(/^[0-9]{6}$/, "Pin code must be a valid 6-digit number"),
       
         latitude: Yup.number()
           .typeError("Latitude must be a valid number")
           .required("Latitude is required")
           .min(-90, "Latitude must be between -90 and 90")
           .max(90, "Latitude must be between -90 and 90"),
       
         longitude: Yup.number()
           .typeError("Longitude must be a valid number")
           .required("Longitude is required")
           .min(-180, "Longitude must be between -180 and 180")
           .max(180, "Longitude must be between -180 and 180"),
       }
     );
     const { form } = this.state;
     const role = JSON.parse(localStorage.getItem("authUser"))?.data?.role
       ?.label;
     return (
       <React.Fragment>
         <Row className="HopFormDiv">
           <Col lg={12}>
             <Row>
               <Formik
                 md="12"
                 initialValues={
                   this.props?.location?.mode === "edit" ? this.formSet() : form
                 }
                 validationSchema={validationSchema}
                 onSubmit={this.handleSubmitSave}
                 validateOnChange={false}
               >
                 {(formik) => {
                   const { handleSubmit, values, errors, touched, resetForm } =
                     formik;
                //    console.log("values", values);
                //    console.log(errors);
                   return (
                     <form
                       className="form text-capitalize"
                       onSubmit={handleSubmit}
                       style={{
                         width: " 450px",
                         height: "380px",
                         marginTop: "6px",
                       }}
                     >
                       <FormGroup>
                         <Label
                           className={`required-field  mt-2 ${
                             this.props.mode ? "light__span" : ""
                           }`}
                           style={{
                             color: "#05284E",
                             fontFamily: "Roboto",
                             fontStyle: "normal",
                             fontWeight: "bold",
                             fontSize: "15px",
                             lineHeight: "18px",
                           }}
                         >
                           Hop Code.
                         </Label>
 
                         <Input
                           type="text"
                           value={form.hopCode}
                           onBlur={(e) =>
                             this.trimSpace(e, "hopCode")
                           }
                           onChange={(e) =>
                             this.handleSubmitChange({
                               target: {
                                 name: "hopCode",
                                 value: e.target.value,
                               },
                             },formik)
                           }
                           name="hopCode"
                           id="hopCode"
                           className={
                             errors.hopCode && touched.hopCode
                               ? "input-error"
                               : null
                           }
                           placeholder="Hop Code"
                           style={{
                             height: "50px",
                             width: " 390px",
                             backgroundColor: this.props.mode
                               ? "#2E2E46"
                               : "#FFFFFF",
                             border: "0.5px solid #B9B9B9",
                             boxSizing: "border-box",
                             borderRadius: "4px",
                           }}
                         />
                         <ErrorMessage
                           name="hopCode"
                           component="span"
                           className="error"
                         />
                       </FormGroup>
 
                       <FormGroup>
                         <Label
                           className={`required-field  mt-2 ${
                             this.props.mode ? "light__span" : ""
                           }`}
                           style={{
                             color: "#05284E",
                             fontFamily: "Roboto",
                             fontStyle: "normal",
                             fontWeight: "bold",
                             fontSize: "15px",
                             lineHeight: "18px",
                           }}
                         >
                           Label
                         </Label>
                         <Input
                           type="text"
                           value={form.label}
                           onChange={(e) =>
                             this.handleSubmitChange({
                               target: {
                                 name: "label",
                                 value: e.target.value,
                               },
                             },formik)
                           }
                           name="label"
                           id="label"
                           className={
                             errors.label && touched.label ? "input-error" : null
                           }
                           placeholder="Label"
                           maxlength="10"
                           style={{
                             height: "50px",
                             width: "390px",
                             backgroundColor: this.props.mode
                               ? "#2E2E46"
                               : "#FFFFFF",
                             border: "0.5px solid #B9B9B9",
                             boxSizing: "border-box",
 
                             borderRadius: "4px",
                           }}
                         />
                         <ErrorMessage
                           name="label"
                           component="span"
                           className="error"
                         />
                       </FormGroup>
 
                       <FormGroup>
                         <Label
                           className={`required-field  mt-2 ${
                             this.props.mode ? "light__span" : ""
                           }`}
                           style={{
                             color: "#05284E",
                             fontFamily: "Roboto",
                             fontStyle: "normal",
                             fontWeight: "bold",
                             fontSize: "15px",
                             lineHeight: "18px",
                           }}
                         >
                           Address 1
                         </Label>
                         <Input
                           type="text"
                           value={form.address1}
                           onBlur={(e) =>
                             this.trimSpace(
                               e,
                               "address1"
                             )
                           }
                           onChange={(e) =>
                             this.handleSubmitChange({
                               target: {
                                 name: "address1",
                                 value: e.target.value,
                               },
                             },formik)
                           }
                           name="address1"
                           id="daddress1"
                           className={
                             errors.address1 &&
                             touched.address1
                               ? "input-error form-control"
                               : null
                           }
                           placeholder="Address 1"
                           style={{
                             height: "50px",
                             width: "390px",
                             backgroundColor: this.props.mode
                               ? "#2E2E46"
                               : "#FFFFFF",
                             border: "0.5px solid #B9B9B9",
                             boxSizing: "border-box",
 
                             borderRadius: "4px",
                           }}
                         />
                         <ErrorMessage
                           name="address1"
                           component="span"
                           className="error"
                         />
                       </FormGroup>
                       <FormGroup>
                         <Label
                           className={`mt-2 ${
                             this.props.mode ? "light__span" : ""
                           }`}
                           style={{
                             color: "#05284E",
                             fontFamily: "Roboto",
                             fontStyle: "normal",
                             fontWeight: "bold",
                             fontSize: "15px",
                             lineHeight: "18px",
                           }}
                         >
                           Address 2
                         </Label>
                         <Input
                           type="text"
                           value={form.address2}
                           onBlur={(e) =>
                             this.trimSpace(
                               e,
                               "address2"
                             )
                           }
                           onChange={(e) =>
                             this.handleSubmitChange({
                               target: {
                                 name: "address2",
                                 value: e.target.value,
                               },
                             },formik)
                           }
                           name="address2"
                           id="address2"
                           className={
                             errors.address2 &&
                             touched.address2
                               ? "input-error form-control"
                               : null
                           }
                           placeholder="Address 2"
                           style={{
                             height: "50px",
                             width: "390px",
                             backgroundColor: this.props.mode
                               ? "#2E2E46"
                               : "#FFFFFF",
                             border: "0.5px solid #B9B9B9",
                             boxSizing: "border-box",
 
                             borderRadius: "4px",
                           }}
                         />
                         <ErrorMessage
                           name="address2"
                           component="span"
                           className="error"
                         />
                       </FormGroup>
                       <FormGroup>
                         <Label
                           className={`mt-2 ${
                             this.props.mode ? "light__span" : ""
                           }`}
                           style={{
                             color: "#05284E",
                             fontFamily: "Roboto",
                             fontStyle: "normal",
                             fontWeight: "bold",
                             fontSize: "15px",
                             lineHeight: "18px",
                           }}
                         >
                           Address 3
                         </Label>
                         <Input
                           type="text"
                           value={form.address3}
                           onBlur={(e) =>
                             this.trimSpace(
                               e,
                               "address3"
                             )
                           }
                           onChange={(e) =>
                             this.handleSubmitChange({
                               target: {
                                 name: "address3",
                                 value: e.target.value,
                               },
                             },formik)
                           }
                           name="address3"
                           id="address3"
                           className={
                             errors.address3 &&
                             touched.address3
                               ? "input-error form-control"
                               : null
                           }
                           placeholder="Address 3"
                           style={{
                             height: "50px",
                             width: "390px",
                             backgroundColor: this.props.mode
                               ? "#2E2E46"
                               : "#FFFFFF",
                             border: "0.5px solid #B9B9B9",
                             boxSizing: "border-box",
 
                             borderRadius: "4px",
                           }}
                         />
                         <ErrorMessage
                           name="address3"
                           component="span"
                           className="error"
                         />
                       </FormGroup>
 
                       <FormGroup>
                         <Label
                           className={`required-field  mt-2 ${
                             this.props.mode ? "light__span" : ""
                           }`}
                           style={{
                             color: "#05284E",
                             fontFamily: "Roboto",
                             fontStyle: "normal",
                             fontWeight: "bold",
                             fontSize: "15px",
                             lineHeight: "18px",
                           }}
                         >
                           City
                         </Label>
                         <Input
                           type="text"
                           value={form.city}
                           onBlur={(e) =>
                             this.trimSpace(
                               e,
                               "city"
                             )
                           }
                           onChange={(e) =>
                             this.handleSubmitChange({
                               target: {
                                 name: "city",
                                 value: e.target.value,
                               },
                             },formik)
                           }
                           name="city"
                           id="city"
                           className={
                             errors.city &&
                             touched.city
                               ? "input-error form-control"
                               : null
                           }
                           placeholder="City"
                           style={{
                             height: "50px",
                             width: "390px",
                             backgroundColor: this.props.mode
                               ? "#2E2E46"
                               : "#FFFFFF",
                             border: "0.5px solid #B9B9B9",
                             boxSizing: "border-box",
 
                             borderRadius: "4px",
                           }}
                         />
                         <ErrorMessage
                           name="city"
                           component="span"
                           className="error"
                         />
                       </FormGroup>
 
                       {role !== "transporter" ? (
                         <FormGroup className="select2-container mt-2">
                           <Label
                             className={`required-field control-label ${
                               this.props.mode ? "light__span" : ""
                             }`}
                             style={{
                               color: "#05284E",
                               fontFamily: "Roboto",
                               fontStyle: "normal",
                               fontWeight: "bold",
                               fontSize: "15px",
                               lineHeight: "18px",
                             }}
                           >
                             State
                           </Label>
                           <Select
                             value={form.state}
                             id="typeVehicle"
                             onChange={(e) => {
                               this.handleSubmitChange({
                                 target: {
                                   name: "state",
                                   value: e,
                                 },
                               },formik);
                             }}
                             options={this.state.stateList}
                             classNamePrefix="select2-selection"
                             style={{
                               height: "40px",
                               width: "400px",
                               backgroundColor: "#FFFFFF",
                               border: "0.5px solid #B9B9B9",
                               boxSizing: "border-box",
                               borderRadius: "4px",
                             }}
                             styles={customStyles1}
                             className={this.props.mode ? "darkModeDriver" : ""}
                           />
                           <ErrorMessage
                           name="state"
                           component="span"
                           className="error"
                         />
                         </FormGroup>
                       ) : (
                         ""
                       )}
 
                       <FormGroup>
                         <Label
                           className={`required-field  mt-2 ${
                             this.props.mode ? "light__span" : ""
                           }`}
                           style={{
                             color: "#05284E",
                             fontFamily: "Roboto",
                             fontStyle: "normal",
                             fontWeight: "bold",
                             fontSize: "15px",
                             lineHeight: "18px",
                           }}
                         >
                           Pincode
                         </Label>
                         <Input
                           type="text"
                           value={form.pincode}
                           onChange={(e) =>
                             this.handleSubmitChange({
                               target: {
                                 name: "pincode",
                                 value: e.target.value,
                               },
                             },formik)
                           }
                           name="pincode"
                           id="pincode"
                           className={
                             errors.pincode && touched.pincode ? "input-error" : null
                           }
                           placeholder="909090"
                           maxlength="6"
                           style={{
                             height: "50px",
                             width: "390px",
                             backgroundColor: this.props.mode
                               ? "#2E2E46"
                               : "#FFFFFF",
                             border: "0.5px solid #B9B9B9",
                             boxSizing: "border-box",
 
                             borderRadius: "4px",
                           }}
                         />
                         <ErrorMessage
                           name="pincode"
                           component="span"
                           className="error"
                         />
                       </FormGroup>
 
                       <FormGroup>
                         <Label
                           className={`required-field  mt-2 ${
                             this.props.mode ? "light__span" : ""
                           }`}
                           style={{
                             color: "#05284E",
                             fontFamily: "Roboto",
                             fontStyle: "normal",
                             fontWeight: "bold",
                             fontSize: "15px",
                             lineHeight: "18px",
                           }}
                         >
                           Latitude
                         </Label>
                         <Input
                           type="text"
                           value={form.latitude}
                           onChange={(e) =>
                             this.handleSubmitChange({
                               target: {
                                 name: "latitude",
                                 value: e.target.value,
                               },
                             },formik)
                           }
                           name="latitude"
                           id="latitude"
                           className={
                             errors.latitude && touched.latitude ? "input-error" : null
                           }
                           placeholder="Latitude"
                           maxlength="10"
                           style={{
                             height: "50px",
                             width: "390px",
                             backgroundColor: this.props.mode
                               ? "#2E2E46"
                               : "#FFFFFF",
                             border: "0.5px solid #B9B9B9",
                             boxSizing: "border-box",
 
                             borderRadius: "4px",
                           }}
                         />
                         <ErrorMessage
                           name="latitude"
                           component="span"
                           className="error"
                         />
                       </FormGroup>
 
                       <FormGroup>
                         <Label
                           className={`required-field  mt-2 ${
                             this.props.mode ? "light__span" : ""
                           }`}
                           style={{
                             color: "#05284E",
                             fontFamily: "Roboto",
                             fontStyle: "normal",
                             fontWeight: "bold",
                             fontSize: "15px",
                             lineHeight: "18px",
                           }}
                         >
                           Longitude
                         </Label>
                         <Input
                           type="text"
                           value={form.longitude}
                           onChange={(e) =>
                             this.handleSubmitChange({
                               target: {
                                 name: "longitude",
                                 value: e.target.value,
                               },
                             },formik)
                           }
                           name="longitude"
                           id="longitude"
                           className={
                             errors.longitude && touched.longitude ? "input-error" : null
                           }
                           placeholder="Longitude"
                           maxlength="10"
                           style={{
                             height: "50px",
                             width: "390px",
                             backgroundColor: this.props.mode
                               ? "#2E2E46"
                               : "#FFFFFF",
                             border: "0.5px solid #B9B9B9",
                             boxSizing: "border-box",
 
                             borderRadius: "4px",
                           }}
                         />
                         <ErrorMessage
                           name="longitude"
                           component="span"
                           className="error"
                         />
                       </FormGroup>
 
                       <Row
                         className="mt-2"
                         style={{ marginRight: "200px", float: "right" }}
                       >
                         <Col className="text-center mt-4">
                           <Button
                             type="submit"
                             color="primary"
                             // onClick={() => this.handleSubmitSave()}
                             className=" mr-2 btn-md button-wide"
                             style={{
                               height: "40px",
                               width: "90px",
                               top: " 549px",
                               borderRadius: " 0px",
                               backgroundColor: this.props.mode
                                 ? "#5E40FF"
                                 : "#05284E",
                             }}
                           >
                             {userLang?.common?.SUBMIT}
                           </Button>
 
                           <Button
                             type="button"
                             color="secondary"
                             className=" btn-md button-wide"
                             onClick={() => this.reset(resetForm)}
                             style={{
                               height: "40px",
                               width: "90px",
                               top: " 549px",
                               borderRadius: " 0px",
                               backgroundColor: "#0AB38C",
                             }}
                           >
                             {userLang?.common?.RESET}
                           </Button>
                         </Col>
                       </Row>
                     </form>
                   );
                 }}
               </Formik>
             </Row>
           </Col>
         </Row>
       </React.Fragment>
     );
   }
 }
 const mapStatetoProps = (state) => {
   const user = state.Login.user;
   return { user };
 };
 
 export default connect(mapStatetoProps, {
   createHop,
   deleteHop,
 })(HopForm);

