import { types } from "./actionTypes";
export const FetchOnTimeDelivery = (values,callback) => ({
  type: types.ON_TIME_DELIVERY_REPORT,
  values,
  callback,
});
export const FetchOnTimeDeliveryExport = (values,callback) => ({
  type: types.ON_TIME_DELIVERY_REPORT_EXPORT,
  values,
  callback,
});


