import { types } from "../actionTypes";
const initialState = {
    onTimeDeliveryReportData: [],
    fetchId:"",
    onTimeDeliveryReportExport:[]
};
export default (state = { initialState }, action) => {
  switch (action.type) {
    case types.ON_TIME_DELIVERY_REPORT_SUCCESS:
      return { ...state, onTimeDeliveryReportData: action.payload ,fetchId: "id" + new Date().getTime()};
      case types.ON_TIME_DELIVERY_REPORT_EXPORT_SUCCESS:
      return { ...state, onTimeDeliveryReportExport: action.payload};

    default:
      state = { ...state };
      break;
  }

  return state;
};
