/* LAYOUT */
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const CHANGE_LAYOUT_WIDTH = "CHANGE_LAYOUT_WIDTH";
export const CHANGE_SIDEBAR_THEME = "CHANGE_SIDEBAR_THEME";
export const CHANGE_SIDEBAR_TYPE = "CHANGE_SIDEBAR_TYPE";

// topbar
export const CHANGE_TOPBAR_THEME = "CHANGE_TOPBAR_THEME";

/* RIGHT SIDEBAR */
export const TOGGLE_RIGHT_SIDEBAR = "TOGGLE_RIGHT_SIDEBAR";
export const SHOW_RIGHT_SIDEBAR = "SHOW_RIGHT_SIDEBAR";
export const HIDE_RIGHT_SIDEBAR = "HIDE_RIGHT_SIDEBAR";

// Preloader
export const CHANGE_PRELOADER = "CHANGE_PRELOADER";
export const types = {
  FETCH_ORDERS: "FETCH_ORDERS",
  GET_ORDERS_BY_PAGINATION: "GET_ORDERS_BY_PAGINATION",
  GET_ORDERS_BY_PAGINATION_SUCCESS: "GET_ORDERS_BY_PAGINATION_SUCCESS",
  FETCH_ORDERS_SUCCESS: "FETCH_ORDERS_SUCCESS",
  FETCH_ORDER_DELIVERED: "FETCH_ORDER_DELIVERED",
  FETCH_FILTER_ORDERS: "FETCH_FILTER_ORDERS",
  FETCH_FILTER_ORDERS_SUCCESS: "FETCH_FILTER_ORDERS_SUCCESS",
  FETCH_ORDER: "FETCH_ORDER",
  FETCH_ORDER_SUCCESS: "FETCH_ORDER_SUCCESS",
  FETCH_PROD_NAMES: "FETCH_PROD_NAMES",
  FETCH_PRODUCT_NAMES_BY_PRODUCT_ID_DEALER_CODE:
    "FETCH_PRODUCT_NAMES_BY_PRODUCT_ID_DEALER_CODE",
  FETCH_PROD_NAMES_SUCCESS: "FETCH_PROD_NAMES_SUCCESS",

  FETCH_AVAIL_ORDERS: "FETCH_AVAIL_ORDERS",
  FETCH_AVAIL_ORDERS_SUCCESS: "FETCH_AVAIL_ORDERS_SUCCESS",
  CREATE_ORDER: "CREATE_ORDER",
  CREATE_ORDER_SUCCESS: "CREATE_ORDER_SUCCESS",
  UPLOAD_ORDER: "UPLOAD_ORDER",
  UPLOAD_ORDER_SUCCESS: "UPLOAD_ORDER_SUCCESS",
  // FETCH_POSTS: 'FETCH_POSTS',
  // FETCH_POSTS_SUCCESS: 'FETCH_POSTS_SUCCESS',
  // CREATE_POST: 'CREATE_POST',
  // CREATE_POST_SUCCESS: 'CREATE_POST_SUCCESS',
  DELETE_ORDER: "DELETE_ORDERS",
  DELETE_ORDER_SUCCESS: "DELETE_ORDERS_SUCCESS",
  GET_ORDERS_BY_EXTERNAL_ID: "GET_ORDERS_BY_EXTERNAL_ID",
  GET_ORDERS_BY_EXTERNAL_ID_SUCCESS: "GET_ORDERS_BY_EXTERNAL_ID_SUCCESS",
  CONFIRM_ORDER: "CONFIRM_ORDER",
  CONFIRM_ORDER_SUCCESS: "CONFIRM_ORDER_SUCCESS",
  GET_CONFIRM_ORDERS: "GET_CONFIRM_ORDERS",
  GET_CONFIRM_ORDERS_SUCCESS: "GET_CONFIRM_ORDERS_SUCCESS",
  GET_ORDERS_BY_ID: "GET_ORDERS_BY_ID",
  GET_ORDERS_BY_ID_SUCCESS: "GET_ORDERS_BY_ID_SUCCESS",
};
//   export
// export const FETCH_ORDERS = "FETCH_ORDERS";
