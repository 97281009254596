import React, { Component } from "react";
import {
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import { Row, Col, FormGroup, Label, Button } from "reactstrap";
import Select from "react-select";

export default class ModalTransporter extends Component {
  render() {
    return (
      <React.Fragment>
        <MDBContainer>
          <MDBModal
            isOpen={this.props.isOpen}
            toggle={() => this.props.toggle()}
            centered
          >
            <MDBModalHeader toggle={this.toggle}>Transporters</MDBModalHeader>
            <MDBModalBody>
              <Row>
                <Col lg="6">
                  <FormGroup className="select2-container">
                    <Label className="control-label ">Transporters</Label>
                    <Select
                      value={this.props.transporter}
                      isMulti={false}
                      onChange={(e) => {
                        this.props.onChange({
                          target: {
                            name: "transporter",
                            value: e,
                          },
                        });
                      }}
                      options={this.props.options}
                      classNamePrefix="select2-selection"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </MDBModalBody>
            <MDBModalFooter>
              <Button color="secondary" onClick={() => this.props.toggle()}>
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={this.props.submit}
                disabled={this.props.disabled}
              >
                Submit
              </Button>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      </React.Fragment>
    );
  }
}
