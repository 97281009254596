export const REGISTER_USER = "register_user";
export const REGISTER_USER_SUCCESSFUL = "register_user_successfull";
export const REGISTER_USER_FAILED = "register_user_failed";
export const CREATE_USER = "CREATE_USER";
export const CREATE_USER_SUCCESSFUL = "CREATE_USER_SUCCESSFUL";
export const LOAD_USERS = "LOAD_USERS";
export const LOAD_USERS_SUCCESSFUL = "LOAD_USERS_SUCCESSFUL";
export const LOAD_USERS_EXCEL = "LOAD_USERS_EXCEL";
export const LOAD_USERS_SUCCESSFUL_EXCEL = "LOAD_USERS_SUCCESSFUL_EXCEL";
export const LOAD_FILTER_USERS = "LOAD_FILTER_USERS";
export const LOAD_FILTER_USERS_SUCCESSFUL = "LOAD_FILTER_USERS_SUCCESSFUL";
export const UPDATE_USER = "UPDATE_USER";
export const USER_DETAILS = "USER_DETAILS";
export const USER_DETAILS_SUCCESSFUL = "USER_DETAILS_SUCCESSFUL";
export const CREATE_USERS_VIA_EXCEL ="CREATE_USERS_VIA_EXCEL";
export const CREATE_USERS_VIA_EXCEL_SUCCESS="CREATE_USERS_VIA_EXCEL_SUCCESS";
