let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  AdminHeaderData = {
    columns: [
      {
        label: userLang?.invoice?.INVOICE_ID,
        field: "invoiceID",
        sort: "asc",
        width: 3,
      },
      {
        label: userLang?.common?.TRANSPORTER,
        field: "transporterName",
        sort: "asc",
        width: 4,
      },
      {
        label: userLang?.invoice?.DATE,
        field: "date",
        sort: "asc",
        width: 3,
      },
      {
        label: userLang?.invoice?.STATE,
        field: "state",
        sort: "asc",
        width: 2,
      },
      // {
      //   label: 'View Details',
      //   field: 'viewDetails',
      //   sort: 'asc',
      //   width: 3,
      // },
      {
        label: userLang?.common?.STATUS,
        field: "status",
        sort: "asc",
        width: 3,
      },
      {
        label: userLang?.invoice?.TOTAL_COST,
        field: "total",
        sort: "asc",
        width: 3,
      },

      // {
      //   label: 'Download Documents',
      //   field: 'downloads',
      //   sort: 'asc',
      //   width: 2,
      // },
      {
        label: userLang?.invoice?.COMMENTS_INVOICE,
        field: "comments",
        sort: "asc",
        width: 4,
      },
      {
        label: userLang?.common?.ACTIONS,
        field: "actions",
        sort: "asc",
        width: 4,
      },
    ],
  };
  AdminHeaderData1 = {
    columns: [
      {
        label: userLang?.invoice?.INVOICE_ID,
        field: "invoiceID",
        sort: "asc",
        width: 3,
      },
      {
        label: userLang?.common?.TRANSPORTER,
        field: "transporterName",
        sort: "asc",
        width: 4,
      },
      {
        label: userLang?.invoice?.DATE,
        field: "date",
        sort: "asc",
        width: 3,
      },
      {
        label: userLang?.invoice?.STATE,
        field: "state",
        sort: "asc",
        width: 2,
      },
      // {
      //   label: 'View Details',
      //   field: 'viewDetails',
      //   sort: 'asc',
      //   width: 3,
      // },
      {
        label: userLang?.common?.STATUS,
        field: "status",
        sort: "asc",
        width: 3,
      },
      {
        label: userLang?.invoice?.TOTAL_COST,
        field: "total",
        sort: "asc",
        width: 3,
      },

      // {
      //   label: 'Download Documents',
      //   field: 'downloads',
      //   sort: 'asc',
      //   width: 2,
      // },
      {
        label: userLang?.invoice?.COMMENTS_INVOICE,
        field: "comments",
        sort: "asc",
        width: 4,
      },
      {
        label: userLang?.common?.ACTIONS,
        field: "actions",
        sort: "asc",
        width: 4,
      },
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}

export var AdminHeaderData = {
  columns: [
    {
      label: userLang?.invoice?.INVOICE_ID,
      field: "invoiceID",
      sort: "asc",
      width: 3,
    },
    {
      label: userLang?.common?.TRANSPORTER,
      field: "transporterName",
      sort: "asc",
      width: 4,
    },
    {
      label: userLang?.invoice?.DATE,
      field: "date",
      sort: "asc",
      width: 3,
    },
    {
      label: userLang?.invoice?.STATE,
      field: "state",
      sort: "asc",
      width: 2,
    },
    // {
    //   label: 'View Details',
    //   field: 'viewDetails',
    //   sort: 'asc',
    //   width: 3,
    // },
    {
      label: userLang?.common?.STATUS,
      field: "status",
      sort: "asc",
      width: 3,
    },
    {
      label: userLang?.invoice?.TOTAL_COST,
      field: "total",
      sort: "asc",
      width: 3,
    },

    // {
    //   label: 'Download Documents',
    //   field: 'downloads',
    //   sort: 'asc',
    //   width: 2,
    // },
    {
      label: userLang?.invoice?.COMMENTS_INVOICE,
      field: "comments",
      sort: "asc",
      width: 4,
    },
    {
      label: userLang?.common?.ACTIONS,
      field: "actions",
      sort: "asc",
      width: 4,
    },
  ],
};
export var AdminHeaderData1 = {
  columns: [
    {
      label: userLang?.invoice?.INVOICE_ID,
      field: "invoiceID",
      sort: "asc",
      width: 3,
    },
    {
      label: userLang?.common?.TRANSPORTER,
      field: "transporterName",
      sort: "asc",
      width: 4,
    },
    {
      label: userLang?.invoice?.DATE,
      field: "date",
      sort: "asc",
      width: 3,
    },
    {
      label: userLang?.invoice?.STATE,
      field: "state",
      sort: "asc",
      width: 2,
    },
    // {
    //   label: 'View Details',
    //   field: 'viewDetails',
    //   sort: 'asc',
    //   width: 3,
    // },
    {
      label: userLang?.common?.STATUS,
      field: "status",
      sort: "asc",
      width: 3,
    },
    {
      label: userLang?.invoice?.TOTAL_COST,
      field: "total",
      sort: "asc",
      width: 3,
    },

    // {
    //   label: 'Download Documents',
    //   field: 'downloads',
    //   sort: 'asc',
    //   width: 2,
    // },
    {
      label: userLang?.invoice?.COMMENTS_INVOICE,
      field: "comments",
      sort: "asc",
      width: 4,
    },
    {
      label: userLang?.common?.ACTIONS,
      field: "actions",
      sort: "asc",
      width: 4,
    },
  ],
};
export const searchBar = {
  searchBarData: [
    {
      label: userLang?.invoice?.INVOICE_ID,
      field: "invoiceID",
      sort: "asc",
      width: 3,
    },
    {
      label: userLang?.common?.TRANSPORTER,
      field: "transporterName",
      sort: "asc",
      width: 4,
    },
    {
      label: userLang?.invoice?.DATE,
      field: "date",
      sort: "asc",
      width: 3,
    },
    {
      label: userLang?.invoice?.STATE,
      field: "state",
      sort: "asc",
      width: 2,
    },

    {
      label: userLang?.common?.STATUS,
      field: "status",
      sort: "asc",
      width: 3,
    },
    {
      label: userLang?.invoice?.TOTAL_COST,
      field: "total",
      sort: "asc",
      width: 3,
    },

    {
      label: userLang?.invoice?.COMMENTS_INVOICE,
      field: "comments",
      sort: "asc",
      width: 4,
    },
  ],
};
