import React from 'react';
import { Button } from "reactstrap";
import GetAppIcon from '@material-ui/icons/GetApp';
import AtFoodPdf from "../../assets/docs/Manual.pdf";
import Baxter_Sales from "../../assets/docs/Baxter_Sales.pdf";
import Baxter_Customer from "../../assets/docs/Baxter_Customer.pdf";
class HelpButton extends React.Component {
    downloadFile = () => {
        // Path to the document file
        const getUser = JSON.parse(window.localStorage.getItem("authUser"))
        const userType = getUser?.data?.role?.label;
        const documentPath = userType === 'sales'? Baxter_Sales : userType === 'customer'? Baxter_Customer : AtFoodPdf;

        // Create an invisible anchor element
        const link = document.createElement('a');
        link.href = documentPath;
        link.target = '_blank'; // Open in a new tab or window

        // Trigger the download
        link.download = 'document.pdf'; // Set the downloaded file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    render() {
        const getUser = JSON.parse(window.localStorage.getItem("authUser"))
        const userType = getUser?.data?.role?.label;
        const documentPath = userType === 'sales'? Baxter_Sales : userType === 'customer'? Baxter_Customer : AtFoodPdf;
        let userLang = window.localStorage.getItem("userLangDetail") && JSON.parse(window.localStorage.getItem("userLangDetail"));
        return (
            <Button onClick={this.downloadFile} className='baxHelpStyle darkCardStyle' size="xs">
                <div className="">
                    <a
                        href={documentPath}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <span className="baxHelpSpan light__span">
                            {userLang.common?.HELP ?? "HELP"}
                        </span>
                        <GetAppIcon fontSize='xs' className="downloadIcon" />
                    </a>
                </div>
            </Button>
        );
    }
}

export default HelpButton;
