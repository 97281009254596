import { Grid } from "@material-ui/core";
import React from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import VerticalMenuBar from "../components/VerticalMenuBar";
import {changeSwitchTheme} from "../store/layout/actions";
import HeaderNew from "../components/HorizontalLayout/HeaderNew";
import { withRouter } from "react-router-dom";

// let policyProps = {};
// const accessPolicies = () => {
//   if (policyProps === undefined || policyProps[0] === "All policies")
//     return true;
//   // if (window.location.pathname === "/pages-401") return true;
//   let policies = [
//     "/",
//     "/operationsDashboard",
//     "/logout",
//     "/login",
//     "/pages-401",
//   ];
//   policyProps &&
//     policyProps.forEach((element) => {
//       console.log("ELEMENT", element)
//       switch (element.entity) {
//         case "trip":
//           if (element.create) {
//             policies.push("/add-trip");
//             policies.push("/upload-trip");
//           }
//           if (element.read) {
//             policies.push("/trips");
//           }
//           if (element.edit) {
//             policies.push("/edit-trip");
//           }
//           break;
//         case "driver":
//           if (element.create) {
//             policies.push("/add-driver");
//             policies.push("/upload-driver");
//           }
//           if (element.read) {
//             policies.push("/drivers");
//           }
//           if (element.edit) {
//             policies.push("/edit-driver");
//           }
//           break;
//         case "vehicle":
//           if (element.create) {
//             policies.push("/add-vehicle");
//             policies.push("/upload-vehicle");
//           }
//           if (element.read) {
//             policies.push("/vehicles");
//           }
//           if (element.edit) {
//             policies.push("/edit-vehicle");
//           }
//           break;
//         case "dealer":
//           if (element.create) {
//             policies.push("/add-dealer");
//             policies.push("/upload-dealer");
//           }
//           if (element.read) {
//             policies.push("/dealers");
//           }
//           if (element.edit) {
//             policies.push("/edit-dealer");
//           }
//           break;
//         case "hop":
//           if (element.create) {
//             policies.push("/add-hop");
//             policies.push("/upload-hop");
//           }
//           if (element.read) {
//             policies.push("/hops");
//           }
//           if (element.edit) {
//             policies.push("/edit-hop");
//           }
//           break;
//         case "order":
//           if (element.create) {
//             policies.push("/add-order");
//             policies.push("/upload-order");
//           }
//           if (element.read) {
//             policies.push("/orders");
//           }
//           if (element.edit) {
//             policies.push("/edit-order");
//           }
//           break;
//         default:
//           return false;
//       }
//     });
//   if (window.location.pathname.includes("/edit-")) {
//     if (policies.includes(window.location.pathname.split("-", 2).join("-"))) {
//       return true;
//     } else return false;
//   }
//   if (policies.includes(window.location.pathname)) {
//     return true;
//   } else return false;
// };

const AppRoute = ({
  component: Component,
  layout: Layout,
  hasAccess,
  isAuthProtected,
  ...rest
}) => {
  const [mini, setMini] = useState(true);
  const [online, setOnline] = useState(localStorage.getItem("online"));
  const [offline, setOffline] = useState("off");

  useEffect(() => {
    localStorage.setItem("online", "on");
    if (!navigator.onLine) {
      localStorage.setItem("online", "off");
      setOffline("on");
    }
    if (navigator.onLine) {
      setOffline("off");
      if (online === "off" && offline === "off") {
        var timeId = setTimeout(() => {
          setOnline("on");
        }, 4000);
      }
    }

    return () => {
      clearTimeout(timeId);
    };
  }, [online, offline]);
  const menuBarIn = () => {
    if (mini === true) {
      // console.log("opening sidebar");
      document.getElementById("contentCard").style.marginLeft = "225px";
      setMini(false);
    }
  };
  const menuBarOut = () => {
    if (mini === false) {
      // console.log("closing sidebar");
      document.getElementById("contentCard").style.marginLeft = "82px";

      setMini(true);
    }
  };
 
  return (
    <Route
      {...rest}
      render={(props) => {
        let token = localStorage.getItem("authUser");
        let mode = localStorage.getItem('Theme')
        if(process.env.REACT_APP_CLIENT === "3_BAXTER" && window.location.pathname === '/viewOrders'){
          sessionStorage.setItem('viewOrderPath',window.location.href)
        }
        if (isAuthProtected && !localStorage.getItem("authUser")) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
        //  else if (accessPolicies() === false) {
        //   return (
        //     <Redirect
        //       to={{ pathname: "/pages-401", state: { from: props.location } }}
        //     />
        //   );
        // }
        else {
          return (
            <Layout>
              {token ? (
                <Grid container className="darkContain">
                  <Grid item xs={1}>
                    <VerticalMenuBar
                      menuBarIn={menuBarIn}
                      menuBarOut={menuBarOut}
                      mini={mini}
                    />
                  </Grid>
                  <Grid item xs={12} id="contentCard" 
                   style={{backgroundColor: mode === "dark__mode"?"#1B1C31":""}}>
                    <HeaderNew {...props} />
                    {online === "off" && offline === "off" && (
                      <div className="navigatorOnlineDiv">
                        <span className="navigatorOnlineInnerDiv">
                          Back Online
                        </span>
                      </div>
                    )}
                    {offline === "on" && (
                      <div className="navigatorOfflineDiv">
                        <span className="navigatorOfflineInnerDiv">
                          Offline Mode
                        </span>
                      </div>
                    )}
                    <Component {...props} {...rest} />
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Component {...props} {...rest} 
                 />
                </Grid>
              )}
            </Layout>
          );
        }
      }}
    />
  );
};

const mapStateToProps = (state) => {
  // policyProps = state.AccessPolicy.accessPolicies.policies;
  return {
    ...state,
    policies: state.AccessPolicy.accessPolicies.policies,
    switchsTheme: state.Layout?.switchTheme,
  };
};
export default withRouter(connect(mapStateToProps,{changeSwitchTheme})(AppRoute));
