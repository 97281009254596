import { types } from "./actionTypes";
const initialState = {
  getinvoiceListData: null,
  fetchId: "",
};
const invoiceListReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_INVOICELIST_SUCCESS:
      state = {
        ...state,
        getInvoiceListData: action.payload,
        fetchId: "id" + new Date().getTime()
      };
      return state;
    default:
      return state;
  }
};
export default invoiceListReducer;
