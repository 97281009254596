import { types } from "./actionTypes";

// creating action
export const dispatchLeadTimeReport = (filters, searchFilter, callback) => {
  return {
    type: types.DIAPATCH_LEAD_TIME_REPORT,
    filters,
    searchFilter,
    callback,
  };
};

//Action to store excel data 
export const dispatchLeadTimeReportExcel = (filters, searchFilter, callback) => {
  console.log("???",filters,searchFilter,callback)
  return {
    type: types.DIAPATCH_LEAD_TIME_REPORT_EXCEL,
    filters,
    searchFilter,
    callback,
  };
};

export const bifurcationReport = (filters, searchFilter, callback) => {
  return {
    type: types.BIFURCATION_REPORT,
    filters,
    searchFilter,
    callback,
  };
};
export const truckUtilizationReport = (filters, searchFilter, callback) => {
  return {
    type: types.TRUCKUTILIZATION_REPORT,
    filters,
    searchFilter,
    callback,
  };
};

export const bifurcationReportExcel = (filters, searchFilter, callback) => {
  return {
    type: types.BIFURCATION_REPORT_EXCEL,
    filters,
    searchFilter,
    callback,
  };
};
export const truckUtilizationReportExcel = (filters, searchFilter, callback) => {
  return {
    type: types.TRUCKUTILIZATION_REPORT_EXCEL,
    filters,
    searchFilter,
    callback,
  };
};
