import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import SecondaryIndentDetails from "./CommonResources/Indent/SecondaryIndentDetails";
import { createIndent } from "../../store/actions";

class SecondaryIndentForm extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        {/* <IndentLayout /> */}
        <SecondaryIndentDetails {...this.props} />
      </React.Fragment>
    );
  }
}

export default connect(null, {
  createIndent,
})(SecondaryIndentForm);
