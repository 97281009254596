import React, { Component } from 'react'
import ReactApexChart from 'react-apexcharts';

export default class TransportDonutChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            series: [],
            options: {
                tooltip: {
                    shared: false,
                    intersect: true,
                    y: {
                        formatter: (value) => {
                            return value + ' %'
                        },
                    },

                },
                plotOptions: {
                    pie: {
                        // customScale: 0.5,
                        donut: {
                            size: '80%'
                        }
                    }
                },
                labels: [],
                colors: ["#28A34C", "#E53D2C", "#FFFFFF"],
                legend: {
                    show: false,
                    position: "bottom",
                    horizontalAlign: "center",
                    verticalAlign: "middle",
                    floating: false,
                    // fontSize: '2px',
                    offsetX: 0,
                    offsetY: 0,
                },
                dataLabels: {
                    enabled: false,
                },
                responsive: [
                    {
                        breakpoint: 600,
                        options: {
                            chart: {
                                height: "100%",
                            },
                            legend: {
                                show: false,
                            },
                        },
                    },
                ],
            },
        };
    }
    componentDidUpdate = (prevProps) => {
        if (prevProps.tripInformation !== this.props.tripInformation) {
            // console.log(this.props.tripInformation, 'this.props.tripInformation')
            let tripInformation = this.props.tripInformation;
            let total = tripInformation?.truckWeight
            + tripInformation.courierWeight
            var data = {
                truck: Number(((tripInformation?.truckWeight / total) * 100).toFixed(2)  || 0),
                courier: Number(((tripInformation?.courierWeight / total) * 100).toFixed(2)|| 0)
            };
            let truck = data?.truck;
            let courier = data?.courier;
             console.log(truck,courier,"op")

            let Series = [truck, courier];
            let Label = [
                "Trucks Weight",
                "Courier Weight"
                
            ];

            this.setState({ options: { ...this.state.options, labels: Label } });
            this.setState({ series: Series });
        }
    };
    render() {
        return (
            <React.Fragment>
                <ReactApexChart className="cursor-pointer IndentApexchartParent mgt5"
                    options={this.state.options}
                    series={this.state.series}
                    type="donut"
                    height="250"
                />
            </React.Fragment>
        )
    }
}
