import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication Module
import Account from "./auth/register/reducer";
import Login from "./auth/login/reducer";
import Forget from "./auth/forgetpwd/reducer";
import Trip from "./trip/reducer/index";
import SecondaryTrip from "./secondaryTrip/reducer/index";
import TripCountStatus from "./dashboard/reducer/index";
import Driver from "./driver/reducer/index";
import Order from "./order/reducer/index";
import Vehicle from "./vehicle/reducer/index";
import Dealer from "./dealer/reducer/index";
import Employee from "./employee/reducer/index";
import Location from "./hop/reducer/index";
import AccessPolicy from "./accessPolicy/reducer/index";
import Indent from "./indent/reducer/index";
import SecondaryHop from "./secondaryIndentPlanning/reducer/index";

import Transporter from "./transporter/reducer/index";
import VehicleType from "./vehicleType/reducer/index";
import Route from "./route/reducer/index";
import Common from "./common/reducer/index";
import Billing from "./billing/reducer/index";
import Reports from "./Reports/reducer/index";
import LoadOptimization from "./LoadOptimization/reducer/index";
import VehiclePlacement from "./vehiclePlacement/reducer/index";
import OnTimeDeliveryReport from "./OntimeDeliveryReports/reducer/index";
import VehicleDetailPlacement from "./vehiclePlacementDetail/reducer";

// baxter
import customerReducer from "./baxter_customer/reducer";
import locationReducer from "./baxter_location/reducer";
import productReducer from "./baxter_product/reducer";
import orderListReducer from "./baxter-order/reducer";
import invoiceListReducer from "./baxter-orderInvoice/reducer";
import weightOfInvoiceItemsReducer from "./baxter-loadOptimization/reducer";
import getBaxTripListReducer from "./baxter-trip/reducer";
import operationalDashboardReducer from "./baxter-dashboard/reducer";
import dispatchLeadTimeReportReducer from "./baxter-report/reducer";
import FreightsMaster from "./freightsMaster/reducer";
import ShipmentMaster from "./shipmentMaster/reducer";
import logbookReducer from "./baxter_logbook/reducer";
import zoneToStateMapReducer from "./baxter_zoneToStateMap/reducer";
const rootReducer = combineReducers({
  // public
  Layout,

  // Authentication
  Account,
  Login,
  Forget,
  Trip,
  SecondaryTrip,
  TripCountStatus,
  Driver,
  Order,
  Vehicle,
  Dealer,
  Employee,
  Location,
  AccessPolicy,
  Indent,
  SecondaryIndent: SecondaryHop,
  Transporter,
  VehicleType,
  Route,
  Common,
  Billing,
  Reports,
  LoadOptimization,
  VehiclePlacement,
  OnTimeDeliveryReport,
  VehicleDetailPlacement,
  FreightsMaster,
  ShipmentMaster,
  // baxter
  locationReducer,
  customerReducer,
  productReducer,
  orderListReducer,
  invoiceListReducer,
  weightOfInvoiceItemsReducer,
  getBaxTripListReducer,
  operationalDashboardReducer,
  dispatchLeadTimeReportReducer,
  logbookReducer,
  zoneToStateMapReducer,
});

export default rootReducer;
