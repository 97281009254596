let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(localStorage.getItem("userLangDetail"));

  HeadersData = {
    columns: [
      {
        label: "Indent Id",
        field: "indentId",
        sort: "asc",
        width: 2,
      },
      {
        label: "Source Code",
        field: "sourceCode",
        sort: "asc",
        width: 3,
      },
      {
        label: "Source Name",
        field: "sourceName",
        sort: "asc",
        width: 3,
      },
      {
        label: "Customer Number",
        field: "customerNumber",
        sort: "asc",
        width: 2,
      },
      {
        label: "Customer Name",
        field: "customerName",
        sort: "asc",
        width: 6,
      },
      {
        label: "Ship To Code",
        field: "shipToCode",
        sort: "asc",
        width: 2,
      },
      {
        label: "Vehicle Type",
        field: "vehicleType",
        sort: "asc",
        width: 2,
      },
      {
        label: "Transporter Name",
        field: "transporterName",
        sort: "asc",
        width: 4,
      },
      {
        label: "Indent Creation Date",
        field: "indentCreationDate",
        sort: "asc",
        width: 4,
      },

      {
        label: "Transporter Acceptance Expiry Date Time",
        field: "transporterAcceptanceExpiryDateTime",
        sort: "asc",
        width: 5,
      },

      {
        label: "Transporters Actual Accept Rej Date",
        field: "trransportersActualAcceptRejDateTime",
        sort: "asc",
        width: 5,
      },
      {
        label: "Transporters Acceptance Event Action taken",
        field: "transportersAcceptanceEventActiontaken",
        sort: "asc",
        width: 5,
      },
      {
        label: "Vehicle Reporting Time At Factory",
        field: "vehicleReportingTimeAtFactory",
        sort: "asc",
        width: 5,
      },
      {
        label: "Trip ID",
        field: "tripID",
        sort: "asc",
        width: 2,
      },
      {
        label: "Trip Creation Date",
        field: "tripCreationDate",
        sort: "asc",
        width: 4,
      },
      {
        label: "Arrival Date At Source",
        field: "markasArrivalDateAtSource",
        sort: "asc",
        width: 4,
      },
      {
        label: "Arrival Date At Source",
        field: "markasArrivalDateAtSource",
        sort: "asc",
        width: 4,
      },
      {
        label: "Quality Event Action Date",
        field: "qualityEventActionDate",
        sort: "asc",
        width: 4,
      },
      {
        label: "Quality Event Action Taken",
        field: "qualityEventActiontaken",
        sort: "asc",
        width: 4,
      },
      {
        label: "Trip Shipment Verification Start Date From Source",
        field: "tripShipmentVerificationStartdateTimefromsource",
        sort: "asc",
        width: 5,
      },
      {
        label: "Arrival At Customer",
        field: "markasArrivalAtCustomer",
        sort: "asc",
        width: 3,
      },
      {
        label: "EPOD Completed Date",
        field: "EPODcompleteddate",
        sort: "asc",
        width: 3,
      },

      {
        label: "Damage Count",
        field: "damageCount",
        sort: "asc",
        width: 3,
      },
      {
        label: "Shortage Count",
        field: "shortageCount",
        sort: "asc",
        width: 3,
      },
      {
        label: "Damage and Shortage Comment",
        field: "damageShortageComment",
        sort: "asc",
        width: 3,
      },
      {
        label: "Shipment Number",
        field: "shipmentNumbersText",
        sort: "asc",
        width: 3,
      },
      {
        label: "LR Number",
        field: "lrNumbers",
        sort: "asc",
        width: 3,
      },
      {
        label: "CFC",
        field: "cfc",
        sort: "asc",
        width: 3,
      },
      {
        label: "Quality Check Comment",
        field: "qualityCheckComment",
        sort: "asc",
        width: 3,
      },
      {
        label: "Trip Status",
        field: "tripStatus",
        sort: "asc",
        width: 3,
      },
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var HeadersData = {
  columns: [
    {
      label: "Indent Id",
      field: "indentId",
      sort: "asc",
      width: 2,
    },
    {
      label: "Source Code",
      field: "sourceCode",
      sort: "asc",
      width: 3,
    },
    {
      label: "Source Name",
      field: "sourceName",
      sort: "asc",
      width: 3,
    },
    {
      label: "Customer Number",
      field: "customerNumber",
      sort: "asc",
      width: 2,
    },
    {
      label: "Customer Name",
      field: "customerName",
      sort: "asc",
      width: 6,
    },
    {
      label: "Ship To Code",
      field: "shipToCode",
      sort: "asc",
      width: 2,
    },
    {
      label: "Vehicle Type",
      field: "vehicleType",
      sort: "asc",
      width: 3,
    },
    {
      label: "Transporter Name",
      field: "transporterName",
      sort: "asc",
      width: 4,
    },
    {
      label: "Indent Creation Date",
      field: "indentCreationDate",
      sort: "asc",
      width: 4,
    },

    {
      label: "Transporter Acceptance Expiry Date Time",
      field: "transporterAcceptanceExpiryDateTime",
      sort: "asc",
      width: 5,
    },

    {
      label: "Transporters Actual Accept Rej Date",
      field: "trransportersActualAcceptRejDateTime",
      sort: "asc",
      width: 5,
    },
    {
      label: "Transporters Acceptance Event Action taken",
      field: "transportersAcceptanceEventActiontaken",
      sort: "asc",
      width: 5,
    },
    {
      label: "Vehicle Reporting Time At Factory",
      field: "vehicleReportingTimeAtFactory",
      sort: "asc",
      width: 5,
    },
    {
      label: "Trip ID",
      field: "tripID",
      sort: "asc",
      width: 2,
    },
    {
      label: "Trip Creation Date",
      field: "tripCreationDate",
      sort: "asc",
      width: 4,
    },
    {
      label: "Arrival Date At Source",
      field: "markasArrivalDateAtSource",
      sort: "asc",
      width: 4,
    },

    {
      label: "Quality Event Action Date",
      field: "qualityEventActionDate",
      sort: "asc",
      width: 4,
    },

    {
      label: "Shipment Verification Start Date From Source",
      field: "tripShipmentVerificationStartdateTimefromsource",
      sort: "asc",
      width: 5,
    },
    {
      label: "Arrival At Customer",
      field: "markasArrivalAtCustomer",
      sort: "asc",
      width: 3,
    },
    {
      label: "EPOD Completed Date",
      field: "EPODcompleteddate",
      sort: "asc",
      width: 4,
    },
    {
      label: "Damage Count",
      field: "damageCount",
      sort: "asc",
      width: 3,
    },
    {
      label: "Shortage Count",
      field: "shortageCount",
      sort: "asc",
      width: 4,
    },
    {
      label: "Damage and Shortage Comment",
      field: "damageShortageComment",
      sort: "asc",
      width: 7,
    },
    {
      label: "Shipment Number",
      field: "shipmentNumbersText",
      sort: "asc",
      width: 3,
    },
    {
      label: "LR Number",
      field: "lrNumbers",
      sort: "asc",
      width: 3,
    },
    {
      label: "CFC",
      field: "cfc",
      sort: "asc",
      width: 3,
    },
    {
      label: "Quality Check Comment",
      field: "qualityCheckComment",
      sort: "asc",
      width: 5,
    },
    {
      label: "Trip Status",
      field: "tripStatus",
      sort: "asc",
      width: 5,
    },
  ],
};

export const searchBar = {
  searchBarData: [],
};
