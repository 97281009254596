import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  Form,
} from "reactstrap";
export default class AddZonetoStateMap extends Component {
  render() {
    const { errors } = this.props;
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    var darkMode = mode === "dark__mode" ? true : false;
    return (
      <Modal
        isOpen={this.props.modal}
        // autoFocus={true}
        centered={true}
        toggle={this.props.toggle}
        className="addZoneToState"
      //  size={"xl"}
      >
        <div className="modal-content p-1">
          <ModalHeader>
            <div className="Heading">
              <h4 className="fontRoboto light__span">
                {this.props.viewById?.viewMode === "edit" ? "Edit" : "Add"} Zone
                To State
              </h4>
              <svg
                onClick={this.props.toggle}
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.9092 1.82141L1.90918 13.8214"
                  stroke="#2800FC"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.90918 1.82141L13.9092 13.8214"
                  stroke="#2800FC"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </ModalHeader>

          <ModalBody>
            <Form>
              <FormGroup>
                <Label
                  className={`required-field labelStyle mt-2 ${this.props.mode ? "light__span" : ""
                    }`}
                >
                  {" "}
                  <span>State</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="State"
                  value={this.props.form?.state}
                  onChange={(e) =>
                    this.props.onChangeInput("state", e.target.value)
                  }
                />
                {errors?.state && <div className="error">{errors?.state} </div>}
              </FormGroup>

              <FormGroup>
                <Label
                  className={`required-field labelStyle mt-2 ${this.props.mode ? "light__span" : ""
                    }`}
                >
                  {" "}
                  <span>Zone</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Zone"
                  value={this.props.form?.zone}
                  onChange={(e) =>
                    this.props.onChangeInput("zone", e.target.value)
                  }
                />
                {errors?.zone && <div className="error">{errors?.zone} </div>}
              </FormGroup>

              <FormGroup>
                <Label
                  className={`required-field labelStyle mt-2 ${this.props.mode ? "light__span" : ""
                    }`}
                >
                  {" "}
                  <span>Cost/kg</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Cost"
                  value={this.props.form?.cost}
                  onChange={(e) =>
                    this.props.onChangeInput("cost", e.target.value)
                  }
                />
                {errors?.cost && <div className="error">{errors?.cost} </div>}
              </FormGroup>

              <FormGroup>
                <Label
                  className={`required-field labelStyle mt-2 ${this.props.mode ? "light__span" : ""
                    }`}
                >
                  {" "}
                  <span>Warehouse</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Warehouse"
                  value={this.props.form?.businessUnit}
                  onChange={(e) =>
                    this.props.onChangeInput("businessUnit", e.target.value)
                  }
                />
                {errors?.businessUnit && <div className="error">{errors?.businessUnit} </div>}
              </FormGroup>
              <FormGroup>
                <Label
                  className={`required-field labelStyle mt-2 ${this.props.mode ? "light__span" : ""
                    }`}
                >
                  {" "}
                  <span>Courier</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Courier"
                  value={this.props.form?.courier}
                  onChange={(e) =>
                    this.props.onChangeInput("courier", e.target.value)
                  }
                />
                {errors?.courier && <div className="error">{errors?.courier} </div>}
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="success"
              onClick={() => this.props.reset()}
            >
              Reset
            </Button>
            <Button
              type="button"
              color="primary"
              onClick={this.props.handleSubmit}
            >
              {this.props.viewById?.viewMode === "edit" ? "Update" : "Add"}
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    );
  }
}
