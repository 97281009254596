export const ENGLISH = {
  dashboard: {
    LOGBOOK: "Logbook",
    DASHBOARD: "Dashboard",
    OPERATIONS: "Operations",
    INVOICE: "Invoice",
    TOTAL: "Total",
    PLANNED: "Planned",
    IN_TRANSIT: "In Transit",
    COMPLETED: "Completed",
    OTHERS: "Others",
    PENDING: "Pending",
    TRIPS_DELAYED: "Trips Delayed",
    SHIPMENT_VERIFIED: "Shipment Verified",
    UNTRACKED_TRIPS: "Untracked Trips",
    SHIPMENT: "Shipment",
    OPERATIONAL_TAT: "Operational TAT",
    TRIP_STATUS: "Trip Status",
    DAMAGED_SHIPMENT: "Damaged Shipments",
    AVERAGE_LOADING_TIME: "Average Loading Time",
    AVERAGE_UNLOADING_TIME: "Average Unloading Time",
    QUALITY_CHECK_TIME_AVERAGE: "Quality Check Time Average",
    SHORTAGE_SHIPMENT: "Shortage Shipment",
    INDENT_DETAILS: "Indent details",
    INDENT_ACCEPTANCE: "Indent Acceptance",
    TOTAL_INDENTS: "Total Indents",
    INDENT_REJECTION: "Indent Rejection",
    EXPIRY: "Expiry",
    CANCELLED: "Cancelled",
    SHIPMENT_VERIFIED: "Shipment Verified",
    ARRIVED_AT_SOURCE: "Arrived at Source",
    QUALITY_CHECK_COMPLETED: "Quality Check Completed",
    ARRIVED_AT_VIA_POINT: "Arrived at Via point",
    LOADING_IN_PROGRESS: "Loading in Progress",
    PAID_INVOICES: "Paid Invoices",
    AMOUNT: "Amount",
    UNPAID_INVOICE: "Unpaid Invoice",
    SUBMITTED_INVOICE_COUNT: "Submitted Invoice Count",
    SUBMITED_INVOICE_PAYMENT: "Submitted Invoice Payment",
    PENDING_APPROVAL: "Pending Approval Count",
    PENDING_APPROVAL_TOTAL: "Pending Approval Total",
    PER_SHIPMENT: "Per Shipment",
    PER_TRIP: "Per Trip",
    FREIGHT_COST: "Freight Cost",
    JANUARY: "January",
    FEBRUARY: "February",
    MARCH: "March",
    APRIL: "April",
    MAY: "May",
    JUNE: "June",
    JULY: "July",
    AUGUST: "August",
    SEPTEMBER: "September",
    OCTOBER: "October",
    NOVEMBER: "November",
    DECEMBER: "December",
    ACCEPTANCE_RATE: "Acceptance Rate",
    REJECTION_RATE: "Rejection Rate",
    LATE_DELIVERY: "Late Delivery",
    ON_TIME_DELIVERY: "On Time Delivery",
    SHORTAGE_PERCENTAGE: "Shortage Percentage ",
    DAMAGE_PERCENTAGE: "Damage Percentage ",
    BUSINESS_BREAKUP: "Business Breakup",
    ACCEPTANCE: "Acceptance",
    REJECTION: "Rejection",
    RATE: "Rate",
    LATE: "Late",
    ON_TIME: "On time ",
    DELIVERY: "Delivery",
    SHORTAGE: "Shortage",
    DAMAGE: "Damage",
    CARBON_FOOT_PRINT: "Carbon FootPrint",
    VEHICLE_PLACEMENT_GRAPH: "Vehicle Placement",
    SHIPMENT_SHORTAGE_GRAPH: "Shipment Shortage",
    SHIPMENT_DAMAGE_GRAPH: "Shipment Damage",
    COST_REVENUE_RATIO: "Cost to Revenue Ratio",
    MONTHLY: "Monthly",
    TRUCK_UTILIZATION: "Truck Utilization",
  },
  planning: {
    CUSTOMER_NUMBER: "Customer Number",
    SHIPTOCODE: "Ship to code",
    PLANNING: "Planning",
    PLANNING_CAPS: "Planning",
    INDENT_PLANNING: "Indent Planning",
    ADD_INDENT: "Add Indent",
    LOAD_OPTIMIZATION: "Load Optimization",
    INDENT_ID: "Indent Id",
    REJECTED: "Rejected",
    VEHICLE_TYPE: "Vehicle Type",
    VEHICLE_REPORTING_DATE: "Vehicle Reporting Date",
    TIME_AT_FACTORY: "Time At Factory",
    DEPOT: "Depot",
    TRANSPORTER: "Transporter",
    TRANSPORTER_ACCEPTANCE_EXPIRT_DATE:
      "Transporter Acceptance Expiry Date/Time",
    COMMENTS: "Comment",
    PICKUP_LOCATION: "Pick up Location",
    CUSTOMERS: "Customers",
    CANCEL_INDENT: "Cancel Indent",
    PROCESSING: "Processing.",
    SOURCE_IS_REQUIRED: "Source is required",
    DESTINATION_IS_REQUIRED: "Destination is required",
    VEHICLE_TYPE_IS_REQUIRED: "Vehicle Type is required",
    ROUTE_IS_REQUIRED: "Route is required",
    TRANSPORTER_IS_REQUIRED: "Transporter is required",
    CUSTOMER_IS_REQUIRED: "Customer is required",
    REPORT_DATE: "Report Date/Time Must be After the Expiry Date/Time",
    EXPIRY_DATE: "Expiry Date/Time Must be Before the Report Date/Time",
    START_DATE_IS_REQUIRED: "Start Date is required",
    EXPIRY_DATE_IS_REQUIRED: "Expiry Date is required",
    INDENT_CANCEL_WARNING: " Are you sure you want to cancel the Indent ?",
    CANCEL_REASONS: "Please provide reason for cancellation",
    REASON: "Reason",
    REJECTION_HISTORY: "Rejection History",
    CREATED_BY: "Created By",
    UPDATED_BY: "Updated By",
    VEHICLE_REG: "Vehicle Reg",
    REJECT_INDENT: "Reject Indent",
    REJECT_INDENT_WARNING: "Rejection charges will be implied. Please confirm.",
    REJECT_INDENT_COST: "Cost",
    REJECT_INDENT_ROUTE: "Route",
    REJECT_INDENT_VEHICLEUNAVAILABILITY: "Vehicle Unavailability",
    REJECT_INDENT_REMARK: "Remark",
  },
  masters: {
    MASTERS: "Masters",
    NAME: "Name",
    PHONE: "Phone",
    USERS: "Users",
    LIC_NO: "LicNo",
    CONSENT_STATUS: "Consent Status",
    OWNER_NAME: "Owner Name",
    REG_NO: "Vehicle Reg No",
    USERNAME: "User Name",
    CONTACT: "Contact",
    ROLE: "Role",
    ROLES: "Roles",
    EMAIL: "Email",
    HOP: "Hop",
    HOPS: "Hops",
    FREIGHT: "Freight",
    DESCRIPTION: "Descriptions",
    VEHICLES: "Vehicles",
    DRIVERS: "Drivers",
    ADD_DRIVER: "Add Driver",
    ADD_VEHICLE: "Add Vehicle",
    ADD_STATE_ZONE: "Add State",
    EDIT_USERS: "Edit User",
    HOP_CODE: "Hop Code",
    HOP_NAME: "Hop Name",
    TOO_SHORT: "Too Short!",
    FULL_NAME_IS_REQUIRED: "Full Name is required",
    PHONE_IS_REQUIRED: "Phone is required",
    PHONE_MUST_BE_10: "Phone Number Must be 10 digits",
    PHONE_FORMAT: "Please enter in a given format",
    VEHICLE_REGISTRATION_IS_REQUIRED: "Vehicle Registration No is required",
    VEHICLE_TYPE_IS_REQUIRED: "Vehicle Type is required",
    DRIVER_LICENSE_REQUIRED: "Driver License Number is required",
    VEHICLE_OWNER_IS_REQUIRED: "Vehicle Owner is required",
    VEHICLE_TYPE: "Vehicle Type",
    VEHICLE_NAME: "Vehicle Name",
    VEHICLE_HEIGHT: "Height",
    VEHICLE_WEIGHT: "Weight",
    VEHICLE_LENGTH: "Length",
    VEHICLE_WIDTH: "Width",
    //Heading
    ROUTES: "Routes",
    INDENTS: "Indents",
    SHIPMENTS: "Shipments",
    //shipment master
    SHIPMENT_ID: "Shipment ID",
    TRIP_ID: "Trip ID",
    // routes master
    ROUTE_CODE: "Route Code",
    NAME: "Name",
    SOURCE: "Source",
    DESTINATION: "Destination",
    VIA: "Via",
    // indent master
    INDENT_PLANNING_CODE: "Indent Planning Code",
    TRANSPORTER: "Transporter",
    VALIDITY: "Validity",
    KMS: "Kms",
    LANE_LOT: "Lane Lot",
    LEAD_TIME: "Lead Time",
    LOT_NO: "LotNo",
    TRAGET_INDENTS: "Target Indents",
    ACTUAL_INDENTS: "Actual Indents",
    //BAXTER
    CUSTOMER: "Customer",
    LOCATIONS: "Locations",
    PRODUCTS: "Products",
    OPTIMIZATION: "Optimization",
  },
  trip: {
    TRIP: "Trip",
    TRIPS: "Trips",
    TRIP_HISTORY: "Trip History",
    TRIP_ID: "Trip Id",
    TRIPS_DETAILS: "Trips Details",
    TIME: "Time",
    VEHICLE: "Vehicle",
    DRIVER: "Driver",
    CANCEL_TRIP: "Cancel Trip",
    TRIP_WARNING: "Do you want to cancel trip?",
    TRIP_CANCEL_REASON: "Why do you want to cancle the trip?",
    SHIPMENT_ID: "Shipment Id",
    SHIPMENT_NO: "Shipment No",
    UNLOADED_BY: "Unloaded By",
    ITEM_NO: "Item No",
    LOT_NO: "Lot No",
    QUANTITY: "Quantity",
    ATTACHED_PROOF: "Attached Proof",
    NO_DAMAGE: "No Damaged Proof Attached",
    NO_SHORTAGE: "No Shortage Proof Attached",
    FRONT_BACK: "Front and Back :",
    LR_COPY: "LR Copy",
    DAMAGE_QUANTITY: "Damage Quantity",
    LOOSE_OIL: "Loose Oil",
    SHORTAGE_QUANTITY: "Shortage Quantity",
    DAMAGE_REASONS: "Damage Reasons",
    QUALITY_CHECKS: "Quality Check",
    QUALITY_CHECK_PHOTO: "Quality Check Photo",
    VERIFY_SHIPMENT: "Verify Shipment",
    VERIFY: "Verify",
    TRIGGER_CONSENT: "Trigger Consent",
    SKIP_SHIPMENT: "Skip Shipment",
    PF_SHIPMENT_NUMBER: "SF Number",
    DRIVER_CONSENT:
      "Driver Consent Not Provided. Please Make Sure Driver Consent Is Provided To Enable Vehicle Tracking",
    OPERATOR: "Operator",
    SKIP_SHIPMENT_CAPS: "SKIP SHIPMENT",
    SKIP_SHIPMENT_PlACEHOLDER: "Please enter reasons for skipping the shipment",
    SKIP_SHIPMENT_WARNING:
      "Driver Consent Not Available. Do You Still Want To Continue",
    WARNING: "WARNING",
    CONTINUE: "Continue",
    REASON: "Reason",
    DRIVER_CONSENT_LOCATION: "Driver Consent for location tracking is provided",
    CONSENT_LOCATION:
      "Please Give Your Consent To Check Your Location From Your Service Provider. IVR Dial 7428498646 (other than JIO) And Press 1 Or Reply via SMS With Y To 55502 -Idea, 5114040 -Airtel,9167500066 -Vodafone, 51712028 - JIO",
    NO_LOADING_AT_THIS_LOCATION: "No loading at this location",
    TRIP_DATE: "Trip Date",
    VIEW_ALL_TRIPS: "View All Trips",
    PLEASE_ENTER_FORMAT: "Please enter in a given or other valid format",
    PLEASE_SELECT_TYPE: "Please select type",
    CLICK_NO_SHIPMENT: "Click if no shipment is to be added",
    Q1: "Arrived Vehicle Type Matched With Indent Requirements",
    Q2: "Are The Container Doors Are Getting Closed And Locked Properly",
    Q3: "Are There Any Protruding Nails",
    Q4: "Is The Truck Content Any Residual Content Of Cement, Chemicals, Coal, Cow Dung, Spices Etc..",
    Q5: "Is There Any Objectionable Odour",
    Q6: "Are There Any Good Tarpaulins In Platform",
    Q7: "Are There Any Holes In The Platform",
    Q8: "Is Platform Even And Free From Dust And Dirt",
    Q9: "Are There Any wooden Corners being used",
    Q10: "Are There Vehicle Design Is Perfect",
    Q11: "Is There Fitness Certificate Valid",
    Q12: "Is There Insurance Certificate Valid",
    Q13: "Is There Pollution Certificate Valid",
    Q14: "Is There Vehicle Registration Certificate Valid",
    Q15: "Is There Driver License Valid",
    YES: "Yes",
    NO: "No",
    EDIT_VEHICLE_DRIVER_DETAILS: "Edit Vehicle & Driver Details",
    ADD_VEHICLE_DRIVER_DETAILS: "Add Vehicle & Driver Details",
  },
  invoice: {
    INVOICE_ID: "Invoice Id",
    TMS_TRIP_ID: "TMS TripID",
    DATE: "Date",
    STATE: "State",
    TOTAL_COST: "Total Cost",
    COMMENTS_INVOICE: "Comments",
    INVOICE_STATUS: "Invoice Status",
    INVOICE_LISTS: "Invoice Lists",
    INVOICE_LIST: "Invoice List",
    INVOICE_GENERATION: "Invoice Generation",
    BILL_DETAILS: "Bill Details",
    AGREEMENT: "Agreement",
    AGREEMENT_CHARGES_COPY: "Agreement charges copy",
    CHARGES: "Charges",
    CANCELLATION_UPON_ARRIVAL_BY_COMPANY:
      "Cancellation Upon Arrival By Company Charges",
    VEHICLE_NON_PLACEMENT_CHARGES: "Vehicle Non Placement Charges",
    VEHICLE_NON_PLACEMENT_ADD_CHARGES: "Vehicle Non Placement - Add Charges",
    MULTI_POINT_PICK_CHARGES: "Multi Point Pick Charges",
    MULTI_POINT_PICK_ADD_CHARGES: "Multi Point Pick - Add Charges",
    MULT_POINT_DROP_CHARGES: "Multi Point Drop Charges",
    MULT_POINT_DROP_ADD_CHARGES: "Multi Point Drop - Add Charges",
    DETENTION_CHARGES_AT_SOURCE: "Detention Charges At Source",
    DETENTION_CHARGES_AT_ADD_SOURCE: "Detention At Source - Add Charges",
    DETENTION_CHARGES_AT_DESTINATON: "Detention Charges At Destination",
    DETENTION_CHARGES_AT_ADD_DESTINATON:
      "Detention At Destination - Add Charges",
    LATE_DELIVERY_CHARGES: "Late Delivery Charges",
    LATE_DELIVERY_ADD_CHARGES: "Late Delivery - Add Charges",
    CANCELLATION_UPON_ARRIVAL_COMPANY: "Cancellation Upon Arrival Company",
    CANCELLATION_UPON_ARRIVAL_ADD_COMPANY:
      "Cancellation Upon Arrival - Add Charges",
    PRINT: "Print",
    DAY: "Day",
    TIME_PERIOD: "Time Period",
    COPY_FROM_LAST_AGREEMENT: "Copy from last Agreement",
    ATTACH_AGREEMENT_COPY: "Attach Agreement copy",
    ADD_ROW: "Add row",
    ADD_To_LIST: "Add To List",
    SAVE_ALL: "Save All",
    CHARGES_IS_REQUIRED: "Charges are required",
    DATE_IS_REQUIRED: "Date is required",
    DAY_IS_REQUIRED: "Day is required",
    TIME_PERIOD_IS_REQUIRED: "Time Period is required",
    BILLING: "Billing",
    FROM_DATE: "From Date",
    TO_DATE: "To Date",
    SEARCH: "Search",
    BILL: "Bill",
    RAISE_INVOICE: "Raise Invoice",
    RAISE_INVOICE_DRAFT: "Raise Invoice Draft",
    CREATED_AT: "Created At",
    TOTAL_COST: "Total Cost",
    SELECT_STATE_FUNCTIONALITY:
      "Select states or select date via search functionality to proceed.",
    ACTION: "Action",
    TRUCK_NO: "Truck No",
    LR_NO: "Lr Number",
    TRUCK_TYPE: "Truck Type",
    TRIP_STATUS: "Trip Status",
    DATE_OF_THE_PLACEMENT: "Date Of The Placement Of The Truck",
    STATUS_EPOD: "Status EPOD",
    TOTAL_DISTANCE: "Total Distance",
    LOADING_CHARGES: "Loading Charges",
    UNLOADING_CHARGES: "Unloading Charges",
    SHORTAGE: "Shortage",
    DED: "Ded",
    DAMAGE: "Damage",
    OTHER_CHARGES: "Other Charges",
    ADD_DEDUCTION: "Add/Deductions",
    EXPENSE_ENTRY: "Expense Entry",
    ADD_EXPENSE: "Add Expense",
    VIEW_DETAILS: "View Details",
    ORIGINAL: "Orginal",
    REVISED: "Revised",
    BILL_TYPE: "Bill Type",
    LR_NUMBER: "LR Number",
    PF_NO: "Pf No.",
    FREIGHT_AMOUNT: "Freight Amount",
    DAMAGE_CHARGES: "Damage Charges",
    REVISED_DAMAGE_CHARGES: "Revised Damage Charges",
    SHORTAGE_CHARGES: "Shortage Charges",
    SOURCE_DETENTION_CHARGES: "Source Detention Charges",
    DESTINATION_DETENTION_CHARGES: "Destination Detention Charges",
    OTHER_ADD_DED: "Other Add/Ded",
    CANCELLATION_UPON_ARRIVAL: "Cancellation Upon Arrival",
    INDENT_REJECTION_CAHRGES: "Indent Rejection Charges",
    NET_CHARGES: "Net Charges",
    NET_DEDUCTIONS: "Net Deductions",
    TMS_INVOICE_NO: "TMS Invoice No",
    SUPPLIER_TAX_INVOICE_NO: "Supplier Tax Invoice No",
    NO_OF_BILLS: "No Of Bills",
    EXPORT: "Export",
    DATED: "dated",
    COMMENT: "Comments",
    DOWNLOAD_DOCUMENTS: "Download Documents",
    REJECTION_COMMENT: "Rejection Comment",
    CONFIRM_REJECTION: "Confirm Rejection",
    EDIT_INVOICE: "Edit Invoice",
    SUBMIT_APPROVAL: "Submit For Approval",
    ADD_AGREEMENT: "Add Agreement",
    ADD_BILL: "Add Bill",
    INPUT_INVOICE: "Input Invoice",
    TRANSPORTER_GST_INVOICE_NO: "Transporter GST Invoice No",
    INVOICE_DATE: "Invoice Date",
    ATTACH_TOPSHEET: "Attach Topsheet",
    AS_PER_ORACLE: "As Per Oracle",
    DIFF: "Diff",
    TOTAL_DELIVERIES: "Total Deliveries",
    SUBMIT_TO_RAISE_INVOICE: "Submit to raise invoice",
    CANCEL_INVOICE: "Cancel Invoice",
  },
  tracking: {
    TRACKING: "Tracking",
    DRIVER_VEHICLE: "Driver and Vehicle Details",
    VEHICLE_HISTORY: "Vehicle History",
    LAST_STATUS: "Last known Status Update",
    CONSENT_STATUS: "Consent Status",
    REPORT_INCIDENT: "Report Incident",
    CHANGE_DRIVER: "Change Driver Details",
    STARTING_LOCATION: "Starting Location",
    CONTACT_NO: "Contact Number",
    VEHICLE_NO: "Vehicle Number",
    VEHICLE_PLATE: "Vehicle License Plates",
    SELECT_DRIVER: "Select Driver",
    DRIVER_NAME: "Driver Name",
    LAST_LOCATION: "Last Known Location ",
    LAST_REPORT_TIME: "Last Reported Time",
    TYPE_OF_INCIDENT: "Type Of Incident",
    WARNING: "Do You want to Change Vehicle details?",
    DESCRIPTION: "Description",
    VEHCILE_REG_NO: "Vehicle Registation No",
    VEHICLE_OWNER: "Vehicle Owner",
    ENTER: "Enter",
    TRACK_TRIP_ID: "Trip ID",
    TRACKING_HISTORY: "Tracking History",
    VEHICLE_CHANGE: "Vehicle Change Due To An Incident",
  },
  controlTower: {
    CONTROL_TOWER: "Control Tower",
    VEHICLE_NO: "Vehicle No",
    NO_TRIPS: " No trips are available",
    DRIVER_DETAILS: "Driver Details",
    TRIPS_DETAILS: "Trip Details",
    TRIP_START_TIME: "Trip Start time",
    ARRIVAL_TIME: "Estimate Arrival time",
    START_LOCATION: "Start Location",
    LATEST_KNOWN_LOCATION: "Latest Known Location",
  },
  report: {
    REPORTS: "Reports",
    TRANSPORTER_PERFORMANCE: "Transporter Performance",
    VEHICLE_PLACEMENT_REPORT: "Vehicle Placement Report",
    TOTAL_TRIPS: "Total Trips",
    TRANSPORTER_SCORE: "Transporter Score",
    VEHICLE_PLACEMENT: "Vehicle Placement (%)",
    QUALITY_REJECTION: "Quality Rejection (%)",
    ON_TIME_DELIVERY_REPORT1: "On Time Delivery Report",
    ON_TIME_DELIVERY_REPORT: "On Time Delivery (%)",
    DAMAGE: "Damage (%)",
    SHORTAGES: "Shortages (%)",
    LANE: "Lane",
    NO_OF_INDENT: "No of Indent",
    INDENT_ANALYSIS_REPORT: "Indent Analysis Report",
    VEHICLE_PLACEMENT_DETAIL_REPORT: "Vehicle Placement Analysis Report",
    VEHICLE_ANALYSIS_REPORT: "Vehicle Analysis Report",
    //BAXTER
    LOGBOOK: "Logbook",
    DISPATCH_LEAD_TIME: "Dispatch Lead Time",
    BIFURICATION_VOLUME_REPORT: "Bifurication Vol. Report",
    BIFURICATION_REVENUE_REPORT: "Bifurication Rev. Report",
    TRUCK_UTILIZATION_REPORT: "Truck Utilization Report",
  },
  common: {
    ORACLE_SYNC: "Oracle Sync",
    HELP: "Help",
    LANGUAGE: "Language",
    CALENDER: "Date Range Filter",
    VEHICLE_TYPE: "Vehicle Type",
    TRANSPORTER: "Transporter",
    INDENT: "Indent",
    INDENT_DETAILS: "Indent Details",
    SOURCE: "Source",
    DESTINATION: "Destination",
    CUSTOMERS: "Customers",
    ROUTE: "Route",
    STATUS: "Status",
    ACTIONS: "Actions",
    DELAYED: "Delayed",
    UNTRACKED: "Untracked",
    REJECTED: "Rejected",
    SUBMIT: "Submit",
    RESET: "Reset",
    BACK: "Back",
    START_TIME: "Start Time",
    EXPIRY_TIME: "Expiry Time",
    START_DATE: "Start Date",
    EXPIRY_DATE: "Expiry Date",
    END_DATE: "End Date",
    ZIP_CODE: "Zip Code",
    CITY: "City",
    STATE: "State",
    PHONE_NO: "Phone Number",
    EMAIL: "Email",
    TRANSPORTER_NAME: "Transporter Name",
    ADD_TRANSPORTER: "Add Transporter",
    TRANSPOTER_DETAILS: "Transporter Details",
    FULL_NAME: "Full Name",
    PHONE: "Phone",
    VEHICLE: "Vehicle",
    EDITABLE_TABLE: "Editable Tables",
    EDITABLE_TABLE_CAPS: "EDITABLE TABLES",
    TABLES: "Tables",
    EDITABLE: "Editable",
    AUTOFILL: "AutoFill",
    ID: "ID",
    AGE: "AGE",
    QTY: "QTY",
    COST: "COST",
    DATA_TABLE: "Datatable Editable",
    AND: "And",
    QUALITY_CHECK: "Quality Check",
    TRUCK_TYPE: "Truck Type",
    QUALITY_CHECK_PHOTO: "Quality Check Photo",
    DRIVER_LICENSE_NO: "Driver License No",
    SAVE: "Save",
    ADD_NEW: "Add New",
    CANCEL: "Cancel",
    OK: "ok",
    CLOSE: "close",
    LOGOUT: "Logout",
    PROFILE_SETTINGS: "Profile",
    ADD: "Add",
    USER_ID: "UserId",
    LOADING_POINTS: "Loading Points",
    DOWNLOAD: "Download",
    VERIFIED_BY: "Verified By",
    VERIFIED_AT: "Verified At",
    RESET: "Reset",
    BACK: "Back",
    MONTHLY: "Monthly",
    YEARLY: "Yearly",
    WEEK_DAY: "Week Day",
    QUARTERLY: "Quarterly",
    YEAR: "Year",
    WEEK: "Week",
    DOWNLOAD_PDF: "Download PDF",
    DOWNLOAD_EXCEL: "Download Excel",
    USER_DETAILS: "User Details",
    USER_NAME: "Name",
    NO_DATA: "No Data",
    ROLE: "Role",
    ADMIN: "Admin",
    HOP: "Hop",
    UPDATE_PASSWORD: "Update Password",
    NEW_PASSWORD: "Password",
    CONFIRM_PASSWORD: "Confirm Password",
    NOTIFICATION_SETTING: "Notification Setting",
    FIELDS: "Fields",
    MOBILE_APP: "Mobile App",
    SMS: "SMS",
    WHATSAPP: "Whatsapp",
    WHATSAPP_NO: "Whatsapp No",
    TRIPS: "Trips",
    INVOICE: "Invoice",
    USERID: "User ID",
    CUSTOMER_CODE: "Customer Code",
    REMARK: "Remark",
  },
  orders: {
    ORDER: "Order",
    ORDERS: "Orders",
    PRODUCT: "Product",
    INVOICE: "Invoice",
    PRODUCT_DETAIL: "Product Detail",
    PRODUCT_LIST: "Product List",
    VIEW_ORDER: "View Order",
    VIEW_PRODUCT: "View Product",
    VIEW_INVOICE: "View Invoice",
    TRACK_ORDER: "Track Order",
    ORDER_HISTORY: "Order History",
    ORDER_LIST: "Order List",
    INVOICE_LIST: "Invoice List",
    TRIPS: "Trip",
    TRIP_LIST: "Trip List",
  },
  baxterdDashboard: {
    //trip
    ARRIVED_AT_SOURCE: "Arrived at Source",
    QUALITY_CHECK: "Quality Check",
    LOADING_IN_PROGRESS: "Loading In Progress",
    //order
    TOTAL_ORDER_PUNCHED: "Order Punch",
    ORDER_RELEASE: "Order Released",
    INVOICE_GENERATED: "Invoice Generated",
    ORDER_DISPATCHED: "Order Dispatch",
    ORDER_DELIVERED: "Order Delivered",
    TRACKING_STATUS: "Tracking Status",
    ORDER_CANCELLED: "Order Cancelled",
    BACK_ORDER: "Back Order",
    DISPATCH_TO_DILEVERY: "Dispatch To Delivery",
    PUNCH_TO_DISPATCH: "Punch To Dispatch",
    BIFURICATION_OF_TRANSPORT_MODE: "Bifurication of transport mode",
    TRIP_STATUS: "Trip Status",
    ORDER_STATUS: "Order Status",
    TRIP_WITHOUT_EWAY_BILL: "trip WithOut Eway BillNumber",
  },
};
export const HINDI = {
  dashboard: {
    LOGBOOK: "कार्यपंजी",
    DASHBOARD: "डैशबोर्ड",
    OPERATIONS: "संचालन",
    INVOICE: "बीजक",
    TOTAL: "कुल",
    PLANNED: "योजनाबद्ध",
    IN_TRANSIT: "मार्गस्थ",
    COMPLETED: "समाप्त",
    OTHERS: "अन्य",
    PENDING: "रूका हुआ",
    TRIPS_DELAYED: "यात्राएं विलंबित",
    SHIPMENT_VERIFIED: "निरीक्षण संपन्न",
    UNTRACKED_TRIPS: "ट्रैक न की गई यात्राएं",
    SHIPMENT: "लदान",
    OPERATIONAL_TAT: "परिचालन टीएटी",
    TRIP_STATUS: "यात्रा की स्थिति",
    DAMAGED_SHIPMENT: "नुकसान लदान",
    AVERAGE_LOADING_TIME: "औसत लोडिंग समय",
    AVERAGE_UNLOADING_TIME: "औसत उतराई समय",
    QUALITY_CHECK_TIME_AVERAGE: "गुणवत्ता जांच समय औसत",
    SHORTAGE_SHIPMENT: "कमी लदान",
    INDENT_DETAILS: "मांगपत्र विवरण",
    INDENT_ACCEPTANCE: "मांगपत्र स्वीकृति",
    TOTAL_INDENTS: "कुल मांगपत्र",
    INDENT_REJECTION: "मांगपत्र अस्वीकृति",
    EXPIRY: "समाप्ति",
    CANCELLED: "रद्द",
    SHIPMENT_VERIFIED: "लदान सत्यापित",
    ARRIVED_AT_SOURCE: "स्रोत पर पहुंचे",
    QUALITY_CHECK_COMPLETED: "गुणवत्ता जांच पूरी",
    ARRIVED_AT_VIA_POINT: "बिंदु के माध्यम से पहुंचे",
    LOADING_IN_PROGRESS: "लोड हो रहा है",
    PAID_INVOICES: "भुगतान किया गया चालान",
    AMOUNT: "रकम",
    UNPAID_INVOICE: "बिना चुकाई रसीद",
    SUBMITTED_INVOICE_COUNT: "भुगतान स्वीकृति",
    SUBMITED_INVOICE_PAYMENT: "भुगतान",
    PENDING_APPROVAL: "लंबित अनुमोदन",
    PENDING_APPROVAL_TOTAL: "लंबित अनुमोदन",
    PER_SHIPMENT: "प्रति लदान",
    PER_TRIP: "प्रति यात्रा",
    FREIGHT_COST: "माल ढुलाई लागत",
    JANUARY: "जनवरी",
    FEBRUARY: "फ़रवरी",
    MARCH: "मार्च",
    APRIL: "अप्रैल",
    MAY: "मई",
    JUNE: "जून",
    JULY: "जुलाई",
    AUGUST: "अगस्त",
    SEPTEMBER: "सितंबर",
    OCTOBER: "अक्टूबर",
    NOVEMBER: "नवंबर",
    DECEMBER: "दिसंबर",
    ACCEPTANCE_RATE: "स्वीकार्य भाव",
    REJECTION_RATE: "अस्वीकृति भाव",
    LATE_DELIVERY: "देर से वितरण",
    ON_TIME_DELIVERY: "समय पर वितरण",
    SHORTAGE_PERCENTAGE: "कमी प्रतिशत",
    DAMAGE_PERCENTAGE: "नुक़सान प्रतिशत",
    BUSINESS_BREAKUP: "व्यापार टूटना",
    ACCEPTANCE: "स्वीकार्य",
    REJECTION: "अस्वीकृति",
    RATE: "भाव",
    LATE: "देर से",
    ON_TIME: "समय पर",
    DELIVERY: "वितरण",
    SHORTAGE: "कमी",
    DAMAGE: "नुक़सान",
    CARBON_FOOT_PRINT: "कार्बन पदचिह्न",
    VEHICLE_PLACEMENT_GRAPH: "वाहन प्लेसमेंट",
    SHIPMENT_SHORTAGE_GRAPH: "शिपमेंट की कमी",
    SHIPMENT_DAMAGE_GRAPH: "शिपमेंट नुक़सान",
    COST_REVENUE_RATIO: "राजस्व अनुपात की लागत",
    MONTHLY: "महीना",
    TOTAL_DELIVERIES: "कुल प्रसव",
    TRUCK_UTILIZATION: "ट्रक का उपयोग",
  },
  planning: {
    PLANNING: "योजना",
    PLANNING_CAPS: "योजना",
    INDENT_PLANNING: "मांगपत्र योजना",
    ADD_INDENT: "मांगपत्र जोड़ें",
    LOAD_OPTIMIZATION: "लोड अनुकूलन",
    INDENT_ID: "मांगपत्र आईडी",
    REJECTED: "अस्वीकार कर दिया",
    VEHICLE_TYPE: "वाहन का प्रकार",
    VEHICLE_REPORTING_DATE: "वाहन रिपोर्टिंग दिनांक",
    TIME_AT_FACTORY: "कारखाने में समय",
    DEPOT: "गोदाम",
    TRANSPORTER: "ट्रांसपोर्टर",
    TRANSPORTER_ACCEPTANCE_EXPIRT_DATE: "परिवहन स्वीकृति समाप्ति तिथि / समय",
    COMMENTS: "टिप्पणी",
    CANCEL_INDENT: "रद्द मांगपत्र",
    SUBMIT: "प्रस्तुत",
    PROCESSING: "प्रसंस्करण.",
    SOURCE_IS_REQUIRED: "स्रोत की आवश्यकता है",
    DESTINATION_IS_REQUIRED: "स्थान की आवश्यकता है",
    VEHICLE_TYPE_IS_REQUIRED: "वाहन का प्रकार आवश्यक है",
    ROUTE_IS_REQUIRED: "मार्ग आवश्यक है",
    TRANSPORTER_IS_REQUIRED: "ट्रांसपोर्टर की आवश्यकता है",
    REPORT_DATE: "रिपोर्ट दिनांक/समय समाप्ति तिथि/समय के बाद का होना चाहिए",
    EXPIRY_DATE: "समाप्ति तिथि/समय रिपोर्ट दिनांक/समय से पहले होना चाहिए",
    START_DATE_IS_REQUIRED: "प्रारंभ दिनांक आवश्यक है",
    EXPIRY_DATE_IS_REQUIRED: "समाप्ति दिनांक आवश्यक है",
    INDENT_CANCEL_WARNING: "क्या आप वाकई इंडेंट को रद्द करना चाहते हैं?",
    CANCEL_REASONS: "कृपया रद्द करने का कारण बताएं",
    REASON: "कारण",
    REJECTION_HISTORY: "अस्वीकृति इतिहास",
    CREATED_BY: "के द्वारा बनाई गई",
    UPDATED_BY: "द्वारा अपडेट",
    VEHICLE_REG: "वाहन पंजीकरण",
    REJECT_INDENT: "इंडेंट अस्वीकार करे",
    REJECT_INDENT_WARNING: "अस्वीकृति शुल्क निहित होगा। कृपया पुष्टि करें।",
    REJECT_INDENT_COST: "लागत",
    REJECT_INDENT_ROUTE: "रास्ता",
    REJECT_INDENT_VEHICLEUNAVAILABILITY: "वाहन अनुपलब्धता",
    REJECT_INDENT_REMARK: "टिप्पणी",
  },
  masters: {
    MASTERS: "मास्टर",
    NAME: "नाम",
    PHONE: "फ़ोन",
    USERS: "उपयोगकर्ताओं",
    LIC_NO: "एलआईसी नंबर",
    CONSENT_STATUS: "सहमति की स्थिति",
    OWNER_NAME: "मालिक का नाम",
    REG_NO: "वाहन पंजीकरण संख्या",
    USERNAME: "उपयोगकर्ता नाम",
    CONTACT: "संपर्क",
    ROLE: "भूमिका",
    ROLES: "भूमिकाएँ",
    EMAIL: "ईमेल",
    HOP: "हॉप",
    HOPS: "हॉप",
    FREIGHT: "परिवहन",
    DESCRIPTION: "विवरण",
    VEHICLES: "वाहनों",
    DRIVERS: "चालक",
    ADD_DRIVER: "ड्राइवर जोड़ें",
    ADD_VEHICLE: "वाहन जोड़ें",
    ADD_STATE_ZONE: "क्षेत्र से राज्य का नक्शा",
    EDIT_USERS: "यूजर को संपादित करो",
    HOP_CODE: "हॉप कोड",
    HOP_NAME: "हॉप नाम",
    TOO_SHORT: "बहुत छोटा!",
    FULL_NAME_IS_REQUIRED: "पूरा नाम आवश्यक है",
    PHONE_IS_REQUIRED: "फोन की आवश्यकता है",
    PHONE_MUST_BE_10: "फ़ोन नंबर 10 अंकों का होना चाहिए",
    PHONE_FORMAT: "कृपया दिए गए प्रारूप में दर्ज करें",
    VEHICLE_REGISTRATION_IS_REQUIRED: "वाहन पंजीकरण संख्या आवश्यक है",
    VEHICLE_TYPE_IS_REQUIRED: "वाहन का प्रकार आवश्यक है",
    DRIVER_LICENSE_REQUIRED: "चालक लाइसेंस संख्या आवश्यक है",
    VEHICLE_OWNER_IS_REQUIRED: "वाहन मालिक की आवश्यकता है",
    VEHICLE_TYPE: "वाहन का प्रकार",
    VEHICLE_NAME: "वाहन का नाम",
    VEHICLE_HEIGHT: "कद",
    VEHICLE_WEIGHT: "वजन",
    VEHICLE_LENGTH: "लंबाई",
    VEHICLE_WIDTH: "चौड़ाई",
    //Heading
    ROUTES: "मार्ग",
    INDENTS: "इंडेंट",
    SHIPMENTS: "लदान",
    //shipment master
    SHIPMENT_ID: "शिपमेंट आईडी",
    TRIP_ID: "ट्रिप आईडी",
    // routes master
    ROUTE_CODE: "रूट कोड",
    NAME: "नाम",
    SOURCE: "स्रोत",
    DESTINATION: "गंतव्य",
    VIA: "के जरिए",
    // indent mastersrc/pages/routes/RowHeader.js
    INDENT_PLANNING_CODE: "इंडेंट प्लानिंग कोड",
    TRANSPORTER: "ट्रांसपोर्टर",
    VALIDITY: "वैधता",
    KMS: "कि.मी.",
    LANE_LOT: "लेन लॉट",
    LEAD_TIME: "लेन लॉट",
    LOT_NO: "बहुत कुछ नहीं",
    TRAGET_INDENTS: "लक्ष्य इंडेंट",
    ACTUAL_INDENTS: "वास्तविक इंडेंट",
    //BAXTER
    CUSTOMER: "ग्राहकों",
    LOCATIONS: "स्थानों",
    PRODUCTS: "उत्पादों",
    OPTIMIZATION: "अनुकूलन",
  },
  trip: {
    TRIP: "यात्रा",
    TRIPS: "यात्राएं",
    TRIP_HISTORY: "यात्रा इतिहास",
    TRIP_ID: "यात्रा आईडी",
    TRIPS_DETAILS: "यात्राएं विवरण",
    TIME: "समय",
    VEHICLE: "वाहन",
    DRIVER: "चालक",
    CANCEL_TRIP: "यात्रा रद्द करें",
    TRIP_WARNING: "क्या आप यात्रा रद्द करना चाहते हैं?",
    TRIP_CANCEL_REASON: "निरस्तीकरण का कारण",
    SHIPMENT_ID: "लदान आईडी",
    SHIPMENT_NO: "लदान संख्या",
    UNLOADED_BY: "द्वारा उतार दिया गया",
    ITEM_NO: "मद संख्या",
    LOT_NO: "लॉट संख्या",
    QUANTITY: "मात्रा",
    ATTACHED_PROOF: "सबूत संलग्न करें",
    NO_DAMAGE: "कोई क्षतिग्रस्त सबूत संलग्न नहीं है ",
    NO_SHORTAGE: "कोई कमी प्रमाण संलग्न नहीं है ",
    FRONT_BACK: "आगे और पीछे :",
    LR_COPY: "एलआर कॉपी",
    DAMAGE_QUANTITY: "क्षतिग्रस्त मात्रा",
    DAMAGE_REASONS: "नुकसान के कारण",
    QUALITY_CHECKS: "गुणवत्ता की जांच",
    QUALITY_CHECK_PHOTO: "गुणवत्ता जांच फोटो",
    VERIFY_SHIPMENT: "शिपमेंट सत्यापित करें",
    VERIFY: "सत्यापित करना",
    TRIGGER_CONSENT: "ट्रिगर सहमति",
    SKIP_SHIPMENT: "शिपमेंट छोड़ें",
    PF_SHIPMENT_NUMBER: "पीएफ/शिपमेंट नंबर",
    DRIVER_CONSENT:
      "चालक की सहमति प्रदान नहीं की गई। कृपया सुनिश्चित करें कि वाहन ट्रैकिंग सक्षम करने के लिए ड्राइवर की सहमति प्रदान की गई है",
    OPERATOR: "ऑपरेटर",
    SKIP_SHIPMENT_CAPS: "शिपमेंट छोड़ें",
    SKIP_SHIPMENT_PlACEHOLDER: "कृपया शिपमेंट छोड़ने के कारण दर्ज करें",
    SKIP_SHIPMENT_WARNING:
      "चालक की सहमति उपलब्ध नहीं है। क्या आप अभी भी जारी रखना चाहते हैं",
    WARNING: "चेतावनी",
    CONTINUE: "जारी रखें",
    REASON: "कारण",
    DRIVER_CONSENT_LOCATION:
      "स्थान ट्रैकिंग के लिए चालक की सहमति प्रदान की जाती है",
    CONSENT_LOCATION:
      "कृपया अपने सेवा प्रदाता से अपना स्थान जांचने के लिए अपनी सहमति दें। आईवीआर डायल 730377771 (जियो के अलावा) और 1 दबाएं या एसएमएस के माध्यम से उत्तर दें वाई से 55502 -आइडिया, 5114040 -एयरटेल, 9167500066 -वोडाफोन, 51712028 - जिओ",
    NO_LOADING_AT_THIS_LOCATION: "इस स्थान पर कोई लोड नहीं हो रहा है",
    TRIP_DATE: "यात्रा तिथि",
    VIEW_ALL_TRIPS: "सभी यात्राएं देखें",
    PLEASE_ENTER_FORMAT:
      "कृपया किसी दिए गए या अन्य मान्य प्रारूप में दर्ज करें",
    PLEASE_SELECT_TYPE: "कृपया प्रकार चुनें",
    CLICK_NO_SHIPMENT: "अगर कोई शिपमेंट नहीं जोड़ा जाना है तो क्लिक करें",
    Q1: "आगमन की आवश्यकताओं के साथ मेल खाने वाले वाहन का प्रकार",
    Q2: "क्या कंटेनर के दरवाजे बंद हो रहे हैं और ठीक से बंद हैं",
    Q3: "क्या कोई उभरे हुए नाखून हैं",
    Q4: "क्या ट्रक सामग्री सीमेंट, रसायन, कोयला, गाय के गोबर, मसाले आदि की कोई अवशिष्ट सामग्री है।",
    Q5: "क्या कोई आपत्तिजनक गंध है",
    Q6: "क्या प्लेटफार्म में कोई अच्छा तिरपाल है",
    Q7: "क्या प्लेटफॉर्म में कोई छेद है",
    Q8: "क्या प्लेटफार्म सम और धूल और गंदगी से मुक्त है",
    Q9: "क्या कोई लकड़ी के कोनों का उपयोग किया जा रहा है",
    Q10: "क्या वाहन का डिज़ाइन बिल्कुल सही है",
    Q11: "क्या फिटनेस सर्टिफिकेट वैलिड है",
    Q12: "क्या बीमा प्रमाणपत्र मान्य हैं",
    Q13: "क्या प्रदूषण प्रमाणपत्र मान्य हैं",
    Q14: "क्या वाहन पंजीकरण प्रमाणपत्र वैध हैं",
    Q15: "क्या ड्राइवर का लाइसेंस वैध है",
    YES: "हां",
    NO: "नहीं",
    EDIT_VEHICLE_DRIVER_DETAILS: "वाहन और चालक विवरण संपादित करें",
    ADD_VEHICLE_DRIVER_DETAILS: "वाहन और चालक विवरण जोड़ें",
  },
  invoice: {
    INVOICE_ID: "चालान आईडी",
    TMS_TRIP_ID: "टीएमएस ट्रिप आईडी",
    DATE: "दिनांक",
    STATE: "राज्य",
    TOTAL_COST: "कुल लागत",
    COMMENTS_INVOICE: "टिप्पणियाँ",
    INVOICE_STATUS: "चालान की स्थिति",
    INVOICE_LISTS: "चालान सूचियां",
    INVOICE_LIST: "चालान सूचियां",
    INVOICE_GENERATION: "चालान जनरेशन",
    BILL_DETAILS: "बिल विवरण",
    AGREEMENT: "समझौता",
    AGREEMENT_CHARGES_COPY: "अनुबंध शुल्क कॉपी",
    CHARGES: "शुल्क",
    CANCELLATION_UPON_ARRIVAL_BY_COMPANY: "कंपनी शुल्क द्वारा आगमन पर रद्दीकरण",
    VEHICLE_NON_PLACEMENT_CHARGES: "वाहन गैर प्लेसमेंट शुल्क",
    VEHICLE_NON_PLACEMENT_ADD_CHARGES: "वाहन गैर प्लेसमेंट शुल्क जोड़ें",
    MULTI_POINT_PICK_CHARGES: "मल्टी पॉइंट पिक चार्ज",
    MULTI_POINT_PICK_ADD_CHARGES: "मल्टी पॉइंट पिक - शुल्क जोड़ें",
    MULT_POINT_DROP_CHARGES: "मल्टी पॉइंट ड्रॉप चार्ज",
    MULT_POINT_DROP_ADD_CHARGES: "मल्टी पॉइंट ड्रॉप - शुल्क जोड़ें",
    DETENTION_CHARGES_AT_SOURCE: "स्रोत पर निरोध शुल्क",
    DETENTION_CHARGES_AT_ADD_SOURCE: "स्रोत पर निरोध - शुल्क जोड़ें",
    DETENTION_CHARGES_AT_DESTINATON: "गंतव्य पर नजरबंदी शुल्क",
    DETENTION_CHARGES_AT_ADD_DESTINATON: "गंतव्य पर नजरबंदी - शुल्क जोड़ें",
    LATE_DELIVERY_CHARGES: "देर से वितरण शुल्क",
    LATE_DELIVERY_ADD_CHARGES: "देर से डिलीवरी - शुल्क जोड़ें",
    CANCELLATION_UPON_ARRIVAL_COMPANY: "आगमन पर रद्दीकरण कंपनी",
    CANCELLATION_UPON_ARRIVAL_ADD_COMPANY: "आगमन पर रद्दीकरण - शुल्क जोड़ें",
    PRINT: "प्रिंट",
    DAY: "दिन",
    TIME_PERIOD: "समय सीमा",
    COPY_FROM_LAST_AGREEMENT: "पिछले समझौते से कॉपी",
    ATTACH_AGREEMENT_COPY: "अनुबंध की प्रति संलग्न करें",
    ADD_ROW: "लाइन जोड़ो",
    ADD_To_LIST: "सूची में शामिल",
    SAVE_ALL: "सब इकट्ठा करना",
    CHARGES_IS_REQUIRED: "शुल्क की आवश्यकता है",
    DATE_IS_REQUIRED: "दिनांक की आवश्यक है",
    DAY_IS_REQUIRED: "दिन की आवश्यकता है",
    TIME_PERIOD_IS_REQUIRED: "समय अवधि की आवश्यकता है",
    BILLING: "बिलिंग",
    FROM_DATE: "की तिथि से",
    TO_DATE: "तारीख तक",
    SEARCH: "तलाशी",
    BILL: "बिल",
    RAISE_INVOICE: "चालान बढ़ाएँ",
    RAISE_INVOICE_DRAFT: "चालान ड्राफ्ट बढ़ाएँ",
    CREATED_AT: "पर बनाया गया",
    TOTAL_COST: "कुल लागत",
    SELECT_STATE_FUNCTIONALITY:
      "आगे बढ़ने के लिए राज्यों का चयन करें या खोज कार्यक्षमता के माध्यम से तिथि चुनें.",
    ACTION: "गतिविधि",
    TRUCK_NO: "ट्रक नंबर",
    TRUCK_TYPE: "ट्रक का प्रकार",
    LR_NO: "एलआर नंबर",
    TRIP_STATUS: "यात्रा की स्थिति",
    DATE_OF_THE_PLACEMENT: "ट्रक की नियुक्ति की तिथि",
    STATUS_EPOD: "स्थिति ईपीओडी",
    TOTAL_DISTANCE: "कुल दूरी",
    LOADING_CHARGES: "लोडिंग शुल्क",
    UNLOADING_CHARGES: "उतराई प्रभार",
    SHORTAGE: "कमी",
    DED: "डेड",
    DAMAGE: "नुक़सान",
    OTHER_CHARGES: "अन्य शुल्क",
    ADD_DEDUCTION: "जोड़ें/कटौती",
    EXPENSE_ENTRY: " व्यय प्रविष्टि",
    ADD_EXPENSE: "व्यय जोड़ें",
    VIEW_DETAILS: "विवरण देखें",
    ORIGINAL: "असली",
    REVISED: "संशोधित",
    BILL_TYPE: "बिल प्रकार",
    LR_NUMBER: "लआर नंबर",
    PF_NO: "पीएफ नंबर",
    FREIGHT_AMOUNT: "भाड़ा की रकम",
    DAMAGE_CHARGES: "क्षति प्रभार",
    REVISED_DAMAGE_CHARGES: "संशोधित क्षति प्रभार",
    SHORTAGE_CHARGES: "कमी प्रभार",
    SOURCE_DETENTION_CHARGES: "स्रोत निरोध शुल्क",
    DESTINATION_DETENTION_CHARGES: "गंतव्य निरोध शुल्क",
    OTHER_ADD_DED: "अन्य जोड़ें / घटाएं",
    CANCELLATION_UPON_ARRIVAL: "आगमन पर रद्दीकरण",
    INDENT_REJECTION_CAHRGES: "मांगपत्र अस्वीकृति शुल्क",
    NET_CHARGES: "शुद्ध शुल्क",
    NET_DEDUCTIONS: "शुद्ध कटौती",
    TMS_INVOICE_NO: "टीएमएस चालान संख्या",
    SUPPLIER_TAX_INVOICE_NO: "आपूर्तिकर्ता कर चालान संख्या",
    NO_OF_BILLS: "बिलों की संख्या",
    EXPORT: "निर्यात",
    DOWNLOAD_EXCEL: "एक्सेल डाउनलोड करें",
    DOWNLOAD_PDF: "डाउनलोड पीडीऍफ़",
    DATED: "दिनांक",
    COMMENT: "टिप्पणियाँ",
    DOWNLOAD_DOCUMENTS: " दस्तावेज़ डाउनलोड करें",
    REJECTION_COMMENT: "अस्वीकृति टिप्पणी",
    CONFIRM_REJECTION: "अस्वीकृति की पुष्टि करें",
    EDIT_INVOICE: "चालान संपादित करें",
    SUBMIT_APPROVAL: "अनुमोदन के लिए सबमिट करें",
    ADD_AGREEMENT: "समझौता जोड़ें",
    ADD_BILL: "बिल जोड़ें",
    INPUT_INVOICE: "इनपुट चालान",
    TRANSPORTER_GST_INVOICE_NO: "ट्रांसपोर्टर जीएसटी चालान संख्या",
    INVOICE_DATE: "चालान की तारीख",
    ATTACH_TOPSHEET: "टॉपशीट संलग्न करें",
    AS_PER_ORACLE: "ओरेकल के अनुसार",
    DIFF: "अंतर",
    SUBMIT_TO_RAISE_INVOICE: "चालान बढ़ाने के लिए सबमिट करें",
    CANCEL_INVOICE: "चालान रद्द करें",
  },
  report: {
    REPORTS: "रिपोर्ट",
    TRANSPORTER_PERFORMANCE: "ट्रांसपोर्टर प्रदर्शन",
    VEHICLE_PLACEMENT_REPORT: "वाहन प्लेसमेंट रिपोर्ट",
    TOTAL_TRIPS: "कुल यात्राएं",
    TRANSPORTER_SCORE: "ट्रांसपोर्टर स्कोर",
    VEHICLE_PLACEMENT: "वाहन प्लेसमेंट (%)",
    QUALITY_REJECTION: "गुणवत्ता अस्वीकृति (%)",
    ON_TIME_DELIVERY_REPORT1: "समय पर डिलीवरी रिपोर्ट",
    ON_TIME_DELIVERY_REPORT: "समय पर डिलीवरी (%)",
    DAMAGE: "नुक़सान (%)",
    SHORTAGES: "कमी (%)",
    LANE: "लेन",
    NO_OF_INDENT: "इंडेंट की संख्या",
    INDENT_ANALYSIS_REPORT: "इंडेंट विश्लेषण रिपोर्ट",
    //baxter
    LOGBOOK: "कार्यपंजी",
    DISPATCH_LEAD_TIME: "प्रेषण लीड समय",
    BIFURICATION_VOLUME_REPORT: "द्विभाजन वॉल्यूम रिपोर्ट",
    BIFURICATION_REVENUE_REPORT: "द्विभाजन राजस्व रिपोर्ट",
    TRUCK_UTILIZATION_REPORT: "ट्रक उपयोगिता रिपोर्ट",
  },
  controlTower: {
    CONTROL_TOWER: "नियंत्रण बुर्ज",
    VEHICLE_NO: "वाहन संख्या",
    NO_TRIPS: "कोई यात्राएं उपलब्ध नहीं हैं",
    DRIVER_DETAILS: "ड्राइवर का विवरण",
    TRIPS_DETAILS: "यात्रा विवरण",
    TRIP_START_TIME: "यात्रा प्रारंभ समय",
    ARRIVAL_TIME: "अनुमानित आगमन समय",
    START_LOCATION: "प्रारंभ स्थान",
    LATEST_KNOWN_LOCATION: "अंतिम ज्ञात स्थान",
  },
  tracking: {
    TRACKING: "नज़र रखना",
    DRIVER_VEHICLE: "चालक और वाहन विवरण",
    VEHICLE_HISTORY: "वाहन इतिहास",
    LAST_STATUS: "अंतिम ज्ञात स्थिति अद्यतन",
    CONSENT_STATUS: "सहमति की स्थिति",
    REPORT_INCIDENT: "घटना की रिपोर्ट करें",
    CHANGE_DRIVER: "ड्राइवर विवरण बदलें",
    STARTING_LOCATION: "प्रारंभिक स्थान",
    CONTACT_NO: "संपर्क नंबर",
    VEHICLE_NO: "वाहन संख्या",
    SELECT_DRIVER: "ट्रांसपोर्टर का चयन करें",
    DRIVER_NAME: "चालक का नाम",
    LAST_LOCATION: "अंतिम पता स्थान",
    LAST_REPORT_TIME: "अंतिम रिपोर्ट किया गया समय",
    TYPE_OF_INCIDENT: "घटना का प्रकार",
    WARNING: "क्या आप वाहन विवरण बदलना चाहते हैं?",
    VEHCILE_REG_NO: "वाहन पंजीकरण संख्या",
    VEHICLE_OWNER: "वाहन मालिक",
    ENTER: "प्रवेश",
    TRACK_TRIP_ID: "यात्रा आईडी",
    VEHICLE_PLATE: "वाहन लाइसेंस प्लेट",
    DESCRIPTION: "विवरण",
    TRACKING_HISTORY: "ट्रैकिंग इतिहास",
    VEHICLE_CHANGE: "एक घटना के कारण वाहन परिवर्तन",
  },
  common: {
    ORACLE_SYNC: "ओरेकल सिंक",
    HELP: "मदद",
    LANGUAGE: "भाषा",
    CALENDER: "दिनांक सीमा फ़िल्टर",
    VEHICLE_TYPE: "वाहन का प्रकार",
    TRANSPORTER: "ट्रांसपोर्टर",
    INDENT: "मांगपत्र",
    INDENT_DETAILS: "मांगपत्र विवरण",
    SOURCE: "स्रोत",
    DESTINATION: "स्थान",
    ROUTE: "रास्ता",
    STATUS: "स्थिति",
    ACTIONS: "गतिविधि",
    DELAYED: "देर से",
    UNTRACKED: "ट्रैक न किए गए",
    REJECTED: "अस्वीकार कर दिया",
    SUBMIT: "प्रस्तुत",
    RESET: "रीसेट",
    BACK: "पीछे",
    START_TIME: "समय शुरू",
    EXPIRY_TIME: "समाप्ति समय",
    START_DATE: "प्रारंभ दिनांक",
    EXPIRY_DATE: "अंत दिनांक",
    END_DATE: "अंत दिनांक",
    ZIP_CODE: "पिन कोड",
    CITY: "शहर",
    STATE: "राज्य",
    PHONE_NO: "फोन नंबर",
    EMAIL: "ईमेल",
    TRANSPORTER_NAME: "ट्रांसपोर्टर का नाम",
    ADD_TRANSPORTER: "ट्रांसपोर्टर जोड़ें",
    TRANSPOTER_DETAILS: "टैन्सपोर्टर विवरण",
    FULL_NAME: "पूरा नाम",
    PHONE: "फोन",
    VEHICLE: "वाहन",
    EDITABLE_TABLE: "संपादन योग्य टेबल",
    EDITABLE_TABLE_CAPS: "संपादन योग्य टेबल",
    TABLES: "टेबल",
    EDITABLE: "संपादन योग्य",
    AUTOFILL: "स्वत: भरण",
    ID: "आईडी",
    AGE: "आयु",
    QTY: "मात्रा",
    COST: "कीमत",
    DATA_TABLE: "डेटाटेबल संपादन योग्य",
    AND: "और",
    QUALITY_CHECK: "गुणवत्ता की जांच",
    TRUCK_TYPE: "ट्रक का प्रकार",
    QUALITY_CHECK_PHOTO: "गुणवत्ता जांच फोटो",
    DRIVER_LICENSE_NO: "चालक लाइसेंस संख्या",
    SAVE: "बचाना",
    ADD_NEW: "नया जोड़ें",
    CANCEL: "रद्द करें",
    OK: "ठीक है",
    CLOSE: "बंद करे",
    LOGOUT: "लॉग आउट",
    PROFILE_SETTINGS: "प्रोफ़ाइल",
    ADD: "जोड़ें",
    USER_ID: "यूज़र आईडी",
    LOADING_POINTS: "लोडिंग पॉइंट",
    DOWNLOAD: "डाउनलोड",
    VERIFIED_BY: "के द्वारा सत्यापित",
    VERIFIED_AT: "सत्यापित",
    RESET: "रीसेट",
    BACK: "पीछे",
    MONTHLY: "महीने ",
    YEARLY: "सालाना",
    WEEK_DAY: "सप्ताह",
    QUARTERLY: "त्रैमासिक",
    DATE: "दिनांक",
    WEEK: "सप्ताह",
    REMARK: "टिप्पणी",

    USER_DETAILS: "उपयोगकर्ता की जानकारी",
    USER_NAME: "नाम",
    NO_DATA: "कोई डेटा नहीं",
    ROLE: "भूमिका",
    ADMIN: "व्यवस्थापक",
    HOP: "स्थान",
    UPDATE_PASSWORD: "पासवर्ड अपडेट करें",
    NEW_PASSWORD: "नया पासवर्ड",
    CONFIRM_PASSWORD: "पासवर्ड की पुष्टि कीजिये",
    NOTIFICATION_SETTING: "अधिसूचना सेटिंग",
    FIELDS: "डेटा",
    MOBILE_APP: "मोबाइल एप्लिकेशन",
    SMS: "एसएमएस",
    WHATSAPP: "व्हाट्सएप",
    WHATSAPP_NO: "व्हाट्सएप",
    TRIPS: "यात्रा करना",
    INVOICE: "बिल",
  },
  //BAXTER
  orders: {
    ORDER: "आदेश",
    ORDERS: "आदेश",
    PRODUCT: "उत्पाद",
    PRODUCT_LIST: "वास्तु सूची",
    PRODUCT_DETAIL: "वास्तु की बारीकी",
    VIEW_ORDER: "आर्डर देखें",
    VIEW_PRODUCT: "उत्पाद देखें",
    VIEW_INVOICE: "चालान देखें",
    TRACK_ORDER: "ऑर्डर पर नज़र रखना",
    ORDER_HISTORY: "आदेश इतिहास",
    ORDER_LIST: "आदेश सूची",
    INVOICE_LIST: "चालान सूची",
    INVOICE: "चालान",
    TRIPS: "यात्रा करना",
    TRIP_LIST: "यात्रा सूची",
  },
  baxterdDashboard: {
    ARRIVED_AT_SOURCE: "स्रोत पर पहुंचे",
    QUALITY_CHECK: "गुणवत्ता की जांच",
    LOADING_IN_PROGRESS: "लोड हो रहा है",
    TOTAL_ORDER_PUNCHED: "आदेश पंच",
    ORDER_RELEASE: "आदेश जारी",
    INVOICE_GENERATED: "चालान जनरेट किया गया",
    ORDER_DISPATCHED: "आदेश प्रेषण",
    ORDER_DELIVERED: "आदेश दिया गया",
    TRACKING_STATUS: "ट्रैकिंग स्थिति",
    ORDER_CANCELLED: "आदेश रद्द",
    BACK_ORDER: "आदेश वापस",
    DISPATCH_TO_DILEVERY: "डिलिवरी के लिए प्रेषण",
    PUNCH_TO_DISPATCH: "भेजने के लिए मुक्का",
    BIFURICATION_OF_TRANSPORT_MODE: "परिवहन मोड का द्विभाजन",
    TRIP_STATUS: "यात्रा स्थिति",
    ORDER_STATUS: "आदेश की स्थिति",
    COURIER: "कूरियर",
    TRUCK: "ट्रक",
    TRACKING: "ट्रैकिंग",
    NON_TRACKING: "गैर ट्रैकिंग",
    TRIP_WITHOUT_EWAY_BILL: "ईवे बिल नंबर के बिना यात्रा",
  },
};
