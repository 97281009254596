export var emptyObject = {
  indent: {
    finalized: {
      sms: false,
      inApp: false,
      email: false,
      whatsapp: false,
    },
    assigned: {
      sms: false,
      inApp: false,
      email: false,
      whatsapp: false,
    },
    accepted: {
      sms: false,
      inApp: false,
      email: false,
      whatsapp: true,
    },
    autorejected: {
      sms: false,
      inApp: false,
      email: false,
      whatsapp: false,
    },
    expired: {
      sms: false,
      inApp: false,
      email: false,
      whatsapp: false,
    },
    cancelled: {
      sms: false,
      inApp: false,
      email: false,
      whatsapp: false,
    },
    rejected: {
      sms: false,
      inApp: false,
      email: false,
      whatsapp: false,
    },
    reassigned: {
      sms: false,
      inApp: false,
      email: false,
      whatsapp: false,
    },
    drafted: {
      sms: false,
      inApp: false,
      email: false,
      whatsapp: false,
    },
  },
  trip: {
    created: {
      sms: false,
      inApp: false,
      email: false,
      whatsapp: false,
    },
    arrivedAtSource: {
      sms: false,
      inApp: false,
      email: false,
      whatsapp: false,
    },
    qualityCheckCompleted: {
      sms: false,
      inApp: false,
      email: false,
      whatsapp: false,
    },
    loadingInProgress: {
      sms: false,
      inApp: false,
      email: false,
      whatsapp: false,
    },
    shipmentVerified: {
      sms: false,
      inApp: false,
      email: false,
      whatsapp: false,
    },
    inTransit: {
      sms: false,
      inApp: false,
      email: false,
      whatsapp: false,
    },
    arrivedAtViaPoint: {
      sms: false,
      inApp: false,
      email: false,
      whatsapp: false,
    },
    EPODInProgress: {
      sms: false,
      inApp: false,
      email: false,
      whatsapp: false,
    },
    EPODCompleted: {
      sms: false,
      inApp: false,
      email: false,
      whatsapp: false,
    },
    completed: {
      sms: false,
      inApp: false,
      email: false,
      whatsapp: false,
    },
    rejected: {
      sms: false,
      inApp: false,
      email: false,
      whatsapp: false,
    },
    cancelled: {
      sms: false,
      inApp: false,
      email: false,
      whatsapp: false,
    },
  },
  invoice: {
    created: {
      sms: false,
      inApp: false,
      email: false,
      whatsapp: false,
    },
    rejected: {
      sms: false,
      inApp: false,
      email: false,
      whatsapp: false,
    },
    approved: {
      sms: false,
      inApp: false,
      email: false,
      whatsapp: false,
    },
  },
};
