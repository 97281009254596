import React, { Component } from "react";
import { Row, Col, Container } from "reactstrap";
import { connect } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import { restrict } from ".././restrict";
import { PERMS } from "../../enum/perms.enum";
import QuickFilters from "./quckFilters";
import moment from "moment";
import Index from "../../components/Common/DataTableNeo/Index";
import {
  AdminHeaderData,
  getLocalStorage,
  searchBar,
} from "./RowHeaders/RowHeaders";
import {
  fetchCustomerMasterSecondary,
  fetchCustomerOptionsSecondary,
} from "../../store/secondaryIndentPlanning/actions";

var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var searchValues = {};

export class CustomersMasterSecondary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataLoading: false,
      FromDate: "",
      ToDate: "",
      searchFilters: {
        customerNumber: "",
        shipToCode: "",
        customerName: "",
      },
      customersOption: [],
      customerName: "",
    };

    if (process.env.REACT_APP_CLIENT != "3_BAXTER") {
      (async () => {
        let redirect = await restrict(PERMS.VEHICLE_TRANSPORTER_READ);
        if (redirect === false) {
          this.props.history.push("/operationsDashboard");
        }
      })();
    }
  }

  componentDidMount = () => {
    offset = 0;
    this.getAllData();
    this.getAllCustomers();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };

  enterCheck = (event) => {
    if (event.key === "Enter") {
      offset = 0;
      this.getAllData();
    }
  };

  componentDidUpdate(prevProps) {}

  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
  };

  getAllCustomers = () => {
    this.props.fetchCustomerOptionsSecondary((res) => {
      let customers = [];
      if (res.data?.docs?.length > 0) {
        res.data.docs.forEach((ele) => {
          customers.push({
            value: {
              id: ele.customerName,
              // customerName: ele.customerName,
            },
            label: ele.customerName ? ele.customerName : "",
          });
        });
      }

      this.setState({ customersOption: customers }, () => {});
    });
  };

  handleSearchInput = (e, data) => {
    searchValues[e?.target?.name] = e?.target?.value;
    this.setState({ searchFilters: searchValues }, () => {});
  };

  handleSelectGroup = (e, name) => {
    searchValues[`${name}`] = e?.value?.id;
    this.setState({ searchFilters: searchValues, [`${name}`]: e }, () => {
      offset = 0;
      this.getAllData();
    });
  };

  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 0.9;
  }

  getAllData = () => {
    this.setState({ dataLoading: true });
    this.props.fetchCustomerMasterSecondary(
      { offset: offset, limit: 10 },
      this.state.searchFilters,
      (res) => {
        this.setState({ dataLoading: false }, () => {});
      }
    );
  };

  reset = (e) => {
    offset = 0;
    searchValues = {};
    this.setState(
      {
        searchFilters: {
          customerNumber: "",
          shipToCode: "",
          customers: "",
        },
        customers: "",
      },
      () => {
        this.getAllData();
      }
    );
  };

  handleChangePage = (event, val) => {
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    toVal = offset + process.env.REACT_APP_PERPAGE;
    this.getAllData();
  };

  render() {
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    var darkTheme = mode === "dark__mode" ? true : false;
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let userType = authUser?.data?.role?.label;
    let rows = [];
    let customerMasterList = this.props.customerMasterListSecondary;

    if (Array.isArray(customerMasterList?.docs)) {
      totCnt = customerMasterList?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = offset + customerMasterList?.docs?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);

      if (
        customerMasterList !== undefined &&
        customerMasterList?.docs?.length > 0
      ) {
        customerMasterList.docs.forEach((ele) => {
          if (ele) {
            rows.push({
              customerName: ele?.customerName ? ele?.customerName : "N/A",
              customerNumber: ele?.customerNumber ? ele?.customerNumber : "N/A",
              shipToCode: ele?.shipToCode ? ele?.shipToCode : "N/A",
              address: ele?.address
                ? `${ele?.address?.address1}, ${ele?.address?.address2}`
                : "N/A",
              city: ele?.address?.city ? ele?.address?.city : "N/A",
              postalCode: ele?.address?.postalCode
                ? ele?.address?.postalCode
                : "N/A",
              state: ele?.address?.state ? ele?.address?.state : "N/A",
            });
          }
        });
      }
    }

    return (
      <React.Fragment>
        <div /* className="page-content" */>
          {getLocalStorage()}
          <QuickFilters
            reset={this.reset}
            onInputChange={this.handleSearchInput}
            handleSelectGroup={this.handleSelectGroup}
            selectectedCustomers={this.state.customers}
            filters={this.state.searchFilters}
            handleSubmitChange={this.handleSubmitChange}
            enterCheck={this.enterCheck}
            filterList={this.getAllData}
            mode={darkTheme}
            customersOption={this.state.customersOption}
            getAllCustomers={this.getAllCustomers}
          />

          <Container fluid>
            {userType && (userType !== "admin" || userType !== "Admin") ? (
              <Index
                {...this.props}
                headers={AdminHeaderData}
                data={rows}
                searchBar={searchBar}
                fetchId={this.props.fetchIdMasterSecondary}
                getAllData={this.getAllData}
                rowHeight={"60px"}
                dataLoading={this.state.dataLoading}
              />
            ) : (
              <Index
                {...this.props}
                headers={AdminHeaderData}
                data={rows}
                searchBar={searchBar}
                fetchId={this.props.fetchIdMasterSecondary}
                getAllData={this.getAllData}
                rowHeight={"60px"}
                dataLoading={this.state.dataLoading}
              />
            )}
            {totCnt !== 0 && !this.state.dataLoading ? (
              <Row
                xs={12}
                style={{ color: darkTheme ? "#9D9D9D" : "#0C0C0C" }}
                className="mb-2 paginationRow"
              >
                <Col xs={7} sm={5} md={4} className="span-col-pagenation">
                  <div className="float-left">
                    <Pagination
                      className="desktop-pagination"
                      size="medium"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                    <Pagination
                      className="mobile-pagination"
                      size="small"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                  </div>
                </Col>
                <Col
                  className="mt-2 spanPagenationentries span-col-pagenation"
                  xs={5}
                  sm={7}
                  md={8}
                >
                  {" "}
                  <span style={{ color: darkTheme ? "#9D9D9D" : "#0C0C0C" }}>
                    Showing {fromVal} to {toVal} of {totCnt} entries
                  </span>
                </Col>
              </Row>
            ) : null}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    customerMasterListSecondary:
      state?.SecondaryIndent?.secondaryIndents?.CustomersMasterDataSecondary
        ?.data,
    fetchIdMasterSecondary:
      state?.SecondaryIndent?.secondaryIndents?.fetchIdCustomerMasterSecondary,
  };
};

export default connect(mapStateToProps, {
  fetchCustomerMasterSecondary,
  fetchCustomerOptionsSecondary,
})(CustomersMasterSecondary);
