import React, { Component } from "react";
//import from libraries
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
//import files
import { HeaderData, searchBar } from "./RowHeader/RowHeader";
import QuickFilters from "./QuickFilters";
import Index from "../../components/Common/DataTableNeo/Index";
//import from store
import { fetchAllTransporterSearch } from "../../store/transporter/actions";
import {
  FetchOnTimeDelivery,
  FetchOnTimeDeliveryExport,
} from "../../store/OntimeDeliveryReports/actions";
// global variables
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var darkMode;
class onTimeDeliveryReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      transpoterId: "",
      FromDate: "",
      ToDate: "",
      offset: 0,
      limit: 10,
      dataLoading: false,
      selectedTranspoterValues: "",
      transpoter: [],
      transporterValues: "",
    };
  }
  componentDidMount() {
    this.getAllData();
    this.getAllTransporterData();
    this.getAllExportData();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 0.9;
  }
  getAllData = () => {
    this.setState({
      dataLoading: true,
    });
    let values = {
      offset: offset,
      transpoterId: this.state.transporterValues,
      startDate: this.state.FromDate,
      endDate: this.state.ToDate,
      isExport: false,
    };
    this.props.FetchOnTimeDelivery(values, () => {
      this.setState({
        dataLoading: false,
      });
    });
  };
  getAllExportData = () => {
    this.setState({
      dataLoading: true,
    });
    let values = {
      offset: offset,
      transpoterId: this.state.transporterValues,
      startDate: this.state.FromDate,
      endDate: this.state.ToDate,
      isExport: true,
    };
    this.props.FetchOnTimeDeliveryExport(values, () => {
      this.setState({
        dataLoading: false,
      });
    });
  };
  getAllTransporterData = () => {
    let filtersHopsData = {
      offset: 0,
      limit: 10,
      q: "",
    };
    this.fetchAllTransporterSearch(filtersHopsData);
  };
  // calling transpoter api
  fetchAllTransporterSearch = (filtersData) => {
    this.props.fetchAllTransporterSearch(
      filtersData.offset,
      filtersData.limit,
      filtersData.q,
      (res) => {
        let transporter = [];
        if (res.data?.docs?.length > 0) {
          res.data.docs.forEach((ele) => {
            transporter.push({
              value: ele._id ? ele._id : "",
              label: ele.name ? ele.name : "",
            });
          });
        }
        this.setState(
          {
            transpoter: transporter,
          },
          () => {}
        );
      }
    );
  };
  enterCheck = (event) => {
    if (event.key === "Enter") this.searchFilter();
  };
  // get transpoter name by filters
  filtersTranspoter = (e) => {
    if (e?.length > 0) {
      let filtersData = {
        offset: this.state.offset,
        limit: this.state.limit,
        q: e,
      };
      this.fetchAllTransporterSearch(filtersData);
    }
    if (e?.length === 0) {
      let filtersData = {
        offset: this.state.offset,
        limit: this.state.limit,
        q: "",
      };
      this.fetchAllTransporterSearch(filtersData);
    }
  };
  handleChange = (dateValues) => {
    // console.log(dateValues)
    this.setState(
      {
        FromDate:dateValues[0]!== null ? moment(dateValues[0]).format("MM-DD-YYYY") : null,
        ToDate: dateValues[1] !== null ? moment(dateValues[1]).format("MM-DD-YYYY") : null,
      },
      () => {
        this.getAllData();
        this.getAllExportData();
      }
    );
  };
  onChangeTranspoter = (selectedTranspoterValues) => {
    // console.log("selectedTranspoterValues",selectedTranspoterValues)
    this.setState(
      {
        selectedTranspoterValues: selectedTranspoterValues,
        transporterValues: selectedTranspoterValues.value,
      },
      () => {
        this.setState({
          dataLoading: true,
        });
        this.getAllData();
        this.getAllExportData();
      }
    );
  };
  handleChangePage = (event, val) => {
    this.setState({ dataLoading : true})
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    toVal = offset + process.env.REACT_APP_PERPAGE;
    let limit = 10;
    this.getAllData();
  };
  reset = () => {
    this.setState(
      { FromDate: "", ToDate: "", transporterValues: [], selectedTranspoterValues: [] , dataLoading : true},
      () => {
        let offset = 0;
        let limit = 10;
        let values = {
          transporterId: this.state.transporterValues,
          startDate: this.state.FromDate,
          endDate: this.state.ToDate,
        };
        this.props.FetchOnTimeDelivery( values,()=>{
            this.setState({
                dataLoading:false
            })
          })
          let valuesExport = {
            offset: offset,
            transpoterId: this.state.transporterValues,
            startDate: this.state.FromDate,
            endDate: this.state.ToDate,
            isExport: true,
          };
          this.props.FetchOnTimeDeliveryExport(valuesExport, () => {
            this.setState({
              dataLoading: false,
            });
          });
      }
    );
  };
  exportToCSV = (csvData, fileName) => {
    // console.log("Calling excel")
    csvData = csvData?.map(function (item) {
      for (var key in item) {
        var upper = key.toUpperCase();
        // check if it already wasn't uppercase
      }
      return item;
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  render() {
    const localStorageData = JSON.parse(
      localStorage.getItem("OntimeDeliveryReports")
    );
    const onTimeDelReportData = navigator?.onLine
      ? this.props.onTimeDeliveryReportData?.data?.docs
      : localStorageData?.data?.docs;
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    darkMode = mode === "dark__mode" ? true : false;
    let onTimeDelivryReports = [];
    let exprotDataReport = [];
    // console.log("onTimeDelReportData",this.props.onTimeDeliveryReportData)
    if (Array.isArray(onTimeDelReportData)) {
      offset = navigator.onLine
        ? this.props.onTimeDeliveryReportData?.data?.offset
        : localStorageData?.data?.offset;
      totCnt = navigator.onLine
        ? this.props.onTimeDeliveryReportData?.data?.length
        : localStorageData?.data?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = navigator.onLine
        ? this.props.onTimeDeliveryReportData?.data?.offset +
          this.props.onTimeDeliveryReportData?.data?.docs?.length
        : localStorageData?.data?.offset + localStorageData?.data?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      // console.log("vehiicleReportData" , vehiicleReportData)

      onTimeDelReportData.forEach((ele, i) => {
        onTimeDelivryReports.push({
          // transporterId: ele?.transporterId ? ele?.transporterId : "",
          region: ele?.region ? ele?.region : "N/A",
          shipmentNumbers: ele?.shipmentNumbers ? ele?.shipmentNumbers : "N/A",
          planGivenDate: ele?.planGivenDate ? ele?.planGivenDate : "N/A",
          sourceOrganisationCode: ele?.sourceOrganisationCode
            ? ele?.sourceOrganisationCode
            :"N/A",
          destinationOrganisationCode: ele?.destinationOrganisationCode
            ? ele?.destinationOrganisationCode
            : "N/A",
          transporter: ele?.transporter ? ele?.transporter :"N/A",
          lRNumbers: ele?.lRNumbers.toString() ? ele?.lRNumbers.toString() : "N/A",
          truckNumber: ele?.truckNumber ? ele?.truckNumber :"N/A",
          dispatchDate: ele?.dispatchDate ? ele?.dispatchDate?.substring(0, ele?.dispatchDate?.length - 5)?.replace('T', ' ') : "N/A",
          leadTime: ele?.leadTime ? ele?.leadTime : "N/A",
          daysInTransit: ele?.daysInTransit ? ele?.daysInTransit : "N/A",
          cfc: ele?.cfc ? ele?.cfc?.toFixed(0) : "N/A",
          reportedDate: ele?.reportedDate ? ele?.reportedDate?.substring(0, ele?.reportedDate?.length - 5)?.replace('T', ' '): "N/A",
          reportedTime: ele?.reportedTime ? ele?.reportedTime?.substring(0, ele?.reportedTime?.length - 5)?.replace('T', ' ') : "N/A",
          unloadingDate: ele?.unloadingDate ? ele?.unloadingDate?.substring(0, ele?.unloadingDate?.length - 5)?.replace('T', ' ') : "N/A",
          unloadingTime: ele?.unloadingTime ? ele?.unloadingTime?.substring(0, ele?.unloadingTime?.length - 5)?.replace('T', ' ') :"N/A",
          transitTime: ele?.transitTime ? 
          <div
          className="tile-text1 text-capitalize p dark__span"
        >
          { ele?.transitTime}
          </div>
          : "N/A",
        });
      });
   
      let exportData = this.props.onTimeDeliveryReportDataExport?.data?.docs;
      if (this.props.onTimeDeliveryReportDataExport !== undefined) {
        if (Array.isArray(exportData)) {
          exportData.forEach((ele, i) => {
            exprotDataReport.push({
              "Sl no":i+1,
              "Region": ele?.region ? ele?.region : "N/A",
              "Shipment Numbers": ele?.shipmentNumbers ? ele?.shipmentNumbers : "",
              "Plan Given Date": ele?.planGivenDate ? ele?.planGivenDate : "",
              "Source Organisation Code": ele?.sourceOrganisationCode
                ? ele?.sourceOrganisationCode
                : "",
              "Destination Organisation Code": ele?.destinationOrganisationCode
                ? ele?.destinationOrganisationCode
                : "",
              "Transporter": ele?.transporter ? ele?.transporter : "",
              "LR Numbers": ele?.lRNumbers.toString() ? ele?.lRNumbers.toString() : "",
              "Truck Number": ele?.truckNumber ? ele?.truckNumber : "",
              "Dispatch Date": ele?.dispatchDate ? ele?.dispatchDate?.substring(0, ele?.dispatchDate?.length - 5)?.replace('T', ' ') : "",
              "Lead Time": ele?.leadTime ? ele?.leadTime : "",
              "Days In Transit": ele?.daysInTransit ? ele?.daysInTransit : "",
              "cfc": ele?.cfc ? ele?.cfc : "",
              "Reported Date": ele?.reportedDate ? ele?.reportedDate?.substring(0, ele?.reportedDate?.length - 5)?.replace('T', ' '): " ",
              "Reported Time": ele?.reportedTime ? ele?.reportedTime?.substring(0, ele?.reportedTime?.length - 5)?.replace('T', ' ') : "",
              "Unloading Date": ele?.unloadingDate ? ele?.unloadingDate?.substring(0, ele?.unloadingDate?.length - 5)?.replace('T', ' ') : "",
              "Unloading Time": ele?.unloadingTime ? ele?.unloadingTime?.substring(0, ele?.unloadingTime?.length - 5)?.replace('T', ' ') : "",
             "Transit Time": ele?.transitTime ? ele?.transitTime : "",
            });
          });
        }
      }
      // console.log("final data vehiclesReports ", vehiclesReports)
    }
    return (
      <React.Fragment>
        {/* {getLocalStorage()} */}
        <div>
          <QuickFilters
            searchFilter={this.searchFilter}
            transporterValues={this.state.transporterValues}
            reset={this.reset}
            selectedTranspoterValues={this.state.selectedTranspoterValues}
            transpoterOptions={this.state.transpoter}
            onChangeTranspoter={this.onChangeTranspoter}
            enterCheck={this.enterCheck}
            onTimeDeliveryReports={exprotDataReport}
            exportToCSV={this.exportToCSV}
            handleChange={this.handleChange}
            mode={darkMode}
            filtersTranspoter={this.filtersTranspoter}
          />
          <Container fluid>
            <Index
                {...this.props}
                headers={HeaderData}
                data={onTimeDelivryReports}
                searchBar={searchBar}
                searchable={false}
                getAllData={this.getAllData}
                fetchId={this.props.fetchId}
                // rowHeight={"56px"}
                dataLoading = {this.state.dataLoading}
              />
            

             {totCnt !== 0 && !this.state.dataLoading ? (
              <Row
                xs={12}
                style={{ color: darkMode ? "#9D9D9D" : "#0C0C0C" }}
                className="mb-2 paginationRow"
              >
                <Col xs={7} sm={5} md={4} className="span-col-pagenation">
                  <div className="float-left">
                    <Pagination
                      className="desktop-pagination"
                      size="medium"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                    <Pagination
                      className="mobile-pagination"
                      size="small"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                  </div>
                </Col>
                <Col
                  className="mt-2 spanPagenationentries span-col-pagenation"
                  xs={5}
                  sm={7}
                  md={8}
                >
                  <span>
                    Showing {fromVal} to {toVal} of {totCnt} entries
                  </span>
                </Col>
              </Row>
            ) : null}  
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  // console.log("state", state);
  return {
    ...state,
    fetchId:state?.OnTimeDeliveryReport?.OnTimeDeliveryReport?.fetchId,
    onTimeDeliveryReportData:
      state?.OnTimeDeliveryReport?.OnTimeDeliveryReport?.onTimeDeliveryReportData,
    onTimeDeliveryReportDataExport:
      state?.OnTimeDeliveryReport?.OnTimeDeliveryReport
        ?.onTimeDeliveryReportExport,
  };
};
export default connect(mapStateToProps, {
  fetchAllTransporterSearch,
  FetchOnTimeDelivery,
  FetchOnTimeDeliveryExport,
})(onTimeDeliveryReport);
