import { types } from "./actionTypes";
export const FetchVehiclePlacementDetailReports = (values, callback) => ({
  type: types.VEHICLE_PLACEMENT_DETAIL_REPORT,
  values,
  callback,
});
export const FetchVehiclePlacementDetailReportsForExcel = (
  values,
  callback
) => ({
  type: types.VEHICLE_PLACEMENT_DETAIL_REPORT_FOR_EXCEL,
  values,
  callback,
});
