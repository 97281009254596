export enum PERMS {
  INDENT_PLANNER_READ = "indent.planner.read",
  INDENT_TRANSPORTER_READ = "indent.transporter.read",
  DRIVER_TRANSPORTER_READ = "driver.transporter.read",
  VEHICLE_TRANSPORTER_READ = "vehicle.transporter.read",
  DASHBOARD_PLANNER_READ = "dashboard.planner.read",
  INDENT_CREATE = "indent.create",
  DRIVER_TRANSPORTER_CREATE = "driver.transporter.create",
  VEHICLE_TRANSPORTER_CREATE = "vrhicle.transporter.create",
  TRIP_READ = "trip.read",
  TRIP_QUALITY_CHECK_COMPLETED_UPDATE = "trip.quality_check_completed_update",
  INVOICE_SUBMITTED_UPDATE = "invoice.submitted.update",
  INVOICE_APPROVAL_PENDING_UPDATE = "invoice.approval_pending.update",
  INVOICE_READ = "invoice.read",
}
