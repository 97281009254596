import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { Card } from "reactstrap";
import { Grid } from "@material-ui/core";

var darkMode;
export default class ApexChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      seriesName: [],
      lagendSeries: "",
      series: [],

      options: {
        chart: {
          type: "bar",
          height: 850,
          width: "100%",
          toolbar: { show: false },
        },
        colors: [
          "#2800FC",
          "#0AB38C",
          "#05284E",
          "#FFFF00",
          "#D35400",
          "#DAF7A6",
          "#6D6589",
          "#896583",
          "#ff4000",
          "#bf00ff",
          "#ff0080",
          "#00ff40"
        ],

        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "45%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          show: false,
        },
        
        legend: {
          show: false,
          margin: "auto",
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: "bottom",
          horizontalAlign: "right",
          floating: false,
          fontSize: "5px",
          fontFamily: "Helvetica, Arial",
          fontWeight: 400,
          formatter: undefined,
          inverseOrder: false,
          width: undefined,
          height: undefined,
          tooltipHoverFormatter: undefined,
          customLegendItems: [],
          offsetX: 0,
          offsetY: 0,
          labels: {
            colors: undefined,
            useSeriesColors: false,
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
          itemMargin: {
            horizontal: 5,
            vertical: 0,
          },
          onItemClick: {
            toggleDataSeries: true,
          },
          onItemHover: {
            highlightDataSeries: true,
          },
        },

        stroke: {
          show: true,
          width: 1,
          colors: ["transparent"],
        },
        xaxis: {
          labels: {
            rotate: 0,
          },
          categories: [
            // 'Acceptance Rate',
            [
              this.props?.userLang?.dashboard?.ACCEPTANCE,
              this.props?.userLang?.dashboard?.RATE,
            ],
            // , 'Rejection Rate',
            [
              this.props?.userLang?.dashboard?.REJECTION,
              this.props?.userLang?.dashboard?.RATE,
            ],
            // 'Late Delivery',
            [
              this.props?.userLang?.dashboard?.LATE,
              this.props?.userLang?.dashboard?.DELIVERY,
            ],
            // 'On time delivery',
            [
              this.props?.userLang?.dashboard?.ON_TIME,
              this.props?.userLang?.dashboard?.DELIVERY,
            ],
            // 'Shortage Percentage ',
            [this.props?.userLang?.dashboard?.SHORTAGE, "%"],
            // 'Damage Percentage '
            [this.props?.userLang?.dashboard?.DAMAGE, "%"],
          ],
          tickAmount: 9,
          tickPlacement: "between",
          axisBorder: {
            show: true,
            color: "#78909C",
            offsetX: -10,
            offsetY: 0,
          },
        },
        yaxis: {
          axisBorder: {
            show: true,
            color: "#78909C",
            offsetX: 0,
            offsetY: +10,
          },
          tickPlacement: "between",
        },
        fill: {
          opacity: 1,
          gradient: {
            inverseColors: false,
            shade: "light",
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        tooltip: {
          theme:"light",
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
      },

      responsive: [
        {
          breakpoint: 600,
          options: {
            chart: {
              height: 90,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
    };
  }
 

  componentDidUpdate = (prevProps) => {
    darkMode = localStorage.getItem("Theme")
    if (prevProps.apiData !== this.props.apiData) {
      let datalabels = this.props?.apiData ? this.props?.apiData : "";
      let size = this.props?.transporterName?.length;
      const items = this.state?.options?.colors.slice(0, size);
      // console.log(datalabels, "trans dashboard", items);
      if (datalabels.length) {
        this.setState({ series: datalabels });
        this.setState({ seriesName: items });
      } else {
        this.setState({
          series: [{ name: "no transporters found", data: [0, 0, 0, 0, 0, 0] }],
        });
        this.setState({ seriesName: [""] });
      }
    }
  };

  render() {
     darkMode = this.props.darkApexChart 
    return (
      <>
        <div
          id="chart cursor-pointer transApexChartParent"
          className=""
          style={{
            cursor: "pointer",
          }}
        >
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="bar"
            height={400}
            // width={800}
          />
        </div>

        <div style={{
          marginTop:"10px",
          paddingLeft:"20px",
          paddingRight:"20px"
        }}>
          <Card className="">
            <div>
              <Grid
              style={{
                paddingLeft:"32px"
              }}
                container
                spacing={2}
                justifyContent="start"
                className={`gridStyle transportCard mobileScroll ${darkMode ? 'transDarkCard' : ''}`}
              >
                {this.state.series?.length > 1
                  ? this.state.seriesName?.map((data, index, array) => (
                      <Grid item xs={6} sm={4 } md={3} key={index} >
                        <div className="transpoter-labels">
                          <div className="d-flex flex-row pt-0 mt-0 ">
                            <div className="pt-1">
                              {
                                <span
                                  key={index}
                                  className="round cricle-shadow"
                                  style={{
                                    backgroundColor: data,
                                    border: `2px solid ${data}`,
                                  }}
                                >
                                  <span className="roundDot"></span>
                                </span>
                              }
                            </div>
                        <div className="">
                              <span  className={` transporterTextStyle ${darkMode ? 'light__span' : ''}`}>
                                {this.props?.transporterName[index]?.replace(
                                  /\w\S*/g,
                                  function (txt) {
                                    return (
                                      txt.charAt(0).toUpperCase() +
                                      txt.substr(1).toLowerCase()
                                    );
                                  }
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    ))
                  :  
                   
                    <span className="fontRoboto1541 d-flex justify-content-center w-100 light__span">
                      Please select hops to display the transpoters
                    </span>
                  }
              </Grid>
            </div>
          </Card>
        </div>
      </>
    );
  }
}
