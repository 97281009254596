import {types } from "./actionTypes";

// creating action
export const getCustomers = (filters, callback) =>{
    return {
        type:types.GET_CUSTOMERS,
        filters,
        callback
    }
}

export const getCustomersExcel = (filters, callback) =>{
    return {
        type:types.GET_CUSTOMERS_EXCEL,
        filters,
        callback
    }
}

export const createCustomer = (payload, callback) =>{
    return {
        type:types.CREATE_CUSTOMER,
        payload,
        callback
    }
}
export const createCustomerViaExcel = (payload, callback) =>{
    return {
        type:types.CREATE_CUSTOMER_VIA_EXCEL,
        payload,
        callback
    }
}
export const updateCustomer = (payload, callback) =>{
    return {
        type:types.UPDATE_CUSTOMER,
        payload,
        callback
    }
}
export const deleteCustomer = (payload, callback) =>{
    return {
        type:types.DELETE_CUSTOMER,
        payload,
        callback
    }
}