import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Container } from "reactstrap";
import { Card, CardBody, Row, Col } from "reactstrap";
import * as  moment from "moment";
import {
  viewOrderLifeCycle,
  viewProductByOrderId,
} from "../../store/baxter-order/actions";
import { Grid } from "@material-ui/core";
import OrderDispatch from "./OrderDispatch";
import InvoiceGenerated from "./InvoiceGenerated";
import OrderPunch from "./OrderPunchRelease";
import OrderTracktimediff from "./OrderTracktimediff";
import { WaveLoader } from "../../components/Common/Loader/LoaderIndex";
import { timeDiff } from "../../components/Common/utils";

let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
class OrderHistory extends Component {
  constructor(props) {
    super();
    this.state = {
      dataLoading: false,
      historyData: "",
      productByOrder: "",
      productLength: "",
    };
  }

  componentDidMount() {
    this.getAllData();

    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 0.9;
  }
  getAllData = () => {
    const { currentOrder } = this.props?.location?.state;
    this.setState({ dataLoading: true }, () => { });
    this.props.viewOrderLifeCycle(currentOrder?._id, true, (res) => {
      this.setState({ dataLoading: false, historyData: res?.data?.docs });
    });
    this.props.viewProductByOrderId(currentOrder?._id, (res) => {
      if (res?.data?.length > 0) {
        const uniqueArr = Array.from(
          new Set(res?.data?.docs?.map((item) => item?.orderLineNo))
        );
        const countProduct = uniqueArr?.length;
        this.setState({
          dataLoading: false,
          productByOrder: res,
          productLength: countProduct,
        });
      }
    });
  };

  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    const bodyElement = document.querySelector('body');
    const darkState = bodyElement.classList.contains('dark__mode');
    const dataBoxStyle = {
      height: "671px",
      overflowY: "scroll",
      overflowX: "clip",
      padding: "3%",
    };
    const oddRowStyle = {
      minHeight: "57px",
      width: "101%",
      backgroundColor: darkState ? "#24243C" : "#ffffff",
      border: "0.5px solid rgba(185, 185, 185, 0.5)",
      borderRadius: "5px",
      margin: "auto",
      marginTop: "4px",

      marginRight: "0px",
      //position:'relative',
    };
    const evenRowStyle = {
      minHeight: "57px",
      width: "101%",
      backgroundColor: darkState ? "#2E2E46" : "#F1F5F7",
      border: "0.5px solid rgba(185, 185, 185, 0.5)",
      borderRadius: "5px",
      margin: "auto",
      marginTop: "4px",

      marginRight: "0px",
      //position:'relative',
    };

    const rowHeaderStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "15px",
      color: darkState ? "#9D9D9D" : "#05284E",
      lineHeight: "18px",
      margin: "auto",
      paddingLeft: "1.5vw",
    };
    const rowSeparaterStyle = {
      borderLeft: "0.5px solid rgba(185, 185, 185, .5)",
      height: "100%",
    };
    const rowValueStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "13px",
      color: darkState ? "#9D9D9D" : "#0C0C0C",
      margin: "auto",
      flex: "wrap",
    };
    const { currentOrder } = this.props?.location?.state;
    const { historyData } = this.state;

    //group by status
    const groupedItems =
      Array.isArray(historyData) &&
      this.state.historyData?.reduce((acc, curr) => {
        // console.log(curr, "cur");
        const { status } = curr;
        if (acc[status]) {
          acc[status].push(curr);

        } else {
          acc[status] = [curr];

        }
        return acc;
      }, {});
    // console.log(groupedItems, "90")
    let dateTimeObjPuch
    let dateTimeObjRelease
    let dateTimeObjInvoiceGenerate
    let dateTimeObjOrderDispatch
    let dateTimeObjOrderDelivered
    const diffTime = Object.entries(groupedItems).map(([key, value]) => {
      if (key === "ORDER_PUNCHED") {

        dateTimeObjPuch = value?.length > 0 ?
          value[0]?.updatedDate && value[0]?.updateTime ?
            moment(`${value[0]?.updatedDate} ${value[0]?.updateTime}`, 'DD-MMM-YYYY HH:mm:ss').toISOString() :
            value[0]?.updatedDateTimeObject ?
              value[0]?.updatedDateTimeObject : ""
          : ""
        // console.log( value[0]?.updatedDate, dateTimeObjPuch, "updatedDateTimeObject1")
      }
      if (key === "ORDER_RELEASED") {

        // dateTimeObjRelease = value[0]?.updatedDateTimeObject;
        dateTimeObjRelease = value?.length > 0 ?
          value[0]?.updatedDate && value[0]?.updateTime ?
            moment(`${value[0]?.updatedDate} ${value[0]?.updateTime}`, 'DD-MMM-YYYY HH:mm:ss').toISOString() :
            value[0]?.updatedDateTimeObject ?
              value[0]?.updatedDateTimeObject : ""
          : ""
        // console.log(key, dateTimeObjRelease, "updatedDateTimeObject2")

      }
      if (key === "INVOICE_GENERATED") {

        // dateTimeObjInvoiceGenerate = value[0]?.updatedDateTimeObject;
        dateTimeObjInvoiceGenerate = value?.length > 0 ?
          value[0]?.acknowledgementDateTimeObject ?
            value[0]?.acknowledgementDateTimeObject :
            value[0]?.acknowledgementDate && value[0]?.acknowledgementTime ?
              moment(`${value[0]?.acknowledgementDate} ${value[0]?.acknowledgementTime}`, 'M/D/YYYY H:mm:ss').toISOString() :
              value[0]?.updatedDate && value[0]?.updateTime ?
                moment(`${value[0]?.updatedDate} ${value[0]?.updateTime}`, 'DD-MMM-YYYY HH:mm:ss').toISOString() :
                "" :
          ""
        // console.log(key, dateTimeObjInvoiceGenerate, "updatedDateTimeObject3")

      }

      if (key === "ORDER_DISPATCHED") {

        // dateTimeObjOrderDispatch = value[0]?.updatedDateTimeObject;
        dateTimeObjOrderDispatch = value?.length > 0 ?
          value[0]?.eWayBillDateTimeObject ?
            value[0]?.eWayBillDateTimeObject :
            value[0]?.eWayBillDate ?
              value[0]?.eWayBillDate :
              value[0]?.updatedDate && value[0]?.updateTime ?
                moment(`${value[0]?.updatedDate} ${value[0]?.updateTime}`, 'DD-MMM-YYYY HH:mm:ss').toISOString() :
                ""
          : ""

        // console.log(key, dateTimeObjRelease, "updatedDateTimeObject3.5")

      }
      if (key === "ORDER_DELIVERED") {

        dateTimeObjOrderDelivered = value[0]?.createdAt;
        // console.log(key, dateTimeObjRelease, "updatedDateTimeObject4")

      }

    })
    let TimeDiffPunchRelease = timeDiff(dateTimeObjPuch, dateTimeObjRelease)
    let TimeDiffReleaseGenerated = timeDiff(dateTimeObjRelease, dateTimeObjInvoiceGenerate)
    let TimeDiffGeneratedDispatch = timeDiff(dateTimeObjInvoiceGenerate, dateTimeObjOrderDispatch)
    let TimeDiffDispatchDelivered = timeDiff(dateTimeObjOrderDispatch, dateTimeObjOrderDelivered)

    // console.log(TimeDiffDispatchDelivered, "TimeDiff")




    // Group by Document Number
    const dataByDocumentNumber1 = Object.entries(groupedItems).map(
      ([key, value]) => {

        if (key === "INVOICE_GENERATED") {

          const documentNumber = {};
          if (Array.isArray(value)) {
            value.forEach((item) => {
              let invoiceNumber = item?.legalInvoiceNo ? item?.legalInvoiceNo : item?.documentNumber;
              if (!documentNumber[invoiceNumber]) {
                documentNumber[invoiceNumber] = [];
              }
              documentNumber[invoiceNumber].push(item);
            });
            return { documentNumber };
          }
        }
      }
    );
    const filteredArray = dataByDocumentNumber1.filter(
      (item) => item !== undefined && item !== null
    );
    const finalDocumentNumber1 = filteredArray?.map((item) => {

      const output = Object.entries(item?.documentNumber).map(
        ([key, value]) => {
          let uniqueOrderLineNo = value?.reduce((accumulator, current) => {
            if (
              !accumulator.find(
                (item) => item?.orderLineNo === current?.orderLineNo
              )
            ) {
              accumulator.push(current);
            }
            return accumulator;
          }, []);
          return {
            id: key,
            values: uniqueOrderLineNo,
          };
        });

      return output;
    });

    // Group by ewaybill Number after grouped status
    const dataByEwayBillNo2 = Object.entries(groupedItems).map(
      ([key, value]) => {

        if (key === "ORDER_DISPATCHED") {
          // console.log(value, "pp");
          const billNumber = {};
          if (Array.isArray(value)) {
            value.forEach((item) => {

              if (!billNumber[item?.eWayBillNo]) {
                billNumber[item?.eWayBillNo] = [];
              }
              billNumber[item?.eWayBillNo].push(item);
            });
            return { billNumber };
          }
        }
      }
    );
    const filteredEwayArray = dataByEwayBillNo2.filter(
      (item) => item !== undefined && item !== null
    );
    const finalEwayBillNo2 = filteredEwayArray?.map((item) => {

      const output = Object.entries(item?.billNumber).map(([key, value]) => {
        let uniqueOrderLineNo = value?.reduce((accumulator, current) => {
          if (
            !accumulator.find(
              (item) => item?.orderLineNo === current?.orderLineNo
            )
          ) {
            accumulator.push(current);
          }
          return accumulator;
        }, []);
        return {
          id: key,
          values: uniqueOrderLineNo,
        };
      });

      return output;
    });

    //count Eway orderItem  and Douciment OrderItem
    const countEway =
      finalEwayBillNo2[0] &&
      finalEwayBillNo2[0].reduce((acc, obj) => acc + obj?.values?.length, 0);
    const countInvoiceItem =
      finalDocumentNumber1[0] &&
      finalDocumentNumber1[0].reduce(
        (acc, obj) => acc + obj?.values?.length,
        0
      );
    // console.log(dataByEwayBillNo2, filteredEwayArray, "dataByEwayBillNo1");
    // console.log(
    //   finalDocumentNumber2,
    //   finalDocumentNumber2,
    //   "finalDocumentNumber"
    // );
    // console.log(countEway, countInvoiceItem, "count");
    return (
      <>
        <div className="orderHistoryModel mt-4 p-2">
          <Container fluid>
            <Row>
              <Col xs={9} className="d-flex flex-row bd-highlight mb-2 ">
                <Button
                  style={{
                    width: "60px",
                    height: "36px",
                    backgroundColor: "#3e3e3e",
                  }}
                  color="secondary"
                  size="sm"
                  onClick={() =>
                    this.props.history.push({
                      pathname: "/ordersList",
                    })
                  }
                >
                  <i className="fas fa-arrow-circle-left fa-2x"></i>
                </Button>
              </Col>
            </Row>
            <Row style={{ width: "104% " }}>
              <div className="p-0 col-1-2 mr-2">
                <Card>
                  <CardBody style={{ height: "100%", overflow: "auto" }} className="barDropDark">
                    <div>
                      <div>
                        <Grid
                          className=" indentData-hover"
                          container
                          style={oddRowStyle}
                        >
                          <Grid
                            // className=""
                            item
                            xs={3}
                            md={6}
                            style={rowHeaderStyle}
                            className="MobilefontRoboto127"
                          >
                            Order Number
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            style={{
                              padding: "2vh 0px",
                            }}
                          >
                            <div style={rowSeparaterStyle}></div>
                          </Grid>
                          <Grid
                            item
                            xs={5}
                            md={5}
                            style={rowValueStyle}
                            className="MobilefontRoboto124"
                          >
                            {currentOrder?.orderNumber}
                          </Grid>
                        </Grid>

                        <Grid
                          className="  shadow indentData-hover"
                          container
                          style={evenRowStyle}
                        >
                          <Grid
                            item
                            xs={3}
                            md={6}
                            style={rowHeaderStyle}
                            className="MobilefontRoboto127"
                          >
                            Order Type
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            style={{
                              padding: "2vh 0px",
                            }}
                          >
                            <div style={rowSeparaterStyle}></div>
                          </Grid>
                          <Grid
                            item
                            xs={5}
                            md={5}
                            style={rowValueStyle}
                            className="MobilefontRoboto124"
                          >
                            {currentOrder?.orderType}
                          </Grid>
                        </Grid>

                        <Grid
                          className="  shadow indentData-hover"
                          container
                          style={oddRowStyle}
                        >
                          <Grid
                            item
                            xs={3}
                            md={6}
                            style={rowHeaderStyle}
                            className="MobilefontRoboto127"
                          >
                            Order Co
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            style={{
                              padding: "2vh 0px",
                            }}
                          >
                            <div style={rowSeparaterStyle}></div>
                          </Grid>
                          <Grid
                            item
                            xs={5}
                            md={5}
                            style={rowValueStyle}
                            className="MobilefontRoboto124"
                          >
                            {currentOrder?.orderCo}
                          </Grid>
                        </Grid>
                        <Grid
                          className="  shadow indentData-hover"
                          container
                          style={evenRowStyle}
                        >
                          <Grid
                            item
                            xs={3}
                            md={6}
                            style={rowHeaderStyle}
                            className="MobilefontRoboto127"
                          >
                            Order Date
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            style={{
                              padding: "2vh 0px",
                            }}
                          >
                            <div style={rowSeparaterStyle}></div>
                          </Grid>
                          <Grid
                            item
                            xs={5}
                            md={5}
                            style={rowValueStyle}
                            className="MobilefontRoboto124"
                          >
                            {currentOrder?.orderDate}
                          </Grid>
                        </Grid>
                        <Grid
                          className="  shadow indentData-hover"
                          container
                          style={oddRowStyle}
                        >
                          <Grid
                            item
                            xs={3}
                            md={6}
                            style={rowHeaderStyle}
                            className="MobilefontRoboto127"
                          >
                            Warehouse
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            style={{
                              padding: "2vh 0px",
                            }}
                          >
                            <div style={rowSeparaterStyle}></div>
                          </Grid>
                          <Grid
                            item
                            xs={5}
                            md={5}
                            style={rowValueStyle}
                            className="MobilefontRoboto124"
                          >
                            {currentOrder?.businessUnit}
                          </Grid>
                        </Grid>

                        <Grid
                          className="  shadow indentData-hover"
                          container
                          style={evenRowStyle}
                        >
                          <Grid
                            item
                            xs={3}
                            md={6}
                            style={rowHeaderStyle}
                            className="MobilefontRoboto127"
                          >
                            Status
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            style={{
                              padding: "2vh 0px",
                            }}
                          >
                            <div style={rowSeparaterStyle}></div>
                          </Grid>
                          <Grid
                            item
                            xs={5}
                            md={5}
                            style={rowValueStyle}
                            className="MobilefontRoboto124 text-bold"
                          >
                            <span
                              className="OrderReleaseCol"
                              style={{ color: "#7701D3" }}
                            >
                              {currentOrder?.status?.replace(/_/g, " ")}
                            </span>
                          </Grid>
                        </Grid>

                        <Grid
                          className="shadow indentData-hover"
                          container
                          style={oddRowStyle}
                        >
                          <Grid
                            item
                            xs={3}
                            md={6}
                            style={rowHeaderStyle}
                            className="MobilefontRoboto127"
                          >
                            <span> Ship To Number </span>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            style={{
                              padding: "2vh 0px",
                            }}
                          >
                            <div style={rowSeparaterStyle}></div>
                          </Grid>
                          <Grid
                            item
                            xs={5}
                            md={5}
                            style={rowValueStyle}
                            className="MobilefontRoboto124"
                          >
                            {currentOrder?.shipToNumber}
                          </Grid>
                        </Grid>
                        <Grid
                          className="shadow indentData-hover"
                          container
                          style={evenRowStyle}
                        >
                          <Grid
                            item
                            xs={3}
                            md={6}
                            style={rowHeaderStyle}
                            className="MobilefontRoboto127"
                          >
                            <span> Total Cost</span>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            style={{
                              padding: "2vh 0px",
                            }}
                          >
                            <div style={rowSeparaterStyle}></div>
                          </Grid>
                          <Grid
                            item
                            xs={5}
                            md={5}
                            style={rowValueStyle}
                            className="MobilefontRoboto124"
                          >
                            {currentOrder?.totalCost}
                          </Grid>
                        </Grid>

                        <Grid
                          className="shadow indentData-hover"
                          container
                          style={oddRowStyle}
                        >
                          <Grid
                            item
                            xs={3}
                            md={6}
                            style={rowHeaderStyle}
                            className="MobilefontRoboto127"
                          >
                            <span>
                              {" "}
                              Order Details Received
                              <span className="text-bold"></span>
                            </span>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            style={{
                              padding: "2vh 0px",
                            }}
                          >
                            <div style={rowSeparaterStyle}></div>
                          </Grid>
                          <Grid
                            item
                            xs={5}
                            md={5}
                            style={rowValueStyle}
                            className="MobilefontRoboto124"
                          >
                            {currentOrder?.orderDetailsReceived}
                          </Grid>
                        </Grid>

                        <Grid
                          className="shadow indentData-hover"
                          container
                          style={evenRowStyle}
                        >
                          <Grid
                            item
                            xs={3}
                            md={6}
                            style={rowHeaderStyle}
                            className="MobilefontRoboto127"
                          >
                            <span> Updated Date</span>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            style={{
                              padding: "2vh 0px",
                            }}
                          >
                            <div style={rowSeparaterStyle}></div>
                          </Grid>
                          <Grid
                            item
                            xs={5}
                            md={5}
                            style={rowValueStyle}
                            className="MobilefontRoboto124"
                          >
                            {currentOrder?.updatedDate}
                          </Grid>
                        </Grid>
                        <Grid
                          className="shadow indentData-hover"
                          container
                          style={oddRowStyle}
                        >
                          <Grid
                            item
                            xs={3}
                            md={6}
                            style={rowHeaderStyle}
                            className="MobilefontRoboto127"
                          >
                            <span> Updated Time</span>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            style={{
                              padding: "2vh 0px",
                            }}
                          >
                            <div style={rowSeparaterStyle}></div>
                          </Grid>
                          <Grid
                            item
                            xs={5}
                            md={5}
                            style={rowValueStyle}
                            className="MobilefontRoboto124"
                          >
                            {currentOrder?.updateTime}
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
              {/* OrderHistory */}
              <div className="p-0 col-1-2">
                <Card>
                  <CardBody style={{ height: "100%" }} className="barDropDark">
                    <div style={dataBoxStyle}>
                      <div
                        style={{ backgroundColor: "#F1F5F7", padding: "4%" }}
                        className="mt-3 darkInputHighlightDiv"
                      >
                        {this.state.dataLoading ? (
                          <WaveLoader />
                        ) : (
                          <>
                            {/*ORDER DISPATCHED ,ORDER DELIVERED*/}
                            {(this.state.historyData?.length > 0 &&
                              currentOrder?.status === "ORDER_DISPATCHED") ||
                              currentOrder?.status === "ORDER_DELIVERED" ? (
                              <OrderDispatch
                                groupedItems={groupedItems}
                                curOrderStatus={currentOrder?.status}
                                finalEwayBillNo2={finalEwayBillNo2}
                                finalDocumentNumber1={finalDocumentNumber1}
                                leadTimeDiff={{ TimeDiffPunchRelease, TimeDiffGeneratedDispatch, TimeDiffReleaseGenerated, TimeDiffDispatchDelivered }}
                                countEway={countEway}
                                countInvoiceItem={countInvoiceItem}
                                darkTheme={darkState}
                                productLength={
                                  this.state.productLength
                                    ? this.state.productLength
                                    : 0
                                }
                              />
                            ) : this.state.historyData?.length > 0 &&
                              currentOrder?.status === "INVOICE_GENERATED" ? (
                              <InvoiceGenerated
                                groupedItems={groupedItems}
                                curOrderStatus={currentOrder?.status}
                                finalEwayBillNo2={finalEwayBillNo2}
                                finalDocumentNumber2={finalDocumentNumber1}
                                countEway={countEway}
                                darkTheme={darkState}
                                leadTimeDiff={{ TimeDiffPunchRelease, TimeDiffGeneratedDispatch, TimeDiffReleaseGenerated }}
                                countInvoiceItem={countInvoiceItem}
                                productLength={
                                  this.state.productLength
                                    ? this.state.productLength
                                    : 0
                                }
                              />
                            ) : (this.state.historyData?.length > 0 &&
                              currentOrder?.status === "ORDER_PUNCHED") ||
                              currentOrder?.status === "ORDER_RELEASED" ? (
                              <OrderPunch
                                groupedItems={groupedItems}
                                curOrderStatus={currentOrder?.status}
                                finalEwayBillNo2={finalEwayBillNo2}
                                finalDocumentNumber2={finalDocumentNumber1}
                                countEway={countEway}
                                darkTheme={darkState}
                                leadTimeDiff={TimeDiffPunchRelease}
                                countInvoiceItem={countInvoiceItem}
                                productLength={
                                  this.state.productLength
                                    ? this.state.productLength
                                    : 0
                                }
                              />
                            ) : (
                              <p>No Record Found</p>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Row>
            <OrderTracktimediff
              groupedItems={groupedItems}
              curOrderStatus={currentOrder?.status}
              finalEwayBillNo2={finalEwayBillNo2}
              finalDocumentNumber1={finalDocumentNumber1}
              leadTimeDiff={{ TimeDiffPunchRelease, TimeDiffGeneratedDispatch, TimeDiffReleaseGenerated, TimeDiffDispatchDelivered }}
              countEway={countEway}
              darkTheme={darkState}
              countInvoiceItem={countInvoiceItem}
              productLength={
                this.state.productLength
                  ? this.state.productLength
                  : 0
              }
            />
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log("state", state);
  return {
    ...state,
    viewOrderHistory: state?.orderListReducer?.viewOrderHistory,
    fetchId: state.orderListReducer?.fetchId,
  };
};
export default connect(mapStateToProps, {
  viewOrderLifeCycle,
  viewProductByOrderId,
})(OrderHistory);
