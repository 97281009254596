export const tripSubStatusMaster = {
    planned: "Planned",
    arrived_at_source: "Arrived At Source",
    quality_check_completed: "Quality Check Completed",
    loading_in_progress: "Loading In Progress",
    in_transit: "In Transit",
    epod_in_progress: "EPOD In Progress",
    completed: "Completed",
    arrived_at_via: "Arrived At Via Point",
    rejected: "Rejected",
    in_progress: "In Progress"
}

export const orderStatusMaster = {
    
    "ORDER_PUNCHED": "ORDER PUNCHED",
    "ORDER_RELEASED": "ORDER RELEASED",
    "INVOICE_GENERATED": "INVOICE GENERATED",
    "ORDER_DISPATCHED": "ORDER DISPATCHED",
    "ORDER_DELIVERED": "ORDER DELIVERED"
}

export const warehouseValueEnum = [
    // {label: "All Warehouse", value: ''},
    {label: "4100030", value: '4100030'},
    {label: "4100040", value: '4100040'},
    {label: "4100060", value: '4100060'},
    {label: "4100270", value: '4100270'},
    {label: "4100370", value: '4100370'},
    {label: "4100520", value: '4100520'},
]

export const tripTypeEnum = [
    { label: "ALL TRIPS", value: 'All' },
    { label: "COURIER", value: "COURIER"},
    { label: "TRUCK", value: "TRUCK"},
];