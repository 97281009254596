import React, { Component } from "react";
import { Row, Col, Card, Button } from "reactstrap";

let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class QuickFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hops: [],
      vehicleTypes: [],
      // searchBar: [],
      searchFilters: {},
      dateValue: [null, null],
      showCalender: false,
      userLangs: undefined,
    };
  }
  componentDidUpdate() {
    if (
      this.state.userLangs === undefined &&
      (userLang !== this.state.userLangs || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  }
  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    return (
      <React.Fragment>
        <Card className="darkBgQuickFilter">
          <div className="quickStylesBar indent-quick-filters darkBgQuickFilter">
            <Row>
              <Col sm={11}>
                <Button
                  onClick={() => this.props.quickFilters("rejected")}
                  className="delayed mr-2 delayDarkStyle"
                >
                  {this.state.userLangs?.common?.REJECTED}
                </Button>
                <Button onClick={() => this.props.reset()} className="reset">
                  {this.state.userLangs?.common?.RESET}
                </Button>
              </Col>
              <Col style={{ textAlignLast: "end" }}>
                <Row>
                  {/* <div style={{ width: "33.33%" }}>
                    <div>
                      <Button
                        onClick={() => {
                          this.setState({
                            showCalender: !this.state.showCalender,
                          });
                        }}
                        className="iconButtons"
                      >
                        <img
                          style={{ margin: "-4px" }}
                          src={calendarIcon}
                          alt="cal"
                        />
                      </Button>
                    </div>
                    {this.state.showCalender ? (
                      <div style={{ float: "right", direction: "rtl" }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <StaticDateRangePicker
                            calendars={1}
                            displayStaticWrapperAs="desktop"
                            format={"MM-DD-YYYY"}
                            value={this.state.dateValue}
                            onChange={(newValue) => {
                              this.setState({ dateValue: newValue }, () => {
                                if (this.state.dateValue[1] !== null) {
                                  this.setState({
                                    showCalender: !this.state.showCalender,
                                  });
                                  this.props.quickFilters(
                                    "date",
                                    this.state.dateValue
                                  );
                                }
                              });
                            }}
                            renderInput={(startProps, endProps) => (
                              <React.Fragment>
                                <TextField {...startProps} />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <TextField {...endProps} />
                              </React.Fragment>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    ) : null}
                  </div> */}
                  {/* <div style={{ width: "33.33%" }}>
                    <Button
                      className="iconButtons"
                      onClick={() => this.props?.onClickAddIndent()}
                    >
                      <img
                        style={{ margin: "-4px" }}
                        src={plusIcon}
                        alt="exp"
                      />
                    </Button>
                  </div>
                  <div style={{ width: "33.33%" }}>
                    <Button
                      disabled={this.props.export ? true : false}
                      onClick={() => this.props.toggleExcel()}
                      className="iconButtons"
                    >
                      <img
                        style={{ margin: "-4px" }}
                        src={exportIcon}
                        alt="exp"
                      />
                    </Button>
                  </div> */}
                </Row>
              </Col>
            </Row>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default QuickFilters;
