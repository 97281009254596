import React, { Component } from "react";
import { Collapse, Container } from "reactstrap";
import { withRouter } from "react-router-dom";

//i18n
import { withNamespaces } from "react-i18next";

import { connect } from "react-redux";
import { fetchRoleGrpPolicies } from "../../store/accessPolicy/actions";
import Option from "./NavOptions/option";
import { menu } from "../../menu";
import { PERMS } from "../../enum/perms.enum";

class Navbar extends Component {
  constructor(props, Policies) {
    super(props, Policies);
    this.state = { policies: [] };
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
    }
  }

  componentDidMount() {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  };
  render() {
    //const { policies,user} = this.props;
    let options = [...menu];

    let data = JSON.parse(window.localStorage.getItem("authUser"));
    let permissions = data.data.permissions;

    options.forEach((opt, j) => {
      // console.log(j, "out");
      // console.log(j, "in");

      opt.subOptions.map((sp, k) => {
        let hasPerm = sp.permissions.every((e) => permissions.includes(e));
        sp.isAllowed = hasPerm;
        return true
      });
      opt.isAllowed = opt.subOptions.some((e) => e.isAllowed);
      if (j === 0) {
        if (permissions.includes(PERMS.DASHBOARD_PLANNER_READ)) {
          opt.isAllowed = true;
        }
      }
    });
    // console.log(options, "optiiii");
    // let featureToggle=user.featureId?user.featureId.featureModules:[];
    return (
      <React.Fragment>
        <div className="topnav">
          <Container fluid>
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
                  {options
                    .filter((e) => e.isAllowed)
                    .map((e, index) => (
                      <Option key={index} data={e} />
                    ))}
                </ul>
              </Collapse>
            </nav>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { leftSideBarType, leftSideBarTheme } = state.Layout;
  const policies = state.AccessPolicy.accessPolicies.policies;
  const user = state.Login.user;
  return { leftSideBarType, leftSideBarTheme, policies, user };
};

export default withRouter(
  connect(mapStatetoProps, { fetchRoleGrpPolicies })(withNamespaces()(Navbar))
);
