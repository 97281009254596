/* LAYOUT */
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const CHANGE_LAYOUT_WIDTH = "CHANGE_LAYOUT_WIDTH";
export const CHANGE_SIDEBAR_THEME = "CHANGE_SIDEBAR_THEME";
export const CHANGE_SIDEBAR_TYPE = "CHANGE_SIDEBAR_TYPE";

// topbar
export const CHANGE_TOPBAR_THEME = "CHANGE_TOPBAR_THEME";

/* RIGHT SIDEBAR */
export const TOGGLE_RIGHT_SIDEBAR = "TOGGLE_RIGHT_SIDEBAR";
export const SHOW_RIGHT_SIDEBAR = "SHOW_RIGHT_SIDEBAR";
export const HIDE_RIGHT_SIDEBAR = "HIDE_RIGHT_SIDEBAR";

// Preloader
export const CHANGE_PRELOADER = "CHANGE_PRELOADER";
export const types = {
  FETCH_INDENTS: "FETCH_INDENTS",
  FETCH_INDENTS_EXCEL: "FETCH_INDENTS_EXCEL",
  FETCH_INDENTS_EXCEL_SUCCESS: "FETCH_INDENTS_EXCEL_SUCCESS",
  FETCH_CREATED_INDENT_INFO: "FETCH_CREATED_INDENT_INFO",
  FETCH_CREATED_INDENT_INFO_SUCCESS: "FETCH_CREATED_INDENT_INFO_SUCCESS",
  FETCH_HOPS_LIST: "FETCH_HOPS_LIST",
  FETCH_HOPS_LIST_SUCCESS: "FETCH_HOPS_LIST_SUCCESS",
  FETCH_INDENTS_SUCCESS: "FETCH_INDENTS_SUCCESS",
  CREATE_INDENT: "CREATE_INDENT",
  CREATE_INDENT_SUCCESS: "CREATE_INDENT_SUCCESS",
  ASSIGN_INDENT: "ASSIGN_INDENT",
  ASSIGN_INDENT_SUCCESS: "ASSIGN_INDENT_SUCCESS",
  REJECT_INDENT: "REJECT_INDENT",
  REJECT_INDENT_SUCCESS: "REJECT_INDENT_SUCCESS",
  APPROVE_INDENT: "APPROVE_INDENT",
  APPROVE_INDENT_SUCCESS: "APPROVE_INDENT_SUCCESS",
  ROUTE_DETAILS: "ROUTE_DETAILS",
  ORDER_DETAILS: "ORDER_DETAILS",
  VEHICLE_DETAILS: "VEHICLE_DETAILS",
  INDENT_UPLOAD: "INDENT_UPLOAD",
  INDENT_UPLOAD_SUCCESS: "INDENT_UPLOAD_SUCCESS",
  CREATE_BULK_INDENT: "CREATE_BULK_INDENT",
  CREATE_BULK_INDENT_SUCCESS: "CREATE_BULK_INDENT_SUCCESS",
  CHANGE_ASSIGNED_INDENT_STATUS: "CHANGE_ASSIGNED_INDENT_STATUS",

  FETCH_INDENTS_ASSIGNED: "FETCH_INDENTS_ASSIGNED",
  FETCH_INDENTS_ASSIGNED_SUCCESS: "FETCH_INDENTS_ASSIGNED_SUCCESS",

  FINALIZE_INDENT: "FINALIZE_INDENT",
  FINALIZE_INDENT_SUCCESS: "FINALIZE_INDENT_SUCCESS",
  INDENT_STATUS_ACCEPT: "INDENT_STATUS_ACCEPT",
  INDENT_STATUS_ACCEPT_SUCCESS: "INDENT_STATUS_ACCEPT_SUCCESS",
  INDENT_STATUS_REJECT: "INDENT_STATUS_REJECT",
  INDENT_STATUS_REJECT_SUCCESS: "INDENT_STATUS_REJECT_SUCCESS",
  INDENT_STATUS_CANCEL: "INDENT_STATUS_CANCEL",
  INDENT_STATUS_CANCEL_SUCCESS: "INDENT_STATUS_CANCEL_SUCCESS",

  // master
  FETCH_INDENTS_FOR_MASTER: "FETCH_INDENTS_FOR_MASTER",
  FETCH_INDENTS_FOR_MASTER_FOR_SUCCESS: "FETCH_INDENTS_FOR_MASTER_FOR_SUCCESS",
};
//   export
// export const FETCH_TRIPS = "FETCH_TRIPS";
