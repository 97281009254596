// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import { types } from "./actionTypes";

import { getApi } from "../../helpers/auth_helper";
// import { getDataGroupBy } from "rsuite/esm/utils";

function* workfetchFreightMaster({
  filters,
  searchFilter,
  callback
}) {
  // console.log("filters",filters)
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : process.env.REACT_APP_PERPAGE;
  let filterString = "";
  if (searchFilter !== null && searchFilter !== undefined) {
    for (const [key, value] of Object.entries(searchFilter)) {
      let filterKey = key;
      let filterValue = value;
      filterString = filterString + "&" + filterKey + "=" + filterValue;
    }
  }
  try {
    const uri = process.env.REACT_APP_SERVERURL +
      `/freight/freights?offset=` +
      offset +
      "&limit=" +
      limit +
      filterString;
    const response = yield call(getApi, uri);
    callback(response);
    yield put({
      type: types.FREIGHTS_MASTER_SUCCESS,
      payload: response,
    });
    localStorage.setItem("fetchFreighMaster", JSON.stringify(response));
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not fetch posts.", error);
  }
}

/**
 * Watchers
 */

export function* watchfetchFreightMaster() {
  yield takeEvery(
    types.FREIGHTS_MASTER,
    workfetchFreightMaster
  );
}


function* FreightMasterSaga() {
  yield all([
    fork(watchfetchFreightMaster),
  ]);
}

export default FreightMasterSaga;
