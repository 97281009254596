import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { Row, Col, Card, CardBody, Container ,Button} from "reactstrap";

export default class ProductInvoiceDetail extends Component {
  render() {
    // console.log(this.props, "prop");

    const rowSeparaterStyle = {
      borderLeft: "0.5px solid rgba(185, 185, 185, .5)",
      height: "311px",
      float: "left",
      marginRight: "18px",
    };
    const rowHeaderStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "15px",
      color: this.props.darkState ? "#9D9D9D" : "#05284E",
      lineHeight: "18px",
      margin: "auto",
      paddingLeft: "1.5vw",
      padding: "2%",
    };
    const rowValueStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "15px",
      color: this.props.darkState ? "#9D9D9D" : "#05284E",
      margin: "auto",
      flex: "wrap",
      padding: "1%",
    };
    return (
      <>
        <Container fluid={true} className="mt-2">
          <Row>
            <Col xs={9} className="d-flex flex-row bd-highlight mb-2 ">
              <Button
                style={{
                  width: "60px",
                  height: "36px",
                  backgroundColor: "#3e3e3e",
                }}
                color="secondary"
                size="sm"
                onClick={() =>
                  this.props.history.goBack()
                }
              >
                <i className="fas fa-arrow-circle-left fa-2x"></i>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xl="12" className="p-1">
              <Card>
                <CardBody style={{ padding: "4%" }} className="darkCardStyle">
                  <>
                    <div className="col-1-2">
                      <Row style={{ width: "100%" }}>
                        <Col md={3} style={rowHeaderStyle}>
                          <span className="light__span">
                            {" "}
                            Order Date <span className="text-bold">:</span>
                          </span>
                        </Col>
                        <Col md={9} style={rowValueStyle} className="dark__span">
                          {
                            this.props?.location?.state?.currentInvoice
                              ?.orderDate
                          }
                        </Col>
                      </Row>
                      <Row style={{ width: "100%" }}>
                        <Col md={3} style={rowHeaderStyle}>
                          <span className="light__span">
                            {" "}
                            Billed To Name <span className="text-bold">:</span>
                          </span>
                        </Col>
                        <Col md={9} style={rowValueStyle} className="dark__span">
                          {
                            this.props?.location?.state?.currentInvoice
                              ?.billToDetails?.billedToName
                          }
                        </Col>
                      </Row>
                      <Row style={{ width: "100%" }}>
                        <Col md={3} style={rowHeaderStyle}>
                          <span className="light__span">
                            {" "}
                            Billed To Address1{" "}
                            <span className="text-bold">:</span>
                          </span>
                        </Col>
                        <Col md={9} style={rowValueStyle} className="dark__span">
                          {
                            this.props?.location?.state?.currentInvoice
                              ?.billToDetails?.billedToAddress1
                          }
                        </Col>
                      </Row>
                      <Row style={{ width: "100%" }}>
                        <Col md={3} style={rowHeaderStyle}>
                          <span className="light__span">
                            {" "}
                            Billed To Address2{" "}
                            <span className="text-bold">:</span>
                          </span>
                        </Col>
                        <Col md={9} style={rowValueStyle} className="dark__span">
                          {
                            this.props?.location?.state?.currentInvoice
                              ?.billToDetails?.billedToAddress2
                          }
                        </Col>
                      </Row>
                      <Row style={{ width: "100%" }}>
                        <Col md={3} style={rowHeaderStyle}>
                          <span className="light__span">
                            {" "}
                            Billed To City <span className="text-bold">:</span>
                          </span>
                        </Col>
                        <Col md={9} style={rowValueStyle} className="dark__span">
                          {
                            this.props?.location?.state?.currentInvoice
                              ?.billToDetails?.billedToCity
                          }
                        </Col>
                      </Row>
                      <Row style={{ width: "100%" }}>
                        <Col md={3} style={rowHeaderStyle}>
                          <span className="light__span">
                            {" "}
                            Billed To City <span className="text-bold">:</span>
                          </span>
                        </Col>
                        <Col md={9} style={rowValueStyle} className="dark__span">
                          {
                            this.props?.location?.state?.currentInvoice
                              ?.billToDetails?.billedToCity
                          }
                        </Col>
                      </Row>
                    </div>
                    <div style={rowSeparaterStyle}></div>
                    <div className="col-1-2 ml-1">
                      <Row style={{ width: "100%" }}>
                        <Col md={4} style={rowHeaderStyle}>
                          <span className="light__span">
                            {" "}
                            Billed To State <span className="text-bold">:</span>
                          </span>
                        </Col>
                        <Col md={8} style={rowValueStyle} className="dark__span">
                          {
                            this.props?.location?.state?.currentInvoice
                              ?.billToDetails?.billedToStateName
                          }
                        </Col>
                      </Row>

                      <Row style={{ width: "100%" }}>
                        <Col md={4} style={rowHeaderStyle}>
                          <span className="light__span">
                            {" "}
                            Billed To Destination{" "}
                            <span className="text-bold">:</span>
                          </span>
                        </Col>
                        <Col md={8} style={rowValueStyle} className="dark__span">
                          {
                            this.props?.location?.state?.currentInvoice
                              ?.billToDetails?.billedToDestination
                          }
                        </Col>
                      </Row>
                      <Row style={{ width: "100%" }}>
                        <Col md={4} style={rowHeaderStyle}>
                          <span className="light__span">
                            {" "}
                            Billed To Zip <span className="text-bold">:</span>
                          </span>
                        </Col>
                        <Col md={8} style={rowValueStyle} className="dark__span">
                          {
                            this.props?.location?.state?.currentInvoice
                              ?.billToDetails?.billedToZip
                          }
                        </Col>
                      </Row>
                      <Row style={{ width: "100%" }}>
                        <Col md={4} style={rowHeaderStyle}>
                          <span className="light__span">
                            {" "}
                            Billed To Country{" "}
                            <span className="text-bold">:</span>
                          </span>
                        </Col>
                        <Col md={8} style={rowValueStyle} className="dark__span">
                          {
                            this.props?.location?.state?.currentInvoice
                              ?.billToDetails?.billedToCountry
                          }
                        </Col>
                      </Row>
                      <Row style={{ width: "100%" }}>
                        <Col md={4} style={rowHeaderStyle}>
                          <span className="light__span">
                            {" "}
                            Billed To GSTN No{" "}
                            <span className="text-bold">:</span>
                          </span>
                        </Col>
                        <Col md={8} style={rowValueStyle} className="dark__span">
                          {
                            this.props?.location?.state?.currentInvoice
                              ?.billToDetails?.billedToGSTNNo
                          }
                        </Col>
                      </Row>
                    </div>
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
