import React, { useState } from "react";

export const CheckBoxItem = (props) => {
  const [isChecked, setIsChecked] = useState(false);

  React.useEffect(() => {
    let arraySI = Array.from(props?.selectedInvoices?.keys());
    if (arraySI.includes(props?.item?._id)) {
      setIsChecked(true);
    } else setIsChecked(false);
  }, []);

  const handleCheckboxChange = () => {
    Array.from(props?.selectedInvoices.keys())?.includes(props?.item?._id)
      ? setIsChecked(true)
      : setIsChecked(false);
  };

  return (
    <label>
      <input
        type="checkbox"
        name="checkbox"
        className={`square-checkbox ${props?.isDisabled ? "isChecked" : ""}`}
        checked={isChecked}
        onChange={handleCheckboxChange}
        disabled={props?.isDisabled}
        onClick={(e) => props.SelectedInvoices(props?.item, e)}
      />
    </label>
  );
};
