import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
//medthods
import {
  getApi,
  getApiNoToken,
  patchApi,
  postApi,
} from "../../helpers/auth_helper";

// workers

function* workerGetOrders({
  offset,
  limit,
  customerCode,
  cfaCodes,
  topFilters,
  filters,
  callback,
}) {
  // console.log(filters,"filter")
  let orderNumber = filters?.hasOwnProperty("Order No")
    ? filters["Order No"]
    : "";
  let orderType = filters?.hasOwnProperty("Order Type")
    ? filters["Order Type"]
    : "";
  let orderCo = filters?.OrderCo ? filters?.OrderCo : "";
  let id = filters?.Id ? filters?.Id : "";
  let orderFromDate = filters?.FromDate ? filters?.FromDate : "";
  let ordertoDate = filters?.ToDate ? filters?.ToDate : "";
  let businessUnit = filters?.warehouse ? filters?.warehouse : "";
  let status = filters?.Status ? filters?.Status : "";
  let backOrders = filters?.isBackOrder ? filters?.isBackOrder : "";
  let cancelledOrder = filters?.isCancelledOrder
    ? filters?.isCancelledOrder
    : "";
  let sortBy = " ";
  let customerCodes = customerCode ? customerCode : "";
  let cfaCode = cfaCodes ? cfaCodes : "";
  let billedToName = filters?.hasOwnProperty("billed To Name")
    ? filters["billed To Name"]
    : "";
  let billedToCity = filters?.hasOwnProperty("billed To City")
    ? filters["billed To City"]
    : "";
  let billedToStateName = filters?.hasOwnProperty("billed To State")
    ? filters["billed To State"]
    : "";
  // console.log(filters,"custSaga")
  if (status?.length) {
    try {
      const url = `${process.env.REACT_APP_SERVERURL}/order/orders?offset=${offset}&limit=${limit}&orderNumber=${orderNumber}&orderType=${orderType}&orderCo=${orderCo}&id=${id}&businessUnit=${businessUnit}&orderFromDate=${orderFromDate}&orderToDate=${ordertoDate}&sortBy=${sortBy}&orderStatus=${status}&billedToName=${billedToName}&isBackOrder=${backOrders}&isCancelledOrder=${cancelledOrder}&customerCodes=${customerCodes}&cfaCodes=${cfaCode}&billedToCity=${billedToCity}&billedToStateName=${billedToStateName}`;
      const response = yield call(getApi, url);
      callback(response);
      localStorage.setItem(
        "OrdersData",
        response ? JSON.stringify(response) : null
      );
      yield put({
        type: types.GET_ORDERS_SUCCESS,
        payload: response,
      });
    } catch (e) {
      // console.log(e);
    }
  } else {
    try {
      const url = `${process.env.REACT_APP_SERVERURL}/order/orders?offset=${offset}&limit=${limit}&orderNumber=${orderNumber}&orderType=${orderType}&orderCo=${orderCo}&id=${id}&businessUnit=${businessUnit}&orderFromDate=${orderFromDate}&orderToDate=${ordertoDate}&sortBy=${sortBy}&billedToName=${billedToName}&isBackOrder=${backOrders}&isCancelledOrder=${cancelledOrder}&customerCodes=${customerCodes}&cfaCodes=${cfaCode}&billedToCity=${billedToCity}&billedToStateName=${billedToStateName}`;
      const response = yield call(getApi, url);
      callback(response);
      localStorage.setItem(
        "OrdersData",
        response ? JSON.stringify(response) : null
      );
      yield put({
        type: types.GET_ORDERS_SUCCESS,
        payload: response,
      });
    } catch (e) {
      // console.log(e);
    }
  }
}
function* workerGetOrdersForExcel({
  offset,
  limit,
  customerCode,
  cfaCodes,
  topFilters,
  filters,
  callback,
}) {
  // console.log(filters,"filter")
  let orderNumber = filters?.hasOwnProperty("Order No")
    ? filters["Order No"]
    : "";
  let orderType = filters?.hasOwnProperty("Order Type")
    ? filters["Order Type"]
    : "";
  let orderCo = filters?.OrderCo ? filters?.OrderCo : "";
  let id = filters?.Id ? filters?.Id : "";
  let orderFromDate = filters?.FromDate ? filters?.FromDate : "";
  let ordertoDate = filters?.ToDate ? filters?.ToDate : "";
  let businessUnit = filters?.warehouse ? filters?.warehouse : "";
  let status = filters?.Status ? filters?.Status : "";
  let backOrders = filters?.isBackOrder ? filters?.isBackOrder : "";
  let cancelledOrder = filters?.isCancelledOrder
    ? filters?.isCancelledOrder
    : "";
  let sortBy = " ";
  let customerCodes = customerCode ? customerCode : "";
  let cfaCode = cfaCodes ? cfaCodes : "";
  let billedToName = filters?.hasOwnProperty("billed To Name")
    ? filters["billed To Name"]
    : "";
  let billedToCity = filters?.hasOwnProperty("billed To City")
    ? filters["billed To City"]
    : "";
  // console.log(filters,"custSaga")
  if (status?.length) {
    try {
      const url = `${process.env.REACT_APP_SERVERURL}/order/orders?offset=${offset}&limit=${limit}&orderNumber=${orderNumber}&orderType=${orderType}&orderCo=${orderCo}&id=${id}&businessUnit=${businessUnit}&orderFromDate=${orderFromDate}&orderToDate=${ordertoDate}&sortBy=${sortBy}&orderStatus=${status}&billedToName=${billedToName}&isBackOrder=${backOrders}&isCancelledOrder=${cancelledOrder}&customerCodes=${customerCodes}&cfaCodes=${cfaCode}&billedToCity=${billedToCity}`;
      const response = yield call(getApi, url);
      callback(response);
      localStorage.setItem(
        "OrdersData",
        response ? JSON.stringify(response) : null
      );
      yield put({
        type: types.GET_ORDERS_FOR_EXCEL_SUCCESS,
        payload: response,
      });
    } catch (e) {
      // console.log(e);
    }
  } else {
    try {
      const url = `${process.env.REACT_APP_SERVERURL}/order/orders?offset=${offset}&limit=${limit}&orderNumber=${orderNumber}&orderType=${orderType}&orderCo=${orderCo}&id=${id}&businessUnit=${businessUnit}&orderFromDate=${orderFromDate}&orderToDate=${ordertoDate}&sortBy=${sortBy}&billedToName=${billedToName}&isBackOrder=${backOrders}&isCancelledOrder=${cancelledOrder}&customerCodes=${customerCodes}&cfaCodes=${cfaCode}&billedToCity=${billedToCity}`;
      const response = yield call(getApi, url);
      callback(response);
      localStorage.setItem(
        "OrdersData",
        response ? JSON.stringify(response) : null
      );
      yield put({
        type: types.GET_ORDERS_FOR_EXCEL_SUCCESS,
        payload: response,
      });
    } catch (e) {
      // console.log(e);
    }
  }
}
function* workerGetOrderProducts({ offset, limit, searchFilter, callback }) {
  // console.log(searchFilter,"seacrchfilter")
  const keyMap = {
    "Order Number": "orderNumber",
    "Order Line No": "orderLineNo",
    "Order Type": "orderType",
    "Quantity Ordered": "quantityOrdered",
    // Add more key-value pairs as needed
  };
  let filterString = "";
  if (searchFilter !== null && searchFilter !== undefined) {
    for (const [key, value] of Object.entries(searchFilter)) {
      let filterKey = keyMap[key] || key;
      let filterValue = value;
      filterString = filterString + "&" + filterKey + "=" + filterValue;
    }
  }

  try {
    const url =
      process.env.REACT_APP_SERVERURL +
      "/order/products?offset=" +
      offset +
      "&limit=" +
      limit +
      filterString;

    // console.log(url,"url")
    const response = yield call(getApi, url);
    callback(response);
    localStorage.setItem(
      "OrderProductsData",
      response ? JSON.stringify(response) : null
    );
    yield put({
      type: types.GET_ORDERPRODUCT_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}

function* workerViewOrderById({ filters, Id, callback }) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  try {
    const url =
      process.env.REACT_APP_SERVERURL +
      `/order/order/${Id}?offset=` +
      offset +
      "&limit=" +
      limit;
    const response = yield call(getApi, url);
    callback(response);
    localStorage.setItem(
      "OrderProductsData",
      response ? JSON.stringify(response) : null
    );
    yield put({
      type: types.VIEW_ORDERSBY_ID_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerViewProductByOrderId({ Id, callback }) {
  try {
    const url =
      process.env.REACT_APP_SERVERURL + `/order/products?orderId=${Id}`;
    const response = yield call(getApi, url);
    callback(response);
    localStorage.setItem(
      "productByOrder",
      response ? JSON.stringify(response) : null
    );
    yield put({
      type: types.VIEW_PRODUCTBY_ORDERID_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}

function* workerViewInvoiceByOrderId({ Id, callback }) {
  try {
    const url =
      process.env.REACT_APP_SERVERURL + `/order/invoices?orderId=${Id}`;
    const response = yield call(getApi, url);
    callback(response);
    localStorage.setItem(
      "InvoiceByOrderId",
      response ? JSON.stringify(response) : null
    );
    yield put({
      type: types.VIEW_INVOICEBY_ORDERID_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}

function* workerViewOrderLife({ Id, paginationStatus, callback }) {
  try {
    const url =
      process.env.REACT_APP_SERVERURL +
      `/order/history?disablePagination=${paginationStatus}&orderId=${Id}`;
    const response = yield call(getApi, url);
    callback(response);
    localStorage.setItem(
      "OrderHistory",
      response ? JSON.stringify(response) : null
    );
    yield put({
      type: types.VIEW_ORDER_LIFE_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}

function* workerTrackOrder({ Id, callback }) {
  try {
    const url = process.env.REACT_APP_SERVERURL + `/tracking/baxterOrder/${Id}`;
    const response = yield call(getApi, url);
    callback(response);
    localStorage.setItem(
      "TrackOrderBaxter",
      response ? JSON.stringify(response) : null
    );
    yield put({
      type: types.TRACK_ORDER_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}

function* workerReSyncOrder({ Id, callback }) {
  try {
    const url = process.env.REACT_APP_SERVERURL + `/sync/reSyncOrder`;
    const response = yield call(postApi, url, Id);
    callback(response);
    localStorage.setItem(
      "SyncedOrder",
      response ? JSON.stringify(response) : null
    );
    yield put({
      type: types.RESYNC_ORDER_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}

function* workerCloseTrip({ Value, callback }) {
  try {
    const url =
      process.env.REACT_APP_SERVERURL + `/trip/baxter/customerTripCompleted`;
    const response = yield call(patchApi, url, Value);
    callback(response);

    yield put({
      type: types.CLOSE_TRIP_SUCCESS,
      payload: response,
    });
  } catch (e) {}
}
function* workerOrderTrackingByEwayBill({ Value, callback }) {
  try {
    // console.log('worker')
    const url =
      process.env.REACT_APP_SERVERURL +
      `/tracking/baxterOrderByEWayBillNo/${Value}`;
    const response = yield call(getApi, url);
    callback(response);

    yield put({
      type: types.ORDER_TRACKING_BY_EWAYBILL_SUCCESS,
      payload: response,
    });
  } catch (e) {}
}

// watchers

export function* watchGetOrders() {
  yield takeEvery(types.GET_ORDERS, workerGetOrders);
}
export function* watchGetOrdersForExcel() {
  yield takeEvery(types.GET_ORDERS_FOR_EXCEL, workerGetOrdersForExcel);
}
export function* watchGetOrderProducts() {
  yield takeEvery(types.GET_ORDERPRODUCT, workerGetOrderProducts);
}

export function* watchViewOrderDetailById() {
  yield takeEvery(types.VIEW_ORDERSBY_ID, workerViewOrderById);
}
export function* watchViewProductByOrderId() {
  yield takeEvery(types.VIEW_PRODUCTBY_ORDERID, workerViewProductByOrderId);
}
export function* watchViewInvoiceByOrderId() {
  yield takeEvery(types.VIEW_INVOICEBY_ORDERID, workerViewInvoiceByOrderId);
}

export function* watchViewOrderLife() {
  yield takeEvery(types.VIEW_ORDER_LIFE, workerViewOrderLife);
}

export function* watchTrackOrder() {
  yield takeEvery(types.TRACK_ORDER, workerTrackOrder);
}
export function* watchReSyncOrder() {
  yield takeEvery(types.RESYNC_ORDER, workerReSyncOrder);
}
export function* watchCloseTrip() {
  yield takeEvery(types.CLOSE_TRIP, workerCloseTrip);
}
export function* watchOrderTrackingByEwayBill() {
  yield takeEvery(
    types.ORDER_TRACKING_BY_EWAYBILL,
    workerOrderTrackingByEwayBill
  );
}

function* orderListSaga() {
  yield all([
    fork(watchGetOrders),
    fork(watchGetOrdersForExcel),
    fork(watchGetOrderProducts),
    fork(watchViewOrderDetailById),
    fork(watchViewProductByOrderId),
    fork(watchViewInvoiceByOrderId),
    fork(watchViewOrderLife),
    fork(watchTrackOrder),
    fork(watchReSyncOrder),
    fork(watchCloseTrip),
    fork(watchOrderTrackingByEwayBill),
  ]);
}
export default orderListSaga;
