// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import { types } from "./actionTypes";

import { postApi} from "../../helpers/auth_helper";




function* workFetchLoadOptimizationData({
  values,
  callback
}) {
  try {
    const uri = "https://load-optimization.dev.cloudtrack.in/report";
    const response = yield call(postApi, uri, values);
    callback(response);
    yield put({
      type: types.LOAD_OPTIMIZATION_DATA_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.countlog("Request failed! Could not fetch posts.", error);
  }
}


/**
 * Watchers
 */


export function* watchFetchLoadOptimizationData() {
  yield takeEvery(
    types.LOAD_OPTIMIZATION_DATA,
    workFetchLoadOptimizationData
  );
}

function* loadOptimizationSaga() {
  yield all([
    fork(watchFetchLoadOptimizationData),
  ]);
}

export default loadOptimizationSaga;
