import { types } from "./actionTypes";
export const fetchOrders = (offset, limit, callback) => ({
  type: types.FETCH_ORDERS,
  offset,
  limit,
  callback,
});
export const getOrdersByPagination = (callback, offset = 0, limit = 100) => ({
  type: types.GET_ORDERS_BY_PAGINATION,
  offset,
  limit,
  callback,
});
export const fetchOrdersfilter = (offset, filters, key, callback) => ({
  type: types.FETCH_FILTER_ORDERS,
  offset,
  filters,
  key,
  callback,
});
export const fetchOrder = (id, callback) => ({
  type: types.FETCH_ORDER,
  id,
  callback,
});
export const fetchProductNamesByOrderId_DealerCode = (
  callback,
  searchString,
  key
) => {
  return {
    type: types.FETCH_PRODUCT_NAMES_BY_PRODUCT_ID_DEALER_CODE,
    searchString,
    key,
    callback,
  };
};
export const fetchProductNames = (callback) => ({
  type: types.FETCH_PROD_NAMES,
  callback,
});
export const fetchOrderDelivered = (callback) => ({
  type: types.FETCH_ORDER_DELIVERED,
  callback,
});
// export const fetchPost = id => ({ type: types.FETCH_POST, id });
export const createOrder = (values, callback) => ({
  type: types.CREATE_ORDER,
  values,
  callback,
});
export const deleteOrder = (id, callback) => ({
  type: types.DELETE_ORDER,
  id,
  callback,
});
export const uploadOrder = (values, callback) => ({
  type: types.UPLOAD_ORDER,
  values,
  callback,
});
export const getOrderByExternalId = (id, callback) => ({
  type: types.GET_ORDERS_BY_EXTERNAL_ID,
  id,
  callback,
});
export const getConfirmOrderDetails = (values, callback) => ({
  type: types.GET_CONFIRM_ORDERS,
  values,
  callback,
});
export const confirmOrder = (values, callback) => ({
  type: types.CONFIRM_ORDER,
  values,
  callback,
});
export const fetchOrdersbyId = (filter, key, callback) => ({
  type: types.GET_ORDERS_BY_ID,
  filter,
  key,
  callback,
});
