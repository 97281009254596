import {
  FORGET_USER,
  SEND_OTP,
  CONFIRM_PASSWORD,
  FORGET_USER_SUCCESSFUL,
  FORGET_PASSWORD_API_FAILED,
} from "./actionTypes";
export const sendOtp = (values, callback) => {
  return {
    type: SEND_OTP,
    values,
    callback,
  };
};
export const verifyOtp = (values, callback) => {
  return {
    type: FORGET_USER,
    values,
    callback,
  };
};
export const sendNewPassword = (values, callback) => {
  return {
    type: CONFIRM_PASSWORD,
    values,
    callback,
  };
};

export const forgetUserSuccessful = (message) => {
  return {
    type: FORGET_USER_SUCCESSFUL,
    payload: message,
  };
};

export const userForgetPasswordError = (error) => {
  return {
    type: FORGET_PASSWORD_API_FAILED,
    payload: error,
  };
};
