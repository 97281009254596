export const types = {
  GET_INVOICELIST: "GET_INVOICELIST",
  GET_INVOICELIST_SUCCESS: "GET_INVOICELIST_SUCCESS",
  GET_INVOICELIST_FOR_EXCEL: "GET_INVOICELIST_FOR_EXCEL",
  GET_INVOICELIST_FOR_EXCEL_SUCCESS: "GET_INVOICELIST_FOR_EXCEL_SUCCESS",
  CHECK_TRIP_CREATION: "CHECK_TRIP_CREATION",
  CHECK_TRIP_CREATION_SUCCESS: "CHECK_TRIP_CREATION_SUCCESS",
  CREATE_TRIP_BAXTER: "CREATE_TRIP_BAXTER",
  CREATE_TRIP_SUCCESS_BAXTER: "CREATE_TRIP_SUCCESS_BAXTER",

  CANCEL_INVOICE :"CANCEL_INVOICE",
  CANCEL_INVOICE_SUCCESS :"CANCEL_INVOICE_SUCCESS",

  ADD_CHARGES: "ADD_CHARGES",
  ADD_CHARGES_SUCCESS: "ADD_CHARGES_SUCCESS",
};
