// import { mapKeys } from "lodash";
import { types } from "../actionTypes";

const initialState = {
  vehicles: [],
  fetchId: "",
};

export default (state = { initialState }, action) => {
  switch (action.type) {
    case types.FETCH_AVAIL_VEHICLES_SUCCESS:
      return { avail_vehicles: action.payload };
    case types.FETCH_VEHICLE_BYID_SUCCESS:
      return { ...state, editVehicle: action.payload };
    case types.EDIT_VEHICLE_BYID_SUCCESS:
      return action.payload;
    case types.CREATE_VEHICLE_SUCCESS:
      return { ...state, newVehicle: action.payload };
    case types.DELETE_VEHICLE_SUCCESS:
      var newState = { ...state };
      delete newState[action.payload.id];
      return newState;
    case types.FETCH_VEHICLES_TYPE_SUCCESS:
      return action.payload;
    case types.FETCH_VEHICLES_SUCCESS:
      // console.log(action.payload);
      return { ...state, vehicles: action.payload ,fetchId: "id" + new Date().getTime() };

    default:
      state = { ...state };
      break;
  }
  return state;
};
