// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import { types } from "./actionTypes";

import {
  getApi,
  postApi,
  postUploadApi,
  patchApi,
} from "../../helpers/auth_helper";

function* workfetchIndents({ offset, filters, callback }) {
  console.log("saga");
  try {
    let filterString = "";
    for (const [key, value] of Object.entries(filters)) {
      let filterKey = key;
      let filterValue = value;
      if (filterKey === "startTime") {
        // console.log(value, "-", key);
        filterString =
          filterString +
          "&" +
          "startTimeStart=" +
          filterValue[0] +
          "&startTimeEnd=" +
          filterValue[1];
      } else if (filterKey === "expiryTime") {
        // console.log(value, "-", key);
        filterString =
          filterString +
          "&" +
          "expiryTimeStart=" +
          filterValue[0] +
          "&expiryTimeEnd=" +
          filterValue[1];
      } else filterString = filterString + "&" + filterKey + "=" + filterValue;
      // console.log(filterString, "FILETR-SAGA");
    }
    // Try to call the API

    const uri =
      process.env.REACT_APP_SERVERURL +
      `/indent/created/searchList?offset=` +
      offset +
      `&limit=` +
      Number(process.env.REACT_APP_PERPAGE) +
      filterString;
    const response = yield call(getApi, uri);
    //  console.log(response,"here saga indent")
    // callback(response);

    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_INDENTS_SUCCESS,
      payload: response,
    });
    callback(response);
    localStorage.setItem("IndentData", JSON.stringify(response));
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch posts.", error);
  }
}
function* workfetchIndentsExcel({ offset, filters, callback }) {
  try {
    let filterString = "";
    for (const [key, value] of Object.entries(filters)) {
      let filterKey = key;
      let filterValue = value;
      if (filterKey === "startTime") {
        // console.log(value, "-", key);
        filterString =
          filterString +
          "&" +
          "startTimeStart=" +
          filterValue[0] +
          "&startTimeEnd=" +
          filterValue[1];
      } else if (filterKey === "expiryTime") {
        // console.log(value, "-", key);
        filterString =
          filterString +
          "&" +
          "expiryTimeStart=" +
          filterValue[0] +
          "&expiryTimeEnd=" +
          filterValue[1];
      } else filterString = filterString + "&" + filterKey + "=" + filterValue;
      // console.log(filterString, "FILETR-SAGA");
    }
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/indent/created/searchList?offset=` +
      offset +
      // `&limit=` +
      // Number(process.env.REACT_APP_PERPAGE) +
      `&limit=10000000` +
      filterString;
    const response = yield call(getApi, uri);
    //  console.log(response,"here saga indent")
    callback(response);

    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_INDENTS_EXCEL_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch posts.", error);
  }
}
// function* workfetchIndentsExcel({
//   offset,
//   limit = Number(process.env.REACT_APP_PERPAGE),
//   filters,
//   searchKey,
//   searchValue,
//   radioFilter,
// }) {
//   try {
//     // Try to call the API
//     console.log("success");
//     const uri =
//       process.env.REACT_APP_SERVERURL +
//       `/indent/created/search?offset=` +
//       offset +
//       `&limit=` +
//       limit +
//       `&filters=` +
//       filters +
//       `&searchKey=` +
//       searchKey +
//       `&searchValue=` +
//       searchValue +
//       `&duration=` +
//       radioFilter;
//     const response = yield call(getApi, uri);
//     //  console.log(response,"here saga indentexcel")
//     // callback(response);

//     //   Dispatch the action to the reducers
//     yield put({
//       type: types.FETCH_INDENTS_EXCEL_SUCCESS,
//       payload: response,
//     });
//   } catch (error) {
//     // Act on the error
//     console.log("Request failed! Could not fetch posts.", error);
//   }
// }
function* workfetchCreatedIndentInfo({ payload, callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/indent/${payload.indentID}`;
    const response = yield call(getApi, uri);
    //  console.log(response,"here saga indent")
    callback(response);

    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_CREATED_INDENT_INFO_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch posts.", error);
  }
}

function* workfetchAssignedIndents({ offset, filters, callback }) {
  try {
    let filterString = "";
    for (const [key, value] of Object.entries(filters)) {
      let filterKey = key;
      let filterValue = value;
      if (filterKey === "startTime") {
        // console.log(value, "-", key);
        filterString =
          filterString +
          "&" +
          "startTimeStart=" +
          filterValue[0] +
          "&startTimeEnd=" +
          filterValue[1];
      } else if (filterKey === "expiryTime") {
        // console.log(value, "-", key);
        filterString =
          filterString +
          "&" +
          "expiryTimeStart=" +
          filterValue[0] +
          "&expiryTimeEnd=" +
          filterValue[1];
      } else filterString = filterString + "&" + filterKey + "=" + filterValue;
      // console.log(filterString, "FILETR-SAGA");
    }
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/indent/created/searchList?offset=` +
      offset +
      `&limit=` +
      Number(process.env.REACT_APP_PERPAGE) +
      filterString;
    const response = yield call(getApi, uri);
    //  console.log(response,"here saga indent")
    // callback(response);

    //   Dispatch the action to the reducers

    yield put({
      type: types.FETCH_INDENTS_ASSIGNED_SUCCESS,
      payload: response,
    });
    callback(response);
    localStorage.setItem("indentAssignData", JSON.stringify(response));
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch posts.", error);
  }
}
function* workfetchHops({ callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/hop/filterhop`;
    const response = yield call(getApi, uri);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_HOPS_LIST_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch hops.", error);
  }
}
function* workUploadIndent({ values, callback }) {
  try {
    // Try to call the API
    let file = values;
    // console.log("values", file);
    const uri = process.env.REACT_APP_SERVERURL + `/indent/validate/bulk`;
    const response = yield call(postUploadApi, uri, file);
    // console.log("response", response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.INDENT_UPLOAD_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not create indent.", error);
  }
}
function* workCreateIndent({ values, callback }) {
  try {
    // console.log("values", values);
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/indent/create`;
    const response = yield call(postApi, uri, values);
    // console.log("response", response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.CREATE_INDENT_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not create indent.", error);
  }
}
function* workCreateBulkIndent({ values, callback }) {
  try {
    // console.log('values', values);
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/indent/create/bulkDraft`;
    const response = yield call(postApi, uri, values);
    // console.log("response", response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.CREATE_BULK_INDENT_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not create indent.", error);
  }
}
function* workAssignIndent({ values, callback }) {
  try {
    //console.log('values', values);
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL + `/indent/update/` + values.indent;
    const response = yield call(patchApi, uri, values);
    //console.log('response',response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.ASSIGN_INDENT_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not create trip.", error);
  }
}
function* workRejectIndent({ values, callback }) {
  try {
    // console.log('values', values);
    // Try to call the API
    // let { indentStatus, description } = values;
    let transporterDetail = values.transporterDetail;
    const uri =
      process.env.REACT_APP_SERVERURL + `/indent/update/` + values.indent;
    // console.log('response',uri,transporterDetail);
    const response = yield call(patchApi, uri, { transporterDetail });
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.REJECT_INDENT_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not create trip.", error);
  }
}
function* workApproveIndent({ values, callback }) {
  try {
    // console.log('values', values);
    // Try to call the API
    // let { indentStatus, description } = values;
    let transporterDetail = values.transporterDetail;
    const uri =
      process.env.REACT_APP_SERVERURL + `/indent/update/` + values.indent;
    // console.log('response',uri,transporterDetail);
    const response = yield call(patchApi, uri, { transporterDetail });
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.APPROVE_INDENT_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not create trip.", error);
  }
}

function* workFinalizeIndent({ values, callback }) {
  try {
    const uri = process.env.REACT_APP_SERVERURL + `/indent/finalize/`;
    const response = yield call(postApi, uri, values);
    callback(response);
    yield put({
      type: types.FINALIZE_INDENT_SUCCESS,
      payload: response,
    });
  } catch (error) {
    console.log("Request failed! Could not finalize Indent.", error);
  }
}

function* workIndentStatusAccept({ value, callback }) {
  try {
    // console.log(value,"value")
    // return false;
    const uri = process.env.REACT_APP_SERVERURL + `/indent/accept`;
    const response = yield call(postApi, uri, value);
    const modresponse = { response: response, indentID: value.indentID };
    callback(Response);
    yield put({
      type: types.INDENT_STATUS_ACCEPT_SUCCESS,
      payload: modresponse,
    });
  } catch (error) {
    console.log("Request failed! Could not accept Indent.", error);
  }
}

function* workIndentStatusReject({ values, callback }) {
  try {
    const uri = process.env.REACT_APP_SERVERURL + `/indent/reject`;
    // console.log(values);
    const response = yield call(postApi, uri, values);

    callback(response);
    yield put({
      type: types.INDENT_STATUS_REJECT_SUCCESS,
      payload: response,
    });
  } catch (error) {
    console.log("Request failed! Could not reject Indent.", error);
  }
}
function* workIndentStatusCancel({ values, callback }) {
  try {
    const uri = process.env.REACT_APP_SERVERURL + `/indent/cancel`;
    // console.log(values);
    const response = yield call(postApi, uri, values);
    // console.log(response);
    callback(response);
    yield put({
      type: types.INDENT_STATUS_CANCEL_SUCCESS,
      payload: response,
    });
  } catch (error) {
    console.log("Request failed! Could not cancel Indent.", error);
  }
}
// masters
function* workfetchIndentsForMaster({ filters, callback }) {
  let offset = filters?.offset ? filters?.offset : 0;
  let indentPlanningCode = filters?.indentPlanningCode
    ? filters?.indentPlanningCode
    : "";
  let source = filters?.source ? filters?.source : "";
  let destination = filters?.destination ? filters?.destination : "";
  let transporter = filters?.transporter ? filters?.transporter : "";
  let validityFrom = filters?.validityFrom ? filters?.validityFrom : "";
  let validityTo = filters?.validityTo ? filters?.validityTo : "";
  let kms = filters?.kms ? filters?.kms : "";
  let laneLot = filters?.laneLot ? filters?.laneLot : "";
  let leadTime = filters?.leadTime ? filters?.leadTime : "";
  let lotNo = filters?.lotNo ? filters?.lotNo : "";
  let targetIndents = filters?.targetIndents ? filters?.targetIndents : "";
  let actualIndents = filters?.actualIndents ? filters?.actualIndents : "";
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/indentplanning/indentPlannings?offset=` +
      offset +
      `&limit=` +
      Number(process.env.REACT_APP_PERPAGE) +
      "&indentPlanningCode=" +
      indentPlanningCode +
      "&source=" +
      source +
      "&destination=" +
      destination +
      "&transporter=" +
      transporter +
      "&validityStartDate=" +
      validityFrom +
      "&validityEndDate=" +
      validityTo +
      "&kms=" +
      kms +
      "&laneLot=" +
      laneLot +
      "&leadTime=" +
      leadTime +
      "&lotNo=" +
      lotNo +
      "&targetIndents=" +
      targetIndents +
      "&actualIndents=" +
      actualIndents;
    const response = yield call(getApi, uri);
    //  console.log(response,"here saga indent")
    // callback(response);

    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_INDENTS_FOR_MASTER_FOR_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch posts.", error);
  }
}

export function* watchFetchHops() {
  yield takeEvery(types.FETCH_HOPS_LIST, workfetchHops);
}
export function* watchFetchIndents() {
  yield takeEvery(types.FETCH_INDENTS, workfetchIndents);
}

export function* watchFetchIndentsExcel() {
  yield takeEvery(types.FETCH_INDENTS_EXCEL, workfetchIndentsExcel);
}
export function* watchFetchCreatedIndentInfo() {
  yield takeEvery(types.FETCH_CREATED_INDENT_INFO, workfetchCreatedIndentInfo);
}

export function* watchFetchAssignedIndents() {
  yield takeEvery(types.FETCH_INDENTS_ASSIGNED, workfetchAssignedIndents);
}
export function* watchCreateIndent() {
  yield takeEvery(types.CREATE_INDENT, workCreateIndent);
}
export function* watchCreateBulkIndent() {
  yield takeEvery(types.CREATE_BULK_INDENT, workCreateBulkIndent);
}
export function* watchUploadIndent() {
  yield takeEvery(types.INDENT_UPLOAD, workUploadIndent);
}
export function* watchAssignIndent() {
  yield takeEvery(types.ASSIGN_INDENT, workAssignIndent);
}
export function* watchRejectIndent() {
  yield takeEvery(types.REJECT_INDENT, workRejectIndent);
}
export function* watchApproveIndent() {
  yield takeEvery(types.APPROVE_INDENT, workApproveIndent);
}
export function* watchFinalizeIndent() {
  yield takeEvery(types.FINALIZE_INDENT, workFinalizeIndent);
}
export function* watchIndentStatusAccept() {
  yield takeEvery(types.INDENT_STATUS_ACCEPT, workIndentStatusAccept);
}
export function* watchIndentStatusReject() {
  yield takeEvery(types.INDENT_STATUS_REJECT, workIndentStatusReject);
}
export function* watchIndentStatusCancel() {
  yield takeEvery(types.INDENT_STATUS_CANCEL, workIndentStatusCancel);
}
//masters
export function* watchFetchIndentsForMasters() {
  yield takeEvery(types.FETCH_INDENTS_FOR_MASTER, workfetchIndentsForMaster);
}
function* IndentSaga() {
  yield all([
    fork(watchFetchHops),
    fork(watchCreateIndent),
    fork(watchCreateBulkIndent),
    fork(watchUploadIndent),
    fork(watchFetchIndents),
    fork(watchFetchIndentsExcel),
    fork(watchAssignIndent),
    fork(watchRejectIndent),
    fork(watchApproveIndent),
    fork(watchFinalizeIndent),
    fork(watchIndentStatusAccept),
    fork(watchIndentStatusReject),
    fork(watchFetchAssignedIndents),
    fork(watchFetchCreatedIndentInfo),
    fork(watchIndentStatusCancel),
    //master
    fork(watchFetchIndentsForMasters),
  ]);
}

export default IndentSaga;
