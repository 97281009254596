import React, { Component } from "react";
import { connect } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import { Row, Col, Container } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { NotificationManager } from "react-notifications";
//actions
import { truckUtilizationReport,truckUtilizationReportExcel } from "../../store/baxter-report/actions";
import Index from "../../components/Common/DataTableNeo/Index";
import {
  AdminHeaderData,
  getLocalStorage,
  searchBar,
} from "./RowHeaders/RowHeaders";
import QuickFilters from "./quickFilters";
import moment from "moment";
import { exportToCSV } from "../common/exportToCsv";

var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var limit = 10;
var searchValues = {};
var darkTheme;
class TruckUtilization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dataLoading: false,
      offset: 0,
      searchFilters: {},
    };
  }

  componentDidMount() {
    this.getAllData();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 0.9;
  }
  getAllData = () => {
    this.setState({ dataLoading: true }, () => { });
    this.props.truckUtilizationReport(
      { offset: offset, limit: 10 },
      this.state.searchFilters,
      (res) => {
        this.setState({ dataLoading: false });
      }
    );
    // this.setState({ dataLoading: false });
  };
  componentWillUnmount = () => {
    offset = 0;
  };
  getAllDataReset = () => {
    offset = 0;
    // this.getAllData();
  };
  handleChangePage = (event, val) => {
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    this.setState({ loading: true });
    this.getAllData();
  };
  handleChange = (dateValues) => {
    searchValues["fromTripCreationDate"] =
      dateValues[0] !== null
        ? dateValues[0]
          ? moment(dateValues[0]).format("MM-DD-YYYY")
          : moment(dateValues).format("MM-DD-YYYY")
        : null;
    searchValues["toTripCreationDate"] =
      dateValues[1] !== null
        ? moment(dateValues[1]).format("MM-DD-YYYY")
        : null;
    this.setState({ searchFilters: searchValues }, () => {
      offset = 0;
      this.getAllData();
    });
  };

  searchValueAssignerFunction = (name, value, type) => {
    if (typeof string === "text" || type === "text") {
      searchValues[name] = value?.replace(/^\s+/, "");
      this.setState({ searchFilters: searchValues }, () => { });
    }

    if (type === "date") {
      offset = 0;
      searchValues[name] = value;
      this.setState({ searchFilters: searchValues }, () => {
        this.getAllData();
      });
    }
  };
  reset = () => {
    offset = 0;
    searchValues = {};
    this.setState({ searchFilters: {} }, () => {
      this.getAllData();
    });
  };

  // exportExcelData = () => {
  //   this.setState({ dataLoading: true });
  //   // console.log("kjrfreifde")
  //   this.props.truckUtilizationReportExcel(
  //     { offset: 0, limit: 10000 },
  //     this.state.searchFilters,
  //     (res) => {
  //       console.log(res)
  //       this.setState({ dataLoading: false });
  //       let exportData = [];
  //       if (res?.data?.docs?.length > 0) {
  //         res.data.docs.map((ele) => {

  //           exportData.push({
  //             tripId: ele?.tripId ? ele?.tripId : "N/A",
  //             orderNumber: ele?.orderNumber
  //               ? ele.orderNumber.map((orderNumber, index) => (
  //                 <div key={index}>{orderNumber}</div>
  //               ))
  //               : "N/A",
  //             invoiceNumber: ele?.legalInvoiceNo
  //               ? ele.legalInvoiceNo.map((invoiceNumber, index) => (
  //                 <div key={index}>{invoiceNumber}</div>
  //               ))
  //               : "N/A",
  //             source: ele?.source ? ele?.source : "N/A",
  //             destination: ele?.destination ? ele?.destination : "N/A",
  //             warehouseNumber: ele?.warehouseNumber ? ele?.warehouseNumber : "N/A",
  //             transporter: ele?.transporter ? ele?.transporter : "N/A",
  //             truckCapacity: ele?.truckCapacityInMT ?? (
                
  //                 (ele?.truckCapacityInMT?.toFixed(2))
                
  //             ),
  //             truckUtilizationInPercent:
  //               ele?.truckCapacityInMT !== 0
  //                 ? (ele?.truckUtilizationInMT / ele?.truckCapacityInMT) * 100
  //                 : 0,
  //             //product Moved
  //             totalTruckUtilizationInMT: ele?.truckUtilizationInMT
  //               ? ele?.truckUtilizationInMT?.toFixed(2)
  //               : "N/A",
  //             tripCreationDate: (
  //             moment(ele?.tripCreationDate).format("DD-MMM-YYYY")
  //             ),
  //           });
  //         });
  //       }
  //       if (exportData?.length > 0) {
  //         exportToCSV([...exportData], "Truck Utilization Report");
  //       }
  //     }
  //   );

  // };

  render() {
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    darkTheme = mode === "dark__mode" ? true : false;

    const { truckUtilizationReportData } = this.props;
    const localStorageData = JSON.parse(localStorage.getItem("LeadTimeReport"));
    const truckUtilizationData = navigator.onLine
      ? truckUtilizationReportData?.data?.docs
      : localStorageData?.data?.docs;
    let rows = [];
    if (Array.isArray(truckUtilizationData)) {
      totCnt = navigator.onLine
        ? truckUtilizationReportData?.data?.length
        : localStorageData?.data?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = navigator.onLine
        ? truckUtilizationReportData?.data?.offset +
        truckUtilizationReportData?.data?.docs?.length
        : localStorageData?.data?.offset + localStorageData?.data?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      truckUtilizationData.forEach((ele) => {
        rows.push({
          tripId: ele?.tripId ? ele?.tripId : "N/A",
          orderNumber: ele?.orderNumber
            ? ele.orderNumber.map((orderNumber, index) => (
              <div key={index}>{orderNumber}</div>
            ))
            : "N/A",
          invoiceNumber: ele?.legalInvoiceNo
            ? ele.legalInvoiceNo.map((invoiceNumber, index) => (
              <div key={index}>{invoiceNumber}</div>
            ))
            : "N/A",
          source: ele?.source ? ele?.source : "N/A",
          destination: ele?.destination ? ele?.destination : "N/A",
          warehouseNumber: ele?.warehouseNumber ? ele?.warehouseNumber : "N/A",
          transporter: ele?.transporter ? ele?.transporter : "N/A",
          truckCapacity: ele?.truckCapacityInMT ?? (
            <div style={{ textAlign: "center" }}>
              {ele?.truckCapacityInMT?.toFixed(2)}
            </div>
          ),
          truckUtilizationInPercent:
            ele?.truckCapacityInMT !== 0
              ? (ele?.truckUtilizationInMT / ele?.truckCapacityInMT) * 100
              : 0,
          //product Moved
          totalTruckUtilizationInMT: ele?.truckUtilizationInMT
            ? ele?.truckUtilizationInMT?.toFixed(2)
            : "N/A",
          tripCreationDate: (
            <div>{moment(ele?.tripCreationDate).format("DD-MMM-YYYY")}</div>
          ),
        });
      });
    }
    return (
      <>
        {getLocalStorage()}
        <QuickFilters 
        // toggleExcel={this.exportExcelData} 
        reset={this.reset} handleChange={this.handleChange} />
        <Container
          aria-hidden="true"
          fluid
          className={`${darkTheme ? "darkBackgroundList mobilepPadding" : "mobilepPadding"
            }`}
        >
          <div className="orderList">
            <Index
              {...this.props}
              headers={AdminHeaderData}
              data={rows}
              searchable={false}
              searchBar={searchBar}
              fetchId={this.props.fetchId}
              reset={this.reset}
              searchValueAssignerFunction={this.searchValueAssignerFunction}
              getAllData={this.getAllDataReset}
              dataLength={90}
              rowHeight={"60px"}
              dataLoading={this.state.dataLoading}
              dashboard="true"
              classList="3"
              textInput="inputIndex"
              resetCol="12"
            />
          </div>

          {totCnt !== 0 && !this.state.dataLoading ? (
            <Row
              xs={12}
              style={{ color: darkTheme ? "#9d9d9d" : "#0C0C0C" }}
              className="mb-2 paginationRow"
            >
              <Col xs={7} sm={5} md={4} className="span-col-pagenation">
                <div className="float-left">
                  <Pagination
                    className="desktop-pagination"
                    size="medium"
                    shape="rounded"
                    page={offset / 10 + 1}
                    count={totPages}
                    color="primary"
                    onChange={this.handleChangePage}
                    defaultPage={1}
                    siblingCount={1}
                  />
                  <Pagination
                    className="mobile-pagination"
                    size="small"
                    shape="rounded"
                    page={offset / 10 + 1}
                    count={totPages}
                    color="primary"
                    onChange={this.handleChangePage}
                    defaultPage={1}
                    siblingCount={1}
                  />
                </div>
              </Col>
              <Col
                className="mt-2 spanPagenationentries span-col-pagenation"
                xs={5}
                sm={7}
                md={8}
              >
                {" "}
                <span>
                  Showing {fromVal} to {toVal} of {totCnt} entries
                </span>
              </Col>
            </Row>
          ) : null}
        </Container>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
    truckUtilizationReportData:
      state?.dispatchLeadTimeReportReducer?.truckUtilizationReportData,
    fetchId: state.dispatchLeadTimeReportReducer?.fetchId,
  };
};

export default connect(mapStateToProps, {
  truckUtilizationReport,
  truckUtilizationReportExcel
})(TruckUtilization);
