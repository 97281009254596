import React, { Component } from "react";
import { Form, FormGroup, Label, Input, Button, Container } from "reactstrap";
import TrackingMap from "../TrackingMap";
import { Modal, ModalHeader, ModalBody, CardBody, Row, Col } from "reactstrap";
import { Box, Grid, Card } from "@material-ui/core";
import UpdatePhoneOnTrack from "./UpdatePhoneOnTrack";
import dotGroup from "./assets/dot.png";
import { connect } from "react-redux";
import { trackOrders } from "../../store/baxter-order/actions";
import { getTripList } from "../../store/baxter-trip/actions";
import { WaveLoader } from "../../components/Common/Loader/LoaderIndex";
import moment from "moment";
import { unset } from "lodash";
import xml2js from "xml2js";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
class OrderTrackModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dataLoading: false,
      TripAddress: [],
      trackingType: "",
      tripCords: "",
      sourceCoordinates: "",
      currentLocation: "",
      destinationCoordinates: "",
      trackAllData: "",
      blueDartData: "",
      updatePhoneInput: "",
      isPhoneUpdateOpen: false,
    };
  }
  componentDidMount = () => {
    this.getAllData();
  };

  getCommonTracking = (trackingType, trackingData) => {
    let trackTripPath = [];
    this.setState(
      { trackingType: trackingType, trackAllData: trackingData },
      () => {
        const { trackingType, trackAllData } = this.state;
        if (trackingType === 3) {
          if (
            Array.isArray(trackAllData?.data?.trackingData) &&
            trackAllData?.data?.trackingData?.length > 0
          ) {
            var trackAddress = trackAllData?.data?.trackingData?.map((ele) => {
              let date = moment(ele["updatedAt"]).format("MM-DD-YYYY");
              let time = moment(ele["updatedAt"]).format("HH:mm:ss");
              let address = ele?.locationDescription
                ? ele?.locationDescription
                : "";
              let coordinates = ele?.coordinates ? ele?.coordinates : "";
              let status = "in_transit";
              return {
                date: date,
                time: time,
                address: address,
                coordinates: coordinates,
                status: status,
              };
            });

            // console.log(trackAddress, "trackTripPath");
            trackTripPath.push(trackAddress);

            this.setState({
              TripAddress: trackTripPath,
              dataLoading: false,
            });
          } else {
            this.setState({ dataLoading: false });
            // console.log("no data");
          }
        }
        if (trackingType === 2) {
          let xmlJsonData = [];
          const parser = new xml2js.Parser({
            explicitArray: false,
            explicitRoot: false,
            trim: true,
          });
          parser.parseString(
            trackAllData?.data?.trackingData,
            (error, result) => {
              if (error) {
                console.error(error, "xmlss");
              } else {
                xmlJsonData = Array.isArray(result?.Shipment?.Scans)
                  ? result?.Shipment?.Scans
                  : [result?.Shipment?.Scans];
              }
            }
          );
          if (xmlJsonData?.length > 0) {
            var blurDart = xmlJsonData?.[0]?.ScanDetail?.map((ele) => {
              let status = ele?.Scan;
              let statusDate = moment(ele["ScanDate"]).format("DD-MMM-YYYY");
              let statusTime = ele?.ScanTime ? ele["ScanTime"] : "";
              let location = ele?.ScannedLocation ? ele["ScannedLocation"] : "";
              return {
                date: statusDate,
                time: statusTime,
                status: status,
                location: location,
              };
            });

            this.setState({ blueDartData: blurDart, dataLoading: false });
          } else {
            this.setState({ dataLoading: false });
          }
        }
        if (trackingType === 4) {
          if (Array.isArray(trackAllData?.data?.trackingData)) {
            var dnxCourier = trackAllData?.data?.trackingData?.map((ele) => {
              this.setState({ dnxCourierData: ele, dataLoading: false });
            });
          } else {
            this.setState({ dnxCourierData: "", dataLoading: false });
          }
        }
        if (trackingType === 1) {
          // SAFEXPRESS PRIVATE LTD
          if (trackAllData?.data?.trackingData) {
            var blurDart = trackAllData?.data?.trackingData?.tracking?.map(
              (ele) => {
                let status = ele?.status;
                let statusDate = moment(ele["date"]).format("DD-MMM-YYYY");
                let statusTime = moment(ele["date"]).format("hh:mm A");

                return {
                  date: statusDate,
                  time: statusTime,
                  status: status,
                };
              }
            );
            this.setState({ blueDartData: blurDart, dataLoading: false });
          } else {
            this.setState({ dataLoading: false });
          }
        }
      }
    );
  };

  getAllData = () => {
    // console.log("TRIP ID", this.props.tripTrackId, "====", this.props);
    let TripObjId = this.props?.history?.location?.state;
    let trackAllData = this.props?.history?.location?.data;
    let pathKey = this.props?.history?.location?.pathKey;
    console.log(
      TripObjId,
      trackAllData?.data?.trackingType,
      pathKey,
      "TripObjIdTripObjId"
    );

    let searchFilters = { _id: TripObjId };
    /// From Order  Track Order
    if (trackAllData && pathKey === "Ordertracking") {
      this.getCommonTracking(trackAllData?.data?.trackingType, trackAllData);
    }
    /// trip Track Order
    this.setState({ dataLoading: true }, () => {});
    this.props.trackOrders(TripObjId, (res) => {
      if (res && res?.success === true) {
        this.getCommonTracking(res?.data?.trackingType, res);
      }
      this.setState({ dataLoading: false });
    });
    this.props.getTripList(
      {
        offset: 0,
        limit: 10,
      },
      searchFilters,
      (res) => {
        // console.log(res, "restrip");
        const status = "in_transist";
        if (Array.isArray(res?.data?.docs)) {
          const tripCords = res?.data?.docs.filter(
            (ele) =>
              ele?.subStatus === "in_transit" || ele?.subStatus === "completed"
          );
          let sourceCord = tripCords?.map((src) => {
            if (Array.isArray(src?.source)) {
              src.source = src?.source?.[0];
            }
            return {
              lat: parseFloat(src?.source?.lattitude),
              lng: parseFloat(src?.source?.longitude),
            };
          });
          let destCord = tripCords?.map((src) => {
            if (Array.isArray(src?.destination)) {
              src.destination = src?.destination?.[0];
            }
            return {
              lat: parseFloat(src?.destination?.lattitude),
              lng: parseFloat(src?.destination?.longitude),
            };
          });
          let currentCord = tripCords?.map((src) => {
            return {
              lat: src?.latestKnownLocation
                ? parseFloat(src?.latestKnownLocation?.coordinates?.lat)
                : "",
              lng: src?.latestKnownLocation
                ? parseFloat(src?.latestKnownLocation?.coordinates?.lng)
                : "",
            };
          });

          this.setState(
            {
              dataLoading: false,
              tripCords: tripCords,
              sourceCoordinates: sourceCord,
              destinationCoordinates: destCord ? destCord : "",
              currentLocation: currentCord,
              updatePhoneInput:
                tripCords?.length > 0 ? tripCords[0]?.driver?.phone : "",
            },
            () => {
              // console.log("sourceCoordinates _", this.state.sourceCoordinates);
              let triptrackData = [
                {
                  date: "",
                  time: "",
                  address: "Source",
                  status: "in_transit",
                  coordinates: {
                    lat: this.state.sourceCoordinates[0]?.lat,
                    lng: this.state.sourceCoordinates[0]?.lng,
                  },
                },
              ];

              let TripAddress = this.state.TripAddress;

              let triptrackDataStateCopy = [];
              if (this.state.TripAddress && this.state.TripAddress?.length) {
                triptrackDataStateCopy = [
                  ...triptrackData,
                  ...this.state.TripAddress[0],
                ];
              }

              TripAddress[0] = triptrackDataStateCopy;
              this.setState({ TripAddress: TripAddress });
            }
          );
        }
      }
    );
  };
  phoneUpdateToggle = () => {
    this.setState({
      isPhoneUpdateOpen: !this.state.isPhoneUpdateOpen,
    });
  };
  render() {
    const { trackingType, trackAllData } = this.state;
    let reverseArray =
      this.state.TripAddress[0]?.length > 0
        ? [...this.state.TripAddress[0]]?.reverse()
        : [];
    let coordArr =
      this.state.TripAddress[0] !== undefined &&
      this.state.TripAddress[0]?.map((ele) => {
        // console.log(ele, "90");
        return {
          lat: parseFloat(ele?.coordinates?.lat),
          lng: parseFloat(ele?.coordinates?.lng),
        };
      });
    // console.log("TRACKING COORDINATES/////////////",coordArr)
    // console.log(coordArr, "coordArr");
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    let darkState = mode === "dark__mode" ? true : false;
    const dataBoxStyle = {
      height: "761px",
      overflowY: "scroll",
      overflowX: "clip",
      padding: "3%",
    };
    const oddRowStyle = {
      minHeight: "57px",
      width: "101%",
      backgroundColor: darkState ? "#24243C" : "#ffffff",
      border: "0.5px solid rgba(185, 185, 185, 0.5)",
      borderRadius: "5px",
      margin: "auto",
      marginTop: "4px",

      marginRight: "0px",
      //position:'relative',
    };
    const evenRowStyle = {
      minHeight: "57px",
      width: "101%",
      backgroundColor: darkState ? "#2E2E46" : "#F1F5F7",
      border: "0.5px solid rgba(185, 185, 185, 0.5)",
      borderRadius: "5px",
      margin: "auto",
      marginTop: "4px",

      marginRight: "0px",
      //position:'relative',
    };

    const rowHeaderStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "15px",
      color: darkState ? "#9D9D9D" : "#05284E",
      lineHeight: "18px",
      margin: "auto",
      paddingLeft: "1.5vw",
    };
    const rowSeparaterStyle = {
      borderLeft: "0.5px solid rgba(185, 185, 185, .5)",
      height: "100%",
    };
    const rowValueStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "13px",
      color: darkState ? "#9D9D9D" : "#0C0C0C",
      margin: "auto",
      flex: "wrap",
    };

    const { currentOrder } = this.props;
    return (
      <div className="orderHistoryModel">
        <Container fluid={true} className="mt-2">
          <Row>
            <Col xs={9} className="d-flex flex-row bd-highlight mb-2 ">
              <Button
                style={{
                  width: "60px",
                  height: "36px",
                  backgroundColor: "#3e3e3e",
                }}
                color="secondary"
                size="sm"
                onClick={() => this.props.history.goBack()}
              >
                <i className="fas fa-arrow-circle-left fa-2x"></i>
              </Button>
            </Col>
          </Row>
          {this.state.dataLoading ? (
            <WaveLoader />
          ) : (
            <Row>
              <div className="p-0 col-1-2 mr-3">
                <Card>
                  <CardBody>
                    <div style={dataBoxStyle}>
                      <div>
                        <Box sx={{ flexGrow: 1 }}>
                          <Grid container spacing={2} className="mb-4">
                            <Grid item xs={4}>
                              <span
                                className={`trackHeading ${
                                  darkState ? "light__span" : ""
                                }`}
                              >
                                TripId :
                              </span>
                              <span
                                className={`trackValuetext ml-3 ${
                                  darkState ? "dark__span" : ""
                                }`}
                              >
                                {this.state.tripCords?.length
                                  ? this.state.tripCords[0]?.tripId
                                  : ""}
                              </span>
                            </Grid>
                            {trackingType === 3 ? (
                              <Grid item xs={8}>
                                <span
                                  className={`trackHeading ${
                                    darkState ? "light__span" : ""
                                  }`}
                                >
                                  Consent Status :{" "}
                                </span>
                                <span
                                  className={`trackValuetext ${
                                    darkState ? "dark__span" : ""
                                  }`}
                                >
                                  {this.state.tripCords?.length
                                    ? this.state.tripCords[0] &&
                                      trackingType === 3
                                      ? this.state.tripCords[0]
                                          ?.driverConsentStatusFromServiceProvider
                                      : ""
                                    : ""}
                                </span>
                              </Grid>
                            ) : (
                              ""
                            )}
                            <Grid item xs={4} className="mt-2">
                              <span
                                className={`trackHeading ${
                                  darkState ? "light__span" : ""
                                }`}
                              >
                                Driver Name:
                              </span>
                              <span
                                className={`trackValuetext  ml-3 ${
                                  darkState ? "dark__span" : ""
                                }`}
                              >
                                {this.state.tripCords?.length
                                  ? this.state.tripCords[0]?.driver?.name
                                  : ""}
                              </span>
                            </Grid>
                            <Grid item xs={8}>
                              <span
                                className={`trackHeading ${
                                  darkState ? "light__span" : ""
                                }`}
                              >
                                Driver Phone Number :
                              </span>
                              <span
                                className={`trackValuetext rounded-blue  p-2 ml-3`}
                              >
                                {this.state.tripCords?.length
                                  ? this.state.tripCords[0]?.driver?.phone
                                    ? this.state.tripCords[0]?.driver?.phone
                                    : "No Data"
                                  : ""}
                              </span>
                              <span
                                className={`trackValuetext ml-3 ${
                                  darkState ? "dark__span" : ""
                                }`}
                              >
                                <Button
                                  color="primary orderDriverBtn"
                                  size="sm"
                                  onClick={this.phoneUpdateToggle}
                                >
                                  Update Phone{" "}
                                </Button>
                              </span>
                            </Grid>
                            <Grid item xs={4}></Grid>
                            <div className="ivrDiv">
                              <div className="driverConsentDarkdiv d-flex flex-row">
                                <div className="pr-3 ">
                                  <span>
                                    <svg
                                      width="15"
                                      height="15"
                                      viewBox="0 0 15 15"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14Z"
                                        stroke="#E53D2C"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M7.5 10.1V7.5"
                                        stroke="#E53D2C"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M7.5 4.90039H7.505"
                                        stroke="#E53D2C"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </span>
                                </div>
                                <div>
                                  <span className="fontRoboto12 dark__span">
                                    IVR Process – applicable for Airtel,
                                    Vodafone & Bsnl only Common IVR MSISDN : -
                                    7303777719 After calling user must press “1”
                                    to complete the consent.
                                  </span>
                                </div>
                              </div>
                              <div className="driverConsentDarkdiv d-flex flex-row">
                                <div className="pr-3 ">
                                  <span>
                                    <svg
                                      width="15"
                                      height="15"
                                      viewBox="0 0 15 15"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14Z"
                                        stroke="#E53D2C"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M7.5 10.1V7.5"
                                        stroke="#E53D2C"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M7.5 4.90039H7.505"
                                        stroke="#E53D2C"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </span>
                                </div>
                                <div>
                                  <span className="fontRoboto12 dark__span">
                                    Reliance Jio operator OTP Based Consent/SMS
                                    Based Consent Model/Missed Call Consent-
                                    Resource needs to open URL link received via
                                    SMS and click on Approve to register consent
                                    under OTP Based Model, Resource needs to
                                    reply on received Consent SMS from 51712032
                                    as Y to register consent. Resource can give
                                    missed call on this number (9321003994) to
                                    register consent
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Grid>
                        </Box>
                        <div
                          style={{
                            backgroundColor: "#F1F5F7",
                            padding: "4%",
                          }}
                          className={darkState ? "dark__mode dark__span" : ""}
                        >
                          {trackingType === 3 &&
                          this.state.TripAddress[0]?.length > 0 ? (
                            this.state.TripAddress.length ? (
                              reverseArray?.map((ele) => (
                                <div>
                                  <Grid
                                    container
                                    className=""
                                    // columnSpacing={3}
                                    style={{
                                      marginBottom: "-24px ",
                                    }}
                                  >
                                    <Grid item xs={4}>
                                      <div style={{ float: "right" }}>
                                        <span
                                          className={`dateTextStyle orderStageDate ${
                                            darkState ? "light__span" : ""
                                          }`}
                                        >
                                          <div>{ele?.date}</div>
                                          <div>{ele?.time}</div>
                                        </span>
                                      </div>

                                      <br></br>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={2}
                                      style={{
                                        backgroundColor: darkState
                                          ? " #171722"
                                          : "#F1F5F7",
                                      }}
                                    >
                                      <div>
                                        <img
                                          src={dotGroup}
                                          style={{
                                            width: " 15%",
                                            height: "80%",
                                            float: "right",
                                            marginRight: "0px",
                                            // marginTop: "60px",
                                          }}
                                          className=" shadow-1-strong rounded mb-4 "
                                          alt=""
                                        />
                                      </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <div>
                                        <span
                                          className={`orderStageStyle  ${
                                            darkState ? "light__span" : ""
                                          }`}
                                        >
                                          {ele?.address
                                            ?.toLowerCase()
                                            .replace(/_/g, " ")
                                            .replace(/\b\w/g, function (match) {
                                              return match.toUpperCase();
                                            })
                                            .replace(/\s+/g, "  ")}
                                        </span>
                                      </div>

                                      <br></br>
                                    </Grid>
                                  </Grid>
                                </div>
                              ))
                            ) : (
                              <p className="text-bold">
                                There is no tracking data available for this
                                trip
                              </p>
                            )
                          ) : (trackingType === 2 || trackingType === 1) &&
                            this.state.blueDartData?.length ? (
                            <div>
                              <Row>
                                <Col xl="12" className="p-1">
                                  <Card style={{ width: "100%" }}>
                                    <div class="table-responsive">
                                      <table class="table">
                                        <thead>
                                          <tr>
                                            {trackingType === 2 ? (
                                              <th style={rowHeaderStyle}>
                                                Location
                                              </th>
                                            ) : (
                                              ""
                                            )}

                                            <th style={rowHeaderStyle}>
                                              Status
                                            </th>
                                            <th style={rowHeaderStyle}>Date</th>
                                            <th style={rowHeaderStyle}>Time</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {trackingType === 2 ||
                                          trackingType === 1
                                            ? this.state.blueDartData &&
                                              this.state.blueDartData?.map(
                                                (ele) => (
                                                  <tr>
                                                    {trackingType === 2 ? (
                                                      <td style={rowValueStyle}>
                                                        {" "}
                                                        {ele?.location}
                                                      </td>
                                                    ) : (
                                                      ""
                                                    )}

                                                    <td style={rowValueStyle}>
                                                      {ele?.status}
                                                    </td>
                                                    <td style={rowValueStyle}>
                                                      {" "}
                                                      {ele?.date}
                                                    </td>
                                                    <td style={rowValueStyle}>
                                                      {" "}
                                                      {ele?.time}
                                                    </td>
                                                  </tr>
                                                )
                                              )
                                            : "There is no tracking data available for this trip"}
                                        </tbody>
                                      </table>
                                    </div>
                                  </Card>
                                </Col>
                              </Row>
                            </div>
                          ) : trackingType === 4 ? (
                            <p className="text-bold">
                              There is no tracking data available for this trip
                            </p>
                          ) : (
                            <p className="text-bold">
                              There is no tracking data available for this trip
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
              <div className="p-0 col-1-2 " style={{ height: "90px" }}>
                <Card>
                  <CardBody>
                    <div style={dataBoxStyle}>
                      <div className="googleMapCard">
                        <TrackingMap
                          id={this.props?.history?.location?.state}
                          // id={"5952"}
                          tripTrackId={this.state.TripAddress}
                          sourceCoordinatesOrder={
                            this.state.sourceCoordinates[0]
                          }
                          destinationCoordinates={
                            this.state.destinationCoordinates
                              ? this.state.destinationCoordinates[0]
                              : ""
                          }
                          currentLocation={this.state.currentLocation}
                          coordArr={
                            coordArr?.length > 0 && coordArr !== undefined
                              ? coordArr
                              : []
                          }
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Row>
          )}
        </Container>
        <UpdatePhoneOnTrack
          isOpen={this.state.isPhoneUpdateOpen}
          toggleModel={this.phoneUpdateToggle}
          driverPhone={this.state.updatePhoneInput}
          tripId={
            this.state.tripCords?.length ? this.state.tripCords[0]?._id : ""
          }
          data={this.getAllData}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    trips: state.Trip.trips.trips,
    locationtripById: state.Trip.trips.locationTrip,
    trackOrderData: state?.orderListReducer,
  };
};
export default connect(mapStateToProps, {
  trackOrders,
  getTripList,
})(OrderTrackModel);
