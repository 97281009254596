import React from "react";
import {  Card } from "reactstrap";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import "./LoadOptimization.scss";
import { Icon1, Icon2 } from "./ThreeDRenderIcons";

class ThreeDRender extends React.Component {
  componentDidMount = () => {
    this.sceneSetup();
    this.addCustomSceneObjects();
    this.startAnimationLoop();
    window.addEventListener("resize", this.handleWindowResize);
  };
  createTruckEdges = () => {
    const { length, width, height } = this.props?.modalData.container;
    const texture_ground = new THREE.TextureLoader().load("truck-texture 1.jpg");
    const texture = new THREE.TextureLoader().load("truck-texture.jpg");

    const geometry = new THREE.BoxGeometry( 200, 80, 1000 );
    const edges = new THREE.EdgesGeometry( geometry );
    const line = new THREE.LineSegments( edges, new THREE.LineBasicMaterial( { color: 0x333333 } ) );
    line.position.set(100,40, 500)
    this.scene.add( line );

    // const geometry1 = new THREE.BoxBufferGeometry(width, 0.01, length);
    // const material1 = new THREE.MeshPhongMaterial({
    //   opacity: 0.5,
    //   transparent: true,
    //   map: texture,
    // });
    // const plane1 = new THREE.Mesh(geometry1, material1);
    // plane1.position.set(width / 2, -2, length / 2);
    // this.scene.add(plane1);

    // const geometry2 = new THREE.BoxBufferGeometry(width, height, 0.01);
    // const material2 = new THREE.MeshPhongMaterial({
    //   opacity: 0.5,
    //   transparent: true,
    //   map: texture,
    // });
    // const plane2 = new THREE.Mesh(geometry2, material2);
    // plane2.position.set(width / 2, height / 2, -2);
    // this.scene.add(plane2);
    // const geometry4 = new THREE.BoxBufferGeometry(width, height, 0.01);
    // const material4 = new THREE.MeshPhongMaterial({
    //   opacity: 0.5,
    //   transparent: true,
    //   map: texture,
    // });
    // const plane4 = new THREE.Mesh(geometry4, material4);
    // plane4.position.set(width / 2, height / 2, length+2);
    // this.scene.add(plane4);

    // const geometry3 = new THREE.BoxBufferGeometry(0.01, height, length);
    // const material3 = new THREE.MeshPhongMaterial({
    //   opacity: 0.5,
    //   transparent: true,
    //   map: texture,
    // });
    // const plane3 = new THREE.Mesh(geometry3, material3);
    // plane3.position.set(width+2, height / 2, length / 2);
    // this.scene.add(plane3);
  };
  createLights = () => {
    const lights = [];
    lights[0] = new THREE.PointLight(0xffffff, 1, 0);
    lights[1] = new THREE.PointLight(0xffffff, 1, 0);
    lights[2] = new THREE.PointLight(0xffffff, 1, 0);

    lights[0].position.set(0, 200, 0);
    lights[1].position.set(100, 200, 100);
    lights[2].position.set(-100, -200, -100);

    this.scene.add(lights[0]);
    this.scene.add(lights[1]);
    this.scene.add(lights[2]);
  };
  generateRandomColor = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  handleWindowResize = () => {
    // console.log(this.el);
    if (this.el !== null) {
      const width = this.el.clientWidth;
      const height = this.el.clientHeight;

      this.renderer.setSize(width, height);
      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();
    }
  };
  addCustomSceneObjects = () => {
    const texture = new THREE.TextureLoader().load("truck-texture 1.jpg");
    this.createTruckEdges();
    this.createLights();
    for (let i = 0; i < this.props.modalData?.packageEventList?.length; i++) {
      const geometry = new THREE.BoxBufferGeometry(
        this.props.modalData.packageEventList[i].l,
        this.props.modalData.packageEventList[i].w,
        this.props.modalData.packageEventList[i].h
      );
      const { l, w, h } = this.props?.modalData?.packageEventList[i];
      const { x, y, z } = this.props?.modalData?.packageEventList[i];
      const prevcoord = [];
      var positions1 = geometry.attributes.position.array;
      for (let i = 0; i < positions1.length; i += 3) {
        prevcoord.push([positions1[i], positions1[i + 1], positions1[i + 2]]);
      }
      // console.log(prevcoord);
      const material = new THREE.MeshPhongMaterial({
        color: 'aqua',
        opacity : 0.6,
        emissive: 0x072534,
        side: THREE.DoubleSide,
        flatShading: true,
        map : texture
      });

      let wireframe = new THREE.WireframeGeometry( geometry );
      let line = new THREE.LineSegments( wireframe );
      
      this.cube2 = new THREE.Mesh(geometry, material);
      const newcoord = [];
      var positions = geometry.attributes.position.array;
      for (let i = 0; i < positions.length; i += 3) {
        const v = new THREE.Vector3(
          positions[i],
          positions[i + 1],
          positions[i + 2]
        );
        positions[i] = v.x + l / 2 + x;
        positions[i + 1] = v.y + w / 2 + y;
        positions[i + 2] = v.z + h / 2 + z;
        newcoord.push([positions[i], positions[i + 1], positions[i + 2]]);
      }
      // console.log(newcoord);
      geometry.attributes.position.needsUpdate = true;
      this.scene.add(this.cube2);
      line.position.set(l/2+x,w/2+y,h/2+z)
     
      line.material.color.setHex(0xf1f5f7);
          
      this.scene.add(line);
    }

    
  };
  startAnimationLoop = () => {
    // this.cube.rotation.x += 0.01;
    // this.cube.rotation.y += 0.01;

    this.renderer.render(this.scene, this.camera);
    this.requestID = window.requestAnimationFrame(this.startAnimationLoop);
  };
  sceneSetup = () => {
    // console.log(this.el);
    // get container dimensions and use them for scene sizing
    const width = this.el.clientWidth;
    const height = this.el.clientHeight;

    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color("#f1f5f7");

    this.camera = new THREE.PerspectiveCamera(45, width / height, 1, 1000);
    this.controls = new OrbitControls(this.camera, this.el);
    this.camera.position.set(-40, 50, 800);
    this.camera.lookAt(new THREE.Vector3(0, 0, 0));

    const axesHelper = new THREE.AxesHelper(5);
    this.scene.add(axesHelper);

    this.renderer = new THREE.WebGLRenderer();
    this.renderer.setSize(width, height);
    this.el.appendChild(this.renderer.domElement); // mount using React ref
  };
  render() {
    // console.log(this.camera);
    const { data } = this.props.modalData;
    return (
      <div>
        <div
          ref={(ref) => (this.el = ref)}
          style={{
            minHeight: "500px",
            height: "500px",
          }}
        ></div>

        <Card className="cardDetails">
          <p className="Label fontRoboto15 align-center">
            {this.props.modalData?.containerTypeName}{" "}
          </p>
          {/* <p className="Label fontRoboto15 align-center heading-sub">(5,758cm x 2,352cm x 2,385cm) </p> */}
          <p className="Label fontRoboto15 align-center heading-sub">
            ({data && data?.container?.length} x{" "}
            {data ? data?.container?.width : ""} x{" "}
            {data ? data?.container?.height : ""} ){" "}
          </p>
          <div className="cardDetailsInfo">
            <span></span>
            <span className="cardDetailsFont Label">Weight</span>
            <span className="cardDetailsFont Label">Volume</span>
            <span className="cardDetailsFont Label">Free Metres</span>
          </div>
          <div className="cardDetailsInfo">
            <span>
              <Icon1 />
            </span>
            <span className="cardDetailsNumber">30,844</span>
            <span className="cardDetailsNumber">112.42 m3</span>
            <span className="cardDetailsNumber">16.00 m</span>
          </div>
          <div className="cardDetailsInfo">
            <span>
              <Icon2 />
            </span>
            <span className="cardDetailsNumber">30,844</span>
            <span className="cardDetailsNumber">112.42 m3</span>
            <span className="cardDetailsNumber">16.00 m</span>
          </div>
          <div className="cardDetailsInfo">
            <span>
              <Icon1 />
            </span>
            <span className="cardDetailsNumber">30,844</span>
            <span className="cardDetailsNumber">112.42 m3</span>
            <span className="cardDetailsNumber">16.00 m</span>
          </div>
        </Card>
      </div>
    );
  }
}
export default ThreeDRender;
