// import { mapKeys } from "lodash";
import { types } from "../actionTypes";

export default (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_PROD_NAMES_SUCCESS:
      //   // Create a new state object that uses an AJAX request response and grabs the 'id' property from each object in the response to use as its key
      return { prodList: action.payload };
    // case types.FETCH_ORDER_SUCCESS:
    //   // Copy the current state and set a new property with a dynamic key value and the payload as the value
    //   return { ...state, [action.payload.id]: action.payload };
    case types.FETCH_ORDERS_SUCCESS:
      //   // Create a new state object that uses an AJAX request response and grabs the 'id' property from each object in the response to use as its key
      return action.payload;
    case types.UPLOAD_ORDER_SUCCESS:
      return { ...state, uploadFile: action.payload };
    case types.CREATE_ORDER_SUCCESS:
      // Copy the current state and set a new property with a dynamic key value and the payload as the value
      return { ...state, [action.payload.id]: action.payload };
    case types.DELETE_ORDER_SUCCESS:
      // Copy the current state and delete the property with the specified key
      var newState = { ...state };
      delete newState[action.payload.id];
      return newState;
    case types.GET_ORDERS_BY_ID_SUCCESS:
      return action.payload;
    default:
      state = { ...state };
      break;
  }
  //   case REGISTER_USER:
  //     state = {
  //         ...state,
  //         user: null,
  //         loading: true,
  //         registrationError: null
  //     }
  //     break;

  // case REGISTER_USER_SUCCESSFUL:
  //     state = {
  //         ...state,
  //         user: action.payload,
  //         loading: false,
  //         registrationError: null
  //     }
  //     break;
  // case REGISTER_USER_FAILED:
  //     state = {
  //         ...state,
  //         loading: false,
  //         registrationError: action.payload
  //     }
  //     break;
  return state;
};
