import React, { Component } from "react";
// import packages
import { Row, Col, Button, Card } from "reactstrap";
import Select from "react-select";
import { Label, FormGroup } from "reactstrap";
import * as Yup from "yup";
import { Formik, ErrorMessage } from "formik";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";

// import from store
import { FetchLoadOptimizationData } from "../../store/LoadOptimization/actions";
import { fetchHopsSourceDestination } from "../../store/hop/actions";
import { fetchVehicleType } from "../../store/vehicle/actions";
import { fetchRoutes } from "../../store/route/actions";
import { fetchVehicleTypesList } from "../../store/vehicleType/actions";
// import files
import {
  VehicleIcon1,
  VehicleIcon2,
  VehicleIcon3,
  VehicleIcon4,
} from "./VehicleIcon";
import { WaveLoader } from "../../components/Common/Loader/LoaderIndex";
// import { includes } from "lodash";
import ThreeDModal from "./ThreeDModal";
let VehicleIconsArr = [1, 2, 3, 4];
export const randomVehiclesTypesIcons = () => {
  let random = Math.round(Math.random() * VehicleIconsArr.length);
  return random;
};
const initialState = () => ({
  source: [],
  destination: [],
  route: [],
  vehicleType: [],
  form: {
    source: "",
    destination: "",
    route: "",
  },
  vehicleTypeData: [],
  vehicleTypeSelectedValue: "",
  vehicleTypeDataArr: [],
  vehicleTypeshow: true,
  randomNumer: "",
  optimizedShow: false,
  dataLoading: false,
  arrayOfItems: [],
  nbrOfUsedVehiclesArr: [],
  resData: {},
  threeDModal: false,
  modalData : [],
  showVehicleDetails : false
});
class LoadContainer extends Component {
  constructor(props) {
    super(props);

    this.state = initialState();

  }
  toggleModal = () => {
    this.setState({
      threeDModal: !this.state.threeDModal,
    });
  };
  onReset = () => {
    this.setState({
      form: {
        source: "",
        destination: "",
        route: "",
      },
      vehicleType: [],
    });
  };
  componentDidMount() {
    let offset = 0;
    this.sourceDestination();
    this.props.fetchVehicleTypesList(offset, (res) => {
      // console.log("res", res?.data?.docs);
      let vehicleTypeData = [];
      if (res?.data?.length > 0) {
        res.data.docs.forEach((ele) => {
          vehicleTypeData.push({
            label: ele.label,
            value: {
              id: ele._id,
              width: ele?.specs?.width,
              length: ele?.specs?.length,
              height: ele?.specs?.height,
              maxWeight: ele?.specs?.weight,
            },
          });
        });
        // console.log("ele data1", vehicleTypeData);

        let vehicleTypeDt = vehicleTypeData.filter(
          (ele, i) => vehicleTypeData.indexOf(ele?.value?.id) !== i
        );

        this.setState({
          vehicleTypeData: vehicleTypeDt,
        });
      }
    });
  }
  sourceDestination = (name = "source", q = "") => {
    let id = this.state.form.source?.value
      ? this.state.form.source?.value.id
      : "";
    let offset = this.state.offset;
    let values = { offset, id };
    if (q !== "") {
      values.source = q;
    }
    this.props.fetchHopsSourceDestination(offset, id, q, (res) => {
      this.hops = [];
      if (res?.data?.length > 0) {
        // this.hops = [this.initialVal("Location")];
        res.data.forEach((ele) => {
          this.hops.push({
            label: ele.hopCode + " (" + ele.label + ")",
            value: {
              name:
                ele.address.addressLine1 + ele.address.city + ele.address.state,
              hopCode: ele.hopCode,
              id: ele._id,
              // latitude: ele.cordinates ? ele.cordinates[0] : null,
              // longitude: ele.cordinates ? ele.cordinates[1] : null,
            },
          });
        });
        this.setState({ [name]: this.hops });
      }
    });
  };
  fetchRoutes = () => {
    let source = this.state.form.source?.value?.id;
    let destination = this.state.form.destination?.value?.id;
    this.props.fetchRoutes(this.state.offset, source, destination, (res) => {
      this.routes = [];
      if (res?.data?.length > 0) {
        res.data.forEach((ele) => {
          this.routes.push({
            label: ele.name,
            value: {
              via: ele.via,
              id: ele._id,
              routeCode: ele.routeCode,
              // name:
              //   ele.address.addressLine1 + ele.address.city + ele.address.state,
              // hopCode: ele.hopCode,
              // latitude: ele.cordinates ? ele.cordinates[0] : null,
              // longitude: ele.cordinates ? ele.cordinates[1] : null,
            },
          });
        });
        this.setState({ route: this.routes }, this.fetchVehicleType);
      }
    });
  };
  fetchVehicleType = (data) => {
    data = data || {};
    let source = data.source?.value?.id || this.state.form.source?.value?.id;
    let destination =
      data.destination?.value?.id || this.state.form.destination?.value?.id;
    let route = data.route?.value?.id || this.state.form.route?.value?.id;
    this.props.fetchVehicleType({
      callback: (res) => {
        this.vehicleType = [];
        if (res?.data?.length > 0) {
          res.data.forEach((ele) => {
            this.vehicleType.push({
              label: ele.label,
              value: {
                id: ele._id,
                width: ele?.specs?.width,
                length: ele?.specs?.length,
                height: ele?.specs?.height,
                maxWeight: ele?.specs?.weight,
              },
            });
          });
          // filtering duplicate vehicle type master
          let vehicleTypeDt = this.vehicleType.filter((ele, i) => {
            return this.vehicleType.indexOf(ele?.value?.id) !== i;
          });
          this.setState(
            { vehicleType: [...vehicleTypeDt], vehicleTypeshow: false },
            () => {}
          );
          this.filterDataVehicle();
        }
      },
      source,
      destination,
      route,
    });
  };
  handleSubmitChange = (e) => {
    //console.log("SOURCE: ", e.target.value);
    const name = e.target.name;
    let value = e.target.value;
    let formData = this.state.form;
    formData[`${name}`] = value;
    //console.log("\n==================\n");
    //console.log(formData["date"], ":", formData["expirydate"]);
    this.setState({ form: formData }, () => {
      if (name === "source") {
        this.sourceDestination("destination");
        this.setState({ disableHop: !this.state.disableHop });
      }
      if (name === "destination") {
        this.fetchRoutes();
      }
      if (name === "route") {
        let vi = value?.value?.via;
        this.setState({ via: vi }, () => {
          let ch = [];
          vi.map((v, i) => {
            ch[i] = false;
            return true;
          });
          this.setState({ check: ch });
        });
        this.fetchVehicleType();
      }
    });
  };
  handleBlurFilter = (e) => {
    if (e.target.name === "source") {
      if (e.target.value.length > 0) {
        if (this.state.source.length > 0) {
          let filter = this.state.source.filter((st, i) => {
            return st.label.includes(e.target.value.toUpperCase());
          });
          if (this.Timer) {
            clearTimeout(this.Timer);
          }
          if (Array.isArray(filter)) {
            if (filter.length <= 0) {
              this.Timer = setTimeout(() => {
                this.sourceDestination("source", e.target.value);
              }, 500);
            }
          }
        }
      }
    }
    if (e.target.name === "destination") {
      if (e.target.value.length >= 3) {
        if (this.state.destination.length > 0) {
          let filter = this.state.destination.filter((st, i) => {
            return st.label.includes(e.target.value.toUpperCase());
          });
          if (this.Timer) {
            clearTimeout(this.Timer);
          }
          if (Array.isArray(filter)) {
            if (filter.length <= 0) {
              this.Timer = setTimeout(() => {
                this.sourceDestination("destination", e.target.value);
              }, 500);
            }
          }
        }
      }
    }
  };
  vehicleTypeOnchange = (e) => {
    // console.log("e testing",e)
    let filerData = this.state.vehicleTypeData.filter(
      (ele, i) => ele?.value?.id !== e?.value?.id
    );
    this.setState({
      vehicleTypeSelectedValue: e,
      vehicleTypeDataArr: [e],
      vehicleTypeData: filerData,
    });
  };
  addVehicleType = () => {
    let data = [];
    this.filterDataVehicle();
    let vehicleTypeDat = this.state.vehicleTypeData.filter(
      (ele, i) =>
        ele?.value?.id !== this.state.vehicleTypeSelectedValue?.value?.id
    );
    data = data.concat(this.state.vehicleType, this.state.vehicleTypeDataArr);
    this.setState(
      {
        vehicleType: data,
        vehicleTypeData: vehicleTypeDat,
      },
      () => {
        this.setState({
          vehicleTypeSelectedValue: "",
        });
      }
    );
  };
  filterDataVehicle = () => {
    let Data = [];
    Data = this.state.vehicleTypeData.filter(
      (o) => !this.state.vehicleType.some((i) => i?.value?.id === o.value?.id)
    );

    // console.log("data", Data);
    this.setState({
      vehicleTypeData: Data,
    });
  };
  removeVehicleType = (i, a) => {
    const deleteVehicleType = this.state.vehicleType.filter(
      (type, index) => i !== index
    );
    this.setState({
      vehicleType: deleteVehicleType,
    });
  };
  runSimulation = () => {
    // console.log("this.props.addItemDetails", this.props.addItemDetails);
    let arrayOfItems = [];
    // looping no of pcs
    this.props.addItemDetails.map((ele, i) => {
      let pcs = ele?.totalPcs;
      if (ele?.measures?.value === "cm") {
        if (pcs > 1) {
          for (let i = 0; i < pcs; i++) {
            arrayOfItems.push({
              width: parseFloat(ele?.width) * 0.393701,
              length: parseFloat(ele?.length) * 0.393701,
              height: parseFloat(ele?.height) * 0.393701,
              weight: ele?.totalKg,
            });
          }
        }
      } else {
        if (pcs > 1) {
          for (let i = 0; i < pcs; i++) {
            arrayOfItems.push({
              width: ele?.width,
              length: ele?.length,
              height: ele?.height,
              weight: ele?.totalKg,
            });
          }
        }
      }

      // console.log("arrayOfItems", arrayOfItems);
      this.setState(
        {
          arrayOfItems: arrayOfItems,
        },
        () => {
          // console.log("this.state.arrayOfItems", this.state.arrayOfItems)
        }
      );
      return true;
    });

    let randomNumer = randomVehiclesTypesIcons();
    this.setState({
      randomNumer: randomNumer,
      dataLoading: true,
      optimizedShow: true,
    });
    let vehicleTyData = [];
    this.state.vehicleType.forEach((ele) => {
      vehicleTyData.push({
        id: ele?.value.id,
        width: ele?.value?.width,
        length: ele?.value?.length,
        height: ele?.value?.height,
        maxWeight: ele?.value?.maxWeight,
      });
    });
    var payloadValue = {
      itemData: [...arrayOfItems],
    };
    // console.log("dataTest",payloadValue)

    this.props.FetchLoadOptimizationData(payloadValue, (res) => {
      if (res && res.status === 200) {
        NotificationManager.success(res?.message, "Success");
        this.setState({
          resData: res?.data,
          dataLoading: false,
        });
      } else {
        NotificationManager.error(res?.message, "Error");
        this.setState({
          resData: "",
          dataLoading: false,
        });
      }
    });
  };
  render() {
    const validationSchema = Yup.object({
      source: Yup.object().required(
        this.state.userLangs?.planning?.SOURCE_IS_REQUIRED
      ),
      destination: Yup.object().required(
        this.state.userLangs?.planning?.DESTINATION_IS_REQUIRED
      ),
      route: Yup.object().required(
        this.state.userLangs?.planning?.ROUTE_IS_REQUIRED
      ),
      vehicleType: Yup.object().required(
        this.state.userLangs?.planning?.VEHICLE_TYPE_IS_REQUIRED
      ),
    });
    const customStyles = {
      indicatorsContainer: (style) => {
        return {
          ...style,
          backgroundColor: "#8e9af8",
          height: "26px",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          backgroundColor: this.props.mode ? "#9d9d9d" : "#8e9af8",
        };
      },
    };
    const customStyles1 = {
      indicatorsContainer: (style) => {
        return {
          ...style,
          backgroundColor: "#8e9af8",
          height: "26px",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          backgroundColor: this.props.mode ? "#9d9d9d" : "#8e9af8",
        };
      },
    };
    const form = this.state.form;
    return (
      <>
        <ThreeDModal
          isOpen={this.state.threeDModal}
          toggle={this.toggleModal}
          modalData={this.state.modalData}
          addItemDetails={this.props.addItemDetails}
        />
        <Card className="shadow LoadAddContainer">
          <Row>
            <Col xs={12}>
              <span
                className="fontRoboto15 spanAddItemName"
                style={{
                  color: "#05284E",
                }}
              >
                Add Container Details
              </span>
            </Col>
            <Col xs={12}>
              <hr className="mt-2 Container-hr-line" />
            </Col>
          </Row>
          <Row className="rowPadding pt-0">
            <Col xs={12} md={12}>
              <Formik
                initialValues={form}
                validationSchema={validationSchema}
                // onSubmit={this.handleSubmitSave}
                validateOnChange={false}
              >
                {(formik) => {
                  const { errors, touched, handleBlur } = formik;
                  return (
                    <form
                    // onSubmit={handleSubmit}
                    >
                      <div>
                        <FormGroup className="select2-container required darkAgreementVehicleCharge">
                          <Label
                            className=" required-field light__span fontRoboto15"
                            style={{
                              color: "#05284E",
                            }}
                          >
                            Source
                          </Label>
                          <Select
                            styles={customStyles}
                            classNamePrefix="select2-selection"
                            name="source"
                            value={form?.source || null}
                            options={this.state.source}
                            onChange={(e) => {
                              this.handleSubmitChange({
                                target: {
                                  name: "source",
                                  value: e,
                                },
                              });
                            }}
                            onInputChange={(e) => {
                              this.handleBlurFilter({
                                target: {
                                  name: "source",
                                  value: e,
                                },
                              });
                            }}
                            id="source"
                            className={
                              errors.source && touched.source
                                ? "input-error"
                                : null
                            }
                          />
                          <ErrorMessage
                            name="source"
                            component="span"
                            className="error"
                          />
                        </FormGroup>
                      </div>
                      <div>
                        <FormGroup className="select2-container required darkAgreementVehicleCharge">
                          <Label
                            className=" required-field light__span fontRoboto15"
                            style={{
                              color: "#05284E",
                            }}
                          >
                            Destination
                          </Label>
                          <Select
                            styles={customStyles}
                            classNamePrefix="select2-selection"
                            value={form?.destination || null}
                            name="destination"
                            // isDisabled={rejection.length > 0 ? true : false}
                            onChange={(e) => {
                              this.handleSubmitChange({
                                target: {
                                  name: "destination",
                                  value: e,
                                },
                              });
                            }}
                            options={this.state.destination}
                            onBlur={handleBlur}
                            id="destination"
                            onInputChange={(e) => {
                              this.handleBlurFilter({
                                target: {
                                  name: "destination",
                                  value: e,
                                },
                              });
                            }}
                            className={
                              errors.destination && touched.destination
                                ? "input-error"
                                : null
                            }
                          />
                          <ErrorMessage
                            name="destination"
                            component="span"
                            className="error"
                          />
                        </FormGroup>
                      </div>
                      <div>
                        <FormGroup className="select2-container required darkAgreementVehicleCharge">
                          <Label
                            className=" required-field light__span fontRoboto15"
                            style={{
                              color: "#05284E",
                            }}
                          >
                            Route
                          </Label>
                          <Select
                            styles={customStyles}
                            classNamePrefix="select2-selection"
                            value={form.route ? form.route : ""}
                            name="route"
                            onChange={(e) => {
                              this.handleSubmitChange({
                                target: {
                                  name: "route",
                                  value: e,
                                },
                              });
                            }}
                            options={this.state.route}
                            onBlur={handleBlur}
                            id="route"
                            className={
                              errors.route && touched.route
                                ? "input-error"
                                : null
                            }
                          />
                          <ErrorMessage
                            name="route"
                            component="span"
                            className="error"
                          />
                        </FormGroup>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </Col>
            <Col xs={12} md={7}>
              <Card className={`shadow vehicleTypeCard ${this.state.showVehicleDetails ? '' : 'd-none'}`}>
                <Row className="rowPadding ptdb10">
                  <Col xs={9}>
                    <div>
                      <FormGroup className="select2-container required darkAgreementVehicleCharge">
                        <Label
                          className=" required-field light__span fontRoboto15"
                          style={{
                            color: "#05284E",
                          }}
                        >
                          Vehicle Type
                        </Label>
                        <Select
                          styles={customStyles1}
                          placeholder="Select vehicle type..."
                          classNamePrefix="select2-selection1"
                          options={
                            this.state.vehicleTypeshow
                              ? []
                              : this.state.vehicleTypeData
                          }
                          value={this.state.vehicleTypeSelectedValue}
                          onChange={(e) => this.vehicleTypeOnchange(e)}
                        />
                      </FormGroup>
                    </div>
                  </Col>
                  <Col xs={3}>
                    <Button
                      className="AddNUmberBtn addDarkBtn pl-3 pr-3 fontRoboto134"
                      onClick={() => this.addVehicleType()}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
                <Row>
                  {this.state.vehicleType?.length > 0 ? (
                    <Col xs={9} className="typeScroll">
                      {this.state.vehicleType.map((a, i) => (
                        <Row className="rowPadding1" key={i}>
                          <Col xs={12} className="pr-0">
                            <input
                              // style={{ height: "38px", minWidth:"100%"}}
                              value={a.label}
                              required
                              className="form-control form-control-sm w-100 InputForm "
                              type="text"
                              onChange={() => {}}
                            />
                            {/* </Col>
                      <Col xs={1} className="pt-0"> */}
                            <svg
                              key={i}
                              className="SvgIcon"
                              onClick={() => this.removeVehicleType(i, a)}
                              width="7"
                              height="7"
                              viewBox="0 0 7 7"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6 1L1 6"
                                stroke="#2800FC"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M1 1L6 6"
                                stroke="#2800FC"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </Col>
                        </Row>
                      ))}
                    </Col>
                  ) : null}
                </Row>
              </Card>
            </Col>
          </Row>
          <Row className="rowPadding">
            <Col x={12}>
              <div className="float-right">
                <Button
                  className="resetButtonStyle"
                  style={{
                    backgroundColor: "#0AB38C",
                    color: "white",
                    height: "40px",
                  }}
                  onClick={() => this.onReset()}
                >
                  Reset
                </Button>
                <Button
                  className="RunStimulationButton  fontRoboto1541"
                  onClick={() => this.runSimulation()}
                  style={{
                    height: "40px",
                  }}
                >
                  Run Simulation
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <span
                className="fontRoboto15 spanAddItemName"
                style={{
                  color: "#05284E",
                }}
              >
                Optimized Result
              </span>
            </Col>
            <Col xs={12}>
              <hr className="mt-3 Container-hr-line" />
            </Col>
          </Row>
          <Card className="shadow optimizerCard pl-2 pr-2">
            {this.state.optimizedShow ? (
              <>
                {this.state.dataLoading ? (
                  <span
                    className="fontRoboto1541 align-items-center d-flex justify-content-center light__span"
                    style={{
                      color: "#05284E",
                      backgroundColor: "#F1F5F7",
                    }}
                  >
                    <WaveLoader />
                  </span>
                ) : (
                  <>
                    <div>
                      {this.state.resData?.reportList ? (
                        this.state.resData.reportList.map((ele, i) => {
                          return (
                            <>
                              <div
                                key={i}
                                style={{
                                  backgroundColor: "#F1F5F7",
                                  height: "70px",
                                }}
                                className="text-left mt-2"
                              >
                                <Row>
                                  <Col xs={6} md={9} lg={10}>
                                    <Row className="paddingContainer">
                                      <Col xs={12}>
                                        <span className="fontRoboto127">
                                          Vehicle-
                                        </span>
                                        <span className="fontRoboto127">
                                          {i + 1}
                                        </span>
                                      </Col>
                                      <Col xs={12}>
                                        <span className="fontRoboto12">
                                          {ele?.containerTypeName} (
                                          {ele?.container?.length}in X{" "}
                                          {ele?.container?.width}in X{" "}
                                          {ele?.container?.height}in X{" "}
                                          {ele?.container?.weight}kg)
                                          {/* CONTAINER 32 FT MXL 14.5 MT (5,758cm x 2,352cm x
                                  2,385cm) */}
                                        </span>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col xs={6} md={3} lg={2}>
                                    <Row>
                                      <Col xs={8}>
                                        <span>
                                          {(this.state.randomNumer === 0 ||
                                            this.state.randomNumer === 4) && (
                                            <VehicleIcon1 />
                                          )}
                                          {this.state.randomNumer === 1 && (
                                            <VehicleIcon2 />
                                          )}
                                          {this.state.randomNumer === 2 && (
                                            <VehicleIcon3 />
                                          )}
                                          {this.state.randomNumer === 3 && (
                                            <VehicleIcon4 />
                                          )}
                                        </span>
                                      </Col>
                                      <Col xs={4}>
                                        <div className="fontRoboto1541 align-items-center d-flex justify-content-center light__span">
                                          <svg
                                            style={{
                                              cursor: "pointer",
                                              position: "relative",
                                              top: "21px",
                                              right: "19px",
                                            }}
                                            onClick={() => {
                                              this.setState(
                                                {
                                                  modalData: ele,
                                                },
                                                () => {
                                                  this.toggleModal();
                                                }
                                              );
                                            }}
                                            width="25"
                                            height="25"
                                            viewBox="0 0 18 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M1 6.66667C1 6.66667 3.83333 1 8.79167 1C13.75 1 16.5833 6.66667 16.5833 6.66667C16.5833 6.66667 13.75 12.3333 8.79167 12.3333C3.83333 12.3333 1 6.66667 1 6.66667Z"
                                              stroke="#2800FC"
                                              strokeWidth="2"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                            <path
                                              d="M8.79199 8.7915C9.9656 8.7915 10.917 7.84011 10.917 6.6665C10.917 5.4929 9.9656 4.5415 8.79199 4.5415C7.61839 4.5415 6.66699 5.4929 6.66699 6.6665C6.66699 7.84011 7.61839 8.7915 8.79199 8.7915Z"
                                              stroke="#2800FC"
                                              strokeWidth="2"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                          </svg>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </div>
                            </>
                          );
                        })
                      ) : (
                        <span></span>
                      )}
                    </div>
                  </>
                )}
              </>
            ) : (
              <span
                className="fontRoboto1541 align-items-center d-flex justify-content-center light__span"
                style={{
                  color: "#05284E",
                  backgroundColor: "#F1F5F7",
                }}
              >
                Run Simulation to Show Loading Plan
              </span>
            )}
          </Card>
        </Card>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  //  loadOPtimization: state?.LoadOptimization?.LoadOptimization
});
export default connect(mapStateToProps, {
  fetchHopsSourceDestination,
  fetchVehicleType,
  fetchRoutes,
  fetchVehicleTypesList,
  FetchLoadOptimizationData,
})(LoadContainer);
