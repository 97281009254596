import { types } from "./actionTypes";

export const fetchVehicleTypesList = (offset, callback) => ({
  type: types.FETCH_VEHICLETYPELIST,
  offset,
  callback,
});

export const fetchVehicleTypesFilter = (offset,filters, callback) => ({
  type: types.SPEC_FILTER_VEHICLETYPE,
  offset,
  filters,
  callback,
});

export const fetchVehicleTypesFilterExcel = (offset,filters, callback) => ({
  type: types.SPEC_FILTER_VEHICLETYPE_EXCEL,
  offset,
  filters,
  callback,
});

export const addVehicleTypesSpec = (values, callback) => ({
  type: types.SPEC_ADD_VEHICLETYPE,
  values,
  callback,
});

export const updateVehicleTypesSpec = ( Id,values,callback) => ({
  type: types.SPEC_UPDATE_VEHICLETYPE,
  Id,
  values,
  callback,
});

export const deleteVehicleTypesSpec = ( Id ,callback) => ({
  type: types.SPEC_DELETE_VEHICLETYPE,
  Id,
  callback,
});

export const fetchSIgnedUrlVehicleTypes = (callback) => ({
  type: types.SPEC_FETCHSIGNEDURL_VEHICLETYPE,
  callback,
});


export const uploadVehicleType = (uri, data, headers) => ({
  type: types.UPLOAD_VEHICLETYPE,
  uri,
  data,
  headers,
});

export const fetchVechileTypePresignedUrl = (callback) => {
  return {
    type: types.GET_VECHILETYPE_PRESIGNED_URL,
    callback,
  };
};


export const createVechileTypeViaExcel = (payload, callback) =>{
  return {
      type:types.CREATE_VECHILETYPE_VIA_EXCEL,
      payload,
      callback
  }
}

export const uploadImagesAws = (uri, data, headers) => ({
  type: types.UPLOAD_IMAGE1,
  uri,
  data,
  headers,
});
