import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Controls from "./Controls/ActionButton";
import CloseIcon from "@material-ui/icons/Close";


const useStyles = makeStyles(theme => ({
    dialogWrapper: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5),
        width: "476px",
        height:"78%",
        background: " #FFFFFF",
        border: " 0.5px solid #B9B9B9",
        boxSizing: "border- box",
        boxShadow: "0px 3px 13px rgba(0, 0, 0, 0.25)",
        borderRadius: "4px",
    },
    dialogWrapper1: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5),
        width: "100%",
        minWidth:"80%",
        height:"81%",
        background: " #FFFFFF",
        border: " 0.5px solid #B9B9B9",
        boxSizing: "border- box",
        boxShadow: "0px 3px 13px rgba(0, 0, 0, 0.25)",
        borderRadius: "4px",
    },
    dialogTitle: {
        paddingRight: '0px',
        fontFamily: " Roboto",
        fontStyle: "normal",
        fontWeight: " bold",
        fontSize: " 20px",
        lineHeight: "23px",
        color: "#0C0C0C",
    
    }
}))

export default function Popup(props) {
  const { title, children, openPopup, setOpenPopupfalse, mode } = props;
  // console.log(props, "props works");
  const classes = useStyles();
  return (
    <Dialog
      open={openPopup}
      maxWidth="md"
      classes={{
        paper:
          props.title === "Add User"
            ? `${classes.dialogWrapper1}`
            : `${classes.dialogWrapper}`,
      }}
      style={
        {
          // width:props.title === "Add User" ? "100%" :"476px"
        }
      }
      className={`${mode ? "darkModeDriver DialogBox" : "DialogBox"}`}
    >
      <DialogTitle className={classes.dialogTitle}>
        <div style={{ display: "flex" }}>
          <Typography
            variant="h6"
            component="div"
            style={{ flexGrow: 1 }}
            className={`${mode ? "light__span" : ""}`}
          >
            {title}
          </Typography>
          <Controls
            variant="outlined"
            onClick={() => {
              setOpenPopupfalse(false);
            }}
          >
            <CloseIcon style={{ color: mode ? "#B7A9FF" : "#2800fc" }} />
          </Controls>
        </div>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}
