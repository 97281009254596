import React, { Component } from "react";
import dot3 from "./assets/5.png";
import dot31 from "./assets/51.png";
import dotLast from "./assets/dotLast.png";
import { connect } from "react-redux";
import { WaveLoader } from "../../components/Common/Loader/LoaderIndex";
import { withRouter } from "react-router-dom";

import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  CardHeader,
} from "reactstrap";

let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
class OrderTracktimediff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statuses: {
        ORDER_PUNCHED: false,
        ORDER_RELEASED: false,
        INVOICE_GENERATED: false,
        ORDER_DISPATCHED: false,
        ORDER_DELIVERED: false,
      },
      dataLoading: false
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  componentDidUpdate = (prevProps) => {
    let statusActInact = {};
    if (prevProps.groupedItems !== this.props?.groupedItems) {
      this.setState({dataLoading:true})
      let statusArray = Object.entries(this.props?.groupedItems).map(
        ([status, items]) => {
          statusActInact[status] = true;
          return statusActInact;
        }
      );


      this.setState((prevState) => ({
        statuses: {
          ...prevState?.statuses,
          ...statusActInact,
        },
        dataLoading:false
      }));
      
    }
  };

  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 0.9;
  }

  render() {
    const { fullScreen } = this.props;
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let user = JSON.parse(window.localStorage.getItem("authUser"));
    let darkState = this.props.darkTheme;
    const { statuses } = this.state;
    return (
      <>
        <Card className="barDropDark">
          <div>
            {darkState ? (
              <h5 className="text-bold text-center mt-4 light__span">
                {" "}
                Lead Time
              </h5>
            ) : (
              <CardHeader className="bg-light orderCard">
                <h5 className="text-bold text-center mt-4">
                  {" "}
                  Lead Time
                </h5>
              </CardHeader>
            )}
            {this.state.dataLoading ? (
              <WaveLoader />
            ) : (
              <Container fluid={true}>
                <Row>
                  <Col xl="12" className="p-0">
                    <Card className="leadTimeTrackDiv">
                      <CardBody className="darkCardStyle">
                        <Row
                          className="mr-4 orderTrackTimeDiff"
                        >
                          <div className="col1-5">
                            <div className=" align-items-center">
                              <div className="col-full">
                                <span
                                  className={`orderLeadTimeStyle dark__span ${statuses?.ORDER_PUNCHED
                                    ? "orderLeadTimeStyle"
                                    : "inctiveStatus"
                                    }`}
                                  style={{ marginLeft: "58px" }}
                                >
                                  Order Punch
                                </span>
                              </div>
                              <div className="leadTimeDiffHeight">
                                {statuses?.ORDER_PUNCHED && statuses.ORDER_RELEASED ?

                                  <img
                                    src={dot3}
                                    className="dotImageTransform img-responsive"
                                    alt="Dot"
                                  />
                                  :
                                  <img
                                    src={dot31}
                                    className="dotImageTransform img-responsive"
                                    alt="Dot"
                                  />
                                }

                              </div>
                              <div className="">

                                <div className="col-full leadTimeCol">
                                  {Object.entries(this.props?.groupedItems).map(
                                    ([status, items]) =>
                                      status === "ORDER_RELEASED" ? (
                                        <>
                                          <span
                                            className="dark__span"
                                            style={{ color: "#2800fc" }}
                                          >
                                            {" "}
                                            {this.props.leadTimeDiff
                                              ? this.props.leadTimeDiff
                                                ?.TimeDiffPunchRelease
                                              : ""}
                                          </span>
                                        </>
                                      ) : (
                                        <span></span>
                                      )
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col1-5">
                            <div className=" align-items-center">
                              <div className="col-full">
                                <span
                                  className={`orderLeadTimeStyle dark__span ${statuses?.ORDER_RELEASED
                                    ? "orderLeadTimeStyle"
                                    : "inctiveStatus"
                                    }`}
                                  style={{ marginLeft: "44px" }}
                                >
                                  Order Release
                                </span>
                              </div>
                              <div className="leadTimeDiffHeight">
                                {statuses.ORDER_RELEASED && statuses.INVOICE_GENERATED ?

                                  <img
                                    src={dot3}
                                    className="dotImageTransform img-responsive"
                                    alt="Dot"
                                  />
                                  :
                                  <img
                                    src={dot31}
                                    className="dotImageTransform img-responsive"
                                    alt="Dot"
                                  />
                                }
                              </div>
                              <div className="">

                                {Object.entries(this.props?.groupedItems).map(
                                  ([status, items]) =>
                                    status === "INVOICE_GENERATED" ? (
                                      <>
                                        <div className="col-full leadTimeCol">
                                          <span className="dark__span" style={{ color: "#2800fc" }}>
                                            {" "}
                                            {this.props.leadTimeDiff
                                              ? this.props.leadTimeDiff
                                                ?.TimeDiffReleaseGenerated
                                              : ""}
                                          </span>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col1-5">
                            <div className=" align-items-center">
                              <div className="col-full">
                                <span
                                  className={`orderLeadTimeStyle dark__span ${statuses?.INVOICE_GENERATED
                                    ? "orderLeadTimeStyle"
                                    : "inctiveStatus"
                                    }`}
                                  style={{ marginLeft: "42px" }}
                                >
                                  Invoice Generated
                                </span>
                              </div>

                              <div className="leadTimeDiffHeight">
                                {statuses?.INVOICE_GENERATED && statuses.ORDER_DISPATCHED ?

                                  <img
                                    src={dot3}
                                    className="dotImageTransform img-responsive"
                                    alt="Dot"
                                  />
                                  :
                                  <img
                                    src={dot31}
                                    className="dotImageTransform img-responsive"
                                    alt="Dot"
                                  />
                                }
                              </div>
                              <div className="">

                                {Object.entries(this.props?.groupedItems).map(
                                  ([status, items]) =>
                                    status === "ORDER_DISPATCHED" ? (
                                      <>
                                        <div className="col-full leadTimeCol">
                                          <span
                                            className="dark__span"
                                            style={{
                                              color: "#2800fc",
                                              // marginTop: "55px",
                                            }}
                                          >
                                            {" "}
                                            {this.props.leadTimeDiff
                                              ? this.props.leadTimeDiff
                                                ?.TimeDiffGeneratedDispatch
                                              : ""}
                                          </span>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col1-5">
                            <div className=" align-items-center">
                              <div className="col-full">
                                <span
                                  className={`orderLeadTimeStyle dark__span ${statuses?.ORDER_DISPATCHED
                                    ? "orderLeadTimeStyle"
                                    : "inctiveStatus"
                                    }`}
                                  style={{ marginLeft: "42px" }}
                                >
                                  Order Dispatch
                                </span>
                              </div>
                              <div className="leadTimeDiffHeight">
                                {statuses.ORDER_DISPATCHED && statuses?.ORDER_DELIVERED ?

                                  <img
                                    src={dot3}
                                    className="dotImageTransform img-responsive"
                                    alt="Dot"
                                  />
                                  :
                                  <img
                                    src={dot31}
                                    className="dotImageTransform img-responsive"
                                    alt="Dot"
                                  />
                                }
                              </div>
                              <div className="">

                                {Object.entries(this.props?.groupedItems).map(
                                  ([status, items]) =>
                                    status === "ORDER_DELIVERED" ? (
                                      <>
                                        <div className="col-full leadTimeCol">
                                          <span className="dark__span" style={{ color: "#2800fc" }}>
                                            {" "}
                                            {this.props.leadTimeDiff
                                              ? this.props.leadTimeDiff
                                                ?.TimeDiffDispatchDelivered
                                              : ""}
                                          </span>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col1-5">
                            <div className=" align-items-center">
                              <div className="col-full">
                                <span
                                  className={`orderLeadTimeStyle dark__span ${statuses?.ORDER_DELIVERED
                                    ? "orderLeadTimeStyle"
                                    : "inctiveStatus"
                                    }`}
                                  style={{ marginLeft: "59px" }}
                                >
                                  Order Delivered
                                </span>
                              </div>
                              <div className="leadTimeDiffHeight">
                                <img
                                  src={dotLast}
                                  className="dotImageTransformLast img-responsive"
                                  alt="Dot"
                                />
                              </div>
                              <div className="">
                                <div className="col-full">
                                  <div className="">

                                    <div className="col-1-2"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            )}
          </div>
        </Card>
      </>
    );
  }
}

export default connect(null)(withRouter(OrderTracktimediff));
