import { types } from "./actionTypes";
const initialState = {
  getZoneToStateMap: null,
  fetchId: "",
};
const zoneToStateMapReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ZONE_TO_STATE_MAP_SUCCESS:
      state = {
        ...state,
        getZoneToStateMap: action.payload,
        fetchId: "id" + new Date().getTime(),
      };
      return state;
    
    default:
      return state;
  }
};
export default zoneToStateMapReducer;
