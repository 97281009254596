import React, { Component } from "react";
import { Row, Col, Card, Button, Input } from "reactstrap";
import Select, { components } from "react-select";
import "./invoiceListTheme.scss";
import refreshIcon from "./assets/refresh-cw.png";
import Datepicker from "../../../../components/Common/DatePicker/DatePicker";
import { Grid } from "@material-ui/core";
import { InputGroup } from "rsuite";
// images
import searchIcon from "./assets/search.png";
import searchDark from "./assets/searchDrk.png";
import download from "./assets/download.png";
import downloadDark from "./assets/downloadDark.png";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
var darkMode;

const DropdownIndicator = (props) => {
  const iconSvg = props.selectProps.selectProps;
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {iconSvg}
      </components.DropdownIndicator>
    )
  );
};

class QuickFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hops: [],
      vehicleTypes: [],
      // searchBar: [],
      searchFilters: {},
      dateValue: [null, null],
      showCalender: false,
      userLangs: undefined,
      fromDate: null,
      toDate: null,
    };
  }
  handleClickAway = () => {
    if (this.state.showCalender === true) {
      this.setState({ showCalender: false });
    }
  };
  componentDidUpdate() {
    // console.log("status", this.state.status);
    if (
      this.state.userLangs === undefined &&
      (userLang !== this.state.userLangs || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  }

  onChangeDatePicker = (value, label) => {
    // console.log(value, label);
    // debugger
    if (label === "fromDate") {
      this.setState({ fromDate: value }, () => {
        if (this.state.fromDate !== null && this.state.toDate !== null) {
          this.props.handleChange([this.state.fromDate, this.state.toDate]);
        }
      });
    }
    if (label === "toDate") {
      this.setState({ toDate: value }, () => {
        if (this.state.fromDate !== null && this.state.toDate !== null) {
          this.props.handleChange([this.state.fromDate, this.state.toDate]);
        }
      });
    }
  };

  render() {
    let localFiltersData = JSON.parse(
      localStorage.getItem("invoiceListFilters")
    );
    let authUserData = JSON.parse(window.localStorage.getItem("authUser"));
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    darkMode = mode === "dark__mode" ? true : false;
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    const customStylesV2 = {
      menu: ({ width, ...css }) => ({
        ...css,
        width: "100%",
        minWidth: "100%",
        backgroundColor: darkMode ? "#171722" : "#ffffff",
        border: darkMode
          ? "0.5px solid #616161"
          : "0.5px solid rgb(204, 204, 204)",
        boxShadow: darkMode
          ? "0px 3px 12px rgba(0, 0, 0, 0.12)"
          : "0px 3px 12px rgba(0, 0, 0, 0.12)",
      }),
      input: (base) => ({
        ...base,
        color: darkMode ? "#9d9d9d" : "#505d69",
      }),
      control: (style) => {
        return {
          ...style,
          height: "28px",
          width: this.props?.form?.type?.length >= 2 ? "100%" : "100%",
          minWidth: "100%",
          minHeight: "28px",
          backgroundColor: darkMode ? "#2E2E46" : "",
          border: darkMode ? "1.5px solid #B7A9FF" : "",
        };
      },
      placeholder: (style) => {
        return {
          ...style,
          marginTop: "0px",
          height: "24px",
          minHeight: "24px",
          color: darkMode ? "#ffffff" : "",
        };
      },
      indicatorsContainer: (style) => {
        return {
          ...style,
          // backgroundColor: "#8e9af8",
          height: "26px",
          minHeight: "26px",
          marginTop: "3px",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "10px",
          minHeight: "10px",
          backgroundColor: "#cccccc",
          marginTop: "4px",
        };
      },
      dropdownIndicator: (style) => {
        return {
          ...style,
          height: "24px",
          minHeight: "26px",
          // backgroundColor: "#0066cc",
          marginTop: "-5px",
        };
      },
    };

    return (
      <React.Fragment>
        <Card className="darkBgQuickFilter">
          <div className="quickStylesBar invoiceStyle">
            <>
              <Row className="m-1">
                {/* <Col xs={12} className="mobileDisplay">
                  <div className="d-flex justify-content-end">
                    <div>
                      <Button
                        style={{
                          minWidth: "70%",
                        }}
                        className="mobile-reset reset"
                        onClick={() => this.props?.reset()}
                      >
                        <img
                          className="mobile-rest-img"
                          src={refreshIcon}
                          alt=""
                        ></img>
                      </Button>
                    </div>

                    <div className="filter">
                      <div className="tooltipIcon QuickFilters">
                        <div className="datepicker-selector-mobile iconButtons mobileCalender actionButtonsDark  mr-2 datepicker-custom">
                          <Datepicker
                            mode={darkMode}
                            value={this.state.toDate}
                            onChangeDatePicker={this.onChangeDatePicker}
                            fromDate={true}
                            toDate={false}
                            classname={"invoiceListStyle"}
                          />
                        </div>
                        <span
                          className="tooltipIcontext"
                          style={{ width: "130px", left: "20%" }}
                        >
                          &nbsp;{this.state.userLangs?.common?.CALENDER}&nbsp;
                        </span>
                      </div>
                    </div>
                    <div className="filter">
                      <div className="tooltipIcon QuickFilters">
                        <div className="datepicker-selector-mobile iconButtons mobileCalender actionButtonsDark  mr-2 datepicker-custom">
                          <Datepicker
                            mode={darkMode}
                            value={this.state.toDate}
                            onChangeDatePicker={this.onChangeDatePicker}
                            fromDate={false}
                            toDate={true}
                            classname={"invoiceListStyle"}
                          />
                        </div>
                        <span
                          className="tooltipIcontext"
                          style={{ width: "130px", left: "20%" }}
                        >
                          &nbsp;{this.state.userLangs?.common?.CALENDER}&nbsp;
                        </span>
                      </div>
                    </div>
                  </div>
                </Col> */}
                <Col xs={12}>
                  <Row className="mb-2">
                    <Col xs={12} md={3}>
                      <div className=" filter">
                        <div className="input-border mt-2">
                          <InputGroup>
                            <Input
                              className="form-control "
                              type="number"
                              name="indentId"
                              value={this.props.filters?.indentId}
                              onChange={(e) => {
                                this.props.handleOnchangeFilters({
                                  target: "indentId",
                                  value: e.target.value,
                                });
                              }}
                              onKeyDown={(event) =>
                                this.props?.enterCheck(event)
                              }
                              placeholder="Indent ID"
                              aria-label="Search"
                              icon="fas fa-times-circle"
                            />
                            {/* <span className="pt-2 pb-2 input-line pl-1 ml-1"></span>
                            <span
                              className="span-img"
                              onClick={() => this.props?.filterList()}
                            >
                              <img
                                className="pl-1"
                                src={darkMode ? searchDark : searchIcon}
                                alt=""
                              ></img>
                            </span> */}
                          </InputGroup>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={3}>
                      <div className=" filter">
                        <div className="input-border mt-2">
                          <InputGroup>
                            <Input
                              className="form-control "
                              type="text"
                              name="Comment"
                              value={this.props.filters?.comment}
                              onChange={(e) =>
                                this.props.handleOnchangeFilters({
                                  target: "comment",
                                  value: e.target.value,
                                })
                              }
                              onKeyDown={(event) =>
                                this.props?.enterCheck(event)
                              }
                              placeholder="Comment"
                              aria-label="Search"
                              icon="fas fa-times-circle"
                            />
                            {/* <span className="pt-2 pb-2 input-line pl-1 ml-1"></span>
                            <span
                              className="span-img"
                              onClick={() => this.props?.filterList()}
                            >
                              <img
                                className="pl-1"
                                src={darkMode ? searchDark : searchIcon}
                                alt=""
                              ></img>
                            </span> */}
                          </InputGroup>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={3}>
                      <div className=" filter">
                        <div className="input-border mt-2">
                          <InputGroup>
                            <Input
                              className="form-control "
                              type="text"
                              name="gst"
                              value={this.props.filters?.gst}
                              onChange={(e) =>
                                this.props.handleOnchangeFilters({
                                  target: "gst",
                                  value: e.target.value,
                                })
                              }
                              onKeyDown={(event) =>
                                this.props?.enterCheck(event)
                              }
                              placeholder="Tax Invoice No"
                              aria-label="Search"
                              icon="fas fa-times-circle"
                            />
                            {/* <span className="pt-2 pb-2 input-line pl-1 ml-1"></span>
                            <span
                              className="span-img"
                              onClick={() => this.props?.filterList()}
                            >
                              <img
                                className="pl-1"
                                src={darkMode ? searchDark : searchIcon}
                                alt=""
                              ></img>
                            </span> */}
                          </InputGroup>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={3}>
                      <div className=" filter">
                        <div className="input-border mt-2">
                          <InputGroup>
                            <Input
                              className="form-control "
                              type="text"
                              name="invoiceId"
                              value={this.props.filters?.invoiceId}
                              onChange={(e) =>
                                this.props.handleOnchangeFilters({
                                  target: "invoiceId",
                                  value: e.target.value,
                                })
                              }
                              onKeyDown={(event) =>
                                this.props?.enterCheck(event)
                              }
                              placeholder="Invoice ID"
                              aria-label="Search"
                              icon="fas fa-times-circle"
                            />
                            {/* <span className="pt-2 pb-2 input-line pl-1 ml-1"></span>
                            <span
                              className="span-img"
                              onClick={() => this.props?.filterList()}
                            >
                              <img
                                className="pl-1"
                                src={darkMode ? searchDark : searchIcon}
                                alt=""
                              ></img>
                            </span> */}
                          </InputGroup>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col xs={12} md={3}>
                  <div className=" filter">
                    <Select
                      name="status"
                      className="transpoter-react_selector"
                      classNamePrefix="react-select"
                      styles={customStylesV2}
                      style={{ width: "82%" }}
                      isMulti={false}
                      onChange={this.props.handleStatusChange}
                      options={this.props.statusOptions}
                      value={this.props.status || ""}
                      id="typeVehicleList"
                      placeholder="Select Status"
                      components={{ DropdownIndicator }}
                      isDisabled={
                        this.props.nameText == "addInvoice" ? true : false
                      }
                      // classNamePrefix='select2-selection'
                    />
                    {/* <InputGroup> */}
                    {/* <Input
                          className="form-control "
                          type="text"
                          name="status"
                          value={this.props.filters?.status}
                          onChange={(e) =>
                            this.props.handleOnchangeFilters({
                              target: "status",
                              value: e.target.value,
                            })
                          }
                          onKeyDown={(event) => this.props?.enterCheck(event)}
                          placeholder="Status"
                          aria-label="Search"
                          icon="fas fa-times-circle"
                        /> */}
                    {/* <span className="pt-2 pb-2 input-line pl-1 ml-1"></span>
                        <span
                          className="span-img"
                          onClick={() => this.props?.filterList()}
                        >
                          <img
                            className="pl-1"
                            src={darkMode ? searchDark : searchIcon}
                            alt=""
                          ></img>
                        </span> */}
                    {/* </InputGroup> */}
                    {/* </div> */}
                  </div>
                </Col>
                <Col xs={12} md={3}>
                  <div className=" filter">
                    <div className="input-border mt-2">
                      <InputGroup>
                        <Input
                          className="form-control "
                          type="text"
                          name="lrNumber"
                          value={this.props.filters?.lrNumber}
                          onChange={(e) =>
                            this.props.handleOnchangeFilters({
                              target: "lrNumber",
                              value: e.target.value,
                            })
                          }
                          onKeyDown={(event) => this.props?.enterCheck(event)}
                          placeholder="Lr Number"
                          aria-label="Search"
                          icon="fas fa-times-circle"
                        />
                        {/* <span className="pt-2 pb-2 input-line pl-1 ml-1"></span>
                        <span
                          className="span-img"
                          onClick={() => this.props?.filterList()}
                        >
                          <img
                            className="pl-1"
                            src={darkMode ? searchDark : searchIcon}
                            alt=""
                          ></img>
                        </span> */}
                      </InputGroup>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={3}>
                  <div className="filter">
                    <Select
                      name={this.props.name}
                      className="transpoter-react_selector"
                      classNamePrefix="react-select"
                      styles={customStylesV2}
                      style={{ width: "82%" }}
                      isMulti={false}
                      onChange={this.props.handleStateChange}
                      options={this.props.stateListOption}
                      value={this.props.stateFilter}
                      id="typeVehicleList"
                      placeholder="Select State..."
                      components={{ DropdownIndicator }}
                      selectProps={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="16"
                          viewBox="0 0 14 16"
                          fill="none"
                        >
                          <path
                            d="M12.455 6.7275C12.455 11.1825 6.727 15.0005 6.727 15.0005C6.727 15.0005 1 11.1825 1 6.7275C1 5.20847 1.60343 3.75166 2.67755 2.67755C3.75166 1.60343 5.20847 1 6.7275 1C8.24653 1 9.70334 1.60343 10.7775 2.67755C11.8516 3.75166 12.455 5.20847 12.455 6.7275V6.7275Z"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7 9.00049C8.10457 9.00049 9 8.10506 9 7.00049C9 5.89592 8.10457 5.00049 7 5.00049C5.89543 5.00049 5 5.89592 5 7.00049C5 8.10506 5.89543 9.00049 7 9.00049Z"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      }
                      // classNamePrefix='select2-selection'
                    />
                  </div>
                </Col>

                <Col
                  xs={12}
                  md={3}
                  // className="desktopDisplay"
                >
                  <Grid item xs={12} className="datePickerGrid">
                    <div
                      className="mr-2 mt-2 date-picker-div RsuiteDateRange dashboardDateRange"
                      style={{
                        width: "100%",
                      }}
                    >
                      <div className="datepicker-selector">
                        <InputGroup>
                          <Datepicker
                            mode={darkMode}
                            value={
                              this.state.fromDate ||
                              (localFiltersData?.FromDate?.length > 0 &&
                                new Date(localFiltersData?.FromDate || null))
                            }
                            onChangeDatePicker={this.onChangeDatePicker}
                            fromDate={true}
                            toDate={false}
                            placeholder={"Start Date"}
                            dashboard={true}
                          />
                          <Datepicker
                            mode={darkMode}
                            value={
                              this.state.toDate ||
                              (localFiltersData?.ToDate?.length > 0 &&
                                new Date(localFiltersData?.ToDate || null))
                            }
                            onChangeDatePicker={this.onChangeDatePicker}
                            fromDate={false}
                            toDate={true}
                            placeholder={"End Date"}
                            dashboard={true}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  </Grid>
                </Col>

                {authUserData?.data?.role?.label !== "transporter" ? (
                  <Col xs={12} md={3}>
                    <div className="filter">
                      <Select
                        name={this.props.name}
                        className="transpoter-react_selector"
                        classNamePrefix="react-select"
                        styles={customStylesV2}
                        style={{ width: "82%" }}
                        isMulti={false}
                        onChange={(e) => this.props.handleStateTransChange(e)}
                        options={this.props.allTransporter}
                        value={this.props.selectedTrans}
                        // onBlur={this.props.getTAllranspoterData}
                        onInputChange={(e) => this.props.filtersTranspoter(e)}
                        id="typeVehicleList"
                        placeholder="Select Transpoter..."
                        components={{ DropdownIndicator }}
                        selectProps={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="16"
                            viewBox="0 0 14 16"
                            fill="none"
                          >
                            <path
                              d="M12.455 6.7275C12.455 11.1825 6.727 15.0005 6.727 15.0005C6.727 15.0005 1 11.1825 1 6.7275C1 5.20847 1.60343 3.75166 2.67755 2.67755C3.75166 1.60343 5.20847 1 6.7275 1C8.24653 1 9.70334 1.60343 10.7775 2.67755C11.8516 3.75166 12.455 5.20847 12.455 6.7275V6.7275Z"
                              stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7 9.00049C8.10457 9.00049 9 8.10506 9 7.00049C9 5.89592 8.10457 5.00049 7 5.00049C5.89543 5.00049 5 5.89592 5 7.00049C5 8.10506 5.89543 9.00049 7 9.00049Z"
                              stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        }
                        // classNamePrefix='select2-selection'
                      />
                    </div>
                  </Col>
                ) : (
                  ""
                )}
                <Col xs={3} md={3} className="d-flex">
                  <div className=" filter">
                    <Button
                     color=""
                      onClick={() => {
                        this.props.filterList();
                      }}
                      className="reset"
                    >
                      {"Search"}
                    </Button>
                  </div>
                  <div className=" filter">
                    {" "}
                    <Button
                     color=""
                      onClick={() => {
                        this.setState(
                          {
                            dateValue: [null, null],
                            fromDate: null,
                            toDate: null,
                          },
                          () => {
                            this.props.reset();
                          }
                        );
                      }}
                      className="reset"
                    >
                      {this.state.userLangs?.common?.RESET}
                    </Button>
                  </div>
                  {/* {this.props.nameText === "addInvoice" ? (
                    ""
                  ) : ( */}
                  <div className=" filter">
                    <div className="tooltipIcon">
                      <Button
                       color=""
                        disabled={!navigator.onLine}
                        onClick={() => {
                          this.props.getAllExportData();
                        }}
                        className={`iconButtons ${
                          darkMode ? "actionButtonsDark" : ""
                        }`}
                      >
                        <img
                          style={{ margin: "-4px" }}
                          src={darkMode ? downloadDark : download}
                          alt="exp"
                        />
                      </Button>

                      <span className="tooltipIcontext" style={{ left: "10%" }}>
                        &nbsp;
                        {this.state.userLangs?.invoice?.DOWNLOAD_EXCEL ??
                          "Download Excel"}
                        &nbsp;
                      </span>
                    </div>
                  </div>
                  {/* )} */}
                </Col>
              </Row>
            </>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default QuickFilters;
