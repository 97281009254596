import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  CloseIcon,
  PlusIcon,
  DotIconSvg,
  MinusIcon,
  VerticalLine,
} from "../Common/CommonIcons";
import { Link } from "react-router-dom";
import Select from "react-select";

import { connect } from "react-redux";
import "./style.css";
import AtFoodPdf from "./Manual.pdf";
import zIndex from "@material-ui/core/styles/zIndex";
const isBaxter = process.env.REACT_APP_CLIENT === "3_BAXTER";
const logoImage = process.env.REACT_APP_DASHBOARD_LOGO_IMAGE
  ? require("../../assets/images/" + process.env.REACT_APP_DASHBOARD_LOGO_IMAGE)
  : "";
const newRtcImage = require('../../assets/images/NEWRTSLOGO-1.png')
const dashboardImage = require("../../assets/images/navigation/DASHBOARD.png");
const dotImage = require("../../assets/images/navigation/dot.png");
const logoMiniImage = process.env.REACT_APP_DASHBOARD_LOGO_MINI_IMAGE
  ? isBaxter
    ? require("../../assets/images/" +
        process.env.REACT_APP_DASHBOARD_LOGO_IMAGE)
    : require("../../assets/images/" +
        process.env.REACT_APP_DASHBOARD_LOGO_MINI_IMAGE)
  : "";
const routeOptimization = process.env.REACT_APP_ROUTE_OPTIMIZATION;
const loadOptimization = process.env.REACT_APP_LOAD_OPTIMIZATION;
const planningImage = require("../../assets/images/navigation/PLANNING.png");
const tripsImage = require("../../assets/images/navigation/TRIPS.png");
const mastersImage = require("../../assets/images/navigation/MASTERS.png");
const billingImage = require("../../assets/images/navigation/BILLING.png");
const reportsImage = require("../../assets/images/navigation/REPORTS.png");
const ControlTowerImage = require("../../assets/images/navigation/control tower icon.svg");
const downArrowImage = require("../../assets/images/navigation/downArrow.svg");
const upArrowImage = require("../../assets/images/navigation/upArrow.svg");
const helpImage = require("../../assets/images/navigation/help.svg");
const reportsImageDark = require("../../assets/images/navigation/ReportDark.png");

//const dashboardImage = require("../../assets/images/navigation/DASHBOARD.png");
//const dashboardImage = require("../../assets/images/navigation/DASHBOARD.png");
let MAIN_DASHBOARD = "MAIN_DASHBOARD";
let MAIN_INDENT_PLANNING = "MAIN_INDENT_PLANNING";
let MAIN_TRIPS = "MAIN_TRIPS";
let MAIN_MASTERS = "MAIN_MASTERS";
let MAIN_INVOICE = "MAIN_INVOICE";
let MAIN_REPORTS = "MAIN_REPORTS";
let MAIN_CONTROL_TOWER = "MAIN_CONTROL_TOWER";
let MAIN_ORDERS = "MAIN_ORDERS";
let DRIVERS = "DRIVERS";

var usrInfo;
var darkMode;
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

export class index extends Component {
  // ref = React.useRef(null);

  constructor(props) {
    super(props);
    this.state = {
      mainLink: "",
      subLink: {},
      activeSubLink: "",
      userInfo: {},
      userLangs: undefined,
      zoomVal: "",
      closeIcon: false,
      client: localStorage?.getItem("AppType")
        ? JSON.parse(localStorage.getItem("AppType"))
        : localStorage.getItem("tenants")
        ? JSON.parse(localStorage.getItem("tenants"))[0]
        : "",
      clientOptions: JSON.parse(localStorage.getItem("tenants")),
      isOpen: false,
    };
  }

  componentDidMount() {
    this.resize();
    setTimeout(this.updateDivHeight, 100); // Delay the height calculation
    window.addEventListener("resize", this.updateDivHeight);
  }

  updateDivHeight = () => {
    if (this.divRef) {
      const divHeight = this.divRef.getBoundingClientRect().height;
      this.setState({ divHeight });
    }
  };

  removeFilterItemFromLocalStorageTrip = () => {
    localStorage.removeItem("filters");
    localStorage.removeItem("offset");
  };
  removeFilterItemFromLocalStorageIndent = () => {
    localStorage.removeItem("IndentFilters");
    localStorage.removeItem("SecondaryIndentFilters");
    localStorage.removeItem("offset");
  };
  removeFilterItemExceptCurrentRoute = () => {
    //need to optimize code of filter(pending)
    const pathName = window.location.pathname;
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      // Remove keys starting with "myStatusFilter_" but not matching the current path
      if (key.startsWith("myStatusFilter_") && !key.endsWith(pathName)) {
        localStorage.removeItem(key);
      }
      if (key.startsWith("myOffsetFilter_")) {
        localStorage.removeItem(key);
      }

      if (key.startsWith("mySearchFilter_") && !key.endsWith(pathName)) {
        localStorage.removeItem(key);
      }

      // Remove "tripsFilterData" and "tripOffset" keys if the current path is not "/tripList"
      if (
        (key === "tripsFilterData" || key === "tripOffset") &&
        pathName !== "/tripList"
      ) {
        localStorage.removeItem("tripsFilterData");
        localStorage.removeItem("tripOffset");
      }

      // Remove "myFilterData" and "offset" keys if the current path is not "/orderList"
      if (
        (key === "myFilterData" || key === "tripOffset") &&
        pathName !== "/orderList"
      ) {
        localStorage.removeItem("myFilterData");
        localStorage.removeItem("offset");
      }
    }
  };

  removeFromLocalStorage = (key) => {
    window.localStorage.removeItem(key);
  };

  resize = () => {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    // else if( window.innerWidth >= 1500 && window.innerWidth <= 1600  ){
    //   this.setState({ zoomVal: 0.84 }, () => {
    //     document.body.style.zoom = this.state.zoomVal;
    //   });
    // }
    else {
      this.setState({ zoomVal: 0.9 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    }
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.state.userLangs === undefined &&
      (this.state.userLangs !== userLang || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
    if (
      this.props !== prevProps ||
      window.location.pathname !== prevProps.location.pathname
    ) {
      this.updateDivHeight();
    }
  };
  onResumePdfClick = () => {
    window.open(AtFoodPdf);
  };

  handleActiveLink = (mainLinkval) => {
    // console.log(mainLinkval);

    this.props.changeActiveTheme(mainLinkval);
    this.setState(
      {
        mainLink: mainLinkval,
        show: mainLinkval === this.state.mainLink ? !this.state.show : true,
      },
      () => {
        // console.log(this.state.mainLink);
      }
    );
  };

  // Method to handle click on submenu links
  handleActiveSubLink = (link) => {
    this.setState({ activeSubLink: link });
  };
  clientSelection = (value) => {
    localStorage.setItem("AppType", JSON.stringify(value));
    this.setState({
      client: value,
      isOpen: false,
    });
    if (localStorage.getItem("tenants")) {
      const tenantObj = JSON.parse(localStorage.getItem("tenants"));
      for (let i = 0; i < tenantObj.length; i++) {
        const element = tenantObj[i];
        console.log(element);
        if (element.label === value.label) {
          localStorage.setItem("AppTenantId", element._id);
        }
      }
    }
    window.location.reload();
  };

  toggleDropdown = () => {
    console.log(this.state.client);
    {
      this.setState({ isOpen: !this.state.isOpen });
    }
  };

  render() {
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let userType = authUser?.data?.role?.label;
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let initStorage = localStorage.getItem("Theme");
    if (initStorage === "dark__mode") {
      darkMode = true;
    } else if (initStorage === "light__mode") {
      darkMode = false;
    } else {
      darkMode = false;
    }

    const isBaxter = process.env.REACT_APP_CLIENT === "3_BAXTER";
    const logoHeight = isBaxter ? "200px" : "93px";
    const logoTop = isBaxter ? "2%" : "5%";
    let userInfo = authUser;
    // const keyboardArrowIconStyle = {
    //   position: "absolute",
    //   fontSize: 20,
    //   marginLeft: "50px",
    //   color: "#FFFFFF",
    //   right: "14%",
    //   marginTop: "0%",
    // };

    //new css for icon0
    const keyboardArrowIconStyle = {
      position: "absolute",
      fontSize: "20px",
      left: "calc(50% + 61px)",
      top: "8px",
      color: " #FFFFFF",
      transform: "translate(-50%, 0)",
    };
    const PlusMinusIconStyle = {
      margin: "auto",
      position: "relative",
      transform: "translate(-50%, 0px)",
    };
    const dotIcon = {
      zIndex: "999",
      position: "absolute",
      right: "159px",
      top: "1px",
      display: "inline-block",
    };
    const dotIconR = {
      zIndex: "999",
      position: "absolute",
      right: "171px",
      top: "-1px",
      display: "inline-block",
    };
    let pathNames = window.location.pathname;
    const { divHeight } = this.state;
    return (
      <>
        {!this.props.mini ? (
          <div
            id={this.props.show ? "my-sidebar-mobile" : "mySidebar"}
            className={`mainExpandedStyle ${
              darkMode ? "verticalMenuDark" : ""
            }`}
            onMouseEnter={this.props.menuBarIn}
            onMouseLeave={this.props.menuBarOut}
          >
            <div>
              <div>
                {this.props.show ? (
                  <span className="closeIconStyles">
                    <i
                      onClick={() =>
                        this.props?.closeMenuBar(this.state.closeIcon)
                      }
                    >
                      <CloseIcon color="white" />
                    </i>
                  </span>
                ) : (
                  ""
                )}

                {localStorage.getItem("tenants") && (
                  <div className="dropdown-wrapper">
                    <div style={{ position: "relative" }}>
                      <div
                        className="dropdownField"
                        onClick={() => this.toggleDropdown()}
                      >
                        <div style={{ fontWeight: "bold", fontSize: "18px" }}>
                          {this.state.client.label}
                        </div>
                        <div>
                          {!this.state.isOpen ? (
                            <img src={downArrowImage} />
                          ) : (
                            <img src={upArrowImage} />
                          )}
                        </div>
                      </div>
                      {this.state.isOpen ? (
                        <div className="options-container">
                          {this.state.clientOptions.map((item, index) => (
                            <div
                              key={index}
                              className={`options-item ${
                                this.state.client.label === item.label
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => this.clientSelection(item)}
                            >
                              {item.label}
                            </div>
                          ))}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )}

                <img
                  className="mainLogoStyleMobile"
                  src={logoImage}
                  alt="logo"
                  style={{
                    height: logoHeight,
                    top: logoTop,
                  }}
                />
              </div>
            </div>
            <div className="logoSepartorStyleMobile"></div>
            <div className="linksSection">
              {/* DASHBOARD */}
              {process.env.REACT_APP_CLIENT !== "3_BAXTER" &&
                (userInfo?.data?.role?.label !== "transporter" ||
                userInfo?.data?.userType === "internal" ? (
                  <div className="linkSection">
                    <div
                      className={`mainLink ${
                        (this.props.activeState === MAIN_DASHBOARD &&
                          (this.props.location.pathname ===
                            "/operationsDashboard" ||
                            this.props.location.pathname ===
                              "/transporterDashboard" ||
                            this.props.location.pathname ===
                              "/invoice-dashboard")) ||
                        this.props.activeTheme === "MAIN_DASHBOARD"
                          ? "activeLinkSection"
                          : null
                      }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_DASHBOARD);
                        this.removeFilterItemFromLocalStorageIndent();
                        this.removeFromLocalStorage("invoiceListFilters");
                        this.removeFromLocalStorage("filters");
                        this.removeFromLocalStorage("IndentFilters");
                      }}
                    >
                      <Link
                        to={`/${
                          localStorage.getItem("typeSelection") === "Secondary"
                            ? "secondary-"
                            : ""
                        }operationsDashboard`}
                      >
                        <div>
                          <img
                            src={dashboardImage}
                            alt="dashImg"
                            className="menuIcon"
                          />
                          <span className="mainLinkName">
                            {this.state.userLangs?.dashboard?.DASHBOARD}
                          </span>
                          {/* <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} /> */}
                        </div>
                      </Link>
                    </div>
                    <div
                      className={`subLinks ${
                        this.state.a === 1 ? "displaySubMenu" : "hideSubMenu"
                      }`}
                    ></div>
                  </div>
                ) : (
                  ""
                ))}
              {/* BAXTER DASHBOARD */}
              {process.env.REACT_APP_CLIENT === "3_BAXTER" &&
                // ["admin", "supervisor"].includes(userType) &&
                (userInfo ? (
                  <div className="linkSection">
                    <div
                      className={`mainLink ${
                        this.props.activeState === MAIN_DASHBOARD &&
                        this.props.location.pathname === "/operationalDashboard"
                          ? "activeLinkSection"
                          : null
                      }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_DASHBOARD);
                        this.removeFilterItemFromLocalStorageTrip();
                        this.removeFilterItemExceptCurrentRoute();
                      }}
                    >
                      <Link to="/operationalDashboard">
                        <div>
                          <img
                            src={dashboardImage}
                            alt="dashImg"
                            className="menuIcon"
                          />
                          <span className="mainLinkName">
                            {this.state.userLangs?.dashboard?.DASHBOARD}
                          </span>
                          {/* <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} /> */}
                        </div>
                      </Link>
                    </div>
                    <div
                      className={`subLinks ${
                        this.state.a === 1 ? "displaySubMenu" : "hideSubMenu"
                      }`}
                      onClick={() => {
                        this.removeFilterItemExceptCurrentRoute();
                      }}
                    ></div>
                  </div>
                ) : (
                  ""
                ))}
              {/* INDENT PLANNING */}
              {process.env.REACT_APP_CLIENT !== "3_BAXTER" &&
                (userInfo?.data?.role?.label !== "transporter" ? (
                  <div className="linkSection">
                    <div
                      className={`mainLink ${
                        this.props.activeState === MAIN_INDENT_PLANNING
                          ? "activeLinkSectionIndent"
                          : null
                      }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_INDENT_PLANNING);
                        this.removeFilterItemFromLocalStorageTrip();
                        this.removeFromLocalStorage("invoiceListFilters");
                        this.removeFromLocalStorage("filters");
                        this.removeFromLocalStorage("IndentFilters");
                        this.removeFromLocalStorage("invoiceGenerationFiliter");
                      }}
                    >
                      <div>
                        <img src={planningImage} alt="" className="menuIcon" />
                        <span className="mainLinkName">
                          {this.state.userLangs?.planning?.PLANNING_CAPS}
                        </span>
                      </div>
                      <div className="expandIcon">
                        {this.props.activeState === MAIN_INDENT_PLANNING &&
                        this.state.show === true ? (
                          // <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                          <i style={PlusMinusIconStyle}>
                            <MinusIcon />
                          </i>
                        ) : (
                          // <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                          <i style={PlusMinusIconStyle}>
                            <PlusIcon />
                          </i>
                        )}
                      </div>
                    </div>
                    <div
                      className={`subMenu ${
                        this.props.activeState === MAIN_INDENT_PLANNING &&
                        this.state.show === true
                          ? "displaySubMenu"
                          : "hideSubMenu"
                      }`}
                      // onClick={() => this.setState({mainLink:MAIN_INDENT_PLANNING})}
                    >
                      <div className="subMenuSec">
                        <div className="verticalLine">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="2"
                            height="68"
                            viewBox="0 0 2 68"
                            fill="none"
                          >
                            <path
                              d="M1 0L1 68"
                              stroke="#D9D9D9"
                              stroke-width="0.5"
                            />
                          </svg>
                        </div>

                        <div className="subMenuItems">
                          <Link
                            to={`/${
                              localStorage.getItem("typeSelection") ===
                              "Secondary"
                                ? "secondary-"
                                : ""
                            }indent-planning`}
                          >
                            <div
                              className={`subMenuLinkDiv ${
                                pathNames === "/indent-planning" ||
                                pathNames === "/secondary-indent-planning"
                                  ? "activeSubLinkI"
                                  : "inActiveSubLinkI"
                              }`}
                            >
                              <span className="subMenuLinkName">
                                {pathNames === "/indent-planning" ||
                                pathNames === "/secondary-indent-planning" ? (
                                  <i style={dotIcon} className="dot-display">
                                    <img src={dotImage} alt="" />
                                  </i>
                                ) : (
                                  ""
                                )}

                                {
                                  this.state.userLangs?.planning
                                    ?.INDENT_PLANNING
                                }
                              </span>
                            </div>
                          </Link>
                          <Link
                            to={`/${
                              localStorage.getItem("typeSelection") ===
                              "Secondary"
                                ? "secondary-"
                                : ""
                            }add-indent`}
                          >
                            <div
                              className={`subMenuLinkDiv ${
                                pathNames === "/add-indent" ||
                                pathNames === "/secondary-add-indent"
                                  ? "activeSubLinkI"
                                  : "inActiveSubLinkI"
                              }`}
                              onClick={() =>
                                this.removeFilterItemFromLocalStorageIndent()
                              }
                            >
                              <span className="subMenuLinkName">
                                {pathNames === "/add-indent" ||
                                pathNames === "/secondary-add-indent" ? (
                                  <i style={dotIcon} className="dot-display">
                                    {" "}
                                    <img src={dotImage} alt="" />
                                  </i>
                                ) : (
                                  ""
                                )}
                                {this.state.userLangs?.planning?.ADD_INDENT}
                              </span>
                            </div>
                          </Link>
                          {loadOptimization === "true" ? (
                            <Link to="/load-optimization">
                              <div
                                className={`subMenuLinkDiv ${
                                  pathNames === "/load-optimization"
                                    ? "activeSubLinkI"
                                    : "inActiveSubLinkI"
                                }`}
                              >
                                <span className="subMenuLinkName">
                                  {pathNames === "/load-optimization" ? (
                                    <i style={dotIcon} className="dot-display">
                                      {" "}
                                      <img src={dotImage} alt="" />
                                    </i>
                                  ) : (
                                    ""
                                  )}
                                  Load Optimization
                                </span>
                              </div>
                            </Link>
                          ) : (
                            <></>
                          )}
                          {routeOptimization === "true" ? (
                            <Link to="/Route-Optimization">
                              <div
                                className={`subMenuLinkDiv ${
                                  pathNames === "/hops"
                                    ? "activeSubLinkI"
                                    : "inActiveSubLinkI"
                                }`}
                              >
                                <span className="subMenuLinkName">
                                  {pathNames === "/Route-Optimization" ? (
                                    <i style={dotIcon} className="dot-display">
                                      {" "}
                                      <img src={dotImage} alt="" />
                                    </i>
                                  ) : (
                                    ""
                                  )}
                                  {"Route Optimization"}
                                  {/* {this.state.userLangs?.planning?.ADD_INDENT} */}
                                </span>
                              </div>
                            </Link>
                          ) : (
                            <></>
                          )}
                          {routeOptimization === "true" ? (
                            <Link to="/Route-Optimization">
                              <div
                                className={`subMenuLinkDiv ${
                                  pathNames === "/hops"
                                    ? "activeSubLinkI"
                                    : "inActiveSubLinkI"
                                }`}
                              >
                                <span className="subMenuLinkName">
                                  {pathNames === "/Route-Optimization" ? (
                                    <i style={dotIcon} className="dot-display">
                                      {" "}
                                      <img src={dotImage} alt="" />
                                    </i>
                                  ) : (
                                    ""
                                  )}
                                  {"Co-Ordinates"}
                                  {/* {this.state.userLangs?.planning?.ADD_INDENT} */}
                                </span>
                              </div>
                            </Link>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>

                      <div />
                    </div>
                  </div>
                ) : (
                  <div className="linkSection">
                    <div
                      className={`mainLink ${
                        this.props.activeState === MAIN_INDENT_PLANNING
                          ? "activeLinkSectionIndent"
                          : null
                      }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_INDENT_PLANNING);
                        this.removeFilterItemFromLocalStorageTrip();
                        this.removeFromLocalStorage("invoiceGenerationFiliter");
                      }}
                    >
                      <div>
                        <img src={planningImage} alt="" className="menuIcon" />
                        <span className="mainLinkName">
                          {this.state.userLangs?.planning?.PLANNING_CAPS}
                        </span>
                      </div>
                      <div className="expandIcon">
                        {this.props.activeState === MAIN_INDENT_PLANNING &&
                        this.state.show === true ? (
                          // <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                          <i style={PlusMinusIconStyle}>
                            <MinusIcon />
                          </i>
                        ) : (
                          // <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                          <i style={PlusMinusIconStyle}>
                            <PlusIcon />
                          </i>
                        )}
                      </div>
                    </div>
                    <div
                      className={`subMenu ${
                        this.props.activeState === MAIN_INDENT_PLANNING &&
                        this.state.show === true
                          ? "displaySubMenu"
                          : "hideSubMenu"
                      }`}
                      // onClick={() => this.setState({mainLink:MAIN_INDENT_PLANNING})}
                    >
                      <div className="subMenuSec">
                        <div className="verticalLine">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="2"
                            height="10"
                            viewBox="0 0 2 10"
                            fill="none"
                          >
                            <path
                              d="M1 0L1 10"
                              stroke="#D9D9D9"
                              stroke-width="0.5"
                            />
                          </svg>
                        </div>
                        <div className="subMenuItems">
                          <Link
                            to={`/${
                              localStorage.getItem("typeSelection") ===
                              "Secondary"
                                ? "secondary-"
                                : ""
                            }assigned-indent`}
                          >
                            <div
                              className={`subMenuLinkDiv ${
                                pathNames === "/assigned-indent" ||
                                pathNames === "/secondary-assigned-indent"
                                  ? "activeSubLinkI"
                                  : "inActiveSubLinkI"
                              }`}
                            >
                              <span className="subMenuLinkName">
                                {pathNames === "/assigned-indent" ||
                                pathNames === "/secondary-assigned-indent" ? (
                                  <i style={dotIcon} className="dot-display">
                                    {" "}
                                    <img src={dotImage} alt="" />
                                  </i>
                                ) : (
                                  ""
                                )}
                                {
                                  this.state.userLangs?.planning
                                    ?.INDENT_PLANNING
                                }
                              </span>
                            </div>
                          </Link>
                          {routeOptimization === "true" ? (
                            <Link to="/Route-Optimization">
                              <div
                                className={`subMenuLinkDiv ${
                                  pathNames === "/hops"
                                    ? "activeSubLinkI"
                                    : "inActiveSubLinkI"
                                }`}
                              >
                                <span className="subMenuLinkName">
                                  {pathNames === "/Route-Optimization" ? (
                                    <i style={dotIcon} className="dot-display">
                                      {" "}
                                      <img src={dotImage} alt="" />
                                    </i>
                                  ) : (
                                    ""
                                  )}
                                  {"Route-Optimization "}
                                  {/* {this.state.userLangs?.planning?.INDENT_PLANNING} */}
                                </span>
                              </div>
                            </Link>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              {process.env.REACT_APP_CLIENT === "3_BAXTER" && (
                <>
                  {/* ORDERS */}
                  <div className="linkSection">
                    <div
                      className={`mainLink ${
                        this.props.activeState === MAIN_ORDERS
                          ? "activeLinkSection"
                          : null
                      }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_ORDERS);
                        this.removeFilterItemFromLocalStorageTrip();
                      }}
                    >
                      <div>
                        <img src={planningImage} alt="" className="menuIcon" />
                        <span className="mainLinkName">
                          {this.state.userLangs?.orders?.ORDERS}
                        </span>
                      </div>
                      <div className="expandIcon">
                        {this.props.activeState === MAIN_ORDERS &&
                        this.state.show === true ? (
                          // <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                          <i style={PlusMinusIconStyle}>
                            <MinusIcon />
                          </i>
                        ) : (
                          // <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                          <i style={PlusMinusIconStyle}>
                            <PlusIcon />
                          </i>
                        )}
                      </div>
                    </div>
                    <div
                      className={`subMenu ${
                        this.props.activeState === MAIN_ORDERS &&
                        this.state.show === true
                          ? "displaySubMenu"
                          : "hideSubMenu"
                      }`}
                    >
                      <div className="subMenuSec">
                        <div className="verticalLine">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="2"
                            height="150"
                            viewBox="0 0 2 150"
                            fill="none"
                          >
                            <path
                              d="M1 0L1 150"
                              stroke="#D9D9D9"
                              stroke-width="0.5"
                            />
                          </svg>
                        </div>
                        <div className="subMenuItems">
                          <Link to="/ordersList">
                            <div
                              className={`subMenuLinkDiv ${
                                pathNames === "/ordersList"
                                  ? "activeSubLinkI"
                                  : "inActiveSubLinkI"
                              }`}
                              onClick={() => {
                                this.removeFilterItemExceptCurrentRoute();
                              }}
                            >
                              <span className="subMenuLinkName">
                                {pathNames === "/ordersList" ? (
                                  <i style={dotIcon} className="dot-display">
                                    {" "}
                                    <img src={dotImage} alt="" />
                                  </i>
                                ) : (
                                  ""
                                )}
                                {this.state.userLangs?.orders?.ORDERS ??
                                  "Orders"}
                              </span>
                            </div>
                          </Link>
                          {userType !== "customer" && userType !== "sales" ? (
                            <>
                              <Link to="/InvoiceList">
                                <div
                                  className={`subMenuLinkDiv ${
                                    pathNames === "/InvoiceList"
                                      ? "activeSubLinkI"
                                      : "inActiveSubLinkI"
                                  }`}
                                  onClick={() => {
                                    this.removeFilterItemExceptCurrentRoute();
                                  }}
                                >
                                  <span className="subMenuLinkName">
                                    {pathNames === "/InvoiceList" ? (
                                      <i
                                        style={dotIcon}
                                        className="dot-display"
                                      >
                                        {" "}
                                        <img src={dotImage} alt="" />
                                      </i>
                                    ) : (
                                      ""
                                    )}
                                    {
                                      this.state.userLangs?.invoice
                                        ?.INVOICE_LIST
                                    }
                                  </span>
                                </div>
                              </Link>
                              <Link to="/order/products">
                                <div
                                  className={`subMenuLinkDiv ${
                                    pathNames === "/order/products"
                                      ? "activeSubLinkI"
                                      : "inActiveSubLinkI"
                                  }`}
                                  onClick={() => {
                                    this.removeFilterItemExceptCurrentRoute();
                                  }}
                                >
                                  <span className="subMenuLinkName">
                                    {pathNames === "/order/products" ? (
                                      <i
                                        style={dotIcon}
                                        className="dot-display"
                                      >
                                        {" "}
                                        <img src={dotImage} alt="" />
                                      </i>
                                    ) : (
                                      ""
                                    )}
                                    {this.state.userLangs?.orders?.PRODUCT ??
                                      "Product"}
                                  </span>
                                </div>
                              </Link>
                              <Link to="/optimization">
                                <div
                                  className="subMenuLinkDiv"
                                  onClick={() => {
                                    this.removeFilterItemExceptCurrentRoute();
                                  }}
                                >
                                  <span className="subMenuLinkName">
                                    {pathNames === "/optimization" ? (
                                      <i
                                        style={dotIcon}
                                        className="dot-display"
                                      >
                                        {" "}
                                        <img src={dotImage} alt="" />
                                      </i>
                                    ) : (
                                      ""
                                    )}
                                    {this.state.userLangs?.masters
                                      ?.OPTIMIZATION ?? "Optimization"}
                                  </span>
                                </div>
                              </Link>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {/* TRIPS */}
              {process.env.REACT_APP_CLIENT !== "3_BAXTER" && (
                <div className="linkSection">
                  <div
                    className={`mainLink ${
                      this.props.activeState === MAIN_TRIPS
                        ? "activeLinkSection"
                        : null
                    }`}
                    onClick={() => {
                      this.handleActiveLink(MAIN_TRIPS);
                      this.removeFilterItemFromLocalStorageIndent();
                      this.removeFromLocalStorage("invoiceListFilters");
                      this.removeFromLocalStorage("filters");
                      this.removeFromLocalStorage("IndentFilters");
                      this.removeFromLocalStorage("invoiceGenerationFiliter");
                    }}
                  >
                    <div>
                      <img src={tripsImage} alt="" className="menuIcon" />
                      <span
                        className={`mainLinkName  ${
                          this.props.activeTheme === "MAIN_TRIPS"
                            ? "activeMenuTextFocus"
                            : null
                        }`}
                      >
                        {this.state.userLangs?.trip?.TRIPS}
                      </span>
                    </div>
                    <div className="expandIcon">
                      {this.props.activeState === MAIN_TRIPS &&
                      this.state.show === true ? (
                        <i style={PlusMinusIconStyle}>
                          <MinusIcon />
                        </i>
                      ) : (
                        // <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                        <i style={PlusMinusIconStyle}>
                          <PlusIcon />
                        </i>
                      )}
                    </div>
                  </div>
                  <div
                    className={`subMenu ${
                      this.props.activeState === MAIN_TRIPS &&
                      this.state.show === true
                        ? "displaySubMenu"
                        : "hideSubMenu"
                    }`}
                  >
                    <div className="subMenuSec">
                      <div className="verticalLine">
                        <svg
                          width="2"
                          height="34"
                          viewBox="0 0 2 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 0L1 34"
                            stroke="#D9D9D9"
                            stroke-width="0.5"
                          />
                        </svg>
                      </div>
                      <div className="subMenuItems">
                        <Link
                          to={`/${
                            localStorage.getItem("typeSelection") ===
                            "Secondary"
                              ? "secondary-"
                              : ""
                          }trips`}
                        >
                          <div
                            className={`subMenuLinkDiv ${
                              pathNames === "/trips" ||
                              pathNames === "/secondary-trips"
                                ? "activeSubLinkT"
                                : "inActiveSubLinkT"
                            }`}
                          >
                            <span className="subMenuLinkName">
                              {pathNames === "/trips" ||
                              pathNames === "/secondary-trips" ? (
                                <i style={dotIcon} className="dot-display">
                                  <DotIconSvg color="#A020F0" />
                                </i>
                              ) : (
                                ""
                              )}
                              {this.state.userLangs?.trip?.VIEW_ALL_TRIPS}
                            </span>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/*BAXTER TRIPS */}
              {process.env.REACT_APP_CLIENT === "3_BAXTER" &&
                userType !== "sales" &&
                userType !== "customer" && (
                  <div className="linkSection">
                    <div
                      className={`mainLink ${
                        this.props.activeState === MAIN_TRIPS
                          ? "activeLinkSection"
                          : null
                      }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_TRIPS);
                        this.removeFilterItemFromLocalStorageTrip();
                      }}
                    >
                      <div>
                        <img src={tripsImage} alt="" className="menuIcon" />
                        <span
                          className={`mainLinkName  ${
                            this.props.activeTheme === "MAIN_TRIPS"
                              ? "activeMenuTextFocus"
                              : null
                          }`}
                        >
                          {this.state.userLangs?.trip?.TRIPS}
                        </span>
                      </div>
                      <div className="expandIcon">
                        {this.props.activeState === MAIN_TRIPS &&
                        this.state.show === true ? (
                          <i style={PlusMinusIconStyle}>
                            <MinusIcon />
                          </i>
                        ) : (
                          // <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                          <i style={PlusMinusIconStyle}>
                            <PlusIcon />
                          </i>
                        )}
                      </div>
                    </div>
                    <div
                      className={`subMenu ${
                        this.props.activeState === MAIN_TRIPS &&
                        this.state.show === true
                          ? "displaySubMenu"
                          : "hideSubMenu"
                      }`}
                    >
                      <div className="subMenuSec">
                        <div className="verticalLine">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="2"
                            height="30"
                            viewBox="0 0 2 30"
                            fill="none"
                          >
                            <path
                              d="M1 0L1 30"
                              stroke="#D9D9D9"
                              stroke-width="0.5"
                            />
                          </svg>
                        </div>
                        <div className="subMenuItems">
                          <Link to="/tripList">
                            <div
                              className={`subMenuLinkDiv ${
                                pathNames === "/tripList"
                                  ? "activeSubLinkT"
                                  : "inActiveSubLinkT"
                              }`}
                              onClick={() => {
                                this.removeFilterItemExceptCurrentRoute();
                              }}
                            >
                              <span className="subMenuLinkName">
                                {pathNames === "/tripList" ? (
                                  <i style={dotIcon} className="dot-display">
                                    {" "}
                                    <DotIconSvg color="#A020F0" />
                                  </i>
                                ) : (
                                  ""
                                )}

                                {this.state.userLangs?.trip?.VIEW_ALL_TRIPS}
                              </span>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              {/* MASTERS */}
              {userType !== "customer" && userType !== "sales" && (
                <div className="linkSection">
                  <div
                    className={`mainLink ${
                      this.props.activeState === MAIN_MASTERS
                        ? "activeLinkSectionIndent"
                        : null
                    }`}
                    onClick={() => {
                      this.handleActiveLink(MAIN_MASTERS);
                      this.removeFilterItemFromLocalStorageIndent();
                      this.removeFilterItemFromLocalStorageTrip();
                      this.removeFilterItemExceptCurrentRoute();
                      this.removeFromLocalStorage("invoiceListFilters");
                      this.removeFromLocalStorage("filters");
                      this.removeFromLocalStorage("IndentFilters");
                      this.removeFromLocalStorage("invoiceGenerationFiliter");
                    }}
                  >
                    <div>
                      <img src={mastersImage} alt="" className="menuIcon" />
                      <span
                        className={`mainLinkName  ${
                          this.props.activeTheme === "MAIN_MASTERS"
                            ? "activeMenuTextFocus"
                            : null
                        }`}
                      >
                        {this.state.userLangs?.masters?.MASTERS}
                      </span>
                    </div>
                    <div className="expandIcon">
                      {this.props.activeState === MAIN_MASTERS &&
                      this.state.show === true ? (
                        // <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                        <i style={PlusMinusIconStyle}>
                          <MinusIcon />
                        </i>
                      ) : (
                        // <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                        <i style={PlusMinusIconStyle}>
                          <PlusIcon />
                        </i>
                      )}
                    </div>
                  </div>
                  <div
                    className={`subMenu ${
                      this.props.activeState === MAIN_MASTERS &&
                      this.state.show === true
                        ? "displaySubMenu"
                        : "hideSubMenu"
                    }`}
                  >
                    <div
                      className="subMenuSec"
                      ref={(ref) => (this.divRef = ref)}
                    >
                      <div className="verticalLine">
                        <svg
                          width="2"
                          height={divHeight}
                          viewBox={`0 0 2 ${divHeight}`}
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d={`M1 0L0.999985 ${divHeight}`}
                            stroke="#D9D9D9"
                            strokeWidth="0.5"
                          />
                        </svg>
                      </div>
                      <div className="subMenuItems">
                        {userInfo?.data?.role?.label !== "indent_planner" ? (
                          <Link to="/drivers">
                            <div
                              className={`subMenuLinkDiv ${
                                pathNames === "/drivers"
                                  ? "activeSubLinkM"
                                  : "inActiveSubLinkM"
                              }`}
                            >
                              <span className="subMenuLinkName">
                                {pathNames === "/drivers" ? (
                                  <i style={dotIcon} className="dot-display">
                                    {" "}
                                    <DotIconSvg color="#006400" />
                                  </i>
                                ) : (
                                  ""
                                )}
                                {this.state.userLangs?.masters?.DRIVERS}
                              </span>
                            </div>
                          </Link>
                        ) : (
                          ""
                        )}
                        <Link to="/vehicles">
                          <div
                            className={`subMenuLinkDiv ${
                              pathNames === "/vehicles"
                                ? "activeSubLinkM"
                                : "inActiveSubLinkM"
                            }`}
                          >
                            <span className="subMenuLinkName">
                              {pathNames === "/vehicles" ? (
                                <i style={dotIcon} className="dot-display">
                                  {" "}
                                  <DotIconSvg color="#006400" />
                                </i>
                              ) : (
                                ""
                              )}
                              {this.state.userLangs?.masters?.VEHICLES}
                            </span>
                          </div>
                        </Link>
                        {userInfo?.data?.role?.label === "Admin" ||
                        process.env.REACT_APP_CLIENT == "3_BAXTER" ||
                        userInfo?.data?.role?.label === "loading_unloading" ? (
                          <Link to="/vehicle-type">
                            <div
                              className={`subMenuLinkDiv ${
                                pathNames === "/vehicle-type"
                                  ? "activeSubLinkM"
                                  : "inActiveSubLinkM"
                              }`}
                            >
                              <span className="subMenuLinkName">
                                {pathNames === "/vehicle-type" ? (
                                  <i style={dotIcon} className="dot-display">
                                    {" "}
                                    <DotIconSvg color="#006400" />
                                  </i>
                                ) : (
                                  ""
                                )}
                                {this.state.userLangs?.masters?.VEHICLE_TYPE}
                              </span>
                            </div>
                          </Link>
                        ) : (
                          ""
                        )}
                        {userInfo?.data?.role?.label !== "transporter" ? (
                          <Link to="/user">
                            <div
                              className={`subMenuLinkDiv ${
                                pathNames === "/user"
                                  ? "activeSubLinkM"
                                  : "inActiveSubLinkM"
                              }`}
                            >
                              <span className="subMenuLinkName">
                                {pathNames === "/user" ? (
                                  <i style={dotIcon} className="dot-display">
                                    {" "}
                                    <DotIconSvg color="#006400" />
                                  </i>
                                ) : (
                                  ""
                                )}
                                {this.state.userLangs?.masters?.USERS}
                              </span>
                            </div>
                          </Link>
                        ) : (
                          ""
                        )}
                        {userInfo?.data?.role?.label !== "transporter" ? (
                          <Link to="/roles">
                            <div
                              className={`subMenuLinkDiv ${
                                pathNames === "/roles"
                                  ? "activeSubLinkM"
                                  : "inActiveSubLinkM"
                              }`}
                            >
                              <span className="subMenuLinkName">
                                {pathNames === "/roles" ? (
                                  <i style={dotIcon} className="dot-display">
                                    {" "}
                                    <DotIconSvg color="#006400" />
                                  </i>
                                ) : (
                                  ""
                                )}
                                Roles
                              </span>
                            </div>
                          </Link>
                        ) : (
                          ""
                        )}
                        {process.env.REACT_APP_CLIENT != "3_BAXTER" ? (
                          <>
                            <Link to="/hops">
                              <div
                                className={`subMenuLinkDiv ${
                                  pathNames === "/hops"
                                    ? "activeSubLinkM"
                                    : "inActiveSubLinkM"
                                }`}
                              >
                                <span className="subMenuLinkName">
                                  {pathNames === "/hops" ? (
                                    <i style={dotIcon} className="dot-display">
                                      {" "}
                                      <DotIconSvg color="#006400" />
                                    </i>
                                  ) : (
                                    ""
                                  )}
                                  Hops
                                </span>
                              </div>
                            </Link>
                            <Link
                              to={`/${
                                localStorage.getItem("typeSelection") ===
                                "Secondary"
                                  ? "secondary-"
                                  : ""
                              }freight`}
                            >
                              <div
                                className={`subMenuLinkDiv ${
                                  pathNames === "/secondary-freight" ||
                                  pathNames === "/freight"
                                    ? "activeSubLinkM"
                                    : "inActiveSubLinkM"
                                }`}
                              >
                                <span className="subMenuLinkName ">
                                  {pathNames === "/secondary-freight" ? (
                                    <i style={dotIcon} className="dot-display">
                                      {" "}
                                      <DotIconSvg color="#006400" />
                                    </i>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.userLangs?.masters?.FREIGHT ??
                                    "Freight"}
                                </span>
                              </div>
                            </Link>
                          </>
                        ) : (
                          ""
                        )}
                        {process.env.REACT_APP_CLIENT == "3_BAXTER" ? (
                          <>
                            <Link to="/customers">
                              <div
                                className={`subMenuLinkDiv ${
                                  pathNames === "/customers"
                                    ? "activeSubLinkM"
                                    : "inActiveSubLinkM"
                                }`}
                              >
                                <span className="subMenuLinkName">
                                  {pathNames === "/customers" ? (
                                    <i style={dotIcon} className="dot-display">
                                      {" "}
                                      <DotIconSvg color="#006400" />
                                    </i>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.userLangs?.masters?.CUSTOMER ??
                                    " Customers"}
                                </span>
                              </div>
                            </Link>
                            <Link to="/locations">
                              <div
                                className={`subMenuLinkDiv ${
                                  pathNames === "/locations"
                                    ? "activeSubLinkM"
                                    : "inActiveSubLinkM"
                                }`}
                              >
                                <span className="subMenuLinkName">
                                  {pathNames === "/locations" ? (
                                    <i style={dotIcon} className="dot-display">
                                      <DotIconSvg color="#006400" />
                                    </i>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.userLangs?.masters?.LOCATIONS ??
                                    "Locations"}
                                </span>
                              </div>
                            </Link>
                            <Link to="/products">
                              <div
                                className={`subMenuLinkDiv ${
                                  pathNames === "/products"
                                    ? "activeSubLinkM"
                                    : "inActiveSubLinkM"
                                }`}
                              >
                                <span className="subMenuLinkName">
                                  {pathNames === "/products" ? (
                                    <i style={dotIcon} className="dot-display">
                                      {" "}
                                      <DotIconSvg color="#006400" />
                                    </i>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.userLangs?.masters?.PRODUCTS ??
                                    "Products"}
                                </span>
                              </div>
                            </Link>
                            <Link to="/zoneToState">
                              <div
                                className={`subMenuLinkDiv ${
                                  pathNames === "/zoneToState"
                                    ? "activeSubLinkM"
                                    : "inActiveSubLinkM"
                                }`}
                              >
                                <span className="subMenuLinkName">
                                  {pathNames === "/zoneToState" ? (
                                    <i style={dotIcon} className="dot-display">
                                      {" "}
                                      <DotIconSvg color="#006400" />
                                    </i>
                                  ) : (
                                    ""
                                  )}
                                  {/* {this.state.userLangs?.masters?.PRODUCTS ?? "Products"} */}
                                  Zone to State Mapping
                                </span>
                              </div>
                            </Link>
                          </>
                        ) : (
                          ""
                        )}
                        {process.env.REACT_APP_CLIENT !== "3_BAXTER" ? (
                          <>
                            <Link to="/routes">
                              <div
                                className={`subMenuLinkDiv ${
                                  pathNames === "/routes"
                                    ? "activeSubLinkM"
                                    : "inActiveSubLinkM"
                                }`}
                              >
                                <span className="subMenuLinkName">
                                  {pathNames === "/routes" ? (
                                    <i style={dotIcon} className="dot-display">
                                      <DotIconSvg color="#006400" />
                                    </i>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.userLangs?.masters?.ROUTES
                                    ? this.state.userLangs?.masters?.ROUTES
                                    : "Routes"}
                                </span>
                              </div>
                            </Link>
                            <Link to="/indents">
                              <div
                                className={`subMenuLinkDiv ${
                                  pathNames === "/indents"
                                    ? "activeSubLinkM"
                                    : "inActiveSubLinkM"
                                }`}
                              >
                                <span className="subMenuLinkName">
                                  {pathNames === "/indents" ? (
                                    <i style={dotIcon} className="dot-display">
                                      <DotIconSvg color="#006400" />
                                    </i>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.userLangs?.masters?.INDENTS
                                    ? this.state.userLangs?.masters?.INDENTS
                                    : "Indents"}
                                </span>
                              </div>
                            </Link>
                            <Link to="/shipments">
                              <div
                                className={`subMenuLinkDiv ${
                                  pathNames === "/shipments"
                                    ? "activeSubLinkM"
                                    : "inActiveSubLinkM"
                                }`}
                              >
                                <span className="subMenuLinkName">
                                  {pathNames === "/shipments" ? (
                                    <i style={dotIcon} className="dot-display">
                                      <DotIconSvg color="#006400" />
                                    </i>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.userLangs?.masters?.SHIPMENTS
                                    ? this.state.userLangs?.masters?.SHIPMENTS
                                    : "Shipments"}
                                </span>
                              </div>
                            </Link>
                            {localStorage.getItem("typeSelection") ===
                              "Secondary" && (
                              <Link to="/secondary-customers">
                                <div
                                  className={`subMenuLinkDiv ${
                                    pathNames === "/secondary-customers"
                                      ? "activeSubLinkM"
                                      : "inActiveSubLinkM"
                                  }`}
                                >
                                  <span className="subMenuLinkName">
                                    {pathNames === "/secondary-customers" ? (
                                      <i
                                        style={dotIcon}
                                        className="dot-display"
                                      >
                                        {" "}
                                        <DotIconSvg color="#006400" />
                                      </i>
                                    ) : (
                                      ""
                                    )}
                                    {this.state.userLangs?.masters?.CUSTOMERS
                                      ? this.state.userLangs?.masters?.CUSTOMERS
                                      : "Customers"}
                                  </span>
                                </div>
                              </Link>
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* INVOICE */}
              {process.env.REACT_APP_CLIENT !== "3_BAXTER" &&
                (userInfo?.data?.role?.label === "transporter" ? (
                  <div className="linkSection">
                    <div
                      className={`mainLink ${
                        this.props.activeState === MAIN_INVOICE
                          ? "activeLinkSection"
                          : null
                      }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_INVOICE);
                        this.removeFilterItemFromLocalStorageIndent();
                        this.removeFilterItemFromLocalStorageTrip();
                        this.removeFromLocalStorage("invoiceListFilters");
                        this.removeFromLocalStorage("filters");
                        this.removeFromLocalStorage("IndentFilters");
                      }}
                    >
                      <div>
                        <img src={billingImage} alt="" className="menuIcon" />
                        <span
                          className={`mainLinkName  ${
                            this.props.activeTheme === "MAIN_INVOICE"
                              ? "activeMenuTextFocus"
                              : null
                          }`}
                        >
                          {this.state.userLangs?.dashboard?.INVOICE}
                        </span>
                      </div>
                      <div className="expandIcon">
                        {this.props.activeState === MAIN_INVOICE &&
                        this.state.show === true ? (
                          <i style={PlusMinusIconStyle}>
                            <MinusIcon />
                          </i>
                        ) : (
                          // <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                          <i style={PlusMinusIconStyle}>
                            <PlusIcon />
                          </i>
                        )}
                      </div>
                    </div>
                    <div
                      className={`subMenu ${
                        this.props.activeState === MAIN_INVOICE &&
                        this.state.show === true
                          ? "displaySubMenu"
                          : "hideSubMenu"
                      }`}
                    >
                      <div className="subMenuSec">
                        <div className="verticalLine">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="2"
                            height="72"
                            viewBox="0 0 2 68"
                            fill="none"
                          >
                            <path
                              d="M1 0L1 68"
                              stroke="#D9D9D9"
                              stroke-width="0.5"
                            />
                          </svg>
                        </div>
                        <div className="subMenuItems">
                          <Link to="/Billing/InvoiceList">
                            <div
                              className={`subMenuLinkDiv ${
                                pathNames === "/Billing/InvoiceList"
                                  ? "activeSubLinkIV"
                                  : "inActiveSubLinkIV"
                              }`}
                              onClick={() => {
                                this.removeFromLocalStorage(
                                  "invoiceGenerationFiliter"
                                );
                              }}
                            >
                              <span className="subMenuLinkName">
                                {pathNames === "/Billing/InvoiceList" ? (
                                  <i style={dotIcon} className="dot-display">
                                    {" "}
                                    <DotIconSvg color="#00ADF7" />
                                  </i>
                                ) : (
                                  ""
                                )}
                                {this.state.userLangs?.invoice?.INVOICE_LIST}
                              </span>
                            </div>
                          </Link>
                          <Link to="/invoiceGeneration">
                            <div
                              className={`subMenuLinkDiv ${
                                pathNames === "/invoiceGeneration"
                                  ? "activeSubLinkIV"
                                  : "inActiveSubLinkIV"
                              }`}
                            >
                              <span className="subMenuLinkName">
                                {pathNames === "/invoiceGeneration" ? (
                                  <i style={dotIcon} className="dot-display">
                                    {" "}
                                    <DotIconSvg color="#00ADF7" />
                                  </i>
                                ) : (
                                  ""
                                )}
                                {
                                  this.state.userLangs?.invoice
                                    ?.INVOICE_GENERATION
                                }
                              </span>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                ))}
              {process.env.REACT_APP_CLIENT !== "3_BAXTER" &&
                (userInfo?.data?.role?.label === "Admin" ||
                userInfo?.data?.role?.label === "billing_manager" ? (
                  <div className="linkSection">
                    <div
                      className={`mainLink ${
                        this.props.activeState === MAIN_INVOICE
                          ? "activeLinkSection"
                          : null
                      }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_INVOICE);
                        this.removeFilterItemFromLocalStorageIndent();
                        this.removeFilterItemFromLocalStorageTrip();
                      }}
                    >
                      <div>
                        <img src={billingImage} alt="" className="menuIcon" />
                        <span
                          className={`mainLinkName  ${
                            this.props.activeTheme === "MAIN_INVOICE"
                              ? "activeMenuTextFocus"
                              : null
                          }`}
                        >
                          {this.state.userLangs?.dashboard?.INVOICE}
                        </span>
                      </div>
                      <div className="expandIcon">
                        {this.props.activeState === MAIN_INVOICE &&
                        this.state.show === true ? (
                          <i style={PlusMinusIconStyle}>
                            <MinusIcon />
                          </i>
                        ) : (
                          <i style={PlusMinusIconStyle}>
                            <PlusIcon />
                          </i>
                        )}
                      </div>
                    </div>
                    <div
                      className={`subMenu ${
                        this.props.activeState === MAIN_INVOICE &&
                        this.state.show === true
                          ? "displaySubMenu"
                          : "hideSubMenu"
                      }`}
                    >
                      <div className="subMenuSec">
                        <div className="verticalLine">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="2"
                            height="72"
                            viewBox="0 0 2 68"
                            fill="none"
                          >
                            <path
                              d="M1 0L1 68"
                              stroke="#D9D9D9"
                              stroke-width="0.5"
                            />
                          </svg>
                        </div>
                        <div className="subMenuItems">
                          <Link to="/BillingIndex">
                            <div
                              className={`subMenuLinkDiv ${
                                pathNames === "/BillingIndex"
                                  ? "activeSubLinkIV"
                                  : "inActiveSubLinkIV"
                              }`}
                            >
                              <span className="subMenuLinkName">
                                {pathNames === "/BillingIndex" ? (
                                  <i style={dotIcon} className="dot-display">
                                    {" "}
                                    <DotIconSvg color="#00ADF7" />
                                  </i>
                                ) : (
                                  ""
                                )}
                                {this.state.userLangs?.invoice?.AGREEMENT}
                              </span>
                            </div>
                          </Link>
                          <Link to="/Billing/InvoiceList">
                            <div
                              className={`subMenuLinkDiv ${
                                pathNames === "/Billing/InvoiceList"
                                  ? "activeSubLinkIV"
                                  : "inActiveSubLinkIV"
                              }`}
                            >
                              <span className="subMenuLinkName">
                                {pathNames === "/Billing/InvoiceList" ? (
                                  <i style={dotIcon} className="dot-display">
                                    {" "}
                                    <DotIconSvg color="#00ADF7" />
                                  </i>
                                ) : (
                                  ""
                                )}
                                {this.state.userLangs?.invoice?.INVOICE_LIST}
                              </span>
                            </div>
                          </Link>
                          <Link to="/tripBills">
                            <div
                              className={`subMenuLinkDiv ${
                                pathNames === "/tripBills"
                                  ? "activeSubLinkIV"
                                  : "inActiveSubLinkIV"
                              }`}
                            >
                              <span className="subMenuLinkName">
                                {pathNames === "/tripBills" ? (
                                  <i style={dotIcon} className="dot-display">
                                    {" "}
                                    <DotIconSvg color="#00ADF7" />
                                  </i>
                                ) : (
                                  ""
                                )}
                                {this.state.userLangs?.invoice?.ADD_BILL}
                              </span>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                ))}
              {process.env.REACT_APP_CLIENT !== "3_BAXTER" &&
                (userInfo?.data?.role?.label === "billing_team_member" ? (
                  <div className="linkSection">
                    <div
                      className={`mainLink ${
                        this.props.activeState === MAIN_INVOICE
                          ? "activeLinkSection"
                          : null
                      }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_INVOICE);
                        this.removeFilterItemFromLocalStorageIndent();
                        this.removeFilterItemFromLocalStorageTrip();
                      }}
                    >
                      <div>
                        <img src={billingImage} alt="" className="menuIcon" />
                        <span
                          className={`mainLinkName  ${
                            this.props.activeTheme === "MAIN_INVOICE"
                              ? "activeMenuTextFocus"
                              : null
                          }`}
                        >
                          {this.state.userLangs?.dashboard?.INVOICE}
                        </span>
                      </div>
                      <div className="expandIcon">
                        {this.props.activeState === MAIN_INVOICE &&
                        this.state.show === true ? (
                          <i style={PlusMinusIconStyle}>
                            <MinusIcon />
                          </i>
                        ) : (
                          // <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                          <i style={PlusMinusIconStyle}>
                            <PlusIcon />
                          </i>
                        )}
                      </div>
                    </div>
                    <div
                      className={`subMenu ${
                        this.props.activeState === MAIN_INVOICE &&
                        this.state.show === true
                          ? "displaySubMenu"
                          : "hideSubMenu"
                      }`}
                    >
                      <div className="subMenuSec">
                        <div className="verticalLine">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="2"
                            height="72"
                            viewBox="0 0 2 68"
                            fill="none"
                          >
                            <path
                              d="M1 0L1 68"
                              stroke="#D9D9D9"
                              stroke-width="0.5"
                            />
                          </svg>
                        </div>
                        <div className="subMenuItems">
                          <Link to="/BillingIndex">
                            <div
                              className={`subMenuLinkDiv ${
                                pathNames === "/BillingIndex"
                                  ? "activeSubLinkIV"
                                  : "inActiveSubLinkIV"
                              }`}
                            >
                              <span className="subMenuLinkName">
                                {pathNames === "/BillingIndex" ? (
                                  <i style={dotIcon} className="dot-display">
                                    {" "}
                                    <DotIconSvg color="#00ADF7" />
                                  </i>
                                ) : (
                                  ""
                                )}
                                {this.state.userLangs?.invoice?.AGREEMENT}
                              </span>
                            </div>
                          </Link>
                          <Link to="/Billing/InvoiceList">
                            <div
                              className={`subMenuLinkDiv ${
                                pathNames === "/Billing/InvoiceList"
                                  ? "activeSubLinkIV"
                                  : "inActiveSubLinkIV"
                              }`}
                            >
                              <span className="subMenuLinkName">
                                {pathNames === "/Billing/InvoiceList" ? (
                                  <i style={dotIcon} className="dot-display">
                                    {" "}
                                    <DotIconSvg color="#00ADF7" />
                                  </i>
                                ) : (
                                  ""
                                )}
                                {this.state.userLangs?.invoice?.INVOICE_LIST}
                              </span>
                            </div>
                          </Link>
                          <Link to="/tripBills">
                            <div
                              className={`subMenuLinkDiv ${
                                pathNames === "/tripBills"
                                  ? "activeSubLinkIV"
                                  : "inActiveSubLinkIV"
                              }`}
                            >
                              <span className="subMenuLinkName">
                                {pathNames === "/tripBills" ? (
                                  <i style={dotIcon} className="dot-display">
                                    {" "}
                                    <DotIconSvg color="#00ADF7" />
                                  </i>
                                ) : (
                                  ""
                                )}
                                {this.state.userLangs?.invoice?.ADD_BILL}
                              </span>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                ))}
              {process.env.REACT_APP_CLIENT !== "3_BAXTER" &&
                (userInfo?.data?.role?.label === "loading_unloading" ? (
                  <div className="linkSection">
                    <div
                      className={`mainLink ${
                        this.props.activeState === MAIN_INVOICE
                          ? "activeLinkSection"
                          : null
                      }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_INVOICE);
                        this.removeFilterItemFromLocalStorageIndent();
                        this.removeFilterItemFromLocalStorageTrip();
                      }}
                    >
                      <div>
                        <img src={billingImage} alt="" className="menuIcon" />
                        <span
                          className={`mainLinkName  ${
                            this.props.activeTheme === "MAIN_INVOICE"
                              ? "activeMenuTextFocus"
                              : null
                          }`}
                        >
                          {this.state.userLangs?.dashboard?.INVOICE}
                        </span>
                      </div>
                      <div className="expandIcon">
                        {this.props.activeState === MAIN_INVOICE &&
                        this.state.show === true ? (
                          <i style={PlusMinusIconStyle}>
                            <MinusIcon />
                          </i>
                        ) : (
                          // <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                          <i style={PlusMinusIconStyle}>
                            <PlusIcon />
                          </i>
                        )}
                      </div>
                    </div>
                    <div
                      className={`subMenu ${
                        this.props.activeState === MAIN_INVOICE &&
                        this.state.show === true
                          ? "displaySubMenu"
                          : "hideSubMenu"
                      }`}
                    >
                      <div className="subMenuSec">
                        <div className="verticalLine">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="2"
                            height="72"
                            viewBox="0 0 2 68"
                            fill="none"
                          >
                            <path
                              d="M1 0L1 68"
                              stroke="#D9D9D9"
                              stroke-width="0.5"
                            />
                          </svg>
                        </div>
                        <div className="subMenuItems">
                          <Link to="/BillingIndex">
                            <div
                              className={`subMenuLinkDiv ${
                                pathNames === "/BillingIndex"
                                  ? "activeSubLinkIV"
                                  : "inActiveSubLinkIV"
                              }`}
                            >
                              <span className="subMenuLinkName">
                                {pathNames === "/BillingIndex" ? (
                                  <i style={dotIcon} className="dot-display">
                                    {" "}
                                    <DotIconSvg color="#00ADF7" />
                                  </i>
                                ) : (
                                  ""
                                )}
                                {this.state.userLangs?.invoice?.AGREEMENT}
                              </span>
                            </div>
                          </Link>
                          <Link to="/tripBills">
                            <div
                              className={`subMenuLinkDiv ${
                                pathNames === "/tripBills"
                                  ? "activeSubLinkIV"
                                  : "inActiveSubLinkIV"
                              }`}
                            >
                              <span className="subMenuLinkName">
                                {pathNames === "/tripBills" ? (
                                  <i style={dotIcon} className="dot-display">
                                    {" "}
                                    <DotIconSvg color="#00ADF7" />
                                  </i>
                                ) : (
                                  ""
                                )}
                                {this.state.userLangs?.invoice?.ADD_BILL}
                              </span>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                ))}
              {/* INVOICE */}
              {/* <InvoiceMenu
                userRole={userInfo?.data?.role?.label}
                handleActiveLink={this.handleActiveLink}
                show={this.state.show}
                keyboardArrowIconStyle={keyboardArrowIconStyle}
              /> */}
              {/* REPORTS */}
              {process.env.REACT_APP_CLIENT !== "3_BAXTER" &&
                (userInfo?.data?.role?.label !== "transporter" ? (
                  <div className="linkSection">
                    <div
                      className={`mainLink ${
                        this.props.activeState === MAIN_REPORTS
                          ? "activeLinkSection"
                          : null
                      }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_REPORTS);
                        this.removeFilterItemFromLocalStorageIndent();
                        this.removeFilterItemFromLocalStorageTrip();
                        this.removeFromLocalStorage("invoiceListFilters");
                        this.removeFromLocalStorage("filters");
                        this.removeFromLocalStorage("IndentFilters");
                      }}
                    >
                      <div>
                        {darkMode ? (
                          <img
                            src={reportsImageDark}
                            alt=""
                            className="menuIcon"
                          />
                        ) : (
                          <img src={reportsImage} alt="" className="menuIcon" />
                        )}

                        <span className="mainLinkName">
                          {this.state.userLangs?.report?.REPORTS}
                        </span>
                      </div>
                      <div className="expandIcon">
                        {this.props.activeTheme === MAIN_REPORTS &&
                        this.state.show === true ? (
                          <i style={PlusMinusIconStyle}>
                            <MinusIcon />
                          </i>
                        ) : (
                          // <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                          <i style={PlusMinusIconStyle}>
                            <PlusIcon />
                          </i>
                        )}
                      </div>
                    </div>
                    <div
                      className={`subMenu ${
                        this.props.activeState === MAIN_REPORTS &&
                        this.state.show === true
                          ? "displaySubMenu"
                          : "hideSubMenu"
                      }`}
                    >
                      <div className="subMenuSec">
                        <div className="verticalLineR vehicle-placement-detail">
                          <svg
                            width="2"
                            height="220"
                            viewBox="0 0 2 220"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 0L1 220"
                              stroke="#D9D9D9"
                              stroke-width="0.5"
                            />
                          </svg>
                        </div>
                        <div className="subMenuItemsReport">
                          <Link to="/Reports">
                            <div
                              className={`subMenuLinkDiv ${
                                pathNames === "/Reports"
                                  ? "activeSubLinkR"
                                  : "inActiveSubLinkR"
                              }`}
                            >
                              <span className="subMenuLinkName">
                                {pathNames === "/Reports" ? (
                                  <i style={dotIconR} className="dot-display">
                                    {" "}
                                    <DotIconSvg color="#05284E" />
                                  </i>
                                ) : (
                                  ""
                                )}
                                {
                                  this.state.userLangs?.report
                                    ?.TRANSPORTER_PERFORMANCE
                                }
                              </span>
                            </div>
                          </Link>

                          <Link to="/indentReport-analysis">
                            <div
                              className={`subMenuLinkDiv ${
                                pathNames === "/indentReport-analysis"
                                  ? "activeSubLinkR"
                                  : "inActiveSubLinkR"
                              }`}
                            >
                              {/* <div className="subMenuLinkName"> */}
                              <span className="subMenuLinkName">
                                {pathNames === "/indentReport-analysis" ? (
                                  <i style={dotIconR} className="dot-display">
                                    {" "}
                                    <DotIconSvg color="#05284E" />
                                  </i>
                                ) : (
                                  ""
                                )}
                                {
                                  this.state.userLangs?.report
                                    ?.INDENT_ANALYSIS_REPORT
                                }
                              </span>
                            </div>
                          </Link>
                          <Link to="/vehicle-placement">
                            <div
                              className={`subMenuLinkDiv ${
                                pathNames === "/vehicle-placement"
                                  ? "activeSubLinkR"
                                  : "inActiveSubLinkR"
                              }`}
                            >
                              <span className="subMenuLinkName">
                                {pathNames === "/vehicle-placement" ? (
                                  <i style={dotIconR} className="dot-display">
                                    {" "}
                                    <DotIconSvg color="#05284E" />
                                  </i>
                                ) : (
                                  ""
                                )}
                                {this.state.userLangs?.report
                                  ?.VEHICLE_PLACEMENT_REPORT
                                  ? this.state.userLangs?.report
                                      ?.VEHICLE_PLACEMENT_REPORT
                                  : "Vehicle Placement Report"}
                              </span>
                            </div>
                          </Link>

                          <Link
                            to={`/${
                              localStorage.getItem("typeSelection") ===
                              "Secondary"
                                ? "secondary-"
                                : ""
                            }vehicle-placement-detail`}
                          >
                            <div
                              className={`subMenuLinkDiv ${
                                pathNames === "/vehicle-placement-detail" ||
                                pathNames ===
                                  "/Secondary-vehicle-placement-detail"
                                  ? "activeSubLinkR"
                                  : "inActiveSubLinkR"
                              }`}
                            >
                              <span className="subMenuLinkName">
                                {pathNames === "/vehicle-placement-detail" ||
                                pathNames ===
                                  "/Secondary-vehicle-placement-detail" ? (
                                  <i style={dotIconR} className="dot-display">
                                    {" "}
                                    <DotIconSvg color="#05284E" />
                                  </i>
                                ) : (
                                  ""
                                )}
                                {this.state.userLangs?.report
                                  ?.VEHICLE_ANALYSIS_REPORT
                                  ? this.state.userLangs?.report
                                      ?.VEHICLE_ANALYSIS_REPORT
                                  : "Vehicle  Analysis Report"}
                              </span>
                            </div>
                          </Link>
                          <Link to="/ontime-delivery-report">
                            <div
                              className={`subMenuLinkDiv ${
                                pathNames === "/ontime-delivery-report"
                                  ? "activeSubLinkR"
                                  : "inActiveSubLinkR"
                              }`}
                            >
                              <span className="subMenuLinkName">
                                {pathNames === "/ontime-delivery-report" ? (
                                  <i style={dotIconR} className="dot-display">
                                    {" "}
                                    <DotIconSvg color="#05284E" />
                                  </i>
                                ) : (
                                  ""
                                )}
                                {this.state.userLangs?.report
                                  ?.ON_TIME_DELIVERY_REPORT1 ??
                                  "On Time Delivery Report  "}
                              </span>
                            </div>
                          </Link>
                          <Link to="/share-of-business">
                            <div
                              className={`subMenuLinkDiv ${
                                pathNames === "/share-of-business"
                                  ? "activeSubLinkR"
                                  : "inActiveSubLinkR"
                              }`}
                            >
                              {/* <div className="subMenuLinkName"> */}
                              <span className="subMenuLinkName">
                                {pathNames === "/share-of-business" ? (
                                  <i style={dotIconR} className="dot-display">
                                    {" "}
                                    <DotIconSvg color="#05284E" />
                                  </i>
                                ) : (
                                  ""
                                )}
                                Share Of Business
                              </span>
                              {/* </div> */}
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                ))}
              {/* BAXTER REPORTS */}
              {process.env.REACT_APP_CLIENT === "3_BAXTER" &&
              userType !== "customer" &&
              userType !== "sales" ? (
                <div className="linkSection">
                  <div
                    className={`mainLink ${
                      this.props.activeState === MAIN_REPORTS
                        ? "activeLinkSection"
                        : null
                    }`}
                    onClick={() => {
                      this.handleActiveLink(MAIN_REPORTS);
                      this.removeFilterItemFromLocalStorageTrip();
                    }}
                  >
                    <div>
                      {darkMode ? (
                        <img
                          src={reportsImageDark}
                          alt=""
                          className="menuIcon"
                        />
                      ) : (
                        <img src={reportsImage} alt="" className="menuIcon" />
                      )}
                      <span className="mainLinkName">
                        {this.state.userLangs?.report?.REPORTS}
                      </span>
                    </div>
                    <div className="expandIcon">
                      {this.props.activeTheme === MAIN_REPORTS &&
                      this.state.show === true ? (
                        <i style={PlusMinusIconStyle}>
                          <MinusIcon />
                        </i>
                      ) : (
                        // <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                        <i style={PlusMinusIconStyle}>
                          <PlusIcon />
                        </i>
                      )}
                    </div>
                  </div>
                  <div
                    className={`subMenu ${
                      this.props.activeState === MAIN_REPORTS &&
                      this.state.show === true
                        ? "displaySubMenu"
                        : "hideSubMenu"
                    }`}
                  >
                    <div className="subMenuSec">
                      <div className="verticalLineR">
                        <svg
                          width="2"
                          height="160"
                          viewBox="0 0 2 160"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 0L0.999985 160"
                            stroke="#D9D9D9"
                            stroke-width="0.5"
                          />
                        </svg>
                      </div>
                      <div className="subMenuItemsReport">
                        <Link to="/logbook">
                          <div
                            className={`subMenuLinkDiv ${
                              pathNames === "/logbook"
                                ? "activeSubLinkR"
                                : "inActiveSubLinkR"
                            }`}
                          >
                            <div
                              className="subMenuLinkName"
                              onClick={() => {
                                this.removeFilterItemExceptCurrentRoute();
                              }}
                            >
                              <span className="subMenuLinkName">
                                {pathNames === "/logbook" ? (
                                  <i style={dotIconR} className="dot-display">
                                    {" "}
                                    <DotIconSvg color="#05284E" />
                                  </i>
                                ) : (
                                  ""
                                )}
                                {this.state.userLangs?.report?.LOGBOOK ??
                                  "Logbook"}
                              </span>
                            </div>
                          </div>
                        </Link>
                        <Link to="/DispatchLeadReports">
                          <div
                            className={`subMenuLinkDiv ${
                              pathNames === "/DispatchLeadReports"
                                ? "activeSubLinkR"
                                : "inActiveSubLinkR"
                            }`}
                          >
                            <span
                              className="subMenuLinkName"
                              onClick={() => {
                                this.removeFilterItemExceptCurrentRoute();
                              }}
                            >
                              {pathNames === "/DispatchLeadReports" ? (
                                <i style={dotIconR} className="dot-display">
                                  {" "}
                                  <DotIconSvg color="#05284E" />
                                </i>
                              ) : (
                                ""
                              )}
                              {this.state.userLangs?.report
                                ?.DISPATCH_LEAD_TIME ?? "Dispatch Lead Time"}
                            </span>
                          </div>
                        </Link>
                        <Link to="/BifuricationVolumeReports">
                          <div
                            className={`subMenuLinkDiv ${
                              pathNames === "/BifuricationVolumeReports"
                                ? "activeSubLinkR"
                                : "inActiveSubLinkR"
                            }`}
                          >
                            <div
                              className="subMenuLinkName"
                              onClick={() => {
                                this.removeFilterItemExceptCurrentRoute();
                              }}
                            >
                              <span className="subMenuLinkName">
                                {pathNames === "/BifuricationVolumeReports" ? (
                                  <i style={dotIconR} className="dot-display">
                                    {" "}
                                    <DotIconSvg color="#05284E" />
                                  </i>
                                ) : (
                                  ""
                                )}
                                {this.state.userLangs?.report
                                  ?.BIFURICATION_VOLUME_REPORT ??
                                  "Bifurication Vol. Report"}
                              </span>
                            </div>
                          </div>
                        </Link>

                        <Link to="/BifuricationRevenueReports">
                          <div
                            className={`subMenuLinkDiv ${
                              pathNames === "/BifuricationRevenueReports"
                                ? "activeSubLinkR"
                                : "inActiveSubLinkR"
                            }`}
                            onClick={() => {
                              this.removeFilterItemExceptCurrentRoute();
                            }}
                          >
                            <span className="subMenuLinkName">
                              {pathNames === "/BifuricationRevenueReports" ? (
                                <i style={dotIconR} className="dot-display">
                                  {" "}
                                  <DotIconSvg color="#05284E" />
                                </i>
                              ) : (
                                ""
                              )}
                              {this.state.userLangs?.report
                                ?.BIFURICATION_REVENUE_REPORT ??
                                "Bifurication Rev. Report"}
                            </span>
                          </div>
                        </Link>

                        <Link to="/TruckUtilizationReports">
                          <div
                            className={`subMenuLinkDiv ${
                              pathNames === "/TruckUtilizationReports"
                                ? "activeSubLinkR"
                                : "inActiveSubLinkR"
                            }`}
                          >
                            <span className="subMenuLinkName">
                              {pathNames === "/TruckUtilizationReports" ? (
                                <i style={dotIconR} className="dot-display">
                                  {" "}
                                  <DotIconSvg color="#05284E" />
                                </i>
                              ) : (
                                ""
                              )}
                              {this.state.userLangs?.report
                                ?.TRUCK_UTILIZATION_REPORT ??
                                "Truck Utilization Report"}
                            </span>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* Control Tower */}
              {process.env.REACT_APP_CLIENT !== "3_BAXTER" &&
                (userInfo?.data?.role?.label !== "transporter" ? (
                  <div className="linkSection">
                    <div
                      className={`mainLink ${
                        this.props.activeState === MAIN_CONTROL_TOWER &&
                        this.props.location.pathname === "/ControlTower"
                          ? "activeLinkSection"
                          : null
                      }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_CONTROL_TOWER);
                        this.removeFilterItemFromLocalStorageIndent();
                        this.removeFilterItemFromLocalStorageTrip();
                        this.removeFromLocalStorage("invoiceListFilters");
                        this.removeFromLocalStorage("filters");
                        this.removeFromLocalStorage("IndentFilters");
                      }}
                    >
                      <Link to="/ControlTower">
                        <div>
                          <img
                            src={ControlTowerImage}
                            alt="controlImg"
                            className="menuIcon"
                          />
                          <span
                            className={`mainLinkName  ${
                              this.props.activeTheme === "MAIN_CONTROL_TOWER"
                                ? "activeMenuTextFocus"
                                : null
                            }`}
                          >
                            {this.state.userLangs?.controlTower?.CONTROL_TOWER}
                          </span>
                          {/* <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} /> */}
                        </div>
                      </Link>
                    </div>
                    <div
                      className={`subLinks ${
                        this.state.a === 1 ? "displaySubMenu" : "hideSubMenu"
                      }`}
                    ></div>
                  </div>
                ) : (
                  ""
                ))}
              {/* HELP */}

              {process.env.REACT_APP_CLIENT !== "3_BAXTER" && (
                <div className="helpMenuStyle" onClick={this.onResumePdfClick}>
                  <div className="mainLinkHelp">
                    {/* Icon */}
                    {/* <img
                      // src={helpImage}
                      alt="controlImg"
                      className="helpIcon"
                    /> */}
                    {/* Help Name */}
                    <a
                      href={AtFoodPdf}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="helpName">
                        {this.state.userLangs?.common?.HELP}
                      </span>
                    </a>
                  </div>
                </div>
              )}

              {/* <div className="toggle-switch-1 ">
                <Link
                  to={
                    (window.location.pathname === "/secondary-add-indent" &&
                      "/add-indent") ||
                    (window.location.pathname ===
                      "/secondary-indent-planning" &&
                      "/indent-planning") ||
                    (window.location.pathname ===
                      "/secondary-assigned-indent" &&
                      "/assigned-indent")
                  }
                >
                  <div
                    className={
                      window.location.pathname === "/add-indent" ||
                      window.location.pathname === "/indent-planning" ||
                      window.location.pathname === "/assigned-indent"
                        ? "isActivated"
                        : ""
                    }
                  >
                    Primary
                  </div>
                </Link>
                <Link
                  to={
                    (window.location.pathname === "/add-indent" &&
                      "/secondary-add-indent") ||
                    (window.location.pathname === "/indent-planning" &&
                      "/secondary-indent-planning") ||
                    (window.location.pathname === "/assigned-indent" &&
                      "/secondary-assigned-indent")
                  }
                >
                  <div
                    className={
                      window.location.pathname === "/secondary-add-indent" ||
                      window.location.pathname ===
                        "/secondary-indent-planning" ||
                      window.location.pathname === "/secondary-assigned-indent"
                        ? "isActivated"
                        : ""
                    }
                  >
                    Secondary
                  </div>
                </Link>
              </div> */}
            </div>
          </div>
        ) : (
          <div
            id="mySidebar"
            className={`mainExpandedStyles ${
              darkMode ? "verticalMenuDark" : ""
            }`}
            onMouseEnter={this.props.menuBarIn}
            onMouseLeave={this.props.menuBarOut}
          >
            <div>
              <img className="mainLogoStyleMini" src={logoMiniImage} alt="" />
            </div>
            <div className="logoSepartorStyle"></div>
            <div className="linksSectionMini">
              {/* DASHBOARD */}
              {process.env.REACT_APP_CLIENT !== "3_BAXTER" &&
                (userInfo?.data?.role?.label !== "transporter" ||
                userInfo?.data?.userType === "internal" ? (
                  <div className="linkSection">
                    <div
                      className={`mainLinkMini ${
                        this.props.activeState === MAIN_DASHBOARD &&
                        (this.props.location.pathname ===
                          "/operationsDashboard" ||
                          this.props.location.pathname ===
                            "/transporterDashboard" ||
                          this.props.location.pathname === "/invoice-dashboard")
                          ? "activeLinkSection"
                          : null
                      }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_DASHBOARD);
                        this.removeFilterItemFromLocalStorageIndent();
                        this.removeFilterItemFromLocalStorageTrip();
                        this.removeFilterItemExceptCurrentRoute();
                      }}
                    >
                      <Link to="/dashboard">
                        <div>
                          <img src={dashboardImage} alt="" />
                          <span className="mainLinkName"></span>
                          {/* <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} /> */}
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : (
                  ""
                ))}
              {/* BAXTER DASHBOARD */}
              {process.env.REACT_APP_CLIENT === "3_BAXTER" &&
                // ["admin", "supervisor"].includes(userType) &&
                (userInfo ? (
                  <div className="linkSection">
                    <div
                      className={`mainLinkMini ${
                        this.props.activeState === MAIN_DASHBOARD &&
                        this.props.location.pathname === "/operationalDashboard"
                          ? "activeLinkSection"
                          : null
                      }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_DASHBOARD);
                        this.removeFilterItemExceptCurrentRoute();
                        // this.removeFilterItemFromLocalStorageIndent();
                        // this.removeFilterItemFromLocalStorageTrip();
                      }}
                    >
                      <Link to="/operationalDashboard">
                        <div>
                          <img src={dashboardImage} alt="" />
                          <span className="mainLinkName"></span>
                          {/* <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} /> */}
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : (
                  ""
                ))}

              {/* INDENT PLANNING */}
              {process.env.REACT_APP_CLIENT !== "3_BAXTER" &&
                (userInfo?.data?.role?.label !== "transporter" ? (
                  <div className="linkSection mt-3">
                    <div
                      className={`mainLinkMini ${
                        this.props.activeState === MAIN_INDENT_PLANNING ||
                        this.props.location.pathname === "/indent-planning"
                          ? "activeLinkSection"
                          : null
                      }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_INDENT_PLANNING);
                        this.removeFilterItemFromLocalStorageTrip();
                      }}
                    >
                      <div>
                        <img src={planningImage} alt="" />
                        <span className="mainLinkName"></span>
                      </div>
                    </div>
                    <div
                      className={`subMenu ${
                        this.props.activeState === MAIN_INDENT_PLANNING &&
                        this.state.show === true
                          ? "displaySubMenu"
                          : "hideSubMenu"
                      }`}
                      // onClick={() => this.setState({mainLink:MAIN_INDENT_PLANNING})}
                    >
                      <Link to="/indent-planning">
                        <div>
                          <span className="subMenuLinkName"></span>
                        </div>
                      </Link>
                      <Link to="/add-indent">
                        <div>
                          <span className="subMenuLinkName"></span>
                        </div>
                      </Link>
                      {routeOptimization ? (
                        <Link to="/Route-Optimization">
                          <div>
                            <span className="subMenuLinkName"></span>
                          </div>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {loadOptimization ? (
                        <Link to="/load-optimization">
                          <div>
                            <span className="subMenuLinkName"></span>
                          </div>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="linkSection mt-3">
                    <div
                      className={`mainLinkMini ${
                        this.props.activeState === MAIN_INDENT_PLANNING ||
                        this.props.location.pathname === "/assigned-indent"
                          ? "activeLinkSection"
                          : null
                      }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_INDENT_PLANNING);
                        this.removeFilterItemFromLocalStorageTrip();
                      }}
                    >
                      <div>
                        <img src={planningImage} alt="" />
                        <span className="mainLinkName"></span>
                      </div>
                    </div>
                    <div
                      className={`subMenu ${
                        this.props.activeState === MAIN_INDENT_PLANNING &&
                        this.state.show === true
                          ? "displaySubMenu"
                          : "hideSubMenu"
                      }`}
                      // onClick={() => this.setState({mainLink:MAIN_INDENT_PLANNING})}
                    >
                      <Link to="/indent-planning">
                        <div>
                          <span className="subMenuLinkName"></span>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))}
              {process.env.REACT_APP_CLIENT === "3_BAXTER" && (
                <>
                  {/* ORDERS */}
                  <div className="linkSection mt-3">
                    <div
                      className={`mainLinkMini ${
                        this.props.activeState === MAIN_ORDERS
                          ? "activeLinkSection"
                          : null
                      }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_ORDERS);
                        // this.removeFilterItemFromLocalStorageIndent();
                      }}
                    >
                      <div>
                        <img src={planningImage} alt="" className="menuIcon" />

                        <span className="mainLinkName"></span>
                      </div>
                    </div>
                    <div
                      className={`subMenu ${
                        this.props.activeState === MAIN_ORDERS &&
                        this.state.show === true
                          ? "displaySubMenu"
                          : "hideSubMenu"
                      }`}
                    >
                      <Link to="/ordersList">
                        <div>
                          <span className="subMenuLinkName"></span>
                        </div>
                      </Link>
                    </div>
                  </div>
                </>
              )}
              {/* TRIPS */}
              {process.env.REACT_APP_CLIENT !== "3_BAXTER" && (
                <div className="linkSection mt-3">
                  <div
                    className={`mainLinkMini ${
                      this.props.activeState === MAIN_TRIPS
                        ? "activeLinkSection"
                        : null
                    }`}
                    onClick={() => {
                      this.handleActiveLink(MAIN_TRIPS);
                      this.removeFilterItemFromLocalStorageIndent();
                    }}
                  >
                    <div>
                      <img src={tripsImage} alt="" />
                      <span className="mainLinkName"></span>
                    </div>
                  </div>
                  <div
                    className={`subMenu ${
                      this.props.activeState === MAIN_TRIPS &&
                      this.state.show === true
                        ? "displaySubMenu"
                        : "hideSubMenu"
                    }`}
                  >
                    <Link to="/trips">
                      <div>
                        <span className="subMenuLinkName"></span>
                      </div>
                    </Link>
                  </div>
                </div>
              )}
              {/*BAXTER TRIPS */}
              {process.env.REACT_APP_CLIENT === "3_BAXTER" &&
                userType !== "sales" &&
                userType !== "customer" && (
                  <div className="linkSection mt-3">
                    <div
                      className={`mainLinkMini ${
                        this.props.activeState === MAIN_TRIPS
                          ? "activeLinkSection"
                          : null
                      }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_TRIPS);
                        this.removeFilterItemFromLocalStorageIndent();
                      }}
                    >
                      <div>
                        <img src={tripsImage} alt="" />
                        <span className="mainLinkName"></span>
                      </div>
                    </div>
                    <div
                      className={`subMenu ${
                        this.props.activeState === MAIN_TRIPS &&
                        this.state.show === true
                          ? "displaySubMenu"
                          : "hideSubMenu"
                      }`}
                    >
                      <Link to="/tripList">
                        <div>
                          <span className="subMenuLinkName"></span>
                        </div>
                      </Link>
                    </div>
                  </div>
                )}
              {/* MASTERS */}
              {userType !== "customer" && userType !== "sales" && (
                <div className="linkSection mt-3">
                  <div
                    className={`mainLinkMini ${
                      this.props.activeState === MAIN_MASTERS
                        ? "activeLinkSection"
                        : null
                    }`}
                    onClick={() => {
                      this.handleActiveLink(MAIN_MASTERS);
                      this.removeFilterItemFromLocalStorageIndent();
                      this.removeFilterItemFromLocalStorageTrip();
                    }}
                  >
                    <div>
                      <img src={mastersImage} alt="" className="menuIcon" />
                      <span className="mainLinkName"></span>
                    </div>
                  </div>
                  <div
                    className={`subMenu ${
                      this.props.activeState === MAIN_MASTERS &&
                      this.state.show === true
                        ? "displaySubMenu"
                        : "hideSubMenu"
                    }`}
                  >
                    <Link to="/drivers">
                      <div onClick={() => this.handleActiveSubLink("DRIVERS")}>
                        <span className="subMenuLinkName"></span>
                      </div>
                    </Link>
                    <Link to="/vehicles">
                      <div>
                        <span className="subMenuLinkName"></span>
                      </div>
                    </Link>
                    {userInfo?.data?.role?.label === "Admin" ? (
                      <Link to="/vehicle-type">
                        <div>
                          <span className="subMenuLinkName"></span>
                        </div>
                      </Link>
                    ) : (
                      ""
                    )}

                    <Link to="/user">
                      <div>
                        <span className="subMenuLinkName"></span>
                      </div>
                    </Link>
                    <Link to="/roles">
                      <div>
                        <span className="subMenuLinkName"></span>
                      </div>
                    </Link>
                    <Link to="/routes">
                      <div>
                        <span className="subMenuLinkName"></span>
                      </div>
                    </Link>
                    <Link to="/routes">
                      <div>
                        <span className="subMenuLinkName"></span>
                      </div>
                    </Link>
                  </div>
                </div>
              )}
              {/* INVOICE */}
              {process.env.REACT_APP_CLIENT !== "3_BAXTER" &&
                (userInfo?.data?.role?.label === "indent_planner" ? (
                  <></>
                ) : (
                  <div className="linkSection mt-3">
                    <div
                      className={`mainLinkMini ${
                        this.props.activeState === MAIN_INVOICE
                          ? "activeLinkSection"
                          : null
                      }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_INVOICE);
                        this.removeFilterItemFromLocalStorageIndent();
                        this.removeFilterItemFromLocalStorageTrip();
                      }}
                    >
                      <div>
                        <img src={billingImage} alt="" />
                        <span className="mainLinkName"></span>
                      </div>
                    </div>
                  </div>
                ))}

              {/* REPORTS */}
              {process.env.REACT_APP_CLIENT !== "3_BAXTER" &&
                (userInfo?.data?.role?.label !== "transporter" ? (
                  <div className="linkSection mt-3">
                    <div
                      className={`mainLinkMini ${
                        this.props.activeState === MAIN_REPORTS
                          ? "activeLinkSection"
                          : null
                      }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_REPORTS);
                        this.removeFilterItemFromLocalStorageIndent();
                        this.removeFilterItemFromLocalStorageTrip();
                      }}
                    >
                      <div>
                        <img src={reportsImage} alt="" />
                        <span className="mainLinkName"></span>
                      </div>
                    </div>
                    <div
                      className={`subMenu ${
                        this.props.activeState === MAIN_REPORTS &&
                        this.state.show === true
                          ? "displaySubMenu"
                          : "hideSubMenu"
                      }`}
                    >
                      <Link to="/Reports">
                        <div>
                          <span className="subMenuLinkName"></span>
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : (
                  ""
                ))}

              {/* BAXTER REPORTS */}
              {process.env.REACT_APP_CLIENT === "3_BAXTER" &&
              userType !== "customer" &&
              userType !== "sales" ? (
                <div className="linkSection mt-3">
                  <div
                    className={`mainLinkMini ${
                      this.props.activeState === MAIN_REPORTS
                        ? "activeLinkSection"
                        : null
                    }`}
                    onClick={() => {
                      this.handleActiveLink(MAIN_REPORTS);
                      this.removeFilterItemFromLocalStorageIndent();
                      this.removeFilterItemFromLocalStorageTrip();
                    }}
                  >
                    <div>
                      <img src={reportsImage} alt="" />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* CONTROL_TOWER */}
              {process.env.REACT_APP_CLIENT !== "3_BAXTER" &&
                (userInfo?.data?.role?.label !== "transporter" ? (
                  <div className="linkSection mt-3">
                    <div
                      className={`mainLinkMini ${
                        this.props.activeState === MAIN_CONTROL_TOWER &&
                        this.props.location.pathname === "/ControlTower"
                          ? "activeLinkSection"
                          : null
                      }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_CONTROL_TOWER);
                        this.removeFilterItemFromLocalStorageIndent();
                        this.removeFilterItemFromLocalStorageTrip();
                      }}
                    >
                      <Link to="/ControlTower">
                        <div>
                          <img src={ControlTowerImage} alt="controlImg" />
                          <span className="mainLinkName"></span>
                          {/* <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} /> */}
                        </div>
                      </Link>
                    </div>
                    <div
                      className={`subLinks ${
                        this.state.a === 1 ? "displaySubMenu" : "hideSubMenu"
                      }`}
                    ></div>
                  </div>
                ) : (
                  ""
                ))}
              {/* <div className="toggle-switch-1 ">
                <div
                  className={
                    window.location.pathname === "/add-indent" ||
                    window.location.pathname === "/indent-planning" ||
                    window.location.pathname === "/assigned-indent"
                      ? "isActivated"
                      : ""
                  }
                >
                  P
                </div>
                <div
                  className={
                    window.location.pathname === "/secondary-add-indent" ||
                    window.location.pathname === "/secondary-indent-planning" ||
                    window.location.pathname === "/secondary-assigned-indent"
                      ? "isActivated"
                      : ""
                  }
                >
                  S
                </div>
              </div> */}
              <div></div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
    activeState: state.Layout.activeTheme,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeActiveTheme: (name) => {
      dispatch({ type: "CHANGE_ACTIVE_THEME", payload: name });
    },
  };
};

export default connect(mapStatetoProps, mapDispatchToProps)(withRouter(index));
