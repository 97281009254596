import React, { Component } from "react";
import { Button, Modal, Row, Col, Card } from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import moment from "moment";
import TruckTypeSelect from "../../BasicComponents/truckTypeSelect";
import ChargesStartEndDate from "../../BasicComponents/ChargesStartEndDate";
import DaySelection from "../../BasicComponents/DaySelection";
import TimePeriodSelection from "../../BasicComponents/TimePeriodSelection";
import {
  DetentionChargesAtSourceHeaders,
  getLocalStorage,
  searchBar,
} from "./DatatableHeaders";
import Index from "../../../../components/Common/DataTableNeo/Index";

let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class DetentionChargesAtSource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      vehicleObject: [],
      vehicleTypeLabel: "",
      vehicleType: "",
      day: "",
      charges: "",
      startDate: "",
      endDate: "",
      timePeriod: "",
      timePeriodDuration: "",
      detentionChargesAtSourceForm: [],
      detentionChargesAtSourceFormID: [],
      viewMode: false,
      DateAltered: false,
      fetchId: "",
      agreementId: "",
    };
  }

  dateResetterOnModalClose = () => {
    this.setState({
      startDate: "",
      endDate: "",
      vehicleTypeLabel: "",
      vehicleType: "",
    });
  };

  listHandler = (list, listID) => {
    list.sort(function (a, b) {
      if (a.vehicleType === b.vehicleType) {
        return a.day.localeCompare(b.day);
      }
      return a.vehicleType.localeCompare(b.vehicleType);
    });
    listID.sort(function (a, b) {
      if (a.vehicleType === b.vehicleType) {
        return a.day.localeCompare(b.day);
      }
      return a.vehicleType.localeCompare(b.vehicleType);
    });
    this.setState(
      {
        detentionChargesAtSourceForm: list,
        detentionChargesAtSourceFormID: listID,
      },
      () => {
        // console.log(
        //   "Array prior to mod",
        //   this.state.detentionChargesAtSourceForm
        // );
        this.props.listFiller(
          this.state.detentionChargesAtSourceForm,
          this.state.detentionChargesAtSourceFormID
        );
      }
    );
  };

  componentDidUpdate = () => {
    let arr = [];
    let arrID = [];
    let vehicleObj = [];
    if (
      (this.props.billing.copiedAgreementValues.id &&
        !this.state.detentionChargesAtSourceForm.length &&
        !this.state.viewMode) ||
      (this.state.fetchId !== this.props.billing.fetchId &&
        this.state.agreementId !== this.props.billing.copiedAgreementValues.id)
    ) {
      this.props.billing.copiedAgreementValues.charges.sourceDetention.forEach(
        (item, index) => {
          // console.log(item, index);
          let duration_value;
          if (item.duration.start === 0) {
            duration_value = "12:00 AM - 12:00 AM";
          } else duration_value = "3:00 PM - 12:00 AM";
          arr.push({
            vehicleType: item.vehicleType.label,
            charges: item.charges,
            day: item.day,
            startDate: moment(item.startDate).format("l"),
            endDate: moment(item.endDate).format("l"),
            timePeriod: duration_value,
          });
          arrID.push({
            vehicleType: item.vehicleType._id,
            charges: item.charges,
            day: item.day,
            startDate: item.startDate,
            endDate: item.endDate,
            duration: item.duration,
          });
          vehicleObj.push({
            label: item.vehicleType.label,
            value: item.vehicleType._id,
          });
        }
      );
      this.setState(
        {
          detentionChargesAtSourceForm: arr,
          detentionChargesAtSourceFormID: arrID,
          vehicleObject: vehicleObj,
          viewMode: true,
          agreementId: this.props.billing.copiedAgreementValues.id,
          fetchId: this.props.billing.fetchId,
        },
        () => {
          this.props.listFiller(
            this.state.detentionChargesAtSourceForm,
            this.state.detentionChargesAtSourceFormID
          );
          this.props.listShow();
        }
      );
      // this.listHandler(arr, arrID);
      // this.props.listShow();
    } else if (
      this.props.billing.startDate &&
      this.props.billing.endDate &&
      !this.state.DateAltered
    ) {
      // console.log("STATE LOGS", this.state);
      let arr = this.state.detentionChargesAtSourceForm;
      let arrID = this.state.detentionChargesAtSourceFormID;
      arr.forEach((item, index) => {
        item.startDate = this.props.billing.startDate.Date;
        item.endDate = this.props.billing.endDate.Date;
        // console.log("ITEM: ", index, "-", item);
      });
      arrID.forEach((item, index) => {
        item.startDate = this.props.billing.startDate.Date;
        item.endDate = this.props.billing.endDate.Date;
        // console.log("ITEMID: ", index, "-", item);
      });
      this.setState(
        {
          detentionChargesAtSourceForm: arr,
          detentionChargesAtSourceFormID: arrID,
          DateAltered: true,
        },
        () => {
          // console.log("ARR", this.state.detentionChargesAtSourceForm);
          this.props.listFiller(
            this.state.detentionChargesAtSourceForm,
            this.state.detentionChargesAtSourceFormID
          );
          this.props.listShow();
        }
      );
    }
  };

  handleDelete = (item) => {
    let modifiedArray = [];
    let modifiedIDArray = [];
    let id;
    // console.log("dlete");
    modifiedArray = this.state.detentionChargesAtSourceForm.filter(
      (e) => e.vehicleType !== item.vehicleType || e.day !== item.day
    );
    this.state.vehicleObject.find((o, i) => {
      if (o.label === item.vehicleType) {
        id = this.state.vehicleObject[i].value;
      }
      return null
    });
    modifiedIDArray = this.state.detentionChargesAtSourceFormID.filter(
      (e) => e.vehicleType !== id || e.day !== item.day
    );
    this.setState(
      {
        detentionChargesAtSourceForm: modifiedArray,
        detentionChargesAtSourceFormID: modifiedIDArray,
      },
      () => {
        this.props.listFiller(
          this.state.detentionChargesAtSourceForm,
          this.state.detentionChargesAtSourceFormID
        );
        // console.log("-----", this.state.detentionChargesAtSourceForm);
        // console.log(this.state.detentionChargesAtSourceFormID, "--------");
      }
    );
  };

  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    const validationSchema = Yup.object({
      vehicleType: Yup.object().required(
        userLang?.planning?.VEHICLE_TYPE_IS_REQUIRED
      ),
      day: Yup.object().required(userLang?.invoice?.DAY_IS_REQUIRED),
      startdate: Yup.date().required(userLang?.invoice?.DATE_IS_REQUIRED),
      expirydate: Yup.date().required(userLang?.invoice?.DATE_IS_REQUIRED),
      charges: Yup.number().required(userLang?.invoice?.CHARGES_IS_REQUIRED),
      timePeriod: Yup.object().required(
        userLang?.invoice?.TIME_PERIOD_IS_REQUIRED
      ),
    });
    this.state.detentionChargesAtSourceForm.forEach((item) => {
      item.actions = (
        <div style={{ color: "red" }}>
          <i
            className="fas fa-trash-alt darkInvoiceModalIcons"
            style={{ cursor: "pointer" }}
            onClick={() => this.handleDelete(item)}
          ></i>
        </div>
      );
    });
   

    return (
      <React.Fragment>
        {getLocalStorage()}
        <Modal
          isOpen={this.props.modal}
          toggle={this.props.toggle}
          size="xl"
          className="ModalHeight"
        >
          <Row className="mt-2">
            <Col xs={8}>
              <div className="m-3">
                <h4 className="fontRoboto light__span">
                  {userLang?.invoice?.DETENTION_CHARGES_AT_SOURCE}{" "}
                </h4>
              </div>
            </Col>
            <Col xs={4} className="text-right">
              <i
                className="fas fa-times closemodal1 pr-2 text-right close"
                onClick={() => this.props.toggle()}
              ></i>
            </Col>
          </Row>
          <Row className="m-2">
            <Formik
              initialValues={{
                vehicleType: this.state.vehicleType,
                day: this.state.day,
                charges: this.state.charges,
                startdate: this.props.startDate,
                expirydate: this.props.endDate,
                timePeriod: this.state.timePeriod,
              }}
              validationSchema={validationSchema}
              validateOnChange={true}
              onSubmit={(values, { resetForm }, e) => {
                let chargeArray = [];
                let chargeArrayID = [];
                // console.log("valu9399", values);
                // console.log("VT", values.vehicleType.label);
                this.setState(
                  {
                    vehicleObject: this.state.vehicleObject.concat(
                      values.vehicleType
                    ),
                    vehicleTypeLabel: values.vehicleType.label,
                    vehicleType: values.vehicleType.value,
                    day: values.day.value,
                    charges: values.charges,
                    startDate: values.startdate,
                    endDate: values.expirydate,
                    timePeriod: values.timePeriod.label,
                    timePeriodDuration: values.timePeriod.duration,
                  },
                  () => {
                    chargeArray = {
                      vehicleType: this.state.vehicleTypeLabel,
                      day: this.state.day,
                      charges: this.state.charges,
                      startDate: this.state.startDate,
                      endDate: this.state.endDate,
                      timePeriod: this.state.timePeriod,
                    };
                    chargeArrayID = {
                      vehicleType: this.state.vehicleType,
                      day: this.state.day,
                      charges: this.state.charges,
                      startDate: this.state.startDate,
                      endDate: this.state.endDate,
                      duration: this.state.timePeriodDuration,
                    };

                    this.setState(
                      {
                        day: "",
                        charges: "",
                        timePeriod: "",
                        timePeriodDuration: "",
                      },
                      () => {
                        // console.log("qwert--", chargeArray);
                        this.setState(
                          {
                            detentionChargesAtSourceForm:
                              this.state.detentionChargesAtSourceForm.concat(
                                chargeArray
                              ),
                            detentionChargesAtSourceFormID:
                              this.state.detentionChargesAtSourceFormID.concat(
                                chargeArrayID
                              ),
                          },
                          () => {
                            // console.log(
                            //   this.state.detentionChargesAtSourceForm
                            // );
                            // console.log(this.state);
                            this.listHandler(
                              this.state.detentionChargesAtSourceForm,
                              this.state.detentionChargesAtSourceFormID
                            );
                          }
                        );
                      }
                    );
                  }
                );
                resetForm({
                  values: {
                    vehicleType: values.vehicleType,
                    charges: "",
                    day: "",
                    startdate: this.state.startDate,
                    expirydate: this.state.endDate,
                    timePeriod: "",
                  },
                });
              }}
            >
              {(formik) => {
                const {
                  setFieldValue,
                  handleChange,
                  handleSubmit,
                  values,
                  errors,
                  touched,
                  handleBlur,
                } = formik;
                return (
                  <Col md={12}>
                    <form onSubmit={handleSubmit}>
                      <Row>
                        <Col xs={12} md={2} lg={2}>
                          <TruckTypeSelect
                            handleBlur={handleBlur}
                            errors={errors}
                            touched={touched}
                            val={values.vehicleType}
                            setFieldValue={setFieldValue}
                          />
                        </Col>
                        <Col xs={12} md={2} lg={2}>
                          <DaySelection
                            handleBlur={handleBlur}
                            errors={errors}
                            touched={touched}
                            val={values.day}
                            setFieldValue={setFieldValue}
                          />
                        </Col>
                        <Col xs={12} md={4} lg={5}>
                          <ChargesStartEndDate
                            valid={this.props.valid}
                            validExpiry={this.props.validExpiry}
                            handleBlur={handleBlur}
                            errors={errors}
                            touched={touched}
                            chargeVal={values.charges}
                            startDateVal={values.startdate}
                            endDateVal={values.expirydate}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                          />
                        </Col>
                        <Col xs={12} md={2} lg={1}>
                          <TimePeriodSelection
                            handleBlur={handleBlur}
                            errors={errors}
                            touched={touched}
                            val={values.timePeriod}
                            setFieldValue={setFieldValue}
                          />
                        </Col>
                        <Col xs={6} md={1} lg={1}>
                          <Button
                            style={{
                              width: "100%",
                              position: "relative",
                              top: "37%",
                              backgroundColor: "#05284E",
                            }}
                            type="submit"
                            color="secondary"
                            className={`small align-middle rounded rounded-black mb-1 ${
                              this.props.mode ? "darkAgreementAddToList" : ""
                            }`}
                          >
                            <span style={{ fontWeight: "900" }}>
                              {userLang?.invoice?.ADD_To_LIST}
                            </span>
                          </Button>{" "}
                        </Col>
                        <Col xs={6} md={1} lg={1}>
                          <Button
                            style={{
                              width: "100%",
                              position: "relative",
                              top: "37%",
                              backgroundColor: "#0AB38C",
                            }}
                            type="button"
                            color="primary"
                            className="small align-middle rounded mb-1"
                            onClick={() => {
                              this.props.listShow();
                              this.props.toggle();
                              this.dateResetterOnModalClose();
                            }}
                            disabled={!this.state.detentionChargesAtSourceForm?.length}
                          >
                            <span style={{ fontWeight: "900" }}>
                              {" "}
                              {userLang?.invoice?.SAVE_ALL}
                            </span>
                          </Button>{" "}
                        </Col>
                      </Row>
                    </form>
                  </Col>
                );
              }}
            </Formik>
            <Col xs={12} className="DataTable darkDataTableCharges">
              <Card>
                <Index
                  headers={DetentionChargesAtSourceHeaders}
                  data={this.state.detentionChargesAtSourceForm}
                  searchBar={searchBar}
                  rowHeight={"56px"}
                  limit = {this.state.detentionChargesAtSourceForm.length}
                />
              </Card>
            </Col>
          </Row>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  billing: state.Billing.billing,
});

export default connect(mapStateToProps, {})(DetentionChargesAtSource);
