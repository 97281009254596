import React from "react";
import { Button, Input } from "reactstrap";
import { skipViaPoint, startUnloading } from "../../store/actions";
import actionVerticleDots from "../Trips/assets/more-vertical.png";
import "./tripStyles.scss";

export const actions = (actionString, routingTOTrip) => {
  routingTOTrip.markedAsArrivedToggle =
    routingTOTrip.markedAsArrivedToggle || false;
  let current = actionString.tracking.current;
  let next = actionString.tracking.next;
  //turn back to false
  let destinationID = actionString?.destination?._id;
  let startUnloading = false;
  let unloadingFlag = false;
  let loadingFlag = false;
  let SkipViaLoadingUnloading = false;
  let epodLoading = false;
  let loadingUnloadingFlag = false;
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  let UserRole = authUser.data?.role?.label;
  if (next) {
    actionString.tracking.path.forEach((ele) => {
      if (next == destinationID && actionString?.subStatus === "in_transit") {
        ele.operations.loading == null
          ? (startUnloading = true)
          : (startUnloading = false);
      }
      if (ele?._id === next) {
        ele?.operations?.loading === false &&
        ele?.operations?.unloading === false
          ? (SkipViaLoadingUnloading = true)
          : (SkipViaLoadingUnloading = false);
      }
    });
  }

  if (current) {
    actionString.tracking.path.forEach((ele) => {
      if (ele._id === current && ele.status === "arrived_at_via") {
        ele.operations.loading ? (loadingFlag = true) : (loadingFlag = false);
        ele.operations.unloading
          ? (unloadingFlag = true)
          : (unloadingFlag = false);
        ele?.operations?.loading === true && ele?.operations?.unloading === true
          ? (loadingUnloadingFlag = true)
          : (loadingUnloadingFlag = false);
      }

      if (ele?._id === current) {
        ele?.operations?.loading === true && ele?.operations?.unloading === true
          ? (epodLoading = true)
          : (epodLoading = false);
      }
    });
  }

  // const setMarkedAsArrivedToggle = () => {
  //   markedAsArrivedToggle = !markedAsArrivedToggle;
  // };

  switch (actionString?.subStatus) {
    case "Planned":
      return (
        <div className="dropdown">
          <Button className="iconButtons dropbtn">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content">
            <div
              className="action-button"
              onClick={() => routingTOTrip.editDetailButton(actionString)}
            >
              Edit Details
            </div>
            <div
              className="action-button"
              onClick={() =>
                routingTOTrip.markerAsArrivedChangeStatus(actionString)
              }
            >
              Marked as Arrived
            </div>
            {UserRole !== "Admin" && (
              <div
                className="action-button-cancel"
                onClick={() =>
                  routingTOTrip.cancelTripDetailSaver(actionString)
                }
              >
                Cancel
              </div>
            )}

            <div
              className="action-button"
              onClick={() => routingTOTrip.routingTOTripDetails(actionString)}
            >
              Trip History
            </div>
          </div>
        </div>
      );
    case "planned":
      return (
        <div className="dropdown">
          <Button className="iconButtons dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.editDetailButton(actionString)}
            >
              Edit Details
            </div>
            <div
              className="action-button actionDspan"
              onClick={() =>
                routingTOTrip.markerAsArrivedChangeStatus(actionString)
              }
            >
              Marked as Arrived
            </div>
            {UserRole !== "Admin" && (
              <div
                className="action-button-cancel actionDrspan"
                onClick={() =>
                  routingTOTrip.cancelTripDetailSaver(actionString)
                }
              >
                Cancel
              </div>
            )}

            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.routingTOTripDetails(actionString)}
            >
              Trip History
            </div>
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.acknowledgeLRForm(actionString)}
            >
              Acknowledge LR
            </div>
          </div>
        </div>
      );
    case "arrived_at_source":
      return (
        <div className="dropdown">
          <Button className="iconButtons dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.qualityDetailButton(actionString)}
            >
              Quality Check Start
            </div>
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.editDetailButton(actionString)}
            >
              Edit Details
            </div>
            {UserRole !== "Admin" && (
              <div
                className="action-button-cancel actionDrspan"
                onClick={() =>
                  routingTOTrip.cancelTripDetailSaver(actionString)
                }
              >
                Cancel
              </div>
            )}

            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.routingTOTripDetails(actionString)}
            >
              Trip History
            </div>
          </div>
        </div>
      );
    case "quality_check_completed":
      return (
        <div className="dropdown">
          <Button className="iconButtons dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.startLoading(actionString._id)}
            >
              Start Loading
            </div>
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.editDetailButton(actionString)}
            >
              Edit Details
            </div>
            {UserRole !== "Admin" && (
              <div
                className="action-button-cancel actionDrspan"
                onClick={() =>
                  routingTOTrip.cancelTripDetailSaver(actionString)
                }
              >
                Cancel
              </div>
            )}

            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.routingTOTripDetails(actionString)}
            >
              Trip History
            </div>
          </div>
        </div>
      );
    case "loading_in_progress":
      return (
        <div className="dropdown">
          <Button className="iconButtons dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            <div
              className="action-button actionDspan"
              onClick={() =>
                routingTOTrip.verifyButton(actionString, { missedShip: false })
              }
            >
              Verify
            </div>
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.editDetailButton(actionString)}
            >
              Edit Details
            </div>
            {UserRole === "Admin" && (
              <div
                className="action-button-cancel actionDrspan"
                onClick={() =>
                  routingTOTrip.cancelTripDetailSaver(actionString)
                }
              >
                Cancel
              </div>
            )}
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.routingTOTripDetails(actionString)}
            >
              Trip History
            </div>
          </div>
        </div>
      );
    case "shipment_verified":
      return (
        <div className="dropdown">
          <Button className="iconButtons dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            <div
              className="action-button actionDspan"
              onClick={() => {
                routingTOTrip.finishLoading(actionString._id);
              }}
            >
              Mark Loading Done
            </div>
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.routingTOTripDetails(actionString)}
            >
              Trip History
            </div>
          </div>
        </div>
      );
    case "in_transit":
      return (
        <div className="dropdown">
          <Button className="iconButtons dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            {/* {SkipViaLoadingUnloading ? (
              <div
                className="action-button actionDspan"
                onClick={() => routingTOTrip.skipViaApiCall(actionString)}
              >
                Skip Via Point
              </div>
            ) : startUnloading ? ( */}
            {/* <>
                {UserRole === "loading_unloading" || */}
            {/* UserRole === "unloading" ? ( */}
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.startUnloadingToggle(actionString)}
            >
              Start Unloading
            </div>
            {/* // ) : (
                //   ""
                // )}
              </>
            // ) : (
            //   <> */}
            <div
              className="action-button actionDspan"
              onClick={() =>
                routingTOTrip.setMarkedAsArrivedToggle(actionString)
              }
            >
              Marked as Arrived
            </div>
            {/* </>
            )} */}
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.routingTOTripTracking(actionString)}
            >
              Track
            </div>
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.routingTOTripDetails(actionString)}
            >
              Trip History
            </div>
            <div
              className="action-button actionDspan"
              onClick={() =>
                routingTOTrip.verifyButton(actionString, { missedShip: true })
              }
            >
              Missed SF
            </div>
          </div>
        </div>
      );
    case "arrived_at_via":
      return (
        <div className="dropdown">
          <Button className="iconButtons dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            {loadingUnloadingFlag ? (
              unloadingFlag ? (
                <>
                  {UserRole === "loading_unloading" ||
                  UserRole === "unloading" ? (
                    <div
                      className="action-button actionDspan"
                      onClick={() => routingTOTrip.startUnloading(actionString)}
                    >
                      Start Unloading
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )
            ) : (
              <>
                {loadingFlag && (
                  <div
                    className="action-button actionDspan"
                    onClick={() => routingTOTrip.startLoading(actionString._id)}
                  >
                    Start Loading
                  </div>
                )}
                {unloadingFlag && (
                  <>
                    {UserRole === "loading_unloading" ||
                    UserRole === "unloading" ? (
                      <div
                        className="action-button actionDspan"
                        onClick={() =>
                          routingTOTrip.startUnloading(actionString)
                        }
                      >
                        Start Unloading
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </>
            )}
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.editDetailButton(actionString)}
            >
              Edit Details
            </div>
            {/* <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.verifyButton(actionString)}
            >
              Verify
            </div> */}
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.routingTOTripDetails(actionString)}
            >
              Trip History
            </div>
          </div>
        </div>
      );
    case "completed":
      return (
        <div className="dropdown">
          <Button className="iconButtons dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.routingTOTripDetails(actionString)}
            >
              Trip History
            </div>
          </div>
        </div>
      );
    case "epod_in_progress":
      return (
        <div className="dropdown">
          <Button className="iconButtons dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            {UserRole === "loading_unloading" || UserRole === "unloading" ? (
              <div
                className="action-button actionDspan"
                onClick={() => routingTOTrip.EPODFunction(actionString)}
              >
                Continue Unloading
              </div>
            ) : (
              ""
            )}

            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.routingTOTripDetails(actionString)}
            >
              Trip History
            </div>
            <div
              className="action-button actionDspan"
              onClick={() =>
                routingTOTrip.verifyButton(actionString, { missedShip: true })
              }
            >
              Missed SF
            </div>
          </div>
        </div>
      );
    case "epod_completed":
      return (
        <div className="dropdown">
          <Button className="iconButtons dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            {/* {loadingFlag ? ( */}
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.startLoading(actionString._id)}
            >
              Start Loading
            </div>
            {/* ) : (
              ""
            )} */}
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.routingTOTripDetails(actionString)}
            >
              Trip History
            </div>
          </div>
        </div>
      );
    case "rejected":
      return (
        <div className="dropdown">
          <Button className="iconButtons dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            <div
              className="action-button actionDspan"
              onClick={() => routingTOTrip.routingTOTripDetails(actionString)}
            >
              Trip History
            </div>
          </div>
        </div>
      );

    default:
      return (
        <div className="dropdown">
          <Button className="iconButtons dropbtn actionButtonsDark">
            <img
              style={{ margin: "-4px" }}
              src={actionVerticleDots}
              alt="actions"
            />
          </Button>
          <div className="dropdown-content contentDark">
            <div className="disabled">
              <span className="actionDspan">No Options Available</span>
            </div>
          </div>
        </div>
      );
  }
};
