import { types } from "../actionTypes";
const initialState = {
  countStatus: [],
};
export default (state = { initialState }, action) => {
  switch (action.type) {
    case types.TRIP_COUNT_BY_STATUS_SUCCESS:
      return { ...state, countStatus: action.payload };
    case types.OPERATIONS_TRIP_INDENT_BY_STATUS_SUCCESS:
      return { ...state, operationsTripIndentDetails: action.payload };

    case types.TRANSPORTER_TRIP_HISTORY_BY_STATUS_SUCCESS:
      return { ...state, transporterTripHistory: action.payload };

    case types.INVOICE_DASHBOARD_BY_STATUS_SUCCESS:
      return { ...state, invoiceDashboardDetails: action.payload };

    default:
      state = { ...state };
      break;
  }

  return state;
};
