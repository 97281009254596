import React, { Component } from "react";
import { Row, Col, Card, Button, Input } from "reactstrap";
import Select, { components } from "react-select";
import "../../pages/Billing/InvoiceNew/InvoiceList/invoiceListTheme.scss";
// import refreshIcon from "./assets/refresh-cw.png";
import Datepicker from "../../components/Common/DatePicker/DatePicker";
import { Grid } from "@material-ui/core";
import { InputGroup } from "rsuite";
// images
import userIcon from "./assets/user.png";
import UserIconDark from "./assets/userDark.png";

import searchIcon from "./assets/search.png";
import searchDark from "./assets/searchDrk.png";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
var darkMode;

const DropdownIndicator = (props) => {
  const iconSvg = props.selectProps.selectProps;
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {iconSvg}
      </components.DropdownIndicator>
    )
  );
};

class QuickFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hops: [],
      vehicleTypes: [],
      // searchBar: [],
      searchFilters: {},
      dateValue: [null, null],
      showCalender: false,
      userLangs: undefined,
      fromDate: null,
      toDate: null,
    };
  }
  handleClickAway = () => {
    if (this.state.showCalender === true) {
      this.setState({ showCalender: false });
    }
  };
  componentDidUpdate() {
    if (
      this.state.userLangs === undefined &&
      (userLang !== this.state.userLangs || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  }

  onChangeDatePicker = (value, label) => {
    // console.log(value, label);
    // debugger
    if (label === "fromDate") {
      this.setState({ fromDate: value }, () => {
        if (this.state.fromDate !== null && this.state.toDate !== null) {
          this.props.handleChangeFilters({
            name: "validity",
            value: [this.state.fromDate, this.state.toDate],
          });
        }
      });
    }
    if (label === "toDate") {
      this.setState({ toDate: value }, () => {
        if (this.state.fromDate !== null && this.state.toDate !== null) {
          this.props.handleChangeFilters({
            name: "validity",
            value: [this.state.fromDate, this.state.toDate],
          });
        }
      });
    }
  };

  render() {
    let authUserData = JSON.parse(window.localStorage.getItem("authUser"));
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    darkMode = mode === "dark__mode" ? true : false;
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    const customStyles1 = {
      menu: ({ width, ...css }) => ({
        ...css,
        width: "100%",
        minWidth: "100%",
        backgroundColor: darkMode ? "#171722" : "#ffffff",
        border: darkMode
          ? "0.5px solid #616161"
          : "0.5px solid rgb(204, 204, 204)",
        boxShadow: darkMode
          ? "0px 3px 12px rgba(0, 0, 0, 0.12)"
          : "0px 3px 12px rgba(0, 0, 0, 0.12)",
        zIndex: 1000,
      }),
      input: (base) => ({
        ...base,
        color: darkMode ? "#9d9d9d" : "#505d69",
      }),
      control: (style) => {
        return {
          ...style,
          height: "28px",
          width: this.props?.form?.type?.length >= 2 ? "100%" : "100%",
          minWidth: "100%",
          minHeight: "28px",
          backgroundColor: darkMode ? "#2E2E46" : "",
          border: darkMode ? "1.5px solid #B7A9FF" : "",
        };
      },
      placeholder: (style) => {
        return {
          ...style,
          marginTop: "0px",
          height: "24px",
          minHeight: "24px",
          color: darkMode ? "#ffffff" : "",
        };
      },
      indicatorsContainer: (style) => {
        return {
          ...style,
          // backgroundColor: "#8e9af8",
          height: "26px",
          minHeight: "26px",
          marginTop: "3px",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "10px",
          minHeight: "10px",
          backgroundColor: "#cccccc",
          marginTop: "4px",
        };
      },
      dropdownIndicator: (style) => {
        return {
          ...style,
          height: "24px",
          minHeight: "26px",
          // backgroundColor: "#0066cc",
          marginTop: "-5px",
        };
      },
    };

    return (
      <React.Fragment>
        <Card className="darkBgQuickFilter input-borderDIv">
          <div className="quickStylesBar invoiceStyle">
            <>
              <Row className="m-1">
                {/* <Col xs={12} className="mobileDisplay">
                  <div className="d-flex justify-content-end">
                    <div>
                      <Button
                        style={{
                          minWidth: "70%",
                        }}
                        className="mobile-reset reset"
                        onClick={() => this.props?.reset()}
                      >
                        {/* <img
                          className="mobile-rest-img"
                          src={refreshIcon}
                          alt=""
                        ></img> 
                      </Button>
                    </div>

                    <div className="filter">
                      <div className="tooltipIcon QuickFilters">
                        <div className="datepicker-selector-mobile iconButtons mobileCalender actionButtonsDark  mr-2 datepicker-custom">
                          <Datepicker
                            mode={darkMode}
                            value={this.state.toDate}
                            onChangeDatePicker={this.onChangeDatePicker}
                            fromDate={true}
                            toDate={false}
                            classname={"invoiceListStyle"}
                          />
                        </div>
                        <span
                          className="tooltipIcontext"
                          style={{ width: "130px", left: "20%" }}
                        >
                          &nbsp;{this.state.userLangs?.common?.CALENDER}&nbsp;
                        </span>
                      </div>
                    </div>
                    <div className="filter">
                      <div className="tooltipIcon QuickFilters">
                        <div className="datepicker-selector-mobile iconButtons mobileCalender actionButtonsDark  datepicker-custom">
                          <Datepicker
                            mode={darkMode}
                            value={this.state.toDate}
                            onChangeDatePicker={this.onChangeDatePicker}
                            fromDate={false}
                            toDate={true}
                            classname={"invoiceListStyle"}
                          />
                        </div>
                        <span
                          className="tooltipIcontext"
                          style={{ width: "130px", left: "20%" }}
                        >
                          &nbsp;{this.state.userLangs?.common?.CALENDER}&nbsp;
                        </span>
                      </div>
                    </div>
                  </div>
                </Col> */}
                <Col xs={12}>
                  <Row className="">
                    <Col xs={12} md={3} className="marg-10">
                      <div className="input-border  ">
                        <InputGroup>
                          <Input
                            className="form-control"
                            type="text"
                            name="indentPlanningCode"
                            value={this.props.filterNames?.indentPlanningCode}
                            onChange={(e) =>
                              this.props.handleChangeFilters({
                                name: "indentPlanningCode",
                                value: e.target.value,
                              })
                            }
                            onKeyDown={(event) => this.props?.enterCheck(event)}
                            placeholder="Indent Planning Code"
                            aria-label="Search"
                            icon="fas fa-times-circle"
                          />
                          <span className="pt-2 pb-2 input-line lineDark  pl-1 ml-1"></span>
                          <span
                            className="span-img"
                            onClick={() => this.props?.filterList()}
                          >
                            <img
                              className=""
                              src={darkMode ? searchDark : searchIcon}
                              alt=""
                            ></img>
                          </span>
                        </InputGroup>
                      </div>
                    </Col>
                    <Col xs={12} md={3} className="marg-10">
                      <Select
                        className="hopes-react_selector"
                        classNamePrefix="react-select"
                        placeholder="Select Source"
                        // isMulti
                        components={{ DropdownIndicator }}
                        selectProps={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="16"
                            viewBox="0 0 14 16"
                            fill="none"
                          >
                            <path
                              d="M12.455 6.7275C12.455 11.1825 6.727 15.0005 6.727 15.0005C6.727 15.0005 1 11.1825 1 6.7275C1 5.20847 1.60343 3.75166 2.67755 2.67755C3.75166 1.60343 5.20847 1 6.7275 1C8.24653 1 9.70334 1.60343 10.7775 2.67755C11.8516 3.75166 12.455 5.20847 12.455 6.7275V6.7275Z"
                              stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7 9.00049C8.10457 9.00049 9 8.10506 9 7.00049C9 5.89592 8.10457 5.00049 7 5.00049C5.89543 5.00049 5 5.89592 5 7.00049C5 8.10506 5.89543 9.00049 7 9.00049Z"
                              stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        }
                        isSearchable
                        styles={customStyles1}
                        // width="150px"
                        value={this.props.filterNames?.source}
                        options={this.props.hopOptions}
                        onChange={(e) =>
                          this.props.handleChangeFilters({
                            name: "source",
                            value: e,
                          })
                        }
                        onInputChange={(e) => {
                          this.props.filtersHops(e);
                        }}
                      />
                    </Col>
                    <Col xs={12} md={3} className="marg-10">
                      <Select
                        className="hopes-react_selector"
                        classNamePrefix="react-select"
                        placeholder="Select Destination"
                        // isMulti
                        components={{ DropdownIndicator }}
                        selectProps={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="16"
                            viewBox="0 0 14 16"
                            fill="none"
                          >
                            <path
                              d="M12.455 6.7275C12.455 11.1825 6.727 15.0005 6.727 15.0005C6.727 15.0005 1 11.1825 1 6.7275C1 5.20847 1.60343 3.75166 2.67755 2.67755C3.75166 1.60343 5.20847 1 6.7275 1C8.24653 1 9.70334 1.60343 10.7775 2.67755C11.8516 3.75166 12.455 5.20847 12.455 6.7275V6.7275Z"
                              stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7 9.00049C8.10457 9.00049 9 8.10506 9 7.00049C9 5.89592 8.10457 5.00049 7 5.00049C5.89543 5.00049 5 5.89592 5 7.00049C5 8.10506 5.89543 9.00049 7 9.00049Z"
                              stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        }
                        isSearchable
                        styles={customStyles1}
                        // width="150px"
                        value={this.props.filterNames?.destination || ""}
                        options={this.props.hopOptions}
                        onChange={(e) =>
                          this.props.handleChangeFilters({
                            name: "destination",
                            value: e,
                          })
                        }
                        onInputChange={(e) => {
                          this.props.filtersHops(e);
                        }}
                      />
                    </Col>
                    <Col xs={12} md={3} className="marg-10">
                      <Select
                        className="hopes-react_selector"
                        classNamePrefix="react-select"
                        placeholder="Select Transporter"
                        // isMulti
                        components={{ DropdownIndicator }}
                        selectProps={
                          <svg
                            width="16"
                            height="17"
                            viewBox="0 0 13 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.7588 12.906V11.583C11.7588 10.8812 11.48 10.2082 10.9838 9.71201C10.4876 9.21579 9.81454 8.93701 9.11278 8.93701H3.82178C3.12002 8.93701 2.447 9.21579 1.95078 9.71201C1.45456 10.2082 1.17578 10.8812 1.17578 11.583V12.906"
                              stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M6.25879 6C7.6395 6 8.75879 4.88071 8.75879 3.5C8.75879 2.11929 7.6395 1 6.25879 1C4.87808 1 3.75879 2.11929 3.75879 3.5C3.75879 4.88071 4.87808 6 6.25879 6Z"
                              stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        }
                        isSearchable
                        styles={customStyles1}
                        // width="150px"
                        value={this.props.filterNames?.transporter || ""}
                        options={this.props.allTransporter}
                        onChange={(e) =>
                          this.props.handleChangeFilters({
                            name: "transporter",
                            value: e,
                          })
                        }
                        onInputChange={(e) => {
                          this.props.filtersTranspoter(e);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col xs={12} md={3} className="marg-10">
                  <div className="input-border ">
                    <InputGroup>
                      <Input
                        className="form-control"
                        type="text"
                        name="kms"
                        value={this.props.filterNames?.kms}
                        onChange={(e) =>
                          this.props.handleChangeFilters({
                            name: "kms",
                            value: e.target.value,
                          })
                        }
                        onKeyDown={(event) => this.props?.enterCheck(event)}
                        placeholder="Search By Kms"
                        aria-label="Search"
                        icon="fas fa-times-circle"
                      />
                      <span className="pt-2 pb-2 input-line lineDark  pl-1 ml-1"></span>
                      <span
                        className="span-img"
                        onClick={() => this.props?.filterList()}
                      >
                        <img
                          className=""
                          src={darkMode ? searchDark : searchIcon}
                          alt=""
                        ></img>
                      </span>
                    </InputGroup>
                  </div>
                </Col>
                <Col xs={12} md={3} className="marg-10">
                  <div className="input-border ">
                    <InputGroup>
                      <Input
                        className="form-control"
                        type="text"
                        name="laneLot"
                        value={this.props.filterNames?.laneLot}
                        onChange={(e) =>
                          this.props.handleChangeFilters({
                            name: "laneLot",
                            value: e.target.value,
                          })
                        }
                        onKeyDown={(event) => this.props?.enterCheck(event)}
                        placeholder="Search By laneLot"
                        aria-label="Search"
                        icon="fas fa-times-circle"
                      />
                      <span className="pt-2 pb-2 input-line lineDark  pl-1 ml-1"></span>
                      <span
                        className="span-img"
                        onClick={() => this.props?.filterList()}
                      >
                        <img
                          className=""
                          src={darkMode ? searchDark : searchIcon}
                          alt=""
                        ></img>
                      </span>
                    </InputGroup>
                  </div>
                </Col>
                <Col xs={12} md={3} className="marg-10">
                  <div className="input-border ">
                    <InputGroup>
                      <Input
                        className="form-control"
                        type="text"
                        name="leadTime"
                        value={this.props.filterNames?.leadTime}
                        onChange={(e) =>
                          this.props.handleChangeFilters({
                            name: "leadTime",
                            value: e.target.value,
                          })
                        }
                        onKeyDown={(event) => this.props?.enterCheck(event)}
                        placeholder="Search By Lead Time"
                        aria-label="Search"
                        icon="fas fa-times-circle"
                      />
                      <span className="pt-2 pb-2 input-line lineDark  pl-1 ml-1"></span>
                      <span
                        className="span-img"
                        onClick={() => this.props?.filterList()}
                      >
                        <img
                          className=""
                          src={darkMode ? searchDark : searchIcon}
                          alt=""
                        ></img>
                      </span>
                    </InputGroup>
                  </div>
                </Col>

                <Col xs={12} md={3} className="marg-10">
                  <Grid item xs={12} className="datePickerGrid">
                    <div
                      className=" date-picker-div RsuiteDateRange dashboardDateRange"
                      style={{
                        width: "100%",
                      }}
                    >
                      <div className="datepicker-selector">
                        <InputGroup>
                          <Datepicker
                            mode={darkMode}
                            value={this.state.fromDate}
                            onChangeDatePicker={this.onChangeDatePicker}
                            fromDate={true}
                            toDate={false}
                            placeholder={"Start Date"}
                            dashboard={true}
                          />
                          <Datepicker
                            mode={darkMode}
                            value={this.state.toDate}
                            onChangeDatePicker={this.onChangeDatePicker}
                            fromDate={false}
                            toDate={true}
                            placeholder={"End Date"}
                            dashboard={true}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  </Grid>
                </Col>
                <Col xs={12}>
                  <Row className="">
                    <Col xs={12} md={3} className="marg-10">
                      <div className="input-border ">
                        <InputGroup>
                          <Input
                            className="form-control"
                            type="text"
                            name="lotNo"
                            value={this.props.filterNames?.lotNo}
                            onChange={(e) =>
                              this.props.handleChangeFilters({
                                name: "lotNo",
                                value: e.target.value,
                              })
                            }
                            onKeyDown={(event) => this.props?.enterCheck(event)}
                            placeholder="Search By LotNo"
                            aria-label="Search"
                            icon="fas fa-times-circle"
                          />
                          <span className="pt-2 pb-2 input-line lineDark  pl-1 ml-1"></span>
                          <span
                            className="span-img"
                            onClick={() => this.props?.filterList()}
                          >
                            <img
                              className=""
                              src={darkMode ? searchDark : searchIcon}
                              alt=""
                            ></img>
                          </span>
                        </InputGroup>
                      </div>
                    </Col>
                    <Col xs={12} md={3} className="marg-10">
                      <div className="input-border ">
                        <InputGroup>
                          <Input
                            className="form-control"
                            type="text"
                            name="targetIndents"
                            value={this.props.filterNames?.targetIndents}
                            onChange={(e) =>
                              this.props.handleChangeFilters({
                                name: "targetIndents",
                                value: e.target.value,
                              })
                            }
                            onKeyDown={(event) => this.props?.enterCheck(event)}
                            placeholder="Search By Target Indents"
                            aria-label="Search"
                            icon="fas fa-times-circle"
                          />
                          <span className="pt-2 pb-2 input-line lineDark  pl-1 ml-1"></span>
                          <span
                            className="span-img"
                            onClick={() => this.props?.filterList()}
                          >
                            <img
                              className=""
                              src={darkMode ? searchDark : searchIcon}
                              alt=""
                            ></img>
                          </span>
                        </InputGroup>
                      </div>
                    </Col>
                    <Col xs={12} md={3} className="marg-10">
                      <div className="input-border ">
                        <InputGroup>
                          <Input
                            className="form-control"
                            type="text"
                            name="actualIndents"
                            value={this.props.filterNames?.actualIndents}
                            onChange={(e) =>
                              this.props.handleChangeFilters({
                                name: "actualIndents",
                                value: e.target.value,
                              })
                            }
                            onKeyDown={(event) => this.props?.enterCheck(event)}
                            placeholder="Search By Actual Indents"
                            aria-label="Search"
                            icon="fas fa-times-circle"
                          />
                          <span className="pt-2 pb-2 input-line lineDark  pl-1 ml-1"></span>
                          <span
                            className="span-img"
                            onClick={() => this.props?.filterList()}
                          >
                            <img
                              className=""
                              src={darkMode ? searchDark : searchIcon}
                              alt=""
                            ></img>
                          </span>
                        </InputGroup>
                      </div>
                    </Col>
                    <Col xs={3} md={3}>
                      <Button
                        style={{
                          float: "right",
                        }}
                        onClick={() => {
                          this.setState(
                            {
                              dateValue: [null, null],
                              fromDate: null,
                              toDate: null,
                            },
                            () => {
                              this.props.reset();
                            }
                          );
                        }}
                        className="reset"
                      >
                        {this.state.userLangs?.common?.RESET}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default QuickFilters;
