import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import FreightsMasterReducer from './reducer';
const FreightsMaster = combineReducers({
    FreightsMaster: FreightsMasterReducer,
    form: formReducer
});


export default FreightsMaster;
