import { types } from "./actionTypes";
const initialState = {
  getlogbookDetails: null,
  getlogbookS3ExcelUrlsData: null,
};
const logbookReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LOGBOOK_DATA_SUCCESS:
      state = {
        ...state,
        getlogbookDetails: action.payload,
      };
      return state;
    case types.GET_LOGBOOK_DATA_EXCEL_S3_SUCCESS:
      state = {
        ...state,
        getlogbookS3ExcelUrlsData: action.payload,
      };
      return state;
    default:
      return state;
  }
};
export default logbookReducer;
