import React, { Component } from "react";
import { Label, FormGroup } from "reactstrap";
import Select from "react-select";
import { ErrorMessage } from "formik";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

var options = [
  {
    label: "3:00 PM - 12:00 AM",
    duration: {
      start: 15,
      end: 0,
    },
  },
  {
    label: "12:00 AM - 12:00 AM",
    duration: {
      start: 0,
      end: 0,
    },
  },
];

class TimePeriodSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      TimePeriod: "",
    };
  }

  render() {
 userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

    const customStyles = {
      indicatorsContainer: (style) => {
        return {
          ...style,
          backgroundColor: "#8e9af8",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          backgroundColor: "#8e9af8",
        };
      },
    };
    return (
      <React.Fragment>
        <FormGroup className="select2-container required darkAgreementVehicleCharge">
          <Label className="col-form-label required-field light__span">
            {" "}
            {userLang?.invoice?.TIME_PERIOD}
          </Label>
          <Select
            value={this.props.val}
            name="timePeriod"
            styles={customStyles}
            onChange={(e) => {
              this.props.setFieldValue("timePeriod", e);
            }}
            options={options}
            classNamePrefix="select2-selection"
            id="timePeriod"
            onBlur={this.props.handleBlur}
            className={
              this.props.errors.timePeriod && this.props.touched.timePeriod
                ? "input-error"
                : null
            }
          />
          <ErrorMessage name="timePeriod" component="span" className="error" />
        </FormGroup>
      </React.Fragment>
    );
  }
}

export default TimePeriodSelection;
