import React, { Component } from "react";

import { Row, Col, Container } from "reactstrap";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import {
  fetchVehicleTypesFilter,
  fetchVehicleTypesFilterExcel,
  fetchVechileTypePresignedUrl,
  createVechileTypeViaExcel,
  uploadImagesAws
} from "../../store/vehicleType/actions";
import { deleteVehicleTypesSpec } from "../../store/vehicleType/actions";
import Pagination from "@material-ui/lab/Pagination";
import { restrict } from ".././restrict";
import { PERMS } from "../../enum/perms.enum";
import PopupVehicleType from "../../components/Common/PopupVehicleType";
import VehicleTypeForm from "../Forms/VehicleTypeAddForm";
import Index from "../../components/Common/DataTableNeo/Index";
import {
  AdminHeaderData,
  searchBar,
  getLocalStorage,
} from "./RowHeaders/RowHeaders.js";
import { actions } from "./actionButtons";
import QuickFilters from "./quckFilters";
import { exportToCSV } from "../common/exportToCsv";
import {
  Modal,
ModalHeader,
ModalBody,
ModalFooter,
} from "reactstrap";
import GetAppIcon from "@material-ui/icons/GetApp";


// var data = {};
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var searchValues = {};

class Vehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoading: false,
      searchFilters: {},
      setOpenPopup: false,
      viewById: {},
      deleteId: "",
      isOpen: false,
      excelUrl: "",
      name: "",

    };

    this.fileInput = React.createRef();

    if (process.env.REACT_APP_CLIENT != "3_BAXTER") {
      (async () => {
        let redirect = await restrict(PERMS.VEHICLE_TRANSPORTER_READ);
        if (redirect === false) {
          this.props.history.push("/operationsDashboard");
        }
      })();
    }
  }

  componentDidMount = () => {
    this.fetchVehicleType();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 0.9;
  }

  setOpenPopupfalse = (props) => {
    this.setState({ setOpenPopup: props });
  };
  handleSubmit = (props) => {
    this.setState({ setOpenPopup: props });
    this.getData();
  };
  addVehicle = (ele, viewMode) => {
    this.setState({ setOpenPopup: true, viewById: { ele, viewMode } });
  };

  fetchVehicleType = (data) => {
    this.setState({ dataLoading: true });
    data = data || {};
    this.props.fetchVehicleTypesFilter(offset, " ", (res) => {
      this.setState({ dataLoading: false });
    });
  };
  enterCheck = (event) => {
    if (event.key === "Enter") {
      this.getData();
    }
  };
  componentDidUpdate(prevProps) {}
  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
  };

  // filterList = () => {
  //   this.getData();
  // };
  handleSearchInput = (e, data) => {
    searchValues[e?.target?.name] = e?.target?.value;
    this.setState({ searchFilters: searchValues }, () => {});
  };

  downloadExcelTemplate = () =>{
    let exportHeaders = [
      {
        "Vehicle Type Code":"1 MT",
        "Label":"1 MT",
        "Length":"56",
        "Width":"65",
        "Height":"23",
        "Weight":"21",
      }
    ];
    exportToCSV([...exportHeaders], "VechileType_Template");
}
  
openModal = () => {
  this.setState({
    isOpen: true,
    excelUrl: "",
    selectedFile: {
      ...this.state.selectedFile, 
      name: "",                   
    },
  });
};
closeModal = () => {
  this.setState({
    isOpen: false,
    excelUrl: "",
    selectedFile: {
      ...this.state.selectedFile,  
      name: "",                    
    },
  });
  };

  handleSubmitExcel = () => {
    this.props.createVechileTypeViaExcel(
      { excelFileURL: this.state.excelUrl },
      (res) => {
        if (res.success) {
          NotificationManager.success(res["message"], "Success");
        
          this.fetchVehicleType();
         
          this.closeModal();
         
        } else {
          NotificationManager.error(res["message"], "Error");
        }
      }
      );
  };
  
  fileUpload = (event) => {
    this.setState(
      {
        selectedFile: event.target.files[0],
      },
      () => {
        this.S3Upload();
      }
      );
  };

  S3Upload = async () => {
    this.props.fetchVechileTypePresignedUrl((res) => {
      let softCopy = "";
      softCopy = res.presignedS3Url.split("?")[0];
      this.setState(
        {
          excelUrl: softCopy,
        },
        () => {
          const formData = new FormData();
          formData.append(
            this.state.selectedFile,
            this.state.selectedFile.name
          );
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", this.state.selectedFile.type);
          this.props.uploadImagesAws(
            res.presignedS3Url,
            this.state.selectedFile,
            myHeaders
          );
        }
      );
    });
    };

    fileUploadTrigger = () => {
      this.fileInput.current.click();
    };

  getData = () => {
    this.setState({ dataLoading: true });
    this.props.fetchVehicleTypesFilter(
      0,
      {
        label: this.state.searchFilters?.label
          ? this.state.searchFilters?.label
          : "",

        vehicleTypeCode: this.state.searchFilters?.vehicleTypeCode
          ? this.state.searchFilters?.vehicleTypeCode
          : "",

        weight: this.state.searchFilters?.weight
          ? this.state.searchFilters?.weight
          : "",

        lengths: this.state.searchFilters?.length
          ? this.state.searchFilters?.length
          : "",

        width: this.state.searchFilters?.width
          ? this.state.searchFilters?.width
          : "",

        height: this.state.searchFilters?.height
          ? this.state.searchFilters?.height
          : "",
      },
      (res) => {
        this.setState({ dataLoading: false });
      }
    );
  };
  reset = (e) => {
    offset = 0;
    searchValues = {};
    this.setState(
      {
        searchFilters: {
          vehicleTypeCode: "",
          label: "",
          height: "",
          length: "",
          width: "",
          weight: "",
        },
      },
      () => {
        this.setState({ dataLoading: true });
        this.props.fetchVehicleTypesFilter(0, " ", () => {
          this.setState({ dataLoading: false });
        });
      }
    );
  };

  handleChangePage = (event, val) => {
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    toVal = offset + process.env.REACT_APP_PERPAGE;
    this.fetchVehicleType();
  };

  editDetail = (ele) => {
    this.addVehicle(ele, "edit");
  };

  deleteVehicleSpec = (ele) => {
    // console.log("delete");
    this.setState({ deleteId: ele?._id ? ele?._id : "" }, () => {
      if (this.state.deleteId.length) {
        let id = this.state.deleteId;
        this.props.deleteVehicleTypesSpec(id, (res) => {
          if (res.success) {
            NotificationManager.success(res["message"], "Success");
            this.getData();
          } else {
            NotificationManager.error(res["message"], "Error");
            this.setState({ onSubmitDisable: false });
          }
        });
      }
    });
  };

  exportExcelData = () => {
    this.setState({ dataLoading: true }, () => {});
    // Call the action function using await to ensure proper execution
    this.props.fetchVehicleTypesFilterExcel(
      0,
      {
        label: this.state.searchFilters?.label
          ? this.state.searchFilters?.label
          : "",

        vehicleTypeCode: this.state.searchFilters?.vehicleTypeCode
          ? this.state.searchFilters?.vehicleTypeCode
          : "",

        weight: this.state.searchFilters?.weight
          ? this.state.searchFilters?.weight
          : "",

        lengths: this.state.searchFilters?.length
          ? this.state.searchFilters?.length
          : "",

        width: this.state.searchFilters?.width
          ? this.state.searchFilters?.width
          : "",

        height: this.state.searchFilters?.height
          ? this.state.searchFilters?.height
          : "",
      },
      (res) => {
        this.setState({ dataLoading: false }, () => {});
        // this.setState({ isExportingExcel: false });
        // let exdata=[]
        let exportData = [];
        if (res?.data?.docs?.length > 0) {
          res.data.docs.map((ele) => {
            // console.log("ele", ele);
            let lengthSpec =
              ele?.specs?.length !== undefined ? ele?.specs?.length : "N/A";
            let widthSpec =
              ele?.specs?.width !== undefined ? ele?.specs?.width : "N/A";
            exportData.push({
              "Vehicle Type Code": ele?.vehicleTypeCode
                ? ele?.vehicleTypeCode
                : "N/A",
              Label: ele?.label ? ele?.label : "N/A",
              Width: widthSpec,
              Length: lengthSpec,
              Height: ele?.specs?.height ? ele?.specs?.height : "N/A",
              Weight: ele?.specs?.weight ? ele?.specs?.weight : "N/A",
            });
          });
        }
        // console.log("export Data", exportData);
        if (exportData?.length > 0) {
          exportToCSV([...exportData], "Vehicle Type");
        } else {
          exportData.push({
            "Vehicle Type Code": "",
            Label: "",
            Width: "",
            Length: "",
            Height: "",
            Weight: "",
          });
          exportToCSV([...exportData], "Vehicle Type");
        }
      }
    );
  };

  render() {
    const { specOffset, specLength } = this.props;
    // const localStorageData = JSON.parse(
    //   localStorage.getItem("vehiclesListData")
    // );
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    var darkTheme = mode === "dark__mode" ? true : false;

    let rows = [];
    let data = this.props.vehicleType?.docs;

    if (Array.isArray(data)) {
      offset = specOffset;
      totCnt = specLength;
      toVal = offset + this.props.vehicleType?.docs?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      if (data !== undefined && data.length > 0) {
        data.forEach((ele) => {
          let lengthSpec =
            ele?.specs?.length !== undefined ? ele?.specs?.length : "N/A";
          let widthSpec =
            ele?.specs?.width !== undefined ? ele?.specs?.width : "N/A";

          if (ele) {
            rows.push({
              vehicleTypeCode: ele?.vehicleTypeCode
                ? ele?.vehicleTypeCode
                : "N/A",
              label: ele?.label ? ele?.label : "N/A",
              specWidth: widthSpec,
              specLength: lengthSpec,
              specHeight: ele?.specs?.height ? ele?.specs?.height : "N/A",
              specWeight: ele?.specs?.weight ? ele?.specs?.weight : "N/A",
              image: ele?.image ? (
                <a href={ele?.image} target="_blank" rel="noopener noreferrer">
                  {" "}
                  <img
                    src={ele?.image}
                    alt=""
                    style={{ width: "90px", height: "41px" }}
                  ></img>
                </a>
              ) : (
                "N/A"
              ),
              actions: actions(ele, {
                routingToEditdetail: this.editDetail,
                routingToDelete: this.deleteVehicleSpec,
              }),
            });
          }
        });
      }
    }

    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let userType = authUser?.data?.role?.label;

    return (
      <React.Fragment>
        <div /* className="page-content" */>

        <Modal
           isOpen={this.state.isOpen}
           toggle={this.closeModal}
           size="lg"
           className="productModal"
         >
           <ModalHeader>
             <div className="Heading">
               <h3>Upload VechileTypes Data</h3>
               <svg
                 onClick={this.closeModal}
                 width="15"
                 height="15"
                 viewBox="0 0 15 15"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <path
                   d="M13.9092 1.82141L1.90918 13.8214"
                   stroke="#2800FC"
                   strokeWidth="4"
                   strokeLinecap="round"
                   strokeLinejoin="round"
                 />
                 <path
                   d="M1.90918 1.82141L13.9092 13.8214"
                   stroke="#2800FC"
                   strokeWidth="4"
                   strokeLinecap="round"
                   strokeLinejoin="round"
                 />
               </svg>
             </div>
           </ModalHeader>
           <ModalBody>
             <div className="imageDiv">
               <div className="d-block">
                 <svg
                   onClick={() => this.fileUploadTrigger()}
                   width="150"
                   height="150"
                   viewBox="0 0 50 50"
                   fill="none"
                   xmlns="http://www.w3.org/2000/svg"
                 >
                   <path
	                     d="M0 31.6534C0.0758962 31.2107 0.126494 30.768 0.227689 30.3253C0.809559 27.6309 2.23894 25.5185 4.51582 23.9753C4.65497 23.8741 4.80676 23.7729 4.9712 23.659C4.17429 20.9521 4.57907 18.4855 6.60297 16.4616C8.61422 14.4503 11.0808 13.9949 13.7372 14.8045C17.0893 8.92253 22.0225 6.15232 28.7773 7.22752C33.9256 8.04973 39.1245 12.4517 39.9214 19.3709C40.2376 19.4468 40.5665 19.4974 40.8827 19.5986C44.8167 20.7876 47.511 23.3428 48.8265 27.2009C50.6607 32.5515 48.7633 37.9781 44.0704 41.1531C42.2995 42.3548 40.3262 42.9873 38.1631 42.9873C35.0008 42.9873 31.8511 42.9873 28.6888 42.9873C28.499 42.9873 28.3093 42.9873 28.0942 42.9873C28.0942 39.3949 28.0942 35.8657 28.0942 32.2733C28.2587 32.2606 28.4105 32.248 28.5623 32.248C29.4351 32.248 30.3079 32.2353 31.168 32.248C31.7246 32.2606 32.2432 32.2227 32.5342 31.6534C32.8378 31.0842 32.5595 30.6415 32.2306 30.1988C31.0415 28.5543 29.8525 26.9099 28.6635 25.2655C27.7021 23.9373 26.7408 22.5965 25.7794 21.2683C25.1343 20.3829 24.35 20.3702 23.7176 21.2683C21.5419 24.2662 19.3788 27.2768 17.2031 30.2747C17.1525 30.3379 17.1146 30.4012 17.064 30.4771C16.811 30.8565 16.7351 31.2487 16.9501 31.6661C17.1652 32.0709 17.5194 32.2606 17.9874 32.2606C19.1132 32.2606 20.239 32.2606 21.4027 32.2606C21.4027 35.853 21.4027 39.3949 21.4027 42.9999C21.2003 42.9999 21.0106 42.9999 20.8209 42.9999C17.3043 42.9999 13.7878 42.9873 10.2839 42.9999C6.78006 43.0126 4.11104 41.4314 2.0745 38.6865C0.974001 37.1939 0.328883 35.5115 0.0885456 33.6647C0.0758962 33.5761 0.0252987 33.4876 0 33.3991C0 32.8172 0 32.2353 0 31.6534Z"
                     fill="#C6C6C6"
                   />
                 </svg>
                 <input
                   type="file"
                   name="Image"
                   multiple={true}
                   accept=".xlsx, .jpg, .jpeg, .png"
                   style={{ display: "none" }}
                   ref={this.fileInput}
                   onChange={(e) => {
                     this.fileUpload(e);
                   }}
                 />
                 <span className="clickText">
                   {this.state.selectedFile?.name
                     ? this.state.selectedFile?.name
                     : "Click here to upload Excel"}
                 </span>
               </div>
             </div>
           </ModalBody>
           <ModalFooter>
             <button
               className="uploadTemplateButton"
               onClick={() => {
                 this.downloadExcelTemplate();
               }}
             >
               <span className="light__span">
                 <i
                   className="fas fa-file-excel"
                   style={{ color: "#20511f" }}
                 ></i>
                 &nbsp;Template
               </span>
               <GetAppIcon fontSize="xs" className="downloadIcon" />
             </button>
             <button
               className="Submit"
               onClick={() => {
                 this.handleSubmitExcel();
               }}
             >
               Submit
             </button>
           </ModalFooter>
         </Modal>


         
          {getLocalStorage()}
          <QuickFilters
            openModal={this.openModal}
            toggleExcel={this.exportExcelData}
            addDriverFunc={this.addVehicle}
            reset={this.reset}
            onInputChange={this.handleSearchInput}
            inputVal={this.state.searchFilters}
            form={this.state.form}
            handleSubmitChange={this.handleSubmitChange}
            enterCheck={this.enterCheck}
            filterList={this.getData}
            vehicleIcon={
              <svg
                width="14"
                height="12"
                viewBox="0 0 14 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.33333 1H1V8.22222H9.33333V1Z"
                  stroke={darkTheme ? "#b7a9ff" : "#2800FC"}
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.33203 3.7778H11.5543L13.2209 5.44447V8.22225H9.33203V3.7778Z"
                  stroke={darkTheme ? "#b7a9ff" : "#2800FC"}
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3.50022 11C4.26728 11 4.88911 10.3782 4.88911 9.61112C4.88911 8.84406 4.26728 8.22223 3.50022 8.22223C2.73315 8.22223 2.11133 8.84406 2.11133 9.61112C2.11133 10.3782 2.73315 11 3.50022 11Z"
                  stroke={darkTheme ? "#b7a9ff" : "#2800FC"}
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.7209 11C11.488 11 12.1098 10.3782 12.1098 9.61112C12.1098 8.84406 11.488 8.22223 10.7209 8.22223C9.95386 8.22223 9.33203 8.84406 9.33203 9.61112C9.33203 10.3782 9.95386 11 10.7209 11Z"
                  stroke={darkTheme ? "#b7a9ff" : "#2800FC"}
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
            mode={darkTheme}
          />

          <Container fluid>
            {userType && (userType !== "admin" || userType !== "Admin") ? (
              <Index
                {...this.props}
                headers={AdminHeaderData}
                data={rows}
                searchBar={searchBar}
                fetchId={this.props.fetchId}
                getAllData={this.fetchVehicleType}
                rowHeight={"56px"}
                limit={data?.length}
                dataLoading={this.state.dataLoading}
              />
            ) : (
              <Index
                {...this.props}
                headers={AdminHeaderData}
                data={rows}
                searchBar={searchBar}
                fetchId={this.props.fetchId}
                getAllData={this.fetchVehicleType}
                rowHeight={"56px"}
                limit={data?.length}
                dataLoading={this.state.dataLoading}
              />
            )}
            {totCnt !== 0 && !this.state.dataLoading ? (
              <Row
                xs={12}
                style={{ color: darkTheme ? "#9D9D9D" : "#0C0C0C" }}
                className="mb-2 paginationRow"
              >
                <Col xs={7} sm={5} md={4} className="span-col-pagenation">
                  <div className="float-left">
                    <Pagination
                      className="desktop-pagination"
                      size="medium"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                    <Pagination
                      className="mobile-pagination"
                      size="small"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                  </div>
                </Col>
                <Col
                  className="mt-2 spanPagenationentries span-col-pagenation"
                  xs={5}
                  sm={7}
                  md={8}
                >
                  {" "}
                  <span style={{ color: darkTheme ? "#9D9D9D" : "#0C0C0C" }}>
                    Showing {fromVal} to {toVal} of {totCnt} entries
                  </span>
                </Col>
              </Row>
            ) : null}
          </Container>

          <PopupVehicleType
            title="Vehicle Type"
            openPopup={this.state.setOpenPopup}
            setOpenPopupfalse={this.setOpenPopupfalse}
            mode={darkTheme}
            height="90%"
          >
            <VehicleTypeForm
              handleSubmit={this.handleSubmit}
              mode={darkTheme}
              viewData={this.state.viewById}
            />
          </PopupVehicleType>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  fetchId: state.VehicleType?.vehicleType?.fetchId,
  policies: state.AccessPolicy.accessPolicies.policies,
  vehicleType: state.VehicleType?.vehicleType?.vehicleTypeSpec,
  specOffset: state.VehicleType?.vehicleType?.vehicleTypeSpec?.offset,
  specLength: state.VehicleType?.vehicleType?.vehicleTypeSpec?.length,
});

export default connect(mapStateToProps, {
  deleteVehicleTypesSpec,
  fetchVehicleTypesFilter,
  fetchVehicleTypesFilterExcel,
  uploadImagesAws,
  createVechileTypeViaExcel,
  fetchVechileTypePresignedUrl
})(Vehicle);
