import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  Form,
} from "reactstrap";
export default class UpdateProductModal extends Component {
  render() {
    const { errors } = this.props;
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    var darkMode = mode === "dark__mode" ? true : false;
    return (
      <Modal
        isOpen={this.props.modal}
        // autoFocus={true}
        centered={true}
        toggle={this.props.toggle}
        className="addZoneToState"
      //  size={"xl"}
      >
        <div className="modal-content p-1">
          <ModalHeader>
            <div className="Heading">
              <h4 className="fontRoboto light__span">
                Update Optimization Values
              </h4>
              <svg
                onClick={this.props.toggle}
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.9092 1.82141L1.90918 13.8214"
                  stroke="#2800FC"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.90918 1.82141L13.9092 13.8214"
                  stroke="#2800FC"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </ModalHeader>

          <ModalBody>
            <Form>
              <FormGroup>
                <Label
                  className={`labelStyle mt-2 ${this.props.mode ? "light__span" : ""
                    }`}
                >
                  {" "}
                  <span>Case In Size</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Case In Size"
                  value={this.props.form?.caseInSize}
                  onChange={(e) =>
                    this.props.onChangeInput("caseInSize", e.target.value)
                  }
                />
                {errors?.caseInSize && <div className="error">{errors?.caseInSize} </div>}
              </FormGroup>

              <FormGroup>
                <Label
                  className={`labelStyle mt-2 ${this.props.mode ? "light__span" : ""
                    }`}
                >
                  {" "}
                  <span>Case Per Pallet</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Case Per Pallet"
                  value={this.props.form?.casePerPallet}
                  onChange={(e) =>
                    this.props.onChangeInput("casePerPallet", e.target.value)
                  }
                />
                {errors?.casePerPallet && <div className="error">{errors?.casePerPallet} </div>}
              </FormGroup>

              <FormGroup>
                <Label
                  className={`labelStyle mt-2 ${this.props.mode ? "light__span" : ""
                    }`}
                >
                  {" "}
                  <span>Weight Per Case(kg)</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Weight Per Case"
                  value={this.props.form?.weightPerCaseInKGS}
                  onChange={(e) =>
                    this.props.onChangeInput("weightPerCaseInKGS", e.target.value)
                  }
                />
                {errors?.weightPerCaseInKGS && <div className="error">{errors?.weightPerCaseInKGS} </div>}
              </FormGroup>

              <FormGroup>
                <Label
                  className={`labelStyle mt-2 ${this.props.mode ? "light__span" : ""
                    }`}
                >
                  {" "}
                  <span>Length(cm)</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Length"
                  value={this.props.form?.lInCMS}
                  onChange={(e) =>
                    this.props.onChangeInput("lInCMS", e.target.value)
                  }
                />
                {errors?.lInCMS && <div className="error">{errors?.lInCMS} </div>}
              </FormGroup>
              <FormGroup>
                <Label
                  className={`labelStyle mt-2 ${this.props.mode ? "light__span" : ""
                    }`}
                >
                  {" "}
                  <span>Weight(cm)</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Weight"
                  value={this.props.form?.wInCMS}
                  onChange={(e) =>
                    this.props.onChangeInput("wInCMS", e.target.value)
                  }
                />
                {errors?.wInCMS && <div className="error">{errors?.wInCMS} </div>}
              </FormGroup>
              <FormGroup>
                <Label
                  className={`labelStyle mt-2 ${this.props.mode ? "light__span" : ""
                    }`}
                >
                  {" "}
                  <span>Height(cm)</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Height"
                  value={this.props.form?.hInCMS}
                  onChange={(e) =>
                    this.props.onChangeInput("hInCMS", e.target.value)
                  }
                />
                {errors?.hInCMS && <div className="error">{errors?.hInCMS} </div>}
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="success"
              onClick={() => this.props.reset()}
            >
              Reset
            </Button>
            <Button
              type="button"
              color="primary"
              onClick={this.props.handleSubmit}
            >
              {this.props.viewById?.viewMode === "edit" ? "Update" : "Add"}
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    );
  }
}
