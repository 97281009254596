import React, { Component } from "react";
import { Row, Col, Card ,Button,Input } from "reactstrap";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { ENGLISH, HINDI } from "../../../language/language";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

export default class QualityDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      QuaityData: "",
    };
  }
  componentDidMount = () => {
    let QC = this.props.history.location?.state?.logs?.qualityCheck;
    let QcArr = [];
    QcArr.push(QC);
  };
  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let mode = localStorage.getItem("Theme");
    var darkState = mode === "dark__mode" ? true : false;
    const dataBoxStyle = {
      height: "360px",
      overflowY: "scroll",
      overflowX: "clip",
    };
    const oddRowStyle = {
      minHeight: "57px",
      width: "101%",
      backgroundColor: darkState ? "#24243C" : "#ffffff",
      border: "0.5px solid rgba(185, 185, 185, 0.5)",
      borderRadius: "5px",
      margin: "auto",
      marginTop: "4px",

      marginRight: "3px",
      //position:'relative',
    };
    const evenRowStyle = {
      minHeight: "57px",
      width: "101%",
      backgroundColor: darkState ? "#2E2E46" : "#F1F5F7",
      border: "0.5px solid rgba(185, 185, 185, 0.5)",
      borderRadius: "5px",
      margin: "auto",
      marginTop: "4px",

      marginRight: "3px",
      //position:'relative',
    };

    const rowHeaderStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "15px",
      color: darkState ? "#9D9D9D" : "#05284E",
      lineHeight: "18px",
      margin: "auto",
      paddingLeft: "1.5vw",
    };
    const rowSeparaterStyle = {
      borderLeft: "0.5px solid rgba(185, 185, 185, .5)",
      height: "100%",
    };
    const rowValueStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "13px",
      color: darkState ? "#9D9D9D" : "#0C0C0C",
      margin: "auto",
      flex: "wrap",
    };
    var action = (ele) => {
      // let getLanguage = userLang;
      // let lan = window.localStorage.getItem("userPreferredLang");
      // if (
      //   !userLang?.trip?.Q11 ||
      //   !userLang?.trip?.Q12 ||
      //   !userLang?.trip?.Q13
      // ) {
      //   getLanguage = lan === "EN" ? ENGLISH : HINDI;
      // }
      switch (ele) {
        case "inspectTrailerForForeignMaterial":
          return <span>Inspect trailer for foreign material?Examples:Poisons, or other Toxins?</span>;
        case "inspectTrailerForOdorsOrResidues":
          return <span>Inspect trailer for odors or residues?</span>;
        case "inspectTrailerForTheFollowingNoHolesInFloorWallCeilingOrProtruding":
          return <span>Inspect trailer for the following: No holes in floor, wall, ceiling or protruding?</span>;
        case "inCaseOfPatientDeliveriesClosedBodyVehicleToBeDeployed":
          return (
            <span>
              In case of Patient Deliveries Closed Body Vehicle to be Deployed?
            </span>
          );
        case "refrigerationUnitIsOperatingIfLoadRequiresEnvironmentalControl":
          return <span>Refrigeration unit is operating if load requires environmental control</span>;
        case "dockOrLockLightsVerifiedWorkingInsideAndOutside":
          return <span>Ck/Lock lights verified, working inside and outside?</span>;
        case "basedOnAboveInspectionCriteriaThisTrailerIsSuitableForTransportOfMedicalProduct":
          return <span>Based on above inspection criteria, this trailer is suitable for transport of medical product?</span>;
      
        default:
          break;
      }
    };

    let QualityCheckData =
      this.props.history.location?.state?.logs?.qualityCheck;
    let QualityTripData =  this.props.history.location?.state?.logs

    return (
      <>
       <Row>
            <Col xs={9} className="d-flex flex-row bd-highlight mb-2 ">
              <Button
                style={{
                  width: "60px",
                  height: "36px",
                  backgroundColor: "#3e3e3e",
                }}
                color="secondary"
                size="sm"
                onClick={() =>
                  this.props.history.push({
                    pathname: "/tripList",
                  })
                }
              >
                <i className="fas fa-arrow-circle-left fa-2x"></i>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <Card style={{ padding: "2%" }}>
                <Row className="mt-2 mr-4">
                  <Col xs={12} md={3}>
                    <div>
                      <span className="fontRoboto15 light__span">
                        Trip Id :
                      </span>{" "}
                      <span className="fontRoboto154 dark__span">
                        {QualityTripData?.tripId}
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col xs={12} md={2}>
                    <div className="fontRoboto15 light__span">Vehicle</div>

                    <Input
                      name="vehicle"
                      placeholder="Vehicle"
                      type="text"
                      size="xs"
                      value={QualityTripData?.vehicle ? QualityTripData?.vehicle?.regNo:""}
                      className="form-control-xs mt-2"
                      id="exampleFormControlInput1"
                      style={{
                        width: "100%",
                        height: "37%",
                      }}
                      readOnly
                    />
                     
                  </Col>
                  <Col xs={12} md={2}>
                    <div className="fontRoboto15 light__span">Driver Name</div>
                    <Input
                      name="driverName"
                      placeholder="Driver Name"
                      type="text"
                      size="xs"
                      value={QualityTripData?.driver ? QualityTripData?.driver?.name:""}
                      className="form-control-xs mt-2"
                      id="exampleFormControlInput1"
                      style={{
                        width: "100%",
                        height: "37%",
                      }}
                     readOnly
                    />
                    
                  </Col>
                  <Col xs={12} md={2}>
                    <div className="fontRoboto15 light__span">Driver Phone Number</div>
                    <Input
                      name="driverPhone"
                      placeholder="Driver Phone Number"
                      type="text"
                      size="xs"
                      value={QualityTripData?.driver ? QualityTripData?.driver?.phone:""}
                      className="form-control-xs mt-2"
                      id="exampleFormControlInput1"
                      style={{
                        width: "100%",
                        height: "37%",
                      }}
                      readOnly
                    />
                       
                  </Col>
                  <Col xs={12} md={2}>
                    <div className="fontRoboto15 dark__span">Carrier</div>
                    <Input
                      name="carrier"
                      placeholder="Carrier"
                      type="text"
                      size="xs"
                      value={QualityTripData?.qualityCheck ? QualityTripData?.qualityCheck?.carrier:"No Data"}
                      className="form-control-xs mt-2"
                      id="exampleFormControlInput1"
                      style={{
                        width: "100%",
                        height: "38%",
                      }}
                      readOnly
                    />
                   
                  </Col>

                  <Col xs={12} md={2}>
                    <div className="fontRoboto15 dark__span">Initiated Date</div>
                    <Input
                      name="initiatedDate"
                      placeholder="Initiated Date"
                      type="text"
                      size="xs"
                      value={QualityTripData?.qualityCheck ? QualityTripData?.qualityCheck?.initiatedDate:"No Data"}
                      className="form-control-xs mt-2"
                      id="exampleFormControlInput1"
                      style={{
                        width: "100%",
                        height: "38%",
                      }}
                      readOnly
                    />
                   
                  </Col>
                  <Col xs={12} md={2}>
                    <div className="fontRoboto15 dark__span">Bol Number</div>
                    <Input
                      name="bol Number"
                      placeholder="bolNumber"
                      type="text"
                      size="xs"
                      value={QualityTripData?.qualityCheck?.bolNumber ? QualityTripData?.qualityCheck?.bolNumber:"No Data"}
                      className="form-control-xs mt-2"
                      id="exampleFormControlInput1"
                      style={{
                        width: "100%",
                        height: "38%",
                      }}
                      readOnly
                    />
                   
                  </Col>
                  
                </Row>
                <Row className="mt-2">
                  <Col xs={12} md={2}>
                    <div className="fontRoboto15 light__span">Issue Date</div>

                    <Input
                      name="issueDate"
                      placeholder="Issue Date"
                      type="text"
                      size="xs"  
                      value={QualityTripData?.qualityCheck?.issueDate ? QualityTripData?.qualityCheck?.issueDate:"No Data"}
                      className="form-control-xs mt-2"
                      id="exampleFormControlInput1"
                      style={{
                        width: "100%",
                        height: "37%",
                      }}
                      readOnly
                    />
                     
                  </Col>
                  <Col xs={12} md={2}>
                    <div className="fontRoboto15 light__span">Date Arrived</div>
                    <Input
                      name="dateArrived"
                      placeholder="Date Arrived"
                      type="text"
                      size="xs"
                      value={QualityTripData?.qualityCheck?.dateArrived ? QualityTripData?.qualityCheck?.dateArrived:"No Data"}
                      className="form-control-xs mt-2"
                      id="exampleFormControlInput1"
                      style={{
                        width: "100%",
                        height: "37%",
                      }}
                     readOnly
                    />
                    
                  </Col>
                  <Col xs={12} md={2}>
                    <div className="fontRoboto15 light__span">Effective Date</div>
                    <Input
                      name="effectiveDate"
                      placeholder="Effective Date"
                      type="text"
                      size="xs"
                      value={QualityTripData?.qualityCheck?.effectiveDate ? QualityTripData?.qualityCheck?.effectiveDate:"No Data"}
                      className="form-control-xs mt-2"
                      id="exampleFormControlInput1"
                      style={{
                        width: "100%",
                        height: "37%",
                      }}
                      readOnly
                    />
                       
                  </Col>
                  <Col xs={12} md={2}>
                    <div className="fontRoboto15 dark__span">Initiated By</div>
                    <Input
                      name="initiatedBy"
                      placeholder="Initiated By"
                      type="text"
                      size="xs"
                      value={QualityTripData?.qualityCheck?.initiatedBy ? QualityTripData?.qualityCheck?.initiatedBy:"No Data"}
                      className="form-control-xs mt-2"
                      id="exampleFormControlInput1"
                      style={{
                        width: "100%",
                        height: "38%",
                      }}
                      readOnly
                    />
                   
                  </Col>

                  <Col xs={12} md={2}>
                    <div className="fontRoboto15 dark__span">Inspected By</div>
                    <Input
                      name="inspectedBy"
                      placeholder="Inspected By"
                      type="text"
                      size="xs"
                      value={QualityTripData?.qualityCheck?.inspectedBy ? QualityTripData?.qualityCheck?.inspectedBy:"No Data"}
                      className="form-control-xs mt-2"
                      id="exampleFormControlInput1"
                      style={{
                        width: "100%",
                        height: "38%",
                      }}
                      readOnly
                    />
                   
                  </Col>
                  <Col xs={12} md={2}>
                    <div className="fontRoboto15 dark__span">Inspected Date</div>
                    <Input
                      name="inspectedDate"
                      placeholder="Inspected Date"
                      type="text"
                      size="xs"
                      value={QualityTripData?.qualityCheck?.inspectedDate ? QualityTripData?.qualityCheck?.inspectedDate:"No Data"}
                      className="form-control-xs mt-2"
                      id="exampleFormControlInput1"
                      style={{
                        width: "100%",
                        height: "38%",
                      }}
                      readOnly
                    />
                   
                  </Col>
                  
                </Row>
                <Row className="mt-4">
                  <Col xs={12} md={2}>
                  <div className="fontRoboto15 dark__span">Reviewed By</div>
                    <Input
                      name="reviewedBy"
                      placeholder="Reviewed By"
                      type="text"
                      size="xs"
                      value={QualityTripData?.qualityCheck?.reviewedBy ? QualityTripData?.qualityCheck?.reviewedBy:"No Data"}
                      className="form-control-xs mt-2"
                      id="exampleFormControlInput1"
                      style={{
                        width: "100%",
                        height: "38%",
                      }}
                      readOnly
                    />
                  </Col>
                  <Col xs={12} md={2}>
                  <div className="fontRoboto15 dark__span">Reviewed Date</div>
                    <Input
                      name="reviewedDate"
                      placeholder="Reviewed Date"
                      type="text"
                      size="xs"
                      value={QualityTripData?.qualityCheck?.reviewedDate ? QualityTripData?.qualityCheck?.reviewedDate:"No Data"}
                      className="form-control-xs mt-2"
                      id="exampleFormControlInput1"
                      style={{
                        width: "100%",
                        height: "38%",
                      }}
                      readOnly
                    />
                  </Col>
                  <Col xs={12} md={2}>
                  <div className="fontRoboto15 dark__span">Truck Type</div>
                    <Input
                      name="type"
                      placeholder="Truck Type"
                      type="text"
                      size="xs"
                      value={QualityTripData?.qualityCheck?.type ? QualityTripData?.qualityCheck?.type:"No Data"}
                      className="form-control-xs mt-2"
                      id="exampleFormControlInput1"
                      style={{
                        width: "100%",
                        height: "38%",
                      }}
                      readOnly
                    />
                  </Col>
                  <Col xs={12} md={2}>
                  <div className="fontRoboto15 dark__span">Remark</div>
                    <Input
                      name="remarks"
                      placeholder="Remark"
                      type="text"
                      size="xs"
                      value={QualityTripData?.qualityCheck?.remarks ? QualityTripData?.qualityCheck?.remarks:"No Data"}
                      className="form-control-xs mt-2"
                      id="exampleFormControlInput1"
                      style={{
                        width: "100%",
                        height: "38%",
                      }}
                      readOnly
                    />
                  </Col>
                </Row>
              </Card>
            
            </Col>
          </Row>
        <div>
          <div style={{ marginRight: "2vw" ,marginBottom:"5vw" }}>
            <div>
              <Grid
                className="shadow indentData-hover"
                container
                style={evenRowStyle}
              >
                <Grid
                  className=""
                  item
                  // xs={4}
                  md={5}
                  style={rowHeaderStyle}
                >
                  QUESTIONS
                </Grid>
                <Grid
                  item
                  // xs={1}
                  md={1}
                  // style={{
                  //   padding: "2vh 0px",
                  //   marginLeft: "-20px",
                  // }}
                >
                  <div style={rowSeparaterStyle}></div>
                </Grid>
                <Grid
                  item
                  // xs={3}
                  md={2}
                  style={rowHeaderStyle}
                  justifyContent="center"
                  alignItems="center"
                >
                  ANSWERS
                </Grid>
                <Grid
                  item
                  md={1}
                  style={{
                    padding: "2vh 0px",
                    marginLeft: "-20px",
                  }}
                >
                  <div style={rowSeparaterStyle}></div>
                </Grid>
                <Grid
                  className=""
                  item
                  // xs={5}
                  md={3}
                  style={rowHeaderStyle}
                >
                  COMMENTS
                </Grid>
              </Grid>
              {Object.entries(QualityCheckData?.answers).map(
                ([key, value], index) => (
                  <div key={key}>
                    <Grid
                      className="shadow indentData-hover"
                      container
                      style={index % 2 === 0 ? oddRowStyle : evenRowStyle}
                      key={index}
                    >
                      <Grid
                        className=""
                        item
                        // xs={4}
                        md={5}
                        style={rowHeaderStyle}
                      >
                        {action(key)}
                      </Grid>
                      <Grid
                        item
                        md={1}
                      >
                        <div style={rowSeparaterStyle}></div>
                      </Grid>
                      <Grid
                        item
                        md={2}
                        style={rowValueStyle}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <>
                          <ul
                            style={{ marginTop: "1rem", marginLeft: "-62px" }}
                          >
                            <div className="form-check">
                              <Row md={12}> 
                               
                                 <Col md ={4}>
                                 {value[`${key}Check`] === "Yes" || value[`${key}Check`] === "yes"? (
                                    <>
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name={key}
                                        id={key}
                                        value="option1"
                                        defaultChecked
                                        readOnly
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={key}
                                      >
                                         {userLang?.trip?.YES}
                                      </label>
                                    </>
                                  ) : (
                                    <>
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name={key}
                                        id={key}
                                        value="option2"
                                        readOnly
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={key}
                                      >
                                       {userLang?.trip?.YES}
                                      </label>
                                    </>
                                  )}
                                </Col>
                                <Col  md ={4}>
                                  {value[`${key}Check`] === "No" || value[`${key}Check`] === "no" ? (
                                    <>
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name={key}
                                        id={key}
                                        value="option1"
                                        defaultChecked
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={key}
                                      >
                                        {userLang?.trip?.NO}
                                      </label>
                                    </>
                                  ) : (
                                    <>
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name={key}
                                        id={key}
                                        value="option2"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={value[`${key}`]}
                                      >
                                        {userLang?.trip?.NO}
                                      </label>
                                    </>
                                  )}
                                </Col>
                                <Col md ={4}>
                                 {value[`${key}Check`] === "NA" || value[`${key}Check`] === "N/A"? (
                                    <>
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name={key}
                                        id={key}
                                        value="option1"
                                        defaultChecked
                                        readOnly
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={key}
                                      >
                                        N/A
                                      </label>
                                    </>
                                  ) : (
                                    <>
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name={key}
                                        id={key}
                                        value="option2"
                                        readOnly
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={key}
                                      >
                                       N/A
                                      </label>
                                    </>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          </ul>
                        </>
                      </Grid>
                      <Grid
                        item
                        md={1}
                        style={{
                          padding: "2vh 0px",
                          marginLeft: "-20px",
                        }}
                      >
                        <div style={rowSeparaterStyle}></div>
                      </Grid>
                      <Grid
                        className=""
                        item
                        // xs={5}
                        md={3}
                        style={rowValueStyle}
                      >
                        {value?.comments}
                      </Grid>
                    </Grid>
                  </div>
                )
              )}
            </div>
           
          </div>
        </div>
      </>
    );
  }
}
