import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import OnTimeDeliveryReportReducer from './reducer';
const OnTimeDeliveryReport = combineReducers({
    OnTimeDeliveryReport: OnTimeDeliveryReportReducer,
    form: formReducer
});


export default OnTimeDeliveryReport;
