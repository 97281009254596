import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import moment from "moment";
import { withRouter } from "react-router-dom";

// import files
import { fetchAllTransporterSearch } from "../../../store/transporter/actions";
import { fetchHops } from "../../../store/hop/actions";
import { operationsDashboardTripsAndIndent } from "../../../store/dashboard/actions";
import { roleCheck } from "../../restrict";
import DashboardCards from "./DashboardCards";
import DashboardHeader from "../CommonHeader/DashboardHeader";
import { changeSwitchTheme } from "../../../store/layout/actions";
// styles
import "./Styles/dashboard.css";
import ShipmentCard from "./ShipmentCard";
import OnTimeDelivery from "./OnTimeDelivery";
import OperationalTATCard from "./OperationalTATCard";
import IndentDetailCard from "./IndentDetailCard";
import TripStatusCard from "./TripStatusCard";
import CarbonFootPrint from "./CarbonFootPrint";
import { WaveLoader } from "../../../components/Common/Loader/LoaderIndex";

let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

var corbonFootOptions = [
  {
    value: "month",
    label: userLang?.common?.MONTHLY ?? "Monthly",
  },
  {
    value: "year",
    label: userLang?.common?.YEARLY ?? "Yearly",
  },
  {
    value: "weekDay",
    label: userLang?.common?.WEEK_DAY ?? "Week Day",
  },
  {
    value: "quarter",
    label: userLang?.common?.QUARTERLY ?? "Quarterly",
  },
  {
    value: "date",
    label: userLang?.common?.DATE ?? "Date",
  },
  {
    value: "week",
    label: userLang?.common?.WEEK ?? "Week",
  },
];

class OperationsDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoading: false,
      transpoter: [],
      selectedTranspoterValues: [],
      selectedHopValues: [],
      transporterValues: [],
      hopValues: [],
      trips: {},
      indents: {},
      hops: [],
      datevalues: [null, null],
      dates: [],
      offset: 0,
      limit: 10,
      filterTranspoter: "",
      selectedFilter: {
        value: "month",
        label: "Monthly",
      },
      selectedFilterOperational: {
        value: "month",
        label: "Monthly",
      },
    };
    if (process.env.REACT_APP_CLIENT != "3_BAXTER") {
      (async () => {
        let redirect = await roleCheck("transporter");
        if (redirect === true) {
          this.props.history.push("/assigned-indent");
        }
      })();
    }
  }
  onReset = () => {
    this.setState(
      {
        selectedTranspoterValues: [],
        selectedHopValues: [],
        transporterValues: [],
        hopValues: [],
        datevalues: [null, null],
      },
      () => {
        this.getAllTripsAndIndentData();
      }
    );
  };
  componentDidMount = () => {
    this.getAllTripsAndIndentData();
    this.getTAllranspoterData();
    this.getAllLocationsData();
    this.props.changeSwitchTheme();
  };
  // get all operations data
  getAllTripsAndIndentData = () => {
    this.setState({ dataLoading: true });
    let startDate = moment(this.state.datevalues[0]);
    let endDate = moment(this.state.datevalues[1]);
    let diff;
    if (startDate !== null || endDate !== null) {
      diff = Math.abs(startDate.diff(endDate, "months"));
      // console.log("dif", Math.abs(diff));
      // let t =
      //   diff <= 12 ? moment(this.state.datevalues[0]).format("MM-DD-YYYY") : "";
      // console.log(t, "ttttt");
    }
    // console.log("start Date", startDate);
    // console.log("endDate", endDate);
    this.props.operationsDashboardTripsAndIndent(
      this.state.transporterValues,
      this.state.hopValues,
      this.state.datevalues[0] === null
        ? ""
        : diff <= 12
        ? moment(this.state.datevalues[0]).format("MM-DD-YYYY")
        : "",
      this.state.datevalues[1] === null
        ? ""
        : diff <= 12
        ? moment(this.state.datevalues[1]).format("MM-DD-YYYY")
        : "",
      this.state.selectedFilter ? this.state.selectedFilter.value : "",
      this.state.selectedFilterOperational
        ? this.state.selectedFilterOperational.value
        : "",
      () => {
        this.setState({ dataLoading: false }, () => {});
      }
    );
  };
  // gett all transpoters data
  getTAllranspoterData = () => {
    let filtersHopsData = {
      offset: 0,
      limit: 10,
      q: "",
    };
    this.fetchAllTransporterSearch(filtersHopsData);
  };
  // calling transpoter api
  fetchAllTransporterSearch = (filtersData) => {
    this.props.fetchAllTransporterSearch(
      filtersData.offset,
      filtersData.limit,
      filtersData.q,
      (res) => {
        let transporter = [];
        if (res.data?.docs?.length > 0) {
          res.data.docs.forEach((ele) => {
            transporter.push({
              value: ele._id ? ele._id : "",
              label: ele.name ? ele.name : "",
            });
          });
        }
        this.setState(
          {
            transpoter: transporter,
          },
          () => {}
        );
      }
    );
  };
  // get transpoter name by filters
  filtersTranspoter = (e) => {
    if (e?.length > 0) {
      let filtersData = {
        offset: this.state.offset,
        limit: this.state.limit,
        q: e,
      };
      this.fetchAllTransporterSearch(filtersData);
    }
    if (e?.length === 0) {
      let filtersData = {
        offset: this.state.offset,
        limit: this.state.limit,
        q: "",
      };
      this.fetchAllTransporterSearch(filtersData);
    }
  };
  // get all locations
  getAllLocationsData = () => {
    let filtersHopsData = {
      offset: 0,
      limit: 10,
      q: "",
    };
    this.fetchHops(filtersHopsData);
  };
  // calling hops api
  fetchHops = (filtersHopsData) => {
    this.props.fetchHops(
      {
        offset: filtersHopsData.offset,
        limit: filtersHopsData.limit,
        label: filtersHopsData.q,
      },
      (res) => {
        let hops = [];
        if (res.data?.docs?.length > 0) {
          res.data.docs.forEach((ele) => {
            hops.push({
              label: ele?.label ? ele?.label : "",
              value: ele?._id ? ele?._id : "",
            });
          });
        }
        this.setState(
          {
            hops: hops,
          },
          () => {},
          () => {}
        );
      }
    );
  };
  // get location name by filter
  filtersHops = (e) => {
    if (e?.length > 0) {
      let filtersHopsData = {
        offset: 0,
        limit: 10,
        q: e,
      };
      this.fetchHops(filtersHopsData);
    }
    if (e.length === 0) {
      let filtersHopsData = {
        offset: 0,
        limit: 10,
        q: "",
      };
      this.fetchHops(filtersHopsData);
    }
  };
  onChangeTranspoter = (selectedTranspoterValues) => {
    // console.log("selectedTranspoterValues",selectedTranspoterValues)
    if (selectedTranspoterValues?.length <= 3) {
      this.setState({ selectedTranspoterValues }, () => {
        if (this.state.selectedTranspoterValues?.length <= 3) {
          var transporterValues =
            this.state.selectedTranspoterValues === null
              ? []
              : this.state.selectedTranspoterValues?.map((ele) => {
                  return ele?.value;
                });
          this.setState({ transporterValues }, () => {
            // if (this.state.transporterValues.length >= 0) {
            this.getAllTripsAndIndentData();
            // }
          });
        } else if (this.state.selectedTranspoterValues?.length === undefined) {
          this.setState(
            {
              transporterValues: [],
              selectedTranspoterValues: [],
            },
            () => {
              this.getAllTripsAndIndentData();
            }
          );
        }
      });
    } else if (selectedTranspoterValues?.length === undefined) {
      this.setState(
        {
          transporterValues: [],
          selectedTranspoterValues: [],
        },
        () => {
          this.getAllTripsAndIndentData();
        }
      );
    }
  };

  onChangeHop = (selectedHopValues) => {
    if (selectedHopValues?.length <= 3) {
      this.setState({ selectedHopValues }, () => {
        if (this.state.selectedHopValues?.length <= 3) {
          var hopValues =
            this.state.selectedHopValues === null
              ? []
              : this.state.selectedHopValues?.map((ele) => {
                  return ele?.value;
                });
          this.setState({ hopValues }, () => {
            this.getAllTripsAndIndentData();
          });
        } else if (this.state.selectedHopValues?.length === undefined) {
          this.setState(
            {
              hopValues: [],
              selectedHopValues: [],
            },
            () => {
              this.getAllTripsAndIndentData();
            }
          );
        }
      });
    } else if (selectedHopValues?.length === undefined) {
      this.setState(
        {
          hopValues: [],
          selectedHopValues: [],
        },
        () => {
          this.getAllTripsAndIndentData();
        }
      );
    }
  };
  onChangeDatePicker = (datevalues) => {
    this.setState({ datevalues }, () => {
      // if (this.state.dates.length >= 0) {
      this.getAllTripsAndIndentData();
      // }
    });
  };
  onChangeOperationalTat = (e) => {
    // console.log("e", e)
    this.setState(
      {
        selectedFilterOperational: e,
      },
      () => {
        this.getAllTripsAndIndentData();
        // console.log("selectedFilte", this.state.selectedFilterOperational)
      }
    );
  };
  onChangeCarbonFoot = (e) => {
    // console.log("filter type",e)
    this.setState(
      {
        selectedFilter: e,
      },
      () => {
        this.getAllTripsAndIndentData();
        // console.log("selectedFilte", this.state.selectedFilter)
      }
    );
  };

  refineData = () => {
    this.setState({ loading: false });
  };

  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    const localStorageData = JSON.parse(
      localStorage.getItem("operationDashboardData")
    );
    // const tripAndIndentData = this.props?.TripsAndIndentData

    const tripAndIndentData = navigator.onLine
      ? this.props?.TripsAndIndentData
      : localStorageData?.data;
    // const indents = navigator.onLine
    //   ? this.props?.operationsIndents
    //   : localStorageData?.data?.indent;
    // const operationalTat = navigator.onLine
    //   ? this.props?.operationsTatDetails
    //   : localStorageData?.data?.operationalTATOverall;
    // const carbonEmisionFootPrint = navigator.onLine
    //   ? this.props?.operationsCarbonEmissionDetails
    //   : localStorageData?.data?.carbonFootprintOverall;

    // console.log("tripInformation", this.props.TripsAndIndentData)
    let tripData = tripAndIndentData?.tripInformation?.trips;
    let performance = tripAndIndentData?.tripInformation?.performance;
    let indentAcc = tripAndIndentData?.indentInformation?.acceptance;
    let indentInfo = tripAndIndentData?.indentInformation?.indents;
    let carbonEmission = tripAndIndentData?.tripInformation?.carbonEmission;
    let operationalTat = tripAndIndentData?.tripInformation?.operationalTAT;
    // console.log("carbonEmissionc",carbonEmission)
    // console.log("indentAcc",indentAcc)
    // console.log("tripData",tripData)
    var IndentAccpet = {
      TotalIndents: 0,
      IndentAcceptance: 0,
      ExpiryIndents: 0,
      cancelledIndents: 0,
    };
    var performanceObj = {
      onTimeDeliveries: 0,
      onTimeDeliverisPercentage: 0,
      damageTrip: 0,
      damageTripPercentage: 0,
      shortageTrip: 0,
      shortageTripPercentage: 0,
    };
    var tripInfo = {
      planned: 0,
      rejected: 0,
      cancelled: 0,
      completed: 0,
      arrivedAtVia: 0,
      epodInProgress: 0,
      loadingInProgress: 0,
      inTransit: 0,
      arrivedAtSource: 0,
      deleyed: tripAndIndentData?.tripInformation?.delayed,
      unTrackedWithConsent:
        tripAndIndentData?.tripInformation?.unTrackedWithConsent,
      unTrackedWithoutConsent:
        tripAndIndentData?.tripInformation?.unTrackedWithoutConsent,
      total: 0,
      shipmentVerified: 0,
      qualityCheckCompleted: 0,
    };
    var tot = 0;
    if (tripData !== undefined) {
      tripData.map((trip) => {
        tot = tot + trip?.total;
        tripInfo.total = tot;
        if (trip?.subStatus === "planned") {
          tripInfo.planned = trip?.total;
        } else if (trip?.subStatus === "rejected") {
          tripInfo.rejected = trip?.total;
        } else if (trip?.subStatus === "cancelled") {
          tripInfo.cancelled = trip?.total;
        } else if (trip?.subStatus === "completed") {
          tripInfo.completed = trip?.total;
        } else if (trip?.subStatus === "arrived_at_via") {
          tripInfo.arrivedAtVia = trip?.total;
        } else if (trip?.subStatus === "epod_in_progress") {
          tripInfo.epodInProgress = trip?.total;
        } else if (trip?.subStatus === "loading_in_progress") {
          tripInfo.loadingInProgress = trip?.total;
        } else if (trip?.subStatus === "in_transit") {
          tripInfo.inTransit = trip?.total;
        } else if (trip?.subStatus === "arrived_at_source") {
          tripInfo.arrivedAtSource = trip?.total;
        } else if (trip?.subStatus === "shipment_verified") {
          tripInfo.shipmentVerified = trip?.total;
        } else if (trip?.subStatus === "quality_check_completed") {
          tripInfo.qualityCheckCompleted = trip?.total;
        }
        return null;
      });
    }

    if (performance !== undefined) {
      performance.map((ele) => {
        // console.log("ele tes", ele)
        if (ele?.performanceType === "onTimeDelivery") {
          performanceObj.onTimeDeliveries = ele?.totalTrips;
          performanceObj.onTimeDeliverisPercentage = Number(
            (ele?.performancePercentage * 100).toFixed(2)
          );
        } else if (ele?.performanceType === "shortage") {
          performanceObj.shortageTrip = ele?.totalTrips;
          performanceObj.shortageTripPercentage = Number(
            (ele?.performancePercentage * 100).toFixed(2)
          );
        } else if (ele?.performanceType === "damage") {
          performanceObj.damageTrip = ele?.totalTrips;
          performanceObj.damageTripPercentage = Number(
            (ele?.performancePercentage * 100).toFixed(2)
          );
        }
        return null;
      });
    }
    if (indentAcc !== undefined) {
      indentAcc.map((ele) => {
        if (ele?.performanceType === "acceptance") {
          IndentAccpet.TotalIndents = ele?.totalIndents;
          IndentAccpet.IndentAcceptance = Number(
            (ele?.performancePercentage * 100).toFixed(2)
          );
        }
        return null;
      });
    }
    if (indentInfo !== undefined) {
      indentInfo.map((ele) => {
        if (ele.status === "Cancelled") {
          IndentAccpet.cancelledIndents = ele?.total;
        } else if (ele.status === "Expired") {
          IndentAccpet.ExpiryIndents = ele?.total;
        }
        return null;
      });
    }
    // console.log("IndentAccpet",IndentAccpet);
    // console.log("performanceObj",performanceObj)

    // const trips = this.props.operationsTrips;
    // const indents = this.props.operationsIndents;
    // const operationalTat = this.props.operationsTatDetails;
    let switchTheme = this.props.switchsTheme;
    let initStorage = localStorage.getItem("Theme");
    var opCardStyle;
    var OperationsBgClr;

    if (switchTheme === "dark__mode" || initStorage === "dark__mode") {
      OperationsBgClr = "#1B1C31";
      opCardStyle = true;
    } else if (switchTheme === "light__mode" || initStorage === "light__mode") {
      OperationsBgClr = "#FBFBFB";
      opCardStyle = false;
    }
    return (
      <React.Fragment>
        <Grid
          style={{
            backgroundColor: `${OperationsBgClr}`,
          }}
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{
              width: "100%",
            }}
            className="pdt25"
          >
            <DashboardHeader
              {...this.props}
              selectedTranspoterValues={this.state.selectedTranspoterValues}
              transporterOptions={this.state.transpoter}
              onChangeTranspoter={this.onChangeTranspoter}
              selectedHopValues={this.state.selectedHopValues}
              hopOptions={this.state.hops}
              onChangeHop={this.onChangeHop}
              datevalues={this.state.datevalues}
              onChangeDatePicker={this.onChangeDatePicker}
              onReset={this.onReset}
              userLang={userLang}
              filtersTranspoter={this.filtersTranspoter}
              filtersHops={this.filtersHops}
            />
          </Grid>
          {this.state.dataLoading ? (
            <WaveLoader />
          ) : (
            <>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <DashboardCards
                  trips={tripInfo}
                  history={this.props.history}
                  userLang={userLang}
                  opCardStyle={opCardStyle ? opCardStyle : ""}
                />
              </Grid>

              <Grid item xs={12} className="mt-2 pt-1 grid-card-container">
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                  className="pl-2 pr-2 "
                >
                  <Grid item xs={12} md={9} lg={8}>
                    <Grid
                      container
                      spacing={2}
                      justifyContent="center"
                      alignItems="center"
                      className="pl-2 pr-2 "
                    >
                      <Grid item xs={12} md={4} lg={3} className="pdt25">
                        <ShipmentCard
                          trips={performanceObj}
                          userLang={userLang}
                          darkThemeShip={opCardStyle}
                        />
                      </Grid>
                      <Grid item xs={12} md={8} lg={9} className="pdt25">
                        <OperationalTATCard
                          operationalTat={operationalTat}
                          userLang={userLang}
                          darkTheme={opCardStyle}
                          corbonFootOptions={corbonFootOptions}
                          selectedFilterOperational={
                            this.state.selectedFilterOperational
                          }
                          onChangeOperationalTat={this.onChangeOperationalTat}
                        />
                      </Grid>
                      <Grid item xs={12} md={5} lg={5} className="pdt25">
                        <OnTimeDelivery
                          indents={performanceObj}
                          performance={performance}
                          userLang={userLang}
                          darkTheme={opCardStyle}
                        />
                      </Grid>
                      <Grid item xs={12} md={7} lg={7} className="pdt25">
                        <IndentDetailCard
                          indentAcc={indentAcc}
                          indents={IndentAccpet}
                          userLang={userLang}
                          darkTheme={opCardStyle}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12} md={3} lg={4} className="pdt25">
                    <TripStatusCard
                      tripData={tripData}
                      trips={tripInfo}
                      userLang={userLang}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="pdt25">
                  <CarbonFootPrint
                    carbonEmision={carbonEmission}
                    userLang={userLang}
                    corbonFootOptions={corbonFootOptions}
                    selectedFilter={this.state.selectedFilter}
                    onChangeCarbonFoot={this.onChangeCarbonFoot}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log("state1",state)
  return {
    ...state,
    TripsAndIndentData:
      state.TripCountStatus.tripCountStatus?.operationsTripIndentDetails?.data,
    operationsTrips:
      state.TripCountStatus.tripCountStatus?.operationsTripIndentDetails?.data
        ?.trip,
    operationsIndents:
      state.TripCountStatus.tripCountStatus?.operationsTripIndentDetails?.data
        ?.indent,
    operationsTatDetails:
      state.TripCountStatus.tripCountStatus?.operationsTripIndentDetails?.data
        ?.operationalTATOverall,
    operationsCarbonEmissionDetails:
      state.TripCountStatus.tripCountStatus?.operationsTripIndentDetails?.data
        ?.carbonFootprintOverall,
    switchsTheme: state.Layout.switchTheme,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    fetchHops,
    operationsDashboardTripsAndIndent,
    fetchAllTransporterSearch,
    changeSwitchTheme,
  })(OperationsDashboard)
);
