import React, { Component } from "react";
//import from libraries
import { Row, Col } from "reactstrap";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Card } from "reactstrap";
import { Button } from "@material-ui/core";
import Select from "react-select";
// excel sheet
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

//files
import FreightCost from "./FreightCost";
// icons
import exportIcon from "../assets/external-link.png";
import exportDarkIcon from "../../Trips/assets/external-linkDark.png";

export default class FreightCostCard extends Component {
  toggleExcel = async () => {
    setTimeout(async () => {
      let invoiceData = [];
      if (this.props.invoice) {
        if (this.props.invoice !== undefined) {
          let invoice = this.props.invoice;
          invoice.forEach((ele) => {
            if (ele) {
              if (this.props.selectedFilteredFreightCost?.value === "month") {
                invoiceData.push({
                  "Freight Cost Value": Number(ele?.freightCost.toFixed(2)),
                   
                  Month: ele?.tenure,
                });
              } else if (
                this.props.selectedFilteredFreightCost?.value === "year"
              ) {
                invoiceData.push({
                  "Freight Cost Value": Number(ele?.freightCost.toFixed(2)),
                   
                  Year: ele?.tenure,
                });
              } else if (
                this.props.selectedFilteredFreightCost?.value === "weekDay"
              ) {
                invoiceData.push({
                  "Freight Cost Value": Number(ele?.freightCost.toFixed(2)),
                   
                  "Week Day": ele?.tenure,
                });
              } else if (
                this.props.selectedFilteredFreightCost?.value === "week"
              ) {
                invoiceData.push({
                  "Freight Cost Value": Number(ele?.freightCost.toFixed(2)),
                   
                  Week: ele?.tenure,
                });
              } else if (
                this.props.selectedFilteredFreightCost?.value === "quarter"
              ) {
                invoiceData.push({
                  "Freight Cost Value": Number(ele?.freightCost.toFixed(2)),
                   
                  Quarter: ele?.tenure,
                });
              } else if (
                this.props.selectedFilteredFreightCost?.value === "date"
              ) {
                invoiceData.push({
                  "Freight Cost Value": Number(ele?.freightCost.toFixed(2)),
                  Quarter: ele?.tenure,
                });
              }
            }
          });
        }
      }
      await this.exportToCSV([...invoiceData], "FREIGHT_COST_DATA_EXCEL");
    }, 1000);
    
    this.setState({ loading: true, export: true });
  };
  exportToCSV = (csvData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    this.setState({ export: false });
  };
  render() {
    const userLang = this.props?.userLang;
    const darkMode = this.props.darkMode;
    const invoice = this.props.invoice;
    // styles for select
    const customStyles = {
      option: (styles, state) => ({
        ...styles,
        cursor: "pointer",
      }),
      menu: ({ width, ...css }) => ({
        ...css,
        cursor: "pointer",
        width: "100%",
        backgroundColor: darkMode ? "#171722" : "#ffffff",
        color: darkMode ? "#B7A9FF" : "#505d69",
        border: darkMode
          ? "0.5px solid #616161"
          : "0.5px solid rgb(204, 204, 204)",
        boxShadow: darkMode
          ? "0px 3px 12px rgba(0, 0, 0, 0.12)"
          : "0px 3px 12px rgba(0, 0, 0, 0.12)",
        // minWidth: "100%",
      }),
      control: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "26px",
          width:
            this.props.selectedTranspoterValues?.length >= 2 ? "100%" : "100%",
          minWidth: "100%",
          minHeight: "26px",
          flexWrap: "nowrap",
          backgroundColor: this.props?.opCardStyle ? "#2E2E46" : "",
        };
      },
      placeholder: (style) => {
        return {
          ...style,
          cursor: "pointer",
          marginTop: "2px",
          height: "26px",
          minHeight: "26px",
          fontSize: "13px",
          fontFamily: "Roboto",
          fontWeight: 400,
          fontStyle: "normal",
          paddingLeft: "10px",
          paddingTop: "5px",
          color: this.props?.opCardStyle ? "#9D9D9D" : "#0C0C0C",
        };
      },
      indicatorsContainer: (style) => {
        return {
          ...style,
          cursor: "pointer",
          // backgroundColor: "#8e9af8",
          height: "24px",
          minHeight: "24px",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "10px",
          minHeight: "10px",
          backgroundColor: "#cccccc",
          marginTop: "8px",
        };
      },
      dropdownIndicator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "26px",
          minHeight: "26px",
          // backgroundColor: "#0066cc",
        };
      },
    };
    return (
      <>
        <Row className="">
          <Card
            className="mt-3 shadow invoice-overflow apexBgDarkCard FreightCostDiv CardBordersDashboard"
            style={{
              width: "100%",
              height: "549px",
            }}
          >
            <div className="mt-2 pl-3">
              <Row className="pt-2">
                <Col xs="9" lg={11}>
                  <span className="freight-cost-name-apx roboto-font-700 light__span">
                    {userLang?.dashboard?.FREIGHT_COST}
                  </span>
                </Col>
                <Col xs="3" lg={1} className="costRevenuPadding">
                  <div
                    className="d-flex justify-content-end"
                    style={{
                      position: "relative",
                      right: "16px",
                    }}
                  >
                    <div className="pr-2">
                      <div className="">
                        <Select
                          style={{
                            width: "100px",
                            height: "26px",
                          }}
                          className=""
                          classNamePrefix="react-select"
                          placeholder="Select"
                          autoFocus={false}
                          isSearchable
                          styles={customStyles}
                          value={this.props.selectedFilteredFreightCost}
                          options={this.props.options}
                          onChange={(e) => this.props.onChangeFreightCost(e)}
                        />
                      </div>
                    </div>

                    <div className="pr-2">
                      <div className="">
                        <Dropdown
                          isOpen={this.props?.freightCostDropdownOpen}
                          toggle={this.props?.toggleFreightCost}
                          className="d-inline-block user-dropdown"
                        >
                          <DropdownToggle
                            style={{
                              width: "100%",
                              display: "flex",
                              height: "26px",
                              border: darkMode
                                ? "0.5px solid #616161"
                                : "1px solid blue",
                              justifyContent: "center",
                            }}
                            tag="button"
                            className="btn mt-0 pt-0"
                          // id="page-header-user-dropdown"
                          >
                            <span
                              style={{
                                display: "flex",
                                marginTop: "5px",
                              }}
                              className="d-xl-inline-block ml-1 text-transform profile-name light__span"
                            >
                              {this.props?.freightCostChartType
                                ? this.props?.freightCostChartType
                                : "area"}
                            </span>
                            <i
                              style={{
                                fontSize: "17px",
                              }}
                              className="mdi mdi-chevron-down ml-1 pl-1 d-xl-inline-block mt-0 profile-chervon"
                            ></i>
                          </DropdownToggle>
                          <DropdownMenu right className="barDropDark">
                            <DropdownItem
                              onClick={() =>
                                this.props?.freightCostChartTypeLine()
                              }
                            >
                              <span className="actionDspan">Line</span>
                            </DropdownItem>
                            <DropdownItem
                              onClick={() =>
                                this.props?.freightCostChartTypeBar()
                              }
                            >
                              <span className="actionDspan">Bar</span>
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </div>

                    <div style={{ width: "33.33%" }}>
                      <div className="tooltipIcon">
                        <Button
                          onClick={() => {
                            this.toggleExcel();
                          }}
                          disabled={!navigator.onLine}
                          className="btn btn-secondary"
                          style={{
                            border: darkMode
                              ? "0.5px solid #B7A9FF"
                              : "1px solid blue",
                            height: "25.5px",
                            minWidth: "33px",
                          }}
                        >
                          <img
                            style={{ margin: "-4px" }}
                            src={darkMode ? exportDarkIcon : exportIcon}
                            alt="exp"
                          />
                        </Button>
                        <span
                          className="tooltipIcontext1"
                          style={{ width: "55px" }}
                        >
                          &nbsp;
                          {userLang?.invoice?.EXPORT ?? "Export"}
                          &nbsp;
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <hr className="mt-3 costToRevenueRatio-hr-line" />
            {/* <div className="mobile-freight">
              <MobileFreightCost
                invoice={invoice?.freightCharges}
                userLang={userLang}
                chartType={this.props?.freightCostChartType}
              />
            </div> */}
            {/* <div className="desktop-freight"> */}
            <FreightCost
              invoice={invoice}
              userLang={userLang}
              chartType={this.props?.freightCostChartType}
              selectedFilteredFreightCost={
                this.props.selectedFilteredFreightCost
              }
            />
            {/* </div> */}
          </Card>
        </Row>
      </>
    );
  }
}
