import React, { Component } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { connect } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import axios from "axios";
import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBInput,
} from "mdbreact";
// files
import { restrict } from "../../../restrict";
import { PERMS } from "../../../../enum/perms.enum";
import InvoicePdf from "../InvoicePdf";
import { stateList } from "../../../../components/Common/StateList";
import { NotificationManager } from "react-notifications";
import Index from "../../../../components/Common/DataTableNeo/Index";
import {
  AdminHeaderData,
  AdminHeaderData1,
  searchBar,
  getLocalStorage,
} from "./RowHeaders/RowHeaders.js";
import QuickFilters from "./quckFilters";
import { actions } from "./actionButtons";

// actions
import {
  fetchInvoices,
  fetchInvoicesExcel,
  acceptInvoice,
  approveInvoice,
  rejectInvoice,
  fetchInvoiceById,
  invoiceBillAddOrDelete,
} from "../../../../store/actions";

import { fetchAllTransporterSearch } from "../../../../store/transporter/actions";

var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
let authUserData;
class InvoiceList extends Component {
  constructor(props) {
    console.log("props ", props);
    super(props);
    this.state = {
      loading: false,
      role: "",
      breadcrumbItems: [
        { title: "Invoice", link: "#" },
        { title: "Invoice Generation", link: "#" },
      ],
      radioFilter: "",
      searchValue: "",
      searchOn: false,
      FromDate: "",
      ToDate: "",
      isOpen: false,
      invoiceDet: {},
      transporter: "",
      stateFilter: "",
      stateValue: "",
      rejectionCommentModal: false,
      rejectionComment: "",
      rejectedInvoiceId: "",
      documentDownloadError: false,
      documentDownloadErrorId: "",
      dataLoading: false,
      rejectState: true,
      transporterId: "",
      status: { value: "drafted", label: "Drafted" },
      filterData: {
        status:
          this.props.name === "addInvoice"
            ? { value: "drafted", label: "Drafted" }
            : "",
        gst: "",
        indentId: "",
        invoiceId: "",
        comment: "",
        lrNumber: "",
      },
      allTransporter: [],
      selectedTrans: "",
    };
    this.fromRef = React.createRef();
    this.toRef = React.createRef();
    this.table = React.createRef();

    (async () => {
      let redirect = await restrict(PERMS.INVOICE_READ);
      // console.log(redirect, "redirect11");

      if (redirect === false) {
        this.props.history.push("/operationsDashboard");
      }
    })();
  }
  componentDidMount = () => {
    let authUserData = JSON.parse(window.localStorage.getItem("authUser"));
    let localFiltersData = JSON.parse(
      localStorage.getItem("invoiceListFilters")
    );
    console.log("localFiltersData", localFiltersData);
    if (authUserData?.data?.role?.label == "transporter") {
      this.setState(
        {
          transporterId: authUserData?.data?._id,
        },
        () => {
          if (this.props.name === "addInvoice") {
            this.setState({ dataLoading: true });
            let role = authUserData.data.role.label;
            this.setState({ role: role });
            let values = {
              ...this.state.filterData,
              transporterId: this.state.transporterId,
            };
            this.props.fetchInvoices(
              values,
              (response) => {
                this.setState({ dataLoading: false });
              },
              this.state.FromDate,
              this.state.ToDate,
              this.state.stateValue
            );
          } else {
            let { status, gst, indentId, invoiceId, comment, lrNumber } =
              this.state.filterData;
            this.setState(
              {
                filterData: {
                  status: localFiltersData?.status || status,
                  gst: localFiltersData?.gst || gst,
                  indentId: localFiltersData?.indentId || indentId,
                  invoiceId: localFiltersData?.invoiceId || invoiceId,
                  comment: localFiltersData?.comment || comment,
                  lrNumber: localFiltersData?.lrNumber || lrNumber,
                  offset: localFiltersData?.offset,
                },
                FromDate: localFiltersData?.FromDate,
                ToDate: localFiltersData?.ToDate,
                stateValue: localFiltersData?.stateValue,
                selectedTrans: localFiltersData?.selectedTrans,
                stateFilter: localFiltersData?.stateFilter,
              },
              () => {
                this.setState({ dataLoading: true });
                let role = authUserData.data.role.label;
                this.setState({ role: role });
                let values = {
                  ...this.state.filterData,
                  transporterId: this.state.transporterId,
                };
                this.props.fetchInvoices(
                  values,
                  (response) => {
                    this.setState({ dataLoading: false });
                  },
                  this.state.FromDate,
                  this.state.ToDate,
                  this.state.stateValue
                );
              }
            );
          }
        }
      );
    } else {
      this.setState(
        {
          filterData: {
            status: localFiltersData?.status,
            gst: localFiltersData?.gst,
            indentId: localFiltersData?.indentId,
            invoiceId: localFiltersData?.invoiceId,
            comment: localFiltersData?.comment,
            lrNumber: localFiltersData?.lrNumber,
            offset: localFiltersData?.offset,
          },
          FromDate: localFiltersData?.FromDate,
          ToDate: localFiltersData?.ToDate,
          stateValue: localFiltersData?.stateValue,
          selectedTrans: localFiltersData?.selectedTrans,
          stateFilter: localFiltersData?.stateFilter,
        },
        () => {
          this.setState({ dataLoading: true });
          let role = authUserData.data.role.label;
          this.setState({ role: role });
          let values = {
            ...this.state.filterData,
            transporterId:
              this.state.transporterId || localFiltersData?.transporterId,
          };
          this.props.fetchInvoices(
            values,
            (response) => {
              this.setState({ dataLoading: false });
            },
            this.state.FromDate,
            this.state.ToDate,
            this.state.stateValue
          );
          this.getTAllranspoterData();
        }
      );
    }
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };
  getAllDataInvoice = () => {
    let localFiltersData = JSON.parse(
      localStorage.getItem("invoiceListFilters")
    );
    console.log("local data", localFiltersData);
    this.setState(
      {
        filterData: {
          status: localFiltersData?.status || this.state.filterData?.status,
          gst: localFiltersData?.gst || this.state.filterData?.gst,
          indentId:
            localFiltersData?.indentId || this.state.filterData?.indentId,
          invoiceId:
            localFiltersData?.invoiceId || this.state.filterData?.invoiceId,
          comment: localFiltersData?.comment || this.state.filterData?.comment,
          lrNumber:
            localFiltersData?.lrNumber || this.state.filterData?.lrNumber,
          offset: localFiltersData?.offset || 0,
        },
        FromDate: localFiltersData?.FromDate || this.state.FromDate,
        ToDate: localFiltersData?.ToDate || this.state.ToDate,
        stateValue: localFiltersData?.stateValue || this.state.stateValue,
        transporterId:
          localFiltersData?.transporterId || this.state.transporterId,
        selectedTrans:
          localFiltersData?.selectedTrans || this.state.selectedTrans,
        stateFilter: localFiltersData?.stateFilter || this.state.stateFilter,
      },
      () => {
        this.setState({ dataLoading: true });

        let values = {
          ...this.state.filterData,
          transporterId: this.state.transporterId,
        };
        this.props.fetchInvoices(
          values,
          (response) => {
            this.setState({ dataLoading: false });
          },
          this.state.FromDate,
          this.state.ToDate,
          this.state.stateValue
        );
      }
    );
  };
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 0.9;
  }
  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
  };

  handleDuration = (e) => {
    this.setState({ radioFilter: e }, () => {
      if (this.state.searchOn !== true) this.setState({ searchOn: true });
      this.getAllData();
    });
  };

  searchValueSetter = (e) => {
    this.setState({ searchValue: e.target.value, searchOn: true }, () => {
      if (this.state.searchKey !== "" && this.state.searchValue !== "")
        this.setState({ disabled: false });
      if (this.state.searchValue === "") {
        this.setState({ disabled: true });
      }
    });
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (this.state.disabled === false) {
        this.getAllData();
      }
    }
  };
  handleStateChange = (e) => {
    // this.setState({ dataLoading: true });
    // console.log(e);
    this.setState(
      {
        radioFilter: e,
        searchOn: !this.state.searchOn,
        stateFilter: e,
        stateValue: e.value,
      },
      () => {
        if (this.props.name !== "addInvoice") {
          localStorage.setItem(
            "invoiceListFilters",
            JSON.stringify({
              ...this.state.filterData,
              stateValue: this.state.stateValue,
              FromDate: this.state.FromDate,
              ToDate: this.state.ToDate,
              transporterId: this.state.transporterId,
              stateFilter: this.state.stateFilter,
              offset: 0,
            })
          );
        }
        // this.getAllData();
        // if (this.state.stateValue && this.state.FromDate && this.state.ToDate) {
        //   let values={
        //     ...this.state.filterData,
        //      transporterId: this.state.transporterId
        //   }
        //   this.props.fetchInvoices(
        //     values,
        //     (response) => {
        //       this.setState({ dataLoading: false });
        //     },
        //     this.state.FromDate,
        //     this.state.ToDate,
        //     this.state.stateValue
        //   );
        // } else if (
        //   this.state.stateFilter?.value &&
        //   !this.state.FromDate &&
        //   !this.state.ToDate
        // ) {
        //   let values={
        //     ...this.state.filterData,
        //      transporterId: this.state.transporterId
        //   }
        //   this.props.fetchInvoices(
        //     values,
        //     (response) => {
        //       this.setState({ dataLoading: false });
        //     },
        //     "",
        //     "",
        //     this.state.stateValue
        //   );
        // } else if (
        //   !this.state.stateFilter?.value &&
        //   !this.state.FromDate &&
        //   !this.state.ToDate
        // ) {
        //   let values={
        //     ...this.state.filterData,
        //      transporterId: this.state.transporterId
        //   }
        //   this.props.fetchInvoices(
        //     values,
        //     (response) => {
        //       this.setState({ dataLoading: false });
        //     },
        //     "",
        //     "",
        //     ""
        //   );
        // }
      }
    );
  };
  handleStatusChange = (e) => {
    console.log("log change", e);

    this.setState(
      (prevState) => ({
        filterData: {
          ...prevState.filterData,
          status: e,
        },
        status: e,
      }),
      () => {
        if (this.props.name !== "addInvoice") {
          localStorage.setItem(
            "invoiceListFilters",
            JSON.stringify({
              ...this.state.filterData,
              stateValue: this.state.stateValue,
              FromDate: this.state.FromDate,
              ToDate: this.state.ToDate,
              transporterId: this.state.transporterId,
              stateFilter: this.state.stateFilter,
              offset: 0,
            })
          );
          console.log("inside if");
          this.getAllDataInvoice();
        }
      }
    );
  };

  handleChange = (dateValues) => {
    // this.setState({ dataLoading: true });
    // console.log("date values", dateValues);
    this.setState(
      {
        FromDate:
          dateValues[0] !== null
            ? moment(dateValues[0]).format("MM-DD-YYYY")
            : null,
        ToDate:
          dateValues[1] !== null
            ? moment(dateValues[1]).format("MM-DD-YYYY")
            : null,
      },
      () => {
        if (this.props.name !== "addInvoice") {
          localStorage.setItem(
            "invoiceListFilters",
            JSON.stringify({
              ...this.state.filterData,
              stateValue: this.state.stateValue,
              FromDate: this.state.FromDate,
              ToDate: this.state.ToDate,
              selectedTrans: this.state.selectedTrans,
              stateFilter: this.state.stateFilter,
              transporterId: this.state.transporterId,
              offset: 0,
            })
          );
        }
        // let values={
        //   ...this.state.filterData,
        //    transporterId: this.state.transporterId
        // }
        // this.props.fetchInvoices(
        //   values,
        //   (response) => {
        //     this.setState({ dataLoading: false });
        //   },
        //   this.state.FromDate,
        //   this.state.ToDate,
        //   this.state.stateValue
        // );
      }
    );
  };

  handleChangeRow = (state) => {
    //console.log("state", state);
    // this.setState({ selectedRows: state.selectedRows });
  };

  closeInvoiceModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  invoiceModal = (r) => {
    let destination = r.destination;
    let id = r.id;
    let indentId = r.indentId;
    let invoice = r.invoice;
    let isOpen = r.isOpen;
    let select = r.select;
    let source = r.select;
    let totalCost = r.totalCost;
    let tripId = r.tripId;
    this.props.fetchInvoiceById(id, (res) => {
      // console.log(res);
    });
    this.setState({
      invoiceDet: {
        destination,
        id,
        indentId,
        invoice,
        calculations: r.calculations,
        isOpen,
        select,
        source,
        totalCost,
        tripId,
      },
      transporter: r.transporter,
    });
    this.closeInvoiceModal();
  };
  handleSearch = () => {
    this.setState({ dataLoading: true });
    let { FromDate, ToDate } = this.state;
    if (FromDate && ToDate) {
      let values = {
        ...this.state.filterData,
        transporterId: this.state.transporterId,
      };
      this.props.fetchInvoices(
        values,
        (response) => {
          this.setState({ dataLoading: false });
        },
        moment(this.state.FromDate).format("l"),
        moment(this.state.ToDate).format("l"),
        this.state.stateValue
      );
    } else {
      let values = {
        ...this.state.filterData,
        transporterId: this.state.transporterId,
      };
      this.props.fetchInvoices(
        values,
        (response) => {
          this.setState({ dataLoading: false });
        },
        "",
        "",
        this.state.stateValue
      );
    }
  };
  reset = () => {
    this.setState({ dataLoading: true });
    // this.fromRef.current.state.inputValue = "";
    // this.toRef.current.state.inputValue = "";
    if (this.props.name !== "addInvoice") {
      localStorage.setItem(
        "invoiceListFilters",
        JSON.stringify({
          status: "",
          gst: "",
          indentId: "",
          invoiceId: "",
          comment: "",
          lrNumber: "",
          stateValue: "",
          FromDate: "",
          ToDate: "",
          transporterId: "",
          stateFilter: "",
          offset: 0,
        })
      );
    }
    this.setState(
      {
        FromDate: "",
        ToDate: "",
        stateFilter: "",
        radioFilter: "",
        stateValue: "",
        filterData: {
          status:
            this.props.name === "addInvoice" ? { value: "", label: "" } : "",
          gst: "",
          indentId: "",
          invoiceId: "",
          comment: "",
          lrNumber: "",
        },
        selectedTrans: "",
        transporterId: "",
      },
      () => {
        offset = 0;
        let limit = 10;
        totCnt = 0;
        totPages = 0;
        fromVal = 0;
        toVal = 0;
        // this.props.fetchInvoices({});
        transporter: console.log(
          totCnt,
          totPages,
          fromVal,
          toVal,
          "pagination"
        );
        let values = {
          ...this.state.filterData,
          offset,
          limit,
          transporterId: this.state.transporterId,
        };
        this.props.fetchInvoices(
          values,
          (response) => {
            this.setState({ dataLoading: false });
          },
          this.state.FromDate,
          this.state.ToDate,
          this.state.stateValue
        );
      }
    );
  };
  handleChangePage = (event, val) => {
    this.setState({ dataLoading: true });
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    // console.log(val, offset);
    if (this.props.name !== "addInvoice") {
      localStorage.setItem(
        "invoiceListFilters",
        JSON.stringify({
          ...this.state.filterData,
          stateValue: this.state.stateValue,
          stateFilter: this.state.stateFilter,
          selectedTrans: this.state.selectedTrans,
          transporterId: this.state.transporterId,
          FromDate: this.state.FromDate,
          ToDate: this.state.ToDate,
          offset: offset,
        })
      );
    }
    let localFiltersData = JSON.parse(
      localStorage.getItem("invoiceListFilters")
    );
    console.log(localFiltersData);
    let limit = 10;
    let values;
    if (this.props.name !== "addInvoice") {
      values = {
        // ...this.state.filterData,
        // offset,
        ...localFiltersData,
        limit,
        transporterId: this.state.transporterId,
      };
    } else {
      values = {
        ...this.state.filterData,
        offset,
        limit,
        transporterId: this.state.transporterId,
      };
    }
    this.props.fetchInvoices(
      values,
      (response) => {
        this.setState({ dataLoading: false });
      },
      this.state.FromDate,
      this.state.ToDate,
      this.state.stateValue
    );
  };
  rejectionCommentToggle = () => {
    // console.log("inside toggle");
    this.setState({ rejectionCommentModal: !this.state.rejectionCommentModal });
  };
  CommentSaver = (e) => {
    this.setState({ rejectionComment: e.target.value }, () => {
      if (this.state.rejectionComment.length > 0) {
        this.setState({ rejectState: false });
      } else {
        this.setState({ rejectState: true });
      }
    });
  };
  rejectedInvoiceAssigner = (id) => {
    this.setState({ rejectedInvoiceId: id });
  };
  rejectInvoice = () => {
    let data = {
      invoiceId: this.state.rejectedInvoiceId,
      status: "sent_for_revision",
      comment: this.state.rejectionComment,
    };
    this.setState({ dataLoading: true });
    this.props.rejectInvoice(data, (res) => {
      NotificationManager.success(res["message"], "Success");
      this.setState({ dataLoading: false });
      this.rejectionCommentToggle();
      window.location.reload();
    });
  };
  downloadExcel = async (url) => {
    try {
      const response = await axios({
        url,
        method: "GET",
        responseType: "blob",
      });
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${new Date() / 1000}.xlsx`;
      link.click();
    } catch (error) {
      // console.error(error);
    }
  };
  downloadDocuments = (e, id) => {
    // console.log("document", e, id);
    let documents = e ? e : "";
    // console.log("downloader function", documents);
    for (let i = 0; i < documents.length; i++) {
      let type = documents[i].substring(
        documents[i].lastIndexOf(".") + 1,
        documents[i]?.length
      );
      if (documents[i].includes("vnd.ms-excel")) {
        this.downloadExcel(documents[i]);
      } else {
        fetch(documents[i])
          .then((res) => res.blob())
          .then((blob) => {
            var element = document.createElement("a");
            element.href = URL.createObjectURL(blob);
            element.download = `${new Date() / 1000}.${type}`;
            element.click();
            this.setState({
              documentDownloadError: false,
              documentDownloadErrorId: "",
            });
          })
          .catch((error) => {
            // console.log(error);
            this.setState(
              {
                documentDownloadError: true,
                documentDownloadErrorId: id,
              },
              () => console.log("error set true")
            );
          });
      }
    }
  };

  invoiceEdit = (e) => {
    this.props.history.push({
      pathname: "/InvoiceEdit",
      state: {
        invoiceId: e.invoiceID,
        id: e.id,
        transporter: e.transporter,
        calculations: e.calculations,
        bills: e.bills,
        state: e.state,
        date: moment(e.createdAt).format("DD-MMM-YYYY"),
        status: e.status,
      },
    });
  };

  invoiceAccept = async (e) => {
    this.setState({ dataLoading: true });
    await this.props.approveInvoice(e.id, (res) => {
      NotificationManager.success(res["message"], "Success");
      let values = {
        ...this.state.filterData,
        transporterId: this.state.transporterId,
      };
      this.props.fetchInvoices(
        values,
        () => {
          this.setState({ dataLoading: false }, () => {
            this.getAllDataInvoice();
          });
        },
        this.state.FromDate,
        this.state.ToDate,
        this.state.stateValue
      );
    });
  };

  invoiceReject = (e) => {
    this.rejectionCommentToggle();
    this.rejectedInvoiceAssigner(e.id);
  };

  invoiceAttached = (e, id) => {
    this.downloadDocuments(e, id);
  };

  routingTOInvoiceDetails = (e) => {
    this.invoiceModal(e);
  };
  handleResetComment = () => {
    this.setState({ rejectionComment: "" }, () => {
      this.setState({ rejectState: true });
    });
  };
  // transpoter search
  getTAllranspoterData = () => {
    this.props.fetchAllTransporterSearch(
      this.state.offset,
      10,
      this.state.filterTranspoter,
      (res) => {
        let transporter = [];
        if (res.data?.docs?.length > 0) {
          res.data.docs.forEach((ele) => {
            transporter.push({
              value: ele._id ? ele._id : "",
              label: ele.name ? ele.name : "",
            });
          });
        }

        this.setState({ allTransporter: transporter }, () => {});
      }
    );
  };
  fetchAllTransporterSearch = (filtersData) => {
    this.props.fetchAllTransporterSearch(
      filtersData.offset,
      filtersData.limit,
      filtersData.q,
      (res) => {
        let transporter = [];
        if (res.data?.docs?.length > 0) {
          res.data.docs.forEach((ele) => {
            transporter.push({
              value: ele._id ? ele._id : "",
              label: ele.name ? ele.name : "",
            });
          });
        }

        this.setState(
          { allTransporter: transporter },

          () => {}
        );
      }
    );
  };
  handleStateTransChange = (e) => {
    this.setState(
      {
        transporterId: e.value,
        selectedTrans: e,
      },
      () => {
        if (this.props.name !== "addInvoice") {
          localStorage.setItem(
            "invoiceListFilters",
            JSON.stringify({
              ...this.state.filterData,
              stateValue: this.state.stateValue,
              FromDate: this.state.FromDate,
              ToDate: this.state.ToDate,
              transporterId: this.state.transporterId,
              selectedTrans: this.state.selectedTrans,
              stateFilter: this.state.stateFilter,
              offset: 0,
            })
          );
        }
        // this.getAllDataInvoice()
      }
    );
  };
  filtersTranspoter = (e) => {
    if (e?.length > 2) {
      let filterData = {
        offset: 0,
        limit: 10,
        q: e,
      };
      this.fetchAllTransporterSearch(filterData);
    }
    if (e?.length === 0) {
      let filterData = {
        offset: 0,
        limit: 10,
        q: "",
      };
      if (this.state.allTransporter?.length === 0)
        this.fetchAllTransporterSearch(filterData);
    }
  };
  handleOnchangeFilters = (type) => {
    this.setState(
      {
        filterData: { ...this.state.filterData, [type.target]: type?.value },
      },
      () => {
        if (this.props.name !== "addInvoice") {
          localStorage.setItem(
            "invoiceListFilters",
            JSON.stringify({
              ...this.state.filterData,
              stateValue: this.state.stateValue,
              FromDate: this.state.FromDate,
              ToDate: this.state.ToDate,
              selectedTrans: this.state.selectedTrans,
              stateFilter: this.state.stateFilter,
              transporterId: this.state.transporterId,
              offset: 0,
            })
          );
        } else {
          offset = offset;
        }
      }
    );
  };
  enterCheck = (event) => {
    if (event.key === "Enter") {
      this.getAllDataInvoice();
    }
  };
  filterList = () => {
    this.getAllDataInvoice();
  };
  getAllExportData = () => {
    let values = {
      ...this.state.filterData,
      transporterId: this.state.transporterId,
      isExport: true,
    };
    this.props.fetchInvoicesExcel(
      values,
      (response) => {
        let exportDataReport = [];
        let exportData = response?.data?.docs;

        if (exportData?.length == 0) {
          exportDataReport.push({
            "Sl no.": "",
            "Invoice Id": "",
            Date: "",
            State: "",
            Status: "",
            "Total Cost": "",
            Comment: "",
          });
        }
        if (response !== undefined) {
          if (Array.isArray(exportData)) {
            exportData.forEach((ele, i) => {
              exportDataReport.push({
                "Sl no.": i + 1,
                "Invoice Id": ele?.invoiceId + "-" + ele?.gstNo,
                Transporter: ele?.transporter?.name
                  ? ele?.transporter?.name
                  : "",
                Date: ele?.createdAt
                  ? moment(ele?.createdAt).format("DD-MMM-YYYY")
                  : "",
                State: ele?.state?.stateCode ? ele?.state?.stateCode : "",
                Status: ele?.status ? ele.status.replace(/_/g, " ") : "",
                "Total Cost": ele?.bills?.reduce(
                  (accumulator, currentObject) =>
                    accumulator + currentObject.calculations.netPayble,
                  0
                ),
                Comment: ele?.comment,
              });
            });
          }
        }

        this.exportToCSV([...exportDataReport], "Invoice Data");
      },
      this.state.FromDate,
      this.state.ToDate,
      this.state.stateValue
    );
  };
  exportToCSV = (csvData, fileName) => {
    // console.log("Calling excel")
    csvData = csvData?.map(function (item) {
      for (var key in item) {
        var upper = key.toUpperCase();
        // check if it already wasn't uppercase
      }
      return item;
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  addInvoiceBills = (id) => {
    this.props.invoiceBillAddOrDelete(
      {
        invoiceId: id,
        billId: this.props.billsArrayForAdd,
        addOrDeleteBill: 1,
      },
      (res) => {
        if (res) {
          NotificationManager.success(res["message"], "Success");
          this.props.handleAddBillclose();
          this.props.getAllDataInvoiceGen();
        }
      }
    );
  };
  render() {
    console.log("select", this.state.selectedTrans);
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    const localStorageData = JSON.parse(localStorage.getItem("invoiceData"));
    const { invoices } = this.props;
    const invoiceData = navigator.onLine ? invoices : localStorageData?.data;
    const { stateFilter } = this.state;
    let Invoices = [];
    if (Array.isArray(invoiceData?.docs)) {
      offset = invoiceData?.offset;
      totCnt = invoiceData?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = invoiceData?.offset + invoiceData?.docs?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      invoiceData.docs.forEach((e) => {
        console.log(e);
        if (this.props.name === "addInvoice" && e.status === "drafted") {
          Invoices.push({
            transporterName: e.transporter ? e.transporter.name : "-----",
            calculations: e.calculations || {},
            id: e._id,
            invoiceID: e.gstNo?.length
              ? e.invoiceId + "-" + e.gstNo
              : e.invoiceId,
            state: e.state.stateCode ? e.state.stateCode : "---",
            date: moment(e.createdAt).format("DD-MMM-YYYY"),
            status: e.status.replace(/_/g, " "),
            _status: e.status,
            actions: (
              <>
                <Button
                  className="InvoiceSubmitBtn"
                  onClick={() => this.addInvoiceBills(e?._id)}
                >
                  Add to this draft
                </Button>
              </>
            ),
            bills: e.bills,
            transporter: e.transporter ? e.transporter : "",
            comments: e.comment,
            total: e.bills.reduce(
              (accumulator, currentObject) =>
                accumulator + currentObject.calculations.netPayble,
              0
            ),
            download: e.documents ? e.documents : "---",
            downloads: e.documents.length ? (
              <div>
                {" "}
                <div>
                  <i
                    onClick={() => {
                      this.downloadDocuments(e);
                    }}
                    className="fas fa-file-download fa-2x"
                    style={{ cursor: "pointer" }}
                  ></i>
                </div>
                {e._id === this.state.documentDownloadErrorId ? (
                  <div style={{ color: "red" }}>Error Downloading</div>
                ) : null}
              </div>
            ) : (
              "N/A"
            ),
          });
        } else {
          Invoices.push({
            transporterName: e.transporter ? e.transporter.name : "-----",
            calculations: e.calculations || {},
            id: e._id,
            invoiceID: e.gstNo?.length
              ? e.invoiceId + "-" + e.gstNo
              : e.invoiceId,
            state: e.state.stateCode ? e.state.stateCode : "---",
            date: moment(e.createdAt).format("DD-MMM-YYYY"),
            status: e.status.replace(/_/g, " "),
            _status: e.status,
            actions: "",
            bills: e.bills,
            transporter: e.transporter ? e.transporter : "",
            comments: (
              <div className="tooltipIconComment">
                <div className="ellipsis-text ">{e.comment}</div>
                <div className="tooltipIcontext">{e.comment}</div>
              </div>
            ),
            total: e.bills.reduce(
              (accumulator, currentObject) =>
                accumulator + currentObject.calculations.netPayble,
              0
            ),
            download: e.documents ? e.documents : "---",
            downloads: e.documents.length ? (
              <div>
                {" "}
                <div>
                  <i
                    onClick={() => {
                      this.downloadDocuments(e);
                    }}
                    className="fas fa-file-download fa-2x"
                    style={{ cursor: "pointer" }}
                  ></i>
                </div>
                {e._id === this.state.documentDownloadErrorId ? (
                  <div style={{ color: "red" }}>Error Downloading</div>
                ) : null}
              </div>
            ) : (
              "N/A"
            ),
          });
        }
      });
    }

    let UserData = JSON.parse(window.localStorage.getItem("authUser"));
    let permissions = UserData.data.permissions;

    Invoices.forEach((e) => {
      if (this.props.name !== "addInvoice") {
        actions(e, permissions, PERMS, this.state.documentDownloadErrorId, {
          routingTOInvoiceDetails: this.routingTOInvoiceDetails,
          invoiceEdit: this.invoiceEdit,
          invoiceAccept: this.invoiceAccept,
          invoiceReject: this.invoiceReject,
          invoiceAttached: this.invoiceAttached,
        });
      }
    });
    let InvoiceList = Invoices;

    // console.log("$$$$$$$$$$$$$$$$$$$$$$$$$",InvoiceList)

    let data = {
      columns: [
        {
          label: <b>{userLang?.invoice?.INVOICE_ID}</b>,
          field: "invoiceID",
          sort: "asc",
          width: 300,
        },
        {
          label: <b>{userLang?.common?.TRANSPORTER}</b>,
          field: "transporterName",
          sort: "asc",
          width: 100,
        },
        {
          label: <b>{userLang?.invoice?.DATE}</b>,
          field: "date",
          sort: "asc",
          width: 100,
        },

        {
          label: <b>{userLang?.invoice?.STATE}</b>,
          field: "state",
          sort: "asc",
          width: 300,
        },
        {
          label: <b>{userLang?.invoice?.VIEW_DETAILS}</b>,
          field: "viewDetails",
          sort: "asc",
          width: 300,
        },
        {
          label: <b>{userLang?.common?.STATUS}</b>,
          field: "status",
          sort: "asc",
          width: 300,
        },
        {
          label: <b>{userLang?.invoice?.TOTAL_COST}</b>,
          field: "total",
          sort: "asc",
          width: 300,
        },
        {
          label: userLang?.common?.ACTIONS,
          field: "actions",
          sort: "asc",
          width: 100,
        },
        {
          label: userLang?.invoice?.DOWNLOAD_DOCUMENTS,
          field: "download",
          sort: "asc",
          width: 100,
        },
        {
          label: userLang?.invoice?.COMMENT,
          field: "comments",
          sort: "asc",
          width: 100,
        },
      ],
      rows: InvoiceList,
    };
    if (this.state.role === "transporter") {
      data.columns.splice(1, 1);
    }
    // let finalData = {
    //   columns: [...data.columns],
    //   rows: [
    //     ...data.rows.map((row, order) => ({
    //       ...row,
    //     })),
    //   ],
    // };
    const statusOptions = [
      { value: "drafted", label: "Drafted" },
      { value: "submitted", label: "Submitted" },
      { value: "approval_pending", label: "Approval Pending" },
      { value: "approved", label: "Approved" },
      { value: "sent_for_revision", label: "Sent for Revision" },
      { value: "paid", label: "Paid" },
      { value: "cancelled", label: "Cancelled" },
    ];
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let userType = authUser?.data?.role?.label;
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    var darkMode = mode === "dark__mode" ? true : false;
    return (
      <>
        {getLocalStorage()}
        <MDBModal
          isOpen={this.state.rejectionCommentModal}
          toggle={this.rejectionCommentToggle}
          centered
        >
          <MDBModalHeader toggle={this.rejectionCommentToggle}>
            <span style={{ color: "red" }}>
              {userLang?.invoice?.REJECTION_COMMENT}
            </span>
          </MDBModalHeader>
          <MDBModalBody>
            <MDBInput
              className="required darkTexBox"
              type="textarea"
              value={this.state.rejectionComment}
              rows="5"
              onChange={this.CommentSaver}
            />
          </MDBModalBody>
          <MDBModalFooter>
            <Button color="warning" onClick={this.handleResetComment}>
              {userLang?.common?.RESET}
            </Button>
            <Button
              color="danger"
              onClick={this.rejectInvoice}
              disabled={this.state.rejectState}
            >
              {userLang?.invoice?.CONFIRM_REJECTION}
            </Button>
          </MDBModalFooter>
        </MDBModal>

        <InvoicePdf
          isOpen={this.state.isOpen}
          closePdf={this.closeInvoiceModal}
        ></InvoicePdf>
        {/* <Container fluid> */}
        <div>
          <QuickFilters
            nameText={this.props.name}
            name="stateFilter"
            handleStateChange={this.handleStateChange}
            handleStatusChange={this.handleStatusChange}
            statusOptions={statusOptions}
            stateListOption={stateList}
            stateFilter={stateFilter}
            handleChange={this.handleChange}
            reset={this.reset}
            hopIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
              >
                <path
                  d="M12.455 6.7275C12.455 11.1825 6.727 15.0005 6.727 15.0005C6.727 15.0005 1 11.1825 1 6.7275C1 5.20847 1.60343 3.75166 2.67755 2.67755C3.75166 1.60343 5.20847 1 6.7275 1C8.24653 1 9.70334 1.60343 10.7775 2.67755C11.8516 3.75166 12.455 5.20847 12.455 6.7275V6.7275Z"
                  stroke="#2800FC"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7 9.00049C8.10457 9.00049 9 8.10506 9 7.00049C9 5.89592 8.10457 5.00049 7 5.00049C5.89543 5.00049 5 5.89592 5 7.00049C5 8.10506 5.89543 9.00049 7 9.00049Z"
                  stroke="#2800FC"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
            mode={darkMode}
            handleOnchangeFilters={this.handleOnchangeFilters}
            filters={this.state.filterData}
            enterCheck={this.enterCheck}
            filterList={this.filterList}
            getTAllranspoterData={this.getTAllranspoterData}
            filtersTranspoter={this.filtersTranspoter}
            allTransporter={this.state.allTransporter}
            selectedTrans={this.state.selectedTrans}
            handleStateTransChange={this.handleStateTransChange}
            getAllExportData={this.getAllExportData}
            status={
              this.props.name === "addInvoice"
                ? this.state.status
                : JSON.parse(window.localStorage.getItem("invoiceListFilters"))
                    ?.status || ""
            }
          />
          {/* </div> */}
          {/* <Row>
              <Col xs={12}> */}
          <Container
            fluid
            style={{
              marginTop: "-8px",
              backgroundColor: darkMode ? "#1B1C31" : "",
            }}
          >
            {this.props.name === "addInvoice" ? (
              <Index
                {...this.props}
                headers={AdminHeaderData1}
                data={InvoiceList}
                searchBar={searchBar}
                fetchId={this.props.fetchId}
                // getAllData={this.getAllData}
                // dataLength={drivers?.drivers?.data?.length}
                rowHeight={"56px"}
                dataLoading={this.state.dataLoading}
              />
            ) : userType && (userType !== "admin" || userType !== "Admin") ? (
              <Index
                {...this.props}
                headers={AdminHeaderData}
                data={InvoiceList}
                searchBar={searchBar}
                fetchId={this.props.fetchId}
                // getAllData={this.getAllData}
                // dataLength={drivers?.drivers?.data?.length}
                rowHeight={"56px"}
                dataLoading={this.state.dataLoading}
              />
            ) : (
              <Index
                {...this.props}
                headers={AdminHeaderData}
                data={InvoiceList}
                searchBar={searchBar}
                fetchId={this.props.fetchId}
                // getAllData={this.getAllData}
                // dataLength={drivers?.drivers?.data?.length}
                rowHeight={"56px"}
                dataLoading={this.state.dataLoading}
              />
            )}

            {totCnt !== 0 && !this.state.dataLoading ? (
              <Row
                xs={12}
                style={{ color: darkMode ? "#9D9D9D" : "#0C0C0C" }}
                className="mb-2 paginationRow"
              >
                <Col xs={7} sm={5} md={4} className="span-col-pagenation">
                  <div className="float-left">
                    <Pagination
                      className="desktop-pagination"
                      size="medium"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                    <Pagination
                      className="mobile-pagination"
                      size="small"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                  </div>
                </Col>
                <Col
                  className="mt-2 spanPagenationentries span-col-pagenation"
                  xs={5}
                  sm={7}
                  md={8}
                >
                  <span>
                    Showing {fromVal} to {toVal} of {totCnt} entries
                  </span>
                </Col>
              </Row>
            ) : null}
          </Container>
          {/* </Col>
            </Row> */}
          {/* </Container> */}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  invoices: state.Billing.billing.invoices,
  fetchId: state.Billing.billing.invoices.fetchId,
});

export default connect(mapStateToProps, {
  fetchInvoices,
  fetchInvoicesExcel,
  acceptInvoice,
  approveInvoice,
  rejectInvoice,
  fetchInvoiceById,
  fetchAllTransporterSearch,
  invoiceBillAddOrDelete,
})(InvoiceList);
